import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import ExecutionPhotoUploadBase from "../../../ExecutionPhotoUploadBase";
import TakePhotoCamera from "../../../../camera/TakePhotoCamera";
import {getNodeOrNull, getNodeSchemaOrNull} from "../../../../../selectors/graphSelectors";
import {putNodeProperty, putNodes} from "../../../../../actions";
import {hasValue} from "../../../../../util/util";
import {getEnabledChildRulesForNodeByActionOrNull} from "../../../../../selectors/ruleSelectors";
import {RULE_ACTION_TYPE} from "../../../../../reducers/graphReducer";

class ExecutionInlineCamera extends ExecutionPhotoUploadBase {

    constructor(props) {
        super(props);
        this.handleFileChanged = this.handleFileChanged.bind(this);
    }

    async handleFileChanged(dataUri) {
        if (typeof window.FileReader !== 'function') {
            throw Error("The file API isn't supported on this browser.");
        }
        await this.handleFileAdded(dataUri);
    };

    render() {
        const {aspectRatio} = this.props;
        return (
            <TakePhotoCamera
                handleTakePhoto={this.handleFileChanged}
                aspectRatio={aspectRatio}
            />
        )
    }
}

const styles = () => ({});
ExecutionInlineCamera.propTypes = {
    executionQuestionId: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
    let node = getNodeOrNull(state, ownProps.executionQuestionId);
    let executionNode = getNodeOrNull(state, node.rootId);
    const photoIds = (node && node[ownProps.field + 'PhotoIds']) || null;
    const aspectRatioRules = getEnabledChildRulesForNodeByActionOrNull(state, ownProps.executionQuestionId, RULE_ACTION_TYPE.photoAspectRatio.id)
    const aspectRatio = aspectRatioRules.length > 0 ? aspectRatioRules[0].calculateValue : null
    return {
        projectId: executionNode?.projectId,
        executionId: executionNode?.id,
        procedureId: executionNode?.procedureId,
        attachmentType: node?.attachmentType || null,
        mobilePhotoMode: node?.mobilePhotoMode || null,
        photoSchema: getNodeSchemaOrNull(state, 'Photo'),
        photoCaptureSchema: getNodeSchemaOrNull(state, 'PhotoCapture'),
        photoInputId: ownProps.executionQuestionId + '-' + ownProps.field,
        required: node && node[ownProps.field + 'PhotoIdsRequired'] && !hasValue(photoIds),
        aspectRatio: aspectRatio
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        onPutNodes: nodes => dispatch(putNodes(nodes)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionInlineCamera));
