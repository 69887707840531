import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Router from './Router'
import {styles} from '../style/main'
import GraphStorageLoad from "./graph/GraphStorageLoad";
import GraphAutoReloadApp from "./graph/GraphAutoReloadApp";
import {ErrorBoundary, ExpiredTokenBanner, PageScrollMemorizer, reportEvent, withLargeScreen,} from 'tbf-react-library';
import {connect} from "react-redux";
import GraphOfflineLoad from './graph/GraphOfflineLoad';
import IntegrationAuthComponent from "../hooks/integrationAuthHooks";
import GraphClearCache from "./graph/GraphClearCache";
import GraphConfigLoad from "./graph/GraphConfigLoad";

class Main extends Component {
    componentDidMount() {
        reportEvent({name: 'SopOpened'});
        console.info('SopOpened')
    }

    componentWillUnmount() {
        // Unsure if this works...
        reportEvent({name: 'SopClosed'});
    }

    render() {
        const {classes, largeScreenViewPort, menuToggleState} = this.props;
        const disabled = window.location.pathname.endsWith('/logout');
        return (
            <main
                className={`${classes.mainContentContainer} ${largeScreenViewPort && menuToggleState && classes.mainContentContainerLargeScreen}`}
                id={'main'} data-cy-element='Main' role={'main'}>
                <div className={classes.innerWrapper}>
                    <PageScrollMemorizer/>
                    <ExpiredTokenBanner/>
                    <ErrorBoundary>
                        <GraphAutoReloadApp/>
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <GraphStorageLoad disabled={disabled}>
                            <GraphOfflineLoad/>
                            <GraphClearCache/>
                            <GraphConfigLoad/>
                            <IntegrationAuthComponent/>
                            <Router/>
                        </GraphStorageLoad>
                    </ErrorBoundary>
                </div>
            </main>
        )
    }
}

Main.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
    const menuToggleState = state.appReducer.toggleState;
    return {
        menuToggleState: menuToggleState,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(withLargeScreen()(Main)));
