import React, { useCallback } from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useDispatch, useSelector } from 'react-redux';
import { getImageEditorConfig } from './selectors/config'
import { getEditorSelectedTool } from './selectors/editor'
import cn from 'classnames'

import SelectorIcon from '@mui/icons-material/CallMade'
import ArrowIcon from '@mui/icons-material/CallReceived'
import CircleIcon from '@mui/icons-material/RadioButtonUnchecked'
import BoxIcon from '@mui/icons-material/Crop32'
import SvgIcon from '@mui/icons-material/PhotoSizeSelectActual'
import BrushIcon from '@mui/icons-material/Brush'
import TextIcon from '@mui/icons-material/TextFields'
import LineIcon from '@mui/icons-material/Remove'
import TriangleIcon from '@mui/icons-material/ChangeHistory'
import PolygonIcon from '@mui/icons-material/Grade'
import { selectImageEditorTool } from './actions'
import Color from 'color'
import { pointerTool } from './reducer';

const TOOL_ICONS_SELECTOR = {
  'default': SelectorIcon,
  'Pointer': SelectorIcon,
  'Circle': CircleIcon,
  'Rect': BoxIcon,
  'Triangle': TriangleIcon,
  'Textbox': TextIcon,
  'IText': TextIcon,
  'Line': LineIcon,
  'Pen': BrushIcon,
  'Polygon': PolygonIcon,
  'Svg': SvgIcon,
  'Arrow': ArrowIcon
}

const ToolListItem = ({ classes, tool, selected, onToolClick }) => {
  const { name, color, type, tooltip, icon, description } = tool
  const handleClick = useCallback(() => {
    if(onToolClick) onToolClick(tool)
  }, [tool, onToolClick])
  const Icon = TOOL_ICONS_SELECTOR[icon || type] || TOOL_ICONS_SELECTOR['default']
  const ToolTipComponent = tooltip ? Tooltip : React.Fragment
  const tooltipProps = tooltip ? { title: tooltip } : {}
  return (
    <ToolTipComponent {...tooltipProps}>
      <ListItem button classes={{root: cn(classes.item, selected && classes.selected)}} onClick={handleClick} >
        <ListItemIcon className={classes.itemIcon}><Icon style={{ color }} /></ListItemIcon>
        <ListItemText className={classes.itemText} primary={name} secondary={description} />
      </ListItem>
    </ToolTipComponent>
  )
}

const Toolbar = ({ classes }) => {
  const { tools } = useSelector(getImageEditorConfig)
  const dispatch = useDispatch()
  const onToolClick = useCallback((toolId) => {
    dispatch(selectImageEditorTool(toolId))
  }, [dispatch])

  const selectedTool = useSelector(getEditorSelectedTool)
  return (
    <div className={classes.toolbar}>
      <List component="nav" className={classes.list}>
        <ToolListItem
          classes={classes}
          tool={pointerTool}
          selected={selectedTool === pointerTool}
          onToolClick={onToolClick}
        />
        {tools && tools.map((tool) => (
          <ToolListItem
            key={tool.name}
            classes={classes}
            tool={tool}
            selected={selectedTool === tool}
            onToolClick={onToolClick}
          />
        ))}
      </List>
    </div>
  )
}

export default withStyles(theme => ({
  toolbar: {
    flexShrink: 0,
    flexGrow: 0,
    order: 1,
    boxShadow: '0px 3px 6px rgb(0 0 0 / 16%)',
    width: '100%',
    overflowY: 'hidden',
    overflowX: 'auto',
    [theme.breakpoints.up('md')]: {
      overflowY: 'auto',
      overflowX: 'hidden',
      width: 250,
      order: 0
    }
  },
  list: {
    whiteSpace: 'nowrap',
    flexDirection: 'row',
    display: 'flex',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column'
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  itemIcon: {
    minWidth: 'auto',
    [theme.breakpoints.up('md')]: {
      minWidth: 56,
    }
  },
  itemText: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'inherit'
    }
  },
  selected: {
    backgroundColor: `${Color(theme.palette.primary.main).alpha(0.1).string()} !important`
  }
}))(Toolbar)