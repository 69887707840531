import withStyles from '@mui/styles/withStyles';
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {NODE_IDS} from '../../reducers/graphReducer'
import NodeTextField from '../graph/NodeTextField'
import {
  getMapsOfflineDownloadProgress,
  getOfflineDownloadProgress,
  isCachingAssignedExecutions,
  isCachingMap,
  isOfflineCachingEnabled,
  isOfflineMapsCachingEnabled,
  retryItemDownload,
  retryMapDownload
} from '../MapView/selectors'
import {Drawer, Grid, LinearProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Loader, TbfAction} from "tbf-react-library";
import ReplayIcon from '@mui/icons-material/Replay';
import {useCallbackPatchNode, useNodeOrNull} from "../../hooks/nodeHooks";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import AssignmentsOfflineDiagnostics from "./AssignmentsOfflineDiagnostics";

function AssignmentsOfflineInput({classes}) {
  const [open, setOpen] = useState(false)
  const isMapRunning = useSelector(isCachingMap)
  const isLoading = useSelector(isCachingAssignedExecutions)
  const isEnabled = useSelector(isOfflineCachingEnabled)
  const downloadProgress = useSelector(getOfflineDownloadProgress)
  const mapsDownloadProgress = useSelector(getMapsOfflineDownloadProgress) || {}
  const isMapsEnabled = useSelector(isOfflineMapsCachingEnabled)
  const itemLoader = useNodeOrNull(NODE_IDS.MyAssignedExecutions)

  const anyMapsFailed = Object.values(mapsDownloadProgress).filter(a => a.failed > 0).length > 0

  const onMapRetry = useCallbackPatchNode(retryMapDownload)
  const onItemRetry = useCallbackPatchNode(retryItemDownload)
  const eitherOffline = isEnabled || isMapsEnabled
  const icon = eitherOffline ? DownloadForOfflineIcon : DownloadForOfflineOutlinedIcon

  return (
      <div className={classes.container} key={'offline-area'}>
        <TbfAction
            onClick={() => setOpen(!open)}
            iconComponent={icon}
            title={'Download for offline'}
            data-cy={'offline-options'}
        >
          Offline options
        </TbfAction>
        <Drawer
            anchor={'bottom'}
            open={open}
            onClose={() => setOpen(false)}
        >
          <Grid container className={classes.drawerContent} spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h1" color="inherit" noWrap>
                Offline options
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <NodeTextField nodeId={NODE_IDS.UserDevice} nodePropertyName='assignmentsOffline'/>
            </Grid>
            {isEnabled && Object.values(downloadProgress).map(item => {
              return <Grid item xs={12} key={item.name} className={classes.boxLevel1}>
                {item.name}: {item.downloaded} of {item.total} downloaded
                {isLoading && <Loader circular source={'Assigned Offline Items'} circularSize={16}/>}
                {
                    itemLoader?.loadingError &&
                    <React.Fragment>. Failed due to {itemLoader.loadingError}</React.Fragment>
                }
                <LinearProgress variant="determinate" value={item.progress}/>
                <AssignmentsOfflineDiagnostics/>
              </Grid>
            })
            }
            {
                itemLoader?.loadingError &&
                <Grid item xs={12} className={classes.boxLevel1}>
                  <TbfAction onClick={onItemRetry} iconComponent={ReplayIcon} title={'Retry failed'}>Retry
                    failed</TbfAction>
                </Grid>
            }
            <Grid item xs={12}>
              <NodeTextField nodeId={NODE_IDS.UserDevice} nodePropertyName='mapsOffline'/>
            </Grid>
            {
                isMapsEnabled && Object.values(mapsDownloadProgress).map(item => {
                  return <Grid item xs={12} key={item.name} className={classes.boxLevel1}>
                    {item.name}: {item.downloaded} of {item.total} tiles
                    downloaded {item.failed ? ' with ' + item.failed + ' failed' : ''}
                    {isMapRunning && item.progress !== 100 && <Loader circular source={'Map tile'} circularSize={16}/>}
                    <LinearProgress variant="determinate" value={item.progress}/>
                  </Grid>
                })
            }
            {
                anyMapsFailed && !isMapRunning &&
                <Grid item xs={12} className={classes.boxLevel1}>
                  <TbfAction onClick={onMapRetry} iconComponent={ReplayIcon} title={'Retry failed'}>Retry
                    failed</TbfAction>
                </Grid>
            }
          </Grid>
        </Drawer>
      </div>
  )
}


export default withStyles(theme => ({
  drawerContent: {
    padding: theme.spacing(4)
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  gridItem: {},
  item: {
    position: 'relative'
  },
  loader: {
    position: 'absolute',
    top: 3,
    '&.enabled': {}
  },
  boxLevel1: {
    marginLeft: theme.spacing(6)
  }
}))(AssignmentsOfflineInput)
