import React from 'react'
import {Alert} from "@mui/material";
import {useDiagnosticsOn} from "../../hooks/nodeHooks";

function TestingFeature({children}) {
    const hasDiagnostics = useDiagnosticsOn()
    return hasDiagnostics ? <Alert severity='info'>{children}</Alert> : null
}


export default TestingFeature
