import React, {useCallback, useEffect, useRef, useState} from "react";
import withStyles from '@mui/styles/withStyles';
import {useNodeOrNull} from "../../hooks/nodeHooks";
import {useCallbackUpdateScrollTop} from "./ExecutionViewHooks";
// Put this into state made the table very slow to render
let lastScrollTop = 0
const CollectionScrollContainer = ({selectorId, children, className}) => {
    const [, setScrollTop] = useState(null)
    const listAndMapContainerRef = useRef()
    const update = useCallbackUpdateScrollTop(selectorId)
    const selector = useNodeOrNull(selectorId)

    // Save scrollTop on
    useEffect(() => {
        if (listAndMapContainerRef && listAndMapContainerRef.current && selector) {
            listAndMapContainerRef.current.scrollTop = selector?.listScrollTop;
        }
    }, [listAndMapContainerRef, selector == null])

    // Restore scrollTop on mount
    useEffect(() => {
        return () => {
            update(lastScrollTop)
        }
    }, [selectorId, update])

    const handleScrollEvent = useCallback((event) => {
        event.persist();
        const {scrollTop} = event.target
        lastScrollTop = scrollTop
    }, [])

    return (
        <div
            className={className}
            id={'listingPageScroller'}
            ref={listAndMapContainerRef}
            onScroll={handleScrollEvent}
        >
            {children}
        </div>
    )
}
const styles = () => ({});
export default withStyles(styles)(CollectionScrollContainer);