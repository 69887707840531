import React, {useCallback} from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ExecutionStepTabLabel from "./ExecutionStepTabLabel";
import {putNodeProperty} from "../../actions";
import {useDispatch} from "react-redux";
import HighlightNode from "./troubleshoot/HighlightNode";
import {useNodePropertyOrNull} from "../../hooks/nodeHooks";

const ExecutionStepsTabsTab = ({stepId, isSelected, tabIndex, ...props}) => {
    const stepName = useNodePropertyOrNull(stepId, a => a.name)
    const label = <HighlightNode nodeId={stepId} cover><ExecutionStepTabLabel nodeId={stepId} isSelected={isSelected}/></HighlightNode>
    return (<Tab key={stepId} wrapped={true} data-cy-label={stepName} label={label} value={tabIndex} {...props}/>)
}
ExecutionStepsTabsTab.muiName = Tab.muiName

const ExecutionStepsTabs = ({executionId}) => {

    const stepIds = useNodePropertyOrNull(executionId, a => a.activeChildren)
    const selectedStepIndex = useNodePropertyOrNull(executionId, a => a.selectedStepIndex)
    const dispatch = useDispatch()
    const handleTabChange = useCallback((event, value) => {
        dispatch(putNodeProperty({id: executionId, selectedStepIndex: value}))
    }, [executionId])

    return (
        <Tabs
            key={'ExecutionStepsContent-' + executionId}
            id={'executionStepTabContainer'}
            value={selectedStepIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
        >
            {
                stepIds && stepIds.map((stepId, tabIndex) => {
                    return (<ExecutionStepsTabsTab key={stepId} stepId={stepId} tabIndex={tabIndex}
                                                   isSelected={tabIndex == selectedStepIndex}/>);
                })
            }
        </Tabs>
    );

}

export default ExecutionStepsTabs;