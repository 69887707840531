import React from "react";
import Alert from '@mui/material/Alert';
import {useNodeOrNull} from "../../hooks/nodeHooks";
import {EXECUTION_SOURCE_TYPES, NODE_IDS} from '../../reducers/graphReducer';
import {Link} from 'react-router-dom';
const ExecutionSourceBanner = ({executionId}) => {
    const node = useNodeOrNull(executionId);
    const source = node?.source;
    const createdFromExecutionId = node?.source?.createdFromExecutionId;
    let relatedExecution = useNodeOrNull(createdFromExecutionId ?? '');

    if(!node) {
        return  <></>
    }

    if(!relatedExecution) {
        // if created from execution is not loaded
        // try to get it on parents
        relatedExecution = node?.parents?.find(p => p.id === createdFromExecutionId);
    }

    let message = "";


    switch (source?.kind?.toLowerCase()) {
        case EXECUTION_SOURCE_TYPES.autoCreate.id.toLowerCase():
            message = `Automatically created by Rule`;
            break;
        case EXECUTION_SOURCE_TYPES.queryAdd.id.toLowerCase():
            message = `Manually added from query question`;
            break;
        case EXECUTION_SOURCE_TYPES.actionAdd.id.toLowerCase():
            message = `Added from Action Button`;
            break;
        case EXECUTION_SOURCE_TYPES.workFlow.id.toLowerCase():
            message = "Created from workflow";
            break;
        case EXECUTION_SOURCE_TYPES.add.id.toLowerCase():
            message = "Manually added from listing page";
            break;
    }

    if(!message) {
        return  <></>
    }

    return <Alert severity="info">{message} {relatedExecution && <> on <Link to={`/executions/${relatedExecution.id}`}>{`${relatedExecution.title}`}</Link></>}</Alert>
}

export default  ExecutionSourceBanner;