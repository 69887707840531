import React, { useEffect, useMemo, useRef } from "react";
import { useChildRuleByActionAndFormatOrNull, useNodeOrNull } from "../../hooks/nodeHooks"
import { createImageObjectUrl } from "../../util/util";
import { getProcedureTypeIcon } from "../../util/image";
import { PROCEDURE_THEME_FORMATS, RULE_ACTION_TYPE } from "../../reducers/graphReducer";
import cn from "classnames";
import { useProcedureConfig } from "../../hooks/procedureHooks";

const useProcedureThemeIcon = (procedureId) => {
    const procedure = useNodeOrNull(procedureId);
    const config = useProcedureConfig(procedureId);
    const iconRule = useChildRuleByActionAndFormatOrNull(procedureId, RULE_ACTION_TYPE.theme.id, PROCEDURE_THEME_FORMATS.icon.id);

    if (procedure && procedure.loadedFull && iconRule && !iconRule.deleted && iconRule.calculateValueQuery) {
        return JSON.parse(iconRule.calculateValueQuery);
    }

    if (config && config.themes && config.themes.length) {
        const theme = config.themes[0];
        return theme.icon;
    }

    return null;
}

const ExecutionThemedIcon = ({procedureId, className, isAnonymous, procedureType, onClick, ...props}) => {
    const icon = useProcedureThemeIcon(procedureId, procedureType);

    const iconSrcRef = useRef();
    const currentIconSrc = useMemo(() => {
        if (iconSrcRef.current) {
            window.URL.revokeObjectURL(iconSrcRef.current);
        }
        if (icon) {
            iconSrcRef.current =  createImageObjectUrl(icon);
        } else {
            iconSrcRef.current = getProcedureTypeIcon(procedureType);
        }
        return iconSrcRef.current;
    }, [procedureType, icon]);

    useEffect(() => {
        return () => {
            window.URL.revokeObjectURL(iconSrcRef.current);
        }
    }, []);

    if (isAnonymous) {
        return null;
    }

    return <img src={currentIconSrc || getProcedureTypeIcon(procedureType)} className={cn(className)} alt={''} onClick={onClick} {...props} />;
}

export default ExecutionThemedIcon;