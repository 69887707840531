import React from "react";
import {getNameInitials} from "../../util/util";
import Avatar from "@mui/material/Avatar";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import {Popover} from "@mui/material";
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import CloseIcon from "@mui/icons-material/CloseRounded";
import PropTypes from "prop-types";
import {getNodeOrNull, getNodesById} from "../../selectors/graphSelectors";
import {putNodeProperty} from "../../actions";
import {connect} from "react-redux";
import {strings} from "./SopLocalizedStrings";
import TbfAvatar from "./common/TbfAvatar";

class AssignmentsUserBadge extends React.Component {
    state = {
        detailsAnchorEl: null
    }

    handleOpenDetailsPopover = (event) => {
        event.stopPropagation();
        this.setState({detailsAnchorEl: event.target});
    }

    handleCloseDetailsPopover = (event) => {
        event.stopPropagation();
        this.setState({detailsAnchorEl: null});
    }

    render() {
        const {classes, users, inline} = this.props;
        const {detailsAnchorEl} = this.state;
        const showDetailsPopover = Boolean(detailsAnchorEl);

        if (!users || users.length === 0) {
            return <React.Fragment/>;
        }

        if (users.length === 1) {
            return <div className={classes.userWrapper} style={{display: inline ? 'inline-block' : undefined}}>
                <TbfAvatar name={users[0].userName}/>
            </div>
        }

        return <React.Fragment>
            <IconButton className={classes.usersShowButton} size={'small'} onClick={this.handleOpenDetailsPopover}
                        title={strings.buttons.showAllUsers}>
                <PeopleAltRoundedIcon/>
            </IconButton>

            {
                showDetailsPopover &&
                <Popover
                    open={showDetailsPopover}
                    anchorEl={detailsAnchorEl}
                    onClose={this.handleCloseDetailsPopover}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    <div className={classes.section}>
                        <IconButton size={'small'} className={classes.closeButton}
                                    title={strings.buttons.closeDetailsPopover}
                                    onClick={this.handleCloseDetailsPopover}>
                            <CloseIcon/>
                        </IconButton>
                        <div className={'sectionTitle'}>{strings.assignment.assignedUser}</div>
                        {
                            users.map((user, index) => {
                                return <div key={index} className={classes.userWrapper}>
                                    <Avatar className={'userInitials'}>{getNameInitials(user.userName)}</Avatar>
                                </div>;
                            })
                        }
                    </div>
                </Popover>
            }
        </React.Fragment>
    }
}


AssignmentsUserBadge.propTypes = {
    nodeId: PropTypes.string.isRequired,
};

const styles = theme => ({
    userInitials: {
        width: 24,
        height: 24,
        marginLeft: 5,
        fontSize: 10,
        backgroundColor: theme.palette.primary.one.main
    },
    usersShowButton: {
        color: theme.palette.primary.two.main,
        background: theme.palette.secondary.four.main,
        marginLeft: 5,
        padding: 5,
        '& .MuiSvgIcon-root': {
            fontSize: 14,
        },
        '&:hover': {
            background: theme.palette.secondary.five.main,
        }
    },
    closeButton: {
        position: 'absolute',
        right: 3,
        top: 3,
    },
    section: {
        padding: '10px 15px',
        borderBottom: `1px solid ${theme.palette.grey.two.main}`,
        minWidth: 180,
        '& .sectionTitle': {
            fontSize: 12,
            fontFamily: theme.fonts.primary.bold,
            marginBottom: 12
        }
    },
    userWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        '&:last-child': {
            marginBottom: 0,
        },
    },
});

const mapStateToProps = (state, ownProps) => {
    let node = getNodeOrNull(state, ownProps.nodeId);
    const assignments = getNodesById(state, (node && node.assignments) || []).filter(a => (a.deleted !== true && a.assignedNodeId === ownProps.nodeId));

    return {
        users: assignments.map(d => ({userName: d.entityName, id: d.id})),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AssignmentsUserBadge));
