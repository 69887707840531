import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import {strings} from "../components/SopLocalizedStrings";
import {Breadcrumbs, HistoryBackButton, PageContentWrapper, PageHeader} from "tbf-react-library";
import TrackChangesIcon from '@mui/icons-material/TrackChangesRounded';
import VersionStream from "./VersionStream";

const pageStrings = strings.versions;

export const DocumentVersionsPage = ({nodeId, nodeType}) => {
    return <>
        <PageHeader
            title={pageStrings.title}
            PageIcon={<TrackChangesIcon/>}
            renderNavigationActions={() => <HistoryBackButton/>}
            showMenuToggle={false}/>
        <PageContentWrapper>
            <Breadcrumbs list={[]}/>
            <VersionStream rootId={nodeId} rootType={nodeType}/>
        </PageContentWrapper>
    </>
}

const styles = () => ({});
DocumentVersionsPage.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.match.params.id;
    return {
        nodeId: nodeId
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocumentVersionsPage));
