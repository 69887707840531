import {Component} from 'react';
import {connect} from 'react-redux';
import flow from 'lodash/flow';
import {putNodeProperty} from "../../actions";
import {LOCATION_MODES, NODE_IDS} from "../../reducers/graphReducer";
import {round} from "tbf-jsonlogic";
import {cypress, reportDeveloperInfo, tbfLocalStorage} from "tbf-react-library";
import {dateToJson, getBrowserVisibilityProp, getIsDocumentHidden, stripNulls} from "../../util/util";
import isEqual from "react-fast-compare";

let position = null;
let mode = LOCATION_MODES.unavailable.id;
// https://rapidlasso.com/2019/05/06/how-many-decimal-digits-for-storing-longitude-latitude/
// 6 is 2.7cm
export const COORDINATE_ACCURACY = 6;

const ACCURACY_ACCURACY = 0;
const ALTITUDE_ACCURACY = 0;

class Location extends Component {

    watcher = null;

    static getLocation() {
        if (position) {
            return position;
        } else {
            return {
                mode: mode
            }
        }
    }

    setLocation(pos) {
        if (pos && pos.coords) {
            position = {
                mode: mode,
                latitude: round(pos.coords.latitude, COORDINATE_ACCURACY),
                longitude: round(pos.coords.longitude, COORDINATE_ACCURACY),
                accuracy: round(pos.coords.accuracy, ACCURACY_ACCURACY),
                altitude: round(pos.coords.altitude, ALTITUDE_ACCURACY),
                altitudeAccuracy: round(pos.coords.altitudeAccuracy, ALTITUDE_ACCURACY),
                heading: pos.coords.heading,
                speed: pos.coords.speed,
                timestamp: dateToJson(pos.timestamp),
            };
            stripNulls(position);
        } else {
            position = null;
        }
    }


    constructor(props, context) {
        super(props, context);

        this.success = this.success.bind(this);
        this.error = this.success.bind(this);
    }

    onActiveInactiveChanged = () => {
        const isInavtive = getIsDocumentHidden();
        if (isInavtive) {
            reportDeveloperInfo("stop watching location");
           this.stopWatchingLocation();
        } else {
            reportDeveloperInfo("start watching location");
            this.watchLocation();
        }
    }

    componentDidMount() {
        this.watchLocation();
        document.addEventListener(getBrowserVisibilityProp(), this.onActiveInactiveChanged, false);
    }

    watchLocation = () => {
        if (this.watcher !== null) {
            return;
        }
        // Additional check for cypress to maybe see mock location
        if (cypress.isTimerEnabled()) {
            setInterval(() => {
                let mocked = tbfLocalStorage.getItem('mocked_location');
                if (mocked) {
                    this.success(JSON.parse(mocked));
                }
            }, 100);
        } else {
            let options = {
                enableHighAccuracy: true,
                timeout: 30000,
                maximumAge: 120000
            };
            this.watcher = navigator.geolocation.watchPosition(this.success, this.error, options);
        }
    };

    componentWillUnmount() {
        this.stopWatchingLocation();
        document.removeEventListener(getBrowserVisibilityProp(), this.onActiveInactiveChanged);
    }

    stopWatchingLocation = () => {
        if (this.watcher !== null) {
            navigator.geolocation.clearWatch(this.watcher);
            this.watcher = null;
        }
    }

    lastSentPos = null;
    success = (pos) => {
        if (pos.code === 1) {
            mode = LOCATION_MODES.userDenied.id;
            this.setLocation(null);
        } else if (pos.code === 2 || pos.code === 3) {
            mode = LOCATION_MODES.unavailable.id;
            this.setLocation(null);
        } else if (pos.coords) {
            mode = LOCATION_MODES.available.id;
            this.setLocation(pos);
        }
        if (!isEqual(this.lastSentPos, position)) {
            this.lastSentPos = position;
            this.props.onPutNodeProperty({id: NODE_IDS.Location, mode: mode, position: position});
        }
    };

    error = (err) => {
        if (err.code === 1 || err.message.matches(/denied/)) {
            mode = LOCATION_MODES.userDenied.id;
        } else if (err.code === 2 || err.code === 3) {
            mode = LOCATION_MODES.unavailable.id;
        }
        this.setLocation(null);
        this.props.onPutNodeProperty({id: NODE_IDS.Location, mode: mode, position: null});
    };

    render() {
        return null;
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    }
};

export default flow([
    connect(mapStateToProps, mapDispatchToProps),
])(Location);
