import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import {putNodesProperty} from "../../../../actions";
import ExecutionQuestionInputBase from "./ExecutionQuestionInputBase";
import {convertGeoJsonFeatureToText, convertTextToGeoJsonFeature} from "../../../../util/location";
import cn from "classnames";
import MapView from "../../../MapView";
import LocationCard from "../../../MapView/LocationCard";
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {round} from "tbf-jsonlogic";
import {COORDINATE_ACCURACY} from "../../../components/Location";
import {MAP_SHAPE_TYPES} from "../../../MapView/constants";
import {ErrorBoundary} from "tbf-react-library";
import {getGeographicTools} from "../../../../selectors/executionSelectors";

const mapPopup = ({feature}) => {
    const coordinates = feature?.geometry?.coordinates || [];
    const position = coordinates[0] && coordinates[1] ? {
        lat: coordinates[1],
        lng: coordinates[0]
    } : null;
    return <LocationCard position={position}/>
}

class ExecutionQuestionGeographicMap extends ExecutionQuestionInputBase {

    onFeaturesUpdated = (features) => {
        let {field} = this.props;
        if (features?.geomentry?.type === 'Point') {
            const text = convertGeoJsonFeatureToText(features)
            features.properties.text = text;
        }
        this.updateNodeProperty(field, features, field)
    };

    handleLocationMarkerChange = (position) => {
        let {field, isPoint} = this.props;
        if (isPoint) {
            let text = `${round(position?.lat, COORDINATE_ACCURACY)} ${round(position?.lng, COORDINATE_ACCURACY)}`;
            let feature = convertTextToGeoJsonFeature(text);
            this.updateNodeProperty(field, feature, field);
        }
    };

    handleGeoSearch = (e) => {
        const {x, y} = e.location;
        const newPosition = {lat: y, lng: x};
        this.handleLocationMarkerChange(newPosition);
    };

    handleMapClick = (e) => {
        const newPosition = e.latlng;
        this.handleLocationMarkerChange(newPosition);
    };

    render = () => {
        let {classes, sourceValue, questionId, valueDisabled, isPoint, allowedGeometryTypes, isDeleted} = this.props;
        const markerId = `marker_${questionId}`;
        const useFeature = sourceValue && {
            id: markerId,
            ...sourceValue,
        }
        const features = sourceValue && !isDeleted ? [useFeature] : [];

        return <div className={`${classes.container}`}>
            <ErrorBoundary>
                <MapView
                    key={allowedGeometryTypes.join()}
                    className={cn(classes.mapContainer)}
                    features={features}
                    enableGeoSearch={!valueDisabled}
                    zoomLevel={14}
                    enableLocationChangeAnimation={true}
                    onMapClick={!valueDisabled && isPoint && !sourceValue && this.handleMapClick}
                    mapViewId={`map_${questionId}`}
                    onFeaturesUpdated={this.onFeaturesUpdated}
                    onGeoSearch={this.handleGeoSearch}
                    popupComponent={mapPopup}
                    disabled={valueDisabled}
                    allowedGeometryTypes={allowedGeometryTypes}
                />
            </ErrorBoundary>
        </div>
    }
}

const styles = (theme) => ({
    container: {
        position: 'relative',
        width: '100%',
        minHeight: 50,
    },
    mapContainer: {
        height: 500,
        borderRadius: 4,
        border: `1px solid ${theme.palette.grey.three.main}`,
        '& .leaflet-geosearch-bar': {
            width: 350,
            maxWidth: 'calc(100% - 70px)',
            margin: 6,
            '& form': {
                border: `1px solid ${theme.palette.grey.four.main} !important`,
                '& a.reset': {
                    lineHeight: '26px',
                    height: '100%',
                },
            },
        }
    },
});
ExecutionQuestionGeographicMap.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    let valueUnknown = executionQuestionNode[ownProps.field + 'Unknown'];
    let valueDisabled = ownProps.disabled || (executionQuestionNode && executionQuestionNode.initialValueReadOnly);
    const allowedGeometryTypes = getGeographicTools(state, ownProps.questionId)
    return {
        questionName: executionQuestionNode.name,
        valueUnknown: valueUnknown,
        valueDisabled: valueDisabled || executionQuestionNode.deleted,
        allowedGeometryTypes: allowedGeometryTypes,
        isPoint: allowedGeometryTypes.length === 1 && allowedGeometryTypes[0] === MAP_SHAPE_TYPES.marker.id,
        isDeleted: executionQuestionNode.deleted
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionGeographicMap));
