import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {clearGraph} from "../../actions";
import {connect} from "react-redux";
import RotateLeftIcon from '@mui/icons-material/RotateLeftRounded';
import {cypress} from "tbf-react-library";
import IconButton from "@mui/material/IconButton";
import {strings} from "../components/SopLocalizedStrings";
import GraphMobileListItem from './GraphMobileListItem';
import {DIAGNOSTIC_MODES, NODE_IDS} from '../../reducers/graphReducer';
import {getNodeOrNull} from '../../selectors/graphSelectors';

class GraphClearButton extends Component {

    clearing = false;

    constructor(props) {
        super(props);
        this.state = {};
        this.handleClear = this.handleClear.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cleared && this.clearing) {
            console.info('GraphClearButton.reload');
            window.location.reload()
        }
    }

    handleClear() {
        this.props.onClearGraph();
        this.clearing = true;
    }

    render() {
        let {classes, display} = this.props;
        return <>
            {
                display &&
                <IconButton size={'small'} onClick={this.handleClear} data-cy={'GraphClearButton'}
                            title={strings.buttons.graphClearReload}>
                    <GraphMobileListItem>
                        <RotateLeftIcon className={classes.buttonIcon}/>
                        <span className={'menuItemTitle'}>Clear cache</span>
                    </GraphMobileListItem>
                </IconButton>
            }
        </>
    }
}

const styles = () => ({});
GraphClearButton.propTypes = {};
const mapStateToProps = (state) => {
    const userDevice = getNodeOrNull(state, NODE_IDS.UserDevice);
    const diagnosticModeOn = userDevice?.diagnosticMode === DIAGNOSTIC_MODES.full.id;
    const isCypress = cypress.isCypress();
    return {
        cleared: state.graph.nodesCleared,
        display: diagnosticModeOn || isCypress || userDevice?.troubleshootOn,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onClearGraph: () => dispatch(clearGraph())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphClearButton));
