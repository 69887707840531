import React, {Component} from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Permissions} from "../../permissions";
import withStyles from '@mui/styles/withStyles';
import {
    AppHeader,
    CAN_SWITCH_VERSIONS,
    cypress,
    GetDrawerList,
    MenuItemDesktopContainer,
    MenuItemMobileContainer,
    reportEvent,
    SharedAuth,
    SideMenuBar,
    withLargeScreen,
    withMobile
} from 'tbf-react-library';
import GraphClientDelete from "../graph/GraphDeleteClient";
import GraphOfflineToggle from "../graph/GraphOfflineToggle";
import GraphClearButton from "../graph/GraphClearReload";
import GraphAutoSave from "../graph/GraphAutoSave";
import {setMenuToggleState} from "../../actions/appActions";
import GraphReloadButton from "../graph/GraphReloadButton";
import GraphNodeViewButton from "../graph/GraphNodeViewButton";
import ExecutionSearch from "./ExecutionSearch";
import GraphTroubleshootModeToggle from '../graph/GraphTroubleshootModeToggle';
import {getClientConfig, getNodeOrNull} from "../../selectors/graphSelectors";
import {NODE_IDS} from "../../reducers/graphReducer";
import {REPORT_EVENT_TYPES} from '../../util/constants';

const HelpUrl = process.env.REACT_APP_HELP_URL || '/help';

class DrawerWithAppbar extends Component {

    componentDidMount() {
        // Cloud Cypress tests are failing as client config is not applied. Seeing if this helps ...
        setTimeout(() => {
            this.setState({rerender: true})
        }, 5000)
    }

    handleDrawerToggle = (toggleState) => {
        const {onSetMenuToggleState} = this.props;
        onSetMenuToggleState(toggleState);
    };

    handleBreadcrumbClick = (_e, {name, to} = {}) => {
        reportEvent({
            name: REPORT_EVENT_TYPES.breadcrumbLinkClicked.name,
            breadcrumbName: name,
            breadcrumbTo: to,
        });
    }

    handleUserMenuOpened = (_e) => {
        reportEvent({
            name: REPORT_EVENT_TYPES.userMenuOpened.name,
        });
    }

    handleSideMenuClicked = (_e, {label, linkHref} = {}) => {
        reportEvent({
            name: REPORT_EVENT_TYPES.sideMenuItemClicked.name,
            label,
            linkHref
        });
    }

    render() {
        const {
            mobileViewPort,
            largeScreenViewPort,
            breadCrumbs,
            menuToggleState,
            history,
        } = this.props;

        const list = GetDrawerList();
        const tenants = SharedAuth.getTenants();
        const userImgSource = SharedAuth.getUserImage();
        const canSwitchVersion = CAN_SWITCH_VERSIONS || cypress.canSwitchVersions();
        const canClientDelete = SharedAuth.getClientId() === 'cypress' && SharedAuth.userHasPermission(Permissions.diagnostic.read);
        const canDiagnosticRead = SharedAuth.userHasPermission(Permissions.diagnostic.read);
        const canCypressReload = cypress.isReloadButtonEnabled();

        const mobileActionList = [<GraphAutoSave/>];
        if (canClientDelete) {
            mobileActionList.push(<GraphClientDelete/>);
        }

        if (canDiagnosticRead) {
            mobileActionList.push(<GraphOfflineToggle/>);
            mobileActionList.push(<GraphClearButton/>);
            mobileActionList.push(<GraphNodeViewButton history={history}/>);
        }
        if (canCypressReload) {
            mobileActionList.push(<GraphReloadButton/>);
        }

        return (
            <React.Fragment>
                <SideMenuBar
                    list={list}
                    defaultRoute={'projects'}
                    menuToggleState={menuToggleState}
                    auth={SharedAuth}
                    userImgSource={userImgSource}
                    canSwitchVersion={canSwitchVersion}
                    mobileActionList={mobileActionList.map(a => <MenuItemMobileContainer>{a}</MenuItemMobileContainer>)}
                    handleDrawerToggle={(ts) => {
                        this.handleDrawerToggle(ts)
                    }}
                    tenants={tenants}
                    onSideMenuClick={this.handleSideMenuClicked}
                />
                {
                    !mobileViewPort &&
                    <AppHeader
                        userImgSource={userImgSource}
                        menuToggleState={menuToggleState}
                        largeScreenViewPort={largeScreenViewPort}
                        canSwitchVersion={canSwitchVersion}
                        breadCrumbs={breadCrumbs}
                        handleDrawerToggle={(ts) => {
                            this.handleDrawerToggle(ts)
                        }}
                        onBreadcrumbClick={this.handleBreadcrumbClick}
                        onUserMenuOpened={this.handleUserMenuOpened}
                        auth={SharedAuth}
                        renderActions={() => {
                            return <React.Fragment>
                                <MenuItemDesktopContainer>
                                    <GraphNodeViewButton history={history}/>
                                </MenuItemDesktopContainer>
                                <MenuItemDesktopContainer>
                                <GraphClientDelete/>
                                </MenuItemDesktopContainer>
                                <MenuItemDesktopContainer>
                                    <GraphOfflineToggle/>
                                </MenuItemDesktopContainer>
                                <MenuItemDesktopContainer>
                                    <GraphTroubleshootModeToggle />
                                </MenuItemDesktopContainer>
                                <MenuItemDesktopContainer>
                                    <GraphClearButton/>
                                </MenuItemDesktopContainer>
                                <MenuItemDesktopContainer>
                                    <GraphReloadButton/>
                                </MenuItemDesktopContainer>
                                <MenuItemDesktopContainer>
                                    <GraphAutoSave/>
                                </MenuItemDesktopContainer>
                            </React.Fragment>
                        }}
                        renderSearchBar={({selectRef}) => {
                            return <ExecutionSearch history={history} selectRef={selectRef}/>
                        }}
                        helpUrl={HelpUrl}
                    />
                }
            </React.Fragment>
        )
    }
}

const styles = () => ({});

const mapStateToProps = (state) => {
    const breadCrumbs = state.breadCrumbReducer.breadCrumbs;
    const menuToggleState = state.appReducer.toggleState;
    const clientConfigVersion = getClientConfig(state)?.version
    const user = getNodeOrNull(state, NODE_IDS.User)

    return {
        breadCrumbs: breadCrumbs,
        menuToggleState: menuToggleState,
        // using this to force a re-render but avoid passing in full list due to too many re-render as array changes
        clientConfigVersion: clientConfigVersion,
        userTokenDate: user?.tokenIssuedDate
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetMenuToggleState: (toggleState) => dispatch(setMenuToggleState(toggleState)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withLargeScreen()(withMobile()(withRouter(DrawerWithAppbar)))));
