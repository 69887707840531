import React, {useEffect, useMemo, useRef, useState} from "react";
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import BulkActionMenuItem from "./BulkActionMenuItem";
import {summaryToFullId} from "../../../../factory/executionFactory";
import {strings} from "../../../components/SopLocalizedStrings";
import {useCallbackPatchNodes, usePermissibleNodesIfPresent} from "../../../../hooks/nodeHooks";
import {reportSuccess, reportUserError} from "tbf-react-library";
import {Permissions} from "../../../../permissions";
import GraphResourceLoad from "../../../graph/GraphResourceLoad";
import Loader from "../../../components/Loader";

const pageStrings = strings.project.show;

export default function BulkActionDelete({action, selectedRowIds, ...props}) {
    const patchNodes = useCallbackPatchNodes();

    const rowIds = useMemo(() => selectedRowIds.map(id => summaryToFullId(id)), [selectedRowIds]);

    const [loadForDeletion, setLoadForDeletion] = useState(false);

    const executions = usePermissibleNodesIfPresent(rowIds, Permissions.execution.delete);

    const localsRef = useRef({deleteProcessCompleted: true});

    const handleDeleteTimeout = () => {
        if (!localsRef.current.deleteProcessCompleted){
            setLoadForDeletion(false);
            localsRef.current.deleteProcessCompleted = true;
            reportUserError(pageStrings.bulkDeleteFailure);
        }
    }

    const handleClick = () => {
        let msg = strings.formatString(pageStrings.bulkDeleteConfirm, selectedRowIds.length ?? 0);
        if (!window.confirm(msg)) {
            return;
        }
        localsRef.current.deleteProcessCompleted = false;
        setLoadForDeletion(true);
        // Allot half a second to load and check permission for each execution
        setTimeout(handleDeleteTimeout, rowIds.length * 500);
    }

    useEffect(() => {
        // Make sure all nodes are loaded and user has permission for each, otherwise should fail accordingly
        const loadedForDeletion = executions.length && executions.length === rowIds.length && executions.every((e) => e.loadedFull);
        if (loadForDeletion && loadedForDeletion && !localsRef.current.deleteProcessCompleted) {
            patchNodes(executions.map(e => ({id: e.id, deleted: true})));
            patchNodes(selectedRowIds.map(id => ({id, deleted: true})));
            reportSuccess(strings.formatString(pageStrings.bulkDeleteSuccess, executions.length));
            localsRef.current.deleteProcessCompleted = true;
            setLoadForDeletion(false);
        }
    }, [executions, rowIds, loadForDeletion, patchNodes, selectedRowIds])

    return <>
        {loadForDeletion && rowIds.map(id => <GraphResourceLoad
            key={id}
            resourcePath={`/executions?id=${id}`}
            friendlyName={strings.execution.name}
            nodeId={id}
            nodeType={'ExecutionRoot'}
            hideOfflineWarnings={false}
            disableLoader
        />)}
        <BulkActionMenuItem
            selectedRowIds={selectedRowIds}
            action={action}
            onClick={handleClick}
            icon={loadForDeletion ? <Loader circularSize={24} circular/> : <DeleteIcon />}
            disabled={loadForDeletion}
            cyName={'bulk-action-delete'}
            {...props}
        >
            {action.name}
        </BulkActionMenuItem>
    </>
}