import React from "react";
import withStyles from '@mui/styles/withStyles';
import {strings} from "../../components/SopLocalizedStrings";
import {useChildRulesByAction} from "../../../hooks/nodeHooks";
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import {useCallbackCreateRuleCollectionColumn} from "../../../hooks/procedureHooks";
import {AddAction} from "tbf-react-library";
import ProcedureRuleCollectionOrderBy from "./ProcedureCollectionOrderBy";

const ProcedureRuleCollectionOrderBys = ({disabled, parentRuleId, showDeleted, multipleOrderBy = true}) => {
    const ruleActionType = RULE_ACTION_TYPE.collectionOrder.id;
    const columns = useChildRulesByAction(parentRuleId, ruleActionType).filter(a => showDeleted || !a.deleted)
    const addOrderBy = useCallbackCreateRuleCollectionColumn(parentRuleId, ruleActionType)

    const handleAddOrderBy = () => addOrderBy();

    return (
        <div data-cy-element={'Order'}>
            <table style={{width: '100%', tableLayout: "fixed"}}>
                <thead>
                <tr>
                    <th/>
                    <th/>
                    { multipleOrderBy && <th/> }
                </tr>
                </thead>
                <tbody>
                {
                    columns.map(c => (
                        <ProcedureRuleCollectionOrderBy
                            key={c.id}
                            ruleId={c.id}
                            ruleActionType={ruleActionType}
                            disabled={disabled}
                            multipleOrderBy={multipleOrderBy}
                        />))
                }
                </tbody>
            </table>
            {
                multipleOrderBy &&
                <AddAction
                    label={strings.procedure.rule.listViewDesignAddOrder}
                    onClick={handleAddOrderBy}
                    title={strings.procedure.rule.listViewDesignAddOrderTip}
                    canAdd={!disabled}
                    dataCy={'add-order'}
                />
            }
        </div>
    );
}
const styles = () => ({});
export default withStyles(styles)(ProcedureRuleCollectionOrderBys);
