import React, {Suspense, lazy} from "react";
import {PROCEDURE_TYPE_COLORS, RULE_ACTION_TYPE, PROCEDURE_THEME_FORMATS} from "../../../reducers/graphReducer";
import {
    useCallbackCreateOrRestoreRuleByFormat,
} from "../../../hooks/procedureHooks";
import FormLabel from "@mui/material/FormLabel";
import {strings} from "../../components/SopLocalizedStrings";
import ProcedureCompileMessages from "../ProcedureCompileMessages";
import { useCallbackPatchNode, useChildRuleByActionAndFormatOrNull, useNodeOrNull } from "../../../hooks/nodeHooks";
import Loader from "../../components/Loader";
import ResetIcon from '@mui/icons-material/Cached';
import { TbfAction } from "tbf-react-library";
import { makeStyles } from "@mui/styles";

const pageStrings = strings.procedure.settings.design;

const TbfColorPicker = lazy(() => import('../../components/common/TbfColorPicker'));

const ColorPicker = (props) => {
    return <Suspense fallback={<Loader circular circularSize={40} contentCentered />}>
        <TbfColorPicker {...props} />
    </Suspense>
};

const useStyle = makeStyles((theme) => ({
    inputContainer: {
        overflow: 'visible',
        position: 'relative',
        "& > *": {
            display: 'inline-flex',
            verticalAlign: 'middle',
        }
    },
    reset: {
        position: 'relative',
        marginLeft: theme.spacing(1),
    }
}));

const ProcedureThemeColorPicker = ({disabled, procedureId}) => {

    const procedure = useNodeOrNull(procedureId);

    const classes = useStyle();

    const rule = useChildRuleByActionAndFormatOrNull(procedureId, RULE_ACTION_TYPE.theme.id, PROCEDURE_THEME_FORMATS.colour.id);

    const patch = useCallbackPatchNode();

    const cb = useCallbackCreateOrRestoreRuleByFormat(procedureId, RULE_ACTION_TYPE.theme.id, PROCEDURE_THEME_FORMATS.colour.id);

    const defaultColor = PROCEDURE_TYPE_COLORS[procedure.procedureType];

    const handleChange = ({hex}) => {
        hex = JSON.stringify(hex);
        if (rule && !rule.deleted) {
            patch({id: rule.id, calculateValueQuery: hex});
        } else {
            cb({on: true, ruleAttributes: {calculateValueOn: true, calculateValueQuery: hex, format: PROCEDURE_THEME_FORMATS.colour.id}});
        }   
    }

    const handleResetToDefault = () => {
        cb({on: false, ruleAttributes: {calculateValueQuery: JSON.stringify(defaultColor)}});
    }

    const savedColor = JSON.parse(rule?.calculateValueQuery || null);

    return (
        <>
            <div data-cy-element={'Property'}>
                <FormLabel className={'sizeSmall'} htmlFor={'theme.color'}>
                    {pageStrings.color}
                </FormLabel>
                <div className={classes.inputContainer}>
                    <ColorPicker color={rule && !rule.deleted ? savedColor : defaultColor} onChange={handleChange} disabled={disabled} disableAlpha />
                    <TbfAction
                        className={classes.reset}
                        title={pageStrings.resetToDefault}
                        iconComponent={ResetIcon}
                        disabled={disabled}
                        onClick={handleResetToDefault}
                        iconButton
                    />
                </div>
            </div>
            {!!rule && <ProcedureCompileMessages nodeId={rule.id}/>}
        </>);
}

export default ProcedureThemeColorPicker;
