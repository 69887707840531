import React from 'react';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import withStyles from '@mui/styles/withStyles';
import {withMobile} from "tbf-react-library";
import MenuIcon from "@mui/icons-material/MoreVertRounded";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {strings} from "./SopLocalizedStrings";

class PageActions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuAnchor: null
        }
    }

    render() {
        const {actions, classes, mobileViewPort} = this.props;
        const {menuAnchor} = this.state;

        if (mobileViewPort) {
            return <React.Fragment>
                <IconButton
                    size={'small'}
                    color={'primary'}
                    title={strings.buttons.pageOptionMenu}
                    aria-controls="pageOptionMenu"
                    aria-haspopup="true"
                    data-cy='pageOptions'
                    onClick={(event) => this.setState({menuAnchor: event.currentTarget})}>
                    <MenuIcon/>
                </IconButton>

                <Menu
                    id='pageOptionMenu'
                    getContentAnchorEl={null}
                    anchorEl={menuAnchor}
                    keepMounted
                    open={Boolean(menuAnchor)}
                    onClose={() => this.setState({menuAnchor: null})}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    {
                        actions.filter(action => action.access).map((action, index) => {
                            switch (action.type) {
                                case 'link':
                                case 'icon':
                                    return <MenuItem key={index} component={Link} to={action.to}
                                                     data-cy={action.cypressName}>
                                        <div
                                            className={classes.listItemWrapper + ' ' + (action.divider ? classes.divider : '')}>
                                            <span className={classes.listItemIcon}>{action.icon}</span>
                                            <label className='listItemLabel'>{action.name}</label>
                                        </div>
                                    </MenuItem>;
                                case 'component':
                                    return <span key={index}
                                                 className={classes.component + ' ' + (action.divider ? classes.divider : '')}>{action.component}</span>;
                                default:
                                    return false;
                            }
                        })
                    }
                </Menu>
            </React.Fragment>
        }

        return <React.Fragment>
            {
                actions.filter(action => action.access).map((action, index) => {
                    switch (action.type) {
                        case 'link':
                            return <Link className={classes.actionButton} to={action.to} key={index}>
                                <Button title={action.name} color='primary' variant='outlined'
                                        data-cy={action.cypressName}>
                                    {action.icon}&nbsp;&nbsp;{action.name}
                                </Button>
                            </Link>;
                        case 'icon':
                            return <IconButton className={classes.actionButton}
                                               color={'secondary'}
                                               size={'small'}
                                               key={index}
                                               title={action.name}
                                               data-cy={action.cypressName}
                                               onClick={action.handleClick}>
                                {action.icon}
                            </IconButton>;
                        case 'component':
                            return <span key={index} className={classes.actionButton}>{action.component}</span>;
                        default:
                            return false;
                    }
                })
            }
        </React.Fragment>
    }
}

const
    styles = (theme) => ({
        actionButton: {
            marginLeft: 8
        },
        listItemWrapper: {
            color: theme.palette.grey.seven.main,
            fontSize: '14px',
            width: '100%',
            '& .listItemLabel': {
                verticalAlign: 'baseline'
            }
        },
        listItemIcon: {
            verticalAlign: 'middle',
            marginRight: 4,
            '& svg': {
                fontSize: '1.25rem'
            }
        },
        component: {
            padding: '10px 20px'
        },
        divider: {
            borderTop: `1px solid ${theme.palette.grey.two.main}`
        }
    });

export default withStyles(styles)

(
    withMobile()

    (
        PageActions
    ))
;
