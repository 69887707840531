import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {ComponentBase, cypress, getTime} from "tbf-react-library";

class TimerComponent extends ComponentBase {

    constructor(props, context) {
        super(props, context);
        this.state = {
            startTime: getTime(),
            currentTime: getTime()
        };
    }

    componentDidMount() {
        if (cypress.isTimerEnabled()) {
            this.interval = setInterval(() => {
                if (this.unmounted) {
                    return;
                }
                this.setState({
                    currentTime: getTime()
                })
            }, 100)
        }
    }


    componentWillUnmount() {
        super.componentWillUnmount();
        clearInterval(this.interval);
    }

    render() {
        let {startTime, currentTime} = this.state;
        let duration = currentTime - startTime;
        const Container = this.props.container || React.Fragment;

        if (!cypress.isTimerEnabled()) {
            return null;
        }

        return <Container>
            <span data-cy={'timer'}>
                {duration} ms
            </span>
        </Container>
    }
}

export const styles = () => ({});

export default withStyles(styles)(TimerComponent);