import React from 'react';
import {strings} from "../components/SopLocalizedStrings";
import {useNodeOrNull} from "../../hooks/nodeHooks";

const GraphDeleteMessage = ({nodeId}) => {
    const node = useNodeOrNull(nodeId)
    const isDeleted = node?.deleted === true && node?.destroyed !== true
    if (isDeleted) {
        return <div className={"alert alert-danger"}>{strings.resource.deletedMessage}</div>
    }
    return (<React.Fragment/>);
}
export default GraphDeleteMessage