import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../style/alert.css';
import {withRouter} from "react-router-dom";
import * as PropTypes from "prop-types";
import CallMadeIcon from '@mui/icons-material/CallMadeRounded';
import {Permissions} from "../../permissions";
import {strings} from "../components/SopLocalizedStrings";
import MaterialTable from "material-table";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Loader from "../components/Loader";
import {
    ComponentBase,
    MaterialTableHeaderWithCount,
    reportBusinessError,
    reportUserError,
    SharedAuth,
    TbfModal,
} from "tbf-react-library";
import {MenuItem, Paper} from "@mui/material";
import ExecutionLink from "../execution/ExecutionLink";
import {hasProcedurePermission} from "../../selectors/procedureSelectors";
import {pushProcedureUpdate} from "../../actions/procedures";
import CustomDataGrid from '../components/common/CustomDataGrid';

const columns = [
    {
        field: "executionTitle",
        title: "Execution",
        headerName: "Execution",
        renderCell: ({row}) => <ExecutionLink nodeId={row.executionId}>{row.executionTitle || '[Blank]'}</ExecutionLink>
    },
    {
        field: "pushResult",
        title: "Result",
        headerName: "Result",
        renderCell: ({row}) => <div className={'resultContainer'}>{row.pushResult}</div>
    },
];

class ProcedurePushDialog extends ComponentBase {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            pushing: false
        };
        this.pushedResults = [];
    }

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    parseResultsToRows = (data) => {
        return data.map(d => ({
            id: d.executionId,
            executionTitle: d.executionTitle,
            executionId: d.executionId,
            pushResult: d.pushResult
        }));
    }

    pushChangesToITP = () => {
        const {procedureId, unsavedChanges, hasCompileWarnings, handleClicked} = this.props;

        handleClicked && handleClicked();
        if (hasCompileWarnings) {
            reportBusinessError('Template cannot be pushed as it contains errors.');
            return false;
        }
        if (unsavedChanges) {
            reportBusinessError('Push is unavailable until all unsaved changes are saved.');
            return false;
        }

        this.setState({pushing: true});
        pushProcedureUpdate(procedureId).then(response => {
            if (response) {
                this.pushedResults = this.parseResultsToRows(response.data?.items ?? response.data);
                this.handleOpen();
            }
            this.setState({pushing: false});
        }).catch(error => {
            reportUserError(error, null, 'Push failed.');
            this.setState({pushing: false});
        });
    }

    render() {
        let {procedureType, canPush, classes} = this.props;
        let {open, pushing} = this.state;
        return (
            <React.Fragment>
                {
                    canPush &&
                    <MenuItem onClick={this.pushChangesToITP} data-cy='push-procedure'>
                        <span className={classes.listItemWrapper + ' ' + (pushing ? classes.disabled : '')}>
                            <span className={classes.iconWrapper}>
                                <CallMadeIcon/>
                                {
                                    pushing &&
                                    <Loader circular={true} circularSize={32}
                                            loaderStyleClass={classes.listItemFabProgress}
                                            source={'Procedure Push Dialog'}/>
                                }
                            </span>
                            {pushing ? strings.procedure.show.pushProgressMessage + '...' : strings.formatString(strings.procedure.show.pushButtonTooltip, procedureType)}
                        </span>
                    </MenuItem>
                }

                <TbfModal
                    open={open}
                    onClose={this.handleClose}>
                    <div className={classes.paper}>
                        <div className={classes.tableContainer + ' materialTableResponsiveWrapper'}>
                            <CustomDataGrid
                                className={classes.table}
                                columns={columns}
                                autoHeight
                                rows={this.pushedResults}
                                allowToggleDeleted={false}
                            />
                        </div>
                        <Toolbar disableGutters={true}>
                            <div className={classes.grow}/>
                            <Button color='secondary' variant='contained' className={classes.modalButton}
                                    onClick={this.handleClose} data-cy='add-link-cancel'>{strings.app.close}</Button>
                        </Toolbar>
                    </div>
                </TbfModal>
            </React.Fragment>
        );
    }
}

const styles = (theme) => ({
    paper: {
        position: 'absolute',
        width: '80%',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        padding: '30px 30px 10px 30px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    tableContainer: {
        width: '100%',
        maxHeight: 500,
        overflow: 'auto',
        '& .resultContainer': {
            maxHeight: 200,
            overflow: 'hidden'
        },
        '& .MuiToolbar-root': {
            paddingLeft: 14
        }
    },
    table: {
        border: "0 none !important",
    },
    modalButton: {
        margin: '20px -10px 0 0',
    },
    grow: {
        flexGrow: 1,
    },
    iconWrapper: {
        position: 'relative',
        width: 'auto',
        float: 'left',
        marginRight: 8,
        top: 1,
    },
    listItemWrapper: {
        color: theme.palette.grey.seven.main,
        fontSize: '14px',
        paddingTop: 5,
        width: '100%'
    },
    listItemFabProgress: {
        color: theme.palette.primary.one.main,
        position: 'absolute',
        top: -4,
        left: -4,
        zIndex: 1,
    },
    disabled: {
        pointerEvents: 'none',
        opacity: 0.7
    }
});
ProcedurePushDialog.propTypes = {
    procedureId: PropTypes.string.isRequired
};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.procedureId;
    let procedureNode = getNodeOrNull(state, nodeId);
    const canEdit = hasProcedurePermission(state, nodeId, Permissions.procedure.push);
    return {
        procedureType: procedureNode && procedureNode.procedureType,
        canPush: SharedAuth.userHasPermission(Permissions.feature.alpha) && canEdit
            && procedureNode && !procedureNode.destroyed && procedureNode.canComplete !== false,
        unsavedChanges: state.graph.pendingUserSaveNodeCount,
        hasCompileWarnings: procedureNode && procedureNode.compileWarnings && procedureNode.compileWarnings.length > 0
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProcedurePushDialog)));
