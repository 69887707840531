import React from 'react';
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBackRounded";
import withStyles from '@mui/styles/withStyles';
import ExecutionSearch from "../components/ExecutionSearch";
import {strings} from "../components/SopLocalizedStrings";

class MobileSearch extends React.Component {

    handleBack = () => {
        window.history.back();
    }

    render() {
        const {classes, history} = this.props;

        return (
            <div className={classes.mobileSearchContainer} role={'search'}>
                <div className={classes.header}>
                    <IconButton
                        color={'primary'}
                        className={classes.hideSearchBarButton}
                        onClick={() => this.handleBack()}
                        title={strings.buttons.hide}
                        data-cy={'hide-executionSearchBar'}
                        size="large">
                        <ArrowBackIcon/>
                    </IconButton>
                    <ExecutionSearch
                        propertyName={'execution-autocomplete'}
                        history={history}
                        label={'Search...'}
                        limit={10}
                        autoFocus={true}
                        dropDownAsSearch={true}
                        isMobileSearch={true}
                    />
                </div>
            </div>
        );
    }
}

export const styles = () => ({
    mobileSearchContainer: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        left: 0,
        padding: '10px 20px 10px 0',
        top: 0,
        background: '#FFF',
        zIndex: 9999,
        overflowX: 'hidden',
        overflowY: 'auto',
        '& .locationButton': {
            top: 3,
        },
        '& .select__input-container': {
            marginTop: 1,
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    hideSearchBarButton: {
        marginRight: 5
    },
});

export default withStyles(styles, {withTheme: true})(MobileSearch);
