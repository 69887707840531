import {objectIdNameToKeyValue} from "../../util/util";

export const MAP_SHAPE_TYPES = {
    marker: {id: 'marker', name: 'Marker', geometryType: 'Point'},
    circleMarker: {id: 'circleMarker', name: 'CircleMarker', geometryType: 'Point'},
    circle: {id: 'circle', name: 'Circle', geometryType: 'Point'},
    measure: {id: 'measure', name: 'Measure', geometryType: 'LineString'},
    polyline: {id: 'polyline', name: 'Polyline', geometryType: 'LineString'},
    polygon: {id: 'polygon', name: 'Polygon', geometryType: 'Polygon'},
    rectangle: {id: 'rectangle', name: 'Rectangle', geometryType: 'Polygon'},
    collection: {id: 'collection', name: 'Collection', geometryType: 'Collection'}
}
export const MAP_SHAPE_TYPE_OPTIONS = objectIdNameToKeyValue(MAP_SHAPE_TYPES);