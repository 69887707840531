import React from 'react';
import {connect} from "react-redux";
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import NodeTextField from "../graph/NodeTextField";
import SopDialog from "../components/SopDialog";
import Button from "@mui/material/Button";
import {Permissions} from "../../permissions";
import ProcedureRuleBuilderContainer from "./rules/ProcedureRuleBuilderContainer";
import {DIAGNOSTIC_MODES, NODE_IDS} from "../../reducers/graphReducer";
import {ComponentBase, SharedAuth} from "tbf-react-library";
import {strings} from "../components/SopLocalizedStrings";

class ProcedurePropertyVisibleRule extends ComponentBase {

    render() {
        let {nodeId, visibleRuleEnabled, visibleRuleQuery, name, disabled, showDiagnostics, diagnosticsOn} = this.props;
        return (
            <React.Fragment>
                <NodeTextField nodeId={nodeId} nodePropertyName={'visible'} disabled={disabled}/>
                {
                    visibleRuleEnabled &&
                    <div>
                        <NodeTextField nodeId={nodeId} nodePropertyName={'visibleRuleHuman'} disabled={true}/>
                        {
                            diagnosticsOn && showDiagnostics &&
                            <pre data-cy={'visibleRuleQuery'}>
                                {visibleRuleQuery}
                            </pre>
                        }
                        {
                            !disabled &&
                            <SopDialog title={`Edit Rule for "${name}"`} closeTitle={'Done'}
                                       action={(clickHandler) => (
                                           <Button variant="outlined" color="secondary" onClick={clickHandler}
                                                   title={strings.buttons.edit}
                                                   data-cy='edit-rule'>
                                               Edit rule
                                           </Button>
                                       )}
                                       renderChildren={() => (
                                           <ProcedureRuleBuilderContainer nodeId={nodeId}
                                                                          ruleProperty={'visibleRule'}/>
                                       )}
                            />
                        }
                    </div>
                }
            </React.Fragment>
        );
    }
}

const styles = () => ({});
ProcedurePropertyVisibleRule.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.nodeId;
    let node = getNodeOrNull(state, nodeId);
    return {
        visibleRuleEnabled: node.visibleRuleEnabled,
        visibleRuleQuery: node.visibleRuleQuery,
        name: node.name,
        showDiagnostics: SharedAuth.userHasPermission(Permissions.diagnostic.read),
        diagnosticsOn: getNodeOrNull(state, NODE_IDS.UserDevice).diagnosticMode === DIAGNOSTIC_MODES.full.id
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedurePropertyVisibleRule));
