import React from "react";
import NodeTextField from "../graph/NodeTextField";
import MessageType from "../components/MessageType";
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodeSchemaOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import FormLabel from "@mui/material/FormLabel";
import {ErrorBoundary} from "tbf-react-library";

const ProcedureMessageContent = ({
                                     classes,
                                     displayLabel,
                                     label,
                                     disabled,
                                     value,
                                     nodePropertyName,
                                     nodeSchema,
                                     messageType,
                                     ...rest
                                 }) => {
    const useLabel = displayLabel ? label || nodeSchema.properties[nodePropertyName].displayName : null;

    if (disabled) {
        return <ErrorBoundary>
            <FormLabel className={'sizeSmall'} htmlFor={nodePropertyName}>{useLabel}</FormLabel>
            <div className={classes.messageContainer}>
                <MessageType contentState={value} messageType={messageType}
                             customClass={classes.questionTypeMessage}/>
            </div>
        </ErrorBoundary>
    }

    return <NodeTextField disabled={disabled} nodePropertyName={nodePropertyName} {...rest}/>
}

ProcedureMessageContent.defaultProps = {
    displayLabel: true,
    disabled: false
};
const styles = theme => ({
    messageContainer: {
        whiteSpace: 'normal',
    },
    questionTypeMessage: {
        fontSize: 14
    },
});
const mapStateToProps = (state, {nodeId, nodePropertyName}) => {
    let node = getNodeOrNull(state, nodeId);
    let nodeSchema = getNodeSchemaOrNull(state, node.type);
    if (!nodeSchema.properties[nodePropertyName]) {
        throw new Error(`Node with id [${nodeId}] and type [${node.type}] does not have a definition for property [${nodePropertyName}].`)
    }
    return {
        messageType: node && node.messageType,
        value: node[nodePropertyName],
        nodeSchema: nodeSchema,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedureMessageContent));
