import React from "react";
import {Permissions} from "../../../permissions";
import {strings} from "../../components/SopLocalizedStrings";
import {useHasExecutionPermission} from "../../../hooks/executionHooks";
import TrackChangesIcon from '@mui/icons-material/TrackChangesRounded';
import AlphaFeature from "../../components/AlphaFeature";
import TbfSopAction from "../../components/common/TbfSopAction";
import { REPORT_EVENT_TYPES } from "../../../util/constants";

const ExecutionVersionsAction = React.forwardRef(({executionId, menuItem}, ref) => {
    const canView = useHasExecutionPermission(executionId, Permissions.execution.read)
    if (!canView) {
        return null
    }
    return (
        <AlphaFeature>
            <TbfSopAction
                menuItem={menuItem}
                title={strings.execution.show.viewVersions}
                dataCy='view-history'
                iconComponent={TrackChangesIcon}
                path={'/executions/' + executionId + '/versions'}
                ref={ref}
                nodeId={executionId}
                eventName={REPORT_EVENT_TYPES.executionPageMenuVersions.name}
            >
                {strings.execution.show.viewVersions}
            </TbfSopAction>
        </AlphaFeature>
    )
})
export default ExecutionVersionsAction;
