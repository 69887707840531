import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../../../style/alert.css';
import {clearSaveError, putNodeProperty} from "../../../../actions";
import {getDirty, getNodeOrNull} from "../../../../selectors/graphSelectors";
import * as PropTypes from "prop-types";
import {ComponentBase} from "tbf-react-library";
import {isOnline} from "../../../../util/util";
import ImageWithMarkup from '../../../ImageEditor/ImageWithMarkup';
import {CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import {useDirtyOrNull, useStoreDbFailed} from "../../../../hooks/nodeHooks";

const SaveLocalIndicator = ({ photoId }) => {
    // as photo and photo captured are stored on same tx, we can use either to check if stored
    const storeFailed = useStoreDbFailed(photoId);
    const dirtyNode = useDirtyOrNull(photoId);

    if(!dirtyNode || dirtyNode?.storedDb) {
        return <></>
    }

    let color = "#fff";
    if(storeFailed) {
        color = "errorRed"
    }

    return <Box sx={{ position: 'absolute', right:3, top: 3, display: 'inline-flex' }}>
        <SaveIcon color={color} sx={{fontSize: 18}} />
    </Box>
}

const UploadIndicator = ({isUploading, uploadPercentage, handleReupload, hasError}) => {
    const onReupload = (e) => {
        e.stopPropagation();
        handleReupload();
    }

    return <Box sx={{ position: 'absolute', right:2, bottom: 2, display: 'inline-flex' }}>
        {
            !hasError &&
            <>
                <CircularProgress data-cy={"upload-progress"} variant="determinate" size={28} value={uploadPercentage} sx={{color: "#fff"}} />
                <Box sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <FileUploadRoundedIcon fontSize={"18px"} sx={{fill: isUploading ? "#fff" : "#d3d3d3b8"}} />
                </Box>
            </>
        }

        {
            hasError &&
            <IconButton data-cy={"photo-retry"} onClick={onReupload} sx={{padding:0}} >
                <SyncProblemIcon  color={"errorRed"} />
            </IconButton>
        }

    </Box>
}

class ExecutionPhoto extends ComponentBase {
    handleReupload = () => {
        const {dirtyNode, reUpload} = this.props;
        reUpload(dirtyNode);
    }

    render() {
        const {classes, photoId, largeUrl, uploadPercentage, dirtyNode, photoCaptureId} = this.props;
        let uploadedPending = !largeUrl;
        const isAppOnline = isOnline();
        return (
            <React.Fragment>
              <div className={classes.imageContainer}>
                <ImageWithMarkup photoId={photoId} photoType='thumbnail' />
              </div>
              {
                  uploadedPending &&
                  <div className={classes.uploadStatusContainer}>
                      <SaveLocalIndicator photoId={photoCaptureId} />
                      <UploadIndicator hasError={!!dirtyNode?.saveErrorCount} handleReupload={this.handleReupload} isUploading={isAppOnline} uploadPercentage={uploadPercentage}/>
                  </div>
              }
            </React.Fragment>
        )
    }
}

const styles = (theme => ({
    uploadStatusContainer: {
        background: 'linear-gradient(#ffffff00, #6868682e, #000000bf)',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        borderRadius: 4,
        border: `1px solid ${theme.palette.grey.three.main}`,
    },
    uploadedIcon: {
        color: '#B41C8B',
    },
    errorIcon: {
        color: theme.palette.grey.three.main,
        verticalAlign: 'middle'
    },
    uploadIcon: {
        color: theme.palette.secondary.two.main,
        verticalAlign: 'middle'
    },
    actionButton: {
        color: theme.palette.grey.two.main,
        verticalAlign: 'middle'
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    photo: {
        width: '100% !important',
        height: '100% !important',
        objectPosition: 'center',
        objectFit: 'cover',
        borderRadius: 4,
        '&:hover': {
            opacity: 0.9,
        }
    },
    downloadPhotoContainer: {
        position: 'absolute',
        left: 0,
        bottom: 0
    },
    iconWrapper: {
        position: 'relative',
    },
    fabProgress: {
        color: theme.palette.secondary.three.main,
        position: 'absolute',
        top: 4,
        left: 4,
        zIndex: 1,
    },
    disabledBtn: {
        pointerEvents: 'none',
        opacity: 0.8
    },
    marginLeft5: {
        marginLeft: 5
    },
    deleteButton: {
        padding: 3,
        borderRadius: 4,
        fontSize: 12,
        width: '100%',
        color: theme.palette.grey.five.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& svg': {
            fontSize: 12,
            border: `1px solid ${theme.palette.grey.five.main}`,
            borderRadius: '50%',
        }
    },
    imageContainer: {
      width: 82,
      height: 82,
      position: 'relative'
    }
}));

ExecutionPhoto.propTypes = {
    executionQuestionId: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    photoId: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
    let question = getNodeOrNull(state, ownProps.executionQuestionId);
    const photoIds = question[ownProps.field + 'PhotoIds'];
    const photo = getNodeOrNull(state, ownProps.photoId);
    const dirtyNode = getDirty(state, ownProps.photoId);

    const uploadPercentage = photo.uploadCompletedBytes ? Math.floor((photo.uploadCompletedBytes * 100) / photo.uploadTotalBytes) : 0;

    return {
        attachmentType: question && question.attachmentType,
        questionId: question && question.id,
        rootId: question && question.rootId,
        photoIds: photoIds,
        photoId: photo && photo.id,
        largeUrl: photo && photo.largeUrl,
        uploadPercentage,
        dirtyNode,
        photoCaptureId: photo?.photoCaptureId,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        reUpload: node => dispatch(clearSaveError(node)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionPhoto));
