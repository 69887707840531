import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {resetGraph} from "../../actions";
import {connect} from "react-redux";
import {TbfAction} from "tbf-react-library";

class GraphClear extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.handleClear = this.handleClear.bind(this);
    }

    handleClear() {
        this.props.resetGraph();
    }

    render() {
        return (
            <TbfAction
                onClick={this.handleClear}
                title={'Clear all data in indexeddb and redux'}
                data-cy={'reset-redux'}
                primaryAction={true}
            >
                Reset
            </TbfAction>
        )
    }
}

const styles = () => ({});
GraphClear.propTypes = {};
const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        resetGraph: () => dispatch(resetGraph())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphClear));
