import React from 'react';
import 'date-fns';
import '../../../../style/alert.css';
import ExecutionQuestionInputBase from "./ExecutionQuestionInputBase";

class ExecutionQuestionSelectResizeBase extends ExecutionQuestionInputBase {

    constructor(props) {
        super(props);
        this.selectorRef = React.createRef();
        this.state = {parentWidth:0};
    }

    componentDidMount() {
        this.setParentWidth();
        window.addEventListener('resize', this.setParentWidth, true);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setParentWidth, true);
    }

    setParentWidth = () => {
        const parentWidth = this.selectorRef?.current?.offsetWidth;
        this.setState({
            parentWidth
        })
    }
}

export default ExecutionQuestionSelectResizeBase;
