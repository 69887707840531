import moment from "moment";
import stringify from "json-stable-stringify";
import isEqual from "react-fast-compare";
import {getTime, reportDeveloperWarning, SharedAuth, tbfLocalStorage} from "tbf-react-library";
import stringSimilarity from "string-similarity-js";
import { parsePhoneNumber } from "react-phone-number-input";

export const objectify = (obj, [k, v]) => {
    obj[k] = v;
    return obj
};

export const objectIdNameToKeyValue = (objects) => {
    let data = {};
    Object.values(objects).forEach(a => data[a.id] = a.name);
    return data;
};
export const objectIdNameToNameLabel = (objects) => {
    return Object.values(objects).map(a => ({value: a.id, label: a.name}));
};

export const keyedObjectIdNameToNameLabel = (objects) => {
    return Object.keys(objects).reduce((map, current) => {
        const obj = objects[current];
        map[current] = {value: obj.id, label: obj.name};
        return map;
    }, {});
}

export const stripAfter = (input, pattern, index) => {
    let t = 0;
    return input.replace(pattern, match => {
        return t++ <= index ? match : ""
    });
};

export const dataURItoBlob = (dataURI) => {
    let first = dataURI.split(',');
    var mime = first[0].split(':')[1].split(';')[0];
    var binary = atob(first[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: mime});
};

export const createImageObjectUrl = (dataUrl) => {
    return URL.createObjectURL(dataURItoBlob(dataUrl));
};
export const blobToDataURL = (blob) => {
    return new Promise((accept, reject) => {
        var a = new FileReader();
        a.onload = function (e) {
            accept(e.target.result);
        };
        a.onerror = function (e) {
            reject(e);
        };
        a.readAsDataURL(blob);
    });
};

export const imageToDataUrl = (url) => {
    return new Promise(function (resolved) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                resolved(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    })
}

export const diff = (a, b) => {
    let properties = [];
    properties.push(...Object.keys(a));
    properties.push(...Object.keys(b));
    let unique = new Set(properties);
    let diff = [];
    for (let property of unique) {
        if (a[property] !== b[property] && JSON.stringify(a[property]) !== JSON.stringify(b[property])) {
            diff.push(property + ': ' + a[property] + ' -> ' + b[property])
        }
    }
    return diff;
};
export const intersect = (a, b) => {
    return a.filter(function (e) {
        return b.indexOf(e) > -1;
    });
};

/**
 * This rule is used in procedure business rules and cannot be modified.
 *
 * Treating doesIntersect([],[]).
 *
 * @param a Item or array of Items
 * @param b Item or array of Items
 * @returns {*}
 */
export const doesIntersect = (a, b) => {
    if (!Array.isArray(a)) {
        a = a === null || a === undefined ? [] : [a];
    }
    if (!Array.isArray(b)) {
        b = b === null || b === undefined ? [] : [b];
    }
    // Convert bool to string as rule may compare string 'true' to boolean true
    a = a.map(x => x == null ? null : x.toString());
    b = b.map(x => x == null ? null : x.toString());
    return intersect(a, b).length > 0;
};
window.doesIntersect = doesIntersect;
export const makeArray = x => {
    if (x === null || x === undefined) {
        return [];
    }
    if (Array.isArray(x)) {
        return x;
    }
    if (x instanceof Set) {
        return [...x];
    }
    return [x];
}
export const mergeUnique = (a, b) => {
    const aArray = makeArray(a);
    const bArray = makeArray(b);
    // Preserve order
    let newList = [];
    let seenItems = {};
    for (let itemA of aArray) {
        if (typeof (itemA) === 'string' || typeof (itemA) === 'number') {
            if (seenItems[itemA]) {
                continue;
            }
            seenItems[itemA] = true;
            newList.push(itemA);
        } else if (!newList.includes(itemA)) {
            newList.push(itemA);
        }
    }
    let bItemAdded = false;
    for (let itemB of bArray) {
        if (typeof (itemB) === 'string' || typeof (itemB) === 'number') {
            if (seenItems[itemB]) {
                continue;
            }
            seenItems[itemB] = true;
            bItemAdded = true;
            newList.push(itemB);
        } else if (!newList.includes(itemB)) {
            bItemAdded = true;
            newList.push(itemB);
        }
    }
    const listsSame = newList.length === aArray.length && !bItemAdded;
    if (listsSame) {
        return aArray;
    }
    return newList;
};
export const uniqueArray = array => [...new Set(array)];
export const arrayMinus = (a, b) => {
    return a.filter(x => b.indexOf(x) === -1);
}
export const arrayMinusItem = (a, b) => {
    if (!a.includes(b)) {
        return a;
    }
    return a.filter(x => x !== b);
}
export const firstOrNull = array => {
    if (!array || !array.length) {
        return null;
    }
    return array[0];
}
export const formatDate = (date) => {
    if (date == null) {
        return null;
    }
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return moment(date).format("DD-MM-YYYY HH:mm:ss");
};
export const formatFullDate = (date) => {
    if (!hasValue(date)) {
        return '-';
    }

    if (typeof date === 'string') {
        date = new Date(date);
    }
    return moment(date).format("MMMM DD, YYYY, hh:mm A");
};
export const formatDay = (date) => {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return moment(date).format("dddd MMMM DD, YYYY");
};

export const formatTime = (date) => {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return date.toLocaleTimeString();
};

export const formatUser = (user) => {
    let index = user !== null && user !== undefined && user.indexOf(':') >= 0;
    if (index) {
        return user.substr(user.indexOf(':') + 1);
    }
    return user;
};

export const parseRule = (valueBy) => {
    const index = valueBy !== null && valueBy !== undefined && valueBy.indexOf(':') >= 0;
    if (!index) return {};
    const sourceAndRule = valueBy.substr(valueBy.indexOf(':') + 1);
    const [sourceId, ruleId] = sourceAndRule.split("/");
    return {sourceId, ruleId};
}

export const isNotBlank = (str) => {
    return str !== null && str !== undefined && str.trim() !== ''
};
export const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
};
/**
 * Used to suppress no-unused-vars warning
 * @param x
 */
export const nop = (x) => {
    const time = getTime();
    if (x < time && time < 0) {
        console.info('NOP This will never run');
    }
};

export const formatPercentage = (ratio) => {
    return Math.round(ratio * 100 || 0);
};
/**
 * Convert from various formats into a json date string
 * @param dt Date
 * @returns {string|null|*}
 */
export const dateToJson = (dt) => {
    if (!hasValue(dt)) {
        return null;
    }
    if (dt === 'null') {
        reportDeveloperWarning('Date should not be string "null"');
        return null;
    }
    if (dt instanceof Date) {
        return dt.toISOString();
    }
    if (dt === Infinity) {
        throw new Error("Date is Infinity, why?");
    }
    if (dt instanceof Number || typeof (dt) == 'number') {
        return new Date(dt).toISOString();
    }
    if (dt instanceof String || typeof (dt) === 'string') {
        return dt;
    }
    throw new Error("Unknown date format [" + dt + "] with type " + typeof (dt) + ".");
}
export const getDate = () => {
    // Unit Test overrides Date.now
    return new Date(Date.now());
};
export const getStandardDate = () => {
    // Unit Test overrides Date.now
    return moment().format('YYYYDDMM-HHmm');
};
export const getJsonDate = () => {
    const now = getDate()
    return now.toISOString();
}
export const arrayMove = (arr, fromIndex, toIndex) => {
    const toArr = [...arr];
    const element = arr[fromIndex];
    toArr.splice(fromIndex, 1);
    toArr.splice(toIndex, 0, element);
    return toArr;
};
export const arrayRemoveItem = (items, item) => {
    let index = items.indexOf(item);
    return arrayRemove(items, index);
};
export const arrayRemove = (items, i) => {
    return items.slice(0, i).concat(items.slice(i + 1, items.length));
};

export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
}

export const existsInArrayByKey = (arr, key, value) => {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i][key] === value) {
            return i;
        }
    }
    return -1;
}

export const isOnline = () => {
    // if token is expired then set force offline mode
    if (SharedAuth.hasTokenExpiredFlag() && !SharedAuth.isAnonymous()) {
        return false;
    }
    let mockedOnline = tbfLocalStorage.getItem('perm_mock_online');
    if (mockedOnline === "false") {
        return false;
    }
    if (window.navigator.onLine === false) {
        return false;
    }
    return true;
};

export const getCurrentHost = () => {
    let location = window.location;
    return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
};

export const stableStringify = obj => {
    return stringify(obj, {space: '  '});
};

export const hasItems = list => {
    return list && list.length > 0;
};
export const dynamicSort = (property) => {
    let sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

const sortByDate = (a, b) => new Date(b).valueOf() - new Date(a).valueOf();

const getDayForEvent = (event, dateExtractor) => {
    const extractedDate = dateExtractor(event);
    const date = new Date(extractedDate);
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
    return date.toISOString();
};

const groupByDay = (events, dateExtractor) => {
    const groups = events.reduce((days, event) => {
        const day = getDayForEvent(event, dateExtractor);
        if (!days[day]) {
            days[day] = [];
        }
        days[day] = days[day].concat(event);
        return days;
    }, {});
    return {
        days: Object.keys(groups).sort(sortByDate) || [],
        eventsByDay: groups,
    };
};

export default groupByDay;

export const whatChanged = (name, prevProps, currentProps) => {
    let keys = new Set([...Object.keys(prevProps), ...Object.keys(currentProps)]);
    let differentKeys = [];
    let differentButSameKeys = [];
    for (let key of keys) {
        let different = prevProps[key] !== currentProps[key];
        let butSame = isEqual(prevProps[key], currentProps[key]);
        if (different) {
            if (butSame) {
                differentButSameKeys.push(key);
            } else {
                differentKeys.push(key);
            }
        }
    }
    if (differentButSameKeys.length > 0) {
        console.info(name + ': Keys are different yet equal: ' + differentButSameKeys.join());
    }
    if (differentKeys.length > 0) {
        console.info(name + ': Keys are different: ' + differentKeys.join());
    }
}
export const parseDate = (value, format) => {
    // Not using isNaN as it returns true when its a string
    if (value === null || value === undefined || value === NaN || !hasValue(value)) { /* eslint-disable-line use-isnan */
        return null;
    }
    if (value instanceof Date) {
        return value;
    }
    // Only digits, assume js time
    if (value.match && value.match(/^\d+$/)) {
        return new Date(Number.parseInt(value));
    }
    if(!format) {
        return moment(value).toDate();
    } else {
       return  moment(value, format).toDate();
    }
}

export const tryParseValue = (value) => {

    const dateTimeRegex = /^\d{2}\/\d{2}\/\d{4} \d{1,2}:\d{2} (AM|PM)$/;
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    const timeRegex = /^\d{1,2}:\d{2} (AM|PM)$/;

    // regex to check datettime format eg. 28/12/2023 2:00 PM
    if (dateTimeRegex.test(value)) {
        return parseDate(value, "DD/MM/YYYY h:mm A");
    }
    // regex to check date format eg. 11/12/2023
    if (dateRegex.test(value)) {
        return parseDate(value, "DD/MM/YYYY");
    }
    // regex to check time format eg. 2:00 PM
    if (timeRegex.test(value)) {
        return moment(value, "h:mm A").format("HH:mm");
    }

    // parse number with format eg. 12.00 km
    const number = (value?.split(" ")[0])?.replace(",","");
    if(!isNaN(number)) {
        return Number.parseFloat(number);
    }

    return value;

}

export const isRecent = (dt, ms) => {
    if (!hasValue(dt)) {
        return false;
    }
    let parsedDt = parseDate(dt);
    let recentDt = getTime() - ms;
    return parsedDt.getTime() >= recentDt;
}
export const isDateGreaterThan = (left, right) => {
    if (left == null || right == null) {
        return false;
    }
    let leftDate = moment(left);
    let rightDate = moment(right);
    return leftDate > rightDate;
};
export const isDateGreaterThanOrEqual = (left, right) => {
    if (left == null || right == null) {
        return false;
    }
    let leftDate = moment(left);
    let rightDate = moment(right);
    return leftDate >= rightDate;
}
export const getMaxDate = (left, right) => {
    let leftDate = (left && moment(left).toDate()) || null;
    let rightDate = (right && moment(right).toDate()) || null;
    if (leftDate == null) {
        return rightDate;
    }
    if (rightDate == null) {
        return leftDate;
    }
    return leftDate >= rightDate ? leftDate : rightDate;
}
export const getMaxJsonDate = (left, right) => {
    let max;
    if (Array.isArray(left)) {
        if (left.length === 0) {
            return null;
        }
        max = left[0];
        for (let n of left) {
            max = getMaxDate(max, n);
        }
    } else {
        max = getMaxDate(left, right);
    }
    return (max && max.toISOString()) || null;
}

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};
export const hasValue = value => {
    if (value === undefined || value === null || value === '') {
        return false;
    }
    if (Array.isArray(value)) {
        return value.length > 0;
    }
    if (value && typeof value === 'string' && value.trim() === '') {
        return false;
    }
    return true;
};
export const defaultValue = (value, useAsDefault) => {
    if (value === null || value === undefined) {
        return useAsDefault;
    }
    return value;
}

export const truncateString = (str, n) => {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
};

export const getTextAreaRows = (value, defaultRows = 1) => {
    if (value) {
        const valRowLength = value.split('\n').length;
        return valRowLength > defaultRows ? valRowLength : defaultRows;
    }
    return defaultRows;
}

export const percentage = (completedValue, totalValue) => {
    // To avoid divide by zero lets go with 100% done when 0
    return totalValue === 0 ? 0 : (100 * completedValue) / totalValue;
}

export const getNameInitials = (name) => {
    if (!name) {
        return '-';
    }
    const nameSplit = name.split(' ');
    return nameSplit[0][0] + (nameSplit[1] ? nameSplit[1][0] : '');
}

export const parseIntOrNull = (text) => {
    let n = parseInt(text);
    if (isNaN(n)) {
        return null;
    }
    return n;
}
export const parseFloatOrNull = (text) => {
    let n = parseFloat(text);
    if (isNaN(n)) {
        return null;
    }
    return n;
}

export const takeWhile = (fn, arr) => {
    const [x, ...xs] = arr;

    if (arr.length > 0 && fn(x)) {
        return [x, ...takeWhile(fn, xs)]
    } else {
        return [];
    }
};

export const getKeyFieldQuestionIds = (procedure) => {
    if (!procedure) {
        return [];
    }

    let questionIds = [];

    for (let k in procedure) {
        if (k.includes('keyField') && k.includes('QuestionId') && procedure[k] !== null) {
            questionIds.push(procedure[k]);
        }
    }
    return questionIds;
}

export const formatDateAge = (date) => {
    let dt = hasValue(date) ? moment(date).fromNow() : '-';
    if (dt === 'Invalid date') {
        console.error("Invalid date format for date: " + date)
    }
    return dt;
}

export const getDateTimeLocalString = (date) => {
    return hasValue(date) ? moment(date).format('LLLL') : '-';
}

export const isNewExecution = (createdDate) => {
    return moment(createdDate).isAfter(moment().subtract(1, 'hours'));
}

export const convertOptionObjectToSelectOptions = (optionsObject) => {
    return Object.values(optionsObject).map(d => ({label: d.name, value: d.id}));
}

export const stringInsert = (str, index, toInsert) => {
    return str.slice(0, index) + toInsert + str.slice(index);
}

export const isAbsoluteUrl = (str) => {
    const r = new RegExp('^(?:[a-z+]+:)?//', 'i');
    return !!r.test(str);
}

export const isValidURL = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export const elementIsInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    if (!rect) {
        return false;
    }
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export const randomString = () => {
    return (Math.random() + 1).toString(36).substring(7);
}

export const inArray = (arr, elem) => {
    return arr.indexOf(elem) !== -1;
}

export const searchAndSortByRank = (searchTerm, items, matchKey) => {
    let ranked = [];
    let termsCleaned = searchTerm.trim().toLowerCase();
    let terms = termsCleaned.split(' ');
    let isTextMatch = (term, text) => text && text.toLowerCase().includes(term);
    for (let option of items) {
        let isMatch = true;
        for (let term of terms) {
            isMatch = isMatch && (isTextMatch(term, option[matchKey]) || (option.parents && option.parents.find(p => isTextMatch(term, p[matchKey]))));
        }
        if (isMatch) {
            let startsWithMatch = option[matchKey].toLowerCase().startsWith(termsCleaned);
            let textSimilarity = stringSimilarity(termsCleaned, option[matchKey]);
            let score = textSimilarity + (startsWithMatch ? 1 : 0);
            ranked.push({option: option, rank: score});
        }
    }
    ranked.sort(dynamicSort('-rank'));
    return ranked.map(a => a.option);
}

/**
 * 
 * @param {string} phoneNumber 
 * @param {string} countryCode 
 * @returns {string | undefined} formattedPhoneNumber - Phone number with calling code
 */
export const upgradePhoneNumber = (phoneNumber, countryCode) => {
    return parsePhoneNumber(phoneNumber, {defaultCountry: countryCode})?.number;
}

export const sortAlphaNumeric = (a, b) => {
    if (a == null && b == null) {
        return 0;
    }
    if (a == null && b != null) {
        return 1;
    }
    if (b == null && a != null) {
        return -1;
    }
    if (a && a.localeCompare) {
        return a.localeCompare(b, 'en', {numeric: true})
    }
    return a > b ? 1 : -1;
}

export const copyCanvasContentsToClipboard = (canvas, onDone, onError) => {
    const {ClipboardItem} = window;
    canvas.toBlob((blob) => {
        let data = [new ClipboardItem({[blob.type]: blob})];
        navigator.clipboard.write(data).then(onDone, onError);
    }, 'image/jpg', 0.85);
}

export const strSpiltByCamelCase = (str) => {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export const cleanLocationHash = hash => {
    if (hash == null || hash === '') {
        return null;
    }
    return hash.substring(1);
}

export const scrollToElement = (element, scrollElem) => {
    const headerContainer = !scrollElem && document.getElementById('app-bar');
    const headerHeight = headerContainer ? headerContainer.offsetHeight + 15 : 0;
    const isElementInView = element ? element.getBoundingClientRect().y >= headerHeight : false;
    if (!isElementInView) {
        const scrollContainer = scrollElem || window;
        const topOfElement = scrollContainer.pageYOffset + element.getBoundingClientRect().top - headerHeight;
        scrollContainer.scroll({top: topOfElement, behavior: "smooth"});
    }
    if (element) {
        const taskContainerPanel = element.closest('.taskContainerPanel');
        const taskContainerPanelHeader = taskContainerPanel?.querySelector('.taskContainerPanelHeader');
        if (taskContainerPanelHeader && taskContainerPanelHeader.getAttribute('aria-expanded') !== 'true') {
            taskContainerPanelHeader.click();
        }
    }
};

export const highlightIncompleteFieldsInForm = (form, scrollElem) => {
    const fieldNotComplete = form ? form.getElementsByClassName('field-not-complete') : null;
    if (fieldNotComplete && fieldNotComplete[0]) {
        scrollToElement(fieldNotComplete[0], scrollElem);
        if (!fieldNotComplete[0].classList.contains('field-not-complete-highlight')) {
            fieldNotComplete[0].classList.add('field-not-complete-highlight');
            setTimeout(() => {
                fieldNotComplete[0] && fieldNotComplete[0].classList.remove('field-not-complete-highlight');
            }, 4000);
        }
    } else {
        const incomplete = document.getElementsByClassName('input-incomplete');
        if (incomplete.length > 0) {
            scrollToElement(incomplete[0], scrollElem);
        }
    }
}

export const stripNulls = obj => {
    if (obj != null) {
        for (let p of Object.keys(obj)) {
            if (obj[p] == null) {
                delete obj[p];
            }
        }
    }
    return obj;
}

export const removeAfterCharacter = (str, char) => {
    const n = str.indexOf(char);
    return str.substring(0, n !== -1 ? n + 1 : str.length);
}
export const isDraftJsString = str => {
    if (str == null) {
        return true;
    }
    if (typeof str === 'string') {
        if (str.startsWith('{"blocks":[')) {
            return true;
        }
        if (str.startsWith('{')) {
            reportDeveloperWarning('Is this DraftJs?', {value: str})
            return true;
        }
        return false;
    }
    return false;
}
export const toDraftJs = (content) => {
    const useStr = content?.toString() || ''
    const obj = {
        blocks: [
            {
                key: "2av15",
                text: useStr,
                type: "unstyled",
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {}
            }
        ],
        entityMap: {}
    }
    return JSON.stringify(obj);
}

export function getBrowserVisibilityProp() {
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      return "visibilitychange"
    } else if (typeof document.msHidden !== "undefined") {
      return "msvisibilitychange"
    } else if (typeof document.webkitHidden !== "undefined") {
      return "webkitvisibilitychange"
    }
  }
  
  export function getBrowserDocumentHiddenProp() {
    if (typeof document.hidden !== "undefined") {
      return "hidden"
    } else if (typeof document.msHidden !== "undefined") {
      return "msHidden"
    } else if (typeof document.webkitHidden !== "undefined") {
      return "webkitHidden"
    }
  }
  
  export function getIsDocumentHidden() {
    return document[getBrowserDocumentHiddenProp()]
  }

export const encodeUrlLastPart = (url) => {
    if (!url) {
        return;
    }

    const urlParts = url.split('/');
    const lastPart = urlParts.pop();
    const encodedLastPart = `/${encodeURIComponent(lastPart)}`;
    return `${urlParts.join('/')}${encodedLastPart}`;
}

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1000
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const calculateTextSize = ({fontStyle = "normal", fontSize = 16, fontFamily = "sans-serif", text}) => {
    const font = `${fontStyle} ${fontSize}px ${fontFamily}`;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

export const isFunction = function (obj) {
    return !!(obj && obj.constructor && obj.call && obj.apply);
};

export const resultOrInvoke = arg => {
    if (isFunction(arg)) {
        return arg()
    }
    return arg
}

export const isNullOrUndefined = (value) => {
    return value === null || value === undefined;
}

export const mergeSet = (a, b) => {
    if (!a || !b) return;
    b.forEach(i => a.add(i), b)
};