import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodesOrError} from "../../../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../../../style/alert.css';
import {ComponentBase} from "tbf-react-library";
import {EMPTY_ARRAY} from "../../../../util/util";
import RenderDraftJs from "../../../components/RenderDraftJs";

class ExecutionQuestionRuleCommentInstructions extends ComponentBase {

    render() {
        let {rules, commentInstructions, classes} = this.props;
        if (rules.length === 0 && !commentInstructions) {
            return null;
        }
        return (
            <label className={classes.commentContainer}>
                {
                    commentInstructions &&
                    <label className={classes.comments + ' ' + classes.fontSize12}>
                        {commentInstructions}
                    </label>
                }
                {rules.map((item, index) => (
                    <label key={index} className={classes.comments}>
                        <RenderDraftJs contentState={item.commentInstructionsMessage} key={item.id} ruleId={item.id}/>
                    </label>
                ))}
            </label>);
    }
}

const styles = () => ({
    commentContainer: {
        display: 'block',
        marginBottom: 4,
        position: 'relative',
    },
    fontSize12: {
        fontSize: 12,
    },
    comments: {
        display: 'block',
        '& p': {
            marginBottom: 4,
            '&:first-child': {
                marginBottom: 0,
            }
        }
    },
});
ExecutionQuestionRuleCommentInstructions.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    if (!executionQuestionNode) {
        return {
            rules: []
        }
    }
    let activeRules = [];
    if (ownProps.field === 'initial') {
        let allRules = getNodesOrError(state, executionQuestionNode.ruleIds || EMPTY_ARRAY);
        activeRules = allRules
            .filter(a => a.evaluatedValue === true)
            .filter(a => a.commentInstructionsOn);
    }
    return {
        rules: activeRules,
        commentInstructions: executionQuestionNode[ownProps.field + 'CommentInstructions'] || null
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionRuleCommentInstructions));
