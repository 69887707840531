import React from "react";
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import {summaryToFullId} from "../../../../factory/executionFactory";
import GraphResourceLoad from "../../../graph/GraphResourceLoad";
import {NODE_IDS} from "../../../../reducers/graphReducer";
import {strings} from "../../../components/SopLocalizedStrings";
import {HOURS_1} from "../../../../util/constants";
import ExecutionActionButton from "../../../execution/actions/ExecutionActionButton";

export default function BulkActionCustom({action, selectedRowIds}) {


    const selectedFullIds = selectedRowIds?.map(summaryToFullId);

    return <>
        {action.procedureId &&
            <GraphResourceLoad
                key={action.procedureId}
                resourcePath={NODE_IDS.ProcedureFull(action.procedureId)}
                nodeId={action.procedureId}
                friendlyName={strings.procedure.name}
                nodeType={'ProcedureRoot'}
                reloadIntervalMs={HOURS_1}
            />
        }
        {
            action.visible &&
            <ExecutionActionButton
                key={action.id}
                ruleId={action.id}
                procedureId={action.procedureId}
                buttonLabel={action.name}
                executionIds={selectedFullIds}
                bulkActionItem={true}
                icon={<ControlPointDuplicateIcon/>}
            />
        }
    </>
}