import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodesByCondition} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import ProjectShowProcedures from "./ProjectShowProcedures";
import PageComponentBase from "../PageComponentBase";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import PhoneIcon from '@mui/icons-material/PhoneRounded';
import EmailIcon from '@mui/icons-material/MailOutlineRounded';
import IconButton from "@mui/material/IconButton";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {Permissions} from "../../permissions";
import EditIcon from '@mui/icons-material/EditRounded';
import ProjectOfflineButton from "./ProjectOfflineButton";
import {
    DIAGNOSTIC_MODES,
    NODE_IDS,
    NODE_TYPE_OPTIONS,
    PROCEDURE_TYPES
} from "../../reducers/graphReducer";
import JSONPretty from "react-json-pretty";
import Info from "@mui/icons-material/InfoRounded";
import Grid from "@mui/material/Grid";
import {strings} from "../components/SopLocalizedStrings";
import NodeVisited from "../graph/GraphNodeVisited";
import {
    Breadcrumbs,
    HistoryBackButton,
    PageContentWrapper,
    PageHeader,
    ProjectIcon,
    SharedAuth
} from "tbf-react-library";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {putNodeProperty} from "../../actions";
import PageActions from "../components/PageActions";
import RenderDraftJs from "../components/RenderDraftJs";

const pageStrings = strings.project.show;

class ProjectShow extends PageComponentBase {

    constructor(props) {
        super(props);

        this.state = {
            showExport: false,
            showJson: false
        };
        this.breadcrumbs = [{name: strings.project.namePlural, to: '/projects'}];
        this.toggleShowJson = this.toggleShowJson.bind(this);
    }


    componentDidUpdate() {
        const {projectTypeExecution, history} = this.props;
        if(projectTypeExecution) {
            history.push(`/list/project/${projectTypeExecution.procedureId}`);
            history.push(`/executions/${projectTypeExecution.rootId}`);
        }
    }
    toggleShowJson() {
        this.setState(state => ({showJson: !state.showJson}));
    }

    handleTabChange = (event, value) => {
        let {projectNode, onPutNodeProperty} = this.props;
        onPutNodeProperty({id: projectNode.id, selectedCategory: value});
    };

    renderTabs = () => {
        const {projectNode, categories} = this.props;
        const selectedCategoryIndex = projectNode.selectedCategory || 0;
        categories.sort();

        return (
            <Tabs
                value={selectedCategoryIndex}
                onChange={this.handleTabChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                >
                {categories.map(category => (
                    <Tab key={category} label={category}/>
                ))}
            </Tabs>
        );
    }

    getPageActionList = () => {
        let {projectNode, diagnosticsOn} = this.props;
        return [
            {
                name: pageStrings.editButton,
                access: SharedAuth.userHasPermission(Permissions.project.edit),
                to: `/projects/${projectNode.id}/edit`,
                cypressName: 'edit-project',
                icon: <EditIcon/>,
                type: 'link'
            },
            {
                name: pageStrings.addExecutionButton,
                access: SharedAuth.userHasPermission(Permissions.execution.create),
                to: `/projects/${projectNode.id}/execution/add`,
                cypressName: 'add-execution',
                icon: <AddRoundedIcon/>,
                type: 'link'
            },
            {
                name: 'Toggle JSON view',
                access: diagnosticsOn,
                cypressName: 'toggle-diagnostics',
                icon: <Info/>,
                type: 'icon',
                handleClick: this.toggleShowJson
            },
            {
                divider: true,
                access: true,
                type: 'component',
                component: <ProjectOfflineButton projectId={projectNode.id}/>
            }
        ];
    }

    renderProjectDetails = () => {
        let {projectNode, classes} = this.props;
        let {showJson} = this.state;

        return <>
            {
                projectNode.deleted &&
                <div
                    className={"alert alert-danger"}>{strings.resource.deletedMessage}</div>
            }
            {
                showJson &&
                <Grid item xs={12}>
                    <JSONPretty id="json-pretty" data={projectNode}/>
                </Grid>
            }
            <p>
                {
                    projectNode.designerName &&
                    <span className={classes.nameLabel}>Designer: {projectNode.designerName}</span>
                }
                {
                    projectNode.designerEmail &&
                    <a href={`mailto:${projectNode.designerEmail}`} className={classes.actionIconButton}>
                        <IconButton color={'primary'} size={'small'} title={strings.buttons.email}>
                            <EmailIcon/>
                        </IconButton>
                    </a>
                }
                {
                    projectNode.designerPhoneNumber &&
                    <a href={`tel:${projectNode.designerPhoneNumber}`} className={classes.actionIconButton}
                       title={strings.buttons.call}>
                        <IconButton color={'primary'} size={'small'}>
                            <PhoneIcon/>
                        </IconButton>
                    </a>
                }
            </p>
            {
                projectNode.descriptionDraft &&
                <RenderDraftJs contentState={projectNode.descriptionDraft}/>
            }
        </>
    }

    render() {
        let {nodeId, projectNode} = this.props;
        return (
            <React.Fragment>
                <GraphResourceLoad
                    resourcePath={`/projects?id=${nodeId}&includeDeleted=true`}
                    nodeId={nodeId}
                    nodeType={'ProjectRoot'}>
                    {
                        projectNode &&
                        <>
                            <PageHeader
                                title={projectNode.name}
                                PageIcon={<img src={ProjectIcon} alt={''}/>}
                                renderNavigationActions={() => <HistoryBackButton/>}
                                renderPageTabs={() => this.renderTabs()}
                                renderPageActions={() => <PageActions actions={this.getPageActionList()}/>}
                                renderPageSubContent={this.renderProjectDetails}
                            />

                            <PageContentWrapper>
                                <Breadcrumbs list={this.breadcrumbs}/>
                                <ProjectShowProcedures projectId={projectNode.id}/>
                            </PageContentWrapper>
                        </>
                    }
                </GraphResourceLoad>
                <NodeVisited nodeId={nodeId} nodeType={NODE_TYPE_OPTIONS.ProjectRoot}/>
            </React.Fragment>
        )
    }

}

const styles = () => ({
    grow: {
        flexGrow: 1,
    },
    toolbarLink: {
        paddingLeft: '10px'
    },
    progress: {
        maxHeight: '50px',
        maxWidth: '50px'
    },
    actionIconButton: {
        margin: '0 5px'
    },
    nameLabel: {
        marginRight: 10
    }
});

ProjectShow.propTypes = {};
const mapStateToProps = (state, ownProps) => {
        let nodeId = ownProps.match.params.id;
        const projectNode = getNodeOrNull(state, nodeId);
        let displayDeleted = (projectNode && projectNode.displayDeletedExecutions) || false;
        let executionFilter = {type: 'ExecutionRoot', projectId: nodeId, deleted: displayDeleted};
        let executions = getNodesByCondition(state, executionFilter);
        let categories = Array.from(new Set(executions.map(node => node.category || 'Category')));

        const projects = executions
                                .filter(e => e.procedureType === PROCEDURE_TYPES.project.id)
                                .sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime));

        return {
            nodeId: nodeId,
            projectNode: projectNode,
            categories: categories,
            diagnosticsOn: getNodeOrNull(state, NODE_IDS.UserDevice).diagnosticMode === DIAGNOSTIC_MODES.full.id,
            projectTypeExecution: projects?.[0]
        };
    }
;
const mapDispatchToProps = (dispatch) => {
        return {
            onPutNodeProperty: node => dispatch(putNodeProperty(node))
        };
    }
;
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectShow));
