import {
  CLOSE_CONFIRMATION_DIALOG,
  OPEN_CONFIRMATION_DIALOG,
  CONFIRM_CONFIRMATION_DIALOG
} from '../actions/confirmation'

const initialState = {
  open: false,
  title: 'Are you sure?',
  description: 'Are you sure?',
  positiveLabel: 'Yes',
  negativeLabel: 'No'
}

export default function confirmation (state = initialState, action) {
  switch (action.type) {
    case OPEN_CONFIRMATION_DIALOG:
      const { payload: { title, description, positiveLabel, negativeLabel } } = action
      return {
        ...state,
        open: true,
        title,
        description,
        positiveLabel: positiveLabel || 'Yes',
        negativeLabel: negativeLabel || 'No'
      }
    case CLOSE_CONFIRMATION_DIALOG:
      return {
        ...state,
        open : false
      }
    case CONFIRM_CONFIRMATION_DIALOG:
      return {
        ...state,
        open : false
      }
    default:
      return state;
  }
}
