import React from "react";
import {Permissions} from "../../../permissions";
import {strings} from "../../components/SopLocalizedStrings";
import ViewActivityIcon from "@mui/icons-material/HistoryRounded";
import {useHasExecutionPermission} from "../../../hooks/executionHooks";
import TbfSopAction from "../../components/common/TbfSopAction";
import { REPORT_EVENT_TYPES } from "../../../util/constants";

const ExecutionActivityAction = React.forwardRef(({executionId, menuItem}, ref) => {
    const canView = useHasExecutionPermission(executionId, Permissions.execution.read)
    if (!canView) {
        return null
    }
    return (
        <TbfSopAction
            menuItem={menuItem}
            title={strings.execution.show.viewActivity}
            dataCy='view-activity'
            iconComponent={ViewActivityIcon}
            path={'/executions/' + executionId + '/activity'}
            ref={ref}
            eventName={REPORT_EVENT_TYPES.executionPageMenuActivity.name}
            nodeId={executionId}
        >
            {strings.execution.show.viewActivity}
        </TbfSopAction>
    )
})
export default ExecutionActivityAction;
