import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeSchemaOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import Button from '@mui/material/Button';
import {Link, withRouter} from "react-router-dom";
import {SharedAuth} from "tbf-react-library";
import {Permissions} from "../../permissions";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {strings} from "../components/SopLocalizedStrings";

class ProjectAddButton extends Component {

    render() {
        let {hasPermission} = this.props;
        if (hasPermission) {
            return (
                <Link to={'/projects/new'}>
                    <Button onClick={this.handleAddClicked} color='primary' variant='contained'
                            title={strings.buttons.add} data-cy='add'>
                        <AddRoundedIcon/>&nbsp;&nbsp;{strings.buttons.add}
                    </Button>
                </Link>);
        }
        return <React.Fragment/>;
    }
}

const styles = () => ({});
ProjectAddButton.propTypes = {};
const mapStateToProps = (state) => {
    return {
        projectSchema: getNodeSchemaOrNull(state, 'ProjectRoot'),
        hasPermission: SharedAuth.userHasPermission(Permissions.project.create)
    };
};
const mapDispatchToProps = () => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProjectAddButton)));
