import React from "react";
import Tooltip from "@mui/material/Tooltip";
import BulkActionMenuItemBase from "./BulkActionMenuItemBase";
import { strings } from "../../../components/SopLocalizedStrings";

export default function BulkActionMenuItem({children, selectedRowIds, action, tooltip, ...props}) {

    if (action.maxItems && selectedRowIds.length > action.maxItems) {
        props.disabled = props.disabled || true;
        tooltip = strings.formatString(strings.project.list.bulkActions.overMaxSelection, action.maxItems);
    }

    if (tooltip) {
        return <Tooltip title={tooltip} followCursor>
            <div>
                <BulkActionMenuItemBase {...props}>
                    {children}
                </BulkActionMenuItemBase>
            </div>
        </Tooltip>;
    }
    return <BulkActionMenuItemBase {...props}>
        {children}
    </BulkActionMenuItemBase>;
}