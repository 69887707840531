import {
  OPEN_IMAGE_EDITOR,
  CLOSE_IMAGE_EDITOR,
  SELECT_IMAGE_EDITOR_TOOL
} from '../actions'
import get from 'lodash/get'

export const pointerTool = {
  name: 'Select',
  type: 'Pointer',
  tooltip: 'Use this tool to move and resize the objects'
}

const initialState = {
  open: false,
  id: null,
  selectedTool: pointerTool,
  drawingMode: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_IMAGE_EDITOR: {
      return {
        ...state,
        open: true,
        selectedTool: pointerTool,
        drawingMode: false,
        ...action.payload
      }
    }
    case CLOSE_IMAGE_EDITOR: {
      return {
        ...state,
        open: false
      }
    }
    case SELECT_IMAGE_EDITOR_TOOL: {
      return {
        ...state,
        selectedTool: action.payload,
        drawingMode: get(action, ['payload', 'type']) === 'pen'
      }
    }
    default:
      return state;
  }
}

export default reducer
