import React, { createContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { isAbsoluteUrl } from "../../util/util";

export const QueryParamsContext = createContext({});

const QueryParamsProvider = ({children}) => {
    const [{highlightId, highlightRuleId, returnUrl}, setQueryParams] = useState({});
    const location = useLocation();
    useState(() => {
        const urlSearch = new URLSearchParams(location.search);
        const highlightId = urlSearch.get('highlight');
        const highlightRuleId = urlSearch.get('highlight_rule');
        const returnUrl = urlSearch.get('returnUrl');
        const useReturnUrl = !isAbsoluteUrl(returnUrl) ? returnUrl : undefined;
        setQueryParams({highlightId, highlightRuleId, returnUrl: useReturnUrl});
    }, [location]);

    return <QueryParamsContext.Provider value={{highlightId, highlightRuleId, returnUrl}}>
        {children}
    </QueryParamsContext.Provider>
}

export default QueryParamsProvider;