import React from "react";
import {useNodeOrNull} from "../../../hooks/nodeHooks";
import {Panel, TbfOptionsMenu} from "tbf-react-library";
import NodeTextField from "../../graph/NodeTextField";
import GraphDelete from "../../graph/GraphDelete";
import {strings} from "../../components/SopLocalizedStrings";
import ProcedureRuleBuilderContainer from "../rules/ProcedureRuleBuilderContainer";
import {useSelector} from "react-redux";
import {ADD_ACTION_LABEL_FORMATS, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import {getNodesOrError} from "../../../selectors/graphSelectors";
import ProcedureCalculateEditor from "../rules/ProcedureCalculateEditor";
import ProcedureSelector from "../ProcedureSelector";
import ProcedureCompileMessages from "../ProcedureCompileMessages";

const ProcedureActionRule = ({ruleId, disabled, index}) => {
    const rule = useNodeOrNull(ruleId);

    const procedure = useNodeOrNull(rule?.rootId);

    const [groupLabelRule, buttonLabelRule] = useSelector((state) => {
        let rules = [];
        if (rule) {
            rules = getNodesOrError(state, procedure?.rules || []);
        }
        return rules.filter(a => a.actionType === RULE_ACTION_TYPE.label.id && a.nodeIds.includes(rule.id)).sort((a, b) => {
            if (a.format === ADD_ACTION_LABEL_FORMATS.group.id && b.format !== ADD_ACTION_LABEL_FORMATS.group.id) {
                return 1;
            } else {
                return -1;
            }
        });
    })

    const renderPanelActions = () => {
        return (<>
            {
                !disabled &&
                <TbfOptionsMenu>
                    <GraphDelete
                        nodeId={ruleId}
                        friendlyName={strings.procedure.rule.title}
                        menuItem={true}
                    />
                </TbfOptionsMenu>
            }
        </>)
    }

    const isMany = rule.actionType === RULE_ACTION_TYPE.manuallyAddExecutionBulk.id;
    const titlePrefix = isMany ? 'Bulk Action #' : 'Action #';

    return <Panel title={titlePrefix + index} renderPanelActions={renderPanelActions}
                  cypressElement={`ActionRule${index}`}>
        <ProcedureCompileMessages nodeId={rule.id}/>
        {
            !isMany &&
            <React.Fragment>
                <NodeTextField nodeId={rule.id} nodePropertyName={'alwaysOn'}
                               disabled={disabled}/>
                <NodeTextField nodeId={rule.id} nodePropertyName={'conditionOn'}
                               disabled={disabled}/>
                {rule.conditionOn &&
                    <ProcedureRuleBuilderContainer
                        nodeId={rule.id}
                        ruleProperty={'condition'}
                        includeLinks={rule.linkMatchOn}
                        disabled={disabled}
                    />
                }
                <NodeTextField
                    nodeId={rule.id}
                    nodePropertyName={'createExecutionLinkType'}
                    disabled={disabled}
                />
            </React.Fragment>
        }
        <ProcedureSelector
            nodeId={rule.id}
            disabled={disabled}
            propertyName={'createExecutionProcedureId'}
        />
        {groupLabelRule && <ProcedureCalculateEditor disabled={disabled} ruleId={groupLabelRule.id}
                                                     label={ADD_ACTION_LABEL_FORMATS[groupLabelRule.format]?.name}/>}
        {buttonLabelRule && <ProcedureCalculateEditor disabled={disabled} ruleId={buttonLabelRule.id}
                                                      label={ADD_ACTION_LABEL_FORMATS[buttonLabelRule.format]?.name}/>}
    </Panel>;
}

export default ProcedureActionRule;