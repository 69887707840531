import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { DATE_FORMAT_STORED } from '../../../reducers/graphReducer'

const mapOperator = (o) => {
  switch(o) {
    case 'contains':
      return 'search'
    case 'startsWith':
      return 'startsWith'
    case 'endsWith':
      return 'endsWith'
    case 'isEmpty':
      return '!'
    case 'equals':
    case 'is':
    case '=':
      return '=='
    case 'isAnyOf':
      return 'in'
    case 'isNotEmpty':
      return '!!'
    case '!=':
    case 'not':
      return '!='
    case '>':
    case 'after':
      return '>'
    case '<':
    case 'before':
      return '<'
    case '>=':
    case 'onOrAfter':
      return '>='
    case '<=':
    case 'onOrBefore':
      return '<='
    default: 
      throw new Error(`Operator ${o} cannot be parsed`)
  }
}
const mapFieldName = (column,columnField, operatorValue) => {
  const useSearchFilter = operatorValue === "contains"
  return (useSearchFilter ? column?.filterBySearch : column?.filterByExact) || columnField
}

const mapValue = (operator, column, value) => {
  if (value) {
    switch (column.dataType) {
      case "datetime":
        value = moment(value).toISOString();
        break;
      case "date":
        value = moment(value).format(DATE_FORMAT_STORED);
        break;
    }
  }

  switch(operator) {
    case 'contains':
      return `*${value}*`
    default:
      return value === undefined ? null : value
  }
}

const isValidFilter = ({ operator, value }) => {
  if(value === undefined || value === null) {
    // If the value is empty we do not want to process this filter, unless we are
    // checking for empty
    if(operator !== 'isEmpty' && operator !== 'isNotEmpty') {
      return false
    }
  }
  if(value === '') {
    return false
  }
  return true
}

export default function convert (columns, filters) {
  if (isEmpty(filters?.items)) return null
  const queries = filters.items
    .filter(isValidFilter)
    .map(({ operator: operatorValue, field: columnField, value }) => {
      const operator = mapOperator(operatorValue)
      const column = columns.find(({field}) => field === columnField)
      if(operator === '!' || operator === '!!') {
        return (
          { [operator]: { 'var': mapFieldName(column, columnField, operatorValue) } }
        )  
      }
      return (
        { [operator]: [{ 'var': mapFieldName(column, columnField, operatorValue) }, mapValue(operatorValue, column, value)] }
      )
  })
  if (isEmpty(queries)) return null
  return {
    [filters.logicOperator]: queries
  }
}