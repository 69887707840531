import React, {useContext, useEffect, useRef} from "react";
import withStyles from '@mui/styles/withStyles';
import {useNodeOrNull} from "../../../hooks/nodeHooks";
import {EXECUTION_PROCEDURE_MAP} from "../../../factory/executionFactory";
import { QueryParamsContext } from "../QueryParamsProvider";

const HighlightNode = ({classes, nodeId, children, cover = false}) => {
    const node = useNodeOrNull(nodeId);
    const ref = useRef();

    const {highlightId, highlightRuleId} = useContext(QueryParamsContext);

    const highlight = nodeId != null &&
        (highlightId === nodeId || node?.[EXECUTION_PROCEDURE_MAP[node?.type]?.executionLinkBack] === highlightId) &&
        !highlightRuleId;

    useEffect(() => {
        if (highlight && ref) {
            ref?.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }, [highlight, ref]);

    if (!highlight) {
        return children;
    }

    if (cover) {
        return <>
            <div ref={ref} className={`${classes.highlight} ${classes.cover}`} />
            {children}
        </>;
    }

    return <span ref={ref} className={classes.highlight}>
        {children}
    </span>;
}

const style = () => ({
    cover: {
        inset: 0,
        position: 'absolute',
        opacity: 0.2,
    },
    highlight: {
        animation: '$highlight 5s ease-in-out',
    },
    '@keyframes highlight': {
        '20%': {
            backgroundColor: 'yellow',
        },
        '80%': {
            backgroundColor: 'yellow',
        },
        '100%': {
            backgroundColor: 'transparent',
        }
    }
});

export default withStyles(style)(HighlightNode);