import withStyles from '@mui/styles/withStyles';
import {getDescendantsAndSelfAsLookup, getNodeOrNull, getSchema} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {getDate} from "../../util/util";
import {ComponentBase, getTime} from "tbf-react-library";

class DocumentShowExport extends ComponentBase {

    constructor(props) {
        super(props);
        this.handleDownload();
    }

    handleDownload() {
        let {nodesById, node} = this.props;
        let json = JSON.stringify(nodesById, null, 2);
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(json));
        let releaseVersion = node.releaseVersion;

        if(!releaseVersion) {
            const today = getDate();
            releaseVersion = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        }
        let filename = node.procedureType.toUpperCase() + '-' + node.name.replace(/[^a-zA-Z0-9]/g, '') + '-' + releaseVersion + '.json';
        if (node.key) {
            filename = node.key + '-' + filename;
        }
        element.setAttribute('download', filename);
        // Make each click download
        let id = "download-" + getTime();
        element.setAttribute('name', id);
        element.id = id;

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    render() {
        return null;
    }
}

const styles = () => ({});
DocumentShowExport.propTypes = {
    nodeId: PropTypes.string.isRequired
};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.nodeId;
    let node = getNodeOrNull(state, nodeId);
    return {
        node: node,
        nodesById: getDescendantsAndSelfAsLookup(state, nodeId),
        schemas: getSchema(state)
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocumentShowExport));
