import React, {useMemo} from "react";
import withStyles from '@mui/styles/withStyles';
import {useNodeOrNull} from "../../../hooks/nodeHooks";
import {NODE_IDS} from "../../../reducers/graphReducer";
import {calculateFeatureDistanceInKm, formatDistance} from "../../../util/location";

export const DistanceToMe = ({feature}) => {
    const myLocation = useNodeOrNull(NODE_IDS.Location)
    const formatted = useMemo(() => {
        let distanceMeters = calculateFeatureDistanceInKm(myLocation.feature, feature);
        return formatDistance(distanceMeters);
    }, [feature, myLocation]);
    return (<span>{formatted}</span>)
}

const styles = () => ({});
export default withStyles(styles)(DistanceToMe);