import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../style/alert.css';
import PageComponentBase from "../PageComponentBase";
import GraphDelete from "../graph/GraphDelete";
import {executionTitle, strings} from "../components/SopLocalizedStrings";
import ExecutionExportPDF from "./actions/ExecutionExportPDF";
import {Link} from "react-router-dom";
import {EMPTY_ARRAY, hasItems} from "../../util/util";
import ExecutionPull from "./ExecutionPull";
import ExecutionShowRelatedWorkAction from "./actions/ExecutionShowRelatedWorkAction";
import GraphDestroy from "../graph/GraphDestroy";
import {Breadcrumbs, ErrorBoundary, HistoryBackButton, PageHeader, TbfOptionsMenu, withMobile} from "tbf-react-library";
import {putNodeProperty, reportNodeEvent} from "../../actions";
import ExecutionStepsTabs from "./ExecutionStepsTabs";
import ExecutionExportButton from "../execution/ExecutionExportButton";
import {NAVIGATION_STYLES, NODE_IDS, PROCEDURE_TYPES} from "../../reducers/graphReducer";
import ExecutionStatusWithDetails from "./ExecutionStatusWithDetails";
import AssignmentsUserBadge from "../components/AssignmentsUserBadge";
import ProcedureExecutionViewButton from "../procedure/actions/ProcedureExecutionViewButton";
import {hasExecutionPermission} from "../../selectors/executionSelectors";
import ViewTemplateButton from "../workItems/ViewTemplateButton";
import ExecutionActivityAction from "./actions/ExecutionActivityAction";
import ExecutionHistoryAction from "./actions/ExecutionHistoryAction";
import Loader from "../components/Loader";
import ExecutionOfflineAction from "./actions/ExecutionOfflineAction";
import ExecutionOfflineToolbarAction from "./actions/ExecutionOfflineToolbarAction";
import ExecutionVersionsAction from "./actions/ExecutionVersionsAction";
import ExecutionActions from "./actions/ExecutionActions";
import {Permissions} from "../../permissions";
import {useIsSavePending, useSaveRunning} from "../../hooks/nodeHooks";
import {isNodeSaved} from '../../factory/executionFactory';
import { REPORT_EVENT_TYPES } from '../../util/constants';
import ExecutionAssignmentDialog from "./assignments/ExecutionAssignmentDialog";
import ExecutionAssignmentAction from "./actions/ExecutionAssignmentAction";
import ExecutionHeaderThemedDivider from './ExecutionHeaderThemedDivider';
import ExecutionThemedIcon from './ExecutionThemedIcon';
import cn from 'classnames';

const ExecutionSaving = ({executionId, classes}) => {

    const savePending = useIsSavePending(executionId)
    const saveRunning = useSaveRunning()
    if (savePending && saveRunning) {
        return <Loader circular circularSize={10} loaderStyleClass={classes.saveRunningLoader}/>
    }
    if (savePending) {
        return <span data-cy={"ignore"} className={classes.savePendingSymbol}>*</span>
    }
    return null
}
const executionSavingStyles = (theme) => ({
    savePendingSymbol: {
        fontSize: '1.2em',
        position: 'absolute',
        top: '0.1em',
    },
    saveRunningLoader: {
        position: 'absolute',
        top: '0.3em',
        color: theme.palette.primary.three.main,
    }
})
const ExecutionSavingStyled = withStyles(executionSavingStyles)(ExecutionSaving)

class ExecutionShowTop extends PageComponentBase {

    constructor(props) {
        super(props);
        this.state = {
            isExporting: false,
            displayOpen: false
        }
    }

    menuOptions = (executionId) => [
        <ExecutionExportPDF key={1} nodeId={executionId}/>,
        <ExecutionActivityAction key={2} executionId={executionId} menuItem={true}/>,
        <ExecutionHistoryAction key={3} executionId={executionId} menuItem={true}/>,
        <ExecutionVersionsAction key={4} executionId={executionId} menuItem={true}/>,
        <ExecutionPull key={5} executionId={executionId}/>
    ]

    getBreadCrumbs = () => {
        const {
            executionParents,
            executionPreview,
            executionName,
            executionProcedureId,
            projectNode,
            executionProcedureType
        } = this.props;
        let breadCrumbs = [];

        const useParents = executionParents.filter(a => a.procedureId);
        const isWorkspace = executionProcedureType === PROCEDURE_TYPES.workspace.id;
        const hasParents = hasItems(useParents);
        const topParent = useParents && useParents.length > 0 && useParents[0];
        const topParentIsWorkspace = topParent?.procedureType === PROCEDURE_TYPES.workspace.id;
        if (executionPreview) {
            breadCrumbs = [
                {name: strings.procedure.list.breadCrumb, to: '/procedures'},
                {name: executionName, to: '/procedures/' + executionProcedureId},
            ];
        } else if (!executionPreview && !hasItems(useParents) && projectNode) {
            breadCrumbs = [
                {name: strings.project.namePlural, to: '/projects'},
                {name: projectNode.name || '[Blank]', to: '/projects/' + projectNode.id},
            ];
        } else {
            breadCrumbs = []
            if (hasParents ? topParentIsWorkspace : isWorkspace) {
                breadCrumbs.push({name: strings.workspace.namePlural, to: '/list/workspace'});
            } else if (topParent) {
                breadCrumbs.push({
                    name: topParent.procedureName,
                    to: '/list/' + topParent.procedureType + '/' + topParent.procedureId
                });
            } else {
                breadCrumbs.push({
                    name: executionName,
                    to: '/list/' + executionProcedureType + '/' + executionProcedureId
                });
            }

            if (useParents) {
                useParents.forEach(item => breadCrumbs.push({
                    name: (item.title || strings.app.loading + '...'),
                    to: '/executions/' + item.id
                }))
            }
        }
        return breadCrumbs;
    }

    handleOpen = () => {
        const {executionId, reportEvent} = this.props;
        reportEvent(executionId, REPORT_EVENT_TYPES.executionPageMenuOpened.name);
    }

    renderPageActions = () => {
        const {
            classes,
            executionId,
            executionProcedureId,
            navigationStyle,
            renderMenu,
            mobileViewPort,
            saved,
            deleted,
        } = this.props;

        if (!renderMenu) {
            return null
        }

        return <div className={classes.pageActionContainer}>
            <ErrorBoundary>
                <div className={classes.secondaryDetails}>
                    <ExecutionOfflineToolbarAction executionId={executionId}/>
                </div>
                <div className={classes.secondaryDetails}>
                    <ExecutionActions executionId={executionId}/>
                </div>
                <div className={classes.secondaryDetails}>
                    {
                        (navigationStyle === NAVIGATION_STYLES.toc.id || navigationStyle === NAVIGATION_STYLES.tab.id) &&
                        <ExecutionStatusWithDetails nodeId={executionId} showStatusName={!mobileViewPort} eventName={REPORT_EVENT_TYPES.executionPageToolbarStatusDetails.name} />
                    }
                    <AssignmentsUserBadge nodeId={executionId}/>
                </div>
                <TbfOptionsMenu>
                    <ViewTemplateButton executionId={executionId} procedureId={executionProcedureId} menuItem={true}/>
                    <ExecutionExportButton nodeId={executionId}/>
                    <GraphDelete nodeId={executionId} menuItem={true} eventName={deleted ? REPORT_EVENT_TYPES.executionPageMenuRestore.name : REPORT_EVENT_TYPES.executionPageMenuDelete.name}/>
                    <GraphDestroy nodeId={executionId} isListItem={true} eventName={REPORT_EVENT_TYPES.executionPageMenuDestroy.name}/>
                    {saved && this.menuOptions(executionId)}
                    {
                        executionProcedureId && navigationStyle !== NAVIGATION_STYLES.none.id &&
                        <ProcedureExecutionViewButton executionId={executionId} procedureId={executionProcedureId}/>
                    }
                    <ExecutionOfflineAction executionId={executionId}/>
                    <ExecutionShowRelatedWorkAction />
                    <ExecutionAssignmentAction onCLick={this.onOpen}/>
                </TbfOptionsMenu>
            </ErrorBoundary>
        </div>
    }

    renderExecutionTitle = () => {
        const {
            classes,
            executionId,
            executionProcedureType,
            executionName,
            executionKey,
            executionPreview,
            executionProcedureId,
            executionLongTitle,
            canList,
            isAnonymous,
            mobileViewPort
        } = this.props;

        if (mobileViewPort) {
            return executionLongTitle;
        }

        return <>
            {
                (!executionPreview && !isAnonymous) &&
                <div className={classes.templateNavigationContainer}>
                    {
                        canList &&
                        <Link
                            className={classes.templateLinkTitle}
                            to={'/list/' + executionProcedureType + '/' + executionProcedureId}
                        >
                            {executionName}
                        </Link>
                    }
                    {
                        !canList && <>{executionName}&nbsp;</>
                    }
                    <Link
                        className={classes.templateLinkTitle}
                        to={'/executions/' + executionId}>
                        {executionKey}
                    </Link>
                    <ExecutionSavingStyled executionId={executionId}/>
                </div>
            }
            <span className={classes.titleContainer}>
                {this.renderBack()}
                <h1 className={classes.customPageTitle}>{executionLongTitle}</h1>
            </span>
        </>;
    }

    renderPageTags = () => {
        const {
            showTabs,
            executionId,
        } = this.props;
        return showTabs && <ExecutionStepsTabs executionId={executionId}/>
    }

    renderBack = () => {
        const {
            renderBack
        } = this.props;
        return renderBack && <HistoryBackButton/>
    }

    onClose = () => {
        this.setState({
            displayOpen: false
        })
    }

    onOpen = () => {
        this.setState({
            displayOpen: true
        })
    }

    render() {
        const {
            classes,
            executionId,
            executionLoaded,
            executionProcedureType,
            executionName,
            executionKey,
            executionPreview,
            executionProcedureId,
            canList,
            isAnonymous,
            mobileViewPort
        } = this.props;

        const {
            displayOpen
        } = this.state;

        if (!executionLoaded) {
            return null;
        }

        return (
            <ErrorBoundary>
                <div className={classes.header}>
                    {
                        (!executionPreview && !isAnonymous && mobileViewPort) &&
                        <div className={cn(classes.templateNavigationContainer, {
                            [classes.marginLeft]: mobileViewPort,
                        })}>
                            {
                                canList &&
                                <Link
                                    className={classes.templateLinkTitle}
                                    to={'/list/' + executionProcedureType + '/' + executionProcedureId}
                                >
                                    {executionName}
                                </Link>
                            }
                            {
                                !canList && <>{executionName}&nbsp;</>
                            }
                            <Link
                                className={classes.templateLinkTitle}
                                to={'/executions/' + executionId}>
                                {executionKey}
                            </Link>
                            <ExecutionSavingStyled executionId={executionId}/>
                        </div>
                    }
                    <PageHeader
                        title={this.renderExecutionTitle()}
                        PageIcon={<ExecutionThemedIcon className={cn({
                            [classes.icon]: !mobileViewPort
                        })} procedureId={executionProcedureId} isAnonymous={isAnonymous}
                                                procedureType={executionProcedureType}/>}
                        renderPageTabs={this.renderPageTags}
                        renderPageActions={this.renderPageActions}
                        renderNavigationActions={mobileViewPort ? this.renderBack : null}
                    />
                    <ExecutionHeaderThemedDivider procedureId={executionProcedureId} procedureType={executionProcedureType} />
                </div>
                <Breadcrumbs list={this.getBreadCrumbs()}/>
                {displayOpen && <ExecutionAssignmentDialog nodeIds={[executionId]} displayOpen={displayOpen} handleClose={this.onClose}/>}
            </ErrorBoundary>
        )
    }
}

const styles = (theme) => ({
    icon: {
        width: "54px !important",
        height: "54px !important",
    },
    listItemWrapper: {
        color: theme.palette.grey.seven.main,
        fontSize: '14px',
        width: '100%',
        paddingTop: 5,
        '& .listItemLabel': {
            verticalAlign: 'baseline'
        }
    },
    grow: {
        flexGrow: 1,
    },
    header: {
        position: 'relative',
        '& > div': {
            float: "none",
        }
    },
    heading: {
        fontSize: '1.1rem',
        marginBottom: 0,
        color: theme.palette.grey.seven.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    iconWrapper: {
        position: 'relative',
        width: 'auto',
        float: 'left',
        marginRight: 8,
        top: 1,
    },
    pageActionContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    secondaryDetails: {
        marginRight: 8,
        display: 'flex',
        '& .userName, & .statusName': {
            fontSize: '12px !important',
        }
    },
    titleContainer: {
        '& > button': {
            padding: '0px !important',
            marginRight: theme.spacing(0.5),
        }
    },
    templateLinkTitle: {
        color: theme.palette.grey.seven.main,
        fontFamily: theme.fonts.primary.regular,
        fontSize: 13,
        marginRight: 5,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    templateNavigationContainer: {
        background: theme.palette.primary.two.main,
    },
    customPageTitle: {
        display: 'inline-block',
        fontFamily: theme.fonts.primary.bold,
        color: theme.palette.grey.seven.main,
        fontSize: "1.1rem",
        verticalAlign: 'middle',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginBottom: 0,
    },
    marginLeft: {
        marginLeft: theme.spacing(2),
    },
});
ExecutionShowTop.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let executionId = ownProps.executionId;
    let executionNode = getNodeOrNull(state, executionId);
    let parents = (executionNode && executionNode.parents) || EMPTY_ARRAY;
    const navigationStyle = executionNode.navigationStyle;
    const executionActiveSteps = executionNode.activeChildren;
    const troubleshootOn = getNodeOrNull(state, NODE_IDS.UserDevice)?.troubleshootOn === true;
    const isTabView = navigationStyle === NAVIGATION_STYLES.tab.id;
    const hasMoreThan1ActiveSteps = executionActiveSteps?.length > 1;
    const showTabs = ( hasMoreThan1ActiveSteps || troubleshootOn) && isTabView;
    const canList = hasExecutionPermission(state, executionId, Permissions.execution.list) || executionNode.preview;
    const saved = isNodeSaved(executionNode);
    return {
        executionLoaded: !!executionNode && executionNode.loaded,
        executionKey: (executionNode && executionNode.key) || strings.execution.show.blankKey,
        executionLongTitle: executionTitle(executionNode),
        executionName: executionNode && executionNode.name,
        executionProcedureId: executionNode && executionNode.procedureId,
        executionPreview: executionNode && executionNode.preview,
        executionParents: parents,
        executionProcedureType: executionNode && executionNode.procedureType,
        projectNode: executionNode && executionNode.procedureType !== PROCEDURE_TYPES.project.id ? getNodeOrNull(state, executionNode.projectId) : null,
        navigationStyle: navigationStyle,
        showTabs: showTabs,
        renderMenu: !ownProps.isAnonymous,
        renderBack: !ownProps.isAnonymous,
        canList: canList,
        executionId: executionNode.id,
        saved,
        deleted: executionNode.deleted,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        reportEvent: (id, eventName, eventProperties) => dispatch(reportNodeEvent(id, eventName, eventProperties)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withMobile()(withStyles(styles)(ExecutionShowTop)));
