import React from 'react';
import '../../../style/alert.css';
import {strings} from "../../components/SopLocalizedStrings";
import SearchIcon from '@mui/icons-material/SearchRounded';
import {TbfAction} from "tbf-react-library";

const pageStrings = strings.procedure.show

const ProcedureSearchExecutionsButton = React.forwardRef(({procedureType, procedureId, primaryAction, className}, ref) => {
    return (
        <React.Fragment>
            <TbfAction
                className={className}
                path={'/list/' + procedureType + '/' + procedureId}
                dataCy='search-executions'
                title={pageStrings.executionSearchButtonTooltip}
                iconComponent={SearchIcon}
                ref={ref}
                primaryAction={primaryAction}
            >
                {strings.labels.search}
            </TbfAction>
        </React.Fragment>
    )
})
export default ProcedureSearchExecutionsButton;
