import React from "react";
import {useCallbackPatchNode, useNodeOrNull} from "../../../../hooks/nodeHooks";
import {LINK_TYPES, NODE_IDS} from "../../../../reducers/graphReducer";
import WorkItems from "../../../workItems/WorkItems";
import QueryAddExecution from "../buttons/QueryAddExecution";

const ExecutionLink = ({disabled, questionId}) => {
    const selectorId = NODE_IDS.ExecutionListingPage(questionId)
    const selector = useNodeOrNull(selectorId);
    const question = useNodeOrNull(questionId);
    const patch = useCallbackPatchNode();

    const handleAddCompleted = (item) => {
        if (item.linkType === LINK_TYPES.none.id) {
            patch({
                id: selectorId,
                recentlyCreated: [{id: item.id, addedTicks: new Date().getTime()},
                    ...(selector?.recentlyCreated || [])]
            });
        }
        
    }

    const renderAddAction = () => {
        return (
            <QueryAddExecution questionId={questionId} disabled={disabled} onAddCompleted={handleAddCompleted} />
        )
    }
    return (<>
        {
            question && <WorkItems nodeId={questionId} selectorId={selectorId} renderAddAction={renderAddAction}/>
        }

    </>)
}
export default ExecutionLink;