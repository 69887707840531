import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import {ComponentBase, SharedAuth} from "tbf-react-library";
import {Permissions} from "../../permissions";

class AlphaFeature extends ComponentBase {

    render() {
        let {hasAlpha} = this.props;
        if (!hasAlpha) {
            return null;
        }
        return this.props.children;
    }
}

const styles = () => ({});
AlphaFeature.propTypes = {};
const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = () => {
    return {
        hasAlpha: SharedAuth.userHasPermission(Permissions.feature.alpha)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlphaFeature));
