import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {clearGraph} from "../../actions";
import {connect} from "react-redux";
import {reportEvent, TbfAction} from "tbf-react-library";

class GraphClear extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.handleClear = this.handleClear.bind(this);
    }

    handleClear() {
        reportEvent({name: 'GraphClear.Started'})
        this.props.onClearGraph();
        setInterval(() => {
            console.info('GraphClear.reload')
            window.location.reload()
        }, 1000);
    }

    render() {
        return (
            <TbfAction
                onClick={this.handleClear}
                title={'Clear all data in indexeddb and redux'}
                data-cy={'clear-all-data'}
                primaryAction={true}
            >
                Clear Data
            </TbfAction>
        )
    }
}

const styles = () => ({});
GraphClear.propTypes = {};
const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        onClearGraph: () => dispatch(clearGraph())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphClear));
