import { fabric } from 'fabric'

function dataURLtoBlob (dataUrl) {
  var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}

export default function createMarkupImage (imageUrl, json, canvasWidth, canvasHeight, cb) {
  const canvasElement = document.createElement('Canvas')
  var canvas = new fabric.Canvas(canvasElement, { width: canvasWidth, height: canvasHeight })

  const onImageLoaded = (img) => {
    canvas.setBackgroundImage(img, () => {}, {
      // We then scale the background image to match the original image so that it fits inside the canvas
      scaleX: canvasWidth / img.width,
      scaleY: canvasHeight / img.height
    })

    const data = canvas.toJSON()
    json.backgroundImage = data.backgroundImage
    canvas.loadFromJSON(json, () => {
      canvas.setBackgroundImage(img)
      cb(dataURLtoBlob(canvas.toDataURL()))
      canvas.dispose()
    })
  }
  fabric.Image.fromURL(imageUrl, onImageLoaded, { crossOrigin: 'Anonymous' })
}
