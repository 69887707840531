import React from 'react';
import {useNodePropertyOrNull} from "../../../hooks/nodeHooks";
import {TbfAction} from "tbf-react-library";
import {ListAlt} from "@mui/icons-material";
import ProcedureCollectionDialog from "./ProcedureCollectionDialog";

const ProcedureRuleAddQueryDesignButton = ({nodeId, showDeleted, disabled}) => {
    const enabled = useNodePropertyOrNull(nodeId, a => a.linkStyleEnabled)

    if (!enabled) {
        return null
    }
    const renderAction = ({handleOpen}) => <TbfAction title={''} iconComponent={ListAlt} dataCy={'design-collection'}
                                                      onClick={handleOpen}>Design</TbfAction>
    return <ProcedureCollectionDialog
        renderOpenAction={renderAction}
        nodeId={nodeId}
        showDeleted={showDeleted}
        disabled={disabled}
    />

}
export default ProcedureRuleAddQueryDesignButton
