import React from 'react';
import GraphStatistics from "../graph/GraphStatistics";
import {BRANCH_NAME, BUILD_NUMBER, COMMIT_MESSAGE, Footer} from "tbf-react-library";
import LocationFooter from "./LocationFooter";
import TimerComponent from "./Timer";

class FooterItem extends React.Component {
    render() {
        return <React.Fragment>| {this.props.children}</React.Fragment>
    }
}

class SopFooter extends React.Component {

    render() {
        const {customClass} = this.props;

        return <Footer customClass={customClass}
                       branch_name={BRANCH_NAME}
                       build_number={BUILD_NUMBER}
                       commit_message={COMMIT_MESSAGE}>
            <GraphStatistics container={FooterItem}/>
            <LocationFooter container={FooterItem}/>
            <TimerComponent container={FooterItem}/>
        </Footer>
    }
}

export default SopFooter;