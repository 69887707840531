import React, {useCallback} from "react";
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import {
    useCallbackCreateOrRestoreRule,
} from "../../../hooks/procedureHooks";
import FormLabel from "@mui/material/FormLabel";
import {strings} from "../../components/SopLocalizedStrings";
import ProcedureCompileMessages from "../ProcedureCompileMessages";
import { useChildRuleByActionOrNull } from "../../../hooks/nodeHooks";
import TbfToggle from "../../components/common/TbfToggle";

const ProcedureAddMoreAvailableToggle = ({disabled, procedureId}) => {

    const rule = useChildRuleByActionOrNull(procedureId, RULE_ACTION_TYPE.addMoreAvailable.id);

    const cb = useCallbackCreateOrRestoreRule(procedureId, RULE_ACTION_TYPE.addMoreAvailable.id);

    const checked = rule && !rule.deleted;
    const handleToggle = useCallback((event) => {
        const checked = event.target.checked;
        const on = checked;
        cb({on});
    }, [cb])
    return (
        <>
            <div data-cy-element={'Property'}>
                <FormLabel className={'sizeSmall'} htmlFor={'addMoreAvailable'}>
                    {strings.procedure.settings.design.addMoreAvailable}
                </FormLabel>
                <TbfToggle
                    id={RULE_ACTION_TYPE.addMoreAvailable.id}
                    label={strings.procedure.settings.design.addMoreAvailable}
                    checked={checked}
                    disabled={disabled}
                    onChange={handleToggle}
                />
            </div>
            {!!rule && <ProcedureCompileMessages nodeId={rule.id}/>}
        </>);
}

export default ProcedureAddMoreAvailableToggle;
