import * as React from 'react';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton
} from '@mui/x-data-grid-premium'

export default function Toolbar ({allowExport}) {
  return (
    <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
      <GridToolbarFilterButton />
        <GridToolbarColumnsButton/>
        {allowExport && <GridToolbarExport printOptions={{disableToolbarButton: true}} />}
    </GridToolbarContainer>
  )
}