import React, {useCallback} from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {useDispatch, useSelector} from 'react-redux'
import {
    getConfirmationDescription,
    getConfirmationNegativeLabel,
    getConfirmationPositiveLabel,
    getConfirmationTitle,
    isConfirmationOpen
} from '../../selectors/confirmation'
import {closeConfirmationDialog, confirmConfirmationDialog} from '../../actions/confirmation'

export default function ConfirmationDialog () {
  const open = useSelector(isConfirmationOpen)
  const title = useSelector(getConfirmationTitle)
  const description = useSelector(getConfirmationDescription)
  const positiveLabel = useSelector(getConfirmationPositiveLabel)
  const negativeLabel = useSelector(getConfirmationNegativeLabel)
  const dispatch = useDispatch()

  const onYes = useCallback(() => {
    dispatch(confirmConfirmationDialog())
  }, [dispatch])

  const onClose = useCallback(() => {
    dispatch(closeConfirmationDialog())
  }, [dispatch])

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
          <DialogContent>
              <DialogContentText>{description}</DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button title={negativeLabel} data-cy="confirmation-dialog-negative" onClick={onClose} color='primary'>{negativeLabel || 'No'}</Button>
              <Button title={positiveLabel} data-cy="confirmation-dialog-positive" onClick={onYes} color='primary'
                      variant='contained'>{positiveLabel || 'Yes'}</Button>
          </DialogActions>
      </Dialog>
    </div>
  )
}
