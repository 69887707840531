import React from 'react';
import GraphResourceLoad from "./GraphResourceLoad";
import {strings} from "../components/SopLocalizedStrings";
import {MINUTES_5} from "../../util/constants";
import {NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";

const GraphConfigLoad = () => (
    <React.Fragment>
        <GraphResourceLoad
            nodeId={NODE_IDS.ClientConfig}
            friendlyName={strings.clientConfiguration.name}
            nodeType={NODE_TYPE_OPTIONS.ClientConfig}
            reloadIntervalMs={MINUTES_5}
            hideLoader={true}
            hideOfflineWarnings={true}
            displayErrorMode={'none'}
        />
    </React.Fragment>
);
export default GraphConfigLoad;