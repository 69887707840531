if (!Object.values) Object.values = o => Object.keys(o).map(k => o[k]);
export const ROLES = {
    ITPTemplateDesigner: {id: 'ITPTemplateDesigner', name: 'Template designer'},
    ITPProjectDesigner: {id: 'ITPProjectDesigner', name: 'Project designer'},
    ITPFieldUser: {id: 'ITPFieldUser', name: 'Field user'},
    ITPAuditor: {id: 'ITPAuditor', name: 'Auditor'},
    ITPReadOnly: {id: 'ITPReadOnly', name: 'Read only'},
    ITPAdministrator: {id: 'ITPAdministrator', name: 'Administrator'},
    ITPWORQSupport: {id: 'ITPWORQSupport', name: 'WORQ Support'},
    // Removing Assigned until we figure out if we need it. It is confusing having it with the "Assigned" security scope.
    //ITPFieldUserAssigned: {id: 'ITPFieldUserAssigned', name: 'Limited user'}
    AppInstallerBot: {id: 'AppInstallerBot', name: 'App Installer Bot'},
    WorQOpsBot: {id: 'WorQOpsBot', name: 'WorQOps Bot'},
    WorQOpsAdmin: {id: 'WorQOpsAdmin', name: 'WorQOps Admin'},
    External: {id: 'External', name: 'External'}
}
export const SECURITY_SCOPES = {
    assigned: {id: 'assigned', name: 'Assigned'},
    anonymous: {id: 'anonymous', name: 'Anonymous', alpha: true}
}
export const Permissions = {
    procedure: {
        create: "create:Procedure",
        list: "list:Procedure",
        read: "read:Procedure",
        delete: "delete:Procedure",
        edit: "edit:Procedure",
        push: "push:Procedure",
        destroy: "destroy:Procedure"
    },
    project: {
        create: "create:Project",
        list: "list:Project",
        read: "read:Project",
        readAssigned: "read:assigned:Project",
        delete: "delete:Project",
        edit: "edit:Project",
        listAssigned: "list:assigned:Project"
    },
    execution: {
        create: "create:Execution",
        list: "list:Execution",
        read: "read:Execution",
        delete: "delete:Execution",
        edit: "edit:Execution",
        complete: "complete:Execution",
        editCompleter: "edit:completer:Execution",
        assign: "assign:Execution",
        link: "link:Execution",
        completeAssigned: "complete:assigned:Execution",
        readAssigned: "read:assigned:Execution",
        destroy: "destroy:Execution"
    },
    photo: {
        capture: "capture:Photo",
        delete: "delete:Photo",
        read: "read:Photo",
        edit: "capture:Photo"
    },
    diagnostic: {
        read: "read:Diagnostics"
    },
    feature: {
        alpha: "alpha:Feature"
    },
    manuals: {
        read: 'read:HelpDocument',
        create: 'create:HelpDocument',
        update: 'update:HelpDocument',
        delete: 'delete:HelpDocument'
    },
    search: {
        group: 'search:Group'
    }
};
export const CombinedPermissions = {
    project: {
        listAny: Permissions.project.list + ',' + Permissions.project.listAssigned,
        readAny: Permissions.project.read + ',' + Permissions.project.readAssigned
    },
    execution: {
        listAny: Permissions.execution.list + ',' + Permissions.execution.listAssigned,
        readAny: Permissions.execution.read + ',' + Permissions.execution.readAssigned,
        completeAny: Permissions.execution.complete + ',' + Permissions.execution.completeAssigned,
    }
};
export const PERMISSION_LIST = Object.values(Permissions).map(item => Object.values(item).join(' ')).join(' ');

export const ExecutionPermissionsWhenDeleted = [
    Permissions.execution.read,
    Permissions.execution.list,
    Permissions.execution.delete,
    Permissions.execution.destroy
];
