import React from "react";
import {strings} from "../components/SopLocalizedStrings";
import {Permissions} from "../../permissions";
import Dashboard from "@mui/icons-material/DashboardRounded";
import {NonMobileOnly} from "tbf-react-library";
import {useHasProcedurePermission} from "../../hooks/procedureHooks";
import {useHasExecutionPermission} from "../../hooks/executionHooks";
import TbfSopAction from "../components/common/TbfSopAction";
import { REPORT_EVENT_TYPES } from "../../util/constants";

const ViewTemplateButton = React.forwardRef(({executionId, procedureId, menuItem}, ref) => {
    const canViewA = useHasProcedurePermission(procedureId, Permissions.procedure.list)
    const canViewB = useHasExecutionPermission(executionId, Permissions.procedure.list)
    if (!canViewA && !canViewB) {
        return null
    }
    return (
        <NonMobileOnly>
            <TbfSopAction
                menuItem={menuItem}
                title={strings.buttons.viewTemplate}
                dataCy='view-template'
                iconComponent={Dashboard}
                path={'/procedures/' + procedureId}
                ref={ref}
                eventName={REPORT_EVENT_TYPES.executionPageMenuTemplate.name}
                nodeId={executionId}
            >
                {strings.procedure.name}
            </TbfSopAction>
        </NonMobileOnly>
    )
})

export default ViewTemplateButton;
