import React, {useState, useRef} from 'react';
import { Button } from "@mui/material";
import { get } from "lodash"
import { strings } from "../../../components/SopLocalizedStrings";
import PrintIcon from "@mui/icons-material/PrintRounded";
import { downloadAttachment } from "../../../../actions/executions";
import { useEffect } from "react";
import withStyles from '@mui/styles/withStyles';
import { MOBILE_MAX_WIDTH, cypress, reportUserError } from "tbf-react-library";
import { isImageFile } from './ExecutionPhotoCarousel';
import { getDbNode } from '../../../../util/offline';
import { createImageObjectUrl } from '../../../../util/util';

const ExecutionPrintAttachment = ({source, photo, classes, on = 'tab'}) => {
    const url = get(source, ['download']);
    const [blobSrc, setBlobSrc] = useState(null);
    const [loading, setLoading] = useState(false);
    const iframeRef = useRef(null);

    const useNewTab = on === 'tab';
    const useIframe = on === 'self';

    const isFile = !isImageFile(url);

    const inMemoryUrl = isFile ? photo.originalInMemoryUrl : photo.largeInMemoryUrl;

    useEffect(() => {
        setBlobSrc(null)
    }, [url]);

    useEffect(() => {
        printBlob();
    }, [iframeRef.current?.src])

    useEffect(() => {
        printBlob();
    }, [blobSrc]);

    const printBlob = () => {
        if (iframeRef.current?.src && useIframe) {
            const iframe = iframeRef.current;
            const iframeWindow = iframe.contentWindow || iframe;
            iframe.focus();
            iframeWindow.print();
            setLoading(false);
        }
        if (useNewTab && !!blobSrc) {
            const w = window.open(blobSrc, cypress.isCypress() ? "_self" : "_blank");
            w.focus();
            setTimeout(() => w.print(), 100)
            setLoading(false);
        }
    }

    const handlePrint = () => {
        if (blobSrc) {
            printBlob();
            return;
        }
        setLoading(true);
        if (inMemoryUrl) {
            setBlobSrc(inMemoryUrl);
        } else {
            if (url) {
                downloadAttachment(url, true).then((response) => {
                    if (response && response.data) {
                        const blobUrl = window.URL.createObjectURL(response.data);
                        setBlobSrc(blobUrl);
                    } else {
                        setLoading(false);
                        throw Error("No response from file.");
                    }
                }).catch((e) => {
                    reportUserError("Could not retrieve file for printing.");
                    setLoading(false);
                });
            } else {
                getDbNode(photo.photoCaptureId).then((dbNode) => {
                    const fileData = dbNode?.node?.originalData;
                    if (fileData) {
                        const fileUrl = createImageObjectUrl(fileData);
                        setBlobSrc(fileUrl);
                    } else {
                        throw Error("Could not retrieve file from IndexedDB");
                    }
                }).catch((e) => {
                    reportUserError("No available source for file.");
                    setLoading(false);
                });
            }
        }
    }

    return <div className={classes.wrapper}>
        {useIframe && <iframe ref={iframeRef} id={`print-${url}`} className={classes.preview} src={blobSrc} />}
        <Button
            disabled={loading}
            className={classes.button}
            startIcon={<PrintIcon />}
            onClick={handlePrint}
            data-cy="print-attachment"
            title={strings.buttons.print}
        >
            <span className={'label'}>{strings.buttons.print}</span>
        </Button>
    </div>
}

const styles = (theme) => ({
    wrapper: {
        position: 'relative',
        paddingTop: 2,
    },
    preview: {
        display: 'none',
    },
    button: {
        color: theme.palette.primary.two.main,
        margin: '0 8px',
        '&[disabled]': {
            opacity: 0.3,
            color: `${theme.palette.primary.two.main} !important`,
        },
        [theme.breakpoints.down(MOBILE_MAX_WIDTH - 200)]: {
            minWidth: 'auto',
        },
        '& .MuiButton-startIcon': {
            [theme.breakpoints.down(MOBILE_MAX_WIDTH - 200)]: {
                marginRight: 0,
            },
        },
        '& .label': {
            [theme.breakpoints.down(MOBILE_MAX_WIDTH - 200)]: {
                display: 'none',
            },
        },
        '&.copyButton': {
            '& .MuiButton-startIcon .MuiSvgIcon-root': {
                fontSize: '1rem',
            },
        }
    },
})

export default withStyles(styles)(ExecutionPrintAttachment);