import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import withStyles from '@mui/styles/withStyles';

const ProgressFilled = ({ classes }) => {
  return (
    <div className={classes.progress}><CircularProgress className={classes.progress} /></div>
  )
}

export default withStyles({
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})(ProgressFilled)
