import { useCallback, useEffect, useState } from 'react';
import '../../../../style/alert.css';
import Location from "../../../components/Location"
import { useDispatch } from 'react-redux';
import isEqual from "lodash/isEqual";
import {getJsonDate} from "../../../../util/util";
import 'date-fns';
import { blurNodeProperty, focusNodeProperty, putNodesProperty } from '../../../../actions';
import ExecutionQuestionInputBase from './ExecutionQuestionInputBase';
import {getTime, reportDeveloperWarning, SharedAuth} from "tbf-react-library";

export const useInputDispatch = ({ value, disabled, questionId, executionQuestion}) => {
    const [currentValue, setCurrentValue] = useState(value ?? "");
    const [{ duration, startTime, finishTime }, setStats] = useState({ duration: null, startTime: null, finishTime: null });
    
    const dispatch = useDispatch();

    useEffect(() => {
        if (value !== currentValue) {
            setCurrentValue(value);
        }
    }, [value]);

    const onPutNode = useCallback((node) => {
        dispatch(putNodesProperty(node));
    }, [dispatch]);

    const onChange = useCallback((e) => {
        let value;
        if (e?.target) {
            value = e.target.value;
        } else {
            value = e;
        }
        setCurrentValue(value);
    }, [setCurrentValue]);

    const onFocusNode = useCallback((id, propertyName) => {
        dispatch(focusNodeProperty(id, propertyName));
    }, [dispatch]);

    const onBlurNode = useCallback((id, propertyName) => {
        dispatch(blurNodeProperty(id, propertyName));
    }, [dispatch]);

    /**
     * Standard properties for controlled input
     * @returns Standard properties for question component
     */
    const useInputStandardProperties = useCallback((valueUnknown, field, {
        handleBlur,
        handleFocus,
        handleChange
    } = {}) => {
        const questionName = executionQuestion.name;
        const id = field + '-' + questionId;

        return (
            {
                id: id,
                name: id,
                value: currentValue,
                readOnly: valueUnknown || disabled,
                'data-readonly': valueUnknown || disabled,
                className: 'textFieldPrimary',
                inputProps: {
                    'data-cy': questionName + '_' + field
                },
                onFocus: () => {
                    handleFocus?.();
                    onFocusNode(questionId, questionId);
                },
                onBlur: () => {
                    handleBlur?.();
                    onBlurNode(questionId, questionId);
                },
                onChange: (e) => {
                    onChange(e);
                    handleChange?.(e);
                },
            }
        );
    }, [questionId, disabled, executionQuestion.name, onBlurNode, onFocusNode, currentValue, onChange]);

    const updateNodeProperty = useCallback((field, newValue, auditField, implicitBlur) => {
        let startTime = getTime();
        let cleanedValue = newValue === '' ? null : newValue;
        let currentValue = executionQuestion ? executionQuestion[field] : value;
        let hasNotChanged = isEqual(currentValue, cleanedValue);
        if (hasNotChanged) {
            return;
        }
        if (disabled) {
            reportDeveloperWarning(`updateNodeProperty called on disabled question [${questionId}] [${executionQuestion?.questionType}] on Execution [${executionQuestion?.rootId}] from useInputDispatch hook.`);
            return;
        }
        let updatedNode = {
            id: questionId,
            [field]: cleanedValue
        };
        if (auditField) {
            updatedNode[auditField + 'DateTime'] = getJsonDate();
            updatedNode[auditField + 'ByUser'] = SharedAuth.getAuditName();
            updatedNode[auditField + 'Coordinates'] = Location.getLocation();
        }

        let updatedNodes = [updatedNode];
        onPutNode(updatedNodes);

        let finishTime = getTime();
        let duration = finishTime - startTime;
        if (duration > 200) {
            ExecutionQuestionInputBase.slowCounter++;
            let lastReported = ExecutionQuestionInputBase.lastSlowReported || 0;
            // Lets log once per minute at most
            let reportNow = (startTime - lastReported) > 60000;
            if (reportNow) {
                reportDeveloperWarning(`Slow typing detected. Processing took ${duration}ms. Slow counter: ${ExecutionQuestionInputBase.slowCounter}`);
            }
        }
        setStats({ duration, startTime, finishTime });
        ExecutionQuestionInputBase.lastSlowReported = finishTime;
        if (implicitBlur) {
            onBlurNode(questionId);
        }
    }, [onPutNode, onBlurNode, value, disabled, questionId, executionQuestion]);

    return { onPutNode, onFocusNode, onBlurNode, updateNodeProperty, useInputStandardProperties, duration, startTime, finishTime };
}