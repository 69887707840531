import React, {useMemo} from "react";
import withStyles from '@mui/styles/withStyles';
import GraphSavingListItemCard from "./GraphSavingListItemCard";
import {
    useDirtyNodes,
    useNodesActiveDescendants,
    useNodesById,
    usePendingUserSaveNodeIds,
    useSavingRootIds
} from "../../hooks/nodeHooks";

const GraphSavingList = ({classes}) => {
    const dirtyNodes = useDirtyNodes();
    const pendingUserSaveNodeIds = usePendingUserSaveNodeIds();
    let nodesToSave = useNodesById(pendingUserSaveNodeIds);
    const nodesToSaveIds = nodesToSave.map(node => node.id);
    const activeDescendantsById = useNodesActiveDescendants(nodesToSaveIds);
    const savingRootIds = useSavingRootIds();
    const nodesToSaveList = useMemo(() => {
        let savingNodes = [];
        nodesToSave.forEach(node => {
            let savingNode = {...node};
            const dirtyNode = dirtyNodes[savingNode.id];
            const recentModifiedActiveDescendant = (activeDescendantsById[savingNode.id] || []).sort((a, b) => {
                return new Date(b.initialValueDateTime || b.lastUpdatedDateTime) - new Date(a.initialValueDateTime || a.lastUpdatedDateTime);
            })[0];
            savingNode.modifiedDateTime = (recentModifiedActiveDescendant?.initialValueDateTime || recentModifiedActiveDescendant?.lastUpdatedDateTime) || savingNode.clientCreatedDateTime;
            const savingNow = savingRootIds[savingNode.id];
            savingNode.status = 'notstarted';
            if (savingNow) {
                savingNode.status = 'inprogress';
            } else {
                if (dirtyNode) {
                    if (dirtyNode.saveAborted) {
                        savingNode.status = 'stopped';
                    } else if (dirtyNode.saveErrorCount > 1) {
                        savingNode.status = 'exception';
                    }
                }
            }
            savingNode.itemId = savingNode.type === "Photo" ? savingNode.executionId : savingNode.rootId;
            savingNode.title = savingNode.title || savingNode.name || savingNode.type;
            savingNodes.push(savingNode);
        });
        return savingNodes;
    }, [nodesToSave, savingRootIds, dirtyNodes, activeDescendantsById]);

    return <div className={classes.container} data-cy={'GraphSavingList'}>
        {nodesToSaveList.map((node, index) => <GraphSavingListItemCard key={index}
                                                                       title={node.title}
                                                                       itemId={node.itemId}
                                                                       procedureType={node.procedureType}
                                                                       modifiedDateTime={node.modifiedDateTime}
                                                                       status={node.status}
                                                                       type={node.type}
        />)}
    </div>
}

const styles = (theme) => ({
    container: {
        minWidth: 240,
        maxWidth: 360,
        padding: '6px 10px 10px 10px',
        background: theme.palette.primary.two.main,
        borderRadius: 4,
    },
})
export default withStyles(styles)(GraphSavingList);
