import React from 'react';
import {ComponentBase} from "tbf-react-library";

class FieldIncompleteIndicator extends ComponentBase {

    render() {
        return <div className={'field-not-complete'}/>
    }
}

export default FieldIncompleteIndicator;
