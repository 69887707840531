import React from "react";
import withStyles from '@mui/styles/withStyles';
import ExecutionStatusIcon from "../components/ExecutionStatusIcon";
import {strings} from "../components/SopLocalizedStrings";

class AssignmentStatus extends React.Component {

    render() {
        const {classes, status} = this.props;

        return <span className={classes.statusWrapper}>
            <ExecutionStatusIcon status={status}/>
            <span className={'title'}>{status && strings.assignment.statuses[status]}</span>
        </span>
    }
}

const styles = theme => ({
    statusWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: 18,
            marginRight: 5
        },
        '& .title': {
            fontSize: 12,
            color: theme.palette.grey.five.main,
            textTransform: 'capitalize',
        }
    },
});

export default withStyles(styles)(AssignmentStatus);