import {executeBlobPromise, executePromise, executePromiseWithoutAuthorization, getApiBaseUrl} from "./commonAction";

export const exportExecutionToPDF = (nodeId) => {
    const url = `${getApiBaseUrl()}/export/pdf/execution/${nodeId}`;
    return executeBlobPromise('GET', url);
};

export const executionPull = (nodeId) => {
    const url = `${getApiBaseUrl()}/executions/${nodeId}/pull`;
    return executePromise('post', url);
};

export const downloadAttachment = (attachmentUrl, attemptWithoutToken = false, useApi = true) => {
    const url = `${useApi ? getApiBaseUrl() : ""}${attachmentUrl}`;
    return executeBlobPromise('GET', url, false, attemptWithoutToken);
};

export const downloadExternalImage = (attachmentUrl) => {
    const url = `${attachmentUrl}`;
    return executePromiseWithoutAuthorization('GET', url);
};