import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import get from 'lodash/get'
import cn from 'classnames'
import find from 'lodash/find'
import Tooltip from '@mui/material/Tooltip';
import '../../../../style/alert.css';
import {getActiveDescendants, getActiveNodesByFilter, getNodeOrNull} from "../../../../selectors/graphSelectors";
import Carousel, {Modal, ModalGateway} from "react-images";
import componentBaseClassNames from "react-images/lib/components/componentBaseClassNames";
import {
    copyCanvasContentsToClipboard,
    formatDateAge,
    formatFullDate,
    getStandardDate,
    hasValue
} from "../../../../util/util";
import {ComponentBase, MOBILE_MAX_WIDTH, SharedAuth, reportUserError} from "tbf-react-library";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/GetAppRounded";
import CopyIcon from "@mui/icons-material/FileCopyRounded";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Loader from "../../../components/Loader";
import {openImageEditor} from '../../../ImageEditor/actions';
import ImageWithMarkup from '../../../ImageEditor/ImageWithMarkup';
import createMarkupImage from '../../../ImageEditor/createMarkupImage';
import {downloadAttachment} from '../../../../actions/executions';
import {strings} from "../../../components/SopLocalizedStrings";
import Button from "@mui/material/Button";
import PDFViewer from '../../../components/PDFViewer';
import {toast} from "react-toastify";
import {putNodeProperty} from "../../../../actions";
import LocationOnIcon from "@mui/icons-material/LocationOnRounded";
import {Input} from "@mui/material";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ExecutionPrintAttachment from './ExecutionPrintAttachment';

const getFileName = (url) => {
    if (!url) return ''
    const urlParts = url.split('/')
    return urlParts[urlParts.length - 1]
}

export const isImageFile = (filename) => filename && filename.match(/.(jpg|jpeg|png|gif|webp)$/i);
const isPDFFile = (filename) => filename && filename.match(/.(pdf|docx)$/i);

class ExecutionPhotoCarousel extends ComponentBase {
    constructor(props) {
        super(props)
        const {photos = [], currentImageId} = this.props
        const index = photos.indexOf(find(photos, p => p.id === currentImageId)) || 0
        this.state = {
            isDownloading: false,
            index,
            editMode: {},
        }
    }

    componentWillUnmount() {
        //Fix warning: Can’t perform a react state update on an unmounted component
        this.setState = () => {
        };
    }

    handleFileNameChanged = (photo, newFileName) => {
        const {onPutNodeProperty} = this.props;
        const urlParts = photo.originalUrl.split('/');
        let fileName = photo.filename || urlParts[urlParts.length - 1];
        const fileNameParts = fileName.split('.');
        onPutNodeProperty({id: photo.id, filename: newFileName + '.' + fileNameParts[1]});
    }

    handleEditMode = (photoId, edit) => {
        this.setState(state => {
            let {editMode} = state;
            if (edit !== false) {
                editMode[photoId] = edit;
            } else {
                delete editMode[photoId];
            }
            return {
                ...state,
                editMode
            }
        });
    }

    renderPhotoCaption = (photo) => {
        const {classes} = this.props;
        const {editMode} = this.state;
        let captionParts = [];

        const photoTakenDate = !!photo.photoTakenDate ? formatDateAge(photo.photoTakenDate): undefined;
        const photoTakenDateFormat = !!photo.photoTakenDate ? formatFullDate(photo.photoTakenDate): undefined;

        const photoCreatedDate = formatDateAge(photo.createdDateTime);
        const photoCreatedDateFormat = formatFullDate(photo.createdDateTime);

        const tooltipContent = !!photoTakenDateFormat ? `Taken ${photoTakenDateFormat} and\nUploaded ${photoCreatedDateFormat}` : `Uploaded ${photoCreatedDateFormat}`;

        const attachmentType = photo.questionNode ? photo.questionNode.attachmentType : 'photo';
        const showDimensions = photo.originalHeight > 0 && photo.originalWidth > 0;

        if (photo.questionNode) {
            captionParts.push(<div key={'qname_' + photo.id + photo.thumbnailUrl}
                                   className={`${classes.infoRow} ${classes.fullWidth}`}>{photo.questionNode.name}</div>);
        }
        if (photo.originalUrl) {
            const urlParts = photo.originalUrl.split('/');
            let fileName = photo.filename || urlParts[urlParts.length - 1];
            const fileNameParts = fileName.split('.');
            fileName = fileNameParts[0].toLowerCase() === 'image' ? fileNameParts[0] + '_ ' + getStandardDate() + '.' + fileNameParts[1] : fileName;
            captionParts.push(<div key={'pname_' + photo.id + photo.thumbnailUrl} className={classes.infoRow}>
                {
                    editMode[photo.id] !== undefined &&
                    <IconButton size={'small'} className={`${classes.editButton} submit`} color={'secondary'}
                                onClick={() => {
                                    if (hasValue(editMode[photo.id])) {
                                        this.handleFileNameChanged(photo, editMode[photo.id]);
                                        this.handleEditMode(photo.id, false);
                                    } else {
                                        toast.error(strings.photo.errors.fileNameEmpty)
                                    }
                                }}>
                        <CheckCircleRoundedIcon/>
                    </IconButton>
                }
                {
                    editMode[photo.id] === undefined &&
                    <IconButton size={'small'} className={classes.editButton} color={'primary'}
                                onClick={() => this.handleEditMode(photo.id, fileNameParts[0])}>
                        <EditRoundedIcon/>
                    </IconButton>
                }
                <span className={`${classes.filenameContainer} ${editMode[photo.id] !== undefined && 'editing'}`}>
                    <span onClick={() => this.handleEditMode(photo.id, fileNameParts[0])}>{fileName}</span>
                    {
                        editMode[photo.id] !== undefined &&
                        <Input
                            autoFocus={true}
                            defaultValue={fileNameParts[0]}
                            className={`${classes.fileNameInput} textFieldPrimary`}
                            onChange={(e) => this.handleEditMode(photo.id, e.target.value)}/>
                    }
                </span>
                {
                    photo.originalSize &&
                    <React.Fragment>
                    <span
                        className={classes.divider}>&#8226;</span> {(photo.originalSize / 1024 / 1024).toFixed(2)} MB
                    </React.Fragment>
                }
                {
                    (attachmentType !== 'file' && showDimensions) &&
                    <React.Fragment>
                    <span
                        className={classes.divider}>&#8226;</span> {photo.originalWidth} x {photo.originalHeight}
                    </React.Fragment>
                }
            </div>);
        }
        {
            captionParts.push(
                <Tooltip key={'time_' + photo.id + photo.thumbnailUrl} title={<div style={{ whiteSpace: 'pre-line' }}>{tooltipContent}</div>}>
                    <div
                        title={tooltipContent}
                        className={classes.infoRow}><ScheduleRoundedIcon
                        className={classes.infoIcon}/> {photoTakenDate ?? photoCreatedDate}
                    </div>
                </Tooltip>
            );
        }
        if (photo.createdByUser) {
            const createdByUser = photo.createdByUser.split(':')[1];
            captionParts.push(<div
                key={'createdBy_' + photo.id + photo.thumbnailUrl}
                className={classes.infoRow}><PersonRoundedIcon
                className={classes.infoIcon}/> {createdByUser}
            </div>);
        }

        let photoCaptionText = captionParts;
        let coordinates = photo.coordinates;
        return (
            <span>
                {photoCaptionText}
                {
                    coordinates && coordinates.latitude &&
                    <span className={classes.infoRow}>
                        <LocationOnIcon className={classes.infoIcon}/>
                        <a target={'_map'}
                           title={`${coordinates.latitude} ${coordinates.longitude} ${coordinates.accuracy ? `±${Math.round(coordinates.accuracy)}` : ''}`}
                           className={classes.locationLink}
                           href={`http://maps.google.com/maps?q=${coordinates.latitude},${photo.coordinates.longitude}`}>
                            View in Map
                        </a>
                    </span>
                }
            </span>
        );
    };

    mapPhoto = (photo) => {
        return {
            caption: this.renderPhotoCaption(photo),
            alt: photo.propertyName,
            photo: photo,
            source: {
                id: photo.id,
                propertyName: photo.propertyName,
                download: photo.originalUrl,
                executionQuestionId: photo.executionQuestionId,
            }
        }
    }

    handleDownloadPhoto = (currentView) => {
        const {source, photo} = currentView;
        const url = get(source, ['download']);

        if (url) {
            this.setState({isDownloading: true});
            downloadAttachment(url).then(response => {
                if (response) {
                    let fileName = photo && (photo.filename || getFileName(photo.originalUrl))
                    const fileNameParts = fileName.split('.');
                    fileName = fileNameParts[0].toLowerCase() === 'image' ? fileNameParts[0] + '_ ' + getStandardDate() + '.' + fileNameParts[1] : fileName;
                    const blobData = new Blob([response.data])
                    const fileDownload = require('js-file-download');
                    if (photo.markup && photo.markup.json) {
                        var imageUrl = URL.createObjectURL(blobData)
                        createMarkupImage(
                            imageUrl,
                            photo.markup.json,
                            photo.originalWidth,
                            photo.originalHeight,
                            (data) => fileDownload(data, fileName)
                        )
                    } else {
                        fileDownload(blobData, fileName);
                    }
                }
                this.setState({isDownloading: false});
            }).catch(error => {
                reportUserError(error, null, 'Photo download failed.');
                this.setState({isDownloading: false});
            });
        }
    }

    handleDeletePhoto = (currentView, onClose) => {
        const {deletePhoto, photos} = this.props;
        const {source} = currentView;
        if (source && source.id) {
            const questionNodePhotoIds = photos.filter(photo => (photo.executionQuestionId === source.executionQuestionId && photo.propertyName === source.propertyName)).map(d => d.id);
            deletePhoto({
                id: source.id,
                propertyName: source.propertyName,
                executionQuestionId: source.executionQuestionId,
                questionNodePhotoIds: questionNodePhotoIds
            });
            onClose();
        }
    }

    handleEditPhoto = (currentView, onClose) => {
        const {editPhoto} = this.props;
        const {source} = currentView;
        const photoId = source && source.id ? source.id : null;
        if (photoId) {
            onClose()
            editPhoto({id: photoId})
        }
    }

    handleCopyPhoto = (currentView) => {
        const {photo} = currentView;
        const fileUrl = photo.convertedUrl || photo.originalUrl;
        const filename = photo && getFileName(fileUrl);
        const isPdf = isPDFFile(filename);
        let canvas;
        if (isPdf) {
            const wrapper = document.getElementById(`pdfViewer_${photo.id}`);
            canvas = wrapper.getElementsByTagName('canvas')[0];
        } else {
            canvas = document.getElementById(`canvas_large_${photo.id}`);
        }
        copyCanvasContentsToClipboard(canvas, () => toast.success(strings.photo.success.canvasCopy), () => toast.error(strings.photo.errors.canvasCopy))
    }

    Header = ({currentView, modalProps}) => {
        const {photos, classes} = this.props;
        const {isDownloading, index} = this.state;
        const {onClose} = modalProps;

        const photo = photos[index];
        const disabled = photo?.disabled || false;

        const {source, photo: photoData} = currentView;

        return (
            <div className={classes.carousalHeader}>
                <IconButton
                    onClick={onClose}
                    className={classes.closeButton}
                    title={strings.buttons.close}
                    size="large"><CloseRoundedIcon/></IconButton>
                <div className={classes.grow}/>
                {
                    currentView.photo.canEdit && !disabled &&
                    <Button className={`${classes.button} copyButton`} title={strings.buttons.edit}
                            startIcon={<EditIcon/>}
                            onClick={() => this.handleEditPhoto(currentView, onClose)}><span
                        className={'label'}>{strings.buttons.edit}</span></Button>
                }
                <Button className={classes.button} title={strings.buttons.copy}
                        startIcon={<CopyIcon/>}
                        onClick={() => this.handleCopyPhoto(currentView)}><span
                    className={'label'}>{strings.buttons.copy}</span></Button>
                <div className={classes.iconWrapper}>
                    <Button className={classes.button} disabled={isDownloading} title={strings.buttons.download}
                            startIcon={<DownloadIcon/>}
                            onClick={() => this.handleDownloadPhoto(currentView)}><span
                        className={'label'}>{strings.buttons.download}</span></Button>
                    {isDownloading &&
                        <Loader circular={true} circularSize={30} loaderStyleClass={classes.fabProgress}
                                source={'Execution Photo List'}/>}
                </div>
                <ExecutionPrintAttachment source={source} photo={photoData} />
                {
                    currentView.photo.canDelete && !disabled &&
                    <Button className={classes.button} title={strings.buttons.delete} startIcon={<DeleteIcon/>}
                            onClick={() => this.handleDeletePhoto(currentView, onClose)}><span
                        className={'label'}>{strings.buttons.delete}</span></Button>
                }
            </div>
        );
    }

    View = (props) => {
        const {data, getStyles, isFullscreen, isModal, currentIndex, index} = props;
        const {classes} = this.props;
        const {isDownloading} = this.state;
        const {photo} = data;
        const fileUrl = photo.convertedUrl || photo.originalUrl;
        const filename = photo && (fileUrl ? getFileName(fileUrl) : photo.filename);
        const isImage = isImageFile(filename);
        const isPdf = isPDFFile(filename);

        if ((currentIndex !== index) && isPdf) {
            return <React.Fragment/>
        }

        const viewBaseClassName = componentBaseClassNames.View
        const styles = {
            ...getStyles(viewBaseClassName, props),
            height: '100%',
            maxHeight: '100vh',
            maxWidth: '100%',
            userSelect: 'none',
        }

        const handleAttachmentDownload = () => this.handleDownloadPhoto(data);

        const renderViewer = () => {
            if (isPdf) {
                return <PDFViewer fileUrl={fileUrl} fileId={photo.id} inMemoryUrl={photo.originalInMemoryUrl}/>
            }
            return <ImageWithMarkup
                photoId={photo.id}
                photoType={photo.displayOriginal ? 'original' : 'large'}
                unmountPhoto={!photo.displayOriginal}
                icon={!isImage}
                iconLabel={
                    !isImage ?
                    <Button
                        startIcon={<DownloadIcon />}
                        className={classes.downloadAttachmentButton}
                        color={'primary'}
                        variant={'contained'}
                        onClick={handleAttachmentDownload}
                        data-cy={`download-attachment-button`}
                        disabled={isDownloading}
                    >
                        {strings.buttons.downloadAttachment}
                    </Button>
                    : null
                }
            />
        }

        return (
            <div style={styles} className={cn(viewBaseClassName, {isFullscreen, isModal})}>
                {renderViewer()}
            </div>
        )
    }

    onViewChange = (index) => {
        this.setState({index})
    }

    render() {
        let {photos, currentImageId, onCloseCarousel, classes} = this.props;
        let currentImageIndex = 0;
        let photoCarousel = photos.map((photo, index) => {
            if (currentImageId && photo.id === currentImageId) {
                currentImageIndex = index;
            }
            return this.mapPhoto(photo);
        });

        if (photoCarousel.length === 0) {
            return <React.Fragment/>
        }

        return (
            <React.Fragment>
                <ModalGateway>
                    <Modal onClose={onCloseCarousel}>
                        <Carousel
                            currentIndex={currentImageIndex}
                            views={photoCarousel}
                            components={{Header: this.Header, View: this.View}}
                            className={classes.carousel}
                            trackProps={{
                                onViewChange: this.onViewChange
                            }}
                        />
                    </Modal>
                </ModalGateway>
            </React.Fragment>
        )
    }
}

const styles = theme => ({
    '@global': {
        '.react-images__positioner': {
            zIndex: '1400 !important',
        },
        '.react-images__view-wrapper': {
            height: '100%'
        },
    },
    locationLink: {
        color: 'white',
        textDecoration: 'underline'
    },
    photoIcon: {
        paddingTop: '.5em'
    },
    fullWidth: {
        width: '100%',
    },
    infoRow: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 10px',
        fontSize: 14,
        float: 'left',
    },
    infoIcon: {
        marginRight: 7
    },
    divider: {
        margin: '0 10px',
        [theme.breakpoints.down('xs')]: {
            margin: '0 5px',
        }
    },
    carousalHeader: {
        display: 'flex',
        padding: '10px 20px',
        [theme.breakpoints.down('xs')]: {
            padding: '8px 10px',
        }
    },
    grow: {
        flexGrow: 1
    },
    closeButton: {
        color: theme.palette.primary.two.main,
        background: '#403e43e6',
        padding: 5,
        fontSize: '1.2rem',
        height: 34,
        '&:hover': {
            background: '#ffffff33'
        },
    },
    button: {
        color: theme.palette.primary.two.main,
        margin: '0 8px',
        '&[disabled]': {
            opacity: 0.3,
            color: `${theme.palette.primary.two.main} !important`,
        },
        [theme.breakpoints.down(MOBILE_MAX_WIDTH - 200)]: {
            minWidth: 'auto',
        },
        '& .MuiButton-startIcon': {
            [theme.breakpoints.down(MOBILE_MAX_WIDTH - 200)]: {
                marginRight: 0,
            },
        },
        '& .label': {
            [theme.breakpoints.down(MOBILE_MAX_WIDTH - 200)]: {
                display: 'none',
            },
        },
        '&.copyButton': {
            '& .MuiButton-startIcon .MuiSvgIcon-root': {
                fontSize: '1rem',
            },
        }
    },
    iconWrapper: {
        position: 'relative',
        paddingTop: 2,
    },
    fabProgress: {
        color: theme.palette.secondary.four.main,
        position: 'absolute',
        top: 3,
        left: 6,
        zIndex: 1,
    },
    disabledBtn: {
        pointerEvents: 'none',
        opacity: 0.8
    },
    filenameContainer: {
        position: 'relative',
        '&.editing': {
            minWidth: 220,
        },
    },
    editButton: {
        color: theme.palette.grey.four.main,
        marginRight: 6,
        '& .MuiSvgIcon-root': {
            fontSize: '1.1rem',
        },
        '&.submit': {
            color: theme.palette.secondary.four.main,
        },
        '&[disabled]': {
            opacity: 0.5,
        }
    },
    fileNameInput: {
        width: '101%',
        minWidth: 220,
        position: 'absolute',
        top: -7,
        left: 0,
        background: '#333',
        fontSize: 14,
        '& .MuiInputBase-input': {
            height: '35px !important',
        }
    },
    downloadAttachmentButton: {
        marginTop: '24px',
        pointerEvents: 'auto',
        '&:disabled': {
            background: 'rgba(255, 255, 255, 0.12)',
            color: 'rgba(255, 255, 255, 0.3)',
        }
    }
});

ExecutionPhotoCarousel.propTypes = {};

const mapStateToProps = (state, ownProps) => {
    let executionQuestions = getActiveDescendants(state, ownProps.executionId).filter(d => d.type === "ExecutionQuestion");
    let questionOrder = {};
    executionQuestions.forEach((node, i) => {
        questionOrder[node.id] = i;
    });

    let filter = {type: 'Photo', executionId: ownProps.executionId};
    let photos = getActiveNodesByFilter(state, filter).sort((a, b) => {
        let aOrder = questionOrder[a.executionQuestionId];
        let bOrder = questionOrder[b.executionQuestionId];
        if (aOrder !== bOrder) {
            return aOrder - bOrder;
        }
        if (a.propertyName !== b.propertyName) {
            return a.propertyName.localeCompare(b.propertyName);
        }
        return new Date(a.photoTakenDate) - new Date(b.photoTakenDate);
    });

    const isAnonymous = SharedAuth.isAnonymous();

    photos = photos.map((photo) => {
        const questionNode = getNodeOrNull(state, photo.executionQuestionId);
        const completeAccess = questionNode?.completeAccess;

        return {
            ...photo,
            questionNode: questionNode,
            canEdit: !isAnonymous && photo.isImage && completeAccess.canComplete,
            canDelete: completeAccess.canComplete,
            displayOriginal: isAnonymous,
        };
    });

    return {
        photos: photos
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        editPhoto: payload => dispatch(openImageEditor(payload)),
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionPhotoCarousel));
