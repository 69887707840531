import React, {useCallback} from "react";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import {cypress, Panel, reportSuccess, reportUserError, TbfAction} from "tbf-react-library";
import {strings} from "../../components/SopLocalizedStrings";
import {TextField} from "@mui/material";
import {useNodeOrNull} from "../../../hooks/nodeHooks";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {ROUTES} from "../../Routes";
import {GRANT_DENY_PERMISSIONS, PROCEDURE_TYPES} from "../../../reducers/graphReducer";
import {SECURITY_SCOPES} from "../../../permissions";
import {useRoleHasProcedurePermission} from "../../../hooks/procedureHooks";
import {useHistory} from "react-router-dom";

const pageStrings = strings.procedure.sharing.link;
const dashboardStrings = strings.procedure.sharing.dashboard;
const anonymousStrings = strings.procedure.sharing.anonymous;

const ProcedureSharingItem = ({url, about, title, basePath}) => {
    const copyCallback = useCallback(() => {
        if (navigator?.clipboard?.writeText) {
            navigator.clipboard.writeText(basePath + url)
            reportSuccess('Copied')
        } else {
            reportUserError('Clipboard unavailable.')
        }
    }, [url])
    const history = useHistory()
    const tryItCallback = useCallback(() => {
        if (cypress.isCypress()) {
            history.push(url)
        } else {
            window.open(basePath + url, 'preview').focus();
        }
    }, [basePath, url, history])

    return (<>
        <Panel title={title}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <p>{about}</p>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        name={'shareViaLinkUrl'}
                        value={basePath + url}
                        fullWidth={true}
                        label={title}
                        InputProps={
                            {
                                readOnly: true,
                                endAdornment: <TbfAction
                                    title={strings.buttons.copyToClipboardHint}
                                    iconComponent={ContentCopyIcon}
                                    iconButton={true}
                                    onClick={copyCallback}
                                />
                            }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TbfAction
                        title={strings.buttons.tryIt}
                        onClick={tryItCallback}
                        dataCy={'sharing-link-try-it'}
                    >{strings.buttons.tryIt}</TbfAction>
                </Grid>
            </Grid>
        </Panel>
    </>);
}

const ProcedureSharing = ({procedureId}) => {
    const procedure = useNodeOrNull(procedureId)
    const shareRelativeUrl = procedure ? ROUTES.sharing(procedure) : null;
    const shareAnonymousRelativeUrl = procedure ? ROUTES.sharing(procedure, true) : null;
    const anonymousCanCreate = useRoleHasProcedurePermission(procedureId, SECURITY_SCOPES.anonymous.id, GRANT_DENY_PERMISSIONS.create.id)
    const shareDashboard = procedure ? ROUTES.procedureDashboard(procedure) : null;

    return (<>
        <ProcedureSharingItem
            basePath={ROUTES.basePath}
            url={shareRelativeUrl}
            title={pageStrings.title}
            about={strings.formatString(pageStrings.description, procedure?.procedureType)}
        />
        {
            anonymousCanCreate &&
            <ProcedureSharingItem
                basePath={ROUTES.basePath}
                url={shareAnonymousRelativeUrl}
                title={anonymousStrings.title}
                about={strings.formatString(anonymousStrings.description, procedure?.procedureType)}
            />
        }
        {
            procedure.procedureType === PROCEDURE_TYPES.dashboard.id &&
            <ProcedureSharingItem
                basePath={ROUTES.basePath}
                url={shareDashboard}
                title={dashboardStrings.title}
                about={dashboardStrings.description}
            />
        }
    </>);
}
const styles = () => ({});
export default withStyles(styles)(ProcedureSharing);