import React from "react";
import withStyles from '@mui/styles/withStyles';
import LinearProgress from '@mui/material/LinearProgress';
import {percentage} from "../../util/util";

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    bar: {
        borderRadius: 5,
        background: `transparent linear-gradient(90deg, ${theme.palette.secondary.two.main} 0%, ${theme.palette.primary.one.main} 100%) 0% 0% no-repeat padding-box`,
    },
}))(LinearProgress);

class ProgressWithLabel extends React.Component {

    render() {
        const {classes, total, completed} = this.props;
        const percentageCount = Math.round(percentage(completed, total)) || 0;

        return <span className={classes.progressWrapper}>
            <BorderLinearProgress variant="determinate" value={percentageCount} aria-label={''}/>
            <div className={'countWrapper'}>
                <span className={classes.fontBold}>{completed} of {total}</span>
                <span>&nbsp;({percentageCount}%) completed</span>
            </div>
        </span>
    }
}

const styles = theme => ({
    progressWrapper: {
        display: 'block',
        '& .countWrapper': {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.grey.seven.main,
        },
    },
    fontBold: {
        fontFamily: theme.fonts.primary.bold
    },
});

export default withStyles(styles)(ProgressWithLabel);