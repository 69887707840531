import React from 'react'
import withStyles from '@mui/styles/withStyles';
import AssignmentCard from "./AssignmentCard";

const MapAssignmentPopup = ({feature}) => {
    const assignmentId = feature?.properties?.id
    if (!assignmentId) {
        return null;
    }
    return <AssignmentCard assignmentId={assignmentId}/>;
}

export default withStyles(() => ({}))(MapAssignmentPopup)
