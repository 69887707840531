import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Collapse, ListItem } from '@mui/material';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useCallbackPatchNode, useNodeOrNull } from "../../../hooks/nodeHooks";
import { PROCEDURE_SHOW_TAB_OPTIONS } from "../ProcedureShow";

const useStyle = makeStyles((theme) => ({
    list: {
        padding: `1px 4px`,
    },
    expandIcon: {
        minWidth: 30
    },
    name: {
        '& span': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }
    },
    child: {
        padding: `0px ${theme.spacing(4)}`,
    }
}));

const ProcedureReferencesListItems = ({nodeId, index, name, rules}) => {
    const classes = useStyle();

    const patch = useCallbackPatchNode();

    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    }

    const node = useNodeOrNull(nodeId);

    const handleLinkClick = () => {
        patch({id: node.rootId, selectedShowTab: PROCEDURE_SHOW_TAB_OPTIONS.build.id, selectedRowIndex: index});
    }

    return <>
        <ListItemButton className={classes.list} key={nodeId} onClick={handleClick}>
            <ListItemIcon className={classes.expandIcon}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon/>}
            </ListItemIcon>
            <ListItemText className={classes.name} primary={
                <Link to={`/procedures/${node?.rootId}`} onClick={handleLinkClick}>
                    {name}
                </Link>
            } />
        </ListItemButton>
        <Collapse in={open} timeout={"auto"} unmountOnExit>
            {
                rules.map(r =>
                    <ListItem className={classes.child} key={r.name}><b>{r.name}</b>: {r.type}</ListItem>
                )
            }
        </Collapse>
    </>;
}

export default ProcedureReferencesListItems;
