import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ActivityItem from "./ActivityItem";

const styles = {};

const ActivityList = ({items}) => (
    <Card>
        <List>
            {
                items.map((item) => <ActivityItem key={item.activityDateTime + item.nodeId} activity={item}/>)
            }
        </List>
    </Card>
);

export default withStyles(styles)(ActivityList);