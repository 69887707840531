import React from 'react';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { useNodeOrNull } from "../../../hooks/nodeHooks";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { withStyles } from "@mui/styles";
import { NODE_TYPE_OPTIONS } from "../../../reducers/graphReducer";

const HighlightLink = ({id, children, classes}) => {
    const location = useLocation();

    const node = useNodeOrNull(id);

    const rootNode = useNodeOrNull(node?.rootId);

    const getNodeLink = () => {

        let link;

        if (rootNode?.preview || location.pathname?.includes?.("/procedures")) {
            link = location.pathname;
        } else {
            link = `/executions/${node?.rootId}`
        }

        if (node?.type === NODE_TYPE_OPTIONS.ExecutionRule) {
            link += `?highlight_rule=${id}`;
        } else {
            link += `?highlight=${id}`;
        }

        return link;
    }

    return <Link to={getNodeLink()} className={classes.link}>{children}</Link>
}

const style = () => ({
    link: {
        textDecoration: 'underline',
    },
});

export default withStyles(style)(HighlightLink);