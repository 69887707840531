import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import {formatDateAge, formatFullDate, formatPercentage} from "../../util/util";
import ExecutionAddLinkDialog from "./ExecutionAddLinkDialog";
import ExecutionShowLinks from "./link/ExecutionShowLinks";
import ExecutionAssignments from "./ExecutionAssignments";
import {strings} from "../components/SopLocalizedStrings";
import Tooltip from "@mui/material/Tooltip";
import {ComponentBase, cypress} from "tbf-react-library";
import ExecutionPhotoList from "./question/attachment/ExecutionPhotoList";
import ExecutionAssignmentDialogWrapper from "./assignments/ExecutionAssignmentDialogWrapper";
import { REPORT_EVENT_TYPES } from '../../util/constants';

const pageStrings = strings.execution.show;

class ExecutionProperties extends ComponentBase {

    render() {
        let {
            classes,
            executionCompletedQuestionCount,
            executionCompletedSignoffCount,
            executionTotalQuestionCount,
            executionTotalSignoffCount,
            executionCompletedRatio,
            executionWarningCount,
            executionTotalPhotoCount,
            executionId,
            executionCreatedDateTime,
            executionLastUpdatedDateTime,
            executionCompletedDate,
            procedureReleaseVersion
        } = this.props;
        return (
            <React.Fragment>
                <div className={classes.propertiesContainer}>
                    <Grid container spacing={1} data-cy-element={'Property'} className={classes.propertyDivider}>
                        <Grid item xs={6} className={classes.paddingBottom0}>
                            <FormLabel
                                className={classes.label}>Completed</FormLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <span className={classes.propertyValueText}>
                                {executionCompletedQuestionCount + executionCompletedSignoffCount} / {executionTotalQuestionCount + executionTotalSignoffCount} ({formatPercentage(executionCompletedRatio)}%)
                            </span>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} data-cy-element={'Property'} className={classes.propertyDivider}>
                        <Grid item xs={12} className={classes.paddingBottom0}>
                            <FormLabel className={classes.label}>Issues</FormLabel>
                            <label className={'muiToolbarCountBadge'}>{executionWarningCount || 0}</label>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} data-cy-element={'Property'} className={classes.propertyDivider}>
                        <Grid item xs={12} className={classes.paddingBottom0}>
                            <div className={classes.propertyHeader}>
                                <FormLabel className={classes.label}>{strings.photo.namePlural}</FormLabel>
                                <div className={classes.grow}/>
                                <ExecutionPhotoList executionId={executionId} viewOnlyLightBox={true} eventName={REPORT_EVENT_TYPES.executionPagePropertiesAttachmentViewed.name} />
                            </div>
                            <label className={'muiToolbarCountBadge'}>{executionTotalPhotoCount || 0}</label>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} data-cy-element={'Property'} className={classes.propertyDivider}>
                        <Grid item xs={12}>
                            <FormLabel className={classes.label}>Assigned To</FormLabel>
                            <div className={classes.actionButtonWrapper}>
                                <ExecutionAssignmentDialogWrapper nodeIds={[executionId]}/>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <ExecutionAssignments executionId={executionId}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} data-cy-element={'Property'} className={classes.propertyDivider}>
                        <Grid item xs={12}>
                            <FormLabel className={classes.label}>Related Work</FormLabel>
                            <div className={classes.actionButtonWrapper}>
                                <ExecutionAddLinkDialog fromNodeId={executionId}/>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <ExecutionShowLinks executionId={executionId}/>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={classes.audit}>
                            <div className={classes.marginBottom5}
                                 data-cy-element={'Property'}>
                                <Tooltip title={formatDateAge(executionCreatedDateTime)}>
                                    <span>{pageStrings.createdLabel} {formatFullDate(executionCreatedDateTime)}</span>
                                </Tooltip>
                            </div>
                            <div data-cy-element={'Property'}>
                                <Tooltip title={formatDateAge(executionLastUpdatedDateTime)}>
                                    <span>{pageStrings.updatedLabel} {formatFullDate(executionLastUpdatedDateTime)}</span>
                                </Tooltip>
                            </div>
                            {
                                executionCompletedDate &&
                                <div data-cy-element={'Property'}>
                                    <Tooltip title={formatDateAge(executionLastUpdatedDateTime)}>
                                        <span>{pageStrings.completedLabel} {formatFullDate(executionCompletedDate)}</span>
                                    </Tooltip>
                                </div>
                            }
                            {
                                procedureReleaseVersion &&
                                <div data-cy-element={'Property'}>
                                    <Tooltip title={pageStrings.procedureReleaseVersionHint}>
                                    <span>{pageStrings.procedureReleaseVersion} {procedureReleaseVersion}</span>
                                    </Tooltip>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        )
    }
}

const styles = (theme) => ({
    label: {
        marginRight: 5
    },
    grow: {
        flexGrow: 1,
    },
    audit: {
        color: theme.palette.grey.seven.main,
        fontSize: '0.75rem'
    },
    propertyHeader: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    actionButtonWrapper: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    actionButton: {
        padding: '2px 10px',
    },
    disabledButton: {
        pointerEvents: 'none'
    },
    marginBottom20: {
        marginBottom: 20
    },
    marginBottom5: {
        marginBottom: 5
    },
    paddingBottom0: {
        paddingBottom: '0 !important'
    },
    propertyDivider: {
        marginBottom: 10,
        paddingBottom: 5,
        minHeight: 35,
        borderBottom: '1px solid #EAEAEA',
        position: 'relative'
    },
    propertiesContainer: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2)
    },
    propertyValueText: {
        color: '#000',
        textAlign: 'right',
        display: 'block'
    }
});
ExecutionProperties.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let execution = getNodeOrNull(state, ownProps.executionId);
    return {
        executionStatus: execution.status,
        executionDraft: execution.draft,
        executionCompletedQuestionCount: execution.completedQuestionCount,
        executionCompletedSignoffCount: execution.completedSignoffCount,
        executionTotalQuestionCount: execution.totalQuestionCount,
        executionTotalSignoffCount: execution.totalSignoffCount,
        executionCompletedRatio: execution.completedRatio,
        executionWarningCount: execution.warningCount,
        executionTotalPhotoCount: execution.totalPhotoCount,
        executionId: execution.id,
        executionCreatedDateTime: execution.createdDateTime,
        executionLastUpdatedDateTime: execution.lastUpdatedDateTime,
        executionCompletedDate: execution.completedDate,
        procedureReleaseVersion: cypress.isCypress() ? null : execution.procedureReleaseVersion || null
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionProperties));
