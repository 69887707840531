import React from "react";
import withStyles from '@mui/styles/withStyles';
import {NODE_IDS} from "../../reducers/graphReducer";
import NodeTextField from "../graph/NodeTextField";
import {strings} from "../components/SopLocalizedStrings";
import {useBuildLookup, useNodeOrNull} from "../../hooks/nodeHooks";
import Loader from "../components/Loader";

const ProcedureSelector = ({disabled, nodeId, propertyName}) => {
    let proceduresResourceLoader = useNodeOrNull(NODE_IDS.ProcedureSummaryAll)
    let proceduresLoaded = proceduresResourceLoader && proceduresResourceLoader.loaded
    let procedureOptions = useBuildLookup(proceduresResourceLoader?.nodeIds)
    return <>
        {
            proceduresLoaded &&
            <NodeTextField
                nodeId={nodeId}
                visual={'select'}
                nodePropertyName={propertyName}
                label={strings.procedure.namePlural}
                nodeValueOptions={procedureOptions}
                disabled={disabled}
            />
        }
        {
            !proceduresLoaded &&
            <Loader source={'/procedures'} inline={true}/>
        }
    </>
}

const styles = () => ({
    advancedModeMsg: {
        margin: '20px 1px',
    },
});

export default withStyles(styles)(ProcedureSelector);
