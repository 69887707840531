import React from "react";
import ExecutionStatusIcon from "../components/ExecutionStatusIcon";
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {putNodeProperty, reportNodeEvent} from "../../actions";
import {connect} from "react-redux";
import withStyles from '@mui/styles/withStyles';
import {Popover} from "@mui/material";
import WorkItemProgressDetails from "../workItems/WorkItemProgressDetails";
import {strings} from "../components/SopLocalizedStrings";
import Button from "@mui/material/Button";
import {EXECUTION_STATUS} from "../../reducers/graphReducer";

class ExecutionStatusWithDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            progressAnchorEl: null,
        }
    }

    handleOpenProgressPopover = (event) => {
        const {nodeId, eventName, reportEvent} = this.props;
        event.preventDefault();
        event.stopPropagation();
        this.setState({progressAnchorEl: event.target});
        if (eventName) {
            reportEvent(nodeId, eventName);
        }
    }

    handleCloseProgressPopover = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({progressAnchorEl: null});
    }

    render() {
        const {classes, status, totalQuestionCount, completedQuestionCount, showStatusName} = this.props;
        const {progressAnchorEl} = this.state;
        const showProgressPopover = Boolean(progressAnchorEl);

        return <div className={classes.statusWrapper}>
            <Button onClick={this.handleOpenProgressPopover} title={strings.buttons.showStatus}
                    className={`${classes.statusButton} ${showStatusName ? classes.statusButtonWithText : ''}`}>
                <ExecutionStatusIcon status={status}/>
                {
                    showStatusName &&
                    <span className={'statusName'}>{status && strings.assignment.statuses[status]}</span>
                }
            </Button>
            {
                showProgressPopover &&
                <Popover
                    open={showProgressPopover}
                    anchorEl={progressAnchorEl}
                    onClose={this.handleCloseProgressPopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    <WorkItemProgressDetails
                        status={status}
                        totalQuestionCount={totalQuestionCount}
                        completedQuestionCount={completedQuestionCount}
                        handleCloseProgressPopover={this.handleCloseProgressPopover}
                    />
                </Popover>
            }
        </div>;
    }
}

const styles = () => ({
    statusWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& .statusName': {
            marginLeft: 3,
        }
    },
    statusButton: {
        minWidth: 'auto',
        padding: '2px',
    },
    statusButtonWithText: {
        padding: '2px 8px',
    }
});

const mapStateToProps = (state, ownProps) => {
    const itemNode = getNodeOrNull(state, ownProps.nodeId);
    let totalQuestionCount = (itemNode?.totalQuestionCount || 0) + (itemNode?.totalSignoffCount || 0);
    let completedQuestionCount = (itemNode?.completedQuestionCount || 0) + (itemNode?.completedSignoffCount || 0);
    ;
    const status = itemNode?.status || (itemNode?.completed ? EXECUTION_STATUS.done.id : completedQuestionCount > 0 ? EXECUTION_STATUS.inprogress.id : EXECUTION_STATUS.notstarted.id);

    return {
        totalQuestionCount: totalQuestionCount,
        completedQuestionCount: completedQuestionCount,
        status: status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        reportEvent: (id, eventName, eventProperties) => dispatch(reportNodeEvent(id, eventName, eventProperties)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionStatusWithDetails));
