import React, {useState, useEffect} from 'react';
import Loader from '../../../components/Loader';

const withDraftJs = (Component, showLoader) => {
    return function ComponentWithDraftJs(innerProps) {
        const [error, setError] = useState(null);
        const [loading, setLoading] = useState(true);
        const [passedProps, setPassedProps] = useState({});

        const props = {...innerProps, ...passedProps};

        const loadDraftJs = async () => {
            try {
                const {ContentState, convertToRaw} = await import('draft-js'); 
                setPassedProps({ContentState, convertToRaw})
            } catch (e) {
                setError(e);
            }
            setLoading(false);
        }

        useEffect(() => {
            loadDraftJs();
        }, []);

        if (loading) {
            if (showLoader) {
                return <Loader circular circularSize={40} />;
            } else {
                return null;
            }
        }

        if (error) {
            throw `Error importing draft-js ${error}`;
        }

        return <Component {...props} />;
    }
}

export default withDraftJs;