import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {areNodesLoadedStorage} from "../../selectors/graphSelectors";
import {restoreAllDirtyNodes, restoreNodesByScope} from "../../actions";
import {connect} from "react-redux";
import Loader from "../components/Loader";

/**
 * Load data from local storage on app startup.
 */
class GraphStorageLoad extends Component {

    loadFromStorageRequested = false;

    componentDidMount() {
        this.loadGraph();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.loadGraph();
    }

    loadGraph() {
        let {loadedStorage, disabled, restoreNodesByScope, restoreAllDirtyNodes} = this.props;
        if (disabled !== true && !loadedStorage && !this.loadFromStorageRequested) {
            restoreNodesByScope('AlwaysLoad');
            restoreAllDirtyNodes();
            this.loadFromStorageRequested = true;
        }
    }

    render() {
        let {disabled} = this.props;
        if (disabled || this.props.loadedStorage) {
            return this.props.children;
        }
        return <Loader source={'GraphStorageLoad'} friendlyName={'Cached Data'}/>;
    }
}

const styles = () => ({});
GraphStorageLoad.propTypes = {};
const mapStateToProps = (state) => {
    return {
        loadedStorage: areNodesLoadedStorage(state)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        restoreNodesByScope: (scope) => dispatch(restoreNodesByScope(scope, null)),
        restoreAllDirtyNodes: () => dispatch(restoreAllDirtyNodes())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphStorageLoad));
