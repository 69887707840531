import {useNodeOrNull} from "../../hooks/nodeHooks";
import {NODE_IDS} from "../../reducers/graphReducer";
import {useCallback} from "react";
import {convertOptionObjectToSelectOptions} from "../../util/util";
import {useDispatch} from "react-redux";
import {putNodeProperty} from "../../actions";
import debounce from "lodash/debounce";
import {sortBy} from "lodash";

/**
 * Gets the list of view options (i.e. Recently created, Near me) for a given template.
 *
 * Near Me is only added if template has location question and user location is known
 *
 * @param selectorId
 */
export const useViewOptions = (selectorId) => {

    const selector = useNodeOrNull(selectorId)

    const options = convertOptionObjectToSelectOptions(selector?.views || [])
    return sortBy(options, [a => a.label]);
}


export const useProcedureHasLocation = (procedureId) => {
    const procedure = useNodeOrNull(procedureId)
    return procedure != null && procedure.hasLocationField
}

export const useDeviceHasLocation = () => {
    const location = useNodeOrNull(NODE_IDS.Location);
    return location && location.position && location.position.latitude && location.position.longitude;
}

export const useCallbackUpdateSelectedView = (selectorId) => {
    const dispatch = useDispatch()
    return useCallback((selectedOption) => {
        dispatch(putNodeProperty({id: selectorId, selectedViewId: selectedOption.value}))
    }, [dispatch, selectorId]);
}

export const useCallbackUpdateFilter = (selectorId) => {
    const dispatch = useDispatch()
    return useCallback(
        debounce((term) => {
            dispatch(putNodeProperty({id: selectorId, searchTerm: term}))
        }, 50)
        , [dispatch, selectorId]);
}

export const useCallbackUpdateMapExpanded = (selectorId, mapExpanded) => {
    const dispatch = useDispatch()
    return useCallback(() => {
        dispatch(putNodeProperty({id: selectorId, mapExpanded: mapExpanded}))
    }, [dispatch, selectorId, mapExpanded]);
}

export const useCallbackUpdateShowMap = (selectorId, showMap) => {
    const dispatch = useDispatch()
    return useCallback(() => {
        dispatch(putNodeProperty({id: selectorId, showMap: showMap}))
    }, [dispatch, selectorId, showMap]);
}

export const useCallbackFocusedItem = (selectorId) => {
    const dispatch = useDispatch()
    return useCallback((e, id) => {
        dispatch(putNodeProperty({id: selectorId, focusedItemId: id}))
    }, [dispatch, selectorId]);
}
export const useCallbackBlurredItem = (selectorId) => {
    const dispatch = useDispatch()
    return useCallback(() => {
        dispatch(putNodeProperty({id: selectorId, focusedItemId: null}))
    }, [dispatch, selectorId]);
}
export const useCallbackUpdateScrollTop = (selectorId) => {
    const dispatch = useDispatch()
    const selector = useNodeOrNull(selectorId)
    const selectorMia = selector == null;
    return useCallback((scrollTop) => {
        if (!selectorMia) {
            dispatch(putNodeProperty({id: selectorId, listScrollTop: scrollTop}))
        }
    }, [dispatch, selectorId, selectorMia]);
}

export const useCallbackSearchThisArea = (selectorId, mapRef) => {
    const dispatch = useDispatch()
    return useCallback(() => {
        if (mapRef.current) {
            const {map} = mapRef.current
            const bb = map.getBounds()
            const boundingBox = [bb.getNorth(), bb.getSouth(), bb.getEast(), bb.getWest()]
            dispatch(putNodeProperty({id: selectorId, boundingBox}))
        }
    }, [dispatch, selectorId, mapRef]);
}
