import React from "react";
import withStyles from '@mui/styles/withStyles';
import {ErrorBoundary, TruncateReadMoreText} from "tbf-react-library";

export const PlainValue = ({value}) => {
    if (value === true) {
        return 'Yes';
    }
    if (value === false) {
        return 'No';
    }
    if (typeof value === 'number') {
        value = value.toString();
    }
    return (<ErrorBoundary><TruncateReadMoreText>{value || ''}</TruncateReadMoreText></ErrorBoundary>);
}

const styles = () => ({});
export default withStyles(styles)(PlainValue);
