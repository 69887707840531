import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
    en: {
        app: {
            name: 'WORQ',
            pageTitlePostfix: ' - WORQ',
            ok: 'Ok',
            cancel: 'Cancel',
            close: 'Close',
            add: 'Add',
            update: 'Update',
            delete: 'Delete',
            loading: 'Loading',
            addMore: 'Add More'
        },
        menu: {
            dashboard: "Dashboard",
            procedures: "Templates",
            projects: "Projects",
            reports: "Reports",
            users: "Users",
            clients: "Clients",
            groups: "Teams",
            manuals: "Manuals",
            workspaces: "Workspaces",
            offline: {
                offlineLabel: 'Offline',
                onlineLabel: 'Online',
                buttonTitle: 'Toggle offline mode'
            },
            clientDelete: 'Delete client data'
        },
        clientConfiguration: {
            name: 'Client configuration'
        },
        assignment: {
            name: 'Assignment',
            namePlural: 'Assignments',
            myAssignmentDisplayName: 'Assigned to me',
            unassignedUser: 'Unassigned',
            assignedUser: 'Assigned Users',
            statuses: {
                inprogress: 'In Progress',
                notstarted: 'Not Started',
                stopped: 'Aborted',
                exception: 'Error',
                done: 'Done'
            }
        },
        execution: {
            name: 'Worq item',
            namePlural: 'Worq items',
            show: {
                title: '{}',
                blankTitle: '[BLANK]',
                blankKey: '[NEW]',
                blankKeyText: 'NEW',
                notLoadedKey: '[NOT LOADED]',
                notLoadedKeyText: 'NOT LOADED',
                previewBlankKey: '[NEW PREVIEW]',
                titlePreviewPostfix: '[PREVIEW]',
                viewActivity: 'Activity',
                viewHistory: 'History',
                viewVersions: 'Versions',
                exportPdf: 'Export PDF',
                exportButton: 'Export',
                offlineTurnOn: 'Make available offline',
                offlineTurnOff: 'Remove offline access',
                offlineLoadedTitle: 'Available offline',
                offlineLoadErrorTitle: 'Error downloading for offline',
                offlineNotLoadedTitle: 'Not yet available offline',
                offlineLoadingTitle: 'Making available offline',
                offlineTitle: 'Offline lets you work with no internet connection',
                offlineOnIconOnlyTitle: 'Available offline. Click to turn off.',
                offlineTurnOnWhenOffline: 'You are offline. Worq item will be made offline available when you are next online.',
                offlineTurnOnWhenOnline: 'Worq item will be made available offline on this device until you log off.',
                offlineImplicitly: 'Worq item is implicitly offline due to parent being offline',
                draft: 'Draft',
                takePhotoTitle: 'Take a photo',
                addAttachmentTitle: 'Add attachment',
                addCommentTitle: 'Add note',
                escalatedReason: 'What required escalation?',
                escalatedTo: 'Who was it escalated to?',
                notAnIssueComment: 'Why is it not an issue?',
                createdLabel: 'Created',
                updatedLabel: 'Modified',
                completedLabel: 'Completed',
                formCompletedLabel: 'Submitted',
                procedureReleaseVersion: 'Version',
                procedureReleaseVersionHint: 'Template version this item is using',
                relatedWork: 'Related Work',
                me: 'Myself',
                standard: {
                    completedLabel: 'Completed',
                    nodeCompletedPrefix: 'Signed off by',
                    completeButton: 'Sign-off As Completed',
                    undoCompleteButton: 'Cancel sign off',
                    notCompleted: 'Not yet signed off.',
                    nextCompleted: 'Next',
                    submitCompleted: 'Sign-off As Completed',
                    previousCompleted: 'Previous',
                },
                form: {
                    completedLabel: 'Submitted',
                    nodeCompletedPrefix: null,
                    nextCompleted: 'Next',
                    submitCompleted: 'Submit',
                    previousCompleted: 'Previous',
                    undoCompleteButton: 'Edit',
                    notCompleted: 'Not yet completed.',
                    completeButton: 'Next',
                },
                calculator  : {
                    completedLabel: null,
                    nodeCompletedPrefix: null,
                    nextCompleted: 'Next',
                    submitCompleted: 'Restart',
                    previousCompleted: 'Previous',
                    undoCompleteButton: 'Edit',
                    notCompleted: null,
                    completeButton: 'Next',
                },
                copy: {
                    label: "Copy to",
                    notLoaded: "Not loaded"
                }
            },
            columns: {
                title: 'Title',
                createdDateTime: 'Created',
                lastUpdatedDateTime: 'Modified',
                category: 'Category',
                name: 'Template',
                procedureType: 'Template type',
                completed: 'Completed',
                completedDate: 'Completed',
                completedRatio: 'Progress',
                totalPhotoCount: 'Attachments',
                warningCount: 'Issues',
                status: 'Status',
                key: 'Key',
                feature: 'Distance',
                id: 'ID',
                pivot: {
                    createdDateTime: 'Created Date',
                    lastUpdatedDateTime: 'Modified Date',
                    completedDate: 'Completed Date',
                }
            },
            procedureViewType: {
                treeView: {
                    openNavigator: 'Open content navigation',
                    closeNavigator: 'Close content navigation',
                    navigatorHeading: 'Contents',
                },
                switchContentView: 'Content view',
                switchTabView: 'Tab view',
            },
            selectorCreateLinkDialog: {
                createMessage: '+ Create a new worq item',
            },
            addLinkDialog: {
                executionLookup: 'Item to link to',
                addButton: 'Add'
            },
            addLinkNewDialog: {
                addButton: 'Add',
                addAndStartEditButton: 'Add & open',
                submit: 'Ok',
                moreOptions: 'More options',
                addMore: 'Add more',
                dataEntryMode: 'Data entry mode',
                successfullyAddedWorqItem: 'Successfully added worq item:',
                unsavedChangesTitle: 'Are you sure?',
                unsavedChanges: 'Any data you have entered will be discarded.'
            },
            attachments: {
                viewButton: 'View'
            },
            assignmentDialog: {
                title: 'Assigned To',
                editLink: 'Edit',
                assignedToGroup: 'Groups:',
                assignedToUser: 'Users:',
                message: 'You are assigning: {0} items'
            },
            addNewPage: {
                title: 'Loading...'
            },
            activity: {
                title: 'Activity',
                nameNull: 'Name unavailable',
                nameSelf: 'you',
                joinWord: 'by'
            },
            search: {
                column: {
                    name: 'Template',
                    title: 'Title',
                },
                placeholder: 'Search by name',
                errorMsg: 'Error while searching worq item',
                offlineMsg: 'Search is unavailable while offline',
                notFoundMsg: `Your search ' {0} ' did not match any results`,
                noOptionsMsg: `No options are available`,
                recentlyCreatedTag: 'NEW',
                pivot: {
                    error: "Error loading work items.",
                    retry: "Retry",
                }
            },
            errors: {
                stepsMissing: 'This Worq item was only partially loaded. Please try logging off and on again.',
                saveError: 'There was an error saving this worq item.',
                saveAborted: 'Saving this worq item failed too many times and will not be automatically tried again.',
                exportPdfPreview: 'Cannot export as PDF when using preview',
                exportPdfDraft: 'Cannot export as PDF when using draft',
                canNotCreate: 'You do not have permission to create this worq item.',
                cannotRestoreParentDeleted: 'Cannot restore as the parent is deleted',
                noValidExecutionAddOption: 'No valid or available add option',
                reload: 'RELOAD',
                attachmentMaxSizeError: 'The attachment file size exceeds the maximum limit of 200MB.'
            },
            questions: {
                geographic: {
                    placeholder: 'e.g. -35.123123 149.12313',
                    invalidFormat: 'Latitude and Longitude is required',
                    useCurrentLocation: 'Use current location',
                    latitudeOutOfBounds: 'Latitude {0} is not between -90 and 90',
                    longitudeOutOfBounds: 'Longitude {0} is not between -180 and 180',
                },
                email: {
                    invalidFormat: 'Please enter a valid email address'
                },
                resolved: {
                    labelPrefix: 'Resolved answer - '
                },
                select: {
                    noOptions: 'No options.',
                    optionMissing: 'Selected option missing'
                },
                phoneNumber: {
                    invalidFormat: 'Please enter a valid phone number',
                },
                attachments: {
                    uploading: 'Attachments are uploading'
                },
            },
            workItems: {
                emptyMessage: 'No worq items to display',
                title: 'WORQ',
                sections: {
                    empty: 'No items',
                }
            },
            list: {
                addNewButtonTitle: 'Add a new {0}'
            },
            pull: {
                button: 'Pull',
                successChanges: 'Pulled in latest template changes.',
                successNoChanges: 'No changes to pull.',
                fail: 'Pull failed.',
                title: 'Pull in the latest template version to this worq item.'
            }
        },
        location: {
            accuracyPrefix: '±',
            accuracyPostfix: 'm',
            searchNearMe: 'Near Me',
            unavailableMessage: 'Location is unavailable',
            userDeniedMessage: 'User denied location permission'
        },
        dashboard: {
            name: 'Dashboard',
            namePlural: 'Dashboards',
            assignments: 'Assigned to me',
            recent: 'Recent',
            recentExecutions: 'My WorQ',
            workspaces: 'Workspaces',
            projects: 'Projects',
            availableOffline: 'Offline'
        },
        workspace: {
            name: 'Workspace',
            namePlural: 'Workspaces'
        },
        availableOffline: {
            name: 'Available Offline',
            namePlural: 'Available Offline',
        },
        group: {
            name: 'Team',
            namePlural: 'Teams'
        },
        photo: {
            name: 'Attachment',
            namePlural: 'Attachments',
            show: {
                undoButton: 'Undo',
                loading: 'Loading'
            },
            deletePhoto: {
                success: '{0} attachment{1} deleted',
            },
            success: {
                canvasCopy: 'Content copied to clipboard',
            },
            errors: {
                loading: 'Error loading image',
                canvasCopy: 'Error copying content',
                fileNameEmpty: 'File name cannot be empty',
                noPhotosMsg: 'No photos available in gallery',
                previewNotAvailable: 'Preview for this document is not available'
            }
        },
        graph: {
            node: {
                processingWarningMessage: 'Processing warning:',
            }
        },
        procedure: {
            name: 'Template',
            namePlural: 'Templates',
            list: {
                title: 'Templates',
                newButtonTitle: 'Add a new template',
                foundXResults: 'Found {0} Templates',
                breadCrumb: 'Templates',
                pushedResults: 'Successfully pushed to {0} worq item(s)',
            },
            show: {
                exportButton: 'Download template definition',
                previewButtonTooltip: 'Switch to full page preview',
                executionSearchButtonTooltip: 'Search instances',
                historyButtonTooltip: 'Template changes',
                treeViewButtonEnabledTooltip: 'Tree view is enabled. Click to disable it',
                treeViewButtonDisabledTooltip: 'Tree view is disabled. Click to enable it',
                previewSideBySideButtonTooltip: 'Switch to side by side preview',
                pushButtonTooltip: 'Push changes to existing {0}',
                createButtonTooltip: 'Create a {0}',
                errorInvalidFileType: 'File type chosen is invalid, choose .csv file',
                importErrorMissingProcedureRoot: 'Could not find Templates root node in imported file.',
                importSuccess: 'Template loaded, please now save it to the server.',
                compileWarningsMessage: 'Please fix the following errors:',
                compileSuggestions: 'Please consider the following suggestions:',
                compileErrorsMessage: 'Please fix all errors in the template before creating an {0}',
                pushProgressMessage: 'Pushing changes',
                cloneNodeIndicator: 'Selected for copying',
                cloneNodeConfirmationMsg: `Are you sure want to paste {0}: {1} of Template: {2}?`,
                settingsGeneral: 'General',
                settingsSearch: 'Search',
                selectLinkAdvancedModeMsg: 'Advanced mode. See rules below',
                settingsDesign: 'Design',
                settingsCollectionDesign: 'Listing',
                addRuleButtonTitle: 'Add rule',
                pruneButtonTitle: 'Prune',
                pruneButtonMessage: 'Prune all deleted step/task/question/rules',
                viewVersions: 'Versions'
            },
            dashboard: {
                title: 'Dashboard',
                unavailable: 'Dashboard View unavailable',
                notDashboard: 'Template is not a dashboard.',
                invalid: 'Template {0} has errors.'
            },
            rule: {
                title: 'Rule',
                forTitle: 'For',
                conditionTitle: 'When',
                actionTitle: 'Then',
                none: 'None',
                addButtonText: 'Add',
                addButtonTooltip: 'Add rule',
                deleteButtonTooltip: 'Delete rule',
                propertyTitle: 'Rules',
                ruleLabel: 'Condition',
                conditionLabel: 'Condition',
                autoCreateChildRules: 'And also',
                addListViewDesignButton: 'Add a design',
                addListViewDesignButtonTip: 'Add a new design for tables and lists',
                listViewDesignName: 'View name',
                listViewDesignColumn: 'Columns',
                listViewDesignAddColumn: 'Add a column',
                listViewDesignAddColumnTip: 'Add another column to the view',
                listViewDesignOrder: 'Order',
                listViewDesignAddOrder: 'Add a column',
                listViewDesignAddOrderTip: 'Add another column to the order',
                listViewDesignFilter: 'Filter',
                column: 'Column',
                addLAnotherLabelSetButton: 'Add another Label Set',
            },
            settings: {
                general: {},
                customKey: {
                    title: 'Key',
                    about: "Customise the key that we generate for worq items using this template. e.g. {0}123",
                    toggleLabel: 'Customise key',
                    prefixLabel: 'Key prefix',
                    formatLabel: 'Zero fill the number'
                },
                search: {
                    textSearchTitle: 'Text search',
                    textSearchCaption: 'Find via toolbar text search. By default all questions in the first task (i.e {0}) are included.',
                    textSearchToggleLabel: 'Customise text search',
                    textSearchNodeIdsHint: 'Hint: Select a Step/Task to index all questions in them.',
                    fieldSearchTitle: 'Column filtering',
                    fieldSearchCaption: 'Filter and sort in tables. By default all questions in the first task (i.e. {0}) are included.',
                    fieldSearchToggleLabel: 'Customise column filtering',
                    fieldSearchNodeIdsHint: 'Hint: Select a Step/Task to index all questions in them.',

                },
                collection: {
                    collectionDeleteName: 'View',
                    collectionViewTitle: 'List view designs',
                    collectionViewButton: 'View',
                    collectionReportDesign: 'Report configuration',
                    save: 'Save',
                    clear: 'Clear',
                },
                design: {
                    title: 'Worq item design',
                    navigationStyleLabel: 'Navigation style',
                    completeStyleLabel: 'Complete style',
                    afterAddNavigationMode: 'After add navigation mode',
                    addMoreAvailable: 'Add more available',
                    theme: 'Theme',
                    color: 'Color',
                    icon: 'Icon',
                    resetToDefault: 'Reset to default',
                    fileSizeLimit: 'File size exceeds limit of 50 KB'
                },
                security: {
                    tabTitle: 'Security',
                    about1: 'You can override the default security provisions to further restrict who can see, edit, and delete your data.',
                    about2: 'Once you customise security the default set of access will be removed and only the policy stated here will apply.',
                    toggleLabel: 'Customise security',
                    grantDenyLabel: 'Type',
                    addLabel: 'Add rule',
                    addTitle: 'Add a new security rule',
                },
                actions: {
                    tabTitle: 'Actions',
                    about1: 'Allow a user to make actions related to the work items. These can be customized to be available depending on the state of the work item.',
                    about2: 'When the user makes an action, a work item will be created corresponding to the action.',
                    addLabel: 'Add action',
                    addMultipleLabel: 'Bulk action',
                    addTitle: 'Add a new action rule',
                }
            },
            sharing: {
                link: {
                    title: 'Hosted Authenticated URL',
                    description: 'To link to this {0} from another web page or to send the form via email, use the URL below:'
                },
                anonymous: {
                    title: 'Hosted Anonymous URL',
                    description: 'To link to this {0} from another web page or to send the form via email for anonymous users, use the URL below:'
                },
                dashboard: {
                    title: 'Dashboard View',
                    description: 'To link to a dashboard view of this template, use the URL below',
                }
            },
            preview: {
                resetButton: 'Re-Set Preview Data',
                closeButton: 'Close Preview',
            },
            types: {
                workspace: {
                    name: 'Workspace',
                    namePlural: 'Workspaces'
                },
                itp: {
                    name: 'ITP',
                    namePlural: 'ITPs'
                },
                audit: {
                    name: 'Audit',
                    namePlural: 'Audits'
                },
                dashboard: {
                    name: 'Dashboard',
                    namePlural: 'Dashboards'
                },
                form: {
                    name: 'Form',
                    namePlural: 'Forms'
                },
                issue: {
                    name: 'Issue',
                    namePlural: 'Issues'
                },
                project: {
                    name: 'Project',
                    namePlural: 'Project'
                },
                subject: {
                    name: 'Subject',
                    namePlural: 'Subjects'
                },
                swms: {
                    name: 'SWMS',
                    namePlural: 'SWMS'
                },
                all: {
                    name: 'WORQ',
                    namePlural: 'WORQ'
                },
            },
            errors: {
                notLoaded: 'Template is not yet loaded.',
                saveError: 'There was an error saving this template.',
                saveAborted: 'Saving this template failed too many times and will not be automatically tried again.',
            },
            schema: {
                schemaId: 'Schema Id',
                addTitle: 'Add Property',
                addLabel: 'Add Property',
                property: 'Property'
            }
        },
        project: {
            name: 'Project',
            namePlural: 'Projects',
            show: {
                editButton: 'Edit',
                addExecutionButton: 'Add',
                offlineMessage: 'All worq items have been downloaded. You may work on any worq item without an internet connection.',
                offlinePhotoDownload: 'Photos for offline',
                offlineExecutionDownload: 'Worq items for offline',
                bulkDeleteConfirm: 'Are you sure you want to delete {0} worq items?',
                bulkRestoreConfirm: 'Are you sure you want to restore {0} worq items?',
                bulkDeleteSuccess: 'Deleting {0} worq items',
                bulkDeleteFailure: 'Error encountered deleting worq items',
                bulkUnDeleteSuccess: 'Restoring {0} worq items',
                executionListTitle: 'Found {0} worq items',
                executionListQuestionTitle: 'Found {0}',
                executionListTitleDeleted: 'Found {0} DELETED worq items',
                toggleSaveOffline: 'Save Offline',
            },
            addExecution: {
                title: 'Add worq items to {0}',
                templateLabel: 'Type',
                successMessage: '{0} x {1} added to project',
                successMessageSingle: '{0} added to project',
                submitButton: 'Add {0} worq items',
                procedureCompileErrorMessage: '{0} has errors that must be fixed before it may be used.'
            },
            list: {
                projectNameColumn: 'Name',
                projectStatusColumn: 'Status',
                executionCountColumn: 'Worq items',
                projectCompletedRatioColumn: 'Completed',
                photoCountColumn: 'Attachments',
                selectedWorqItems: '{0} item(s) selected',
                allowSelection: 'Allow selection',
                hideSelection: 'Hide selection',
                moreThan20Items: 'Max selection of 20 items',
                bulkActions: {
                    assign: 'Assign',
                    delete: 'Delete',
                    cancel: 'Cancel',
                    overMaxSelection: 'Maximum of {0} item(s)',
                }
            }
        },
        user: {
            activity: {
                title: 'My Activity'
            }
        },
        activity: {
            none: 'No activity',
            name: 'Activity',
            namePlural: 'Activities'
        },
        versions: {
            title: 'Versions'
        },
        submitted: {
            redirecting: 'Redirecting in 5 seconds'
        },
        resource: {
            deletedMessage: 'This record is marked as deleted',
            destroyedMessage: 'This record has been permanently deleted',
            saveTimeout: 'Saving timed out',
            worqSaved: 'All changes are synced',
        },
        warnings: {
            integerRangeExceeded: 'Value entered for number exceeds the allowed range',
            integerRangeLowered: 'Value entered for number is lower than the allowed range',
            invalidValue: 'Invalid value'
        },
        manuals: {
            name: 'Manual',
            namePlural: 'Manuals',
            bookmarksName: 'Bookmark',
            bookmarksNamePlural: 'Bookmarks',
            addBookmark: {
                title: 'Add Bookmark',
                successMessage: 'Bookmark added!',
                invalidMessage: 'Invalid Bookmark! Cannot add, Please choose another',
            },
            editBookmark: {
                title: 'Edit Bookmark'
            },
            showBookmark: {
                title: 'Show Bookmark'
            },
            searchBookmark: {
                title: 'Search Bookmark',
                titlePlural: 'Search Bookmarks',
            },
            helpDocument: {
                fullScreenAction: 'Show Full Document'
            },
            deleteBookmark: {
                title: 'Delete Bookmark',
                confirmMessage: 'Are you sure you want to delete this bookmark?',
                successMessage: 'Bookmark deleted!',
            },
        },
        dataTable: {
            title: 'Total',
            toggleShowDeleted: 'Toggle show deleted',
        },
        selectBox: {
            placeholder: 'Type to search and select item',
            placeholderPlural: 'Type to search and select items',
            nearMeTitle: 'Search near me',
            defaultPlaceholder: 'Select me',
        },
        inputBox: {
            commentBoxPlaceholder: 'Add a note...',
            searchPlaceholder: 'Search...',
            globalSearchPlaceholder: 'Search all...',
            searchListPlaceholder: 'Search list...',
        },
        searchResults: {
            title: 'Showing search results for: {0}'
        },
        buttons: {
            closeAssignmentsDialog: 'Close assignments dialog',
            closeDetailsPopover: 'Close details details',
            closeProgressPopover: 'Close progress details',
            showProgressPopover: 'Show progress details',
            closePhotoSelection: 'Close selection',
            downloadSelectedPhotos: 'Download selected photos',
            deleteSelectedPhotos: 'Delete selected photos',
            showPhotoPreview: 'Show photo preview',
            showAttachments: 'Show Photo or File Attachments',
            uploadAttachments: 'Upload Photo or File',
            graphAutoSave: 'Save now',
            showGraphAutoSavingList: 'Sync',
            graphClearReload: 'Clear all cached data and reload the application',
            graphClientDelete: 'Graph client delete',
            graphTroubleshootMode: 'Toggle troubleshoot mode',
            graphDestroy: 'Graph destroy',
            graphNodeView: 'Graph node view',
            graphReload: 'Graph reload',
            pageOptionMenu: 'Page Option Menu',
            add: 'Add',
            open: 'Open',
            close: 'Close',
            cancel: 'Cancel',
            delete: 'Delete',
            reload: 'Reload',
            refresh: 'Refresh',
            edit: 'Edit',
            clear: 'Clear',
            undo: 'Undo',
            restore: 'Restore',
            unknown: 'Skip this question',
            import: 'Import',
            hide: 'Hide',
            prev: 'Previous',
            next: 'Next',
            done: 'Done',
            save: 'Save',
            download: 'Download',
            downloadAttachment: 'Download attachment',
            email: 'Email',
            call: 'Call',
            list: 'List',
            copy: 'Copy',
            map: 'Map',
            mine: 'Mine',
            all: 'All',
            touch: 'Touch',
            sign: 'Sign',
            view: 'View',
            reSign: 'Re-sign',
            searching: 'Searching',
            syncing: 'Syncing',
            syncOffline: 'Sync unavailable while offline',
            syncNow: 'Sync now',
            resolved: 'Resolved',
            escalated: 'Escalated',
            notAnIssue: 'Not an issue',
            searchThisArea: 'Search this area',
            updateArea: 'Update area',
            attachFile: 'Attach a file',
            addPhoto: 'Add a photo',
            showLess: 'Show less',
            showMore: 'Show more',
            viewAll: 'View all',
            print: 'Print',
            discardChanges: 'Discard Changes',
            saveChanges: 'Sync Changes',
            keepChanges: 'Sync next login',
            addExecution: 'Add execution',
            permanentlyDelete: 'Permanently Delete',
            previewMode: 'Preview mode',
            procedurePush: 'Procedure push',
            executionPull: 'Pull',
            toggleOffline: 'Toggle Offline',
            diagnostics: 'Diagnostics',
            showStatus: 'Show status',
            toggleTroubleshootMode: 'Toggle troubleshoot mode',
            toggleContentView: 'Toggle content view',
            goPreviousStep: 'Go to previous step',
            goNextStep: 'Go to next step',
            showAllExecutions: 'Show all executions',
            addSibling: 'Add sibling below selected',
            addChild: 'Add child to selected',
            moveSelectedUp: 'Move the selected item up',
            moveSelectedDown: 'Move the selected item down',
            deleteSelectedItem: 'Delete the selected item',
            restoreSelectedItem: 'Restore the selected item',
            hideDeletedNodes: 'Hide deleted nodes',
            showDeletedNodes: 'Show deleted nodes',
            showMyProjects: 'Show projects for my group',
            showAllProjects: 'Show all projects',
            showAllUsers: 'Show All Users',
            viewTemplate: 'View Template',
            addLink: 'Add Link',
            removeLink: 'Remove Link',
            copyToClipboardHint: 'Copy to clipboard',
            tryIt: 'Try it',
            viewPivotTable: 'Report',
            viewDataGrid: 'Table',
            fieldList: 'Field List',
        },
        checkBox: {
            primary: 'Primary checkbox',
            secondary: 'Secondary checkbox',
        },
        loader: {
            main: 'Main loader',
            progressBar: 'Progress bar',
            loadingMessage: 'Loading',
        },
        labels: {
            completed: 'Completed',
            modified: 'Modified',
            created: 'Created',
            distance: 'Distance',
            seeMoreResults: 'See more results',
            create: 'Create',
            preview: 'Preview',
            search: 'Search',
            cancel: 'Cancel',
        },
        imageEditor: {
            title: 'Image Editor',
            unavailableOffline: 'Unavailable offline',
            unavailableOfflineLarge: 'You are working offline and the attachment has not been cached',
            downloadError: 'Image error',
            downloadErrorLarge: 'Error downloading image, please refresh the page and try again',
        },
        richTextEditor: {
            confirmTextSelectedToAddLink: 'Please select the text to make into a link',
        },
    },
    evo: {
        procedures: "Worq items"
    }
});


export const linkLongTitle = (link, toExecution) => {
    let key = toExecution?.key || link.toNodeKey || (toExecution?.preview ? strings.execution.show.previewBlankKey : strings.execution.show.blankKey);
    let title = toExecution?.title || link.toNodeTitle || strings.execution.show.blankTitle;
    return key + ' - ' + title;
}
export const executionLongTitle = (execution) => {
    if (!execution) {
        return '';
    }
    let key = execution.key || strings.execution.show.blankKey;
    let title = executionTitle(execution);
    let preview = execution.preview === true ? ' ' + strings.execution.show.titlePreviewPostfix : ''
    return key + ' - ' + title + preview;
}
export const executionTemplateTitle = (execution) => {
    if (!execution) {
        return '';
    }
    let key = execution.key || strings.execution.show.blankKey;
    let templateName = execution.name;
    return templateName + ' ' + key;
}
export const executionTitle = (execution) => {
    if (!execution) {
        return '';
    }
    return execution.title || strings.execution.show.blankTitle;
}
