import {
    HELP_DOCUMENT_CREATE_ERROR,
    HELP_DOCUMENT_CREATE_START,
    HELP_DOCUMENT_CREATED,
    HELP_DOCUMENT_LOAD_END,
    HELP_DOCUMENT_LOAD_START,
    HELP_DOCUMENT_LOADED,
    HELP_DOCUMENT_UPDATE_ERROR,
    HELP_DOCUMENT_UPDATE_START,
    HELP_DOCUMENT_UPDATED,
    HELP_POINTS_LOADED,
    SET_HELP_DOCUMENT_SOURCE,
    UPDATE_HELP_POINT,
} from "./helpManualActionTypes";
import {execute} from "./manualServices";
import {getApiBaseUrl} from "./commonActions";

export const setHelpDocumentSource = (helpDocumentSource) => {
    return {
        type: SET_HELP_DOCUMENT_SOURCE,
        payload: helpDocumentSource
    }
};
export const updateHelpPointNode = (helpPoint) => {
    return {
        type: UPDATE_HELP_POINT,
        payload: helpPoint
    }
};

export const searchHelpDocument = (criteria) => {
    const url = `${getApiBaseUrl()}/helpdocument?externalId=${encodeURIComponent(criteria.externalId)}`;
    const actions = {loading: HELP_DOCUMENT_LOAD_START, success: HELP_DOCUMENT_LOADED, error: HELP_DOCUMENT_LOAD_END};
    return execute('get', url, null, actions, criteria);
};

export const loadHelpDocument = (helpDocumentId, helpDocumentSource) => {
    const url = `${getApiBaseUrl()}/helpdocument/${helpDocumentId}`;
    const actions = {success: HELP_DOCUMENT_LOADED};
    return execute('get', url, null, actions, helpDocumentSource);
};

export const createHelpDocument = (helpDocument, helpDocumentSource) => {
    const url = `${getApiBaseUrl()}/helpdocument`;
    const actions = {
        loading: HELP_DOCUMENT_CREATE_START,
        success: HELP_DOCUMENT_CREATED,
        error: HELP_DOCUMENT_CREATE_ERROR
    };
    return execute('post', url, helpDocument, actions, helpDocumentSource);
};

export const updateHelpDocument = (helpDocument, helpDocumentSource) => {
    const url = `${getApiBaseUrl()}/helpdocument/${helpDocument.id}?excludeBookmarks=true`;
    const actions = {
        loading: HELP_DOCUMENT_UPDATE_START,
        success: HELP_DOCUMENT_UPDATED,
        error: HELP_DOCUMENT_UPDATE_ERROR
    };
    return execute('put', url, helpDocument, actions, helpDocumentSource);
};

export const getHelpPoints = (criteria) => {
    const url = `${getApiBaseUrl()}/helppoint?externalId=${encodeURIComponent(criteria.externalId)}`;
    const actions = {success: HELP_POINTS_LOADED};
    return execute('get', url, null, actions, criteria);
};
