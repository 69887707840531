import React from "react";
import withStyles from '@mui/styles/withStyles';
import {useDiagnosticsOn} from "../../../hooks/nodeHooks";
import JSONPretty from "react-json-pretty";

const JsonLogicViewer = ({classes, jsonLogic, jsonLogicStr}) => {
    const diagnosticsOn = useDiagnosticsOn()
    if (!diagnosticsOn) {
        return null
    }
    jsonLogic = jsonLogic || JSON.parse(jsonLogicStr || "{}")
    return (
        <>
            <div>
                <pre className={classes.preStyle}>{JSON.stringify(jsonLogic, null, 2)}</pre>
            </div>
            {typeof (jsonLogic) === 'object' &&
                <div className="query-builder-result">
                    <JSONPretty id="json-pretty" node={jsonLogic}/>
                </div>
            }
        </>
    );
}
const styles = () => ({
    preStyle: {backgroundColor: "lightgray", margin: "10px", padding: "10px"}
});
export default withStyles(styles)(JsonLogicViewer);