import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../../../style/alert.css';
import {blurNodeProperty, putNodesProperty} from "../../../../actions";
import 'date-fns';
import {ChipGroup} from "tbf-react-library";
import ExecutionQuestionSelectResizeBase from "./ExecutionQuestionSelectResizeBase";
import sortBy from "lodash/sortBy";
import {calculateTextSize} from "../../../../util/util";
import { strings } from '../../../components/SopLocalizedStrings';

class ExecutionQuestionSelectChip extends ExecutionQuestionSelectResizeBase {

    handleClick = (currentValue, newValue) => {
        const {selectMany, field} = this.props;
        if (selectMany) {
            if (!currentValue) {
                currentValue = [];
            }
            const index = currentValue.indexOf(newValue);
            let newArray = [...currentValue];
            if (index === -1) {
                newArray.push(newValue);
            } else {
                newArray.splice(index, 1);
            }
            this.updateNodeProperty(field, newArray, field);
        } else {
            const clear = currentValue === newValue;
            this.updateNodeProperty(field, clear ? null : newValue, field, true);
        }
    };

    render = () => {
        const {
            value,
            options = [],
            selectMany,
            disabled,
            width,
            classes,
            questionId,
        } = this.props;
        const { parentWidth } = this.state;
        const values = value ? value : [];
        const optionPercentage = width/parentWidth;
        const isMoreThan50Percent = optionPercentage > 0.5;

        if (options.length === 0) {
            return <span className={classes.noOptions}>{strings.execution.questions.select.noOptions}</span>
        }

        let chipGroupStyle = undefined;
        if(isMoreThan50Percent) {
            chipGroupStyle = {
                flexGrow: 1,
                alignItems: "flex-start",
                flexDirection: "column"
            }
        }

        return <ChipGroup ref={this.selectorRef}
                          style={chipGroupStyle}
                          column={isMoreThan50Percent} value={values} options={options} multiple={selectMany} disabled={disabled}
                          onChange={(newValue) => this.handleClick(value, newValue)}
                          truncateText={false}
                          labelledBy={`question-label-${questionId}`}
        />;
    }
}

const styles = (theme) => ({
    noOptions: {
        color: theme.palette.grey.four.main,
    }
});
ExecutionQuestionSelectChip.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    let value = executionQuestionNode[ownProps.field];
    let options = executionQuestionNode.optionsParsed;

    const sortedOptons = sortBy(options, opt => opt.label.length);
    const longestWidth = sortedOptons[sortedOptons.length - 1]; // get the last item, since this is sorted by asc
    const width = calculateTextSize({text: longestWidth?.label})

    return {
        options: options,
        value: value,
        selectMany: executionQuestionNode.selectMany,
        width: width
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionSelectChip));
