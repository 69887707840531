import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeSchemaOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import PageComponentBase from "../PageComponentBase";
import Loader from "../components/Loader";
import {putNode} from "../../actions";
import {createNode} from "../../factory/graphFactory";

class ProjectNew extends PageComponentBase {

    componentDidMount() {
        super.componentDidMount();
        let {projectSchema, onPutNode} = this.props;
        let node = createNode(projectSchema);
        onPutNode(node);
        this.props.history.replace('/projects/' + node.id + '/edit');
    }

    render() {
        return (
            <div>
                <Loader source={'Project New'}/>
            </div>
        )
    }
}

const styles = () => ({});
ProjectNew.propTypes = {};
const mapStateToProps = (state) => {
    return {
        projectSchema: getNodeSchemaOrNull(state, 'ProjectRoot')
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNode: node => dispatch(putNode(node)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProjectNew)));
