import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../../style/alert.css';
import "../../../style/form.css";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import {strings} from "../../components/SopLocalizedStrings";
import Box from "@mui/material/Box";
import {getActiveDescendantsAndSelfIfPresent, getNodeOrNull} from "../../../selectors/graphSelectors";
import {putNodesProperty} from "../../../actions";
import NodeTextField from "../../graph/NodeTextField";
import {Permissions} from "../../../permissions";
import GraphDelete from "../../graph/GraphDelete";
import GraphDeleteMessage from "../../graph/GraphDeleteMessage";
import ProcedureRuleBuilderContainer from "./ProcedureRuleBuilderContainer";
import {ComponentBase, SharedAuth} from "tbf-react-library";
import {NODE_IDS, NODE_TYPE_OPTIONS, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import ProcedureRuleAutoCreateEditor from "./ProcedureRuleAutoCreateEditor";
import GraphResourceLoad from "../../graph/GraphResourceLoad";
import ProcedureRuleToggleEditor from "./ProcedureRuleToggleEditor";
import ProcedureRuleNodeIdSelector from "./ProcedureRuleNodeIdSelector";
import ProcedureRuleFilterEditor from "./ProcedureRuleFilterEditor";
import AlphaFeature from "../../components/AlphaFeature";
import ProcedureSelector from "../ProcedureSelector";
import ProcedureCalculateEditor from "./ProcedureCalculateEditor";
import {HOURS_1} from "../../../util/constants";
import ProcedureRuleCollectionOrderBys from "../collection/ProcedureCollectionOrderBys";

const pageStrings = strings.procedure.rule;
const initialState = {
    open: false,
};

class ProcedureRuleEditor extends ComponentBase {

    constructor(props) {
        super(props);
        this.state = initialState;
        this.containerRef = React.createRef();
    }

    onSubmit = (event) => {
        event.preventDefault();
        let {onPutNodesProperty, rule} = this.props;
        onPutNodesProperty([{id: rule.id, draft: false}]);
        this.props.handleClose();
        return false;
    }

    positionDialog = () => {
      if (this.containerRef.current) {
        const bb = this.containerRef.current.getBoundingClientRect()
        const left = (window.innerWidth - bb.width) / 2
        this.containerRef.current.style.left = `${left}px`
      }
    }

    componentDidMount () {
      window.addEventListener('resize', this.positionDialog)
      this.positionDialog()
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.positionDialog)
    }

    render() {
        let {
            classes,
            rule,
            disabled,
            copyToOptions,
            showDeleted,
            handleClose,
        } = this.props;
        let isNew = rule.draft === true;
        return (
            <React.Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className={classes.paper} ref={this.containerRef}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h1" color="inherit" noWrap>{pageStrings.title}</Typography>
                            </Grid>
                            {
                                (rule.linkMatchProcedureIds || []).map(id => (
                                    <GraphResourceLoad
                                        key={id}
                                        resourcePath={NODE_IDS.ProcedureFull(id)}
                                        nodeId={id}
                                        friendlyName={strings.procedure.name}
                                        nodeType={'ProcedureRoot'}
                                        reloadIntervalMs={HOURS_1}
                                    />))
                            }
                            {
                                rule.createExecutionProcedureId &&
                                <GraphResourceLoad
                                    key={rule.createExecutionProcedureId}
                                    resourcePath={NODE_IDS.ProcedureFull(rule.createExecutionProcedureId)}
                                    nodeId={rule.createExecutionProcedureId}
                                    friendlyName={strings.procedure.name}
                                    nodeType={'ProcedureRoot'}
                                    reloadIntervalMs={HOURS_1}
                                />
                            }
                            {
                                rule.deleted &&
                                <Grid item xs={12}>
                                    <GraphDeleteMessage nodeId={rule.id}/>
                                </Grid>
                            }
                            {/*<Grid item xs={12}>*/}
                            {/*    <NodeTextField nodeId={rule.id}*/}
                            {/*                   visual={'select'}*/}
                            {/*                   nodePropertyName={'actionTypes'}*/}
                            {/*                   disabled={disabled}/>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                <Typography variant="h2" color="inherit" noWrap>{pageStrings.forTitle}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <NodeTextField nodeId={rule.id} nodePropertyName={'selfOn'}
                                               disabled={disabled}/>
                                {
                                    rule.nodeIdsVisible &&
                                    <Grid item xs={12} className={classes.boxLevel1}>
                                        <ProcedureRuleNodeIdSelector
                                            procedureId={rule.rootId}
                                            ruleId={rule.id}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                }
                                <NodeTextField nodeId={rule.id} nodePropertyName={'linkMatchOn'}
                                               disabled={disabled}/>
                                {
                                    rule.linkMatchOn &&
                                    <Box className={classes.boxLevel1}>
                                        <ProcedureSelector nodeId={rule.id} disabled={disabled}
                                                           propertyName={'linkMatchProcedureIds'}/>
                                        <NodeTextField nodeId={rule.id}
                                                       visual={'select'}
                                                       nodePropertyName={'linkMatchLinkTypes'}
                                                       disabled={disabled}/>
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h2" color="inherit"
                                            noWrap>{pageStrings.conditionTitle}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <NodeTextField nodeId={rule.id} nodePropertyName={'alwaysOn'}
                                               disabled={disabled}/>
                                {/*<NodeTextField nodeId={rule.id} nodePropertyName={'numberMinOn'} disabled={disabled}/>*/}
                                {/*<NodeTextField nodeId={rule.id} nodePropertyName={'numberMaxOn'} disabled={disabled}/>*/}
                                {/*<NodeTextField nodeId={rule.id} nodePropertyName={'numberBetweenOn'} disabled={disabled}/>*/}
                                {/*<NodeTextField nodeId={rule.id} nodePropertyName={'selectAnyOn'} disabled={disabled}/>*/}
                                <NodeTextField nodeId={rule.id} nodePropertyName={'conditionOn'}
                                               disabled={disabled}/>
                                {
                                    rule.conditionOn &&
                                    <Box className={classes.boxLevel1}>
                                        <ProcedureRuleBuilderContainer
                                            nodeId={rule.id}
                                            ruleProperty={'condition'}
                                            includeLinks={false}
                                            disabled={disabled}
                                        />
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h2" color="inherit"
                                            noWrap>{pageStrings.actionTitle}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <NodeTextField nodeId={rule.id} nodePropertyName={'invalidInputOn'}
                                               disabled={disabled}/>
                                {
                                    rule.invalidInputOn &&
                                    <Box className={classes.boxLevel1}>
                                        <NodeTextField nodeId={rule.id}
                                                       nodePropertyName={'invalidInputMessage'}
                                                       visual={'editor'}
                                                       disabled={disabled}/>
                                    </Box>
                                }
                                <NodeTextField nodeId={rule.id} nodePropertyName={'visibleOn'} disabled={disabled}/>
                                <NodeTextField nodeId={rule.id} nodePropertyName={'messageOn'} disabled={disabled}/>
                                {
                                    rule.messageOn &&
                                    <Box className={classes.boxLevel1}>
                                        <NodeTextField nodeId={rule.id} nodePropertyName={'messageType'}
                                                       disabled={disabled}/>
                                        <NodeTextField nodeId={rule.id}
                                                       nodePropertyName={'message'}
                                                       visual={'editor'}
                                                       disabled={disabled}/>
                                    </Box>
                                }
                                <NodeTextField nodeId={rule.id} nodePropertyName={'raiseIssueOn'}
                                               disabled={disabled}/>
                                {
                                    rule.raiseIssueOn &&
                                    <NodeTextField nodeId={rule.id}
                                                   nodePropertyName={'raiseIssueMessage'}
                                                   visual={'editor'}
                                                   disabled={disabled}/>
                                }
                                <Box className={classes.boxLevel1}>
                                    <NodeTextField nodeId={rule.id} nodePropertyName={'raiseIssueResolveOn'}
                                                   disabled={disabled}/>
                                    {/*<NodeTextField nodeId={rule.id} nodePropertyName={'raiseIssueReAnswerOn'}*/}
                                    {/*               disabled={disabled}/>*/}
                                </Box>
                                <NodeTextField nodeId={rule.id} nodePropertyName={'photoRequiredOn'}
                                               disabled={disabled}/>
                                <NodeTextField nodeId={rule.id} nodePropertyName={'photoInstructionsOn'}
                                               disabled={disabled}/>
                                {
                                    rule.photoInstructionsOn &&
                                    <Box className={classes.boxLevel1}>
                                        <NodeTextField nodeId={rule.id}
                                                       nodePropertyName={'photoInstructionsMessage'}
                                                       visual={'editor'}
                                                       disabled={disabled}/>
                                    </Box>
                                }
                                <NodeTextField nodeId={rule.id} nodePropertyName={'commentRequiredOn'}
                                               disabled={disabled}/>
                                <NodeTextField nodeId={rule.id} nodePropertyName={'commentInstructionsOn'}
                                               disabled={disabled}/>
                                {
                                    rule.commentInstructionsOn &&
                                    <Box className={classes.boxLevel1}>
                                        <NodeTextField nodeId={rule.id}
                                                       nodePropertyName={'commentInstructionsMessage'}
                                                       visual={'editor'}
                                                       disabled={disabled}/>
                                    </Box>
                                }
                                <NodeTextField nodeId={rule.id} nodePropertyName={'createExecutionOn'}
                                               disabled={disabled}/>
                                {
                                    rule.createExecutionOn &&
                                    <Box className={classes.boxLevel1}>
                                        <NodeTextField nodeId={rule.id} nodePropertyName={'createExecutionLinkType'}
                                                       disabled={disabled}/>
                                        <ProcedureSelector
                                            nodeId={rule.id}
                                            disabled={disabled}
                                            propertyName={'createExecutionProcedureId'}
                                        />
                                        <ProcedureRuleAutoCreateEditor ruleId={rule.id} showDeleted={showDeleted}
                                                                       disabled={disabled}/>
                                    </Box>
                                }
                                <NodeTextField nodeId={rule.id} nodePropertyName={'linkToQuestionOn'}
                                               disabled={disabled}/>
                                {
                                    <Box className={classes.boxLevel1}>
                                        <NodeTextField nodeId={rule.id} nodePropertyName={'addExistingOn'}
                                                       disabled={disabled}/>
                                        <ProcedureRuleToggleEditor
                                            ruleId={rule.id}
                                            actionType={RULE_ACTION_TYPE.filter.id}
                                            disabled={disabled}
                                        />
                                        <ProcedureRuleFilterEditor parentRuleId={rule.id} disabled={disabled} />
                                        <NodeTextField nodeId={rule.id} nodePropertyName={'addNewOn'}
                                                       disabled={disabled}/>

                                        <ProcedureRuleToggleEditor
                                            ruleId={rule.id}
                                            actionType={RULE_ACTION_TYPE.collectionOrder.id}
                                            disabled={disabled}
                                            ruleAttributes={{calculateValueOn: true}}
                                        >
                                            <ProcedureRuleCollectionOrderBys
                                                disabled={disabled}
                                                multipleOrderBy={false}
                                                parentRuleId={rule.id}
                                                ruleActionType={RULE_ACTION_TYPE.collectionOrder.id}
                                            />
                                        </ProcedureRuleToggleEditor>

                                    </Box>
                                }
                                {/*<NodeTextField nodeId={rule.id} nodePropertyName={'assignToOn'} disabled={disabled}/>*/}
                                <NodeTextField nodeId={rule.id} nodePropertyName={'calculateValueOn'}
                                               disabled={disabled}/>
                                {
                                    rule.calculateValueOn &&
                                    <Box className={classes.boxLevel1}>
                                        <ProcedureCalculateEditor
                                            disabled={disabled}
                                            ruleId={rule.id}
                                        />
                                    </Box>
                                }
                                <NodeTextField nodeId={rule.id} nodePropertyName={'copyToOn'}
                                               disabled={disabled}/>
                                {
                                    rule.copyToOn &&
                                    <Grid item xs={12} className={classes.boxLevel1}>
                                        <NodeTextField nodeId={rule.id} nodePropertyName={'copyToNodeIds'}
                                                       disabled={disabled}
                                                       visual={'select'}
                                                       nodeValueOptions={copyToOptions}/>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <ProcedureRuleToggleEditor
                                        ruleId={rule.id}
                                        actionType={RULE_ACTION_TYPE.readOnly.id}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ProcedureRuleToggleEditor
                                        ruleId={rule.id}
                                        actionType={RULE_ACTION_TYPE.uniqueConstraint.id}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ProcedureRuleToggleEditor
                                        ruleId={rule.id}
                                        actionType={RULE_ACTION_TYPE.assignment.id}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ProcedureRuleToggleEditor
                                        ruleId={rule.id}
                                        actionType={RULE_ACTION_TYPE.commentHideAction.id}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ProcedureRuleToggleEditor
                                        ruleId={rule.id}
                                        actionType={RULE_ACTION_TYPE.textSearch.id}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ProcedureRuleToggleEditor
                                        ruleId={rule.id}
                                        actionType={RULE_ACTION_TYPE.fieldSearch.id}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ProcedureRuleToggleEditor
                                        ruleId={rule.id}
                                        actionType={RULE_ACTION_TYPE.geographicTools.id}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <AlphaFeature>
                                    <Grid item xs={12}>
                                        <ProcedureRuleToggleEditor
                                            ruleId={rule.id}
                                            actionType={RULE_ACTION_TYPE.reloadInterval.id}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ProcedureRuleToggleEditor
                                            ruleId={rule.id}
                                            actionType={RULE_ACTION_TYPE.computeOnClient.id}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ProcedureRuleToggleEditor
                                            ruleId={rule.id}
                                            actionType={RULE_ACTION_TYPE.computeOnServer.id}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ProcedureRuleToggleEditor
                                            ruleId={rule.id}
                                            actionType={RULE_ACTION_TYPE.label.id}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ProcedureRuleToggleEditor
                                            ruleId={rule.id}
                                            actionType={RULE_ACTION_TYPE.inlineCamera.id}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ProcedureRuleToggleEditor
                                            ruleId={rule.id}
                                            actionType={RULE_ACTION_TYPE.photoAspectRatio.id}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ProcedureRuleToggleEditor
                                            ruleId={rule.id}
                                            actionType={RULE_ACTION_TYPE.calculatePreviousVersions.id}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                </AlphaFeature>
                            </Grid>
                            <Grid item xs={12}>
                                <Toolbar disableGutters={true}>
                                    <Box flexGrow={1}/>
                                    {
                                        !disabled && isNew &&
                                        <React.Fragment>
                                            <Button color='secondary' variant='contained' className={classes.button}
                                                    title={strings.app.cancel}
                                                    onClick={handleClose}
                                                    data-cy='edit-rule-close'>{strings.app.cancel}</Button>
                                            <Button type={'submit'} color='primary' variant='contained'
                                                    title={strings.app.add}
                                                    className={classes.button}
                                                    data-cy='edit-rule-ok'>{strings.app.add}</Button>
                                        </React.Fragment>
                                    }
                                    {
                                        (disabled) &&
                                        <Button color='secondary' variant='contained' className={classes.button}
                                                title={strings.app.close}
                                                onClick={handleClose}
                                                data-cy='edit-rule-close'>{strings.app.close}</Button>
                                    }
                                    {
                                        !disabled && !isNew &&
                                        <React.Fragment>
                                            <GraphDelete nodeId={rule.id} iconButton={true}/>
                                            <Button type={'submit'} color='primary' variant='contained'
                                                    title={strings.app.ok}
                                                    onClick={handleClose}
                                                    className={classes.button}
                                                    data-cy='edit-rule-ok'>{strings.app.ok}</Button>
                                        </React.Fragment>
                                    }
                                </Toolbar>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </React.Fragment>
        )
    }
}

const styles = (theme) => ({
    paper: {
        position: 'absolute',
        width: '95%',
        maxWidth: 1000,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50px',
        maxHeight: 'calc(100vh - 100px)',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    button: {
        margin: theme.spacing(1),
    },
    grow: {
        flexGrow: 1,
    },
    link: {
        display: 'flex',
        alignItems: 'center'
    },
    linkAddIcon: {
        fill: theme.palette.add.main
    },
    boxLevel1: {
        marginLeft: theme.spacing(6)
    }
});
ProcedureRuleEditor.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let rule = getNodeOrNull(state, ownProps.ruleId);
    let copyToOptions = [];
    for (let procedureId of rule.linkMatchProcedureIds || []) {
        let procedure = getNodeOrNull(state, procedureId);
        let nodeQuestions = getActiveDescendantsAndSelfIfPresent(state, procedureId)
            .filter(a => a.type === NODE_TYPE_OPTIONS.ProcedureQuestion)
            .map(a => ({id: a.id, name: procedure.name + ' - ' + a.name}));
        copyToOptions.push(...nodeQuestions);
    }
    return {
        rule: rule,
        copyToOptions: copyToOptions,
        alpha: SharedAuth.userHasPermission(Permissions.feature.alpha),
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedureRuleEditor));
