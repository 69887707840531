import React, { useEffect, useState } from "react";
import withStyles from '@mui/styles/withStyles';
import {Paper} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Loader from "../components/Loader";
import { SharedAuth } from "tbf-react-library";
import { strings } from "../components/SopLocalizedStrings";

const SubmittedPage = ({executionKey, returnUrl, classes}) => {
    useEffect(() => {
        if (SharedAuth.isAnonymous() && returnUrl) {
            window.setTimeout(() => {
                window.top.location.href = returnUrl;
            }, 5000);
        }
    }, [returnUrl])

    return <div className={classes.container}>
        <Paper elevation={3}>
            <div className={classes.container}>
                <Grid container direction="column" alignItems="center" justify="center" spacing={2}>
                    <Grid item xs={12}>
                        <CheckCircleIcon fontSize={'large'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'h1'}>Thank you</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'h2'}>
                            Your form <b>{executionKey}</b> was successfully submitted.
                        </Typography>
                    </Grid>
                </Grid>
                {returnUrl &&
                    <div className={classes.redirect}>
                        <Loader source="Return Url" friendlyName={strings.submitted.redirecting} contentCentered linear/>
                    </div>   
                }
            </div>
        </Paper>
    </div>
}
const styles = (theme) => ({
    container: {
        padding: theme.spacing(3)
    },
    redirect: {
        padding: theme.spacing(2)
    }
});
export default withStyles(styles)(SubmittedPage);