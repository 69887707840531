import { fabric } from 'fabric'
import { selectImageEditorTool } from './actions'
import { pointerTool } from './reducer'

let isDrawingArrow = false
let arrowObject = null
let arrowOptions = null
let startingPoint = null

const translate = (points, translation) => {
  return points.map(({ x, y }) => ({
    x: x + translation.x,
    y: y + translation.y
  }))
}

const rotate = (points, angle) => {
  return points.map(({ x, y }) => ({
    x: x * Math.cos(angle) - y * Math.sin(angle),
    y: x * Math.sin(angle) + y * Math.cos(angle),
  }))
}

const drawArrow = (canvas, point) => {
  if(arrowObject) {
    canvas.remove(arrowObject)
  }

  const strokeWidth = arrowOptions.lineWidth || 4
  const arrowHeadSize = arrowOptions.arrowHeadSize || 24  // arrow head size
  const distance = Math.sqrt(Math.pow(point.x - startingPoint.x, 2) + Math.pow(point.y - startingPoint.y, 2))
  const angle = Math.atan2(point.y - startingPoint.y, point.x - startingPoint.x) // Opposite over adjacent
  const halfLineWidth = (strokeWidth / 2)

  var arrowPoints = [
    {
      x: 0,
      y: -halfLineWidth
    },
    {
      x: distance - arrowHeadSize,
      y: -halfLineWidth
    },
    // Start Of Arrow Head 
    {
      x: distance - arrowHeadSize,
      y: -(arrowHeadSize / 2)
    },
    {
      x: distance,
      y: 0
    },
    {
      x: distance - arrowHeadSize,
      y: arrowHeadSize / 2
    },
    // End Of Arrow Head 
    {
      x: distance - arrowHeadSize,
      y: halfLineWidth
    },
    {
      x: 0,
      y: halfLineWidth
    }
  ]

  const points = translate(rotate(arrowPoints, angle), startingPoint)
  const arrow = new fabric.Polyline(points, {
    ...arrowOptions
  })
  canvas.add(arrow)
  canvas.renderAll()
  arrowObject = arrow
}

export const toggleDrawingArrow = (canvas, point, options, dispatch) => {
  if(isDrawingArrow) {
    drawArrow(canvas, point)
    isDrawingArrow = false
    startingPoint = null
    arrowObject = null
    dispatch(selectImageEditorTool(pointerTool))
  } else {
    isDrawingArrow = true
    startingPoint = point
    arrowOptions = options
  }
}

export const handleArrowMouseMove = (canvas, tool, point) => {
  if(tool.type !== 'Arrow' && isDrawingArrow) {
    isDrawingArrow = false
    startingPoint = null
    arrowObject = null
  }
  if(tool.type === 'Arrow' && isDrawingArrow) {
    drawArrow(canvas, point)
  }
}
