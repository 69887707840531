import React from "react";
import withStyles from '@mui/styles/withStyles';
import {strings} from "../../components/SopLocalizedStrings";
import {PROCEDURE_LINK_STYLE, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import {Grid} from "@mui/material";
import ProcedureCollection from "../collection/ProcedureCollection";
import {useCallbackCreateCollectionViewRule} from "../../../hooks/procedureHooks";
import {useNodeOrNull, useRulesForNodeByActionIfPresent} from "../../../hooks/nodeHooks";
import {AddAction, Panel} from "tbf-react-library";
import ProcedureSettingsReportDesign from "./ProcedureSettingsReportDesign";

const pageStrings = strings.procedure.settings.collection;

const ProcedureSettingsCollectionDesign = ({nodeId, disabled, showDeleted}) => {
    const node = useNodeOrNull(nodeId);
    const collectionViewRules = useRulesForNodeByActionIfPresent(nodeId, RULE_ACTION_TYPE.collectionView.id).filter(a => showDeleted || !a.deleted)
    const createNew = useCallbackCreateCollectionViewRule(nodeId)
    return (<>
        <Panel title={pageStrings.collectionViewTitle}>
            {
                !disabled &&
                <Grid container>
                    <Grid item xs={12}>
                        <AddAction
                            label={strings.procedure.rule.addListViewDesignButton}
                            title={strings.procedure.rule.addListViewDesignButtonTip}
                            canAdd={!disabled}
                            onClick={createNew}
                            dataCy={'add-view'}
                        />
                    </Grid>
                </Grid>
            }
        </Panel>
        {
            collectionViewRules.map(a => (
                <ProcedureCollection key={a.id} ruleId={a.id} showDeleted={showDeleted} disabled={disabled}/>))
        }
        {
            node.linkStyle === PROCEDURE_LINK_STYLE.report.id && collectionViewRules[0] && <ProcedureSettingsReportDesign nodeId={nodeId} viewId={collectionViewRules[0].id} disabled={disabled} />
        }
    </>);
}
const styles = () => ({});
export default withStyles(styles)(ProcedureSettingsCollectionDesign);
