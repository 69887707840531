import React from "react";
import CompletedIcon from "@mui/icons-material/CheckCircleRounded";
import RunningIcon from "@mui/icons-material/TimelapseRounded";
import SkipRoundedIcon from "@mui/icons-material/SkipNextRounded";
import WaitingIcon from "@mui/icons-material/WatchLaterRounded";
import ErrorIcon from "@mui/icons-material/ErrorRounded";
import StopIcon from "@mui/icons-material/StopRounded";

class ExecutionStatusIcon extends React.Component {

    getProgressIcons = (status) => {
        switch (status.toLowerCase()) {
            case 'completed':
            case 'done':
                return <CompletedIcon style={{color: '#3F25F2'}}/>;
            case 'inprogress':
                return <RunningIcon style={{color: '#F4B010'}}/>;
            case 'skipped':
                return <SkipRoundedIcon style={{color: '#33bdc3'}}/>;
            case 'notstarted':
            case 'waiting':
                return <WaitingIcon style={{color: '#A6A6A6'}}/>;
            case 'exception':
                return <ErrorIcon style={{color: '#EF4C5D'}}/>;
            case 'stopped':
                return <StopIcon style={{color: '#EF4C5D'}}/>;
            case 'completedwitherrors':
                return <CompletedIcon style={{color: '#F4B010'}}/>;
            default:
                return <RunningIcon style={{color: '#F4B010'}}/>;
        }
    }

    render() {
        const {status} = this.props;
        return <React.Fragment>
            {this.getProgressIcons(status || '')}
        </React.Fragment>
    }
}

export default ExecutionStatusIcon;