import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import {putNodes} from "../../../actions";
import ImportExportIcon from '@mui/icons-material/ImportExportRounded';
import {strings} from "../../components/SopLocalizedStrings";
import {Permissions} from "../../../permissions";
import {ComponentBase, reportEvent, reportSuccess, reportUserError, SharedAuth} from "tbf-react-library";
import {withRouter} from "react-router-dom";
import Button from "@mui/material/Button";

const pageStrings = strings.procedure.show;

class ProcedureShowImport extends ComponentBase {

    constructor(props) {
        super(props);
        this.state = {invalidFile: false};
    }

    import = (filename, nodesById) => {
        const {history} = this.props;
        const nodes = Object.values(nodesById);
        // Draft rule ids may end up in here. 
        for (let node of nodes) {
            delete node.ruleIds;
        }
        const root = nodes.find(node => node.type === 'ProcedureRoot');
        if (!root) {
            reportUserError(pageStrings.importErrorMissingProcedureRoot);
            return;
        }
        root.editOn = true;
        root.importOn = true;
        reportEvent({name: 'Import Procedure'}, {id: root.id, name: root.name, filename: filename});
        this.props.onPutNodes(nodes);
        reportSuccess(strings.formatString(pageStrings.importSuccess));
        history.push(`/procedures/${root.id}`);
    };

    handleFileChange(event) {
        event.persist();
        let value;
        const allowedExtensions = /(\.json)$/i;
        const self = this;
        const reader = new FileReader();
        if (allowedExtensions.exec(event.target.value)) {
            reader.onload = function (e) {
                value = reader.result;
                self.setState({invalidFile: false});
                self.import(e.target.fileName, JSON.parse(value));
            };
            reader.readAsText(event.target.files[0], 'utf-8');
        } else {
            this.setState({invalidFile: true});
            document.getElementById('file').value = '';
        }

    }

    render() {
        const {invalidFile} = this.state;
        const {classes, canAdd} = this.props;
        return (
            <div>
                {
                    canAdd &&
                        <React.Fragment>
                            <input
                                accept=".json"
                                className={classes.input}
                                id='file'
                                name="importData"
                                type="file"
                                onChange={this.handleFileChange.bind(this)}
                            />
                            <label htmlFor="file">
                                <Button variant="outlined" component="span" color='secondary' data-cy='export'
                                        title={strings.buttons.import}>
                                    <ImportExportIcon/>&nbsp;&nbsp;
                                    {strings.buttons.import}
                                </Button>
                            </label>
                            {
                                invalidFile &&
                                <div className={classes.validFileErr}>{pageStrings.errorInvalidFileType}</div>
                            }
                        </React.Fragment>
                }
            </div>
        )
    }
}

const styles = () => ({
    validFileErr: {
        marginTop: '10px',
        width: '336px',
        padding: '10px',
        color: 'red',
        marginBottom: '10px'
    },
    input: {
        display: 'none',
    }
});
ProcedureShowImport.propTypes = {};
const mapStateToProps = () => {
    return {
        canAdd: SharedAuth.userHasPermission(Permissions.procedure.create)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodes: nodes => dispatch(putNodes(nodes))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProcedureShowImport)));
