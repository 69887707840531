import React from "react";
import {strings} from "../../components/SopLocalizedStrings";
import {useNodeOrError} from "../../../hooks/nodeHooks";
import SearchIcon from "@mui/icons-material/SearchRounded";
import {TbfAction} from "tbf-react-library";
import {Permissions} from "../../../permissions";

const ProcedureCollectionViewButton = React.forwardRef(({ruleId}, ref) => {
    const rule = useNodeOrError(ruleId)
    const procedure = useNodeOrError(rule.rootId)
    const isListingPageView = rule.nodeIds.includes(procedure.id)
    if (!isListingPageView) {
        return null;
    }
    const path = `/list/${procedure?.procedureType}/${rule?.rootId}?defaultView=${ruleId}`
    return (
        <TbfAction
            iconComponent={SearchIcon}
            path={path}
            dataCy={'view'}
            permission={Permissions.execution.read}
            ref={ref}
        >
            {strings.procedure.settings.collection.collectionViewButton}
        </TbfAction>
    );
})
export default ProcedureCollectionViewButton
