import React from "react";
import withStyles from '@mui/styles/withStyles';
import {strings} from "../components/SopLocalizedStrings";
import {TbfAction} from "tbf-react-library";
import {useNodeOrNull} from "../../hooks/nodeHooks";
import {useCallbackUpdateShowMap} from "./ExecutionViewHooks";
import MapIcon from "@mui/icons-material/Map";

const CollectionShowMapButton = ({selectorId, classes}) => {
    const selector = useNodeOrNull(selectorId);
    const showMap = selector?.showMap
    const handleClicked = useCallbackUpdateShowMap(selectorId, !showMap)
    if (!selector?.mapAvailable) {
        return null;
    }
    return (
        <TbfAction
            className={classes.toggleMapButton}
            onClick={handleClicked}
            title={showMap ? strings.inputBox.list : strings.inputBox.map}
            iconComponent={MapIcon}
            data-cy={showMap ? "hide-map" : "show-map"}
        >
            {showMap ? 'List' : 'Map'}
        </TbfAction>
    )
}
const styles = (theme) => ({
    toggleMapButton: {
        flexShrink: 0,
        flexGrow: 0,
        backgroundColor: "#fff",
    },
});
export default withStyles(styles)(CollectionShowMapButton);