import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../../../style/alert.css';
import RenderDraftJs from "../../../components/RenderDraftJs";
import {ComponentBase} from "tbf-react-library";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";

class ExecutionQuestionRulePhotoInstructions extends ComponentBase {

    render() {
        let {classes, photoInstructionsDraftJs, photoInstructionsText} = this.props;
        if (!photoInstructionsDraftJs && !photoInstructionsText) {
            return null;
        }
        return (
            <div className={classes.photoInstructionsContainer} data-cy-element={'Message'}>
                <CameraAltRoundedIcon className={classes.photoInstructionsRuleIcon}/>
                <div className={classes.photoInstructionsRuleMessage}>
                    {
                        photoInstructionsText
                    }
                    {
                        photoInstructionsDraftJs &&
                        <RenderDraftJs contentState={photoInstructionsDraftJs}/>
                    }
                </div>
            </div>);
    }

    renderPhotoInstructionsRule(rule) {
    }
}

const styles = () => ({
    photoInstructionsContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 4,
    },
    photoInstructionsRuleIcon: {
        marginRight: 2,
        fontSize: 18,
        zIndex: 99,
    },
    photoInstructionsRuleMessage: {
        position: 'relative',
        '& p': {
            marginBottom: 4,
            '&:first-child': {
                marginBottom: 0,
            }
        }
    },
});
ExecutionQuestionRulePhotoInstructions.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let node = getNodeOrNull(state, ownProps.ruleId);
    return {
        photoInstructionsDraftJs: node ? node.photoInstructionsMessage : null
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionRulePhotoInstructions));
