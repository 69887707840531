import React, { useCallback, useMemo } from "react";
import {useJsonLogicReferences, useNodeOrNull} from "../../hooks/nodeHooks";
import {useDispatch} from "react-redux";
import {blurNodeProperty, focusNodeProperty, putNodeProperty} from "../../actions";
import { JsonLogicEditor } from 'tbf-jsonlogic-editor';
import { methods, operators } from 'tbf-jsonlogic';
import JsonLogicViewer from "./rules/JsonLogicViewer";
import forEach from 'lodash/forEach'
import last from 'lodash/last'
import { loader } from '@monaco-editor/react'
import { PUBLIC_URL } from 'tbf-react-library'

// We have copied the monaco library into the public folder and have set the monaco loader to use this location,
// we did that because we are now blocking other domains and the loaded uses the npm cdn to dynamically load the monaco library
loader.config({ paths: { vs: `${PUBLIC_URL}/monaco-editor-0.34.1/vs` } });

const ProcedureRuleCalculate = ({ruleId, disabled, nodePropertyName}) => {
    const rule = useNodeOrNull(ruleId);
    const jsonLogicReferences = useJsonLogicReferences(rule?.rootId);
    const value = rule[nodePropertyName];
    const dispatch = useDispatch();

    const handleChange = (conditionHuman) => {
        dispatch(putNodeProperty({
            id: ruleId,
            [nodePropertyName]: conditionHuman?.replace('= ', '=')
        }));
    }

    const handleFocus = () => {
        dispatch(focusNodeProperty(ruleId, nodePropertyName));
    }

    const handleBlur = () => {
        dispatch(blurNodeProperty(ruleId, nodePropertyName));
    }

    // Creates a hierarchical structure for the nodes
    const references = useMemo(() => {
      const results = []
      const items = [{shortName: 'User', display: 'User'},{shortName: 'Links', display: 'Links'}, ...(jsonLogicReferences.references || [])]
      forEach(items, (reference) => {
        const shortNamePath = reference.shortName.split('.')
        if (shortNamePath.length > 1) {
            const parentShortName = shortNamePath.slice(0, shortNamePath.length - 1).join('.')
            const parent = items.find(({ shortName }) => shortName === parentShortName)
            parent.children = [...(parent.children || []), {
                shortName: last(shortNamePath)
            }]
        }
          results.push(reference)
      })
      return results
    }, [jsonLogicReferences?.references?.length])

    const getReferences = useCallback((parentReferencePath) => {
      let currentReferences = [];
      if (parentReferencePath) {
          currentReferences = references.find(ref => ref.shortName.toLowerCase() === parentReferencePath.toLowerCase())?.children;
      } else {
          currentReferences = references.filter(ref => {
              const shortNamePath = ref.shortName.split('.')
              return shortNamePath.length === 1;
          });
      }
        return currentReferences?.map(({ shortName, display }) => ({
            label: display && display.toLowerCase() !== shortName.toLowerCase() ? `${shortName} ${display}` : shortName,
            insertText: shortName
            // detail: 'Right hand side',
            // documentation: [
            //   { value: 'help text' }
            // ]
        }))
    }, [references?.length])

    const getHoverContents = useCallback((path) => {
      const pathSplit = path.split('.')
      const firstPath = pathSplit[0]
      const ref = jsonLogicReferences.references.find(({ shortName }) => shortName === firstPath)
      if (ref) {
        pathSplit[0] = ref.display
        return [
          { supportHtml: true, value: `${pathSplit.join('.')}` }
        ]
      }
    }, [jsonLogicReferences?.references?.length])

    return <>
        <JsonLogicEditor
            disabled={disabled}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value}
            getReferences={getReferences}
            getHoverContents={getHoverContents}
            methods={methods}
            operators={operators}
            id={nodePropertyName}
        />
        <JsonLogicViewer jsonLogicStr={rule?.calculateValueQuery}/>
    </>
}

export default ProcedureRuleCalculate;
