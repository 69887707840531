import React from 'react';
import {connect} from 'react-redux'
import {putNodeProperty} from '../../../../../actions'
import {getNodeOrNull} from '../../../../../selectors/graphSelectors'
import withTheme from '@mui/styles/withTheme';
import ExecutionQuestionInputBase from '../ExecutionQuestionInputBase'
import {Signature} from "tbf-react-library";

class SignatureWrapper extends ExecutionQuestionInputBase {

    handleSaveSignature = (value) => {
        const {field} = this.props
        this.updateNodeProperty(field, value, field)
    }

    render = () => {
        const {value, disabled, editing, date, onToggleEditMode, loadSignature} = this.props;
        return <Signature
            loadSignature={loadSignature}
            value={value}
            disabled={disabled}
            editing={editing}
            date={date}
            onToggleEditMode={onToggleEditMode}
            onChange={this.handleSaveSignature}
        />
    }
}

const mapStateToProps = (state, ownProps) => {
    const executionQuestionNode = getNodeOrNull(state, ownProps.questionId)
    const value = executionQuestionNode[ownProps.field]
    const date = executionQuestionNode[ownProps.field + 'DateTime']
    return {
        value,
        date,
    }
}

const mapDispatchToProps = (dispatch) => ({
    onPutNodeProperty: node => dispatch(putNodeProperty(node))
})

export default connect(mapStateToProps, mapDispatchToProps)(withTheme((SignatureWrapper)))
