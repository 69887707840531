import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ImportExportIcon from "@mui/icons-material/ImportExportRounded";
import {ComponentBase, TbfMenuItem} from "tbf-react-library";
import {strings} from "../../components/SopLocalizedStrings";
import DocumentShowExport from "../../graph/DocumentShowExport";
import {getNodeOrNull} from "../../../selectors/graphSelectors";

const pageStrings = strings.procedure.show;

class DocumentExportButton extends ComponentBase {

    constructor(props) {
        super(props);
        this.state = {download: false}
        this.handleDownload = this.handleDownload.bind(this);
    }

    handleDownload() {
        const {handleClicked} = this.props;
        handleClicked && handleClicked();
        this.setState({download: true});
    }

    render() {
        let {nodeId, disable} = this.props;
        let {download} = this.state;
        if (disable) {
            return null;
        }

        return <React.Fragment>
            <TbfMenuItem onClick={this.handleDownload} iconComponent={ImportExportIcon} dataCy={'export'}>
                {pageStrings.exportButton}
            </TbfMenuItem> {
            download && <DocumentShowExport nodeId={nodeId}/>
        }
        </React.Fragment>;
    }
}

const styles = () => ({
    grow: {
        flexGrow: 1,
    },
    addLink: {textDecoration: 'none'}
});
DocumentExportButton.propTypes = {
    nodeId: PropTypes.string.isRequired
};
const mapStateToProps = (state, ownProps) => {
    let procedureNode = getNodeOrNull(state, ownProps.nodeId);
    return {
        disable: procedureNode.canComplete === false
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocumentExportButton));
