import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import PageComponentBase from "../PageComponentBase";
import {strings} from "../components/SopLocalizedStrings";
import HistoryStream from "./HistoryStream";
import {Breadcrumbs, HistoryBackButton, PageContentWrapper, PageHeader} from "tbf-react-library";
import TrackChangesIcon from '@mui/icons-material/TrackChangesRounded';

const pageStrings = strings.execution.activity;

class DocumentHistory extends PageComponentBase {

    render() {
        let {nodeId, nodeType} = this.props;
        return <>
            <PageHeader
                title={pageStrings.title}
                PageIcon={<TrackChangesIcon/>}
                renderNavigationActions={() => <HistoryBackButton/>}
                showMenuToggle={false}/>
            <PageContentWrapper>
                <Breadcrumbs list={[]}/>
                <HistoryStream rootId={nodeId} rootType={nodeType}/>
            </PageContentWrapper>
        </>
    }
}

const styles = () => ({});
DocumentHistory.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.match.params.id;
    return {
        nodeId: nodeId
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocumentHistory));
