import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {createNode} from "../factory/graphFactory";
import {putNodeProperty} from "../actions";
import {useNodeOrNull, useNodePropertyOrNull, useNodeSchemaOrError,} from "./nodeHooks";
import {NODE_TYPE_OPTIONS} from "../reducers/graphReducer";
import {reportInfo} from "tbf-react-library";
import {getActiveExecutionQuestionsSafe, hasExecutionPermission} from "../selectors/executionSelectors";


export const useCallbackExecutionLinkNew = (procedureId) => {

    const procedure = useNodeOrNull(procedureId)
    const schema = useNodeSchemaOrError(NODE_TYPE_OPTIONS.ExecutionLinkNew)
    const dispatch = useDispatch()

    return useCallback(() => {
        if (procedure) {
            const newNode = createNode(schema, {procedureId: procedureId})
            dispatch(putNodeProperty(newNode))
        } else {
            reportInfo('Template is not loaded')
        }
    }, [dispatch, procedure, procedureId, schema])
}

export const useHasExecutionPermission = (procedureId, permission) => {
    return useSelector(state => hasExecutionPermission(state, procedureId, permission))
}

export const useComputeExecutionOfflineStatus = (offline) => {
    if (!offline?.on) {
        return 'notenabled';
    }
    if (offline?.loadingError) {
        return 'loaderror'
    }
    if (offline?.loaded) {
        return 'loaded';
    }
    return offline?.loading ? 'loading' : 'notloaded';
}

export const useGetAddButtonLabel = (questionId) => {
    const question = useNodeOrNull(questionId);
    return question?.addButton;
}

export const useActiveExecutionQuestions = (procedureId) => {
    return useSelector(state => getActiveExecutionQuestionsSafe(state, procedureId));
}

export const useGetTemplateOptions = (questionId) => {
    const addOptions = useNodePropertyOrNull(questionId, a => a.linkedAddOptions)

    return {
        canAdd: addOptions?.length > 0,
        displayOptions: addOptions,
        hasMultiOptions: addOptions?.length > 1,
        hasOptions: addOptions?.length > 0
    }
}