import DOMPurify from "dompurify";

function isUrlSafe(url) {
    try {
        const parsedUrl = new URL(url);
        switch (parsedUrl.protocol) {
            case 'http:':
            case 'https:':
                return true;
            default:
        }
        return false;
    } catch (e) {
        const pathRegex = new RegExp('^(\\/(\\w\\~*\\-*\\.*)+)+(\\.(\\w\\~*\\-*)+(\\?(\\w+=[\\w\\d]+(&\\w+=[\\w\\d]+)+)+)*)+$');
        const isPath = pathRegex.test(url);
        if (isPath) {
            return true;
        } else {
            return false;
        }
    }
}

const createTrustedTypesPolicy = function _createTrustedTypesPolicy(trustedTypes, policyName) {
    if (typeof trustedTypes !== 'object' || typeof trustedTypes.createPolicy !== 'function') {
        return null;
    }
    try {
        return trustedTypes.createPolicy(policyName, {
        createHTML(html) {
            return html;
        },
        createScriptURL(scriptUrl) {
            return scriptUrl;
        }
        });
    } catch (_) {
        console.warn('TrustedTypes policy ' + policyName + ' could not be created.');
        return null;
    }
}

function wrapInColGroup(string) {
    return `<table><colgroup>${string}</colgroup></table>`;
}

const tableElementsPolicy = createTrustedTypesPolicy(window.trustedTypes, 'table-elements');

if (window.trustedTypes && window.trustedTypes.createPolicy) {
    window.trustedTypes.createPolicy('default', {
        createHTML: (string) => {
            // Allow col element
            if (string.includes("<col")) {
                const dom = DOMPurify.sanitize(wrapInColGroup(string), {RETURN_DOM: true});
                const columnStrings = dom.getElementsByTagName("colgroup")[0].innerHTML;
                return tableElementsPolicy.createHTML(columnStrings);
            }
            return DOMPurify.sanitize(string, {RETURN_TRUSTED_TYPE: true});
        },
        // Temporarily reuse same sanitization method for script and scriptUrl.
        createScriptURL: string => {
            if (isUrlSafe(string)) {
                return DOMPurify.sanitize(string);
            }
            throw Error("Unsafe url!");
        },
        createScript: string => DOMPurify.sanitize(string),
    });
}