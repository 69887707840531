import { TbfAction } from "tbf-react-library"
import { useReportEventCallback } from "../../../hooks/nodeHooks";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


export default function TbfSopAction({
    nodeId,
    eventName,
    menuItem,
    iconButton,
    path,
    onClick,
    permission,
    title,
    iconComponent,
    eventProperties,
    ...props
}) {

    const reportEvent = useReportEventCallback();

    const history = useHistory();

    const handleClick = (e) => {
        if (eventName) {
            reportEvent(nodeId, eventName, eventProperties);
        }
        onClick?.(e);
        if (path) {
            history.push(path);
        }
    }

    return <TbfAction
        menuItem={menuItem}
        iconButton={iconButton}
        path={path}
        onClick={handleClick}
        permission={permission}
        title={title}
        iconComponent={iconComponent}
        {...props}
    />;
}