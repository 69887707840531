import React, {forwardRef, Suspense, lazy} from 'react';
import Loader from '../../components/Loader';

const PivotTableView = lazy(() => import('./WorkItemsPivotTableView'));

const LazyPivotTableView = (props) => {
    return <Suspense fallback={<Loader circular circularSize={40} contentCentered />}>
        <PivotTableView {...props} />
    </Suspense>
};

export default LazyPivotTableView;