import React, {useCallback, useState} from "react";
import {AddAction} from "tbf-react-library";

import ExecutionShowQuestionAddLink from "../../ExecutionShowQuestionAddLink";
import {useGetAddButtonLabel, useGetTemplateOptions} from "../../../../hooks/executionHooks";


const QueryAddExecution = ({disabled, questionId, onAddCompleted, variant, label, className, primaryAction = true, dialog = true}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const {canAdd, hasMultiOptions, hasOptions} = useGetTemplateOptions(questionId)
    label = useGetAddButtonLabel(questionId) || label;

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [])
    const handleAdd = useCallback((e) => {
        setAnchorEl(e.target)
    }, [])
    if (!canAdd || disabled) {
        return null
    }

    if(!hasOptions) {
        return  <></>
    }

    return (
        <>
            <ExecutionShowQuestionAddLink
                anchorEl={anchorEl}
                questionId={questionId}
                onClose={handleClose}
                handleCreate={onAddCompleted}
                dialog={dialog}
            />
            <AddAction
                isMultiple={hasMultiOptions}
                primaryAction={primaryAction}
                onClick={handleAdd}
                label={label}
                dataCy={'add-execution'}
                className={className}
                variant={variant}
            />
        </>
    );
}
export default QueryAddExecution
