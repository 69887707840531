import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {PageContentWrapper, reportEvent} from "tbf-react-library";
import Typography from "@mui/material/Typography";

class NotFoundPage extends Component {

    componentWillUnmount() {
        reportEvent({name: '404'});
    }

    render() {
        let {classes, name, nodeId} = this.props;
        return <PageContentWrapper>
            <div className={classes.container} data-cy={'404'}>
                <Typography className={classes.title} variant="h1" color="inherit" noWrap>404 Not Found</Typography>
                <div className={classes.subText} title={nodeId}>
                    The requested resource {name && name + ' '}{nodeId} could not be found
                </div>
            </div>
        </PageContentWrapper>
    }
}

export const styles = (theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        paddingTop: '5rem'
    },
    title: {
        color: theme.palette.grey.seven.main,
        marginBottom: 10,
    },
    subText: {
        color: theme.palette.grey.five.main,
    }
});

export default withStyles(styles)(NotFoundPage);