import React from "react";
import withStyles from '@mui/styles/withStyles';
import {strings} from "../../components/SopLocalizedStrings";
import NodeTextField from "../../graph/NodeTextField";
import Typography from "@mui/material/Typography";
import {useNodeOrError} from "../../../hooks/nodeHooks";
import ProcedureRuleCollectionColumns from "./ProcedureCollectionColumns";
import {Panel, TbfOptionsMenu} from "tbf-react-library";
import PanelSection from "../../components/PanelSection";
import ProcedureCompileMessages from "../ProcedureCompileMessages";
import ProcedureCollectionFilter from "./ProcedureCollectionFilter";
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import ProcedureCollectionViewButton from "./ProcedureCollectionViewButton";
import GraphDelete from "../../graph/GraphDelete";
import first from "lodash/first";
import ProcedureRuleCollectionOrderBys from "./ProcedureCollectionOrderBys";

const ProcedureCollection = ({disabled, ruleId, showDeleted}) => {
    const rule = useNodeOrError(ruleId)
    const isDialog = rule.rootId === first(rule.nodeIds)
    const renderPanelActions = () => {
        return (<>
            <ProcedureCollectionViewButton ruleId={ruleId}/>
            {
                !disabled &&
                <TbfOptionsMenu>
                    <GraphDelete
                        nodeId={ruleId}
                        friendlyName={strings.procedure.settings.collection.collectionDeleteName}
                        menuItem={true}
                    />
                </TbfOptionsMenu>
            }
        </>)
    }
    return (
        <Panel title={rule.message} cypressElement={'Collection'} renderPanelActions={renderPanelActions}>
            <PanelSection>
                <NodeTextField
                    nodeId={rule.id}
                    nodePropertyName={'message'}
                    disabled={disabled}
                    label={strings.procedure.rule.listViewDesignName}
                />
            </PanelSection>
            <PanelSection>
                <Typography variant="h2" color="inherit" noWrap>
                    {strings.procedure.rule.listViewDesignColumn}
                </Typography>
                <ProcedureRuleCollectionColumns
                    disabled={disabled}
                    parentRuleId={ruleId}
                    ruleActionType={RULE_ACTION_TYPE.collectionColumn.id}
                    showDeleted={showDeleted}
                    isDialog={isDialog}
                />
            </PanelSection>
            {
                isDialog &&
                <>
                    <PanelSection>
                        <Typography variant="h2" color="inherit" noWrap>
                            {strings.procedure.rule.listViewDesignOrder}
                        </Typography>
                        <ProcedureRuleCollectionOrderBys
                            disabled={disabled}
                            parentRuleId={ruleId}
                            ruleActionType={RULE_ACTION_TYPE.collectionOrder.id}
                            showDeleted={showDeleted}
                        />
                    </PanelSection>
                    <PanelSection>
                        <Typography variant="h2" color="inherit" noWrap>
                            {strings.procedure.rule.listViewDesignFilter}
                        </Typography>
                        <ProcedureCollectionFilter collectionRuleId={ruleId} disabled={disabled}/>
                    </PanelSection>
                </>
            }
            <PanelSection>
                <ProcedureCompileMessages nodeId={ruleId}/>
            </PanelSection>
        </Panel>);
}
const styles = () => ({});
export default withStyles(styles)(ProcedureCollection);
