import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeSchemaOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import PageComponentBase from "../PageComponentBase";
import Loader from "../components/Loader";
import {putNodes} from "../../actions";
import {COMPLETE_MODES, PROCEDURE_TYPES, STATISTICS_MODES} from "../../reducers/graphReducer";
import {createProcedureNode} from "../../factory/procedureFactory";

class ProcedureNew extends PageComponentBase {

    componentDidMount() {
        super.componentDidMount();
        const {procedureSchema, procedureStepSchema, procedureTaskSchema, procedureQuestionSchema,
            procedureType, onPutNodes} = this.props;
        const root = createProcedureNode(procedureSchema, {
            procedureType: procedureType
        },procedureType);
        const step1 = createProcedureNode(procedureStepSchema, {
            rootId: root.id,
            parentId: root.id,
            name: 'Job',
            completeMode: COMPLETE_MODES.task.id,
            statisticsMode: STATISTICS_MODES.include.id
        }, procedureType);
        root.children.push(step1.id);
        const task1_1 = createProcedureNode(procedureTaskSchema, {
            rootId: root.id,
            parentId: step1.id,
            name: 'Context',
            statisticsMode: STATISTICS_MODES.exclude.id
        }, procedureType);
        step1.children.push(task1_1.id);
        const question1_1_1 = createProcedureNode(procedureQuestionSchema, {
            rootId: root.id,
            parentId: task1_1.id,
            name: 'Job Number'
        }, procedureType);

        task1_1.children.push(question1_1_1.id);
        const step2 = createProcedureNode(procedureStepSchema, {
            rootId: root.id,
            parentId: root.id,
            name: 'Step 1'
        }, procedureType);
        root.children.push(step2.id);
        const task2_1 = createProcedureNode(procedureTaskSchema, {
            rootId: root.id,
            parentId: step2.id,
            name: 'Task 1'
        }, procedureType);
        step2.children.push(task2_1.id);
        const question2_1_1 = createProcedureNode(procedureQuestionSchema, {
            rootId: root.id,
            parentId: task2_1.id,
            name: 'Question 1'
        }, procedureType);

        task2_1.children.push(question2_1_1.id);
        const nodes = [root, step1, task1_1, question1_1_1, step2, task2_1, question2_1_1];
        onPutNodes(nodes);
        this.props.history.replace('/procedures/' + root.id);
    }

    render() {
        return (
            <div>
                <Loader source={'Procedure New'}/>
            </div>
        )
    }
}

const styles = () => ({});
ProcedureNew.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    return {
        procedureType: ownProps.procedureType || PROCEDURE_TYPES.itp.id,
        procedureSchema: getNodeSchemaOrNull(state, 'ProcedureRoot'),
        procedureStepSchema: getNodeSchemaOrNull(state, 'ProcedureStep'),
        procedureTaskSchema: getNodeSchemaOrNull(state, 'ProcedureTask'),
        procedureQuestionSchema: getNodeSchemaOrNull(state, 'ProcedureQuestion')
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodes: nodes => dispatch(putNodes(nodes))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProcedureNew)));
