import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodesById} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import * as PropTypes from "prop-types";
import NodeTextField from "../graph/NodeTextField";
import {strings} from "../components/SopLocalizedStrings";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const pageStrings = strings.project.addExecution;

class ProjectAddProcedurePrefill extends Component {

    constructor(props) {
        super(props);
        let state = {from: {}, to: {}};
        for (let question of props.questions) {
            state.from[question.id] = null;
            state.to[question.id]=null;
        }
        this.state = {
            procedureId: null,
            ...state
        };
    }

    renderQuestion = (question) => {
        return (<React.Fragment key={question.id}>
            <Grid item xs={6}>
                <NodeTextField nodeId={question.id} nodePropertyName={'fromValue'} label={question.fromValueName}/>
            </Grid>
            <Grid item xs={6}>
                {
                    question.toValueEnabled &&
                    <NodeTextField nodeId={question.id} nodePropertyName={'toValue'} label={question.toValueName}/>
                }
            </Grid>
        </React.Fragment>);
    };

    render() {
        let {classes, createExecution, questions} = this.props;
        if (questions.length === 0) {
            return <div>{pageStrings.errorMissingTask}</div>
        }
        return (
            <React.Fragment>
                <Accordion defaultExpanded={true} className={classes.panel}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography variant={"h2"}>{createExecution.taskName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2} justifyContent={"flex-start"}>
                            {questions.map(question => this.renderQuestion(question))}
                            {
                                createExecution.signOffEnabled &&
                                <Grid item xs={12}>
                                    <NodeTextField nodeId={createExecution.id} nodePropertyName={'completeFlag'}/>
                                </Grid>
                            }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </React.Fragment>
        );
    }
}

const styles = () => ({
    grow: {
        flexGrow: 1,
    },
    panel: {
        width: '100%'
    }
});
ProjectAddProcedurePrefill.propTypes = {
    createExecutionId: PropTypes.string.isRequired
};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.createExecutionId;
    let createExecution = getNodeOrNull(state, nodeId);
    return {
        createExecution: createExecution,
        questions: getNodesById(state, createExecution.children)
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProjectAddProcedurePrefill)));
