import React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export default function BulkActionMenuItemBase({icon, children, cyName, ...menuItemProps}) {
    return <MenuItem {...menuItemProps} data-cy={cyName}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText>{children}</ListItemText>
    </MenuItem>;
}