import React, { useCallback, useMemo, useState } from 'react';
import {
    useNodeOrNull,
    useNodeSchemaOrError,
    useRuleForNodeByActionOrNull,
    useRulesByActionIfPresent,
} from "../../../hooks/nodeHooks";
import { useDispatch } from "react-redux";
import { DEFAULT_LAYOUT_COLUMNS_WIDTH, LAYOUT_COLUMN_FORMATS, LAYOUT_COLUMN_WIDTHS, NODE_TYPE_OPTIONS, RULE_ACTION_TYPE } from "../../../reducers/graphReducer";
import { putNodesProperty } from "../../../actions";
import { createChildNode } from '../../../factory/graphFactory';
import { TbfSelectDeviceFriendly, reportBusinessError } from 'tbf-react-library';
import { keyedObjectIdNameToNameLabel } from '../../../util/util';
import FormLabel from "@mui/material/FormLabel";
import keyBy from 'lodash/keyBy';
import without from 'lodash/without';


export const useProcedureActionTypeRule = (procedureId, nodeId, properties) => {

    const actionType = properties.actionType;

    const procedure = useNodeOrNull(procedureId);

    const dispatch = useDispatch();
    
    const actionRules = useRulesByActionIfPresent(procedureId, actionType);
    const actionRule = useRuleForNodeByActionOrNull(nodeId, actionType, true);
    const actionRulesByValue = useMemo(() => {
        const rules = keyBy(actionRules, r => r.calculateValueQuery);

        return JSON.parse(JSON.stringify(rules));
    }, [actionRules]);
    
    const schema = useNodeSchemaOrError(NODE_TYPE_OPTIONS.ProcedureRule);

    const upsertRule = useCallback((newValue, oldValue) => {
        const patches = [];
        // Remove selected rule first
        let removeRule = actionRulesByValue[oldValue];
        if (removeRule) {
            removeRule.nodeIds = (removeRule.nodeIds ?? []);
            removeRule.nodeIds = without(removeRule.nodeIds, nodeId);
            patches.push({
                id: removeRule.id,
                nodeIds: without(removeRule.nodeIds, nodeId)
            })
        }


        // UpSert to new rule
        if (+newValue !== DEFAULT_LAYOUT_COLUMNS_WIDTH) {
            let useRule = actionRulesByValue[newValue];
            if (!useRule) {
                if (!procedure) {
                    reportBusinessError('Trying to create rule for procedure that does not exist');
                    return;
                }
                useRule = createChildNode(procedure, schema, properties);
                useRule.calculateValueQuery = newValue;
            }

            useRule.nodeIds = useRule.nodeIds ?? [];
            useRule.nodeIds.push(nodeId);

            patches.push(useRule);
        }

        if(!patches.length) {
            return;
        }

        dispatch(putNodesProperty(patches))

    }, [nodeId, schema, dispatch, procedure, actionType, properties, actionRulesByValue]);

    return {rule: actionRule, upsertRule};
}

export default function ProcedurePropertyColumnWidthRule({nodeId, disabled}) {
    const nodePropertyName = RULE_ACTION_TYPE.layoutColumns.id;
    const options = keyedObjectIdNameToNameLabel(LAYOUT_COLUMN_WIDTHS);
    const [searchTerm, setSearchTerm] = useState(null);

    const node = useNodeOrNull(nodeId);

    const isQuestionNode = node.type === NODE_TYPE_OPTIONS.ProcedureQuestion;
    const parentLayoutColumnRule = useRuleForNodeByActionOrNull(node?.parentId, RULE_ACTION_TYPE.layoutColumns.id, true);
    const {rule, upsertRule} = useProcedureActionTypeRule(node.rootId, nodeId, {
        alwaysOn: true,
        calculateValueOn: true,
        actionType: RULE_ACTION_TYPE.layoutColumns.id,
        format: LAYOUT_COLUMN_FORMATS.desktop.id,
    });

    const selected = rule && rule.calculateValueQuery && options[rule.calculateValueQuery] ? options[rule.calculateValueQuery] : options[DEFAULT_LAYOUT_COLUMNS_WIDTH];

    const selectOptions = React.useMemo(() => {
        const parentSelection = parentLayoutColumnRule?.calculateValueQuery;
        let optns = Object.values(options);

        if(parentSelection) {
            optns = optns?.filter(e => +e.value <= +parentSelection) ?? [];
        }

        optns.sort((a, b) => {
            if (a.order && b.order) {
                return (a.order - b.order);
            }
            return (a.label || '').localeCompare(b.label || '');
        });

        return optns ?? [];
    }, [parentLayoutColumnRule?.id, selected?.value]) ;

    if(isQuestionNode && !parentLayoutColumnRule) {
        return  <></>
    }


    const handleChange = (selection) => {
        upsertRule(selection.value, selected.value);
    }

    const handleSearchTermChange = (searchTerm_) => {
        setSearchTerm(searchTerm_);
    }

    return (
        <div data-cy-element={'Property'}>
            <FormLabel className={'sizeSmall'} htmlFor={nodePropertyName}>{RULE_ACTION_TYPE.layoutColumns.name}</FormLabel>
            <TbfSelectDeviceFriendly
                multiple={false}
                closeMenuOnSelect={true}
                propertyName={nodePropertyName}
                onChange={handleChange}
                options={selectOptions}
                value={selected}
                inputValue={searchTerm}
                handleSearchTermChange={handleSearchTermChange}
                disabled={disabled}
                required={false}
                isSearchable={true}
                mobileView={true}
                menuPortalTarget={document.body}
                menuPlacement={"auto"}
            />
        </div>
    );
}