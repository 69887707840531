import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../../style/alert.css';
import "../../../style/form.css";
import {getNodeOrNull, getNodeSchemaOrNull,} from "../../../selectors/graphSelectors";
import {putNodesProperty} from "../../../actions";
import {ComponentBase} from "tbf-react-library";
import {NODE_TYPE_OPTIONS, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import TbfToggle from "../../components/common/TbfToggle";
import {getChildRulesByAction} from "../../../selectors/ruleSelectors";
import ProcedureCalculateEditor from "./ProcedureCalculateEditor";
import NodeTextField from "../../graph/NodeTextField";
import {createRule} from "../../../factory/procedureFactory";

const initialState = {
    open: false,
};

class ProcedureRuleToggleEditor extends ComponentBase {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleSwitchChange = (event) => {
        const {ruleSchema, procedure, parentRule, actionRule, actionType, onPutNodesProperty, ruleAttributes} = this.props;
        if (actionRule == null) {
            if (event.target.checked) {
                let ruleNode = createRule(parentRule, procedure, ruleSchema, {actionType: actionType, ...ruleAttributes})
                // This is not adding the new child to procedure.rules as that is done in graph reducer
                // when draft is false (i.e. Add is clicked)
                onPutNodesProperty([ruleNode]);
            }
        } else {
            // Delete/Undelete the existing
            onPutNodesProperty([{id: actionRule.id, deleted: !event.target.checked}]);
        }
    }

    render() {
        let {available, ruleId, actionType, disabled, checked, label, actionRule, children} = this.props;
        if (!available) {
            return null;
        }
        return (
            <div>
                <TbfToggle
                    id={ruleId + '-' + actionType}
                    label={label}
                    checked={checked}
                    disabled={disabled}
                    onChange={this.handleSwitchChange}
                />
                {
                    checked && <NodeTextField
                        nodeId={actionRule.id}
                        nodePropertyName={'format'}
                        disabled={disabled}
                        label={'Apply to'}
                        visual={'select'}
                    />
                }

                {
                    checked && children
                }

                {
                    (checked && actionRule?.calculateValueOn && !children) &&
                    <ProcedureCalculateEditor disabled={disabled} ruleId={actionRule.id}/>
                }
            </div>
        )
    }
}

const styles = () => ({});
ProcedureRuleToggleEditor.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    const parentRule = getNodeOrNull(state, ownProps.ruleId);
    const procedure = getNodeOrNull(state, parentRule.rootId);
    const actionRules = getChildRulesByAction(state, ownProps.ruleId, ownProps.actionType);
    const actionRule = actionRules.length > 0 ? actionRules[0] : null;
    const checked = (actionRule && !actionRule.deleted) || false;
    const actionOption = RULE_ACTION_TYPE[ownProps.actionType];
    const available = parentRule.actionTypesAvailable && parentRule.actionTypesAvailable.includes(ownProps.actionType)
    return {
        procedure: procedure,
        parentRule: parentRule,
        actionRule: actionRule,
        checked: checked,
        label: actionOption.name,
        ruleSchema: getNodeSchemaOrNull(state, NODE_TYPE_OPTIONS.ProcedureRule),
        available: available
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedureRuleToggleEditor));
