import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {strings} from "../../components/SopLocalizedStrings";
import FormLabel from "@mui/material/FormLabel";
import ProcedureRuleInlineItem from "./ProcedureRulesListItem";
import ProcedureRuleAddButton from "./ProcedureRuleAddButton";
import {useNodeOrNull, useRulesForNode, useRulesMissingNode} from "../../../hooks/nodeHooks";
import {ADD_ACTION_LABEL_FORMATS, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";

const pageStrings = strings.procedure.rule;


const ProcedureRulesList = ({classes, disabled, showDeleted, nodeId}) => {
    const node = useNodeOrNull(nodeId)
    const ruleNodes = useRulesForNode(nodeId)
        .filter(a => showDeleted || !a.deleted)
        .filter(a => !a.draft)
        .filter(rule =>
            rule.actionType !== RULE_ACTION_TYPE.collectionView.id &&
            rule.actionType !== RULE_ACTION_TYPE.completeLabels.id &&
            rule.actionType !== RULE_ACTION_TYPE.security.id &&
            rule.actionType !== RULE_ACTION_TYPE.customKey.id &&
            rule.actionType !== RULE_ACTION_TYPE.layoutColumns.id &&
            rule.actionType !== RULE_ACTION_TYPE.manuallyAddExecution.id &&
            rule.actionType !== RULE_ACTION_TYPE.manuallyAddExecutionBulk.id &&
            rule.actionType !== RULE_ACTION_TYPE.addMoreAvailable.id &&
            rule.actionType !== RULE_ACTION_TYPE.afterAddNavigationMode.id &&
            rule.actionType !== RULE_ACTION_TYPE.navigationStyle.id &&
            rule.actionType !== RULE_ACTION_TYPE.completeActionStyle.id &&
            rule.actionType !== RULE_ACTION_TYPE.theme.id &&
            rule.format !== ADD_ACTION_LABEL_FORMATS.button.id &&
            rule.format !== ADD_ACTION_LABEL_FORMATS.group.id)

    const miaRuleNodes = useRulesMissingNode(node?.rootId === nodeId ? nodeId : null)
        .filter(a => !a.draft)
        .filter(a => showDeleted || !a.deleted)
    const rules = [...ruleNodes, ...miaRuleNodes]
    return (
        <div data-cy-element={'Rules'}>
            <Box display={'flex'} className={classes.rulesWrapper}>
                <Box flexGrow={1}>
                    <FormLabel className={classes.labelAlignButton}>
                        {pageStrings.propertyTitle}
                    </FormLabel>
                </Box>
                {
                    !disabled &&
                    <Box>
                        <ProcedureRuleAddButton nodeId={nodeId}/>
                    </Box>
                }
            </Box>
            <List dense={true}>
                {
                    rules.length === 0 &&
                    <ListItem className={classes.ruleList}>{pageStrings.none}</ListItem>
                }
                {
                    rules.map((rule) => (
                        <ProcedureRuleInlineItem
                            key={rule.id}
                            showDeleted={showDeleted}
                            ruleId={rule.id}
                            disabled={disabled}
                        />))
                }
            </List>
        </div>
    )
}

const styles = () => ({
    labelAlignButton: {
        paddingTop: 10,
        paddingLeft: 3
    },
    rulesWrapper: {
        paddingTop: 10,
    }
});
export default withStyles(styles)(ProcedureRulesList);
