import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getDirtyNodes, getSchema} from "../selectors/graphSelectors";
import {clearGraph, clearSaveError} from "../actions";
import {connect} from "react-redux";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/SaveRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverRounded';
import Typography from "@mui/material/Typography";
import {withRouter} from "react-router-dom";
import PageComponentBase from "./PageComponentBase";
import {getDbNodeByDirty, isDbConnected} from "../util/offline";
import {strings} from "./components/SopLocalizedStrings";
import LogoutIcon from "@mui/icons-material/PowerSettingsNewRounded";
import {Loader, reportDeveloperInfo, reportEvent} from "tbf-react-library";

class Logout extends PageComponentBase {

    constructor(props, context) {
        super(props, context);
        this.handleDiscardClicked = this.handleDiscardClicked.bind(this);
        this.handleSaveChangesClicked = this.handleSaveChangesClicked.bind(this);
        this.checkIndexedbDirtyCount = this.checkIndexedbDirtyCount.bind(this);
        this.runAutoLogic = this.runAutoLogic.bind(this);
        this.logoutAuth0 = this.logoutAuth0.bind(this);
        this.state = {
            indexedDbDirtyCount: null
        };
    }

    interval = null;
    timeout1 = null;
    timeout2 = null;

    componentDidMount() {
        this.runAutoLogic();
        this.checkIndexedbDirtyCount();
        // Check if the user clicked Save Now and now there are no dirty changes
        this.interval = setInterval(() => {
            this.checkIndexedbDirtyCount();
        }, 1000);
        // If we get stuck on here log them out after 60 seconds wihtout input
        this.timeout1 = setTimeout(() => {
            this.logoutAuth0();
        }, 60000);
        // And finally, lets give up after 30 seconds on auth0
        this.timeout2 = setTimeout(() => {
            window.location = '/app'
        }, 90000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
        if (this.timeout1) {
            clearTimeout(this.timeout1)
        }
        if (this.timeout2) {
            clearTimeout(this.timeout2)
        }
        if (this.timer) {
            clearInterval(this.timer)
        }
    }

    checkIndexedbDirtyCount() {
        // Check for dirty nodes in this or other tabs
        getDbNodeByDirty().then((dbNodes) => {
            let uniqueRootIds = {};
            for (let dbNode of dbNodes) {
                uniqueRootIds[dbNode?.node?.rootId] = true;
            }
            this.setState({indexedDbDirtyCount: dbNodes.length, dirtyRootIds: Object.keys(uniqueRootIds)})
        }).catch(() => {
            this.setState({indexedDbDirtyCount: -1})
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.runAutoLogic();
    }

    clearGraph = () => {
        const {onClearGraph} = this.props;
        onClearGraph();
        reportEvent({name: 'Logout.ClearGraph.Started'})
    }

    clearCalled = false;
    runAutoLogic = () => {
        const {autoClearGraph, autoLogout, clearing} = this.props;
        let {discardCalled, indexedDbDirtyCount} = this.state;
        // Wait until we know if another window has unsaved changes
        if (indexedDbDirtyCount == null) {
            return;
        }
        if (this.clearCalled || discardCalled) {
            if (!clearing) {
                reportDeveloperInfo('Logout.runAutoLogic.conditionClear');
                this.logoutAuth0();
            }
            return;
        }
        if (indexedDbDirtyCount === 0) {
            if (autoClearGraph) {
                this.clearCalled = true;
                this.clearGraph();
            } else if (autoLogout) {
                reportDeveloperInfo('Logout.runAutoLogic.autoLogout');
                this.logoutAuth0();
            }
        } else if (indexedDbDirtyCount === -1) {
            reportDeveloperInfo('Logout.runAutoLogic.autoLogout indexedDbDirtyCount error');
        }
    };

    static authLogoutCalled = false;
    logoutAuth0 = () => {
        const {auth} = this.props;
        if (Logout.authLogoutCalled === false) {
            auth.logout(this.props.history);
            Logout.authLogoutCalled = true;
        }
    }

    handleDiscardClicked = () => {
        let {dirtyRootIds} = this.state;
        reportEvent({name: 'LogoutDiscardClicked', discardRootIds: dirtyRootIds});
        this.setState({discardCalled: true});
        this.clearGraph();
    };

    handleSaveChangesClicked = () => {
        let {dirtyNodes} = this.props;
        reportEvent({name: 'LogoutSaveNowClicked'});
        for (let node of Object.values(dirtyNodes)) {
            this.props.onClearSaveError(node);
        }
    };

    handleKeepClicked = () => {
        reportEvent({name: 'LogoutKeepClicked'});
        this.logoutAuth0();
    };

    render() {
        let {
            classes, dirty, dirtyCount, saveRunning, autoClearGraph, autoLogout
        } = this.props;
        let {discardCalled, indexedDbDirtyCount} = this.state;
        let anyDirty = dirty || indexedDbDirtyCount > 0;
        let autoSomething = (autoClearGraph || autoLogout) && indexedDbDirtyCount === 0;
        if (autoSomething || discardCalled || indexedDbDirtyCount == null) {
            return (<div>
                <Typography className={classes.title} variant="h1" color="inherit" noWrap>
                    Logging Out <Loader circular={true} source={'logout'} inline={true}/>
                </Typography>
            </div>);
        }
        return (
            <div>
                <Typography className={classes.title} variant="h1" color="inherit" noWrap>
                    Logout
                </Typography>
                {
                    anyDirty &&
                    <div>
                        <div className={'alert alert-warning'}>You have {dirtyCount || indexedDbDirtyCount} unsaved
                            changes.
                        </div>
                        <Button title={strings.buttons.discardChanges}
                                onClick={this.handleDiscardClicked} color='secondary'
                                variant='contained' data-cy={'discard'}>
                            <DeleteForeverIcon/>&nbsp;&nbsp;Discard changes
                        </Button>
                        &nbsp;&nbsp;
                        <Button title={strings.buttons.saveChanges} onClick={this.handleSaveChangesClicked}
                                color='primary' variant='contained'
                                disabled={saveRunning} data-cy={'save-now'}>
                            <SaveIcon/>&nbsp;&nbsp;{saveRunning ? 'Syncing changes...' : 'Sync changes now'}
                        </Button>
                        &nbsp;&nbsp;
                        <Button title={strings.buttons.keepChanges} onClick={this.handleKeepClicked}
                                color='secondary' variant='contained'
                                disabled={saveRunning} data-cy={'keep-dirty'}>
                            <LogoutIcon/>&nbsp;&nbsp;{strings.buttons.keepChanges}
                        </Button>
                    </div>
                }
            </div>
        );
    }
}

const styles = () => ({});
Logout.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let dirtyNodes = getDirtyNodes(state);
    // We will keep the indexeddb data between login attempts if any unsaved changes for a max
    // of 7 days

    // They reopen the app the next day with unsaved changes
    // We wil not clear indexeddb

    // They reopen the app the next day, lets quietly log them out
    let dirtyCount = state.graph.pendingUserSaveNodeCount;
    let dbConnected = isDbConnected();
    let autoClearGraph = dbConnected && dirtyCount === 0;
    let autoLogout = dirtyCount === 0 || !dbConnected || state.graph.nodesCleared;
    return {
        schema: getSchema(state),
        dirty: dirtyCount !== 0,
        dirtyCount: dirtyCount,
        dirtyNodes: dirtyNodes,
        saveRunning: state.graph.saveRunning,
        autoClearGraph: autoClearGraph,
        autoLogout: autoLogout,
        clearing: state.graph.nodesClearing,
        auto: ownProps?.location?.search?.includes('auto')
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onClearSaveError: node => dispatch(clearSaveError(node)),
        onClearGraph: () => dispatch(clearGraph())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Logout)));
