import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { useNodeOrNull } from "../../hooks/nodeHooks";
import { DIAGNOSTIC_MODES, NODE_IDS } from "../../reducers/graphReducer";
import { formatFullDate, formatUser, parseRule } from "../../util/util";
import {Link} from "react-router-dom";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ExecutionAnsweredBy = ({classes, questionId}) => {
    const question = useNodeOrNull(questionId);
    const {sourceId, ruleId} = parseRule(question.initialValueByUser);
    const sourceNode = useNodeOrNull(sourceId);
    const rule = useNodeOrNull(ruleId);

    const userDevice = useNodeOrNull(NODE_IDS.UserDevice);
    const troubleshootOn = userDevice?.troubleshootOn === true;
    const diagnosticsOn = userDevice?.diagnosticMode === DIAGNOSTIC_MODES.full.id;

    const location = useLocation();

    const getRuleLink = () => {

        let link;

        if (sourceNode.preview || location.pathname?.includes?.("/procedures")) {
            link = location.pathname;
        } else {
            link = `/executions/${sourceId}`
        }

        if (sourceId === question.rootId) {
            link += `?highlight=${question.id}&highlight_rule=${ruleId}`;
        } else {
            link += `?highlight_rule=${ruleId}`;
        }

        return link;
    }

    if (!diagnosticsOn && !troubleshootOn) {
        return null;
    }

    if (!troubleshootOn || !rule) {
        return <div className={classes.answeredBy}>
            Answered
            by {formatUser(question.initialValueByUser)} at {formatFullDate(question.initialValueDateTime)}
        </div>;
    }

    return <div className={classes.answeredBy}>
        Answered
        by <Link
            to={getRuleLink()}
            className={classes.ruleLink}
            data-cy={'rule-link'}
        >{rule.copyToOn ? `Copied from [${sourceNode.key ?? 'NEW'}] via Rule` : 'Rule'}</Link> at {formatFullDate(question.initialValueDateTime)}
    </div>
}

const styles = () => ({
    answeredBy: {
        fontSize: '80%',
        color: '#666'
    },
    ruleLink: {
        textDecoration: 'underline',
        fontSize: '100%',
    },
})

export default withStyles(styles)(ExecutionAnsweredBy);
