import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {putNodeProperty} from "../../actions";
import {strings} from "../components/SopLocalizedStrings";

class NodeDestroyedMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {isDestroyed} = this.props;
        if (isDestroyed) {
            return <div className={"alert alert-danger"}>{strings.resource.destroyedMessage}</div>
        }
        return (<React.Fragment/>);
    }
}

const styles = () => ({});
NodeDestroyedMessage.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.nodeId;
    let node = getNodeOrNull(state, nodeId);
    return {
        isDestroyed: node && node.destroyed === true,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeDestroyedMessage));
