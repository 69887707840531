import React from "react";
import withStyles from '@mui/styles/withStyles';
import {strings} from "../components/SopLocalizedStrings";
import Button from "@mui/material/Button";
import {NonMobileOnly} from "tbf-react-library";
import cn from "classnames";
import ArrowRight from "@mui/icons-material/ArrowForwardIos";
import ArrowLeft from "@mui/icons-material/ArrowBackIos";
import {useNodeOrNull} from "../../hooks/nodeHooks";
import {useCallbackUpdateMapExpanded} from "./ExecutionViewHooks";

const CollectionExpandMapButton = ({selectorId, classes}) => {
    const selector = useNodeOrNull(selectorId);
    const mapExpanded = selector?.mapExpanded
    const handleClicked = useCallbackUpdateMapExpanded(selectorId, !mapExpanded)
    return (
        <NonMobileOnly>
            <Button
                title={mapExpanded ? strings.buttons.list : strings.buttons.map}
                variant="contained"
                color="primary"
                className={cn(classes.toggleMapExpandedButton, {mapExpanded})}
                startIcon={mapExpanded ? <ArrowRight/> : <ArrowLeft/>}
                onClick={handleClicked}>
                {mapExpanded ? 'Show List' : 'Expand Map'}
            </Button>
        </NonMobileOnly>
    )
}
const styles = (theme) => ({
    toggleMapExpandedButton: {
        position: 'absolute',
        top: 10,
        left: 10,
        zIndex: 1000,
        backgroundColor: theme.palette.primary.two.main,
        color: theme.palette.grey.seven.main,
        '&:hover': {
            backgroundColor: 'white'
        }
    },
});
export default withStyles(styles)(CollectionExpandMapButton);
