import { fabric } from 'fabric'
import merge from 'lodash/merge'
import forEach from 'lodash/forEach'
import { selectImageEditorTool } from './actions'
import { pointerTool } from './reducer'
import { handleLineMouseMove, toggleDrawingLine } from './lineTool'
import { toggleDrawingArrow, handleArrowMouseMove } from './arrowTool'
import Color from 'color'

const defaultOptions = {
  default: {
    transparentCorners: false,
    cornerSize: 10,
    strokeUniform: true,
    fill: 'transparent'
  },
  Circle: {
    radius: 50
  },
  Rect: {
    width: 50,
    height: 50
  },
  Triangle: {
    width: 50,
    height: 50
  },
  Textbox: {
    editable: true,
    fontSize: 40,
    fontFamily: 'Nimbus Sans',
    fill: '#000',
    textAlign: 'center',
    defaultText: 'Enter Text Here',
    originX: 'left'
  },
  Line: {
    fill: '#000',
    strokeWidth: 5,
    selectable: true
  }
}

const mergeOptions = (type, options) => {
  return merge({}, defaultOptions.default, defaultOptions[type], options)
}

export const handleCanvasMouseMove = (canvas, tool, point) => {
  handleLineMouseMove(canvas, tool, point)
  handleArrowMouseMove(canvas, tool, point)
}

export const handleToolMouseUp = (canvas, scale, point, tool, dispatch) => {
  if(!tool) return null
  const { type, options } = tool
  switch(type) {
    case pointerTool.type: {
      // Do nothing, fabric will take care of this
      break
    }
    case 'Pen': {
      // Do nothing pen tool is handled by fabric
      break
    }
    case 'Arrow': {
      toggleDrawingArrow(canvas, point, mergeOptions(type, options), dispatch)
      break
    }
    case 'Line': {
      toggleDrawingLine(canvas, point, mergeOptions(type, options), dispatch)
      break
    }
    case 'Polygon': {
      const mergedOptions = mergeOptions('Polygon', options)
      const canvasObject = new fabric.Polygon(options.points, {
        top: point.y,
        left: point.x,
        ...mergedOptions
      })
      canvas.add(canvasObject)
      canvas.renderAll()
      dispatch(selectImageEditorTool(pointerTool))
      break
    }
    case 'Svg': {
      const mergedOptions = mergeOptions('Svg', options)
      fabric.loadSVGFromString(options.svg, function(objects) {
        if(options.grouped) {
          const obj = fabric.util.groupSVGElements(objects, mergedOptions)
          obj.top = point.y
          obj.left = point.x
          canvas.add(obj).renderAll()
          obj.setCoords()
        } else {
          forEach(objects, obj => {
            canvas.add(obj).centerObject(obj)
          })
          canvas.renderAll()
        }
      })
      dispatch(selectImageEditorTool(pointerTool))
      break
    }
    case 'Textbox': 
    case 'IText': {
      const mergedOptions = mergeOptions('Textbox', options)
      const textBox = new fabric[type](mergedOptions.defaultText, {
        top: point.y,
        left: point.x,
        ...mergedOptions
      })
      textBox.on('editing:entered', () => {
        textBox.dirty = true
        textBox.backgroundColor = Color(mergedOptions.fill).isLight()
          ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.8)'
        canvas.renderAll()
      })
      textBox.on('editing:exited', () => {
        textBox.dirty = true
        textBox.backgroundColor = 'transparent'
        canvas.renderAll()
      })
      canvas.add(textBox)
      canvas.renderAll()
      dispatch(selectImageEditorTool(pointerTool))

      break
    }
    default: {
      const mergedOptions = mergeOptions(type, options)
      const bounds = {
        width: (mergedOptions.width || mergedOptions.radius * 2) / scale,
        height: (mergedOptions.height || mergedOptions.radius * 2 || 0) / scale,
      }
      const ops = scaleOptionsProps(scale, {
        ...mergedOptions,
        top: point.y - (bounds.height / 2),
        left: point.x - (bounds.width / 2),
      })
      const canvasObject = new fabric[type](ops)
      canvas.add(canvasObject)
      canvas.renderAll()
      dispatch(selectImageEditorTool(pointerTool))
      return canvasObject
    }
  }
}

const scaledProps = ['width', 'height', 'radius', 'strokeWidth']

const scaleOptionsProps = (scale, options) => {
  forEach(options, (value, prop) => {
    if (scaledProps.includes(prop)) {
      options[prop] = value / scale
    }
  })
  return options
}

export const toggleDrawingMode = (canvas, tool) => {
  if(tool && tool.type === 'Pen') {
    canvas.freeDrawingBrush = new fabric['PencilBrush'](canvas)
    canvas.freeDrawingBrush.width = tool.options.width || 1
    canvas.freeDrawingBrush.color = tool.options.color || '#000'
    canvas.freeDrawingBrush.strokeLineCap = tool.options.strokeLineCap || 'round'
    canvas.isDrawingMode = true
  } else {
    canvas.isDrawingMode = false
  }
}

const patchFabricTextboxForModals = () => {
  const _original_initHiddenTextarea = fabric.IText.prototype.initHiddenTextarea;
  fabric.util.object.extend(fabric.IText.prototype, /** @lends fabric.IText.prototype */ {    
    //fix for : IText not editable when canvas is in a fullscreen element on chrome
    // https://github.com/fabricjs/fabric.js/issues/5126
    initHiddenTextarea: function() {
      _original_initHiddenTextarea.call(this);
      (document.getElementById('image-editor') || this.canvas.wrapperEl).appendChild(this.hiddenTextarea)
    }
  });  
}

patchFabricTextboxForModals()