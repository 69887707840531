import React from 'react';
import {MenuItem} from '@mui/material';
import {withStyles} from '@mui/styles';
import AddNewExecutionButton, {AddNewStyle} from '../../workItems/AddNewExecutionButton';
import {EXECUTION_SOURCE_TYPES} from '../../../reducers/graphReducer';
import {TbfAction} from 'tbf-react-library';
import BulkActionMenuItemBase from "../../workItems/table/bulkActions/BulkActionMenuItemBase";

const ExecutionActionButton = (
    {
        executionId,
        executionIds,
        ruleId,
        procedureId,
        linkType,
        classes,
        buttonLabel,
        onAction,
        menuItem = false,
        bulkActionItem = false,
        icon
    }) => {

    let buttonRender;
    if (menuItem) {
        buttonRender = ({onClick, disabled}) => <MenuItem
            title={buttonLabel}
            data-cy={`action-button-${buttonLabel.toString().replaceAll(' ', '-').toLowerCase()}`}
            onClick={onClick}
            disabled={disabled}
        >
            <div className={classes.item}>
                {buttonLabel}
            </div>
        </MenuItem>;
    } else if (bulkActionItem) {
        buttonRender = ({onClick}) => <BulkActionMenuItemBase
            onClick={onClick}
            icon={icon}
            cyName={'bulk-action-custom-' + buttonLabel}
        >
            {buttonLabel}
        </BulkActionMenuItemBase>;
    } else {
        buttonRender = ({onClick, disabled}) =>
            <TbfAction
                title={buttonLabel}
                onClick={onClick}
                disabled={disabled}
                dataCy={`action-button-${buttonLabel.toString().replaceAll(' ', '-').toLowerCase()}`}
            >
                {buttonLabel}
            </TbfAction>
    }

    return <AddNewExecutionButton
        procedureId={procedureId}
        style={AddNewStyle.dialog}
        components={{
            Button: buttonRender
        }}
        linkAttributes={{
            linkType: linkType,
            fromExecutionId: executionId,
            fromExecutionIds: executionIds,
            source: {
                createdFromExecutionId: executionId,
                createdFromRuleId: ruleId,
                kind: EXECUTION_SOURCE_TYPES.actionAdd.id,
            }
        }}
        addingCompleted={onAction}
        disableOnCompileWarnings={false}
    />;
}

const styles = (theme) => ({
    item: {
        color: theme.palette.grey.seven.main,
        fontSize: '14px',
        paddingTop: 5,
        width: '100%'
    },
});
export default withStyles(styles)(ExecutionActionButton);