import React from "react";
import withStyles from '@mui/styles/withStyles';
import NodeTextField from "../../graph/NodeTextField";
import {useNodeOrError} from "../../../hooks/nodeHooks";

const ProcedureCalculateEditor = ({disabled, ruleId, label}) => {
    const rule = useNodeOrError(ruleId)
    return (
        <>
            {
                rule.calculateValueQueryOptions &&
                <NodeTextField
                    nodeId={ruleId}
                    nodePropertyName={'calculateValueQuery'}
                    nodeValueProperty={'calculateValueHumanStored'}
                    disabled={disabled}
                    visual={'select'}
                    storeJson={true}
                    label={'Options'}
                    multiple={true}
                />
            }
            {
                !rule.calculateValueQueryOptions &&
                <NodeTextField
                    nodeId={ruleId}
                    nodePropertyName={'calculateValueHuman'}
                    disabled={disabled}
                    visual={'jsonlogic'}
                    label={label}
                />
            }

        </>
    );
}
const styles = () => ({});
export default withStyles(styles)(ProcedureCalculateEditor);