import React from "react";
import ExecutionShow from "../execution/ExecutionShow";
import { useGetOrCreateNode, useNodeOrNull } from "../../hooks/nodeHooks";
import { MINUTES_2 } from "../../util/constants";
import { NODE_TYPE_OPTIONS, PROCEDURE_TYPES } from "../../reducers/graphReducer";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import makeStyles from "@mui/styles/makeStyles";
import { strings } from "../components/SopLocalizedStrings";
import { PageContentWrapper } from "tbf-react-library";
import Typography from "@mui/material/Typography";

const pageStrings = strings.procedure.dashboard;

const useStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        paddingTop: '5rem'
    },
    title: {
        color: theme.palette.grey.seven.main,
        marginBottom: 10,
    },
    subText: {
        color: theme.palette.grey.five.main,
    }
}))

const ProcedureDashboard = ({procedureId, ...props}) => {

    const classes = useStyle();

    const procedure = useNodeOrNull(procedureId);

    const preview = useGetOrCreateNode(`preview-procedure-${procedureId}`, NODE_TYPE_OPTIONS.ExecutionPreview, {
        procedureId,
    }, !!procedure?.loadedFull);

    const renderDashboard = () => {
        if (procedure.procedureType !== PROCEDURE_TYPES.dashboard.id) {
            return <PageContentWrapper>
                <div className={classes.container} data-cy={'not-dashboard'}>
                    <Typography className={classes.title} variant="h1" color="inherit" noWrap>{pageStrings.unavailable}</Typography>
                    <div className={classes.subText} title={procedureId}>
                        {pageStrings.notDashboard}
                    </div>
                </div>
            </PageContentWrapper>;
        }

        if (procedure.compileWarnings?.length) {
            return <PageContentWrapper>
                <div className={classes.container} data-cy={'dashboard-invalid'}>
                    <Typography className={classes.title} variant="h1" color="inherit" noWrap>{pageStrings.unavailable}</Typography>
                    <div className={classes.subText} title={procedureId}>
                        {strings.formatString(pageStrings.invalid, procedure.name)}
                    </div>
                </div>
            </PageContentWrapper>;
        }

        return <ExecutionShow executionId={preview.executionId} dashboard/>;
    }

    return <GraphResourceLoad
        nodeId={procedureId}
        nodeType={NODE_TYPE_OPTIONS.ProcedureRoot}
        reloadIntervalMs={MINUTES_2}
        friendlyName={'Dashboard'}
    >
        {procedure && renderDashboard()}
    </GraphResourceLoad>;
}

export default ProcedureDashboard;