import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import IconButton from "@mui/material/IconButton";
import {reportUserError, SharedAuth} from "tbf-react-library";
import {Permissions} from "../../permissions";
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverRounded';
import {deleteAllClientData} from "../../actions/cleanup";
import Loader from "../components/Loader";
import {strings} from "../components/SopLocalizedStrings";
import GraphMobileListItem from './GraphMobileListItem';

class GraphClientDelete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isProcessing: false
        }
    }

    handleDeleteClicked = () => {
        this.setState({isProcessing: true});
        deleteAllClientData()
            .then(() => {
                this.setState({isProcessing: false});
            })
            .catch((err) => {
                this.setState({isProcessing: false});
                reportUserError(err);
            });
    };

    render() {
        let {canDelete, classes} = this.props;
        let {isProcessing} = this.state;
        if (!canDelete) {
            return null;
        }
        return <>
            {
                <IconButton size={'small'} title={strings.buttons.graphClientDelete}
                            onClick={this.handleDeleteClicked}
                            data-cy={'GraphDeleteClient'}>
                    <GraphMobileListItem>
                        <DeleteForeverIcon/>
                        {
                            isProcessing &&
                            <Loader circular={true} circularSize={42} source={strings.buttons.graphClientDelete}
                                    loaderStyleClass={classes.spinner}/>
                        }
                        {
                            !isProcessing &&
                            <span className={'menuItemTitle'}>{strings.menu.clientDelete}</span>
                        }
                    </GraphMobileListItem>
                </IconButton>
            }
        </>
    }
}

const styles = () => ({
    spinner: {
        position: 'absolute',
        color: '#FFF',
        top: 3,
        left: 3
    }
});
GraphClientDelete.propTypes = {};
const mapStateToProps = () => {
    let canDelete = SharedAuth.userHasPermission(Permissions.diagnostic.read) && SharedAuth.getClientId() === 'cypress';
    return {
        canDelete: canDelete
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphClientDelete));
