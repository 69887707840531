import React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import {
    setFileDeleteCompleted,
    setFileDeleteSnackbarToggleState,
    setFileDeleteSnackbarUndo
} from '../../actions/appActions.js';
import {strings} from "../components/SopLocalizedStrings";
import {TbfSnackbar} from "tbf-react-library";

class ExecutionDeleteSnackBar extends React.Component {

    onDeleteSnackbarClose = () => {
        const {onSetFileDeleteSnackbarToggleState, onSetFileDeleteSnackbarUndo, onSetFileDeleteCompleted} = this.props;
        onSetFileDeleteSnackbarToggleState(false);
        onSetFileDeleteSnackbarUndo(false);
        onSetFileDeleteCompleted(true);
    }

    undoDelete = () => {
        const {onSetFileDeleteSnackbarUndo} = this.props;
        onSetFileDeleteSnackbarUndo(true);
    }

    render() {
        const {
            fileLength,
            classes,
            fileDeleteSnackBarToggleState,
            onSetFileDeleteCompleted,
            onSetFileDeleteSnackbarToggleState,
            onSetFileDeleteSnackbarUndo
        } = this.props;

        return <TbfSnackbar
            onClickAway={() => {
                onSetFileDeleteCompleted(true);
                onSetFileDeleteSnackbarToggleState(false);
                onSetFileDeleteSnackbarUndo(false);
            }}
            open={fileDeleteSnackBarToggleState}
            onClose={this.onDeleteSnackbarClose}
            message={strings.formatString(strings.photo.deletePhoto.success, fileLength, (fileLength > 1 ? 's' : ''))}
            action={
                <React.Fragment>
                    <Button color="secondary" size="small" className={classes.undoDeleteButton}
                            title={strings.photo.show.undoButton}
                            onClick={() => this.undoDelete()}>
                        {strings.photo.show.undoButton}
                    </Button>
                    <IconButton size="small" title={strings.buttons.close} color="inherit"
                                onClick={this.onDeleteSnackbarClose}>
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                </React.Fragment>
            }
        />
    }
}

const styles = theme => ({
    undoDeleteButton: {
        color: theme.palette.element.three.main,
        textTransform: 'upperCase'
    },
});

const mapStateToProps = (state) => {
    return {
        fileDeleteSnackBarToggleState: state.appReducer.fileDeleteSnackBarToggleState,
        fileLength: state.appReducer.fileLength,
        deleteUndo: state.appReducer.deleteUndo,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onSetFileDeleteSnackbarToggleState: (state) => dispatch(setFileDeleteSnackbarToggleState(state)),
        onSetFileDeleteSnackbarUndo: (state) => dispatch(setFileDeleteSnackbarUndo(state)),
        onSetFileDeleteCompleted: (state) => dispatch(setFileDeleteCompleted(state)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionDeleteSnackBar));
