import React from "react";
import {connect} from "react-redux";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {strings} from "../components/SopLocalizedStrings";
import withStyles from '@mui/styles/withStyles';
import {
    getActiveNodesByFilter,
    getGraph,
    getNodeOrNull,
    getNodeSchemaOrError,
    getNodeSchemaOrNull,
    getNodesIfPresent
} from "../../selectors/graphSelectors";
import {NODE_IDS, NODE_TYPE_OPTIONS, UserConstant} from "../../reducers/graphReducer";
import {putNodeProperty, putNodes} from "../../actions";
import {createNode} from "../../factory/graphFactory";
import {hasValue} from "../../util/util";
import {TbfRichTextEditorWithExecutionSearch} from "tbf-draft-js";
import ExecutionPhotoListPhoto from "./question/attachment/ExecutionPhotoListPhoto";
import ExecutionPhotoUploadBase from "./ExecutionPhotoUploadBase";
import {downloadAttachment} from "../../actions/executions";
import {MINUTES_5} from "../../util/constants";

export const RICHTEXT_MAX_SIZE = 10 * 1024 * 1024; // 10MB

class SopRichTextEditor extends ExecutionPhotoUploadBase {
    constructor(props) {
        super(props);
        this.handleFileAdded = this.handleFileAdded.bind(this);
    }

    componentDidMount() {
        let {selectorSchema, selector, onPutNodeProperty, selectorId} = this.props;
        if (selector == null) {
            let selectorNode = createNode(selectorSchema, {id: selectorId});
            onPutNodeProperty(selectorNode);
        }
    }

    onMentionSearchChange = (search) => {
        let {selectorId, onPutNodeProperty} = this.props;
        onPutNodeProperty({id: selectorId, mentions: search});
    }

    render() {
        const {
            classes,
            loadFilteredUrl,
            ...rest
        } = this.props;

        return <React.Fragment>
            {
                loadFilteredUrl &&
                <GraphResourceLoad
                    key={loadFilteredUrl}
                    resourcePath={loadFilteredUrl}
                    friendlyName={strings.execution.namePlural}
                    nodeType={'ExecutionRoot'}
                    reloadIntervalMs={MINUTES_5}
                    hideLoader={true}
                    incrementalLoadOff={true}
                />
            }
            <TbfRichTextEditorWithExecutionSearch
                downloadAttachment={downloadAttachment}
                GalleryPhotoTileComponent={ExecutionPhotoListPhoto}
                handleFileAdded={this.handleFileAdded}
                onMentionSearchChange={this.onMentionSearchChange}
                {...rest}/>
        </React.Fragment>;
    }
}

const styles = () => ({});

const mapStateToProps = (state, ownProps) => {
    const selectorId = NODE_TYPE_OPTIONS.ExecutionSelector + '-' + ownProps.id;
    const selector = getNodeOrNull(state, selectorId);
    const mentions = (selector && selector.mentions) || null;
    const UsersProcedureId = NODE_IDS.UserSubject;
    const defaultLimit = 20;
    let loadFilteredUrl = `/executions?summary=true&limit=${defaultLimit}`;
    if (mentions && mentions.value && hasValue(mentions.value.trim())) {
        if (mentions.trigger === '@') {
            loadFilteredUrl += `&procedureIds=${UsersProcedureId}`
        }
        if (mentions.value) {
            loadFilteredUrl += `&q=${encodeURIComponent(mentions.value)}`
        }
    } else {
        loadFilteredUrl = null;
    }

    let loadFilteredResourceSync = getNodeOrNull(state, loadFilteredUrl);
    let loadedFilteredNodes = getNodesIfPresent(state, (loadFilteredResourceSync && loadFilteredResourceSync.nodeIds) || []);
    let executions = loadedFilteredNodes.filter(a => a.deleted !== true);
    const isLoading = loadFilteredResourceSync === null || (loadFilteredResourceSync.loading && !hasValue(loadFilteredResourceSync.nodeIds));
    const loadingStarted = loadFilteredResourceSync && loadFilteredResourceSync.loading;
    let mentionSuggestions = [];
    if (loadFilteredUrl) {
        if (isLoading) {
            mentionSuggestions = [{
                name: 'Loading....',
            }];
        } else {
            mentionSuggestions = executions.map(d => ({
                name: d.name === UserConstant ? d.title : d.key + ' - ' + d.title,
                link: `/app/executions/${d.rootId}`,
                title: d.title,
                type: d.name,
            }));
        }
    }

    const executionId = ownProps.executionId;
    const executionQuestionId = ownProps.nodeId;
    let executionNode = getNodeOrNull(state, executionId);
    const field = 'initial';
    let filter = {type: 'Photo', executionId: executionId};
    let photos = getActiveNodesByFilter(state, filter).filter(d => (d.originalUrl ? d.originalUrl.indexOf('.jpg') !== -1 : true));
    const focusedNode = getGraph(state).focusedNode;
    const previousFocusedNode = getGraph(state).previousFocusedNode;
    const focusedNodeId = (focusedNode?.id || previousFocusedNode?.id) + (focusedNode?.propertyName || previousFocusedNode?.propertyName);
    const elementId = executionQuestionId + ownProps.id;
    const isFocused = focusedNodeId === elementId;

    return {
        loadFilteredUrl,
        selector,
        selectorId,
        mentionSuggestions,
        selectorSchema: getNodeSchemaOrError(state, NODE_TYPE_OPTIONS.ExecutionSelector),
        isLoading,
        loadingStarted,
        allPhotoIds: photos.map(d => d.id),
        isFocused,
        elementId,
        projectId: executionNode?.projectId,
        executionId: executionNode?.id,
        procedureId: executionNode?.projectId,
        executionQuestionId,
        photoSchema: getNodeSchemaOrNull(state, 'Photo'),
        photoCaptureSchema: getNodeSchemaOrNull(state, 'PhotoCapture'),
        photoInputId: executionQuestionId + '-' + field,
        field,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        onPutNodes: nodes => dispatch(putNodes(nodes)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SopRichTextEditor));
