import {executePromise, getApiBaseUrl} from "./commonAction";

export const searchActivity = (criteria) => {
    let url = `${getApiBaseUrl()}/activity?`;
    if (criteria.userId) {
        url += 'userId=' + encodeURIComponent(criteria.userId) + '&';
    }
    if (criteria.rootId) {
        url += 'rootId=' + encodeURIComponent(criteria.rootId) + '&';
    }
    return executePromise('get', url);
};