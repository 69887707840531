import React, {useCallback, useState} from 'react'
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import SignatureWrapper from './SignatureWrapper';
import {focusNodeProperty, putNodesProperty} from "../../../../../actions";
import {connect} from "react-redux";

function ExecutionQuestionSignature({classes, onFocusNodeProperty, ...rest}) {
    const [editing, setEditing] = useState()
    const handleToggleEditMode = useCallback(() => {
        const {questionId} = rest;
        setEditing(value => !value);
        onFocusNodeProperty(questionId);
    }, [onFocusNodeProperty, rest])
    return (
        <>
            <SignatureWrapper
                {...rest}
                loadSignature
                editing={false}
                onToggleEditMode={handleToggleEditMode}
            />
            {editing && (
                <Dialog
                    open={true}
                    maxWidth='md'
                >
                    <DialogContent className={classes.dialogContent}>
                        <SignatureWrapper
                            {...rest}
                            loadSignature={false}
                            editing
                            onToggleEditMode={handleToggleEditMode}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    )
}

const styles = (theme) => ({
    dialogContent: {
        padding: '0px !important',
        maxWidth: '100%',
        width: 600
    }
});
const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
        onFocusNodeProperty: (id, propertyName) => dispatch(focusNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionSignature));
