import { useEffect } from 'react'
import forEach from 'lodash/forEach'
import find from 'lodash/find'

export const deleteSelectedObjects = (canvas) => {
  if(canvas) {
    forEach(canvas.getActiveObjects(), item => {
      canvas.remove(item)
    })
    canvas.renderAll()
  }
}

export default function useDeleteKeyDownListener (enabled, canvasRef) {
  useEffect(() => {
    if(enabled) {
      const onKeyUp = (e) => {
        if(e.keyCode === 8 || e.keyCode === 46) { // Delete and Backspace
          const { canvas } = canvasRef.current
          if(canvas) {
            if(!find(canvas.getObjects(), obj => obj.isEditing)) {
              deleteSelectedObjects(canvas)
            }
          }
        }
      }
      window.addEventListener('keyup', onKeyUp)
      return () => {
        window.removeEventListener('keyup', onKeyUp)
      }
    }
  }, [enabled, canvasRef])
}