import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import Loader from "./components/Loader";

class LoginReturn extends Component {

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Loader source={'Login Return'}/>
            </div>
        );
    }
}

const styles = () => ({});

export default withStyles(styles)(LoginReturn);
