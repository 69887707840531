import {getStorageInfo} from "leaflet.offline";
import {keyBy} from "lodash";

export const addMultiPointSave = (L, saveControl) => {
    saveControl._getTileUrls = function (layer, bounds, zoom) {

        const tiles = [];
        const tileBounds = L.bounds(
            bounds.min.divideBy(layer.getTileSize().x).floor(),
            bounds.max.divideBy(layer.getTileSize().x).floor(),
        );
        // OSM
        layer._tileZoom = zoom;
        for (let j = tileBounds.min.y; j <= tileBounds.max.y; j += 1) {
            for (let i = tileBounds.min.x; i <= tileBounds.max.x; i += 1) {
                const tilePoint = new L.Point(i, j);
                tilePoint.z = zoom;
                const tileUrl = layer.getTileUrl(tilePoint);
                let templateUrl = layer._url;
                // ESRI stores config in url
                templateUrl = templateUrl?.url || templateUrl;
                tiles.push({
                    key: tileUrl,
                    url: tileUrl,
                    z: zoom,
                    x: i,
                    y: j,
                    urlTemplate: templateUrl,
                    retrievedDate: new Date().toISOString()
                });
            }
        }

        return tiles;
    };
    saveControl._getTileUrls = saveControl._getTileUrls.bind(saveControl);
    saveControl._saveTiles = (cancellationToken) => {

        let bounds;
        let tiles = [];
        const seenTiles = {};
        // minimum zoom to prevent the user from saving the whole world
        const minZoom = 5;
        // current zoom or zoom options
        let zoomlevels = [];

        let saveAreas = saveControl.options.saveAreas;
        if (!saveAreas) {
            if (saveControl.options.saveWhatYouSee) {
                const currentZoom = saveControl._map.getZoom();
                if (currentZoom < minZoom) {
                    throw new Error("It's not possible to save with zoom below level 5.");
                }
                const {maxZoom} = saveControl.options;

                for (let zoom = currentZoom; zoom <= maxZoom; zoom += 1) {
                    zoomlevels.push(zoom);
                }
            } else {
                zoomlevels = saveControl.options.zoomlevels || [saveControl._map.getZoom()];
            }
            const latlngBounds = saveControl.options.bounds || saveControl._map.getBounds();
            if (latlngBounds) {
                for (let zoomLevel of zoomlevels) {
                    saveAreas.push({zoomLevel, bounds: latlngBounds});
                }
            }
        }

        let start = new Date().getTime();
        for (let saveArea of saveAreas) {
            bounds = L.bounds(
                saveControl._map.project(saveArea.bounds.getNorthWest(), saveArea.zoomLevel),
                saveControl._map.project(saveArea.bounds.getSouthEast(), saveArea.zoomLevel),
            );
            let newTiles = saveControl._getTileUrls(saveControl._baseLayer, bounds, saveArea.zoomLevel);
            tiles = tiles.concat(newTiles.filter(a => !seenTiles[a.key]));
            for (let tile of newTiles) {
                seenTiles[tile.key] = true;
            }
        }
        console.info(`Computing tiles took ${new Date().getTime() - start} ms to get ${tiles.length} tiles.`);
        let url = saveControl._baseLayer._url;
        url = url?.url || url;
        getStorageInfo(url).then(items => {
            let alreadyLoaded = keyBy(items, 'key');
            let missingTiles = tiles.filter(a => !alreadyLoaded[a.key]);
            console.info(`Downloading ${missingTiles.length} tiles for layer: ${url}`);
            // Need at least one for the done event to occur
            saveControl._resetStatus(missingTiles);
            const successCallback = async () => {
                saveControl._baseLayer.fire('savestart', {...saveControl.status, lengthTotal: tiles.length});
                const loader = () => {
                    if (missingTiles.length === 0 || cancellationToken?.cancel) {
                        // If they fail this is not firing
                        saveControl._baseLayer.fire('saveend', saveControl.status);
                        return Promise.resolve();
                    }
                    const tile = missingTiles.shift();
                    return saveControl._loadTile(tile).then(() => {
                        setTimeout(loader, 5);
                    }).catch(err => {
                        // TODO What to do if error? For now we will skip
                        console.error(err)
                        setTimeout(loader, 5);
                        saveControl.status.lengthFailed = (saveControl.status.lengthFailed || 0) + 1
                        saveControl._baseLayer.fire('loadtilefailed', saveControl.status);
                    });
                };
                const parallel = Math.min(missingTiles.length, saveControl.options.parallel);
                for (let i = 0; i < parallel; i += 1) {
                    loader();
                }
            };
            if (missingTiles.length === 0) {
                setTimeout(() => {
                    saveControl._baseLayer.fire('savestart', {...saveControl.status, lengthTotal: tiles.length});
                    saveControl._baseLayer.fire('saveend', saveControl.status);
                }, 5);
            } else if (saveControl.options.confirm) {
                saveControl.options.confirm(saveControl.status, successCallback);
            } else {
                successCallback();
            }
        });
    }
    saveControl._saveTiles = saveControl._saveTiles.bind(saveControl);
};