import React from "react";
import withStyles from '@mui/styles/withStyles';
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseRounded";
import ProgressWithLabel from "../components/ProgressWithLabel";
import {strings} from "../components/SopLocalizedStrings";
import {EXECUTION_STATUS} from "../../reducers/graphReducer";

class WorkItemProgressDetails extends React.Component {

    render() {
        const {
            classes,
            handleCloseProgressPopover,
            status,
            totalQuestionCount,
            completedQuestionCount
        } = this.props;

        return <React.Fragment>
            <div className={classes.progressPopoverContainer}>
                <div className={'header'}>
                    <span>Progress</span>
                    <div className={classes.grow}/>
                    <IconButton size={'small'} className={'closeButton'} title={strings.buttons.close}
                                onClick={handleCloseProgressPopover}>
                        <CloseIcon/>
                    </IconButton>
                </div>
                <div className={'content'}>
                    <ProgressWithLabel total={totalQuestionCount} completed={completedQuestionCount}/>
                    {
                        (status !== EXECUTION_STATUS.done.id) &&
                        <span
                            className={'subText'}>Status changes to 'Done' once all questions are answered</span>
                    }

                    {
                        (status.toLowerCase() === EXECUTION_STATUS.done.id) &&
                        <span
                            className={'subText'}>Status is 'Done', all questions are answered</span>
                    }
                </div>
            </div>
        </React.Fragment>
    }
}

const styles = theme => ({
    progressPopoverContainer: {
        background: theme.palette.primary.two.main,
        maxWidth: 220,
        '& .header': {
            display: 'flex',
            alignItems: 'center',
            padding: '10px 15px',
            color: theme.palette.grey.seven.main,
            fontFamily: theme.fonts.primary.bold,
            '& .closeButton': {
                position: 'absolute',
                right: 3,
                top: 3,
            }
        },
        '& .content': {
            padding: '10px 15px 12px 15px',
        },
        '& .subText': {
            color: theme.palette.grey.four.main,
            fontSize: 12,
            marginTop: 10,
            display: 'block',
        }
    },
});

export default withStyles(styles)(WorkItemProgressDetails);
