import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodesIfPresent} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../style/alert.css';
import {putNodeProperty} from "../../actions";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InfoIcon from '@mui/icons-material/InfoRounded';
import {COMPLETE_ACTION_STYLES, DIAGNOSTIC_MODES, NAVIGATION_STYLES, NODE_IDS} from "../../reducers/graphReducer";
import Grid from "@mui/material/Grid";
import SwipableTabs from '../components/SwipableTabs';
import ExecutionNavigationButton from "./ExecutionNavigationButton";
import GraphNodeJson from "../graph/GraphNodeJson";
import {strings} from "../components/SopLocalizedStrings";
import ExecutionTOC from "./ExecutionTOC";
import ExecutionTreeLayoutStepDetails from "./ExecutionTreeLayoutStepDetails";
import {getCompleteActionStyle} from "../../selectors/executionSelectors";
import {ComponentBase, Error, SharedAuth, withMobile} from "tbf-react-library";
import cn from "classnames";
import {Drawer} from "@mui/material";
import ExecutionCancelReturn from './ExecutionCancelReturn';
import {EMPTY_ARRAY} from "../../util/util";

class ExecutionStepsContent extends ComponentBase {

    constructor(props) {
        super(props);
        this.state = {
            showJson: false
        };
        this.toggleShowJson = this.toggleShowJson.bind(this);
        this.executionLevelTreeContainerRef = React.createRef();
    }

    toggleShowJson() {
        this.setState(state => ({showJson: !state.showJson}));
    }

    handleTabChange = (event, value, scroll) => {
        let {executionId} = this.props;
        this.props.onPutNodeProperty({id: executionId, selectedStepIndex: value});
        if (scroll) {
            const executionStepTabContainer = document.getElementById('executionStepTabContainer');
            const boundingRect = executionStepTabContainer && executionStepTabContainer.getBoundingClientRect();
            if (boundingRect) {
                const tabContainerTopPos = (boundingRect.bottom - boundingRect.top) + boundingRect.height;
                setTimeout(() => window.scrollTo(0, tabContainerTopPos)); // Set timeout is used to wait for React to finish rendering all questions and its child component
            }
        }
    };

    getSelectedStep() {
        let {selectedStepIndex, visibleStepsIds} = this.props;
        return visibleStepsIds[selectedStepIndex];
    }

    hideTocTreeView = () => {
        let {executionId, onPutNodeProperty} = this.props;
        onPutNodeProperty({id: executionId, treeViewToggleState: false});
    }

    render() {
        let {
            classes,
            diagnosticsOn,
            executionId,
            selectedStepIndex,
            visibleStepsIds,
            stepsMissing,
            navigationStyle,
            showTreeView,
            mobileViewPort,
            troubleshootOn,
            showCancel
        } = this.props;
        let {showJson} = this.state;
        let selectedStepNode = this.getSelectedStep();
        const showTreeViewAsSideNav = !mobileViewPort && showTreeView;
        const columns = showTreeViewAsSideNav && navigationStyle === NAVIGATION_STYLES.toc.id ? 8 : 12;
        const renderingTOC = navigationStyle === NAVIGATION_STYLES.toc.id;

        return (
            <>
                <div key={'ExecutionStepsContent-' + executionId}>
                    {
                        stepsMissing && <Error message={strings.execution.errors.stepsMissing}/>
                    }
                    <Grid container>
                        {
                            showTreeViewAsSideNav &&
                            <Grid item xs={4} sm={4} md={4} className={classes.executionLevelTreeContainer}
                                  ref={this.executionLevelTreeContainerRef}>
                                <ExecutionTOC executionId={executionId}
                                              parentRef={this.executionLevelTreeContainerRef}/>
                            </Grid>
                        }
                        <Grid item xs={columns} sm={columns} md={columns}>
                            {
                                navigationStyle === NAVIGATION_STYLES.toc.id &&
                                <ExecutionTreeLayoutStepDetails executionId={executionId}/>
                            }
                            {
                                selectedStepNode &&
                                <SwipableTabs
                                    tabSelectedIndex={selectedStepIndex}
                                    onChangeIndex={this.handleTabChange}
                                    visibleStepsIds={visibleStepsIds}
                                    troubleshootOn={troubleshootOn}
                                    diagnosticsOn={diagnosticsOn}
                                />
                            }
                        </Grid>
                    </Grid>
                    {
                        !selectedStepNode &&
                        <div>You do not have access to any Steps</div>
                    }
                    <Toolbar disableGutters={true} className={cn(classes.tabNavigationFooter, {
                        [classes.tabNavigationFooterContentView]: renderingTOC && showTreeViewAsSideNav,
                        [classes.tabNavigationFooterTabView]: !renderingTOC || !showTreeViewAsSideNav
                    })}>
                        <ExecutionNavigationButton
                            handleTabChange={this.handleTabChange}
                            selectedStepIndex={selectedStepIndex}
                            visibleSteps={visibleStepsIds}
                            executionId={executionId}
                            direction={'Previous'}
                        />
                        <div className={classes.grow}/>
                        {
                            diagnosticsOn &&
                            <IconButton
                                title={strings.buttons.diagnostics}
                                data-cy='toggle-diagnostics'
                                className={classes.info}
                                onClick={this.toggleShowJson}
                                size="large">
                                <InfoIcon/>
                            </IconButton>
                        }
                        {
                            showCancel &&
                            <ExecutionCancelReturn variant="inherit" />
                        }
                        <ExecutionNavigationButton
                            handleTabChange={this.handleTabChange}
                            selectedStepIndex={selectedStepIndex}
                            visibleSteps={visibleStepsIds}
                            executionId={executionId}
                            direction={'Next'}
                        />

                    </Toolbar>
                    {
                        showJson &&
                        <Grid container>
                            <Grid item xs={12}>
                                <GraphNodeJson nodeId={selectedStepNode}/>
                            </Grid>
                        </Grid>
                    }
                </div>


                {/*Display ToC step/task selector as modal*/}
                {mobileViewPort && <Drawer
                    anchor={'bottom'}
                    open={showTreeView}
                    ModalProps={{ onBackdropClick: this.hideTocTreeView }}
                >
                    <div className={classes.executionLevelTreeContainer}
                         ref={this.executionLevelTreeContainerRef}>
                        <ExecutionTOC executionId={executionId}
                                      parentRef={this.executionLevelTreeContainerRef}/>
                    </div>
                </Drawer>}
            </>

        );
    }

}

const styles = (theme) => ({
    progressIcon: {
        verticalAlign: 'bottom'
    },
    button: {
        margin: theme.spacing(1),
    },
    grow: {
        flexGrow: 1,
    },
    tabNavigationFooter: {
        position: 'absolute',
        bottom: 0,
    },
    tabNavigationFooterContentView: {
        width: '66.666667%',
        left: '33.333333%',
    },
    tabNavigationFooterTabView: {
        width: '100%',
        left: 0,
    },
    executionLevelTreeContainer: {
        paddingRight: theme.spacing(),
        zIndex: 1,
    }
});

ExecutionStepsContent.propTypes = {
    executionId: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};
const mapStateToProps = (state, ownProps) => {
    const userDevice = getNodeOrNull(state, NODE_IDS.UserDevice);
    let executionNode = getNodeOrNull(state, ownProps.executionId);
    let stepIds = (executionNode && executionNode.children) || EMPTY_ARRAY;
    const visibleStepIds = executionNode?.activeChildren || EMPTY_ARRAY;
    const visibleSteps = getNodesIfPresent(state, visibleStepIds);
    let steps = getNodesIfPresent(state, stepIds);
    let stepsMissing = stepIds.length !== steps.length;
    let selectedStepIndex = executionNode.selectedStepIndex || 0;
    if (selectedStepIndex >= visibleSteps.length) {
        selectedStepIndex = visibleSteps.length - 1;
    }
    const navigationStyle = executionNode?.navigationStyle;

    const completeActionStyle = getCompleteActionStyle(state, ownProps.executionId);

    const isAnonymous = SharedAuth.isAnonymous();
    const showCancel = isAnonymous && completeActionStyle === COMPLETE_ACTION_STYLES.standard.id;

    return {
        stepsMissing: stepsMissing,
        selectedStepIndex: selectedStepIndex,
        visibleStepsIds: visibleStepIds,
        diagnosticsOn: userDevice.diagnosticMode === DIAGNOSTIC_MODES.full.id,
        troubleshootOn: userDevice.troubleshootOn,
        navigationStyle: navigationStyle,
        showTreeView: navigationStyle === NAVIGATION_STYLES.toc.id && executionNode.treeViewToggleState,
        showCancel,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withMobile()(withStyles(styles)(ExecutionStepsContent)));
