import {createSelector} from 'reselect'
import {NODE_IDS} from '../../../reducers/graphReducer'
import {getNodes} from '../../../selectors/graphSelectors'
import get from 'lodash/get'

export const getMyAssignments = createSelector(
    getNodes,
    (nodes) => {
        const assignmentIds = get(nodes, [NODE_IDS.MyAssignments, 'nodeIds'], []);
        return assignmentIds.map(nodeId => get(nodes, [nodeId])).filter(a => a)
    }
)

export const getMyAssignmentFeatures = createSelector(
    getNodes,
    (nodes) => {
        const assignmentIds = get(nodes, [NODE_IDS.MyAssignments, 'nodeIds'], []);
        return assignmentIds.map(nodeId => get(nodes, [nodeId])).filter(a => a?.feature).map(getNodeFeature)
    }
)

export const getNodeFeature = node => {
    if (node.feature?.properties?.id) {
        return node.feature;
    }
    return {
        ...node.feature,
        properties: {
            id: node.id,
            ...node?.feature?.properties,
        }
    }
}