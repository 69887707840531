import {nodeFactory, SYSTEM_PROPERTIES} from "./common";

export const CLIENT_CONFIG_SCHEMA = {
    type: 'ClientConfig',
    properties: {
        ...SYSTEM_PROPERTIES,
        clientId: {kind: 'string'},
        imageEditor: {kind: 'object'},
        mapSettings: {kind: 'object'},
        menu: {kind: 'object'},
        procedures: {kind: 'object'},
        tenantId: {kind: 'string'},
        timezones: {kind: 'object'},
        userCounter: {kind: 'object'}
    },
    isRoot: true,
    storeServer: false,
    storeDb: false,
    storeRedux: true,
    dependentNodePaths: {},
    factory: nodeFactory('ClientConfig', {
    }),
}