import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import {blurNodeProperty, focusNodeProperty, putNodesProperty} from "../../../../actions";
import Input from "@mui/material/Input";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ExecutionQuestionInputBase from './ExecutionQuestionInputBase';
import {reportError} from "tbf-react-library";

class ExecutionQuestionText extends ExecutionQuestionInputBase {
    constructor() {
        super();
        this.state = {
            focused: false,
            cursorPositions: null,
        }
        this.handleChange = this.handleChange.bind(this);
        this.inputRef = React.createRef(null);
    }

    inputValue = null;
    cursorPositions = null;

    componentDidMount() {
        let {value} = this.props;
        this.inputValue = value
    }


    componentDidUpdate(prevProps) {
        let {value, node} = this.props;
        const {value: prevValue} = prevProps;
        const {focused} = this.state;
        if (focused && value != prevValue && value != this.inputValue) {
            reportError('Text question input changed not by user.', `Question [${node.name}] on [${node.rootId}] value changed unexpectedly from [${this.inputValue?.length}] to [${value?.length}] characters.`)
            if (this.inputRef.current && this.cursorPositions) {
                const {start, end} = this.cursorPositions;
                this.inputRef.current.setSelectionRange?.(start, end);
            }
            this.inputValue = value;
        }
    }

    handleChange = (e) => {
        let {field} = this.props;
        const value = e.target.value;
        this.inputValue = value === '' ? null : value;
        this.updateNodeProperty(field, value, field);
        this.cursorPositions = {
            start: e.target.selectionStart,
            end: e.target.selectionEnd,
        };
    };

    handleFocus = () => {
        this.setState({focused: true});
    }

    handleBlur = () => {
        this.setState({focused: false});
    }

    render = () => {
        const {value, valueUnknown, field, textMultipleLines, classes} = this.props;

        const {focused} = this.state;


        const {inputProps, className, ...standardProperties} = this.renderInputStandardProperties(value, valueUnknown, field, this.handleFocus, this.handleBlur);
        return (
            <Input
                multiline={textMultipleLines}
                minRows={focused && textMultipleLines ? 3 : 1}
                maxRows={textMultipleLines ? 100 : 1}
                fullWidth={true}
                startAdornment={<EditRoundedIcon/>}
                onChange={this.handleChange}
                {...standardProperties}
                className={`${className} ${classes.textBox}`}
                required={true}
                inputProps={{
                    ...inputProps,
                    ref: this.inputRef,
                }}
            />);
    }
}

const styles = () => ({
    textBox: {
        '& textarea': {
            transition: 'height .3s, border-color .3s !important',
        },
        '& textarea:not(:focus)': {
            transitionDelay: '0.3s !important'
        },
    },
});
ExecutionQuestionText.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let node = ownProps.node;
    let valueUnknown = node[ownProps.field + 'Unknown'];
    let valueDisabled = ownProps.disabled || (node && node.initialValueReadOnly);

    return {
        questionName: node.name,
        valueUnknown: valueUnknown,
        valueDisabled: valueDisabled,
        textMultipleLines: node.textMultipleLines === true,
        node: node
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
        onFocusNodeProperty: (id, propertyName) => dispatch(focusNodeProperty(id, propertyName)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionText));
