import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {buildLookup, getNodeOrNull, getNodeSchemaOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import NodeTextField from "../graph/NodeTextField";
import ProjectAddItpPrefill from "./ProjectAddProcedurePrefill";
import Grid from "@mui/material/Grid";
import {putNode, putNodeProperty} from "../../actions";
import ProjectAddProcedureButton from "./ProjectAddProcedureButton";
import PageComponentBase from "../PageComponentBase";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {strings} from "../components/SopLocalizedStrings";
import ExecutionReferenceBadge from "../execution/ExecutionReferenceBadge";
import {createNode} from "../../factory/graphFactory";
import {Breadcrumbs, HistoryBackButton, PageContentWrapper, PageHeader} from "tbf-react-library";
import Dashboard from "@mui/icons-material/DashboardRounded";
import {NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {hasProcedurePermission} from "../../selectors/procedureSelectors";
import {Permissions} from "../../permissions";
import {hasItems} from "../../util/util";
import {HOURS_1} from "../../util/constants";

const pageStrings = strings.project.addExecution;

class ProjectAddProcedure extends PageComponentBase {

    componentDidMount() {
        super.componentDidMount();
        this.prepare();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        this.prepare();
    }

    prepare = () => {
        let {createExecutionsSchema, createExecutionNode, createExecutionId, projectNode, onPutNode} = this.props;
        if (createExecutionNode == null && projectNode && projectNode.loaded) {
            let node = createNode(createExecutionsSchema, {id: createExecutionId, projectId: projectNode.id});
            onPutNode(node);
        }
    };

    getBreadCrumbs = () => {
        const {
            projectNode
        } = this.props;
        if (projectNode) {
            return [
                {name: strings.project.namePlural, to: '/projects'},
                {name: projectNode.name || '[Blank]', to: '/projects/' + projectNode.id},
            ];
        }
        return [];
    }

    render() {
        let {
            classes,
            projectNode,
            createExecutionNode,
            procedureOptions,
            nodeId,
            procedureNode,
            canCreate
        } = this.props;
        const hasCompileWarnings = hasItems(procedureNode?.compileWarnings)
        return (
            <GraphResourceLoad
                resourcePath={`/projects?id=${nodeId}&includeDeleted=true`}
                nodeId={nodeId}
                nodeType={'ProjectRoot'}
            >
                <GraphResourceLoad
                    resourcePath={'/procedures?summary=true'}
                    displayErrorMode={'toast'}
                    friendlyName={strings.procedure.namePlural}
                    nodeType={NODE_TYPE_OPTIONS.ProcedureRoot}
                >
                    <>
                        {
                            createExecutionNode && projectNode && projectNode.loaded &&
                            <PageHeader
                                title={strings.formatString(pageStrings.title, projectNode.name)}
                                PageIcon={<Dashboard/>}
                                renderNavigationActions={() => <HistoryBackButton/>}
                                renderPageActions={() => {
                                    return <>
                                        {
                                            createExecutionNode.procedureId && procedureNode && !hasCompileWarnings &&
                                            <ProjectAddProcedureButton
                                                createExecutionId={createExecutionNode.id}/>
                                        }
                                    </>
                                }}
                            />
                        }
                        <PageContentWrapper>
                            {
                                createExecutionNode && projectNode && projectNode.loaded &&
                                <div style={{maxWidth: '900px'}}>
                                    <Breadcrumbs list={this.getBreadCrumbs()}/>
                                    <form>
                                        <Grid container spacing={2} justifyContent={"flex-start"}>
                                            <Grid item xs={12}>
                                                <NodeTextField nodeId={projectNode.id}
                                                               nodePropertyName={'name'}
                                                               disabled={true}
                                                               label={'Project Name'}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <NodeTextField nodeId={createExecutionNode.id}
                                                               nodePropertyName={'procedureId'}
                                                               label={pageStrings.templateLabel}
                                                               nodeValueOptions={procedureOptions}/>
                                            </Grid>
                                            {
                                                createExecutionNode && createExecutionNode.procedureId &&
                                                <GraphResourceLoad
                                                    key={createExecutionNode.procedureId}
                                                    resourcePath={NODE_IDS.ProcedureFull(createExecutionNode.procedureId)}
                                                    nodeId={createExecutionNode.procedureId}
                                                    friendlyName={strings.procedure.name}
                                                    nodeType={'ProcedureRoot'}
                                                    reloadIntervalMs={HOURS_1}
                                                    hideOfflineWarnings={true}
                                                />
                                            }
                                            {

                                                procedureNode && hasCompileWarnings &&
                                                <div className={'alert alert-error ' + classes.marginLeft8}>
                                                    {
                                                        strings.formatString(pageStrings.procedureCompileErrorMessage,
                                                            <Link to={`/procedures/${procedureNode.id}`}>
                                                                {procedureNode.name}
                                                            </Link>)
                                                    }
                                                </div>
                                            }
                                            {
                                                createExecutionNode.procedureId && procedureNode && !hasCompileWarnings &&
                                                <React.Fragment>
                                                    <Grid item xs={12}>
                                                        {
                                                            canCreate &&
                                                            <ProjectAddItpPrefill
                                                                createExecutionId={createExecutionNode.id}/>
                                                        }
                                                        {
                                                            !canCreate && <div
                                                                className={'alert alert-danger'}>{strings.execution.errors.canNotCreate}</div>
                                                        }
                                                    </Grid>
                                                </React.Fragment>
                                            }
                                        </Grid>
                                    </form>
                                </div>
                            }
                            {
                                procedureNode && createExecutionNode.createdExecutionIds.length > 0 &&
                                <React.Fragment>
                                    <div className={'alert alert-success'}>
                                        Created {createExecutionNode.createdExecutionIds.length} x {procedureNode.name}
                                        <ul>
                                            {
                                                createExecutionNode.createdExecutionIds.map(a =>
                                                    (
                                                        <li key={a}><ExecutionReferenceBadge executionId={a}/></li>
                                                    ))
                                            }
                                        </ul>
                                    </div>
                                </React.Fragment>

                            }
                        </PageContentWrapper>
                    </>
                </GraphResourceLoad>
            </GraphResourceLoad>
        );
    }
}

const styles = () => ({
    grow: {
        flexGrow: 1,
    },
    marginLeft8: {
        marginLeft: 8
    }
});
ProjectAddProcedure.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.match.params.id;
    let createNode = getNodeOrNull(state, 'create-executions-for-' + nodeId);
    let resourceLoader = getNodeOrNull(state, '/procedures?summary=true');
    let procedureOptions = buildLookup(state, resourceLoader?.nodeIds);
    let canCreate = hasProcedurePermission(state, createNode?.procedureId, Permissions.execution.create);
    return {
        nodeId: nodeId,
        projectNode: getNodeOrNull(state, nodeId),
        procedureNode: getNodeOrNull(state, createNode?.procedureId),
        createExecutionId: 'create-executions-for-' + nodeId,
        createExecutionNode: createNode,
        createExecutionsSchema: getNodeSchemaOrNull(state, 'CreateExecutionsRoot'),
        procedureOptions: procedureOptions,
        canCreate: canCreate
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNode: node => dispatch(putNode(node)),
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProjectAddProcedure)));
