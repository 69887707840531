import React from "react";
import withStyles from '@mui/styles/withStyles';
import WarningIcon from "@mui/icons-material/WarningRounded";
import {useNodeOrNull} from "../../hooks/nodeHooks";
import {strings} from "../components/SopLocalizedStrings";

const pageStrings = strings.graph.node;
const NodeProcessingWarning = ({nodeId, classes}) => {
    const node = useNodeOrNull(nodeId);
    const processingWarning = node?.processingWarning;
    return (
        <>
            {
                !!processingWarning &&
                <div className={`alert alert-danger alert-icon ${classes.alert}`}>
                    <WarningIcon/> {pageStrings.processingWarningMessage}
                    <ul>
                        <li className={classes.listItem}>{processingWarning}</li>
                    </ul>
                </div>
            }
        </>
    );
}
const styles = () => ({
    listItem: {
        whiteSpace: 'break-spaces'
    },
    alert: {
        width: '100%',
    }
});
export default withStyles(styles)(NodeProcessingWarning);