import React, { memo } from "react";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";

function PopperMenu({open, anchorEl, role, placement, children, onClose, ariaLabelledBy, popperProps, paperProps, autoFocusMenuItem = false, clickAway = false}) {

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            onClose?.();
        } else if (event.key === 'Escape') {
            onClose?.();
        }
    }

    const getTransformOrigin = (placement) => {
        switch (placement) {
            case "bottom-start":
                return "left top";
            case "bottom-end":
                return "right top";
            default:
                return "left bottom";
        }
    }

    const wrapInner = (inner) => {
        if (clickAway) {
            inner = <ClickAwayListener onClickAway={onClose}>
                {inner}
            </ClickAwayListener>
        }

        return inner;
    }

    return <Popper
        open={open}
        anchorEl={anchorEl}
        role={role}
        placement={placement}
        transition
        disablePortal
        {...popperProps}
    >
        {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                style={{
                    transformOrigin: getTransformOrigin(placement),
                }}
            >
                <Paper {...paperProps}>
                {wrapInner(
                    <MenuList
                        autoFocusItem={autoFocusMenuItem && open}
                        id="popper-menu"
                        aria-labelledby={ariaLabelledBy}
                        onKeyDown={handleListKeyDown}
                    >
                        {children}
                    </MenuList>
                )}
                </Paper>
            </Grow>
        )}
    </Popper>
}

export default memo(PopperMenu);