import React, {useEffect, useState} from "react";
import {
    useCallbackPatchNode,
    useDiagnosticsOn,
    useNodeOrNull,
    usePendingStoreDbNodeCount,
    useSaveRunning
} from "../../hooks/nodeHooks";
import {useActiveTab, useOnline} from "../../hooks/onlineHooks";
import {getTime} from "tbf-react-library";
import {HOURS_24} from "../../util/constants";
import {NODE_IDS} from "../../reducers/graphReducer";
import {getJsonDate, isDateGreaterThan} from "../../util/util";
import {startClearOldData, stopClearOldData} from "../../actions/offline";

let hasCleared = false
let clearRunning = false
const GraphClearCache = () => {

    const [timeoutValue, setTimeoutValue] = useState(null)

    const online = useOnline()
    const active = useActiveTab()
    const pendingStoreCount = usePendingStoreDbNodeCount()
    const saveRunning = useSaveRunning()
    const diagnosticsOn = useDiagnosticsOn()
    const patchNode = useCallbackPatchNode()
    const userDevice = useNodeOrNull(NODE_IDS.UserDevice)
    const lastCleared = userDevice.lastIndexeddbClearedDateTime

    const clearAllowed = online && pendingStoreCount === 0 && !hasCleared && !saveRunning && active
    /**
     * Clear when active tab + 10 seconds after last indexeddb write + once per day + internet available
     */
    useEffect(() => {
        const minLastCleared = new Date(getTime() - HOURS_24)
        const timeToClear = lastCleared == null || isDateGreaterThan(minLastCleared, lastCleared)
        if (clearAllowed && !timeoutValue && timeToClear) {
            const ts = setTimeout(() => {
                const since = getTime() - userDevice.indexeddbClearedAge
                startClearOldData(since, diagnosticsOn).then(e => {
                    clearRunning = false
                    patchNode({id: NODE_IDS.UserDevice, lastIndexeddbClearedDateTime: getJsonDate()})
                })
                clearRunning = true
                hasCleared = true
                clearTimeout(timeoutValue)
            }, 15000)
            setTimeoutValue(ts)
        } else {
            if (!clearAllowed && timeoutValue) {
                clearTimeout(timeoutValue)
                setTimeoutValue(null)
            }
            if (clearRunning) {
                clearRunning = false
                stopClearOldData(diagnosticsOn)
            }
        }
    }, [online, timeoutValue, diagnosticsOn, lastCleared, patchNode, clearAllowed])

    /**
     * If clear started in tab that is then closed, lets also cancel clear each time a tab activates.
     */
    useEffect(() => {
        if (active) {
            stopClearOldData(diagnosticsOn)
        }
    }, [active])
    return null
}
export default GraphClearCache
