import React from "react";
import withStyles from '@mui/styles/withStyles';
import { strings } from "../components/SopLocalizedStrings";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton"
import { useCallbackPatchNode, useNodeOrNull } from "../../hooks/nodeHooks";
import { useCallbackSearchThisArea } from "./ExecutionViewHooks";
import SearchIcon from "@mui/icons-material/SearchRounded";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const CollectionMapSearchThisAreaButton = ({ selectorId, classes, mapRef }) => {
    const selector = useNodeOrNull(selectorId);
    const patch = useCallbackPatchNode()
    const isSearchAreaEnabled = !!selector.boundingBox;
    const isLoading = selector?.isLoading
    const handleSearchBounds = useCallbackSearchThisArea(selectorId, mapRef)
    const handlerClearMap = (e) => {
        e.stopPropagation();
        patch({ id: selectorId, boundingBox: undefined })
    }

    const buttonTitle = React.useMemo(() => {
        if (isLoading) {
            return strings.buttons.searching;
        }

        if (isSearchAreaEnabled) {
            return strings.buttons.updateArea;
        }

        return strings.buttons.searchThisArea;

    }, [isLoading, isSearchAreaEnabled])

    if (!selector?.mapAvailable || !selector?.mapSearchAvailable) {
        return null;
    }

    return (
        <Button
            className={classes.searchAreaButton}
            title={buttonTitle}
            data-cy="search-area"
            onClick={handleSearchBounds}
            disabled={isLoading}
            sx={{ backgroundColor: !isSearchAreaEnabled ? "white" : undefined }}
            variant={isSearchAreaEnabled ? "contained" : "outlined"}
            color='primary'
            startIcon={<SearchIcon />}
        >
            {buttonTitle}
            {isSearchAreaEnabled && !isLoading &&
                <IconButton data-cy="reset-search" title={buttonTitle} onClick={handlerClearMap} className={classes.clearButton} size="small" aria-label="reset-search">
                    <HighlightOffOutlinedIcon sx={{ color: "white" }} fontSize="small" />
                </IconButton>
            }
        </Button>
    )
}
const styles = () => ({
    searchAreaButton: {
        position: 'absolute',
        top: 10, // Matches the buttons in the map
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000
    },
    clearButton: {
        marginLeft: 5,
    }
});
export default withStyles(styles)(CollectionMapSearchThisAreaButton);