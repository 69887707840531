import React from "react";
import {strings} from "../../components/SopLocalizedStrings";
import {Panel} from "tbf-react-library";
import {useCallbackCreateOrRestoreRule} from "../../../hooks/procedureHooks";
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import TbfToggle from "../../components/common/TbfToggle";
import {useRuleForNodeByActionOrNull} from "../../../hooks/nodeHooks";
import ProcedureSecurityRules from "./ProcedureSecurityRules";
import {Grid} from "@mui/material";
import ProcedureSecurityAddAction from "./ProcedureSecurityAdd";
import {SecurityChildRules} from "../../../factory/procedureFactory";

const pageStrings = strings.procedure.settings.security;
const ProcedureSecurityPanel = ({procedureId, disabled}) => {
    const toggleOn = useCallbackCreateOrRestoreRule(procedureId, RULE_ACTION_TYPE.security.id, SecurityChildRules())
    const rule = useRuleForNodeByActionOrNull(procedureId, RULE_ACTION_TYPE.security.id)
    const isOn = rule && !rule?.deleted
    return <>
        <Panel title={pageStrings.tabTitle}>
            <Grid container>
                <Grid item xs={12}>
                    <p>{pageStrings.about1}</p>
                    <p>{pageStrings.about2}</p>
                </Grid>
                <Grid item xs={8}>
                    <TbfToggle
                        id={'customiseSecurity'}
                        label={pageStrings.toggleLabel}
                        checked={isOn}
                        disabled={disabled}
                        onChange={toggleOn}
                    />
                </Grid>
                <Grid item xs={4} container justifyContent={"flex-end"}>
                    {
                        isOn &&
                        <ProcedureSecurityAddAction parentRuleId={rule.id} disabled={disabled}/>
                    }
                </Grid>
            </Grid>
        </Panel>
        {
            isOn &&
            <ProcedureSecurityRules procedureId={procedureId} disabled={disabled}/>
        }
    </>;
}
export default ProcedureSecurityPanel;
