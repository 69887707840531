import React from "react";
import withStyles from '@mui/styles/withStyles';
import ProcedureCollectionColumnSelector from "./ProcedureCollectionColumnSelector";
import NodeTextField from "../../graph/NodeTextField";
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import GraphDelete from "../../graph/GraphDelete";
import {strings} from "../../components/SopLocalizedStrings";

const ProcedureRuleCollectionOrderBy = ({disabled, ruleId, multipleOrderBy}) => {
    const ruleActionType = RULE_ACTION_TYPE.collectionOrder.id;
    return (
        <tr>
            <td>
                <ProcedureCollectionColumnSelector
                    disabled={disabled}
                    ruleId={ruleId}
                    rulePropertyName={'calculateValue'}
                    ruleActionType={ruleActionType}
                />
            </td>
            <td>
                <NodeTextField
                    nodeId={ruleId}
                    nodePropertyName={'orderByDirection'}
                    displayLabel={false}
                    disabled={disabled}
                />
            </td>
            {
                multipleOrderBy &&
                <td>
                    <GraphDelete
                        nodeId={ruleId}
                        iconButton={true}
                        friendlyName={strings.procedure.rule.column}
                        confirmDelete={false}
                        disabled={disabled}
                    />
                </td>
            }
        </tr>
    );
}
const styles = () => ({});
export default withStyles(styles)(ProcedureRuleCollectionOrderBy);