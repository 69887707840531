import React, {Component} from 'react';
import draftToHtml from "draftjs-to-html";
import withStyles from '@mui/styles/withStyles';

export const customImageEntityTransform = (entity) => {
    const isImage = entity.type.indexOf('image') !== -1;
    if (!isImage) return;
    return `<img alt="rich text image" src="${entity.data.src}"/>`;
};

export const customEntityTransform = (entity, text) => {
    const isMention = entity.type.indexOf('mention') !== -1;
    const isImage = entity.type.indexOf('image') !== -1;
    if (entity.type !== 'LINK' && !isMention && !isImage) return;
    if (isImage) {
        const alignment = entity.data.alignment;
        const width = entity.data.width;
        if (alignment && alignment !== 'default') {
            return `<div style="text-align: ${alignment}"><img alt="rich text image" style="width: ${width ? width + '%' : 'auto'};max-width: 100%;" src="${entity.data.src}"/></div>`;
        }
        return `<img alt="rich text image" style="width: ${width ? width + '%' : 'auto'};max-width: 100%;" src="${entity.data.src}"/>`;
    }
    if (isMention) {
        return `<a href="${entity.data.mention.link}" target="_blank" class="underlineHover">${text}</a>`;
    }
    return `<a href="${entity.data.url}" style="font-size: inherit" target="_blank" class="underlineHover">${text}</a>`;
};

class RenderDraftJs extends Component {

    render() {
        const {contentState, classes} = this.props;
        let deserialised = contentState;
        if (typeof deserialised === 'string') {
            try {
                deserialised = JSON.parse(deserialised)
            } catch (error) {
                return <span>Error converting string to json.<pre>{contentState}</pre></span>
            }
        }
        let contentHtml = draftToHtml(deserialised, {}, false, customEntityTransform);
        return <span className={classes.renderContent} dangerouslySetInnerHTML={{__html: contentHtml}}/>
    }
}

RenderDraftJs.propTypes = {};

const styles = theme => ({
    renderContent: {
        display: 'block',
        width: '100%',
        '& blockquote': {
            background: theme.palette.grey.one.main,
            borderLeft: `10px solid ${theme.palette.grey.three.main}`,
            color: theme.palette.grey.seven.main,
            padding: '0.3em 10px',
        },
    },
});

export default withStyles(styles)(RenderDraftJs);
