import {
    SET_FILE_DELETE_COMPLETED,
    SET_FILE_DELETE_SNACKBAR_TOGGLE_STATE,
    SET_FILE_DELETE_UNDO,
    SET_MENU_TOGGLE_STATE
} from './types';

export const setMenuToggleState = (toggleState) => {
    return {
        type: SET_MENU_TOGGLE_STATE,
        toggleState: toggleState
    }
};

export const setFileDeleteSnackbarToggleState = (toggleState, fileLength = 0) => {
    return {
        type: SET_FILE_DELETE_SNACKBAR_TOGGLE_STATE,
        toggleState: toggleState,
        fileLength: fileLength
    }
};

export const setFileDeleteSnackbarUndo = (toggleState) => {
    return {
        type: SET_FILE_DELETE_UNDO,
        toggleState: toggleState
    }
};

export const setFileDeleteCompleted = (toggleState) => {
    return {
        type: SET_FILE_DELETE_COMPLETED,
        toggleState: toggleState
    }
};