import React from "react";
import withStyles from '@mui/styles/withStyles';
import ProcedureCollectionColumnSelector from "./ProcedureCollectionColumnSelector";
import NodeTextField from "../../graph/NodeTextField";
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import GraphDelete from "../../graph/GraphDelete";
import {strings} from "../../components/SopLocalizedStrings";
import {useChildRulesByAction} from "../../../hooks/nodeHooks";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from "@mui/material/IconButton";
import {Stack} from "@mui/material";

const ProcedureRuleCollectionColumn = ({disabled, ruleId, ruleActionType, showDeleted, classes, onMove, prevNode, nextNode, index, isDialog}) => {
    const columnSources = useChildRulesByAction(ruleId, RULE_ACTION_TYPE.collectionColumnSource.id).filter(a => showDeleted || !a.deleted)

    const moveUp = () => {
        onMove({sourceId: ruleId, destinationId: prevNode?.id});
    }

    const moveDown = () => {
        onMove({sourceId: ruleId, destinationId: nextNode?.id});
    }

    return (
        <tr className={classes.row}>
            {!disabled &&
                <th>
                    <Stack direction={"row"}>
                        <IconButton data-cy={`column-move-up_${index}`} disabled={!prevNode} onClick={moveUp} ><ArrowUpwardIcon fontSize={"small"} /></IconButton>
                        <IconButton data-cy={`column-move-down_${index}`} disabled={!nextNode} onClick={moveDown} ><ArrowDownwardIcon fontSize={"small"}/></IconButton>
                    </Stack>
                </th>
            }

            {
                columnSources.map(s => (
                    <td key={s.id}>
                        <ProcedureCollectionColumnSelector
                            disabled={disabled}
                            ruleId={s.id}
                            rulePropertyName={'calculateValue'}
                            ruleActionType={ruleActionType}
                            isDialog={isDialog}
                        />
                    </td>))
            }
            {
                ruleActionType === RULE_ACTION_TYPE.collectionOrder.id &&
                <td>
                    <NodeTextField
                        nodeId={ruleId}
                        nodePropertyName={'orderByDirection'}
                        displayLabel={false}
                        disabled={disabled}
                    />
                </td>
            }
            {
                <td>
                    <NodeTextField
                        nodeId={ruleId}
                        nodePropertyName={'message'}
                        label={'Name'}
                        displayLabel={false}
                        disabled={disabled}
                    />
                </td>
            }
            <td>
                {!disabled && <GraphDelete
                    nodeId={ruleId}
                    iconButton={true}
                    friendlyName={strings.procedure.rule.column}
                    confirmDelete={false}
                    disabled={disabled}
                />}
            </td>
        </tr>
    );
}
const styles = () => ({
    row: {
        '& td': {
            minWidth: '300px'
        }
    }
});
export default withStyles(styles)(ProcedureRuleCollectionColumn);