import React, { useMemo } from "react";
import { useNodeOrNull, useNodesIfPresent } from "../../../hooks/nodeHooks";
import { strings } from "../../components/SopLocalizedStrings";
import withStyles from '@mui/styles/withStyles';
import ExecutionJsonLogic from "./ExecutionJsonLogic";

const RuleCopiedToNodesList = ({ruleId, classes}) => {
    const rule = useNodeOrNull(ruleId);
    const copyToNodes = useNodesIfPresent(rule.copyToNodeIds);
    const copyToNodesById = useMemo(() => {
        return copyToNodes.reduce((map, node) => ({...map, [node.id]: node}), {});
    }, [copyToNodes]);

    if (!rule || !rule.copyToNodeIds?.length) {
        return null;
    }

    return <div>
        {rule.copyToNodeIds?.map((nodeId) => {
            return <p key={nodeId}>- {strings.execution.show.copy.label} <ExecutionJsonLogic jsonLogic={rule.calculateValueQuery}>
                    {copyToNodesById[nodeId]?.name ?? strings.execution.show.copy.notLoaded}
                </ExecutionJsonLogic>
            </p>
        })}
    </div>
}

const styles = () => ({});

export default withStyles(styles)(RuleCopiedToNodesList);