import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {clearGraph} from "../../actions";
import {connect} from "react-redux";
import JSONPretty from "react-json-pretty";
import {getNodeOrError} from "../../selectors/graphSelectors";

class GraphNodeJson extends Component {

    render() {
        let {node} = this.props;
        return (
            <div>
                <JSONPretty id="json-pretty" node={node}/>
            </div>
        )
    }
}

const styles = () => ({});
GraphNodeJson.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let node = getNodeOrError(state, ownProps.nodeId)
    return {
        node: node
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onClearGraph: () => dispatch(clearGraph())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphNodeJson));
