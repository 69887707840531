import {latLngBounds} from 'leaflet'
import reverse from "lodash/reverse";

export const latLngBoundsToNode = (bounds) => {
  return {
    northWest: {
      lat: bounds.getNorthWest().lat,
      lng: bounds.getNorthWest().lng
    },
    southEast: {
      lat: bounds.getSouthEast().lat,
      lng: bounds.getSouthEast().lng
    }
  }
}

export const nodeToLatLngBounds = (data) => {
  const bounds = new latLngBounds()
  bounds.extend([data.northWest.lat, data.northWest.lng])
  bounds.extend([data.southEast.lat, data.southEast.lng])
  return bounds
}
/**
 * Get the bounds for a given marker.
 *
 * If single point, the point is returned.
 *
 * If multiple points, a box is drawn around the points with some padding.
 * @param features
 * @param pad
 * @returns {{point: *}|null|{bounds: LatLngBounds}}
 */
export const creatBoundsFromMarkers = (features, pad = 0.1) => {
  const points = getPositions(features)
  if (!features || points.length === 0) {
    return null
  }
  if (points.length === 1) {
    return {point: points[0]};
  }
  const bounds = latLngBounds()
  points.forEach((p) => {
    p && bounds.extend(p)
  })
  const paddedBounds = bounds.pad(pad)
  const boundsArePoint = !paddedBounds.isValid() || paddedBounds.getNorth() === paddedBounds.getSouth() || paddedBounds.getEast() === paddedBounds.getWest();
  if (boundsArePoint) {
    return {point: getPosition(points[0])};
  }
  return {bounds: paddedBounds};
}


/**
 * Get the position in leaflet syntax from geojson feature
 * @param feature
 * @returns {null|*}
 */
export const getPosition = (feature) => {
  const coords = feature?.geometry?.coordinates
  if (!coords || coords.length < 2) {
    return null
  }
  let position = reverse([...coords.slice(0, 2)])
  return position
}

export const getPositions = (features) => {
  const positions = []
  for (let feature of features || []) {
    if (Array.isArray(feature.features)) {
      let innerPositions = getPositions(feature.features)
      for (let pos of innerPositions) {
        positions.push(pos)
      }
    }
    const coords = feature?.geometry?.coordinates
    const points = geoJsonCoordinatesToPoints(coords)
    for (let point of points) {
      positions.push(point)
    }
  }
  return positions
}
export const geoJsonCoordinatesToPoints = coords => {
  const points = []
  const process = [coords]
  while (process.length > 0) {
    const next = process.pop()
    if (!Array.isArray(next) || next.length === 0) {
      continue
    }
    if (Array.isArray(next[0])) {
      for (let item of next) {
        process.push(item)
      }
    } else if (areGeoJsonCoordinatesValid(next)) {
      points.push(reverse([...next.slice(0, 2)]))
    }
  }
  return points
}
export const areGeoJsonCoordinatesValid = (point) => {
  if (point === null || !Array.isArray(point) || point.length < 2) {
    return false
  }
  if (point[0] > 180 && point[0] < -180) {
    return false
  }
  if (point[1] > 90 && point[1] < -90) {
    return false
  }
  return true
}