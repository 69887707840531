import React from 'react';
import withStyles from '@mui/styles/withStyles';
import '../../../style/alert.css';
import * as PropTypes from "prop-types";
import VerticalSplitIcon from '@mui/icons-material/VerticalSplitRounded';
import {strings} from "../../components/SopLocalizedStrings";
import Hidden from "@mui/material/Hidden";
import ImageSearchIcon from '@mui/icons-material/ImageSearchRounded';
import {TbfAction} from "tbf-react-library";
import {useCallbackPatchNode, useNodePropertyOrNull} from "../../../hooks/nodeHooks";

const pageStrings = strings.procedure.show;

const ProcedurePreviewButton = React.forwardRef(({procedureId}, ref) => {
    const sideBySidePreviewOn = useNodePropertyOrNull(procedureId, a => a.sideBySidePreviewOn)
    const patch = useCallbackPatchNode({id: procedureId, sideBySidePreviewOn: !sideBySidePreviewOn});
    return (
        <React.Fragment>
            <Hidden lgUp={true}>
                <TbfAction
                    path={'/procedures/' + procedureId + '/preview'}
                    dataCy='preview-procedure'
                    title={pageStrings.previewButtonTooltip}
                    iconComponent={ImageSearchIcon}
                    ref={ref}
                >
                    {strings.labels.preview}
                </TbfAction>
            </Hidden>
            <Hidden mdDown={true}>
                <TbfAction
                    onClick={patch}
                    dataCy='live-preview-procedure'
                    title={pageStrings.previewSideBySideButtonTooltip}
                    iconComponent={VerticalSplitIcon}
                    ref={ref}
                >
                    {strings.labels.preview}
                </TbfAction>
            </Hidden>
        </React.Fragment>
    );
})
const styles = () => ({});
ProcedurePreviewButton.propTypes = {
    procedureId: PropTypes.string.isRequired
};
export default withStyles(styles)(ProcedurePreviewButton);
