import React from "react";
import {useNodeOrError, useRuleForNodeByActionOrNull} from "../../../hooks/nodeHooks";
import {PROCEDURE_TYPES, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import {Panel} from "tbf-react-library";
import {strings} from "../../components/SopLocalizedStrings";
import TbfToggle from "../../components/common/TbfToggle";
import {useCallbackCreateOrRestoreRule} from "../../../hooks/procedureHooks";
import NodeTextField from "../../graph/NodeTextField";
import Grid from "@mui/material/Grid";
import ProcedureCompileMessages from "../ProcedureCompileMessages";
import ProcedureCalculateEditor from "./ProcedureCalculateEditor";

const pageStrings = strings.procedure.settings.customKey
const formatOptions = [
    {value: '0', label: 'None'},
    {value: '00', label: '2 digits e.g. 04'},
    {value: '000', label: '3 digits e.g. 004'},
    {value: '0000', label: '4 digits e.g. 0004'},
    {value: '00000', label: '5 digits e.g. 00004'},
    {value: '000000', label: '6 digits e.g. 000004'},
    {value: '0000000', label: '7 digits e.g. 0000004'},
    {value: '00000000', label: '8 digits e.g. 00000004'},
    {value: 'none', label: 'No sequence'},
]
const ProcedureRuleCustomKey = ({disabled, procedureId}) => {
    const rule = useRuleForNodeByActionOrNull(procedureId, RULE_ACTION_TYPE.customKey.id)
    const procedure = useNodeOrError(procedureId)
    const procedureType = PROCEDURE_TYPES[procedure.procedureType]
    const toggleOn = useCallbackCreateOrRestoreRule(procedureId, RULE_ACTION_TYPE.customKey.id)
    const isOn = rule && !rule?.deleted
    return (
        <Panel title={pageStrings.title}>
            <p>{strings.formatString(pageStrings.about, procedureType.keyPrefix)}</p>
            <TbfToggle
                id={'customiseKey'}
                label={pageStrings.toggleLabel}
                checked={isOn}
                disabled={disabled}
                onChange={toggleOn}
            />
            {
                isOn && rule && rule.id &&
                <Grid container>
                    <Grid item md={6} xs={12}>
                        <ProcedureCalculateEditor
                            ruleId={rule.id}
                            disabled={disabled}
                            label={pageStrings.prefixLabel}
                        />
                        <NodeTextField
                            nodeId={rule.id}
                            nodePropertyName={'format'}
                            disabled={disabled}
                            label={pageStrings.formatLabel}
                            nodeValueOptions={formatOptions}
                        />
                        <ProcedureCompileMessages nodeId={rule.id}/>
                    </Grid>
                </Grid>
            }
        </Panel>
    );
}
export default ProcedureRuleCustomKey
