import React, { useMemo } from "react";
import {Link} from "react-router-dom";
import { strings } from "../components/SopLocalizedStrings";
import CustomDataGrid from "../components/common/CustomDataGrid";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import get from "lodash/get";
import TbfSopLink from "../components/common/TbfSopLink";
import { REPORT_EVENT_TYPES } from "../../util/constants";

const pageStrings = strings.project.list;

const columns = [
    {
        field: "name",
        title: pageStrings.projectNameColumn,
        headerName: pageStrings.projectNameColumn,
        renderCell: ({row}) => row.id ? <TbfSopLink
            to={`/projects/${row.id}`}
            nodeId={row.id}
            eventName={REPORT_EVENT_TYPES.projectPageItemClicked.name}
        >
            {row.name || '[Blank]'}
        </TbfSopLink> : null,
    },
    {
        field: "status",
        title: pageStrings.projectStatusColumn,
        headerName: pageStrings.projectStatusColumn,
    },
    {
        field: "projectStatistics.completedExecutionCount",
        title: pageStrings.executionCountColumn,
        headerName: pageStrings.executionCountColumn,
        renderCell: ({row}) => row.projectStatistics ? (row.projectStatistics.completedExecutionCount + ' / ' + row.projectStatistics.totalExecutionCount) : '',
    },
    {
        field: "projectStatistics.completedRatio",
        title: pageStrings.projectCompletedRatioColumn,
        headerName: pageStrings.projectCompletedRatioColumn,
        renderCell: ({row}) => row.projectStatistics ? (Math.round(row.projectStatistics.completedRatio * 100) + '%') : ''
    },
    {
        field: "projectStatistics.totalPhotoCount",
        title: strings.photo.namePlural,
        headerName: strings.photo.namePlural,
    }
]

const useStyle = makeStyles((theme) => ({
    container: {
        height: 'calc(100vh - 172px)',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 160px)' // TODO: Make this smarter, how do we calculate the full height
        },
    }
}))

export default function ProjectGrid({projects, filter, allowToggleDeleted, onToggleDeleted, onFilterChanged}) {
    const classes = useStyle();

    const filterChangeHandler = (filter) => () => onFilterChanged?.(filter);

    const projectRows = useMemo(() => {
        return projects.map((p) => ({
            id: p.id,
            projectStatistics: p.projectStatistics,
            deleted: p.deleted,
            ...columns.reduce((map, c) => ({
                ...map,
                [c.field]: get(p, c.field),
            }), {})
        }));
    }, [projects]);

    return <div className={classes.container}>
        <CustomDataGrid rows={projectRows} columns={columns} allowToggleDeleted={allowToggleDeleted} onToggleDeleted={onToggleDeleted} additionalFilters={
            <ButtonGroup color="primary" className={classes.filter}>
                <Button
                    variant={filter === 'mine' ? 'contained' : 'outlined'}
                    color={'primary'}
                    onClick={filterChangeHandler('mine')}
                    aria-label={strings.buttons.showMyProjects}
                    title={strings.buttons.showMyProjects}
                    data-cy='my-projects'>
                    {strings.buttons.mine}
                </Button>
                <Button
                    variant={filter === 'all' ? 'contained' : 'outlined'}
                    color={'primary'}
                    onClick={filterChangeHandler('all')}
                    aria-label={strings.buttons.showAllProjects}
                    title={strings.buttons.showAllProjects}
                    data-cy='all-projects'>
                    {strings.buttons.all}
                </Button>
            </ButtonGroup>
        } />
    </div>;
}