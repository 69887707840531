import React, {forwardRef, useCallback, useEffect, useRef, useState} from 'react'
import { CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {fabric} from 'fabric'
import get from 'lodash/get'
import {useNodeOrNull} from '../../hooks/nodeHooks'
import useResizeObserver from '../../hooks/useResizeObserver'
import useDebounceCallback from '../../hooks/useDebounceCallback'
import cn from 'classnames'
import first from 'lodash/first'
import forEach from 'lodash/forEach'
import SopApiImage from '../components/SopApiImage'
import cloneDeep from "lodash/cloneDeep";
import { strings } from '../components/SopLocalizedStrings';
import WifiOff from '@mui/icons-material/WifiOff';
import FileDownloadOff from '@mui/icons-material/FileDownloadOff';
import { isOnline } from '../../util/util';
import { useMobile } from 'tbf-react-library';

const ImageWithMarkup = forwardRef(({ classes, photoId, onLoaded, photoType = 'original', editable, icon, iconLabel, unmountPhoto }, ref) => {
  const containerRef = useRef()
  const canvasRef = useRef()
  const fabricCanvasRef = useRef({
    scale: 0,
    canvas: null,
    width: null,
    height: null,
    convertEventToViewportPoint: (e) => {
      const { canvas, scale } = fabricCanvasRef.current
      const boundingRec = canvas.upperCanvasEl.getBoundingClientRect();

      if(e.type === 'touchend') {
        const changedTouch = first(e.changedTouches)
        if (changedTouch) {
          return {
            x: (changedTouch.clientX - boundingRec.left) / scale,
            y: (changedTouch.clientY - boundingRec.top) / scale
          }
        }
      }
      else {
        return {
          x: (e.x - boundingRec.left) / scale,
          y: (e.y - boundingRec.top) / scale
        }
      }
    }
  })

  const [ready, setReady] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false);
  const photo = useNodeOrNull(photoId)
  const markup = get(photo, ['markup'])
  const json = get(markup, ['json'])
  // pdf + docx originalWidth is 0
  const originalWidth = get(photo, ['originalWidth']) || get(photo, ['largeWidth']) || 300
  const originalHeight = get(photo, ['originalHeight']) || get(photo, ['largeHeight']) || 300

  const imageUrl = get(photo, [`${photoType}InMemoryUrl`])

  const mobileViewPort = useMobile();

  const updateCanvasSize = useCallback(() => {
    const {canvas, width: imageWidth, height: imageHeight} = fabricCanvasRef.current
    if (containerRef.current && canvas && canvas.wrapperEl && containerRef.current.clientWidth) {
      const imageAspect = imageWidth / imageHeight
      canvas.setWidth(icon ? imageWidth : containerRef.current.clientWidth)
      canvas.setHeight(icon ? imageHeight : containerRef.current.clientHeight)
      const canvasAspect = canvas.width / canvas.height

      if (imageAspect < canvasAspect) {
        const scale = canvas.height / imageHeight
        canvas.setWidth(imageWidth * scale)
        if (!icon) {
          canvas.setZoom(scale)
        } else {
          canvas.backgroundImage?.center();
        }
        fabricCanvasRef.current.scale = scale
      } else {
        const scale = canvas.width / imageWidth
        canvas.setHeight(imageHeight * scale)
        if (!icon) {
          canvas.setZoom(scale)
        } else {
          canvas.backgroundImage?.center();
        }
        fabricCanvasRef.current.scale = scale
      }
    }
  }, [])

  useEffect(() => {
    return () => {
      fabricCanvasRef.current.canvas?.dispose()
      fabricCanvasRef.current.canvas = null /* eslint-disable-line react-hooks/exhaustive-deps */
    }
  }, [])

  const loadMarkup = useCallback((canvas, json, backgroundImage) => {
    if(json && canvas.wrapperEl) {
      const data = canvas.toJSON()
      // To avoid mutating state that is in redux, we need to clone.
      let useJson = cloneDeep(json)
      // When it loads the markup it replaces the background image, this just puts it back in
      useJson.backgroundImage = data.backgroundImage
      canvas.loadFromJSON(useJson, () => {
        canvas.setBackgroundImage(backgroundImage)
        forEach(canvas.getObjects(), obj => {
          obj.editable = true
        })
      })
    }
  }, [])

  // Load the image
  useEffect(() => {
    if (canvasRef.current && imageUrl) {
      const width = containerRef.current.clientWidth
      const height = containerRef.current.clientHeight
      const canvas = fabricCanvasRef.current.canvas || new fabric.Canvas(canvasRef.current,
          {
            hoverCursor: 'pointer',
            backgroundColor: "transparent",
            height: height,
            width: width
          })

      const onImageLoaded = (img) => {
        // Always use the original width and height for the canvas
        fabricCanvasRef.current.width = originalWidth
        fabricCanvasRef.current.height = originalHeight
        fabricCanvasRef.current.canvas = canvas
        canvas.setBackgroundImage(img, () => {}, {
          // We then scale the background image to match the original image so that it fits inside the canvas
          scaleX: fabricCanvasRef.current.width / img.width,
          scaleY: fabricCanvasRef.current.height / img.height
        })
        updateCanvasSize()
        loadMarkup(canvas, json, img)
        if(onLoaded) onLoaded(fabricCanvasRef)
        setReady(true)
      }
      fabric.Image.fromURL(imageUrl, onImageLoaded, { crossOrigin: 'Anonymous' })
    }
  }, [onLoaded, imageUrl, updateCanvasSize, loadMarkup, json,originalWidth , originalHeight])

  useResizeObserver(containerRef, useDebounceCallback(() => {
    updateCanvasSize()
  }, 200, []))

  const onError = () => {
    setHasError(true);
  }

  const imageUnavailable = !imageUrl && !loading && hasError;

  const imageUnavailableMarkup = <div className={cn(classes.imageUnavailable, {
    [classes.imageUnavailableLarge]: photoType === 'large',
    [classes.columnDirection]: mobileViewPort,
  })}
    data-cy-element="ImageUnavailable"
    data-cy="image-unavailable"
    aria-label="Image unavailable"
  >
    {
      !isOnline() ?
        photoType === 'large' ?
        <>
          <WifiOff fontSize='large' color='white'  />
          {strings.imageEditor.unavailableOfflineLarge}
        </> :
          strings.imageEditor.unavailableOffline
      :
        photoType === 'large' ?
        <>
          <FileDownloadOff fontSize='large' color='white'  />
          {strings.imageEditor.downloadErrorLarge}
        </> :
          strings.imageEditor.downloadError
    }
  </div>

  return (
      <div className={cn(classes.container, classes.available, {editable,
        ['loading']: !ready,
        [classes.unavailableSmall]: imageUnavailable && photoType !== 'large',
      })} ref={containerRef}>
        {imageUnavailable && imageUnavailableMarkup}
        <SopApiImage onLoading={setLoading} onError={onError} renderImage={false} attachmentId={photoId} attachmentType={photoType} data-cy={`image-${!imageUnavailable ? 'displayed' : `not-displayed`}`} unmountPhoto={unmountPhoto} />
        {/* DO NOT CHANGE ANY PROPERTIES ON THE CANVAS, fabric has control of this and will create new elements instead of this one */}
        <canvas ref={canvasRef} id={`canvas_${photoType}_${photoId}`} data-canvas={`canvas_${photoType}_${photoId}`}/>
        {iconLabel}
        {loading && !ready && <div className={cn(classes.progress, {loading})}><CircularProgress data-cy={'Loader'} /></div>}
      </div>
  )
})

const styles = theme => ({
  container: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    flexGrow: 1,
    overflow: 'hidden',
    pointerEvents: 'none',
    '&.editable': {
      pointerEvents: 'all',
    },
    '&.loading canvas': {
      opacity: 0
    },
    '& canvas': {
      transition: 'opacity 0.5s ease-in-out',
      opacity: 1
    }
  },
  unavailableSmall: {
    backgroundColor: theme.palette.grey.two.main,
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.25s ease-in-out',
    '&.loading': {
      opacity: 1
    }
  },
  imageUnavailable: {
    position: 'absolute',
    inset: 0,
    color: theme.palette.grey.four.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 'normal',
    columnGap: '16px',
  },
  imageUnavailableLarge: {
    fontSize: '24px',
    color: 'white',
  },
  columnDirection: {
    flexDirection: 'column',
  }
})

export default withStyles(styles)(ImageWithMarkup)
