import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../../../style/alert.css';
import FormHelperText from "@mui/material/FormHelperText";
import {ComponentBase} from "tbf-react-library";
import {MESSAGE_TYPES} from "../../../../reducers/graphReducer";
import MessageType from "../../../components/MessageType";

class ExecutionQuestionRuleMessage extends ComponentBase {

    render() {
        let {classes, messageType, message, ruleId} = this.props;
        if (message == null) {
            return null;
        }
        let cssMap = {
            [MESSAGE_TYPES.success.id]: classes.successMessage,
            [MESSAGE_TYPES.warning.id]: classes.warningMessage,
        };
        let cssClass = cssMap[messageType] || '';
        let isError = messageType === MESSAGE_TYPES.error.id;
        return (
            <FormHelperText key={ruleId} className={cssClass} error={isError}>
                <MessageType contentState={message} messageType={messageType}/>
            </FormHelperText>);
    }
}

const styles = () => ({
    successMessage: {
        color: '#155724',
    },
    warningMessage: {
        color: '#856404'
    },
});
ExecutionQuestionRuleMessage.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let rule = getNodeOrNull(state, ownProps.ruleId);
    return {
        messageType: rule && rule.messageType,
        message: rule && rule.message
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionRuleMessage));
