import {asyncDispatchMiddleware} from './asyncDispatchMiddleware'
import map from '../layouts/MapView/middleware/map'
import confirmationMiddleware from './confirmationMiddleware'
import userMiddleware from "./userMiddleware";
import onlineMiddleware from "./onlineMiddleware";
import {promisesMiddleware} from "./promisesMiddleware";

const middlewares = [
  asyncDispatchMiddleware,
  promisesMiddleware,
  map,
  confirmationMiddleware,
  userMiddleware,
  onlineMiddleware
]

export default middlewares