import '../../../../style/alert.css';
import Location from "../../../components/Location"
import {getJsonDate} from "../../../../util/util";
import 'date-fns';
import isEqual from "lodash/isEqual";
import {ComponentBase, getTime, reportDeveloperWarning, SharedAuth} from "tbf-react-library";

class ExecutionQuestionInputBase extends ComponentBase {

    static lastSlowReported = null;
    static slowCounter = 0;
    updateNodeProperty = (field, newValue, auditField, implicitBlur) => {
        let {disabled, value, questionId, executionQuestionNode} = this.props;
        let startTime = getTime();
        let cleanedValue = newValue === '' ? null : newValue;
        let currentValue = executionQuestionNode ? executionQuestionNode[field] : value;
        let hasNotChanged = isEqual(currentValue, cleanedValue);
        if (hasNotChanged) {
            return;
        }
        if (disabled) {
            reportDeveloperWarning(`ExecutionShowQuestion.updateNodeProperty called on disabled question [${questionId}] [${executionQuestionNode?.questionType}] on Execution [${executionQuestionNode?.rootId}] from component ${this.constructor.name}.`);
            return;
        }
        let updatedNode = {
            id: questionId,
            [field]: cleanedValue
        };
        if (auditField) {
            updatedNode[auditField + 'DateTime'] = getJsonDate();
            updatedNode[auditField + 'ByUser'] = SharedAuth.getAuditName();
            updatedNode[auditField + 'Coordinates'] = Location.getLocation();
        }

        let updatedNodes = [updatedNode];
        this.props.onPutNodesProperty(updatedNodes);

        let finishTime = getTime();
        let duration = finishTime - startTime;
        if (duration > 200) {
            ExecutionQuestionInputBase.slowCounter++;
            let lastReported = ExecutionQuestionInputBase.lastSlowReported || 0;
            // Lets log once per minute at most
            let reportNow = (startTime - lastReported) > 60000;
            if (reportNow) {
                reportDeveloperWarning(`Slow typing detected. Processing took ${duration}ms. Slow counter: ${ExecutionQuestionInputBase.slowCounter}`);
            }
        }
        ExecutionQuestionInputBase.lastSlowReported = finishTime;
        if (implicitBlur) {
            this.props.onBlurNodeProperty(questionId);
        }
    };

    renderInputStandardProperties = (value, valueUnknown, field, handleFocus, handleBlur) => {
        let {valueDisabled, questionId, questionName} = this.props;
        const id = field + '-' + questionId;
        return (
            {
                id: id,
                name: id,
                value: value == null ? '' : value,
                readOnly: valueUnknown || valueDisabled,
                'data-readonly': valueUnknown || valueDisabled,
                className: 'textFieldPrimary',
                inputProps: {
                    'data-cy': questionName + '_' + field
                },
                onFocus: () => {
                    handleFocus?.();
                    this.handleFocusElement();
                },
                onBlur: () => {
                    handleBlur?.();
                    this.handleBlurElement();
                }
            });
    };

    handleFocusElement = () => {
        let {questionId, onFocusNodeProperty} = this.props;
        onFocusNodeProperty(questionId, questionId);
    }

    handleBlurElement = () => {
        let {questionId, onBlurNodeProperty} = this.props;
        onBlurNodeProperty(questionId, questionId);
    }

}

export default ExecutionQuestionInputBase;
