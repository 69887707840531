import React from 'react';
import PageComponentBase from "../PageComponentBase";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import GraphDebug from "../graph/GraphDebug";
import {Breadcrumbs} from "tbf-react-library";

class GraphDashboard extends PageComponentBase {

    render() {
        return (
            <div>
                <Breadcrumbs list={[]}/>
                <Toolbar disableGutters={true}>
                    <Typography variant="h1" color="inherit" noWrap>
                        Dashboard
                    </Typography>
                </Toolbar>
                <GraphDebug/>
            </div>
        )
    }
}

export default GraphDashboard;
