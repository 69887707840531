import {createSelector} from 'reselect'
import get from 'lodash/get'
import {getClientConfig} from "../../../selectors/graphSelectors";

const DEFAULT_CONFIG = {
  enabled: true,
  tools: [
    {
      type: 'Rect',
      name: 'Rectangle',
      color: '#F00',
      options: {
        width: 80,
        height: 80,
        strokeWidth: 10,
        stroke: '#F00'
      }
    }, {
      type: 'Line',
      name: 'Line',
      color: '#F00',
      options: {
        strokeWidth: 10,
        stroke: '#F00'
      }
    }, {
      type: 'Circle',
      name: 'Circle',
      color: '#F00',
      options: {
        strokeWidth: 10,
        stroke: '#F00'
      }
    },
    {
      type: 'IText',
      name: 'Text',
      color: '#F00',
      options: {
        fontSize: 40,
        fill: '#F00',
        originX: 'left',
        textAlign: 'center'
      }
    },
    {
      type: 'Arrow',
      name: 'Arrow',
      color: '#F00',
      options: {
        lineWidth: 10,
        arrowHeadSize: 50,
        strokeWidth: 0,
        stroke: '#F00',
        fill: '#F00'
      }
    },
    {
      type: 'Pen',
      name: 'Pen',
      color: '#F00',
      options: {
        width: 10,
        color: '#F00'
      }
    },
    {
      type: 'Pen',
      name: 'Highlight',
      color: '#dae203',
      options: {
        width: 25,
        color: 'rgba(255, 255, 0, 0.4)',
        strokeLineCap: 'square',
        opacity: 0.2
      }
    },
    // }, {
    //   type: 'Triangle',
    //   name: 'Triangle Tool',
    //   options: {
    //     strokeWidth: 1,
    //     stroke: '#0FF',
    //     fill: '#F0F',
    //     width: 50,
    //     height: 50,
    //     opacity: 0.3
    //   }
    // },
    // {
    //   type: 'Textbox',
    //   name: 'Text Tool',
    //   options: {
    //     fontSize: 40,
    //     fill: '#FFF',
    //     backgroundColor: '#000',
    //     originX: 'left',
    //     textAlign: 'center'
    //   }
    // },
    // {
    //   type: 'Svg',
    //   name: 'Grouped SVG',
    //   options: {
    //     grouped: true,
    //     svg: `<svg version="1.1" baseProfile="tiny"
    //               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
    //               x="0px" y="0px" width="151px" height="151px" viewBox="-0.32 -0.44 151 151" xml:space="preserve">
    //           <defs>
    //           </defs>
    //           <path fill="#5E0000" d="M121.32,0L44.58,0C36.67,0,29.5,3.22,24.31,8.41c-5.19,5.19-8.41,12.37-8.41,20.28
    //             c0,15.82,12.87,28.69,28.69,28.69c0,0,4.4,0,7.48,0C36.66,72.78,8.4,101.04,8.4,101.04C2.98,106.45,0,113.66,0,121.32
    //             c0,7.66,2.98,14.87,8.4,20.29l0,0c5.42,5.42,12.62,8.4,20.28,8.4c7.66,0,14.87-2.98,20.29-8.4c0,0,28.26-28.25,43.66-43.66
    //             c0,3.08,0,7.48,0,7.48c0,15.82,12.87,28.69,28.69,28.69c7.66,0,14.87-2.99,20.29-8.4c5.42-5.42,8.4-12.62,8.4-20.28l0-76.74
    //             c0-7.66-2.98-14.87-8.4-20.29C136.19,2.98,128.98,0,121.32,0z"/>
    //           <path fill="#961B1E" d="M142.42,105.43V28.69c0-11.65-9.45-21.1-21.1-21.1H44.58c-11.65,0-21.1,9.45-21.1,21.1s9.45,21.1,21.1,21.1
    //             h25.79L13.77,106.4c-8.24,8.24-8.24,21.6,0,29.84c8.24,8.24,21.6,8.24,29.84,0l56.61-56.61v25.79c0,11.65,9.45,21.1,21.1,21.1
    //             C132.97,126.53,142.42,117.08,142.42,105.43z"/>
    //           </svg>
    //           `
    //   }
    // },
    // {
    //   type: 'Svg',
    //   name: 'SVG',
    //   options: {
    //     grouped: false,
    //     svg: `<svg version="1.1" baseProfile="tiny"
    //               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
    //               x="0px" y="0px" width="151px" height="151px" viewBox="-0.32 -0.44 151 151" xml:space="preserve">
    //           <defs>
    //           </defs>
    //           <path fill="#5E0000" d="M121.32,0L44.58,0C36.67,0,29.5,3.22,24.31,8.41c-5.19,5.19-8.41,12.37-8.41,20.28
    //             c0,15.82,12.87,28.69,28.69,28.69c0,0,4.4,0,7.48,0C36.66,72.78,8.4,101.04,8.4,101.04C2.98,106.45,0,113.66,0,121.32
    //             c0,7.66,2.98,14.87,8.4,20.29l0,0c5.42,5.42,12.62,8.4,20.28,8.4c7.66,0,14.87-2.98,20.29-8.4c0,0,28.26-28.25,43.66-43.66
    //             c0,3.08,0,7.48,0,7.48c0,15.82,12.87,28.69,28.69,28.69c7.66,0,14.87-2.99,20.29-8.4c5.42-5.42,8.4-12.62,8.4-20.28l0-76.74
    //             c0-7.66-2.98-14.87-8.4-20.29C136.19,2.98,128.98,0,121.32,0z"/>
    //           <path fill="#961B1E" d="M142.42,105.43V28.69c0-11.65-9.45-21.1-21.1-21.1H44.58c-11.65,0-21.1,9.45-21.1,21.1s9.45,21.1,21.1,21.1
    //             h25.79L13.77,106.4c-8.24,8.24-8.24,21.6,0,29.84c8.24,8.24,21.6,8.24,29.84,0l56.61-56.61v25.79c0,11.65,9.45,21.1,21.1,21.1
    //             C132.97,126.53,142.42,117.08,142.42,105.43z"/>
    //           </svg>
    //           `
    //   }
    // },
    // {
    //   type: 'Polygon',
    //   name: 'Polygon',
    //   color: '#0F0',
    //   options: {
    //     fill: '#FF0',
    //     stroke: '#00F',
    //     strokeWidth: 5,
    //     points: [
    //       {x: 185, y: 0},
    //       {x: 250, y: 100},
    //       {x: 385, y: 170},
    //       {x: 0, y: 245}
    //     ]
    //   }
    // }
  ]
}

export const getImageEditorConfig = createSelector(
  getClientConfig,
  (clientConfig) => {
    const imageEditorConfig = get(clientConfig, ['imageEditor'])
    if(imageEditorConfig) {
      if(imageEditorConfig.extend) {
        return {
          ...DEFAULT_CONFIG,
          ...imageEditorConfig,
          tools: [
            ...DEFAULT_CONFIG.tools,
            ...(imageEditorConfig.tools || [])
          ]
        }
      }
      return imageEditorConfig
    }
    return DEFAULT_CONFIG
  }
);