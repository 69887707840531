import React from 'react';
import {
    reportEvent,
} from "tbf-react-library";
import { getIsDocumentHidden, getBrowserVisibilityProp } from '../util/util';


const AppActiveReporter = (props) => {

    const onVisibilityChange = () => {
        const isHidden = getIsDocumentHidden();
        console.log('[App] App is ', isHidden ? 'Inactive' : 'Active', new Date());
        reportEvent({ name: isHidden ? 'AppInactive' : 'AppActive' });
    }

    React.useEffect(() => {
        document.addEventListener(getBrowserVisibilityProp(), onVisibilityChange, false);
        return () => {
            document.removeEventListener(getBrowserVisibilityProp(), onVisibilityChange);
        };
    }, []);

    return props.children;
}

export default AppActiveReporter;