import debounce from "lodash/debounce";
import { useCallback, useEffect } from "react"
import { useCallbackPatchNode } from "../../hooks/nodeHooks";
import { NODE_IDS } from "../../reducers/graphReducer";

const Activity = ({}) => {
    const patchNode = useCallbackPatchNode();

    const updateLastUserActivityDateTime = useCallback(debounce(() => {
        patchNode({id: NODE_IDS.UserSettings, lastUserActivityDateTime: Date.now()});
    }, 1000), []);

    useEffect(() => {
        // If user just loaded page in, update activity to avoid last using stored in indexeddb
        updateLastUserActivityDateTime();
        const userEvents = [
            "mousedown",
            "keydown",
            "touchstart",
            "visibilitychange",
            "scroll",
        ]
        for (const event of userEvents) {
            window.addEventListener(event, updateLastUserActivityDateTime);
        }
        return () => {
            updateLastUserActivityDateTime();
            for (const event of userEvents) {
                window.removeEventListener(event, updateLastUserActivityDateTime);
            }
        }
    }, []);

    return null;
}

export default Activity;