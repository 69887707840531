import React from "react";
import withStyles from '@mui/styles/withStyles';
import {getFirstTaskOrNull, getNodeOrNull, getNodeSchemaOrNull} from "../../../selectors/graphSelectors";
import {Panel} from "tbf-react-library";
import {connect} from "react-redux";
import {strings} from "../../components/SopLocalizedStrings";
import {NODE_TYPE_OPTIONS, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import {createChildNode} from "../../../factory/graphFactory";
import {putNodesProperty} from "../../../actions";
import ProcedureRuleNodeIdSelector from "../rules/ProcedureRuleNodeIdSelector";
import {Grid} from "@mui/material";
import TbfToggle from "../../components/common/TbfToggle";
import {getRulesByActions} from "../../../selectors/ruleSelectors";

const pageStrings = strings.procedure.settings.search;

class ProcedureGeneralSettings extends React.Component {


    constructor(props, context) {
        super(props, context);
        this.handleCustomiseTextSearch = this.handleCustomiseTextSearch.bind(this);
    }

    handleCustomiseTextSearch = (event) => {
        let {textSearchRules} = this.props;
        this.handleCustomiseSearch(event, textSearchRules, RULE_ACTION_TYPE.textSearch.id);
    }
    handleCustomiseFieldSearch = (event) => {
        let {fieldSearchRules} = this.props;
        this.handleCustomiseSearch(event, fieldSearchRules, RULE_ACTION_TYPE.fieldSearch.id);
    }
    handleCustomiseSearch = (event, rules, ruleActionType) => {
        let {ruleSchema, procedure, onPutNodesProperty, firstTask} = this.props;

        let checked = event.target.checked;
        if (checked && rules.length > 0) {
            const patches = rules.map(a => ({id: a.nodeIds[0], deleted: false}));
            onPutNodesProperty(patches);
        } else if (checked) {
            // Add 2 rules, the block rule with condition + selected nodes, and the action rule with Text Search
            let parentRuleAttr = {
                alwaysOn: true,
                nodeIds: [firstTask?.id],
                actionType: RULE_ACTION_TYPE.block.id
            };
            let parentRuleNode = createChildNode(procedure, ruleSchema, parentRuleAttr);

            let ruleAttr = {
                alwaysOn: true,
                nodeIds: [parentRuleNode.id],
                actionType: ruleActionType
            };
            let ruleNode = createChildNode(procedure, ruleSchema, ruleAttr);
            onPutNodesProperty([parentRuleNode, ruleNode]);
        } else if (rules.length > 0) {
            const patches = rules.map(a => ({id: a.nodeIds[0], deleted: true}));
            onPutNodesProperty(patches);
        }
    }

    render() {
        const {
            disabled,
            customSearchOn,
            firstTask,
            procedureId,
            textSearchRules,
            customFieldOn,
            fieldSearchRules
        } = this.props;


        return (<>
            <Panel title={pageStrings.textSearchTitle}>
                <Grid container>
                    <Grid item xs={12}>
                        <p>{strings.formatString(pageStrings.textSearchCaption, (firstTask?.name || ''))}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <div data-cy-element={'Property'}>
                            <TbfToggle
                                id={'customiseTextSearch'}
                                label={pageStrings.textSearchToggleLabel}
                                checked={customSearchOn}
                                disabled={disabled}
                                onChange={this.handleCustomiseTextSearch}
                            />
                            {
                                customSearchOn && <p>{pageStrings.textSearchNodeIdsHint}</p>
                            }
                            {
                                customSearchOn && textSearchRules.map(r => (
                                    <ProcedureRuleNodeIdSelector
                                        key={r.id}
                                        procedureId={procedureId}
                                        ruleId={r.nodeIds[0]}
                                        disabled={disabled}
                                    />))
                            }
                        </div>
                    </Grid>
                </Grid>
            </Panel>

            <Panel displayName={pageStrings.fieldSearchTitle}>
                <p>{strings.formatString(pageStrings.fieldSearchCaption, (firstTask?.name || ''))}</p>
                <div data-cy-element={'Property'}>
                    <TbfToggle
                        id={'customiseFieldSearch'}
                        label={pageStrings.fieldSearchToggleLabel}
                        checked={customFieldOn}
                        disabled={disabled}
                        onChange={this.handleCustomiseFieldSearch}
                    />
                </div>
                {
                    customFieldOn && <p>{pageStrings.fieldSearchNodeIdsHint}</p>
                }
                {
                    customFieldOn && fieldSearchRules.map(r => (
                        <ProcedureRuleNodeIdSelector
                            key={r.id}
                            procedureId={procedureId}
                            ruleId={r.nodeIds[0]}
                            disabled={disabled}
                        />))
                }
            </Panel>
        </>);
    }
}

const styles = () => ({});

ProcedureGeneralSettings.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let procedureId = ownProps.procedureId;
    let node = getNodeOrNull(state, procedureId);

    const textSearchRules = getRulesByActions(state, procedureId, [RULE_ACTION_TYPE.textSearch.id]);
    const fieldSearchRules = getRulesByActions(state, procedureId, [RULE_ACTION_TYPE.fieldSearch.id]);
    let firstTask = getFirstTaskOrNull(state, procedureId);

    return {
        customSearchOn: textSearchRules.length > 0 && textSearchRules.find(a => !a.deleted && !a.implicitlyDeleted) !== undefined,
        customFieldOn: fieldSearchRules.length > 0 && fieldSearchRules.find(a => !a.deleted && !a.implicitlyDeleted) !== undefined,
        procedure: node,
        ruleSchema: getNodeSchemaOrNull(state, NODE_TYPE_OPTIONS.ProcedureRule),
        firstTask: firstTask,
        textSearchRules: textSearchRules,
        fieldSearchRules: fieldSearchRules
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedureGeneralSettings));
