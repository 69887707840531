import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../style/alert.css';
import PageComponentBase from "../PageComponentBase";
import {reportUserError, SharedAuth} from "tbf-react-library";
import {Permissions} from "../../permissions";
import {searchActivity} from "../../actions/activity";
import Loader from "../components/Loader";
import Typography from "@mui/material/Typography";
import groupByDay, {formatDay} from "../../util/util";
import ActivityList from "./ActivityList";
import {strings} from "../components/SopLocalizedStrings";

class ActivityStream extends PageComponentBase {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: false
        }
    }

    componentWillUnmount() {
        this.mouted = false;
    }

    componentDidMount() {
        let {criteria} = this.props;
        this.mouted = true;
        this.setState({loading: true});
        searchActivity(criteria).then(response => {
            if (this.mouted) {
                this.setState({
                    loading: false,
                    items: response.data?.items || response.data
                });
            }
        }).catch(error => {
            reportUserError(error, null, 'Loading activity failed.');
            if (this.mouted) {
                this.setState({loading: false});
            }
        });
    }

    render() {
        let {loading, items} = this.state;
        let {classes} = this.props;
        const {days, eventsByDay} = groupByDay(items, a => a.activityDateTime);
        return (
            <div data-cy-element={'Activity'}>
                {
                    loading && <Loader friendlyName={'history'}/>
                }
                {
                    !loading && items.length === 0 &&
                    <React.Fragment>{strings.activity.none}</React.Fragment>
                }
                {days.map(day => (
                    <div key={day} className={classes.day}>
                        <Typography variant="h2" gutterBottom>
                            {formatDay(day)}
                        </Typography>
                        <ActivityList items={eventsByDay[day]}/>
                    </div>
                ))}
            </div>);
    }
}

const styles = () => ({
    day: {
        marginBottom: '1em',
    },
});
ActivityStream.propTypes = {};
const mapStateToProps = () => {
    return {
        canAssign: SharedAuth.userHasPermission(Permissions.execution.read)
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActivityStream));
