import React from "react";
import withStyles from '@mui/styles/withStyles';
import {useNodeOrError,} from "../../../hooks/nodeHooks";
import NodeTextField from "../../graph/NodeTextField";
import {useSecurityMemberOptions} from "../../../hooks/procedureHooks";

const ProcedureSecurityRule = ({disabled, ruleId}) => {
    const rule = useNodeOrError(ruleId)
    const root = useNodeOrError(rule.rootId)
    const whoOptions = useSecurityMemberOptions()
    return (<>
        <NodeTextField
            nodeId={rule.id}
            nodePropertyName={'calculateValueQuery'}
            nodeValueProperty={'calculateValueHumanStored'}
            disabled={disabled}
            visual={'select'}
            nodeValueOptions={whoOptions}
            storeJson={true}
            label={'Members'}
            multiple={true}
        />
    </>)
}
const styles = () => ({});
export default withStyles(styles)(ProcedureSecurityRule);