import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import PageComponentBase from "../PageComponentBase";
import {strings} from "../components/SopLocalizedStrings";
import ActivityStream from "../activity/ActivityStream";
import {Breadcrumbs, HistoryBackButton, PageContentWrapper, PageHeader} from "tbf-react-library";
import ViewActivityIcon from "@mui/icons-material/HistoryRounded";

const pageStrings = strings.execution.activity;

class ExecutionActivity extends PageComponentBase {

    render() {
        let {nodeId} = this.props;
        return (
            <>
                <PageHeader
                    title={pageStrings.title}
                    PageIcon={<ViewActivityIcon/>}
                    renderNavigationActions={() => <HistoryBackButton/>}
                    showMenuToggle={false}/>
                <PageContentWrapper>
                    <Breadcrumbs list={[]}/>
                    <ActivityStream criteria={{rootId: nodeId}}/>
                </PageContentWrapper>
            </>
        )
    }
}

const styles = () => ({});
ExecutionActivity.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.match.params.id;
    return {
        nodeId: nodeId
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionActivity));
