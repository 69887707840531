export const Roles = {
    ITPAuditor: {
        id: 'ITPAuditor',
        name: 'ITP Auditor'
    },
    ITPAdministrator: {
        id: 'ITPAdministrator',
        name: 'ITP Administrator'
    },
    ITPFieldUser: {
        id: 'ITPFieldUser',
        name: 'ITP Field User'
    },
    ITPProjectDesigner: {
        id: 'ITPProjectDesigner',
        name: 'ITP Project Designer'
    },
    ITPTemplateDesigner: {
        id: 'ITPTemplateDesigner',
        name: 'ITP Template Designer'
    },
    ITPReadOnly: {
        id: 'ITPReadOnly',
        name: 'ITP Read Only'
    },
    ITPTester: {
        id: 'ITPTester',
        name: 'ITP Tester'
    },
};