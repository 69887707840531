export const HELP_DOCUMENT_LOAD_START = 'HELP_DOCUMENT_LOAD_START';
export const HELP_DOCUMENT_LOADED = 'HELP_DOCUMENT_LOADED';
export const HELP_DOCUMENT_LOAD_END = 'HELP_DOCUMENT_LOAD_END';
export const HELP_DOCUMENT_CREATE_START = 'HELP_DOCUMENT_CREATE_START';
export const HELP_DOCUMENT_CREATED = 'HELP_DOCUMENT_CREATED';
export const HELP_DOCUMENT_CREATE_ERROR = 'HELP_DOCUMENT_CREATE_ERROR';
export const HELP_DOCUMENT_UPDATE_START = 'HELP_DOCUMENT_UPDATE_START';
export const HELP_DOCUMENT_UPDATED = 'HELP_DOCUMENT_UPDATED';
export const HELP_POINT_UPDATED = 'HELP_POINT_UPDATED';
export const HELP_DOCUMENT_UPDATE_ERROR = 'HELP_DOCUMENT_UPDATE_ERROR';
export const SET_HELP_DOCUMENT_SOURCE = 'SET_HELP_DOCUMENT_SOURCE';
export const UPDATE_HELP_POINT = 'UPDATE_HELP_POINT';
export const HELP_POINTS_LOADING = 'HELP_POINTS_LOADING';
export const HELP_POINTS_DISPLAY_TOGGLE = 'HELP_POINTS_DISPLAY_TOGGLE';
export const HELP_POINTS_LOADED = 'HELP_POINT_LOADED';
export const HELP_POINT_MANUAL_HTML_LOADED = 'HELP_POINT_MANUAL_HTML_LOADED';
