import React, {useCallback} from "react";
import {TbfSelectDeviceFriendly} from "tbf-react-library";
import {AFTER_ADD_NAVIGATION_MODE, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import {
    useCallbackCreateOrRestoreRule,
} from "../../../hooks/procedureHooks";
import FormLabel from "@mui/material/FormLabel";
import {strings} from "../../components/SopLocalizedStrings";
import ProcedureCompileMessages from "../ProcedureCompileMessages";
import {keyedObjectIdNameToNameLabel} from "../../../util/util";
import { useChildRuleByActionOrNull } from "../../../hooks/nodeHooks";

const ProcedureAddDialogNavigationMode = ({disabled, procedureId}) => {

    const rule = useChildRuleByActionOrNull(procedureId, RULE_ACTION_TYPE.afterAddNavigationMode.id);

    const cb = useCallbackCreateOrRestoreRule(procedureId, RULE_ACTION_TYPE.afterAddNavigationMode.id);

    const optionsByKey = keyedObjectIdNameToNameLabel(AFTER_ADD_NAVIGATION_MODE);
    const selectedOption = rule && rule.format && !rule.deleted ? optionsByKey[rule.format] : optionsByKey[AFTER_ADD_NAVIGATION_MODE.default.id];
    const handleSelect = useCallback((option) => {
        const on = option.value !== AFTER_ADD_NAVIGATION_MODE.default.id;
        cb({on, ruleAttributes: {format: option.value}});
    }, [cb])
    return (
        <>
            <div data-cy-element={'Property'}>
                <FormLabel className={'sizeSmall'} htmlFor={'afterAddNavigationMode'}>
                    {strings.procedure.settings.design.afterAddNavigationMode}
                </FormLabel>
                <TbfSelectDeviceFriendly
                    onChange={handleSelect}
                    propertyName={'afterAddNavigationMode'}
                    id={'afterAddNavigationMode'}
                    label={'After add navigation mode'}
                    options={Object.values(optionsByKey)}
                    value={selectedOption}
                    closeMenuOnSelect={true}
                    isSearchable={false}
                    mobileView={true}
                    menuPortalTarget={document.body}
                    disabled={disabled}
                />
            </div>
            {!!rule && <ProcedureCompileMessages nodeId={rule.id}/>}
        </>);
}

export default ProcedureAddDialogNavigationMode;
