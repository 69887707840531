import {recordUser} from "../actions/user";
import {reportError, SharedAuth} from "tbf-react-library";

let userLoaded = false;
const clientConfiguration = store => {
    const fetcher = () => {
        if (SharedAuth.hasActiveToken() && !userLoaded) {
            window.setTimeout(() => {
                recordUser().then(() => {
                }).catch((e) => {
                    reportError(e);
                });
            }, 0)
            userLoaded = true
        }
    }
    SharedAuth.addLoginListener(fetcher);

    return next => action => {
        return next(action)
    }
}

export default clientConfiguration
