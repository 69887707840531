import {getBrowserVisibilityProp, getIsDocumentHidden, isOnline} from "../util/util";
import {useInternetAvailable, useNodeOrNull} from "./nodeHooks";
import {NODE_IDS} from "../reducers/graphReducer";
import {useCallback, useEffect, useState} from "react";

export const useOnline = ({checkRecentErrors = true} = {}) => {
    // User Device internetAvailable is based on token + browser.online
    const userDevice = useNodeOrNull(NODE_IDS.UserDevice)

    // graph internet available is based on the last request result
    const isGetSaveOnline = useInternetAvailable()
    const result = isOnline() && (isGetSaveOnline !== false || !checkRecentErrors) && !!userDevice?.internetAvailable
    return result;
}

export const useActiveTab = () => {
    const [activeTab, setActiveTab] = useState(true)
    const onVisibilityChanged = useCallback(() => {
        setActiveTab(!getIsDocumentHidden())
    }, [setActiveTab])
    useEffect(() => {
        document.addEventListener(getBrowserVisibilityProp(), onVisibilityChanged, false);
        return () => {
            document.removeEventListener(getBrowserVisibilityProp(), onVisibilityChanged, false);
        }
    });
    return activeTab
}