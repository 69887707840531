import {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {recomputeCheck} from "../../actions";
import {connect} from "react-redux";
import {cypress} from "tbf-react-library";

class GraphDeveloperChecks extends Component {

    constructor(props) {
        super(props);
        //this.checkIntervalTime = setInterval(() => this.checkRecompute(), 60000);

        this.checkRecompute = this.checkRecompute.bind(this);
    }

    checkIntervalTime = null;


    componentWillUnmount() {
        clearInterval(this.checkIntervalTime);
    }

// For now doing this each time we see a dirty node count increase
    // Alternatively we could compute dirty on server load and ignore until touched via a PUT_NODE?
    checkRecompute() {
        let {doChecks} = this.props;
        if (doChecks) {
            this.props.recomputeCheck();
        }
    }

    render() {
        return null;
    }
}

const styles = () => ({});
GraphDeveloperChecks.propTypes = {};
const mapStateToProps = () => {
    return {
        doChecks: cypress.isDeveloperChecksEnabled()
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        recomputeCheck: () => dispatch(recomputeCheck())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphDeveloperChecks));
