import React, { useMemo, useRef } from "react";
import {RULE_ACTION_TYPE, PROCEDURE_THEME_FORMATS} from "../../../reducers/graphReducer";
import {
    useCallbackCreateOrRestoreRuleByFormat,
} from "../../../hooks/procedureHooks";
import FormLabel from "@mui/material/FormLabel";
import {strings} from "../../components/SopLocalizedStrings";
import ProcedureCompileMessages from "../ProcedureCompileMessages";
import { useCallbackPatchNode, useChildRuleByActionAndFormatOrNull, useNodeOrNull } from "../../../hooks/nodeHooks";
import UploadIcon from '@mui/icons-material/AddAPhoto';
import ResetIcon from '@mui/icons-material/Cached';
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import { getProcedureTypeIcon } from "../../../util/image";
import { createImageObjectUrl } from "../../../util/util";
import { reportUserError } from "tbf-react-library";

const pageStrings = strings.procedure.settings.design;

const useStyle = makeStyles((theme) => ({
    inputContainer: {
        overflow: 'visible',
        position: 'relative',
        "& > *": {
            display: 'inline-flex',
            verticalAlign: 'middle',
        }
    },
    value: {
        maxHeight: 54,
        maxWidth: 54,
    },
    reset: {
        position: 'relative',
        marginLeft: theme.spacing(1),
    }
}));

const FILE_SIZE_LIMIT_BYTES = 50000;

const ProcedureThemeIconPicker = ({disabled, procedureId}) => {

    const procedure = useNodeOrNull(procedureId);

    const classes = useStyle();

    const rule = useChildRuleByActionAndFormatOrNull(procedureId, RULE_ACTION_TYPE.theme.id, PROCEDURE_THEME_FORMATS.icon.id);

    const patch = useCallbackPatchNode();

    const cb = useCallbackCreateOrRestoreRuleByFormat(procedureId, RULE_ACTION_TYPE.theme.id, PROCEDURE_THEME_FORMATS.icon.id);

    const handleChange = (event) => {
        const file = event.target.files[0];

        if (file.size > FILE_SIZE_LIMIT_BYTES) {
            reportUserError(pageStrings.fileSizeLimit);
            event.target.value = null;
            return;
        }

        const reader = new FileReader();
        reader.onloadend = function () {
            const base64data = JSON.stringify(reader.result);
            if (rule && !rule.deleted) {
                patch({id: rule.id, calculateValueQuery: base64data});
            } else {
                cb({on: true, ruleAttributes: {calculateValueOn: true, calculateValueQuery: base64data, format: PROCEDURE_THEME_FORMATS.icon.id}});
            }   
        }
        reader.readAsDataURL(file);
    }
    const iconSrcRef = useRef();
    const currentIconSrc = useMemo(() => {
        if (iconSrcRef.current) {
            window.URL.revokeObjectURL(iconSrcRef);
        }
        if (rule && !rule.deleted && rule.calculateValueQuery) {
            iconSrcRef.current = createImageObjectUrl(JSON.parse(rule?.calculateValueQuery));
        } else {
            iconSrcRef.current = getProcedureTypeIcon(procedure.procedureType);
        }
        return iconSrcRef.current;
    }, [procedure?.procedureType, rule?.calculateValueQuery])

    const handleResetToDefault = () => {
        cb({on: false, ruleAttributes: {calculateValueOn: false, calculateValueQuery: null}});
    }

    return (
        <>
            <div data-cy-element={'Property'}>
                <FormLabel className={'sizeSmall'} htmlFor={'theme.icon'}>
                    {pageStrings.icon}
                </FormLabel>
                <div className={classes.inputContainer}>
                    <IconButton
                        variant="contained"
                        component="label"
                        disabled={disabled}
                        className={classes.upload}
                    >
                        {rule && !rule.deleted && rule.calculateValueQuery ? <img className={classes.value} src={currentIconSrc} alt={"Icon"} /> : <UploadIcon />}
                        <Input
                            type="file"
                            onChange={handleChange}
                            disabled={disabled}
                            hidden
                        />
                    </IconButton>
                    <IconButton
                        variant="contained"
                        onClick={handleResetToDefault}
                        title={pageStrings.resetToDefault}
                        disabled={disabled}
                    >
                        <ResetIcon />
                    </IconButton>
                </div>
            </div>
            {!!rule && <ProcedureCompileMessages nodeId={rule.id}/>}
        </>);
}

export default ProcedureThemeIconPicker;
