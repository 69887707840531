import {getNodeOrError, getNodeOrNull, getNodesIfPresent} from "../selectors/graphSelectors";
import {arrayMinus, EMPTY_ARRAY, mergeUnique} from "../util/util";
import {getActiveRulesForNode} from "../selectors/ruleSelectors";

export const syncRuleIds = (beforeState, state, node, nodeWithNodeIds, update) => {
    let beforeNodeWithNodeIds = getNodeOrNull(beforeState, nodeWithNodeIds.id);
    let currentNodeWithNodeIds = node;
    if (beforeNodeWithNodeIds == null || beforeNodeWithNodeIds.nodeIds !== currentNodeWithNodeIds.nodeIds) {
        let appliedToNodes = getNodesIfPresent(state, nodeWithNodeIds.nodeIds);
        // Add
        for (let source of appliedToNodes) {
            const revisedIds = mergeUnique(source.ruleIds, node.id);
            if (source.ruleIds !== revisedIds) {
                let cloned = update[source.id] || {...source};
                cloned.ruleIds = revisedIds;
                update[cloned.id] = cloned;
            }
        }
        // Remove
        if (beforeNodeWithNodeIds != null) {
            let toRemove = arrayMinus(beforeNodeWithNodeIds.nodeIds, nodeWithNodeIds.nodeIds);
            for (let removeId of toRemove) {
                let cloned = update[removeId] || {...getNodeOrError(state, removeId)};
                update[cloned.id] = cloned;
                cloned.ruleIds = cloned.ruleIds.filter(a => a !== node.id);
            }
        }
    }
}

export const computeRuleIds = (state, node) => {
    if (node.ruleIds === undefined) {
        const rules = getActiveRulesForNode(state, node.id).map(a => a.id);
        node.ruleIds = rules.length === 0 ? EMPTY_ARRAY : rules;
    }
}