import React, {useCallback, useEffect, useState} from 'react';
import '../../style/alert.css';
import {reportUserError} from "tbf-react-library";
import Loader from "../components/Loader";
import {downloadVersion, searchVersions} from "../../actions/history";
import ReactDataGrid from "react-data-grid";
import {formatDay, formatTime} from "../../util/util";
import {Link} from "@mui/material";
import {Data} from "react-data-grid-addons";

const VersionStream = ({rootId, rootType}) => {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [expandedRows, setExpandedRows] = useState({"1": true});
    const [collapsedRows, setCollapsedRows] = useState(null);

    const onExpandedRowsChange = useCallback(({expandedRows, collapsedRows}) => {
        setExpandedRows(expandedRows);
        setCollapsedRows(collapsedRows);
    }, [])

    useEffect(() => {
        setLoading(true)
        searchVersions(rootId, rootType).then(response => {
            let rows = response.data?.items || response.data;
            for (let row of rows) {
                row.changeDate = (row.createdDateTime && formatDay(row.createdDateTime)) || '';
                row.changeTime = (row.createdDateTime && formatTime(row.createdDateTime)) || '';
                row.name = (row.area || []).join(' -> ');
                // Filter code does not deal with nulls
                for (let propertyName of Object.keys(row)) {
                    if (row[propertyName] == null) {
                        row[propertyName] = '';
                    }
                }
            }
            setRows(rows)
            setLoading(false)
        }).catch(error => {
            reportUserError(error, null, 'Loading history failed.');
            setLoading(false)
        });
    }, [setLoading])

    const viewRow = useCallback((row) => {
        const promise = downloadVersion(rootId, rootType, row.fileName)
        promise.then(response => {
            if (response) {
                let fileName = `${rootId}-${row.fileName}`;
                const contentDisposition = response.headers['content-disposition'];
                if (contentDisposition) {
                    const contentDispositionParts = contentDisposition.split('filename=')[1];
                    fileName = contentDispositionParts ? contentDispositionParts.split(';')[0] ? contentDispositionParts.split(';')[0].split('"').join("") : 'execution' : 'execution';
                }
                const fileDownload = require('js-file-download');
                fileDownload(new Blob([response.data]), fileName);
            }
        }).catch(error => {
            reportUserError(error, null, 'Downloading file failed.');
        });
    }, [rootId, rootType])

    let groupedRows = Data.Selectors.getRows({rows: rows, groupBy})
    return (
        <React.Fragment>
            {
                loading &&
                <Loader/>
            }
            <ReactDataGrid
                columns={columns(viewRow)}
                rowGetter={(i) => groupedRows[i]}
                rowsCount={groupedRows.length}
                enableRowSelect={null}
                minWidth={'100%'}
                rowScrollTimeout={null}
                // rowExpandHeight={300}
                // expandedRows={expandedRows}
                // collapsedRows={collapsedRows}
                // onExpandedRowsChange={onExpandedRowsChange}
                // renderRowDetails={renderRowDetails}
            />
        </React.Fragment>);
}
//
// class VersionStream extends PageComponentBase {
//
//     constructor(props) {
//         super(props);
//         this.state = {
//             items: [],
//             loading: false
//         }
//     }
//
//     componentWillUnmount() {
//         this.mouted = false;
//     }
//
//     componentDidMount() {
//         let {rootId, rootType} = this.props;
//         this.mouted = true;
//         this.setState({loading: true});
//         searchVersions(rootId, rootType).then(response => {
//             if (this.mouted) {
//                 let rows = response.data?.items || response.data;
//                 for (let row of rows) {
//                     row.changeDate = (row.createdDateTime && formatDay(row.createdDateTime)) || '';
//                     row.changeTime = (row.createdDateTime && formatTime(row.createdDateTime)) || '';
//                     row.name = (row.area || []).join(' -> ');
//                     // Filter code does not deal with nulls
//                     for (let propertyName of Object.keys(row)) {
//                         if (row[propertyName] == null) {
//                             row[propertyName] = '';
//                         }
//                     }
//                 }
//                 this.setState({
//                     loading: false,
//                     items: rows
//                 });
//             }
//         }).catch(error => {
//             reportUserError(error, null, 'Loading history failed.');
//             if (this.mouted) {
//                 this.setState({loading: false});
//             }
//         });
//     }
//
//     handleViewClick(e)
//     {
//
//     }
//
//     renderRowDetails(p)
//     {
//
//     }
//
//     render() {
//         let {items, loading, filters} = this.state;
//         let groupedRows = Data.Selectors.getRows({rows: items, groupBy, filters})
//         return (
//             <React.Fragment>
//                 {
//                     loading &&
//                     <Loader/>
//                 }
//                 <ReactDataGrid
//                     columns={columns(this.handleViewClick)}
//                     rowGetter={(i) => groupedRows[i]}
//                     rowsCount={groupedRows.length}
//                     enableRowSelect={null}
//                     minWidth={'100%'}
//                     rowScrollTimeout={null}
//                     rowExpandHeight={300}
//                     renderRowDetails={this.renderRowDetails}
//                 />
//             </React.Fragment>);
//     }
// }

const columns = (viewRow) => [
    {
        key: "changeDate",
        name: "Date", editable: false,
        width: 80, resizable: true,
        filterable: true
    },
    {
        key: "changeTime",
        name: "Time", editable: false,
        width: 80, resizable: true,
        filterable: true
    },
    {
        key: "version",
        name: "Version",
        editable: false,
        width: 80,
        resizable: true,
        filterable: true
    },
    {
        key: "userEmail",
        name: "Who",
        editable: false,
        width: 160,
        resizable: true,
        filterable: true
    },
    {
        key: "versionType",
        name: "File Type",
        editable: false,
        width: 200,
        resizable: true,
        filterable: true
    },
    {
        key: "contentLength",
        name: "Size",
        editable: false,
        width: 300,
        resizable: true,
        filterable: true
    },
    {
        key: "view",
        name: "View",
        editable: false,
        width: 300,
        resizable: true,
        filterable: true,
        formatter: ({row}) => {
            return <Link onClick={() => viewRow(row)}>View</Link>
        }
    }
];
const groupBy = ["changeDate"];

// const styles = () => ({
//     day: {
//         marginBottom: '1em',
//     },
// });
// VersionStream.propTypes = {};
// const mapStateToProps = () => {
//     return {
//         canAssign: SharedAuth.userHasPermission(Permissions.execution.read)
//     };
// };
// const mapDispatchToProps = () => {
//     return {};
// };
// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VersionStream));
export default VersionStream
