import {useNodeOrNull, useRulesForNode} from "../../../hooks/nodeHooks";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import withStyles from '@mui/styles/withStyles';
import GavelIcon from '@mui/icons-material/Gavel';
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import IconButton from "@mui/material/IconButton";
import {strings} from "../../components/SopLocalizedStrings";
import InfoIcon from "@mui/icons-material/InfoRounded";
import Grid from "@mui/material/Grid";
import JSONPretty from "react-json-pretty";
import ExecutionJsonLogic from "./ExecutionJsonLogic";
import {formatDate} from "../../../util/util";
import {useLocation} from "react-router-dom/cjs/react-router-dom.min";
import {EXECUTION_PROCEDURE_MAP} from "../../../factory/executionFactory";
import RuleCreatedExecutionsList from "./RuleCreatedExecutionsList";
import ExecutionRulesChildRulesList from "./ExecutionRulesChildRulesList";
import NodeProcessingWarning from "../../graph/NodeProcessingWarning";

const actionTypes = Object.values(RULE_ACTION_TYPE)

const ExecutionRulesListItem = ({classes, nodeId, ruleId, diagnosticsOn}) => {
    const node = useNodeOrNull(nodeId);
    const rule = useNodeOrNull(ruleId)
    const childRules = useRulesForNode(ruleId)
    const [showJson, setShowJson] = useState(false)
    const handleToggleShowJson = useCallback(() => {
        setShowJson(!showJson)
    }, [showJson, setShowJson])

    const itemRef = useRef(null);

    const location = useLocation();

    const highlight = useMemo(() => {
        const urlSearch = new URLSearchParams(location.search);
        const highlightId = urlSearch.get("highlight");
        const highlightRuleId = urlSearch.get("highlight_rule");
        const isTargetRule = highlightRuleId === rule?.id || rule?.ruleIds?.find?.((childRuleId) => childRuleId === highlightRuleId);
        let matchToHighlightProcedureNode = node?.[EXECUTION_PROCEDURE_MAP[node?.type]?.executionLinkBack] === highlightId;
        if (!!highlightId) {
            return !!isTargetRule && (highlightId === nodeId || matchToHighlightProcedureNode);
        }
        return !!isTargetRule;
    }, [rule, location, node]);

    useEffect(() => {
        if (highlight === true) {
            itemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [highlight])

    const ruleActions = []
    if (rule.actionType === RULE_ACTION_TYPE.block.id) {
        for (let actionType of actionTypes) {
            if (rule[actionType.id] === true) {
                ruleActions.push({
                    name: actionType.name,
                    calculate: rule.calculateValueOn ? rule.calculateValueQuery : null,
                    calculateDateTime: rule.calculateValueOn ? rule.calculateValueDateTime : null,
                    rule: rule,
                    condition: rule.calculateValueOn ? rule.calculateValueQuery : null,
                })
            }
        }
        for (let subRule of childRules) {

            if (subRule.actionType !== RULE_ACTION_TYPE.block.id && subRule.deleted === false) {
                const subactionType = RULE_ACTION_TYPE[subRule.actionType]
                ruleActions.push({
                    name: subactionType.name,
                    calculate: subRule.calculateValueOn ? subRule.calculateValueQuery : null,
                    calculateDateTime: rule.calculateValueOn ? rule.calculateValueDateTime : null,
                    conditionQuery: subRule.conditionQuery
                })
            }
        }
    } else {
        const actionType = RULE_ACTION_TYPE[rule.actionType]
        ruleActions.push({
            name: actionType.name,
            calculate: rule.calculateValueOn ? rule.calculateValueQuery : null,
            calculateDateTime: rule.calculateValueOn ? rule.calculateValueDateTime : null,
            rule: rule
        })
    }
    const onOff = rule.evaluatedValue ? 'On' : 'Off'
    const onOffTitle = (rule.evaluatedValue ? 'Turned on at ' : 'Turned off at ') + formatDate(rule.evaluatedValueDateTime);

    return (
        <ListItem ref={itemRef} key={rule.id} data-cy-element={'Rule'}
                  className={(rule.deleted ? 'alert-danger' : '') + ' ' + classes.ruleList + ' ' + (highlight ? classes.highlight : '')} alignItems="flex-start">
            <ListItemIcon className={classes.ruleIcon}><GavelIcon/></ListItemIcon>
            <ListItemText
                className={classes.ruleName}
                primary={rule.name}
            >
                &nbsp;
                {
                    rule.alwaysOn && <span title={onOffTitle}>[Always {onOff}]</span>
                }
                {
                    !rule.alwaysOn && <span title={onOffTitle}>[<ExecutionJsonLogic
                        jsonLogic={rule.conditionQuery}>Conditionally {onOff}</ExecutionJsonLogic>]</span>
                }
                &nbsp;
                {
                    ruleActions.filter(a => a.calculate !== null).map((a, i) => (
                        <span
                            key={i}
                            title={`Calculated value last changed at ${formatDate(a.calculateDateTime) || 'Never'}`}><ExecutionJsonLogic
                            jsonLogic={a.calculate}>{a.name}</ExecutionJsonLogic></span>))
                }
                &nbsp;
                {
                    ruleActions.filter(a => a.calculate === null).map((a, i) => {
                        if(a.conditionQuery) {
                            return <ExecutionJsonLogic
                                    key={i}
                                    jsonLogic={a.conditionQuery}>
                                        <React.Fragment><span>• {a.name}</span>&nbsp;</React.Fragment>
                                    </ExecutionJsonLogic>
                        } else {
                            return <React.Fragment
                                key={i}><span>• {a.name}</span>&nbsp;</React.Fragment>
                        }
                    })
                }
                {
                    rule.createExecutionOn &&
                    <RuleCreatedExecutionsList ruleId={ruleId} />
                }
                {!!childRules?.length && <ExecutionRulesChildRulesList ruleId={ruleId} />}
                {
                    showJson &&
                    <Grid item xs={12}>
                        <JSONPretty id="json-pretty" data={rule}/>
                    </Grid>
                }
                <NodeProcessingWarning nodeId={ruleId} />
            </ListItemText>
            {
                diagnosticsOn &&
                <IconButton
                    title={strings.buttons.diagnostics}
                    data-cy='toggle-diagnostics'
                    className={classes.diagnosticButton}
                    onClick={handleToggleShowJson}
                    size="large">
                    <InfoIcon/>
                </IconButton>
            }
        </ListItem>
    );
}
const styles = (theme) => ({
    ruleList: {
        paddingLeft: 5,
    },
    diagnosticButton: {
        marginTop: '6px',
    },
    ruleIcon: {
        minWidth: 30,
        padding: 12,
        marginTop: '6px',
    },
    ruleName: {
        overflow: 'visible',
        padding: 12,
        '& p': {
            margin: 0,
            color: theme.palette.grey.six.main,
        },
        '& span': {
            whiteSpace: 'wrap'
        }
    },
    subRule: {
        listStyleType: 'circle',
    },
    link: {
        textDecoration: 'underline'
    },
    highlight: {
        animation: '$highlight 5s ease-in-out',
    },
    '@keyframes highlight': {
        '20%': {
            backgroundColor: 'yellow',
        },
        '80%': {
            backgroundColor: 'yellow',
        },
        '100%': {
            backgroundColor: 'transparent',
        }
    },
});
export default withStyles(styles)(ExecutionRulesListItem);
