import React, {useEffect, useRef} from 'react';
import withStyles from '@mui/styles/withStyles';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import PageComponentBase from "../PageComponentBase";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {strings} from "../components/SopLocalizedStrings";
import {getNodeOrNull, getNodeSchemaOrError} from "../../selectors/graphSelectors";
import {withMobile} from "tbf-react-library";
import Grid from "@mui/material/Grid";
import MobileView from "./MobileView";
import TableView from "./table/TableView";
import {EXECUTION_FILTER_MODE, EXECUTION_SEARCH_VIEWS, NODE_IDS, NODE_TYPE_OPTIONS,} from "../../reducers/graphReducer";
import {isProcedureLoadedFull} from "../../selectors/procedureSelectors";
import cn from 'classnames'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import WorqItemsViewSelector from "./CollectionViewSelector";
import CollectionSearchInput from "./CollectionSearchInput";
import CollectionShowMapButton from "./CollectionShowMapButton";
import CollectionMap from "./CollectionMap";
import CollectionShowFilterButton from "./CollectionShowFilterButton";
import CollectionShowToggleColumnButton from "./CollectionShowToggleColumnButton";
import CollectionScrollContainer from "./CollectionScrollContainer";
import {getExecutionSummaryFullByIdIfPresent} from "../../selectors/executionSelectors";
import isString from 'lodash/isString'
import Loader from '../components/Loader';
import {MINUTES_5, REPORT_EVENT_TYPES} from "../../util/constants";
import HighlightNode from '../execution/troubleshoot/HighlightNode';
import ViewPivotTableButton from './pivot/ViewPivotTableButton';
import PivotTableView from './pivot/LazyPivotTableView';
import AlphaFeature from '../components/AlphaFeature';
import FieldListToggleButton from './pivot/FieldListToggleButton';
import IconButton from "@mui/material/IconButton";
import StartSelectingIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import SelectingIcon from '@mui/icons-material/LibraryAddCheck';
import { putNodeProperty } from '../../actions';
import { TableViewPaginationModes, TableViewPaginationStyles } from './table/DataTableView';
import SaveIcon from "@mui/icons-material/Save";

// TODO: There is an issue with the LazyLoadingIntersectionObserver, it is triggering twice. Once when the component enters
// and again when the component leaves. I just used the isIntersecting property on the entries, the LazyLoadingIntersectionObserver uses a
// bounding box to check if it is intersecting. Not sure if this is safe to change as it is in the base library so i have put this here for now
const MyLazyLoader = ({ intersectionCallback, rootContainer, showLoader, loaderText }) => {
  const ref = useRef()

  useEffect(() => {
    if (ref.current) {
      const element = ref.current
      const cb = (entries) => {
        const intersecting = entries.find(x => x.isIntersecting)
        if(intersecting) {
          intersectionCallback(intersecting)
        }  
      }
  
      const options = {
        root: isString(rootContainer) ? document.querySelector(rootContainer) : rootContainer,
        rootMargin: '0px',
        threshold: 0.5
      }    
      const observer = new IntersectionObserver(cb, options)
      observer.observe(element)

      return () => {
        observer.unobserve(element)
        observer.disconnect()
      }
    }    
  }, [rootContainer, intersectionCallback])

  return (
    <div ref={ref} style={{ minHeight: 96, paddingTop: 32, marginBottom: 32, textAlign: 'center' }}>
      {showLoader && <Loader circular={true} circularSize={32} source={loaderText || 'Loading...'}/>}
    </div>
  )
}

class WorkItems extends PageComponentBase {

    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();
        this.dataTableRef.current = {};
        this.state = {
            executionLinkNewId: null,
            activeId: null,
            requestedPageNumber: 1,
            showReportFieldList: false,
            allowSelection: false,
        }
    }

    componentDidMount() {

    }

    getNextPageData = (requestingPageNumber) => {
        let {isLoading, hasNextPage, pageNumber} = this.props;
        const {requestedPageNumber} = this.state;
        let isRefetch = requestingPageNumber && requestingPageNumber < requestedPageNumber;
        if (isLoading === false && hasNextPage && requestedPageNumber <= pageNumber && !isRefetch) {
          this.setState({requestedPageNumber: (pageNumber + 1)});
        }
    }

    getPageSize = () => {
        const {allowMap, selector} = this.props;
        if (allowMap) {
            return 100;
        }

        if (selector?.questionId) {
            return selector?.viewingPageSize || 10;
        }

        return 20;
    }

    toggleAllowSelection = () =>{
        const {selector, onPutNodeProperty} = this.props;
        onPutNodeProperty({id: selector.id, checkboxSelection: !selector?.checkboxSelection});
    }

    resetPageNumber = () => {
      this.setState({requestedPageNumber: 1});
    }

    renderReportToggle = () => {
        const {
            loadFilteredUrl,
            classes,
            selector,
            procedureId,
            procedureType
        } = this.props;
        const isListingPage = !!loadFilteredUrl;

        if (!isListingPage) {
            return null;
        }

        return <AlphaFeature>
            <ViewPivotTableButton className={classes.toggleButton} selectorId={selector?.id} procedureId={procedureId || selector?.procedureId} procedureType={procedureType} />
        </AlphaFeature>;
    }

    toggleFieldList = () => {
        this.setState({showReportFieldList: !this.state.showReportFieldList})
    }

    render() {
        let {
            workItems,
            procedureId,
            mobileViewPort,
            classes,
            loadFilteredUrl,
            isLoading,
            loadingStarted,
            totalItems,
            showLocation,
            showMap,
            mapExpanded,
            selectorId,
            columnsConfig,
            totalTitle,
            nodeId,
            renderAddAction,
            selector,
            report,
            onSave,
            disabled,
        } = this.props;
        const {requestedPageNumber, showReportFieldList} = this.state

        const showList = mobileViewPort ? !showMap : showMap && !mapExpanded;
        const showTable = !mobileViewPort && !showMap;
        const isListingPage = loadFilteredUrl && !selector?.questionId;
        const itemsPaged = isListingPage;
        const allowTextSearch = isListingPage;
        const allowMap = selector?.mapAvailable;
        const showFilterButton = !mobileViewPort && isListingPage && selector?.filterMode === EXECUTION_FILTER_MODE.server.id;
        const showColumnToggleButton = !mobileViewPort && isListingPage && selector?.filterMode === EXECUTION_FILTER_MODE.server.id;
        const allowSelection = !!selector?.checkboxSelection;
        const hasAvailableActions = Object.keys(selector?.availableActions ?? {}).length > 0;
        const isQueryListing = !isListingPage && loadFilteredUrl;
        const usePaginationStyle = isListingPage ? TableViewPaginationStyles.infiniteScroll : TableViewPaginationStyles.navigation;
        const usePaginationMode = isQueryListing ? TableViewPaginationModes.server : TableViewPaginationModes.client;
        const showReportToolbar = !disabled && (onSave || !selector?.questionId);
        const events = {
            itemClicked: isListingPage ? REPORT_EVENT_TYPES.listingPageItemClicked.name : REPORT_EVENT_TYPES.executionPageQueryItemClicked.name,
        }
        return <>
            {
                loadFilteredUrl && !report &&
                <GraphResourceLoad
                    key={loadFilteredUrl}
                    resourcePath={loadFilteredUrl}
                    friendlyName={strings.execution.namePlural}
                    nodeType={'ExecutionRoot'}
                    reloadIntervalMs={MINUTES_5}
                    hideLoader={true}
                    incrementalLoadOff={true}
                    pageNumber={requestedPageNumber}
                    pageSize={this.getPageSize()}
                    displayErrorMode={'inline'}
                    loadOnPageSizeChange={true}
                />
            }

            <div className={classes.toolbar} aria-live={'polite'} role={'region'}>
                <Grid container>
                    <Grid item lg={6} xl={8} md={4} sm={12} xs={12}
                          className={`${classes.flexContainer} ${classes.filterContainer}`}>
                        <div className={`${classes.flexContainer} ${classes.countWrap}`}>
                            <HighlightNode nodeId={nodeId}><label className={classes.countText}>{totalTitle}</label></HighlightNode>
                            <span data-cy={'count'}
                                  className={'muiToolbarCountBadge'}>{totalItems == null ? '' : totalItems}</span>
                        </div>
                        {!report && (showTable || showList) && hasAvailableActions && <IconButton
                            onClick={this.toggleAllowSelection}
                            title={!allowSelection ? strings.project.list.allowSelection : strings.project.list.hideSelection}
                            data-cy={'toggle-allow-selection'}
                            size="large"
                        >
                            {allowSelection ?
                                <SelectingIcon /> :
                                <StartSelectingIcon />
                            }
                        </IconButton>}
                        <div className={classes.grow}/>
                        {isListingPage && <WorqItemsViewSelector selectorId={selectorId}/>}
                    </Grid>
                    <Grid item lg={6} xl={4} md={8} sm={12} xs={12} className={classes.searchInputContainer}>
                        <div className={classes.grow}/>
                        {
                            allowTextSearch &&
                            <CollectionSearchInput selectorId={selectorId}/>
                        }
                        {
                            allowMap &&
                            <CollectionShowMapButton selectorId={selectorId}/>
                        }
                        {this.renderReportToggle()}
                        {
                            showFilterButton &&
                            <CollectionShowFilterButton selectorId={selectorId} dataTableRef={this.dataTableRef} />
                        }
                        {
                            showColumnToggleButton &&
                            <CollectionShowToggleColumnButton selectorId={selectorId} dataTableRef={this.dataTableRef} />
                        }
                        {(report && showReportToolbar && !disabled) && <FieldListToggleButton className={classes.toggleButton} on={showReportFieldList} onClick={this.toggleFieldList} />}
                        {renderAddAction && renderAddAction()}
                    </Grid>
                </Grid>
            </div>
            {report &&
                <div className={classes.pivotTableContainer}>
                    <div className={classes.hiddenTable}>     
                        <TableView
                            workItems={[]}
                            procedureId={procedureId}
                            loading={isLoading}
                            showLocation={showLocation}
                            selectorId={selectorId}
                            sortingMode={selector?.sortMode}
                            filterMode={selector?.filterMode}
                            sortModel={selector?.sortModel}
                            filterModel={selector?.filterModel}
                            columnsConfig={columnsConfig}
                            onSortChanged={this.resetPageNumber}
                            onFilterChanged={this.resetPageNumber}
                            ref={showFilterButton ? this.dataTableRef : undefined}
                        />                
                    </div>
                    <PivotTableView
                        selectorId={selectorId}
                        showFieldList={showReportFieldList}
                        showToolbar={showReportToolbar}
                        onUnmount={onSave}
                        maxHeight={selector?.questionId ? 704.5 : null}
                    />
                </div>
            }


            {!report && <CollectionScrollContainer
                className={cn(classes.listAndMapContainer, {showMap, itemsPaged, ['empty']: !itemsPaged && !workItems.length})}
            >
                {
                    (showTable || showList) &&
                    <div className={'listContainer'}>
                        {showList &&
                            <MobileView
                                workItems={workItems}
                                selectorId={selectorId}
                                className={classes.listContainer}
                            />
                        }
                        {showTable && columnsConfig &&
                            <TableView
                                workItems={workItems}
                                procedureId={procedureId}
                                loading={isLoading}
                                showLocation={showLocation}
                                selectorId={selectorId}
                                sortingMode={selector?.sortMode}
                                filterMode={selector?.filterMode}
                                sortModel={selector?.sortModel}
                                filterModel={selector?.filterModel}
                                columnsConfig={columnsConfig}
                                onSortChanged={this.resetPageNumber}
                                onFilterChanged={this.resetPageNumber}
                                onNextPage={this.getNextPageData}
                                ref={showFilterButton ? this.dataTableRef : undefined}
                                showToolbar={!showFilterButton}
                                pagination={!showFilterButton}
                                autoHeight={!showFilterButton}
                                expand={showFilterButton}
                                paginationMode={usePaginationMode}
                                paginationStyle={usePaginationStyle}
                                events={events}
                            />
                        }
                    </div>
                }
                {
                    showMap &&
                    <CollectionMap
                        workItems={workItems}
                        selectorId={selectorId}
                    />
                }
                {itemsPaged && !showTable && (
                    <MyLazyLoader
                    rootContainer={'#listingPageScroller'}
                    intersectionCallback={this.getNextPageData}
                    showLoader={loadingStarted}
                    loaderText="Work items..."                              
                    />
                )}
            </CollectionScrollContainer>}
        </>
    }
}

const styles = theme => ({
    hiddenTable: {
        height: 0,
        width: 0,
        overflow: 'hidden',
        '& div': {
            minHeight: "0px !important",
            minWidth: "0px !important",
            height: "0px !important",
            width: "0px !important",
        },
        position: 'absolute',
        left: 0,
        top: 0,
    },
    pivotTableContainer: {
        position: 'relative',
    },
    toolbar: {
        position: 'relative',
        width: '100%',
        display: 'block',
        padding: '5px 0 8px 0',
        [theme.breakpoints.down('xs')]: {
            padding: '5px 10px 8px 10px',
            background: '#FFF',
        },
    },
    countWrap: {
        padding: '5px 0',
    },
    countText: {
        color: theme.palette.grey.seven.main,
        fontSize: 16,
        fontFamily: theme.fonts.primary.bold,
        marginRight: 5,
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    grow: {
        flexGrow: 1
    },
    filterContainer: {
        gap: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginBottom: 5,
        },
    },
    toggleButton: {
        flexShrink: 0,
        flexGrow: 0,
        backgroundColor: "#fff",
    },
    listAndMapContainer: {
        display: 'flex',
        position: 'relative',
        '&.showMap': {
            backgroundColor: 'white',
            minHeight: 'calc(100vh - 230px)'
        },
        '& .listContainer': {
            width: '100%',
            flexGrow: 0,
            flexShrink: 0
        },
        '&.showMap .listContainer': {
            width: 500
        },
        '&.empty': {
            minHeight: '200px'
        },
        '&.itemsPaged': {
            height: 'calc(100vh - 230px)',
            width: '100%',
            overflow: 'auto',
            [theme.breakpoints.up('sm')]: {
                height: 'calc(100vh - 218px)' // TODO: Make this smarter, how do we calculate the full height
            },
            ['@media (max-height:560px)']: { // eslint-disable-line no-useless-computed-key
                height: '100%'
            },
        },
    },
    listContainer: {
        overflow: 'auto',
    },
    searchInputContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1)
    },
    actionButton: {
        marginLeft: 5,
    },
});

const mapStateToProps = (state, ownProps) => {
    const selectorId = ownProps.selectorId;
    const selector = getNodeOrNull(state, selectorId);
    const selectedViewId = get(selector, 'selectedViewId', null);
    const showMap = get(selector, 'showMap')
    const location = getNodeOrNull(state, NODE_IDS.Location);


    let loadFilteredResourceSync = getNodeOrNull(state, selector?.loadUrl);
    let loadedFilteredNodes = getExecutionSummaryFullByIdIfPresent(state, selector?.executionIds || []);

    let results = selector?.includeDeleted ? loadedFilteredNodes : loadedFilteredNodes.filter(a => a.deleted !== true);
    let allResults = [];
    allResults = allResults.concat(results);
    allResults = uniqBy(allResults, x => x.rootId)

    let procedure = ownProps.procedureId ? getNodeOrNull(state, ownProps.procedureId) : null;
    let procedureLoaded = isProcedureLoadedFull(procedure);
    const locationAvailable = location.position && location.position.latitude && location.position.longitude;
    const hasLocationField = procedure && procedure.hasLocationField;
    const hasLocation = locationAvailable && hasLocationField;
    const pageNumber = loadFilteredResourceSync?.pageNumber || 1;

    return {
        loadFilteredUrl: selector?.loadUrl,
        isLoading: selector?.isLoading,
        loadingStarted: loadFilteredResourceSync && loadFilteredResourceSync.loading,
        hasNextPage: loadFilteredResourceSync && loadFilteredResourceSync.hasNextPage,
        pageNumber: pageNumber,
        totalItems: selector?.total,
        totalTitle: selector?.totalTitle,
        selectorId: selectorId,
        selector: selector,
        selectorSchema: getNodeSchemaOrError(state, NODE_TYPE_OPTIONS.ExecutionListPage),
        workItems: allResults,
        procedureLoaded: procedureLoaded,
        hasLocation,
        hasLocationField,
        showLocation: hasLocation && locationAvailable && selectedViewId === EXECUTION_SEARCH_VIEWS.nearMe.id,
        showMap,
        mapExpanded: selector?.mapExpanded,
        columnsConfig: selector?.columns,
        nodeId: ownProps.nodeId,
        report: selector?.pivot,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withMobile()(WorkItems))));
