import React from "react";

class StopEventPropagator extends React.Component {

    stopPropagationOfEvent = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    render() {
        const {children, element} = this.props;

        if (element === 'inline') {
            return <span
                onClick={this.stopPropagationOfEvent}
                onKeyUp={this.stopPropagationOfEvent}
            >{children}</span>
        }

        return <div
            onClick={this.stopPropagationOfEvent}
            onKeyUp={this.stopPropagationOfEvent}
        >{children}</div>
    }
}

export default StopEventPropagator;
