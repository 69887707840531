import get from 'lodash/get';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import cloneDeep from "lodash/cloneDeep";
import keyBy from "lodash/keyBy";
import {reportError} from "tbf-react-library";
import {EMPTY_ARRAY} from "../util/util";
import {NODE_IDS, NODE_TYPE_OPTIONS} from '../reducers/graphReducer';

/**
 * As re-used in reducer needs to support state.graph.nodes and state.nodes
 * @param state
 * @returns {*}
 */
export const getState = (state) => state;
export const getGraph = (state) => state.graph ? state.graph : state;
export const getNodes = (state) => getGraph(state).nodes;
export const areNodesLoadedStorage = (state) => get(getGraph(state), ['nodesLoadedStorage']);
export const getActiveNodesByFilter = (state, applyFilter) => reject(filter(getNodes(state), applyFilter), {deleted: true});
export const getActiveNodesByType = (state, type) => getActiveNodesByFilter(state, {type: type});
export const getNodesByFilter = (state, applyFilter) => filter(getNodes(state), applyFilter);
export const getNodesByType = (state, type) => getNodesByFilter(state, {type: type});
export const getNodeOrNull = (state, nodeId) => get(getNodes(state), [nodeId]) || null;
export const getNodeOrError = (state, nodeId) => {
    let node = getNodeOrNull(state, nodeId);
    if (!node) {
        throw new Error(`Node with id [${nodeId}] does not exist.`);
    }
    return node;
};
export const getNodesById = (state, nodeIds) => nodeIds.map(nodeId => get(getNodes(state), [nodeId])).filter(a => a);
export const getNodesByIdAsLookup = (state, nodeIds) => keyBy(nodeIds.map(nodeId => getNodeOrError(state, nodeId)), "id");
export const getNodeByProperty = (state, nodeOrNodeId, property) => {
    let node;
    if (typeof nodeOrNodeId === 'object') {
        let fromNode = nodeOrNodeId;
        let nodeId = nodeOrNodeId[property];
        node = state.nodes[nodeId];
        if (node === undefined) {
            let rootNode = getRootNodeOrNull(fromNode.id) || fromNode;
            let msg;
            if (rootNode === fromNode) {
                msg = `${rootNode.type} [${rootNode.id}] with name [${rootNode.name || rootNode.title || 'No Name'}] has property [${fromNode.type}.${property}] that references a node [${nodeId}] that doesn't exist`;
            } else {
                msg = `${rootNode.type} [${rootNode.id}] with name [${rootNode.name || rootNode.title || 'No Name'}] has a child node [${fromNode.id}] with name [${fromNode.name || fromNode.title || 'No name'}] has property [${fromNode.type}.${property}] that references node [${nodeId}] that doesn't exist`;
            }
            throw new Error(msg);
        }
    } else {
        node = state.nodes[nodeOrNodeId];
        if (node === null) {
            throw new Error("Node with id " + nodeOrNodeId + " does not exist.");
        }
        node = getNodeByProperty(state, node, property);
    }
    return node;
};


export const getSchema = (state) => getGraph(state).schema;
export const getNodeSchemaOrNull = (state, nodeType) => get(getSchema(state), [nodeType]) || null;
export const getNodeSchemaOrError = (state, nodeType) => {
    let schema = getNodeSchemaOrNull(state, nodeType);
    if (!schema) {
        throw new Error(`Schema with name [${nodeType}] does not exist.`);
    }
    return schema;
};
export const getNodesByCondition = (state, condition) => filter(getNodes(state), condition);
export const getDirtiesById = (state, nodeIds) => nodeIds.map(nodeId => getDirty(state, nodeId));
export const getDirty = (state, nodeId) => get(getGraph(state), ['dirtyNodes', nodeId]);
export const getAllDirty = (state) => get(getGraph(state), ['dirtyNodes']);
export const getDirtyNodes = (state) => {
    let graph = getGraph(state);
    let dirtyNodeIds = get(graph, ['dirtyNodeIds']);
    let allDirty = get(graph, ['dirtyNodes']);
    let dirtyDirty = {};
    Object.entries(dirtyNodeIds).forEach(([key]) => dirtyDirty[key] = allDirty[key]);
    return dirtyDirty;
};
export const getDirtyNodesToSave = (state) => {
    let dirtyNodes = getDirtyNodes(state);
    return Object.values(dirtyNodes)
        .filter(dirtyNode => dirtyNode.saveAborted !== true);
};
export const getDirtyNodesSaveAborted = (state) => {
    let dirtyNodes = getDirtyNodes(state);
    return Object.values(dirtyNodes)
        .filter(dirtyNode => (dirtyNode.saveAborted === true));
};
export const hasAnyDirtyNodes = (state) => {
    let graph = getGraph(state);
    let dirtyNodeIds = get(graph, ['dirtyNodeIds']);
    return dirtyNodeIds != null && Object.keys(dirtyNodeIds).length > 0;
}
/**
 * Check if there are any changes on the specified node.
 *
 * Note: This will return false if nodeId is root, and change is on child.
 * @param state
 * @param nodeId
 * @returns {boolean}
 */
export const isNodeDirty = (state, nodeId) => {
    let graph = getGraph(state);
    let dirtyNodeIds = get(graph, ['dirtyNodeIds']);
    return dirtyNodeIds && !!dirtyNodeIds[nodeId];
}
export const isRootNodeDirty = (state, rootId) => {
    let graph = getGraph(state);
    let dirtyNodeIds = get(graph, ['dirtyRootIds']);
    return dirtyNodeIds && !!dirtyNodeIds[rootId];
}
export const buildLookup = (state, nodeIds) => {
    const items = [];
    for (let nodeId of nodeIds || []) {
        const node = getNodeOrNull(state, nodeId);
        if (node && node.deleted !== true) {
            items.push({id: node.rootId, name: node.name});
        }
    }
    items.sort((a, b) => a.name > b.name ? 1 : -1);
    return items;
};

export const getDescendantsAndSelfAsLookup = (state, procedureNodeId) => {
    return keyBy(getDescendantsAndSelf(state, procedureNodeId), 'id');
};

export const getActiveDescendantsAndSelfAsLookup = (state, procedureNodeId) => {
    return keyBy(getActiveDescendantsAndSelfIfPresent(state, procedureNodeId), 'id');
};

export const getRootNodeOrError = (state, nodeId) => {
    let node = getNodeOrError(state, nodeId);
    if (node.rootId) {
        return getNodeOrError(state, node.rootId);
    }
    while (node && node.parentId != null) {
        node = getNodeOrError(state, node.parentId);
    }
    return node;
};

export const getRootNodeOrNull = (state, nodeId) => {
    let node = getNodeOrNull(state, nodeId);
    if (!node) {
        throw reportError(`Node [${nodeId}] does not exist in state. Cannot determine it's root node.`);
    }
    if (node.rootId) {
        return getNodeOrNull(state, node.rootId);
    }
    while (node && node.parentId != null) {
        node = getNodeOrNull(state, node.parentId);
    }
    return node;
};
export const getNodeWithChildren = (state, rootNode) => {
    let nodes = [];
    if (rootNode.children) {
        for (let childId of rootNode.children) {
            let childNode = getNodeOrNull(state, childId);
            nodes.push(childNode);
            nodes.push(...getNodeWithChildren(state, childNode));
        }
    }
    return nodes;
};
export const getAncestorNodesAndSelf = (state, rootNode) => {
    let nodes = [rootNode];
    let currentNode = rootNode;
    if (!currentNode) return [];
    while (currentNode?.parentId !== currentNode?.id) {
        let parentNode = getNodeOrNull(state, currentNode.parentId);
        if (!parentNode) break;
        nodes.unshift(parentNode);
        currentNode = parentNode;
    }
    return nodes;
};
export const getNodesOrNull = (state, nodeIds) => {
    let missingNodeIds = nodeIds.filter(id => !getNodeOrNull(state, id));
    if (missingNodeIds.length > 0) {
        return null;
    }
    let okNodeIds = nodeIds.filter(id => getNodeOrNull(state, id));
    return okNodeIds.map(id => getNodeOrNull(state, id));
};
export const getNodesIfPresent = (state, nodeIds) => {
    return (nodeIds || []).map(id => getNodeOrNull(state, id)).filter(a => a !== null);
};
export const getNodesOrError = (state, nodeIds, node) => {
    let nodes = nodeIds.map(id => getNodeOrNull(state, id));
    let missingNodeIds = nodeIds.filter((a, i) => nodes[i] === null);
    if (missingNodeIds.length > 0) {
        if (node) {
            throw reportError('Referenced nodes are missing: ' + missingNodeIds.join(',') + ' from node ' + node.type + ' ' + node.id + ' ' + node.name, null, node);
        } else {
            throw reportError('Referenced nodes are missing: ' + missingNodeIds.join(','));
        }
    }
    return nodes;
};
export const getActiveNodesOrError = (state, nodeIds, node) => {
    let allNodes = getNodesOrError(state, nodeIds, node);
    return allNodes.filter(item => item.deleted !== true);
};

export const getNodesSafe = (state, nodeIds, node) => {
    const nodes = getNodes(state);
    let results = [];
    let missingNodeIds = [];
    for (let id of nodeIds || []) {
        const n = nodes[id];
        if (n) {
            results.push(n);
        } else {
            missingNodeIds.push(id);
        }
    }
    if (missingNodeIds.length > 0) {
        console.info('Referenced nodes are not loaded: ' + missingNodeIds.join(',') + ' from node ' + node.type + ' ' + node.id + ' ' + node.name, null, node);
    }
    return results;
};
export const getVisibleNodesSafe = (state, nodeIds, node) => {
    let allNodes = getNodesSafe(state, nodeIds, node);
    return allNodes.filter(item => item.visible === true && item.deleted === false);
};
export const getChildrenSafe = (state, node) => {
    return getNodesSafe(state, node.children, node);
};
export const getChildrenOrNull = (state, node) => {
    return getNodeOrNull(state, node.children, node);
};
export const getActiveChildrenSafe = (state, node) => {
    let allNodes = getNodesSafe(state, node.children, node);
    return allNodes.filter(item => item.deleted !== true);
};
export const getChildrenOrError = (state, node) => {
    return getNodesOrError(state, node.children, node);
};
export const getActiveChildrenOrError = (state, node) => {
    return getActiveOrError(state, node, 'children');
};
export const getActiveOrError = (state, node, property) => {
    let allNodes = getNodesOrError(state, node[property], node);
    return allNodes.filter(item => item.deleted !== true);
};
export const getActivesNodesSafe = (state, nodeIds, node) => {
    let allNodes = getNodesSafe(state, nodeIds, node);
    return allNodes.filter(item => item.deleted !== true);
};

export const getActiveChildrenDescendants = (state, nodeId) => {
    const node = getNodeOrError(state, nodeId);
    if (node.deleted === true) {
        return [];
    }
    let next = [...node.children || []];
    return [...next.flatMap(a => getActiveChildrenDescendantsAndSelf(state, a))];
};
export const getActiveChildrenDescendantsAndSelf = (state, nodeId) => {
    const node = getNodeOrError(state, nodeId);
    if (node.deleted === true) {
        return [];
    }
    let next = [...node.children || []];
    return [node, ...next.flatMap(a => getActiveChildrenDescendantsAndSelf(state, a))];
};
export const getChildrenDescendantsAndSelf = (state, nodeId) => {
    const node = getNodeOrError(state, nodeId);
    let next = [...node.children || []];
    return [node, ...next.flatMap(a => getChildrenDescendantsAndSelf(state, a))];
};
export const getActiveDescendantsSafe = (state, nodeId) => {
    const node = getNodeOrNull(state, nodeId);
    if (!node || node.deleted === true) {
        return [];
    }
    let next = [...node.children || [], ...node.rules || [], ...node.links || [], ...node.assignments || []];
    return [...next.flatMap(a => getActiveDescendantsAndSelf(state, a))];
};
export const getActiveDescendants = (state, nodeId) => {
    const node = getNodeOrError(state, nodeId);
    if (node.deleted === true) {
        return [];
    }
    let next = [...node.children || [], ...node.rules || [], ...node.links || [], ...node.assignments || []];
    return [...next.flatMap(a => getActiveDescendantsAndSelf(state, a))];
};
export const getNodesActiveDescendants = (state, nodeIds) => {
    let descendantsById = {};
    nodeIds.forEach(nodeId => {
        const node = getNodeOrError(state, nodeId);
        let list;
        if (node.deleted === true) {
            list = [];
        } else {
            let next = [...node.children || [], ...node.rules || [], ...node.links || [], ...node.assignments || []];
            list = [...next.flatMap(a => getActiveDescendantsAndSelf(state, a))];
        }
        descendantsById[nodeId] = list;
    });
    return descendantsById;
};
export const getActiveDescendantsAndSelf = (state, nodeId) => {
    const node = getNodeOrError(state, nodeId);
    if (node.deleted === true) {
        return [];
    }
    let next = [...node.children || [], ...node.rules || [], ...node.links || [], ...node.assignments || []];
    return [node, ...next.flatMap(a => getActiveDescendantsAndSelf(state, a))];
};
export const getMatchingExecutionNodeToProcedureNode = (state, nodeId, procedureNodeId) => {
    const executionNodes = getDescendantsAndSelf(state, nodeId);
    return executionNodes.find((node) =>
        node.procedureQuestionId === procedureNodeId ||
        node.procedureTaskId === procedureNodeId ||
        node.procedureStepId === procedureNodeId ||
        node.procedureId === procedureNodeId);
}

export const getDescendantsAndSelf = (state, nodeId) => {
    const nodes = [];
    const useNodeId = (nodeId && nodeId.id) || nodeId;
    getDescendantsAndSelfInnerRoot(state, useNodeId, nodes);
    return nodes;
};
const getDescendantsAndSelfInnerRoot = (state, nodeId, nodes) => {
    const node = getNodeOrError(state, nodeId);
    nodes.push(node);
    for (let id of node.children || EMPTY_ARRAY) {
        getDescendantsAndSelfInnerChild(state, id, nodes);
    }
    for (let id of node.rules || EMPTY_ARRAY) {
        nodes.push(getNodeOrError(state, id))
    }
    for (let id of node.links || EMPTY_ARRAY) {
        nodes.push(getNodeOrError(state, id))
    }
    for (let id of node.assignments || EMPTY_ARRAY) {
        nodes.push(getNodeOrError(state, id))
    }

    for (let id of node.schemas || EMPTY_ARRAY) {
        getDescendantsAndSelfInnerChild(state, id, nodes, "properties");
    }
};

const getDescendantsAndSelfInnerChild = (state, nodeId, nodes, field = "children") => {
    const node = getNodeOrError(state, nodeId);
    nodes.push(node);
    for (let id of node[field] || EMPTY_ARRAY) {
        getDescendantsAndSelfInnerChild(state, id, nodes, field);
    }
};
export const getActiveDescendantsAndSelfIfPresent = (state, nodeId) => {
    const node = getNodeOrNull(state, nodeId);
    if (!node || node.deleted === true) {
        return [];
    }
    let next = [...node.children || [], ...node.rules || [], ...node.links || [], ...node.assignments || []];
    return [node, ...next.flatMap(a => getActiveDescendantsAndSelfIfPresent(state, a))];
};
export const getDescendantsAndSelfIfPresent = (state, nodeId) => {
    const node = getNodeOrNull(state, nodeId);
    if (!node) {
        return [];
    }
    let next = [...node.children || [], ...node.rules || [], ...node.links || [], ...node.assignments || []];
    return [node, ...next.flatMap(a => getActiveDescendantsAndSelfIfPresent(state, a))];
};
export const getMainDescendantsAndSelfIfPresent = (state, nodeId) => {
    const node = getNodeOrNull(state, nodeId);
    if (!node) {
        return [];
    }
    let next = [...node.children || []];
    return [node, ...next.flatMap(a => getMainDescendantsAndSelfIfPresent(state, a))];
};
export const getDeepClonedNodeOrError = (state, nodeOrNodeId) => {
    let node;
    if (typeof nodeOrNodeId === 'string') {
        node = getNodeOrError(state, nodeOrNodeId);
    } else {
        node = nodeOrNodeId;
    }
    return cloneDeep(node);
};
export const getShallowClonedNodeOrError = (state, nodeOrNodeId) => {
    let node;
    if (typeof nodeOrNodeId === 'string') {
        node = getNodeOrError(state, nodeOrNodeId);
    } else {
        node = nodeOrNodeId;
    }
    return shallowClone(node);
};
export const shallowClone = node => {
    if (node == null) {
        return null;
    }
    return Object.assign({}, node);
}
export const getRootNodes = (state) => {
    let nodes = getNodes(state);
    return Object.values(nodes).filter(a => a.rootId === a.id);
};
export const getAncestorNodeByTypeOrNull = (state, node, type, untilType = NODE_TYPE_OPTIONS.ExecutionRoot) => {
    let currentNode = node;
    while (currentNode?.type !== type && currentNode?.type !== untilType && node.rootId !== currentNode?.id && !!currentNode.parentId) {
        currentNode = getNodeOrNull(state, currentNode.parentId);
    }
    return currentNode?.type === type ? currentNode : null;
}

export const getFirstAppliedNodeByRule = (state, ruleNode) => {
    let currentNode = ruleNode.nodeIds.length ? getNodeOrNull(state, ruleNode.nodeIds[0]) : null;
    while (currentNode?.type === NODE_TYPE_OPTIONS.ExecutionRule && currentNode?.nodeIds?.length) {
        currentNode = getNodeOrNull(state, currentNode.nodeIds[0]);
    }
    return currentNode.type !== NODE_TYPE_OPTIONS.ExecutionRule ? currentNode : null;
}
export const getReportData = (state, nodeId) => {
    if (!nodeId) {
        return {};
    }
    let reportData = {};
    const node = getNodeOrError(state, nodeId);
    if (node.type.startsWith('Procedure') || node.type.startsWith('Execution')) {
        if (node.id.startsWith('ExecutionSummary-')) {
            reportData.executionId = node.rootId;
            reportData.executionKey = node.key;
            let parentData = node.parents?.[0];
            reportData.procedureId = parentData?.procedureId || node.procedureId;
            if (parentData?.procedureName) {
                reportData.procedureName = parentData?.procedureName;
            }
        } else {
            const rootNode = getRootNodeOrError(state, nodeId);
            let procedureNode;
            if (rootNode.type === NODE_TYPE_OPTIONS.ExecutionRoot) {
                reportData.executionId = rootNode.id;
                reportData.procedureId = rootNode.procedureId;
                reportData.procedureName = rootNode.name;
                if (rootNode.key) {
                    reportData.executionKey = rootNode.key;
                }
                if (rootNode.category) {
                    reportData.procedureCategory = rootNode.category;
                }
                procedureNode = getNodeOrNull(state, rootNode.procedureId);
            }
            if (rootNode.type === NODE_TYPE_OPTIONS.ProcedureRoot) {
                procedureNode = rootNode;
            }
            if (procedureNode) {
                reportData.procedureId = procedureNode.id;
                reportData.procedureName = procedureNode.name;
                if (procedureNode.category) {
                    reportData.procedureCategory = procedureNode.category;
                }
            }
        }
    } else {
        if (node.type === NODE_TYPE_OPTIONS.NodeAssignment || node.type === NODE_TYPE_OPTIONS.NodeActivity) {
            const {procedureId, rootId, rootKey} = node;
            reportData = {
                procedureId,
                executionId: rootId,
                executionKey: rootKey,
            }
        }

        if (node.type === NODE_TYPE_OPTIONS.ProjectRoot) {
            const {id, name} = node;
            reportData = {
                projectId: id,
                projectName: name,
            }
        }
    }
    return reportData;
}
export const getFirstTaskOrNull = (state, nodeId) => {
    let procedureNode = getNodeOrNull(state, nodeId);
    let steps = getActiveNodesOrError(state, procedureNode.children)
    if (steps.length === 0) {
        return null;
    }
    let tasks = getActiveNodesOrError(state, steps[0].children)
    if (tasks.length === 0) {
        return null;
    }
    return tasks[0];
}

export const getClientConfig = (state) => {
    return getNodeOrNull(state, NODE_IDS.ClientConfig)
}