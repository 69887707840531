import React, {useMemo} from "react";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import {withStyles} from "@mui/styles";
import ExecutionActionGroupButton from "./ExecutionActionGroupButton";
import {useSelector} from "react-redux";
import ExecutionActionButton from "./ExecutionActionButton";
import {getActiveAvailableActionRules} from "../../../selectors/executionSelectors";

const useAvailableActiveActionRules = (executionId) =>
    useSelector((state) => getActiveAvailableActionRules(state, executionId), isEqual);

const ExecutionActions = ({ executionId, classes }) => {
    const rules = useAvailableActiveActionRules(executionId);

    const grouped = useMemo(() => {
        return groupBy(rules.filter((rule) => !!rule.groupRule?.calculateValue && !!rule.buttonRule?.calculateValue), (rule) => rule.groupRule.calculateValue);
    }, [rules]);

    const noGroup = useMemo(() => {
        return rules.filter((rule) => rule.groupRule?.calculateValue == null && rule.buttonRule?.calculateValue);
    }, [rules]);

    if (Object.keys(grouped).length === 0 && noGroup.length === 0) {
        return null;
    }

    return <div className={classes.actionsContainer} data-cy-element={`ExecutionActions`}>
        {<>
            {noGroup.map(({executionRule, buttonRule}) => {
                return <ExecutionActionButton
                    key={buttonRule.id}
                    executionId={executionId}
                    ruleId={executionRule.id}
                    procedureId={executionRule.createExecutionProcedureId}
                    linkType={executionRule.createExecutionLinkType}
                    buttonLabel={buttonRule.calculateValue}
                />
            })}
        </>}
        {Object.entries(grouped).map(([groupName, groupedRules]) => {
            if (groupedRules.length === 0) return null;
            return <ExecutionActionGroupButton key={groupName} data-cy={`execution-actions-group-${groupName.toString().toLowerCase()}`} executionId={executionId} group={groupName} rules={groupedRules} />
        })}
    </div>
}

const styles = () => ({
    actionsContainer: {
        display: 'flex',
        columnGap: '4px',
    },
})

export default withStyles(styles)(ExecutionActions);