import React, {useMemo} from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodesById} from "../../../selectors/graphSelectors";
import {connect} from "react-redux";
import groupBy from "lodash/groupBy";
import {Permissions} from "../../../permissions";
import {SharedAuth} from "tbf-react-library";
import {getExecutionFullSummaryOrNull} from "../../../selectors/executionSelectors";
import ExecutionShowLinkGroup from "./ExecutionShowLinkGroup";
import {NODE_IDS} from "../../../reducers/graphReducer";

const ExecutionShowLinks = ({executionId, links, linkedExecutions, canRemoveLink}) => {

    const sortLinkType = useMemo(() => {
        if (links == null || links.length === 0) {
            return null;
        }
        let linksByType = groupBy(links, "toNodeName");
        return Object.entries(linksByType).sort((a, b) => {
            return (b[1].filter(d => d.linkType === 'parent').length - a[1].filter(d => d.linkType === 'parent').length) || (a[0].toLowerCase().localeCompare(b[0].toLowerCase()))
        });
    }, [links]);

    return (<React.Fragment>
        <div data-cy={'Links'}>
            {sortLinkType && sortLinkType.map(([type, list]) => (<ExecutionShowLinkGroup executionId={executionId}
                                                                                         key={type}
                                                                                         type={type}
                                                                                         list={list}
                                                                                         linkedExecutions={linkedExecutions}
                                                                                         canRemoveLink={canRemoveLink}/>))}
        </div>

    </React.Fragment>)
}

const styles = () => ({});
ExecutionShowLinks.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let execution = getNodeOrNull(state, ownProps.executionId);
    const userDevice = getNodeOrNull(state, NODE_IDS.UserDevice);
    const troubleShootOn = userDevice?.troubleshootOn;
    // Hide any link missing its name/title as this might be auto-created link for a select an item the user cannot see.
    // Plus a user seeing undefined looks crap
    let links = getNodesById(state, execution.links || []).filter(a => a && (troubleShootOn || a.deleted !== true) && a.toNodeName);
    let linkedExecutions = {};
    let visibleLinks = [];
    for (let link of links) {
        // Getting full first as when auto-deleted we mark full as deleted and leave summary alone
        let toNode = getExecutionFullSummaryOrNull(state, link.toNodeId);
        linkedExecutions[link.toNodeId] = toNode;

        // Lets hide deleted so when deleted from data grid they disappear but if not sure show em
        if ((execution?.deleted === true || !toNode?.deleted || troubleShootOn) && !toNode?.draft) {
            visibleLinks.push(link);
        }
    }
    return {
        links: visibleLinks.length === 0 ? null : visibleLinks,
        linkedExecutions: visibleLinks.length === 0 ? null : linkedExecutions,
        canRemoveLink: SharedAuth.userHasPermission(Permissions.execution.link)
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionShowLinks));
