import React from "react";
import withStyles from '@mui/styles/withStyles';
import PanelSection from "../../components/PanelSection";
import ProcedureRuleFilterEditor from "../rules/ProcedureRuleFilterEditor";
import ProcedureRuleToggleEditor from "../rules/ProcedureRuleToggleEditor";
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";

const ProcedureRuleCollectionColumn = ({disabled, collectionRuleId}) => {
    return (
        <PanelSection>
            <ProcedureRuleToggleEditor
                ruleId={collectionRuleId}
                actionType={RULE_ACTION_TYPE.filter.id}
                disabled={disabled}
            />
            <ProcedureRuleFilterEditor parentRuleId={collectionRuleId} disabled={disabled}/>
        </PanelSection>
    );
}
const styles = () => ({});
export default withStyles(styles)(ProcedureRuleCollectionColumn);