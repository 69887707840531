import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import WifiIcon from '@mui/icons-material/WifiRounded';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0BarRounded';
import {cypress, SharedAuth} from "tbf-react-library";
import IconButton from "@mui/material/IconButton";
import {isOnline} from '../../util/util';
import GraphMobileListItem from './GraphMobileListItem';
import {strings} from "../components/SopLocalizedStrings";
import {toast} from "react-toastify";
import {updateInternetAvailableOverride} from "../../middleware/onlineMiddleware";
import {DIAGNOSTIC_MODES, NODE_IDS} from '../../reducers/graphReducer';
import {getNodeOrNull} from '../../selectors/graphSelectors';

class GraphOfflineToggle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAppOnline: false
        };
        this.toggleOffline = this.toggleOffline.bind(this);
    }

    componentDidMount() {
        this.setState({
            isAppOnline: isOnline()
        });

        SharedAuth.addLoginListener(this.onChangeUserEvent);
        window.addEventListener('storage', this.onChangeUserEvent);
    };

    componentWillUnmount() {
        SharedAuth.removeLoginListener(this.onChangeUserEvent);
        window.removeEventListener('storage', this.onChangeUserEvent);
    }

    onChangeUserEvent = () => {
        this.setState({
            isAppOnline: isOnline()
        })

    }

    toggleOffline() {
        if(SharedAuth.hasTokenExpiredFlag()) {
            toast.error("Cannot go online as token has expired");
            return;
        }

        const {isAppOnline} = this.state;
        updateInternetAvailableOverride(!isAppOnline)
        this.setState({isAppOnline: !isAppOnline});
    }

    render() {
        let {classes, display, isListItem} = this.props;
        let {isAppOnline} = this.state;
        return <>
            {
                display && !isListItem &&
                <IconButton
                    size={'small'}
                    title={isAppOnline ? strings.menu.offline.onlineLabel : strings.menu.offline.offlineLabel}
                    onClick={this.toggleOffline} data-cy={'GoOfflineModeButton'}>
                    <GraphMobileListItem>
                        {
                        isAppOnline ? <WifiIcon className={classes.buttonIcon}/> :
                            <SignalWifi0BarIcon className={classes.buttonIcon}/>
                        }
                        {
                            isAppOnline &&
                            <span className={'menuItemTitle'}>{strings.menu.offline.onlineLabel}</span>
                        }
                        {
                            !isAppOnline &&
                            <span className={'menuItemTitle'}>{strings.menu.offline.offlineLabel}</span>
                        }
                    </GraphMobileListItem>
                  
                </IconButton>
            }
        </>
    }
}

const styles = () => ({});
GraphOfflineToggle.propTypes = {};
const mapStateToProps = (state) => {
    const {diagnosticMode, troubleshootOn} = getNodeOrNull(state, NODE_IDS.UserDevice) ?? {};
    const diagnosticModeOn = diagnosticMode === DIAGNOSTIC_MODES.full.id;
    const isCypress = cypress.isCypress();
    return {
        display: diagnosticModeOn || isCypress || troubleshootOn,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphOfflineToggle));
