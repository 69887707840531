// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

import axios from "axios";
import {BUILD_NUMBER, PUBLIC_URL, reportError, reportInfo} from "tbf-react-library";

export const isLocalhost = Boolean(
    window.location.hostname === 'local.worq.com.au' ||
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]'
);
const forceUseServiceWorker = false;
export default function register() {
    if (process.env.NODE_ENV !== 'production' && !forceUseServiceWorker) {
        console.log('registerServiceWorker: Offline ability is not supported as this is not production.');
    } else if (!('serviceWorker' in navigator)) {
        console.log('registerServiceWorker: Offline ability is not supported as the browser does not support it.');
    } else {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(PUBLIC_URL, window.location);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
            console.log('registerServiceWorker: Offline ability is unavailable due to origin mismatch');
            return;
        }

        window.addEventListener('load', () => {
            const swUrl = `${PUBLIC_URL}/sw.js`;

            if (isLocalhost && !forceUseServiceWorker) {
                // This is running on localhost. Lets check if a service worker still exists or not.
                checkValidServiceWorker(swUrl);

                // Add some additional logging to localhost, pointing developers to the
                // service worker/PWA documentation.
                navigator.serviceWorker.ready.then(() => {
                    console.log(
                        'registerServiceWorker: This web app is being served cache-first by a service ' +
                        'worker. To learn more, visit https://goo.gl/SC7cgQ'
                    );
                });
            } else {
                // Is not local host. Just register service worker
                registerValidSW(swUrl);
            }
        });
    }
}
const getVersionUrl = () => `${PUBLIC_URL}/version.json?v=${new Date().getTime()}`;
const getIndexUrl = () => `${PUBLIC_URL}/index.html`;
const getIndexCachedUrl = () => `${PUBLIC_URL}/index.html`;

function registerValidSW(swUrl) {
    console.debug('registerServiceWorker: Registering serviceWorker URL [' + swUrl + ']');
    navigator.serviceWorker
        .register(swUrl, {scope: process.env.PUBLIC_PATH})
        .then(registration => {
            registration.onupdatefound = (e) => {

                let target = e.target;
                const installingWorker = target.installing;
                if (installingWorker) {
                    installingWorker.onstatechange = (e) => checkInstalled(e.target);
                    checkInstalled(installingWorker);
                } else {
                    // It seems installing is not being set for some reason. In this case
                    // lets try reloading all the same
                    checkInstalled(target.active);
                }
            };

            let initialIndexHtmlResponse = null;

            axios.get(getIndexUrl()).then(response => {
                initialIndexHtmlResponse = response.data;
            });

            // By default SW doesn't check for new versions all that often
            // This will make it look every 5 minutes
            let checkUpdate = () => {
                if (window.navigator.onLine && registration.active) {
                    // Doing this myself as it breaks in cypress headless due to sw thinking there is an update when there is not and hence reloading
                    // Also this will be a smaller response than the 2kb sw.js
                    const url = getVersionUrl();
                    axios.get(url).then(response => {
                        let buildNumber = response.data.buildNumber;
                        let different = BUILD_NUMBER !== buildNumber;
                        if (different) {
                            console.info('registerServiceWorker: Upgrade: Current build number ' + BUILD_NUMBER + ' does not match that on the server of ' + buildNumber + '. Will get service worker to check for a new version.');
                            registration.update();
                            axios.get(getIndexCachedUrl()).then(response => {
                                if (response.data !== initialIndexHtmlResponse) {
                                    console.info('registerServiceWorker: Upgrade: Index.html is different.')
                                } else {
                                    console.info('registerServiceWorker: Upgrade: Index.html is the same.')
                                }
                            });
                        }
                    });
                }

                // If there is a waiting service worker lets use that
                if (registration.waiting) {
                    registration.waiting.postMessage({type: 'SKIP_WAITING'})
                    reloadApp('waiting');
                }
            }

            window.setInterval(checkUpdate, 1000 * 60 * 5);
            checkUpdate();

            // reload once when the new Service Worker starts activating
            navigator.serviceWorker.addEventListener('controllerchange', (e) => {
                console.info('registerServiceWorker: Service worker controllerchange event fired', e);
                reloadApp('controllerchange');
            });          
          })
        .catch(error => {
            reportError('Error during service worker registration:', error);
        });
}

let checkInstalled = (sw) => {
    if (sw.state === 'installed') {
        sw.postMessage({type: 'SKIP_WAITING'})
        if (navigator.serviceWorker.controller) {
            // At this point, the old content will have been purged and
            // the fresh content will have been added to the cache.
            // It's the perfect time to display a "New content is
            // available; please refresh." message in your web app.
            reloadApp('installed');
        } else {
            // At this point, everything has been precached.
            // It's the perfect time to display a
            // "Content is cached for offline use." message.
            reportInfo('App is cached for offline use.');
        }
    } else {
        console.info('registerServiceWorker: Service Worker state is ' + sw.state + '. Not reloading just yet.')
    }
};
let getSwState = () => navigator?.serviceWorker?.controller?.state || 'No Controller';
let refreshing = false;
let reloadApp = (source) => {
    const url = getVersionUrl();
    axios.get(url).then(response => {
        let buildNumber = response.data.buildNumber;
        let different = BUILD_NUMBER !== buildNumber;
        if (different) {
            console.info('registerServiceWorker: Upgrade: Reloading page due to: ' + source);
            console.info('registerServiceWorker: Upgrade: Current build number ' + BUILD_NUMBER + ' does not match that on the server of ' + buildNumber + '. Will reload.');
            if (refreshing) return;
            refreshing = true;
            reportInfo('App has been updated. This page will refresh in 5 seconds.');
            console.info('registerServiceWorker: Upgrade: App has been updated. This page will refresh in 5 seconds. Controller State: ' + getSwState());
            setInterval(() => {
                console.info('registerServiceWorker: Upgrade: Reloading now page due to: ' + source + ' Controller State: ' + getSwState());
                window.location.reload();
            }, 5000);
        } else {
            console.info('registerServiceWorker: Upgrade: Not reloading as same build number: ' + buildNumber);
        }
    });
}

function checkValidServiceWorker(swUrl) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl)
        .then(response => {
            // Ensure service worker exists, and that we really are getting a JS file.
            if (
                response.status === 404 ||
                response.headers.get('content-type').indexOf('javascript') === -1
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then(registration => {
                    registration.unregister().then(() => {
                        console.info('registerServiceWorker: Upgrade: Reloading as no service worker');
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl);
            }
        })
        .catch(() => {
            reportInfo('No internet connection found. App is running in offline mode.');
        });
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
        });
    }
}
