import React from "react";
import {Permissions} from "../../../permissions";
import {TbfAction} from "tbf-react-library";
import {strings} from "../../components/SopLocalizedStrings";
import TrackChangesIcon from '@mui/icons-material/TrackChangesRounded';
import AlphaFeature from "../../components/AlphaFeature";
import {useHasProcedurePermission} from "../../../hooks/procedureHooks";

const ProcedureVersionsAction = React.forwardRef(({procedureId, menuItem}, ref) => {
    const canView = useHasProcedurePermission(procedureId, Permissions.procedure.read)
    if (!canView) {
        return null
    }
    return (
        <AlphaFeature>
            <TbfAction
                menuItem={menuItem}
                title={strings.procedure.show.viewVersions}
                dataCy='view-history'
                iconComponent={TrackChangesIcon}
                path={'/procedures/' + procedureId + '/versions'}
                ref={ref}
            >
                {strings.execution.show.viewVersions}
            </TbfAction>
        </AlphaFeature>
    )
})
export default ProcedureVersionsAction;
