import React from 'react';
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {MINUTES_15} from "../../util/constants";
import {strings} from "../components/SopLocalizedStrings";
import {NODE_IDS} from "../../reducers/graphReducer";
import {useNodeOrNull} from "../../hooks/nodeHooks";
import {Loader} from "tbf-react-library";

const pageStrings = strings.project.show;

export const ProjectOfflineLoad = ({projectId}) => {

    const photoPath = NODE_IDS.PhotosForExecution(null, projectId)
    const photoResource = useNodeOrNull(photoPath)
    const executionsForProjectPath = NODE_IDS.ExecutionsForProject(projectId)
    const executionsForProjectResource = useNodeOrNull(photoPath)
    const offlineLoading = !photoResource?.loaded || !executionsForProjectResource.loaded

    return (
        <React.Fragment>
            {
                offlineLoading && <Loader friendlyName={pageStrings.offlineExecutionDownload}/>
            }
            <GraphResourceLoad
                resourcePath={photoPath}
                nodeType={'Photo'}
                friendlyName={pageStrings.offlinePhotoDownload}
                reloadIntervalMs={MINUTES_15}
                offline={true}
                hideLoader={true}
            />
            <GraphResourceLoad
                resourcePath={executionsForProjectPath}
                nodeType={'ExecutionRoot'}
                friendlyName={pageStrings.offlineExecutionDownload}
                reloadIntervalMs={MINUTES_15}
                offline={true}
                hideLoader={true}
            >
                <div className={'alert alert-info'}>
                    {strings.project.show.offlineMessage}
                </div>
            </GraphResourceLoad>
        </React.Fragment>
    )
}