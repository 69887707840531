import {saveTile} from "leaflet.offline";
import {downloadTileSync, getTileDataOrError} from "./TbfTileManager";

(function (factory, window) {
    // define an AMD module that relies on 'leaflet'
    if (typeof exports === 'object') {
        module.exports = factory(require('leaflet'));
    }

    // attach your plugin to the global 'L' variable
    if (typeof window !== 'undefined' && window.L && !window.L.TileCacheFirst) {
        factory(window.L);
    }
}(function (L) {

    L.TileCacheFirst = {
        previousMethods: {
            createTile: L.TileLayer.prototype.createTile
        }
    };

    L.TileLayer.include({
        /** @lends  TileLayerOffline */
        /**
         * Create tile HTMLElement
         * @private
         * @param  {object}   coords x,y,z
         * @param  {Function} done
         * @return {HTMLElement}  img
         */
        createTile: function (coords, done) {
            var this$1 = this;
            var error;
            var tile = L.TileCacheFirst.previousMethods.createTile.call(this, coords, function () {
            });
            var url = tile.src;
            let templateUrl = this._url;
            // ESRI stores config in url
            templateUrl = templateUrl?.url || templateUrl;
            let tileInfo = {
                z: this._getZoomForUrl(),
                ...coords,
                key: url,
                urlTemplate: templateUrl,
                retrievedDate: new Date().toISOString()
            };
            tile.src = '';
            let cacheSettings = this.options.cacheTiles;

            // If you add this immediately the images flicker, as I assume src="" is considered loaded.
            const addEventListeners = () => {
                L.DomEvent.on(tile, 'error', L.Util.bind(this$1._tileOnError, this$1, done, tile));
                L.DomEvent.on(tile, 'load', () => {
                    this$1._tileOnLoad.call(this$1, done, tile);
                    if (dataUrl) {
                        //URL.revokeObjectURL(dataUrl);
                    }
                });
            }

            // When image is loaded release the data url
            // Not 100% we really need to do this, just seems sensible
            let dataUrl = null;

            if (!cacheSettings && !this.options.offline) {
                addEventListeners();
                tile.src = url;
                return tile;
            }

            const renewAfterSeconds = cacheSettings?.renewAfterSeconds || 604800;

            let downloadTileNow = (raiseEvents) => () => downloadTileSync(url, this.options)
                .then((data) => {
                    dataUrl = URL.createObjectURL(data);
                    raiseEvents && addEventListeners();
                    tile.src = dataUrl;
                    return saveTile(tileInfo, data);
                }).catch(error => {
                    this$1._tileOnError(done, tile, error);
                });
            // Try indexeddb first
            getTileDataOrError(url)
                .then(function (tileData) {
                    // A user had tiles jumble. No idea if this is the issue, but lets see ...
                    if (tileData.x !== tileInfo.x || tileData.y !== tileInfo.y || tileData.z !== tileInfo.z) {
                        downloadTileNow(true)
                        return
                    }
                    dataUrl = URL.createObjectURL(tileData.blob);
                    addEventListeners();
                    tile.src = dataUrl;
                    done(error, tile);
                    // Re-download the file and update src
                    let tileDate = new Date(tileData.retrievedDate || 0).getTime()
                    let tileAgeMs = new Date().getTime() - tileDate;
                    let redownloadAfter = tileAgeMs > renewAfterSeconds * 1000;
                    if (redownloadAfter) {
                        downloadTileNow(false)
                    }
                })
                .catch((e) => {
                    downloadTileNow(true)();
                })
            return tile;
        },
    });

}, window));