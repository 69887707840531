import React from "react";
import {Panel} from "tbf-react-library";
import {useProcedureReferences} from "../../../hooks/procedureHooks";
import ViewTemplateButton from "../../workItems/ViewTemplateButton";
import List from "@mui/material/List";
import ProcedureReferencesListItems from "./ProcedureReferencesListItems";

const ProcedureReferences = ({procedureId}) => {
    const references = useProcedureReferences(procedureId)
    const renderPanelActions = (refId) => () => {
        return <>
            <ViewTemplateButton procedureId={refId}/>
        </>
    }
    return (<>
        <Panel title={'References'}>

            This template references {Object.values(references).length} other templates.
        </Panel>
        {
            Object.values(references).map(ref => (
                <Panel key={ref.procedureId} title={ref.procedureName} cypressElement={'Collection'}
                       renderPanelActions={renderPanelActions(ref.procedureId)}>
                    Used in rules:
                    <List dense>
                        {Object.keys(ref.ruleReferencesByNodeIds).map((nodeId) => {
                            const {name, index, rules} = ref.ruleReferencesByNodeIds[nodeId];

                            return <ProcedureReferencesListItems
                                nodeId={nodeId}
                                name={name}
                                index={index}
                                rules={rules}
                            />
                        })}
                    </List>
                </Panel>))
        }
        <Panel title={'Referenced by'}>

            This template is referenced by TODO other templates.
        </Panel>
    </>)
}
export default ProcedureReferences;
