import React from "react";
import withStyles from '@mui/styles/withStyles';
import WorkItemCard from "./WorkItemCard";
import EmptyMessage from "../dashboard/EmptyMessage";
import {strings} from "../components/SopLocalizedStrings";
import cn from 'classnames'
import {useNodeOrNull} from "../../hooks/nodeHooks";
import {useCallbackBlurredItem, useCallbackFocusedItem} from "./ExecutionViewHooks";

const MobileView = ({workItems, classes, className, selectorId}) => {
    const selector = useNodeOrNull(selectorId)
    const loading = selector?.isLoading
    const handleItemFocused = useCallbackFocusedItem(selectorId)
    const handleItemBlurred = useCallbackBlurredItem(selectorId)
    return <React.Fragment>
        <div className={cn(classes.container, className)}>
            {workItems.length === 0 &&
            <EmptyMessage message={loading ? strings.app.loading + '...' : strings.execution.workItems.emptyMessage}/>
            }
            {workItems.map((workItem, index) => (
                <WorkItemCard
                    key={index}
                    selectorId={selectorId}
                    id={workItem.id}
                    onMouseEnter={handleItemFocused}
                    onMouseLeave={handleItemBlurred}
                />
            ))}
        </div>
    </React.Fragment>
}

const styles = () => ({
    container: {
        width: '100%',
        padding: '5px 10px 10px 10px',
        background: '#FFF',
    },
});

export default withStyles(styles)(MobileView);