import React, { Suspense } from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodesIfPresent} from "../../../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../../../style/alert.css';
import {ComponentBase} from "tbf-react-library";
import {EMPTY_ARRAY} from "../../../../util/util";
import Grid from "@mui/material/Grid";
import withDraftJs from "./LazyDraftJs";
import MessageType from "../../../components/MessageType";

class ExecutionQuestionIssueContainer extends ComponentBase {

    render() {
        let {rules, issueDescription, children, resolvedEnabled, issueType, classes, ContentState, convertToRaw} = this.props;
        const haveWarningToShow = issueType === 'warning' && (issueDescription || resolvedEnabled || rules.length > 0);
        if (!haveWarningToShow) {
            return null;
        }

        return (
            <Grid item xs={12}>
                    <div role="alert">
                        {
                            (issueDescription || rules.length > 0) &&
                            <React.Fragment>
                                {
                                    issueDescription &&
                                    <div className={classes.issueContainer}>
                                        <MessageType
                                            contentState={convertToRaw?.(ContentState?.createFromText(issueDescription))}
                                            messageType={issueType}/>
                                    </div>
                                }
                                {
                                    rules.map(item => (
                                        <div key={item.id} className={classes.issueContainer}>
                                            <MessageType
                                                contentState={item.raiseIssueMessage} messageType={issueType}/>
                                        </div>))
                                }
                            </React.Fragment>
                        }
                        {
                            children
                        }
                    </div>
                </Grid>
        );
    }
}

const styles = () => ({
    issueContainer: {
        marginBottom: 8
    }
});
ExecutionQuestionIssueContainer.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    if (!executionQuestionNode) {
        return {
            rules: []
        }
    }
    let activeRules = getNodesIfPresent(state, executionQuestionNode.ruleIds || EMPTY_ARRAY)
        .filter(a => a.evaluatedValue === true)
        .filter(a => a.raiseIssueOn);
    return {
        rules: activeRules,
        issueType: executionQuestionNode.issueType,
        issueDescription: executionQuestionNode.issueDescription,
        issueResolution: executionQuestionNode.issueResolution,
        resolvedEnabled: executionQuestionNode.resolvedEnabled
    };
};
const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withDraftJs(ExecutionQuestionIssueContainer, false)));