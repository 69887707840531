import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {getTime, reportEvent} from "tbf-react-library";
import CircularProgress from "@mui/material/CircularProgress";
import {LinearProgress} from "@mui/material";
import {strings} from "./SopLocalizedStrings";

class Loader extends Component {

    componentDidMount() {
        this.startTime = getTime();
    }

    componentWillUnmount() {
        const {source, friendlyName = null, hidden} = this.props;
        const finishTime = getTime();
        const duration = finishTime - this.startTime;
        if (!hidden) {
            reportEvent({name: 'Loader', duration: duration, source: source, friendlyName: friendlyName});
        }
    }

    render() {
        const {
            source,
            friendlyName = null,
            inline,
            circular,
            circularSize,
            loaderStyleClass,
            classes,
            contentCentered,
            loaderContainerStyleClass,
            hidden,
            linear
        } = this.props;

        let loader = null;
        if (hidden) {
            return <div data-cy={'Loader'}/>
        }
        if (circular) {
            loader = <CircularProgress data-cy={'Loader'} size={circularSize} className={loaderStyleClass}/>;
        } else if (inline) {
            loader = <span data-cy={'Loader'}>{friendlyName || 'Loading...'}</span>;
        } else if (linear) {
            loader = <div data-cy={'Loader'}>
                <LinearProgress aria-label={strings.loader.main}/>
                <label className={classes.loaderText}><span data-cy={'Loader'}>{friendlyName || 'Loading...'}</span>...</label>
            </div>
        }
        if (contentCentered) {
            return <div className={classes.contentCentered + ' ' + loaderContainerStyleClass}>
                {loader}
            </div>
        } else if (loader != null) {
            return loader;
        }

        return (
            <div data-cy={'Loader'}>
                <div id="loading-bar-spinner" className={classes.loaderContainer} title={source || friendlyName}>
                    <LinearProgress aria-label={strings.loader.main}/>
                    <label className={classes.loaderText}>{strings.loader.loadingMessage} <span
                        className={classes.textLowerCase}>{friendlyName}</span>...</label>
                </div>
            </div>
        )
    }
}

Loader.propTypes = {
    classes: PropTypes.object.isRequired,
    containerStyle: PropTypes.string,
    source: PropTypes.string,
    friendlyName: PropTypes.string,
    inline: PropTypes.bool,
    circular: PropTypes.bool,
    linear: PropTypes.bool,
    circularSize: PropTypes.number,
    loaderStyleClass: PropTypes.string,
    contentCentered: PropTypes.bool,
    hidden: PropTypes.bool,
};
export const styles = () => ({
    loaderContainer: {
        width: '50%',
        height: 50,
        position: 'fixed',
        top: '30%',
        left: 0,
        right: 0,
        margin: 'auto',
        zIndex: '19 !important',
    },
    contentCentered: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loaderText: {
        marginTop: 6,
        display: 'block',
        fontSize: '1.05rem'
    },
    textLowerCase: {
        textTransform: 'lowercase'
    }
});

export default withStyles(styles)(Loader);
