import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Slide from '@mui/material/Slide';
import {strings} from "./SopLocalizedStrings";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        color: '#FFF'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SopDialog({action, title, closeTitle, renderChildren}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    return (
        <div>
            {action(handleClickOpen)}
            <Dialog fullScreen open={open} onClose={handleClose} disableEnforceFocus={true} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            title={strings.buttons.close}
                            data-cy='close'
                            size="large">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                        <Button color="secondary" variant={'contained'} onClick={handleClose} data-cy='done'
                                title={strings.buttons.done}>
                            {closeTitle}
                        </Button>
                    </Toolbar>
                </AppBar>
                <div>
                    {renderChildren()}
                </div>
            </Dialog>
        </div>
    );
}
