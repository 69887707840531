import React, {useCallback} from "react";
import withStyles from '@mui/styles/withStyles';
import {TbfSelectDeviceFriendly} from "tbf-react-library";
import {COMPLETE_ACTION_STYLES, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import {useCallbackCreateOrRestoreRule, useCompleteActionStyle} from "../../../hooks/procedureHooks";
import FormLabel from "@mui/material/FormLabel";
import {strings} from "../../components/SopLocalizedStrings";
import {ProcedureCompleteLabelCollections} from "./ProcedureCompleteCustomLabel";
import {objectIdNameToNameLabel} from "../../../util/util";

const ProcedureCompleteActionStyle = ({disabled, procedureId}) => {
    const currentStyle = useCompleteActionStyle(procedureId)
    const options = objectIdNameToNameLabel(COMPLETE_ACTION_STYLES);
    const selectedOption = options.filter(d => d.value === currentStyle)
    const cb = useCallbackCreateOrRestoreRule(procedureId, RULE_ACTION_TYPE.completeActionStyle.id)
    const handleFilterChange = useCallback((option) => {
        const on = option.value !== COMPLETE_ACTION_STYLES.standard.id
        cb({on: on, ruleAttributes: {format: option.value}})
    }, [cb])
    return (
        <>
            <div data-cy-element={'Property'}>
                <FormLabel className={'sizeSmall'} htmlFor={'completeStyle'}>
                    {strings.procedure.settings.design.completeStyleLabel}
                </FormLabel>
                <TbfSelectDeviceFriendly
                    onChange={handleFilterChange}
                    propertyName={'completeStyle'}
                    id={'completeStyle'}
                    label={'Complete style'}
                    options={options}
                    value={selectedOption}
                    closeMenuOnSelect={true}
                    isSearchable={false}
                    mobileView={true}
                    menuPortalTarget={document.body}
                    disabled={disabled}
                />

                {
                    <ProcedureCompleteLabelCollections disabled={disabled} procedureId={procedureId}/>
                }

            </div>
        </>);
}
const styles = () => ({});
export default withStyles(styles)(ProcedureCompleteActionStyle);
