import React from "react";
import GraphResourceLoad from "./GraphResourceLoad";
import {strings} from "../components/SopLocalizedStrings";
import {NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {SharedAuth} from "tbf-react-library";
import {Permissions} from "../../permissions";

export const GraphLoadGroup = (props) => {
    const hasSearchGroupPermission = SharedAuth.userHasPermission(Permissions.search.group)
    if(!hasSearchGroupPermission) {
        return  <></>
    }

    return  <GraphResourceLoad
                resourcePath={NODE_IDS.Groups}
                friendlyName={strings.group.namePlural}
                nodeType={NODE_TYPE_OPTIONS.GroupRoot}
                displayErrorMode={"none"}
                {...props}/>
}