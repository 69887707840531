import React from "react";
import withStyles from '@mui/styles/withStyles';
import {NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {getNodeOrNull, getNodesIfPresent} from "../../selectors/graphSelectors";
import {hasValue, searchAndSortByRank} from "../../util/util";
import {mapProcedureToOption, mapTeamToOption, mapUserToOption} from "../components/ExecutionSelect";
import {connect} from "react-redux";
import {Breadcrumbs, PageContentWrapper, PageHeader} from "tbf-react-library";
import GlobalSearchItemList from "./GlobalSearchProcedureList";
import {GLOBAL_SEARCH_TYPES} from "../../util/constants";
import EmptyMessage from "../dashboard/EmptyMessage";
import Loader from "../components/Loader";
import GlobalSearchUserList from "./GlobalSearchUserList";
import {isLocalhost} from "../../registerServiceWorker";
import {strings} from "../components/SopLocalizedStrings";

class GlobalSearchMoreResults extends React.Component {

    render() {
        const {classes, allProcedures, allUsers, allTeams, isLoading, resultType} = this.props;

        return <React.Fragment>
            <PageHeader
                title={strings.formatString(strings.searchResults.title, (GLOBAL_SEARCH_TYPES[resultType]?.name || '-'))}/>
            <PageContentWrapper>
                <Breadcrumbs list={[]}/>
                {
                    isLoading && <Loader circular={true} circularSize={34} loaderStyleClass={classes.loadingIndicator}
                                         source={'Execution Photo List'}/>
                }

                {
                    !isLoading &&
                    <div className={classes.innerWrapper}>

                        {
                            allProcedures.length === 0 && allUsers.length === 0 && allTeams.length === 0 &&
                            <EmptyMessage message='No search results' secondaryMessage={''}/>
                        }

                        {
                            allProcedures.length > 0 &&
                            <div className={classes.sectionDivider}>
                                <GlobalSearchItemList title={GLOBAL_SEARCH_TYPES.templates.name}
                                                      type={GLOBAL_SEARCH_TYPES.templates.id}
                                                      items={allProcedures}/>
                            </div>
                        }

                        {
                            allUsers.length > 0 &&
                            <div className={classes.sectionDivider}>
                                <GlobalSearchUserList title={GLOBAL_SEARCH_TYPES.users.name}
                                                      type={GLOBAL_SEARCH_TYPES.users.id}
                                                      items={allUsers}/>
                            </div>
                        }

                        {
                            allTeams.length > 0 &&
                            <div className={classes.sectionDivider}>
                                <GlobalSearchUserList title={GLOBAL_SEARCH_TYPES.teams.name}
                                                      type={GLOBAL_SEARCH_TYPES.teams.id}
                                                      items={allTeams}/>
                            </div>
                        }
                    </div>
                }
            </PageContentWrapper>
        </React.Fragment>
    }
}

const styles = theme => ({
    innerWrapper: {
        width: '100%',
        maxWidth: 900,
        margin: '0 auto',
        paddingTop: 10,
    },
    sectionDivider: {
        width: '100%',
        marginTop: 20,
        float: 'left',
    },
    chipFilter: {
        margin: '0 5px',
        height: 32,
        '& .MuiChip-label': {
            fontSize: 12,
        },
    },
    disabledFilter: {
        pointerEvents: 'none',
    },
    loadingIndicator: {
        position: 'absolute',
        top: '20rem',
        right: 0,
        bottom: 0,
        left: 0,
        margin: 'auto',
    }
});

const defaultProps = {
    renderOptionKey: true,
    limit: 10,
    id: 'menu-search',
    defaultOptions: false,
    procedureIds: [NODE_IDS.UserSubject],
};

const mapStateToProps = (state, ownProps) => {
    const defaultOptions = defaultProps.defaultOptions;
    const defaultsLimit = defaultProps.limit || 100;
    const selectorId = NODE_TYPE_OPTIONS.ExecutionSelector + '-' + defaultProps.id;
    const selector = getNodeOrNull(state, selectorId);
    const searchTerm = (selector && selector.searchTerm && selector.searchTerm.toLowerCase().trim()) || null;
    const procedureIds = defaultProps.procedureIds || [];
    const resultType = ownProps.resultType;

    let loadProcedureUrl = `/procedures?summary=true&limit=${defaultsLimit}`;
    let loadUsersUrl = `/executions?summary=true&limit=${defaultsLimit}`;
    let loadTeamsUrl = NODE_IDS.Groups;

    procedureIds.forEach(procedureId => loadUsersUrl += `&procedureIds=${procedureId}`);
    if (searchTerm && resultType === GLOBAL_SEARCH_TYPES.users.id) {
        loadUsersUrl += `&q=${encodeURIComponent(searchTerm)}`;
    } else if (!defaultOptions) {
        loadUsersUrl = null;
    }

    if (searchTerm && resultType === GLOBAL_SEARCH_TYPES.templates.id) {
        loadProcedureUrl += `&q=${encodeURIComponent(searchTerm)}`;
    } else if (!defaultOptions) {
        loadProcedureUrl = null;
    }

    if ((resultType !== GLOBAL_SEARCH_TYPES.teams.id && !defaultOptions) || !searchTerm) {
        loadTeamsUrl = null;
    }

    let procedures, users, teams;
    let loadFilteredProcedureResourceSync, loadUsersResourceSync, loadTeamsResourceSync;

    loadFilteredProcedureResourceSync = getNodeOrNull(state, loadProcedureUrl);
    let loadedFilteredProcedureNodes = getNodesIfPresent(state, (loadFilteredProcedureResourceSync && loadFilteredProcedureResourceSync.nodeIds) || []);
    procedures = loadedFilteredProcedureNodes.filter(a => a.deleted !== true);

    loadUsersResourceSync = getNodeOrNull(state, loadUsersUrl);
    let loadedUserNodes = getNodesIfPresent(state, (loadUsersResourceSync && loadUsersResourceSync.nodeIds) || []);
    users = loadedUserNodes.filter(a => a.deleted !== true);

    loadTeamsResourceSync = getNodeOrNull(state, loadTeamsUrl);
    let loadedFilteredTeamNodes = getNodesIfPresent(state, (loadTeamsResourceSync && loadTeamsResourceSync.nodeIds) || []);
    teams = loadedFilteredTeamNodes.filter(a => a.deleted !== true);
    if (searchTerm) {
        teams = searchAndSortByRank(searchTerm, teams, 'name');
    }

    const allProcedures = mapProcedureToOption(procedures);
    const allUsers = mapUserToOption(users);
    const allTeams = mapTeamToOption(teams);

    const isLoading = (loadFilteredProcedureResourceSync && (loadFilteredProcedureResourceSync.loading && !hasValue(loadFilteredProcedureResourceSync.nodeIds))) ||
        (loadUsersResourceSync && (loadUsersResourceSync.loading && !hasValue(loadUsersResourceSync.nodeIds))) ||
        (!isLocalhost && (loadTeamsResourceSync && (loadTeamsResourceSync.loading && !hasValue(loadTeamsResourceSync.nodeIds))));

    return {
        isLoading: isLoading,
        allProcedures,
        allUsers,
        allTeams,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GlobalSearchMoreResults));
