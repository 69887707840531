import React from "react";
import {Link} from "react-router-dom";
import withStyles from '@mui/styles/withStyles';
import {getProcedureTypeIcon} from "../../util/image";
import {ClickableArea, TruncateReadMoreText} from "tbf-react-library";
import {formatDateAge} from "../../util/util";
import {strings} from "../components/SopLocalizedStrings";
import AssignmentStatus from "../assignments/AssignmentStatus";
import {NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";

const GraphSavingListItemCard = ({classes, title, status, itemId, procedureType, type, modifiedDateTime}) => {

    return <React.Fragment>
        <ClickableArea
            className={classes.workItemCard}>
            <label className={classes.workItemCardHeader}>
                <img className={'icon'}
                     src={getProcedureTypeIcon(procedureType)}
                     alt={''}/>
                <Link className={'title'} title={title}
                      to={type === NODE_TYPE_OPTIONS.ProcedureRoot ? `/procedures/${itemId}` : `/executions/${itemId}`}
                      tabIndex='-1' data-cy={'execution-link'}
                      data-expand-click-area>
                    <TruncateReadMoreText>{title}</TruncateReadMoreText>
                </Link>
            </label>

            <div className={classes.workItemCardFooter}>
                    <span className={classes.subText}>
                        {`${strings.labels.modified} ${formatDateAge(modifiedDateTime)}`}
                    </span>
                <div className={classes.grow}/>
                <AssignmentStatus status={status}/>
            </div>
        </ClickableArea>
    </React.Fragment>
}

const styles = theme => ({
    workItemCard: {
        padding: '10px 5px',
        borderBottom: `1px solid ${theme.palette.grey.two.main}`,
        transition: 'box-shadow .2s',
        minWidth: 300,
        '&:last-child': {
            borderBottom: `none`,
        },
        '&:hover': {
            boxShadow: '0 3px 3px #00000029',
            background: theme.palette.secondary.one.main
        },
    },
    workItemCardHeader: {
        display: 'flex',
        alignItems: 'center',
        '& .icon': {
            width: 22,
            marginRight: 6,
        },
        '& .title': {
            fontSize: 14,
            color: theme.palette.grey.seven.main,
            fontFamily: theme.fonts.primary.bold,
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            '&:hover': {
                textDecoration: 'underline',
            },
        }
    },
    workItemCardFooter: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 8,
    },
    subText: {
        color: theme.palette.grey.five.main,
        fontSize: 12,
        display: 'block',
    },
    grow: {
        flexGrow: 1
    },
});

export default withStyles(styles)(GraphSavingListItemCard);
