import {
    dbName,
    deleteDbNodes,
    getDbNode,
    indexScopeName,
    indexStoredDateTimeName,
    performCursor
} from "../util/offline";
import {NODE_IDS, NODE_TYPE_OPTIONS} from "../reducers/graphReducer";
import {HOURS_24} from "../util/constants";
import {
    cypress,
    getTime,
    reportBusinessError,
    reportDebug,
    reportError,
    reportEvent,
    reportInfo,
    reportSuccess,
    SharedAuth
} from "tbf-react-library";

let clearCancelled = false
export const startClearOldData = (since, report) => {
    const startTime = getTime()
    clearCancelled = false
    return new Promise((resolve, reject) => {
        // Unsure why cypress is not liking server worker. Might be different client?
        if (navigator.serviceWorker?.controller && !cypress.isCypress()) {
            report && reportInfo('Clearing via service worker')
            reportEvent({
                name: 'ClearCache.Started',
                method: 'ServiceWorker'
            })
            const messageChannel = new MessageChannel()
            messageChannel.port1.onmessage = (event) => {
                const duration = getTime() - startTime
                if (event.data.type === 'CLEAR_CACHED_DATA_SUCCESSFUL') {
                    report && reportSuccess(`Deleted ${event.data.result} nodes.`)
                    reportEvent({
                        name: 'ClearCache',
                        result: event.data.result,
                        duration: duration,
                        cancelled: clearCancelled
                    })
                    resolve(event.data)
                } else {
                    report && reportBusinessError(`Clear cache failed. ${event.data.error}`)
                    reportError(`Clearing cached indexeddb data failed after ${duration}ms. ${event.data.error}`, event.data.error)
                    reject(event.data)
                }
            }
            const message = {
                type: 'CLEAR_CACHED_DATA',
                clientId: SharedAuth.getClientId(),
                tenantId: 'main',
                userId: SharedAuth.getUserId(),
                minLastUsed: since,
                indexedDbName: dbName()
            }
            navigator.serviceWorker.controller.postMessage(message, [messageChannel.port2])
        } else {
            report && reportInfo('Clearing not via service worker')
            reportEvent({
                name: 'ClearCache.Started',
                method: 'Direct'
            })
            clearOldDataV2(since).then(results => {
                const duration = getTime() - startTime
                report && reportSuccess(`Deleted ${results} nodes.`)
                reportEvent({
                    name: 'ClearCache',
                    result: results,
                    duration: duration,
                    cancelled: clearCancelled
                })
                resolve({results: results})
            })
        }
    })
}

export const stopClearOldData = (diagnostics) => {
    clearCancelled = true
    if (navigator.serviceWorker?.controller) {
        reportDebug('Cancel clearing via service worker')
        const messageChannel = new MessageChannel()
        const message = {
            type: 'CANCEL_CLEAR_CACHED_DATA'
        }
        navigator.serviceWorker.controller.postMessage(message, [messageChannel.port2])
    } else {
        reportDebug('Cancel clearing not via service worker')
        cancelClearOldDataV2()
    }
}

let cancelRequested = false
export const clearOldDataV2 = async (minLastUsed) => {
    cancelRequested = false
    const keepScopes = {}
    keepScopes[NODE_IDS.User] = true;
    keepScopes[NODE_IDS.Me] = true;
    keepScopes[NODE_IDS.UserDevice] = true;
    keepScopes[NODE_IDS.MyAssignmentPage] = true;
    keepScopes[NODE_IDS.MyAssignments] = true;
    keepScopes[NODE_IDS.Me] = true;
    keepScopes[NODE_IDS.UserSubject] = true;
    keepScopes[NODE_IDS.MyRecentWorkspaces] = true;
    keepScopes[NODE_IDS.MyRecentProjects] = true;
    keepScopes[NODE_IDS.MyActivityRecent] = true;
    keepScopes[NODE_IDS.Groups] = true;

    // #1 Offline
    const userDevice = (await getDbNode(NODE_IDS.UserDevice))?.node
    for (let id of userDevice?.offlineResources?.projectIds || []) {
        keepScopes[id] = true
    }
    for (let id of userDevice?.offlineResources?.procedureIds || []) {
        keepScopes[id] = true
    }
    for (let id of userDevice?.offlineResources?.executionListPaths || []) {
        keepScopes[id] = true
    }
    for (let id of userDevice?.offlineResources?.photoPaths || []) {
        keepScopes[id] = true
    }
    let executionIds = Object.entries(userDevice?.offlineExecutions || {}).filter(([, on]) => on.on).map(([id]) => id);
    for (let id of executionIds) {
        keepScopes[id] = true
    }

    // #2 Assignments
    if (userDevice?.assignmentsOffline !== false) {
        const asignments = await getDbNode(NODE_IDS.MyAssignments)
        for (let id of asignments?.node?.nodeIds || []) {
            keepScopes[id] = true
        }
        const assignedExecutions = await getDbNode(NODE_IDS.MyAssignedExecutions)
        for (let id of assignedExecutions?.node?.nodeIds || []) {
            keepScopes[id] = true
        }
    }

    // #3 Process oldest
    let lastSeenStoredDateTime = new Date(getTime() - HOURS_24 * 365).toISOString()
    let lastSeenStoredDateTimeOpen = false
    let maxClear = new Date(minLastUsed).toISOString()
    let processedScopes = {
        // We process this one item by item, not all as one
        [NODE_IDS.MyAssignedExecutions]: true,
        ['AlwaysLoad']: true,
        ...keepScopes
    }
    let deletedCount = 0
    let processedScopeCount = 0
    while (true) {
        if (cancelRequested) {
            break;
        }
        // #3.1 Filter
        const filter = IDBKeyRange.bound(lastSeenStoredDateTime, maxClear, lastSeenStoredDateTimeOpen, false)
        let usingTimeStamp = undefined

        // #3.2 Get all results of the next timestamp
        let results = await performCursor(
            store => store.index(indexStoredDateTimeName).openCursor(filter),
            'get next',
            'readonly',
            a => {
                return {id: a.id, rootId: a.node.rootId, scopes: a.node.scopes, storedDateTime: a.storedDateTime}
            },
            item => {
                if (usingTimeStamp === undefined) {
                    usingTimeStamp = item.storedDateTime
                    return false
                } else {
                    return item.storedDateTime !== usingTimeStamp
                }
            }
        )

        // #3.3 All done
        if (results.length === 0) {
            break
        }

        // #3.4
        lastSeenStoredDateTime = results[0].storedDateTime
        lastSeenStoredDateTimeOpen = true
        if (lastSeenStoredDateTime >= maxClear) {
            break
        }

        // #3.4 Find new scopes to process
        let scopesToProcess = {}
        for (let item of results) {
            const isSummary = item.id.startsWith('ExecutionSummary-') || item.id.startsWith('ProcedureSummary-') || item.id.startsWith('NodeActivity') || item.id.startsWith('NodeAssignment')
            if (item.scopes && Array.isArray(item.scopes)) {
                for (let scope of item.scopes) {
                    if (processedScopes[scope] || (isSummary && item.rootId === scope)) {
                        continue
                    }
                    scopesToProcess[scope] = []
                }
            }
            if (!processedScopes[item.rootId] && !isSummary) {
                scopesToProcess[item.rootId] = []
            }
        }

        // #3.5 Process each scope to decide if we are keeping it
        for (let scope of Object.keys(scopesToProcess)) {
            if (cancelRequested) {
                return deletedCount;
            }
            if (keepScopes[scope]) {
                continue;
            }
            processedScopeCount++
            const items = await performCursor(store => store.index(indexScopeName).openCursor(IDBKeyRange.only(scope)),
                'get by scope', 'readonly',
                item => {
                    // #1 Last Used
                    const isSummary = item.id.startsWith('ExecutionSummary-') || item.id.startsWith('ProcedureSummary-') || item.id.startsWith('NodeActivity') || item.id.startsWith('NodeAssignment')
                    if (item.storedDateTime && !isSummary) {
                        const lastUsed = new Date(item.storedDateTime).getTime()
                        if (lastUsed >= minLastUsed) {
                            keepScopes[item.node.rootId] = true
                        }
                    }

                    // #2 Dirty
                    const isDirty = item.dirtyNode.dirty
                    if (isDirty) {
                        keepScopes[item.node.rootId] = true
                    }

                    // #3 Photo
                    if (item.node.photoCaptureId && isDirty) {
                        keepScopes[item.node.photoCaptureId] = true
                    }

                    // #4 Project Offline
                    if (item.node.type == NODE_TYPE_OPTIONS.ProjectRoot && item.node.offline) {
                        keepScopes[item.node.id] = true
                        keepScopes[NODE_IDS.ProjectExecutionSummary(item.node.id, false)] = true
                        keepScopes[NODE_IDS.PhotosForExecution(null, item.node.id)] = true
                        keepScopes[NODE_IDS.ExecutionsForProject(item.node.id)] = true
                    }
                    return {id: item.id, scopes: item.node.scopes, rootId: item.rootId}
                })
            scopesToProcess[scope] = items
        }

        // #3.7 Delete per scope
        for (let scope of Object.keys(scopesToProcess)) {
            if (cancelRequested) {
                return deletedCount
            }
            let nodesToDelete = []
            for (let item of scopesToProcess[scope]) {
                const keepAsDirtyRoot = !!keepScopes[item.rootId]
                let keepAsScope = false;
                for (let scope of item.scopes || []) {
                    keepAsScope = keepAsScope || keepScopes[scope]
                }
                if (!keepAsScope && !keepAsDirtyRoot) {
                    nodesToDelete.push(item.id)
                }
            }
            if (nodesToDelete.length > 0) {
                console.info(`ClearOldData: Deleting ${nodesToDelete.length} nodes under scope ${scope}.`, nodesToDelete)
                await deleteDbNodes(nodesToDelete)
                deletedCount = deletedCount + nodesToDelete.length
            }
            processedScopes[scope] = true
        }
    }
    console.info(`Processed ${processedScopeCount} scopes`)
    return deletedCount
}
export const cancelClearOldDataV2 = () => {
    cancelRequested = true
}