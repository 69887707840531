import React, {useEffect, useState} from "react";
import {Loader, SHARED_AUTH_INSTANCE} from "tbf-react-library";

const LoginCallback = () => {
    let [stage, setStage] = useState('');
    useEffect(() => {

        let timer = window.setInterval(() => {
            setStage(SHARED_AUTH_INSTANCE.getStage())
        }, 100)
        return () => {
            window.clearInterval(timer)
        }
    }, [])
    return <>
        <h3>{stage || 'Authenticating...'}</h3>
        <Loader source={'LoginCallback'} friendlyName={'Validating token'}/>
    </>;
}
export default LoginCallback;
