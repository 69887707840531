import React from "react";
import {strings} from "../../components/SopLocalizedStrings";
import {Permissions} from "../../../permissions";
import TableView from "@mui/icons-material/TableView";
import Dashboard from "@mui/icons-material/Dashboard";
import {NonMobileOnly} from "tbf-react-library";
import {useHasProcedurePermission} from "../../../hooks/procedureHooks";
import AlphaFeature from "../../components/AlphaFeature";
import {useCallbackPatchNode, useNodeOrNull} from "../../../hooks/nodeHooks";
import { REPORT_EVENT_TYPES } from "../../../util/constants";
import TbfSopAction from "../../components/common/TbfSopAction";

const ViewPivotTableButton = React.forwardRef(({selectorId, executionId, procedureId, className}, ref) => {

    const selector = useNodeOrNull(selectorId);

    const canView = useHasProcedurePermission(procedureId, Permissions.procedure.list)

    const patch = useCallbackPatchNode();

    const handleClick = () => {
        patch({id: selectorId, pivot: !selector?.pivot})
    }

    if (!canView) {
        return null
    }

    const useLabel = selector?.pivot !== true ? strings.buttons.viewPivotTable : strings.buttons.viewDataGrid;
    const useIcon = selector?.pivot !== true ? TableView : Dashboard;

    return (
        <AlphaFeature>
            <NonMobileOnly>
                <TbfSopAction
                    nodeId={selector?.procedureId}
                    className={className}
                    title={useLabel}
                    dataCy='view-pivot-table'
                    iconComponent={useIcon}
                    onClick={handleClick}
                    ref={ref}
                    eventName={REPORT_EVENT_TYPES.listingPageReportToggled.name}
                    eventProperties={{
                        to: useLabel,
                    }}
                >
                    {useLabel}
                </TbfSopAction>
            </NonMobileOnly>
        </AlphaFeature>
    )
})

export default ViewPivotTableButton;
