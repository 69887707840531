import React from 'react';
import {Permissions} from "../../../permissions";
import {strings} from "../../components/SopLocalizedStrings";
import {cypress, TbfAction} from "tbf-react-library";
import EditIcon from '@mui/icons-material/EditRounded';
import {useHasProcedurePermission} from "../../../hooks/procedureHooks";
import {useCallbackPatchNode, useIsRootNodeDirty, useNodePropertyOrNull} from "../../../hooks/nodeHooks";

const ProcedureReviewButton = ({procedureId}) => {
    const can = useHasProcedurePermission(procedureId, Permissions.procedure.edit)
    const editOn = useNodePropertyOrNull(procedureId, a => a.editOn)
    const procedureDirty = useIsRootNodeDirty(procedureId)
    const callback = useCallbackPatchNode({id: procedureId, editOn: false})
    const isCypress = cypress.isCypress()
    if (!can || !editOn) {
        return null;
    }
    return (<TbfAction
        iconComponent={EditIcon}
        dataCy={'view-procedure'}
        onClick={callback}
        disabled={procedureDirty && !isCypress}
    >
        {strings.buttons.view}
    </TbfAction>)
}
export default ProcedureReviewButton
