import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodesById} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import Grid from "@mui/material/Grid";
import {formatPercentage, uniqueArray} from "../../util/util";
import ExecutionAssignmentChip from "./ExecutionAssignmentChip";
import {ComponentBase} from "tbf-react-library";
import {DIAGNOSTIC_MODES, NODE_IDS} from "../../reducers/graphReducer";
import {ExecutionShowNodeInfoDialog} from "./ExecutionShowNodeInfoDialog";

class ExecutionAssignments extends ComponentBase {
    renderAssignments(assignedNode) {
        let {classes, diagnosticsOn} = this.props;
        return (
            <div className={assignedNode.deleted ? classes.deleted : undefined} key={assignedNode.id}>
                <Grid item xs={12} className={classes.linkTypeLabel}>
                    {
                        assignedNode &&
                        <React.Fragment>
                            {assignedNode.name} ({formatPercentage(assignedNode.completedRatio)}%)
                            {diagnosticsOn &&
                                <ExecutionShowNodeInfoDialog node={assignedNode} title={assignedNode.name} buttonStyle={classes.diagnosticButton} />
                            }
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12} className={classes.propertyDivider}>
                    <ExecutionAssignmentChip
                        nodeIds={[assignedNode.id]}
                        includeCount={false}
                        displayDelete={false}
                        displayUsers={true}
                    />
                </Grid>
            </div>
        )
    }

    render() {
        let {assignedNodes} = this.props;
        return (
            <React.Fragment>
                <Grid container>
                    {
                        assignedNodes && assignedNodes.map(assignedNode => (this.renderAssignments(assignedNode)))
                    }
                </Grid>
            </React.Fragment>
        )
    }
}

const styles = (theme) => ({
    label: {
        fontWeight: 'bold'
    },
    noAssignmentsMessage: {
        marginTop: 5,
        marginLeft: 7,
        display: 'block'
    },
    propertyDivider: {
        marginBottom: 10
    },
    linkTypeLabel: {
        color: theme.palette.grey.seven.main,
        verticalAlign: 'baseline',
        marginBottom: 5
    },
    deleted: {
        backgroundColor: theme.palette.deleted.one.main
    },
    diagnosticButton: {
        '& svg.MuiSvgIcon-root': {
            fontSize: '1.2rem'
        },
    }
});
ExecutionAssignments.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let execution = getNodeOrNull(state, ownProps.executionId);
    const userDevice = getNodeOrNull(state, NODE_IDS.UserDevice);
    const troubleShootOn = userDevice?.troubleshootOn;
    const diagnosticsOn = userDevice?.diagnosticMode === DIAGNOSTIC_MODES.full.id;

    let assignedNodes = null;
    if (execution.assignments != null && execution.assignments.length > 0) {
        assignedNodes = [];
        let assignments = getNodesById(state, execution.assignments || []).filter(a => troubleShootOn || a.deleted !== true);
        const assignedNodeIds = uniqueArray(assignments.map(a => a.assignedNodeId));
        assignedNodeIds.forEach(assignedNodeId => {
            const asssignedNode = getNodeOrNull(state, assignedNodeId);
            const isDeleted = assignments.filter(e => e.assignedNodeId === assignedNodeId).every(e => e.deleted);
            assignedNodes.push({
                id: asssignedNode.id,
                name: asssignedNode.name,
                completedRatio: asssignedNode.completedRatio,
                deleted: isDeleted
            });
        });

    }

    return {
        assignedNodes: assignedNodes,
        diagnosticsOn
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionAssignments));
