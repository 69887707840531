import React from 'react';
import '../../../style/alert.css';
import {strings} from "../../components/SopLocalizedStrings";
import {Permissions} from "../../../permissions";
import TrackChangesIcon from '@mui/icons-material/TrackChangesRounded';
import {TbfAction} from "tbf-react-library";
import {useHasProcedurePermission} from "../../../hooks/procedureHooks";

const pageStrings = strings.procedure.show;

const ProcedureHistoryButton = React.forwardRef(({procedureId}, ref) => {
    const hasPermission = useHasProcedurePermission(procedureId, Permissions.procedure.edit)
    if (!hasPermission) {
        return null;
    }
    return (<TbfAction
        menuItem={true}
        title={pageStrings.historyButtonTooltip}
        dataCy='history'
        iconComponent={TrackChangesIcon}
        path={'/procedures/' + procedureId + '/history'}
        ref={ref}
    >
        {pageStrings.historyButtonTooltip}
    </TbfAction>)
})
export default ProcedureHistoryButton
