import React from 'react'
import withStyles from '@mui/styles/withStyles';

const EmptyMessage = ({ classes, message, secondaryMessage, children }) => {
  return (
      <div className={`${classes.container} emptyMessageContainer`}>
          <label className={classes.message}>{message}</label>
          <label className={classes.secondaryMessage}>{secondaryMessage}</label>
          <div>
              {children}
          </div>
      </div>
  )
}

const styles = theme => ({
  container: {
    width: '100%',
    textAlign: 'center',
    padding: `${theme.spacing(4)} 0`
  },
  message: {
      display: 'block',
      fontWeight: 500,
      fontSize: 18,
      color: '#2c3435'
  },
  secondaryMessage: {
      display: 'block',
      fontWeight: 500,
      fontSize: 12,
      color: '#265156',
      padding: '5px 0'
  }
})

export default withStyles(styles)(EmptyMessage)
