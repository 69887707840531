import React, {useCallback, useEffect, useRef, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {strings} from "../../layouts/components/SopLocalizedStrings";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from '@mui/material/Typography';
import {getLoadedHelpManuals, getLoadedHelpPoints} from "../selectors/loadHelpPointSelectors";
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import {useSelector} from 'react-redux'
import first from 'lodash/first'
import gsap from 'gsap'
import {TbfModal} from "tbf-react-library"

/* eslint-disable-next-line */
export default ({helpPointExternalId, closeHelpModal}) => {
    const classes = useStyles()
    const helpPoint = get(useSelector(getLoadedHelpPoints), [helpPointExternalId])
    const manualId = get(helpPoint, ['manualId'])
    const helpDocumentHtml = get(useSelector(getLoadedHelpManuals), [manualId])
    const bookmarkIds = get(helpPoint, ['manualBookmarkIds'])
    const helpPointManualName = get(helpPoint, ['manualName'])
    const parsedDocumentContainerRef = useRef()
    const [html, setHtml] = useState()

    useEffect(() => {
        setHtml(helpDocumentHtml)
    }, [helpDocumentHtml])

    useEffect(() => {
        if (parsedDocumentContainerRef.current && html) {
            var bookmarkElements = parsedDocumentContainerRef.current.querySelectorAll('a')
            forEach(bookmarkElements, bookmark => {
                if (bookmarkIds.includes(bookmark.id)) {
                    bookmark.parentElement.classList.add(classes.flashHighlight)
                }
            })
            const firstElement = first(parsedDocumentContainerRef.current.querySelectorAll(`.${classes.flashHighlight}`))
            if (firstElement) {
                gsap.to(parsedDocumentContainerRef.current, {duration: 0.5, scrollTo: {y: firstElement, offsetY: 32}})
            }
        }
    }, [classes, html, bookmarkIds])

    const handleClose = useCallback((e) => {
        e.stopPropagation();
        closeHelpModal();
    }, [closeHelpModal])

    return (
        <TbfModal open={true} onClose={handleClose}>
            <div className={classes.container}>
                <Toolbar disableGutters={true} className={classes.toolbarContainer}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        {helpPointManualName}
                    </Typography>
                    <div className={classes.grow}/>
                    <Button
                        color='primary'
                        title={strings.app.close}
                        variant='contained'
                        className={classes.modalButton}
                        onClick={handleClose}
                        data-cy='add-link-cancel'
                    >
                        {strings.app.close}
                    </Button>
                </Toolbar>
                <div className={classes.parseDocumentContainer} ref={parsedDocumentContainerRef}>
                    <div dangerouslySetInnerHTML={{__html: html}}/>
                </div>
            </div>
        </TbfModal>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        width: '90%',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #FFF',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        outline: 0
    },
    grow: {
        flexGrow: 1,
    },
    toolbarContainer: {
        width: '100%',
        float: 'left',
        borderBottom: '1px solid #CCC',
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        minHeight: 'auto'
    },
    title: {
        margin: 0
    },
    parseDocumentContainer: {
        width: '100%',
        maxHeight: 'calc(100vh - 200px)',
        minHeight: '400px',
        overflowX: 'hidden',
        float: 'left',
        padding: theme.spacing(2),
    },
    modalButton: {},
    flashHighlight: {
        background: "#ffff00",
        transition: 'background 1s ease-out'
    }
}))
