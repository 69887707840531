import axios from "axios";
import {reportError} from "tbf-react-library";
import {SHARED_AUTH_INSTANCE} from 'tbf-react-library';
import {getApiBaseUrl} from "./commonActions";


export const execute = (method, url, payload, actions, loadingPayload) => {
    return dispatch => {
        SHARED_AUTH_INSTANCE.getToken()
            .then(token => {
                const headers = {
                    'Authorization': 'Bearer ' + token
                };
                const instance = axios.create({
                    headers: headers
                });

                actions.loading && dispatch({type: actions.loading, payload: loadingPayload || payload});

                const promise = method === 'delete' ? instance[method](url, {
                    headers: headers,
                    data: payload
                }) : instance[method](url, payload, {headers});

                promise.then(({data}) => {
                    actions.success && dispatch({type: actions.success, payload: {response: data, ...loadingPayload}});
                }).catch((err) => {
                    const msg = (err && err.response && err.response.data && err.response.data.message) || err.message;
                    actions.error && dispatch({type: actions.error, payload: {error: err, message: msg}});
                });

            }).catch(error => {
            reportError('Failed to get token to access manual API.', error);
        });
    }
};

const executePromise = (method, url, payload = null) => {
    return SHARED_AUTH_INSTANCE.getToken()
        .then(token => {
            const headers = {
                'Authorization': 'Bearer ' + token
            };
            const instance = axios.create({
                headers: headers
            });
            return method === 'delete' ? instance[method](url, {
                headers: headers,
                data: payload
            }) : instance[method](url, payload, {headers});
        });
};

export const getProcedureManualBookmarks = (searchTerm) => {
    const url = `${getApiBaseUrl()}/manuals?content=${encodeURIComponent(searchTerm)}`;
    return executePromise('get', url);
};

export const getProcedureManualContent = (manualId) => {
    const url = `${getApiBaseUrl()}/manuals/${manualId}/download?documentType=html`;
    return executePromise('get', url);
};
