import React, {useCallback} from 'react';
import '../../style/alert.css';
import {executionPull} from "../../actions/executions";
import CallReceivedIcon from '@mui/icons-material/CallReceivedRounded';
import {reportSuccess, reportUserError} from "tbf-react-library";
import {strings} from "../components/SopLocalizedStrings";
import {Permissions} from "../../permissions";
import {useDispatch} from "react-redux";
import {markNodeForReload} from "../../actions";
import {useHasExecutionPermission} from "../../hooks/executionHooks";
import TbfSopAction from '../components/common/TbfSopAction';
import {REPORT_EVENT_TYPES} from '../../util/constants';

const pageStrings = strings.execution.pull;
const ExecutionPull = React.forwardRef(({executionId, handleClicked}, ref) => {

    const dispatch = useDispatch()
    const handlePull = useCallback(() => {
        executionPull(executionId)
            .then(response => {
                if (response.data?.pushResult) {
                    reportSuccess(pageStrings.successChanges + ' ' + response.data.pushResult);
                } else {
                    reportSuccess(pageStrings.successNoChanges);
                }
                dispatch(markNodeForReload(executionId))
            }).catch(error => {
            reportUserError(error, null, pageStrings.fail);
        });
    }, [dispatch, executionId])
    const canPull = useHasExecutionPermission(executionId, Permissions.procedure.push)
    if (!canPull) {
        return null
    }
    return (<TbfSopAction
        menuItem={true}
        onClick={handlePull}
        title={pageStrings.title}
        iconComponent={CallReceivedIcon}
        ref={ref}
        nodeId={executionId}
        eventName={REPORT_EVENT_TYPES.executionPageMenuPull.name}
    >
        {pageStrings.button}
    </TbfSopAction>)
})
export default ExecutionPull;