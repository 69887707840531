import React, {useCallback, useState} from 'react';
import '../../../style/alert.css';
import "../../../style/form.css";
import Modal from "@mui/material/Modal";
import ProcedureRuleEditor from "./ProcedureRuleEditor";

const ProcedureRuleDialog = ({ruleId, showDeleted, renderAction, disabled}) => {
    const [open, setOpen] = useState(false)
    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])
    return (
        <React.Fragment>
            {renderAction({handleOpen: handleOpen})}
            {
                <Modal open={open} onClose={handleClose} data-cy={'ruleDialog'}>
                    <React.Fragment>
                        <ProcedureRuleEditor
                            handleClose={handleClose}
                            ruleId={ruleId}
                            showDeleted={showDeleted}
                            disabled={disabled}
                        />
                    </React.Fragment>
                </Modal>
            }
        </React.Fragment>
    )
}
export default ProcedureRuleDialog
