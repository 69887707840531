import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {connect} from "react-redux";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import '../../../../style/alert.css';
import {blurNodeProperty, putNodesProperty} from "../../../../actions";
import 'date-fns';
import sortBy from "lodash/sortBy";
import {calculateTextSize} from "../../../../util/util";
import ExecutionQuestionSelectResizeBase from "./ExecutionQuestionSelectResizeBase";
import { strings } from '../../../components/SopLocalizedStrings';

class ExecutionQuestionSelectRadio extends ExecutionQuestionSelectResizeBase {
    handleChangeRadio = (field, currentValue, newValue) => () => {
        const clear = currentValue === newValue;
        this.updateNodeProperty(field, clear ? null : newValue, field, true);
    };

    render = () => {
        let {
            classes,
            disabled,
            value,
            field,
            options = [],
            questionName,
            questionId,
            width
        } = this.props;
        const { parentWidth } = this.state;
        const optionPercentage = width/parentWidth;
        const isMoreThan50Percent = optionPercentage > 0.5;

        if (options.length === 0) {
            return <span className={classes.noOptions}>{strings.execution.questions.select.noOptions}</span>
        }

        return (<RadioGroup
            row={!isMoreThan50Percent}
            ref={this.selectorRef}
            aria-label={questionName}
            name={field + '-' + questionId}
            value={value == null ? '' : value}
            sx={{
                flexGrow: 1
            }}
            className={'radioGroupFieldPrimary'}
        >
            {
                options.map(option => (
                    <FormControlLabel
                        value={option.value}
                        key={option.value}
                        control={<Radio onClick={this.handleChangeRadio(field, value, option.value)}/>}
                        label={option.label}
                        className={classes.radio}
                        disabled={disabled}
                        readOnly={disabled}
                    />
                ))
            }
        </RadioGroup>);
    };
}

const styles = (theme) => ({
    noOptions: {
        color: theme.palette.grey.four.main,
    }
});
ExecutionQuestionSelectRadio.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    let value = executionQuestionNode[ownProps.field];
    let options = ownProps.options ?? executionQuestionNode.optionsParsed;

    const sortedOptons = sortBy(options, opt => opt.label.length);
    const longestWidth = sortedOptons[sortedOptons.length - 1]; // get the last item, since this is sorted by asc
    const width = calculateTextSize({text: longestWidth?.label})
    const checkboxOffset = 42;

    return {
        options: options,
        questionName: executionQuestionNode.name,
        value: value,
        width: width + checkboxOffset
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionSelectRadio));
