import React, {useCallback, useState} from 'react';
import {useDispatch} from "react-redux";
import {strings} from "../../components/SopLocalizedStrings";
import ProcedureRuleDialog from "./ProcedureRuleDialog";
import {NODE_TYPE_OPTIONS} from "../../../reducers/graphReducer";
import {createChildNode} from "../../../factory/graphFactory";
import {putNodesProperty} from "../../../actions";
import {useNodeOrError, useNodeSchemaOrError} from "../../../hooks/nodeHooks";
import {AddAction} from "tbf-react-library";

const ProcedureRuleAddButton = ({nodeId, ruleAttributes}) => {
    const [ruleId, setRuleId] = useState(null)
    const ruleSchema = useNodeSchemaOrError(NODE_TYPE_OPTIONS.ProcedureRule)
    const node = useNodeOrError(nodeId)
    const procedure = useNodeOrError(node.rootId)
    const dispatch = useDispatch()

    const handleClicked = useCallback((handleOpen) => {
        let ruleAttr = {
            nodeIds: [node.id],
            draft: true,
            ...ruleAttributes
        };
        let ruleNode = createChildNode(procedure, ruleSchema, ruleAttr);
        dispatch(putNodesProperty([ruleNode]))
        setRuleId(ruleNode.id)
        handleOpen();
    }, [dispatch, node.id, procedure, ruleAttributes, ruleSchema])

    const renderAction = ({handleOpen}) => {
        return (
            <AddAction
                title={strings.procedure.show.addRuleButtonTitle}
                dataCy={'add-rule'}
                iconButton={true}
                onClick={() => handleClicked(handleOpen)}
            />
        )
    }
    return (
        <React.Fragment>
            <ProcedureRuleDialog ruleId={ruleId} renderAction={renderAction}/>
        </React.Fragment>
    )
}
export default ProcedureRuleAddButton
