import React from 'react';
import withStyles from '@mui/styles/withStyles';
import AddAPhotoRounded from '@mui/icons-material/AddAPhotoRounded';
import AttachmentIcon from '@mui/icons-material/AttachFileRounded';
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {focusNodeProperty} from "../../../../actions";
import {connect} from "react-redux";
import {ComponentBase} from "tbf-react-library";
import Button from "@mui/material/Button";
import {strings} from "../../../components/SopLocalizedStrings";

class ExecutionQuestionAddAttachment extends ComponentBase {

    handleOpenFileExplorer = () => {
        const {photoInputId, onFocusNodeProperty, questionId} = this.props;
        document.getElementById(photoInputId).click();
        onFocusNodeProperty(questionId, questionId);
    }

    render() {
        const {classes, attachmentType, valueDisabled} = this.props;

        return <div className={classes.attachmentWrap}>
            <Button onClick={this.handleOpenFileExplorer}
                    disabled={valueDisabled}
                    title={attachmentType === 'file' ? strings.buttons.attachFile : strings.buttons.addPhoto}
                    className={classes.attachmentTypeContainer}>
                {
                    attachmentType === 'file' &&
                    <React.Fragment><AttachmentIcon className={classes.attachmentIcon}/> {strings.buttons.attachFile}
                    </React.Fragment>
                }
                {
                    attachmentType !== 'file' &&
                    <React.Fragment><AddAPhotoRounded className={classes.attachmentIcon}/> {strings.buttons.addPhoto}
                    </React.Fragment>
                }
            </Button>
        </div>
    }
}

const styles = theme => ({
    attachmentWrap: {
        display: 'flex',
    },
    attachmentTypeContainer: {
        padding: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        border: `1px solid ${theme.palette.temporary.light}`,
        borderRadius: 4,
        color: theme.palette.grey.seven.main,
        background: theme.palette.grey.one.main,
        width: 80,
        height: 80,
        fontSize: 12,
        transition: 'border-color .3s',
        cursor: 'pointer',
        '&:hover': {
            borderColor: theme.palette.grey.four.main
        },
        '&:active': {
            borderColor: theme.palette.primary.one.main
        },
        '& .MuiButton-label': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    attachmentIcon: {
        marginRight: 5,
        fontSize: `35px !important`,
    },
});
const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    const executionId = executionQuestionNode.rootId;
    let executionNode = getNodeOrNull(state, executionId);
    let valueDisabled = ownProps.disabled || executionNode.deleted;
    if (!executionQuestionNode) {
        return {
            photoInstructionRuleIds: []
        }
    }

    return {
        photoInputId: executionQuestionNode.id + '-' + ownProps.field,
        attachmentType: executionQuestionNode.attachmentType,
        valueDisabled,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onFocusNodeProperty: (id, propertyName) => dispatch(focusNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionAddAttachment));
