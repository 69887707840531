import React, {useCallback} from "react";
import withStyles from '@mui/styles/withStyles';
import {TbfSelectDeviceFriendly} from "tbf-react-library";
import {NAVIGATION_STYLES, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import {
    useCallbackCreateOrRestoreRule,
    useNavigationStyle,
    useNavigationStyleRule
} from "../../../hooks/procedureHooks";
import FormLabel from "@mui/material/FormLabel";
import {strings} from "../../components/SopLocalizedStrings";
import ProcedureCompileMessages from "../ProcedureCompileMessages";
import {objectIdNameToNameLabel} from "../../../util/util";

const ProcedureNavigationStyle = ({disabled, procedureId}) => {
    const currentStyle = useNavigationStyle(procedureId)
    const navigationStyleRule = useNavigationStyleRule(procedureId);
    const options = objectIdNameToNameLabel(NAVIGATION_STYLES);
    const selectedOption = options.filter(d => d.value === currentStyle)
    const cb = useCallbackCreateOrRestoreRule(procedureId, RULE_ACTION_TYPE.navigationStyle.id)
    const handleFilterChange = useCallback((option) => {
        const on = option.value !== NAVIGATION_STYLES.tab.id
        cb({on: on, ruleAttributes: {format: option.value}})
    }, [cb])
    return (
        <>
            <div data-cy-element={'Property'}>
                <FormLabel className={'sizeSmall'} htmlFor={'navigationStyle'}>
                    {strings.procedure.settings.design.navigationStyleLabel}
                </FormLabel>
                <TbfSelectDeviceFriendly
                    onChange={handleFilterChange}
                    propertyName={'navigationStyle'}
                    id={'navigationStyle'}
                    label={'Navigation style'}
                    options={options}
                    value={selectedOption}
                    closeMenuOnSelect={true}
                    isSearchable={false}
                    mobileView={true}
                    menuPortalTarget={document.body}
                    disabled={disabled}
                />
            </div>
            {!!navigationStyleRule && <ProcedureCompileMessages nodeId={navigationStyleRule.id}/>}
        </>);
}
const styles = () => ({});
export default withStyles(styles)(ProcedureNavigationStyle);
