import {useNodeOrNull} from "../../../hooks/nodeHooks";
import {strings} from "../../components/SopLocalizedStrings";
import EditIcon from "@mui/icons-material/EditRounded";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import WarningIcon from "@mui/icons-material/WarningRounded";
import RuleIcon from "@mui/icons-material/ErrorRounded";
import Tooltip from "@mui/material/Tooltip";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ProcedureRuleDialog from "./ProcedureRuleDialog";
import React from "react";
import withStyles from '@mui/styles/withStyles';
import {TbfAction} from "tbf-react-library";

const ProcedureRulesListItem = ({classes, ruleId, showDeleted, disabled}) => {
    const rule = useNodeOrNull(ruleId)
    const renderAction = ({handleOpen}) => {
        const title = disabled ? strings.buttons.view : strings.buttons.edit;
        return (
            <TbfAction
                iconComponent={EditIcon}
                title={title}
                dataCy={'edit-rule'}
                onClick={handleOpen}
                iconButton={true}
            />
        )
    }
    return (
        <ListItem key={rule.id} data-cy-element={'Rule'}
                  className={(rule.deleted ? 'alert-danger' : '') + ' ' + classes.ruleList}>
            <ListItemIcon className={classes.ruleIcon}>
                {
                    rule.compileWarnings && rule.compileWarnings.length > 0 ?
                        <WarningIcon className={classes.ruleWarningIcon}/> : <RuleIcon/>
                }
            </ListItemIcon>
            <Tooltip title={rule.name || 'None'}>
                <ListItemText className={classes.ruleName} primary={rule.name}
                />
            </Tooltip>
            <ListItemSecondaryAction className={classes.ruleAction}>
                <ProcedureRuleDialog ruleId={ruleId} showDeleted={showDeleted} renderAction={renderAction}
                                     disabled={disabled}/>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
const styles = () => ({
    ruleList: {
        paddingLeft: 5
    },
    ruleIcon: {
        minWidth: 30
    },
    ruleName: {
        '& span': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }
    },
    ruleWarningIcon: {
        color: '#9f2723'
    },
    ruleAction: {
        right: 0
    }
});
export default withStyles(styles)(ProcedureRulesListItem);
