import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodeSchemaOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import Typography from "@mui/material/Typography";
import NodeTextField from "../graph/NodeTextField";
import {Permissions} from "../../permissions";
import HelpPointEdit from '../../manuals/layouts/HelpPointEdit';
import ProcedureRuleInline from "./rules/ProcedureRulesList";
import Divider from "@mui/material/Divider";
import ProcedurePropertyVisibleRule from "./ProcedurePropertyVisibleRule";
import ProcedurePropertyAudit from "./ProcedurePropertyAudit";
import {ComponentBase, SharedAuth} from "tbf-react-library";
import ProcedureSelectLink from "./ProcedureSelectLink";
import ProcedureRuleAddQueryDesignButton from "./collection/ProcedureRuleAddQueryDesignButton";
import ProcedureMessageContent from "./ProcedureMessageContent";
import ProcedurePropertyColumnWidthRule from './rules/ProcedurePropertyColumnWidthRule';

class ProcedureProperties extends ComponentBase {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentWillUnmount() {
        console.info('ProcedureProperties.componentWillUnmount');
    }

    renderRoot() {
        let {classes, nodeId, disabled, showDeleted} = this.props;
        return (
            <div data-cy-element={'ProcedureProperties'}>
                <Typography className={classes.headline} variant="h2" color="inherit" noWrap>
                    Properties
                </Typography>
                <NodeTextField nodeId={nodeId} nodePropertyName={'name'} disabled={disabled}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'procedureType'} disabled={disabled}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'category'} disabled={disabled}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'titleTemplateEditorEnabled'} disabled={disabled}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'titleTemplateEditor'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <Divider/>
                <ProcedureRuleInline key={nodeId} nodeId={nodeId} showDeleted={showDeleted} disabled={disabled}/>
                {this.renderAudit()}
            </div>
        );
    }

    renderStep() {
        let {classes, nodeId, disabled, showDeleted, procedureId} = this.props;
        return (
            <div data-cy-element={'StepProperties'}>
                <Typography className={classes.headline} variant="h2" color="inherit" noWrap>
                    Properties
                </Typography>
                <NodeTextField nodeId={nodeId} nodePropertyName={'name'} disabled={disabled}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'completeMode'} disabled={disabled}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'statisticsMode'} disabled={disabled}/>
                {this.renderVisibleProperty()}
                <Divider/>
                <ProcedureRuleInline key={nodeId} nodeId={nodeId} showDeleted={showDeleted} disabled={disabled}/>
                {this.renderAudit()}
                <HelpPointEdit helpDocumentExternalId={procedureId} helpPointExternalId={nodeId} type={'HelpPoint'}
                               externalProperty={'Help'}/>
            </div>
        );
    }

    renderTask() {
        let {classes, nodeId, disabled, showDeleted, procedureId} = this.props;
        return (
            <div data-cy-element={'TaskProperties'}>
                <Typography className={classes.headline} variant="h2" color="inherit" noWrap>
                    Properties
                </Typography>
                <NodeTextField nodeId={nodeId} nodePropertyName={'name'} disabled={disabled}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'statisticsMode'} disabled={disabled}/>
                {this.renderVisibleProperty()}
                <ProcedurePropertyColumnWidthRule nodeId={nodeId} disabled={disabled} />
                <Divider/>
                <ProcedureRuleInline key={nodeId} nodeId={nodeId} showDeleted={showDeleted} disabled={disabled}/>
                {this.renderAudit()}
                <HelpPointEdit helpDocumentExternalId={procedureId} helpPointExternalId={nodeId} type={'HelpPoint'}
                               externalProperty={'Help'}/>
            </div>
        );
    }

    renderQuestion() {
        let {
            classes,
            nodeId,
            disabled,
            showDeleted,
            optionsParsed,
            messageRichTextEnabled,
            procedureId,
        } = this.props;
        return (
            <div data-cy-element={'QuestionProperties'}>
                <Typography className={classes.headline} variant="h2" color="inherit" noWrap>
                    Properties
                </Typography>
                <NodeTextField nodeId={nodeId} nodePropertyName={'name'} disabled={disabled}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'questionType'} disabled={disabled}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'format'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'formatDisplay'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'textMultipleLines'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'selectMany'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'selectDataSource'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'selectRenderMode'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <ProcedureSelectLink nodeId={nodeId} disabled={disabled}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'linkStyle'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <ProcedureRuleAddQueryDesignButton nodeId={nodeId} showDeleted={showDeleted} disabled={disabled}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'options'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'validOptions'}
                               nodeValueOptions={optionsParsed} disabled={disabled} hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'minInclusive'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'maxInclusive'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'messageType'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                {
                    messageRichTextEnabled &&
                    <ProcedureMessageContent nodeId={nodeId} nodePropertyName={'initialValue'} visual={'editor'}
                                             disabled={disabled} hideWhenEnabledIsFalse={true}/>
                }
                <NodeTextField nodeId={nodeId} nodePropertyName={'warningMessage'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'fixMode'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'comment'} disabled={disabled}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'initialCommentInstructions'}
                               disabled={disabled} hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'resolvedCommentInstructions'}
                               disabled={disabled} hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'photo'} disabled={disabled}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'attachmentType'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'mobilePhotoMode'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>
                <NodeTextField nodeId={nodeId} nodePropertyName={'photoInstructions'} disabled={disabled}
                               hideWhenEnabledIsFalse={true}/>

                {this.renderVisibleProperty()}
                <ProcedurePropertyColumnWidthRule nodeId={nodeId} disabled={disabled} />
                <NodeTextField nodeId={nodeId} nodePropertyName={'unknown'} disabled={disabled}/>
                <Divider/>
                <ProcedureRuleInline key={nodeId} nodeId={nodeId} showDeleted={showDeleted} disabled={disabled}/>
                {this.renderAudit()}
                <HelpPointEdit helpDocumentExternalId={procedureId} helpPointExternalId={nodeId} type={'HelpPoint'}
                               externalProperty={'Help'}/>
            </div>
        )
    }

    renderVisibleProperty() {
        let {nodeId, disabled} = this.props;
        return <ProcedurePropertyVisibleRule nodeId={nodeId} disabled={disabled}/>;
    }

    renderAudit() {
        let {nodeId} = this.props;
        return <ProcedurePropertyAudit nodeId={nodeId}/>;
    }

    render() {
        let {type} = this.props;
        switch (type) {
            case 'ProcedureRoot':
                return this.renderRoot();
            case 'ProcedureStep':
                return this.renderStep();
            case 'ProcedureTask':
                return this.renderTask();
            case 'ProcedureQuestion':
                return this.renderQuestion();
            default:
                return (<span>Unknown type</span>);
        }
    }
}

const styles = () => ({
    addLink: {textDecoration: 'none'}
});
ProcedureProperties.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.nodeId;
    let node = getNodeOrNull(state, nodeId);
    return {
        type: node && node.type,
        optionsParsed: node && node.optionsParsed,
        messageRichTextEnabled: node && node.messageRichTextEnabled,
        messageType: node && node.messageType,
        nodeSchema: getNodeSchemaOrNull(state, node.type),
        showDiagnostics: SharedAuth.userHasPermission(Permissions.diagnostic.read),
        procedureId: node?.rootId,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedureProperties));
