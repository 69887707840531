import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {EXECUTION_STATUS_OPTIONS, KEY_FIELD_COUNT} from "../../reducers/graphReducer";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import MaterialTable from "material-table";
import {putNodeProperty} from "../../actions";
import {Permissions} from "../../permissions";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrashRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import HistoryIcon from '@mui/icons-material/HistoryRounded';
import UpdateIcon from '@mui/icons-material/UpdateRounded';
import GroupIcon from '@mui/icons-material/GroupRounded';
import {formatPercentage} from "../../util/util";
import {strings} from "../components/SopLocalizedStrings";
import {MaterialTableHeaderWithCount, reportSuccess, SharedAuth} from "tbf-react-library";
import ExecutionLink from "../execution/ExecutionLink";
import {getExecutionSummaryFullByIdIfPresent, getLinkedChildren} from "../../selectors/executionSelectors";
import Loader from "../components/Loader";
import {MINUTES_5} from "../../util/constants";
import {ProjectOfflineLoad} from "./ProjectOfflineLoad";
import ExecutionAssignmentDialogWrapper from "../execution/assignments/ExecutionAssignmentDialogWrapper";

const pageStrings = strings.project.show;


const ProcedureTable = ({rows, columns, canDelete, canAssign, displayDeleted, actions, loading, classes}) => {
    const renderTableTitle = () => {
        return <>
            {
                loading && <div className={classes.inlineLoader}><Loader circular={true} circularSize={16}/></div>
            }
            <MaterialTableHeaderWithCount
                name={strings.dataTable.title}
                count={rows.length}
            />
        </>;
    }

    return (
        <div>
            <div className={'materialTableResponsiveWrapper textFieldPrimary'}>
                <MaterialTable
                    title={renderTableTitle(rows)}
                    columns={columns}
                    data={rows}
                    options={{
                        paging: false,
                        selection: (canDelete || canAssign),
                        rowStyle: {
                            backgroundColor: displayDeleted ? ' #ffb3b3' : ''
                        }
                    }}
                    localization={{toolbar: {searchPlaceholder: strings.inputBox.searchListPlaceholder}}}
                    actions={actions}
                    isLoading={loading && rows.length === 0}
                />
            </div>
        </div>
    )
}

class ProjectShowProcedures extends Component {

    constructor(props) {
        super(props);

        this.state = {showExport: false, assignOpen: false, assignNodeIds: []};

        this.handleDeleteExecutions = this.handleDeleteExecutions.bind(this);
        this.toggleDisplayDeleted = this.toggleDisplayDeleted.bind(this);
    }

    handleDeleteExecutions = (evt, data) => {
        let {deleteTerm, deleteValue} = this.props;
        let msg = deleteValue ?
            strings.formatString(pageStrings.bulkDeleteConfirm, data.length) :
            strings.formatString(pageStrings.bulkRestoreConfirm, data.length);
        if (!window.confirm(msg)) {
            return;
        }
        for (let row of data) {
            this.props.onPutNodeProperty({id: row.id, deleted: deleteValue});
        }
        reportSuccess(strings.formatString(pageStrings.bulkDeleteSuccess, deleteTerm, data.length));
    };

    handleAssignExecutions = (evt, data) => {
        this.setState({assignOpen: true, assignNodeIds: data.map(a => a.rootId)})
    };
    handleAssignExecutionsClose = () => {
        this.setState({assignOpen: false, assignNodeIds: []})
    };

    toggleDisplayDeleted = () => {
        let {projectNode, displayDeleted} = this.props;
        this.props.onPutNodeProperty(
            {
                id: projectNode.id,
                displayDeletedExecutions: !displayDeleted,
                // Categories may change, so go back to 0
                selectedCategory: 0
            });
    };

    render() {
        let {
            projectNode,
            executions,
            canDelete,
            deleteTerm,
            deleteIcon,
            displayDeletedIcon,
            displayDeleted,
            canAssign,
            resourceId,
            loading,
            classes,
            addAction,
            fromQueryQuestion
        } = this.props;
        let {assignOpen, assignNodeIds} = this.state;
        const selectedCategoryIndex = projectNode.selectedCategory || 0;
        if (!projectNode) {
            return <span>Project not found</span>;
        }
        let categories = Array.from(new Set(executions.map(node => node.category || 'Category')));
        categories.sort();
        let selectedCategory = categories[selectedCategoryIndex] || categories[0];
        let filteredExecutions = executions.filter(node => (node.category || 'Category') === selectedCategory);

        let columns = [];
        columns.push({
            field: "name",
            title: "Name",
            render: row => <ExecutionLink nodeId={row.rootId}>{row.name}</ExecutionLink>
        });

        // Build column indexes
        let columnIndexByName = {};
        for (let execution of filteredExecutions) {
            for (let i = 1; i <= KEY_FIELD_COUNT; i++) {
                let keyFieldName = execution['keyField' + i + 'Name'];
                if (keyFieldName) {
                    if (!columnIndexByName[keyFieldName]) {
                        columnIndexByName[keyFieldName] = columns.length;
                        columns.push(
                            {
                                field: keyFieldName,
                                title: keyFieldName,
                                defaultSort: 'asc',
                                guessedType: 'number',
                                render: row => row[keyFieldName + 'Formatted']
                            });
                    }
                }
            }
        }
        columns.push({field: "status", title: "Status"});
        columns.push({field: "completedPercentage", title: "Completed", render: row => row.completedPercentage + '%'});
        columns.push({field: "totalPhotoCount", title: strings.photo.namePlural});

        // Build Rows
        let rows = [];
        for (let execution of filteredExecutions) {
            let row = {
                id: execution.id,
                rootId: execution.rootId,
                name: execution.name,
                status: EXECUTION_STATUS_OPTIONS[execution.status],
                totalPhotoCount: execution.totalPhotoCount,
                completedPercentage: formatPercentage(execution.completedRatio)
            };
            for (let i = 1; i < columns.length - 3; i++) {
                let cellValue = '';
                let column = columns[i];
                let field = column.field;
                for (let k = 1; k <= KEY_FIELD_COUNT; k++) {
                    let keyFieldName = execution['keyField' + k + 'Name'];
                    if (keyFieldName === field) {
                        cellValue = execution['keyField' + k + 'Value'];
                        let parsedNumber = cellValue && cellValue.match && cellValue.match(/^(-?[0-9,]+(\.[0-9]+)?)( [a-zA-Z]+)?$/);
                        if (parsedNumber) {
                            row[field] = Number.parseFloat(parsedNumber[1].replace(/[^0-9-.]+/g, ''));
                        } else {
                            column.guessedType = 'string';
                            row[field] = cellValue;
                        }
                        break;
                    }
                }
                row[field + 'Formatted'] = cellValue;
            }
            rows.push(row);
        }
        const actions = [];
        if (canDelete) {
            if(!fromQueryQuestion) {
                actions.push(
                    {
                        tooltip: 'Toggle show deleted',
                        icon: () => displayDeletedIcon,
                        isFreeAction: true,
                        onClick: this.toggleDisplayDeleted
                    }
                );
            }
            actions.push(
                {
                    tooltip: `${deleteTerm} selected`,
                    icon: () => deleteIcon,
                    onClick: this.handleDeleteExecutions
                }
            );
        }
        if (canAssign) {
            actions.push(
                {
                    tooltip: `Assign selected to group`,
                    icon: () => <GroupIcon/>,
                    onClick: this.handleAssignExecutions
                }
            );
        }
        if(fromQueryQuestion && addAction) {
            actions.push(
                {
                    tooltip: `Add`,
                    icon: addAction,
                    isFreeAction: true,
                }
            );
        }

        return (
            <React.Fragment>
                {
                    projectNode.offline &&
                    <ProjectOfflineLoad projectId={projectNode.id}/>
                }
                {
                    assignOpen &&
                    <ExecutionAssignmentDialogWrapper
                        displayOpen={false}
                        nodeIds={assignNodeIds}
                        handleClose={this.handleAssignExecutionsClose}/>
                }
                {
                    resourceId &&
                    <GraphResourceLoad
                        key={resourceId}
                        resourcePath={resourceId}
                        nodeType={'ExecutionRoot'}
                        friendlyName={strings.execution.namePlural}
                        reloadIntervalMs={MINUTES_5}
                    >
                        <ProcedureTable
                            rows={rows}
                            columns={columns}
                            canDelete={canDelete}
                            canAssign={canAssign}
                            displayDeleted={displayDeleted}
                            actions={actions}
                            loading={loading}
                            classes={classes}
                        />
                    </GraphResourceLoad>
                }
                {
                    !resourceId &&
                        <ProcedureTable
                            rows={rows}
                            columns={columns}
                            canDelete={canDelete}
                            canAssign={canAssign}
                            displayDeleted={displayDeleted}
                            actions={actions}
                            loading={loading}
                            classes={classes}
                        />
                }
            </React.Fragment>
        )
    }
}

const styles = () => ({
    grow: {
        flexGrow: 1,
    },
    inlineLoader: {
        position: 'absolute',
        left: '0px'
    },
});
ProjectShowProcedures.propTypes = {
    projectId: PropTypes.string.isRequired
};
const mapStateToProps = (state, ownProps) => {
    const fromQueryQuestion = !!ownProps.executionId;
    let nodeId =  ownProps.projectId ?? ownProps.executionId;
    const node = getNodeOrNull(state, nodeId);

    let resourceId = "";
    let displayDeleted = false;
    let executions = [];
    let resource;

    if(ownProps.executionId) {
        resourceId = null; //NODE_IDS.ExecutionSummaryScoped(executionId);
        executions = getLinkedChildren(state, ownProps.executionId);
    } else {
        resourceId = `/executions?projectId=${node.id}&includeDeleted=${displayDeleted}&summary=true`;
        displayDeleted = node.displayDeletedExecutions || false;
        resource = getNodeOrNull(state, resourceId);
        executions = getExecutionSummaryFullByIdIfPresent(state, resource?.nodeIds)
    }



    return {
        fromQueryQuestion,
        projectNode: node,
        executions: executions,
        canDelete: SharedAuth.userHasPermission(Permissions.execution.delete),
        canAssign: SharedAuth.userHasPermission(Permissions.execution.assign),
        displayDeleted: displayDeleted,
        displayDeletedIcon: displayDeleted ? <UpdateIcon/> : <HistoryIcon/>,
        deleteTerm: displayDeleted ? 'Undelete' : 'Delete',
        deleteValue: !displayDeleted,
        deleteIcon: displayDeleted ? <RestoreFromTrashIcon/> : <DeleteIcon/>,
        resourceId: resourceId,
        loading: resource?.loading ?? false
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProjectShowProcedures)));
