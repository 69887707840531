import React from "react";
import {AddAction, Panel} from "tbf-react-library";
import {Stack} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import {useNodeOrNull, useNodeSchemaOrNull, usePutNodesProperty} from "../../../hooks/nodeHooks";
import NodeTextField from "../../graph/NodeTextField";
import {useGetActiveProcedureQuestionOptions} from "../../../hooks/procedureHooks";
import {strings} from "../../components/SopLocalizedStrings";
import {createProcedureNode} from "../../../factory/procedureFactory";
import {NODE_IDS} from "../../../reducers/graphReducer";
import GraphDelete from "../../graph/GraphDelete";
import Box from "@mui/material/Box";

const pageStrings = strings.procedure.schema;
const columns = {
    elementName: { name: 'Name', editable: true},
    propertyType: { name: 'Type'},
    format: { name: 'Format' },
    description: { name: 'Description' },
    nodeId: { name:'Source', editable: true, isSelect: true, render: (prop) => prop.source ?? "-" }
}

const Cell = ({children}) => {
    return  <div style={{flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', padding: 8, alignItems: 'center', display: 'flex'}}>{children}</div>
}

const ProcedureSchemaProperty = ({procedureId, propertyId, disabled}) => {
    const property = useNodeOrNull(propertyId);
    const isDefaultProperty = !property.nodeId && !property.clientCreatedDateTime;
    const options = useGetActiveProcedureQuestionOptions(procedureId);

    return <Stack
        direction="row"
        spacing={2}
        style={{textDecoration: property.deleted ? 'line-through' : undefined, color: property.deleted ? 'red' : undefined}}>
        {
            Object.entries(columns).map(([col, value]) => {
                if(isDefaultProperty || disabled || !value.editable || property.deleted) {
                    return <Cell key={col}>
                       <Tooltip title={property[col]}>{value?.render?.(property) ?? property[col] ?? "-"}</Tooltip>
                    </Cell>
                }

                return <Box flex={1}>
                    <NodeTextField nodeId={propertyId} nodePropertyName={col} nodeValueOptions={value.isSelect ? options : undefined} />
                </Box>;
            })
        }

        {
            (!disabled && !isDefaultProperty) && <GraphDelete
                nodeId={propertyId}
                iconButton={true}
                friendlyName={pageStrings.property}
                disabled={disabled} />
        }
    </Stack>
}

const ProcedureSchemaPanel = ({procedureId, schemaId, disabled}) => {
    const putNodesProperty = usePutNodesProperty();
    const schema = useNodeOrNull(schemaId);
    const schemaPropertySchema = useNodeSchemaOrNull(NODE_IDS.ProcedureSchemaProperty);

    const createNew = React.useCallback(() => {
        const childNode = createProcedureNode(schemaPropertySchema, {parentId: schemaId, rootId: procedureId});
        let updatedChildren = [...schema.properties, childNode.id];
        putNodesProperty([childNode, {id: schema.id, properties: updatedChildren}]);
    }, [putNodesProperty, schema])

    return <>
        <Panel title={schema.name}>
            {/*Schema Id*/}
            <Stack marginTop={2} marginBottom={1}>
                <label>{pageStrings.schemaId}: {schema.schemaId}</label>
            </Stack>

            {/*Header*/}
            <Stack direction="row" spacing={2}>
                { Object.values(columns).map((col, ind) => <Cell key={ind} ><b>{col.name}</b></Cell>) }
            </Stack>

            {/*Content*/}
            { schema.properties.map((propId) => <ProcedureSchemaProperty key={propId} procedureId={procedureId} propertyId={propId} disabled={disabled}/>)}

            <Stack
                p={2}
                justifyContent="center"
                alignItems="end"
            >
                <AddAction
                    label={pageStrings.addLabel}
                    title={pageStrings.addTitle}
                    canAdd={!disabled}
                    onClick={createNew}
                    dataCy={'add-schema'}
                />
            </Stack>
        </Panel>
    </>
}

export default ProcedureSchemaPanel;