import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import withStyles from '@mui/styles/withStyles';
import omit from 'lodash/omit'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'

import Save from '@mui/icons-material/Save'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import {PageHeader} from 'tbf-react-library'

import {strings} from '../components/SopLocalizedStrings'

import {getEditorPhotoId, getEditorSelectedTool, isEditorOpen} from './selectors/editor'
import {closeImageEditor} from './actions'

import EditorToolbar from './Toolbar'
import ImageWithMarkup from './ImageWithMarkup'

import {handleCanvasMouseMove, handleToolMouseUp, toggleDrawingMode} from './fabricHelpers'
import {putNodeProperty} from '../../actions'
import {useNodeOrNull} from '../../hooks/nodeHooks'
import {openConfirmationDialog} from '../../actions/confirmation'
import useDeleteKeyDownListener, {deleteSelectedObjects} from './useDeleteKeyDownListener'

const ActionButton = ({text, onClick, IconComponent, disabled = false}) => {
  return (
    <span>
        <IconButton onClick={onClick} disabled={disabled} title={text} size="large">
            <IconComponent/>
        </IconButton>
      </span>
  );
}

const ImageEditor = ({ classes }) => {
  const open = useSelector(isEditorOpen)
  const photoId = useSelector(getEditorPhotoId)
  const dispatch = useDispatch()
  const selectedTool = useSelector(getEditorSelectedTool)
  const photo = useNodeOrNull(photoId)
  const [deleteEnabled, setDeleteEnabled] = useState()

  const markup = get(photo, ['markup'])

  const localsRef = useRef({
    selectedTool: selectedTool,
    canvas: null,
    isDrawingLine: false,
    currentCanvasObject: null
  })

  const onSave = useCallback(() => {
    // const image = this.canvas.toDataURL({ format: 'jpeg' })
    const { canvas } = localsRef.current
    const json = canvas.toJSON()
    const svg = canvas.toSVG()
    dispatch(putNodeProperty({ id: photoId, markup: { json, svg } }))
    dispatch(closeImageEditor())
  }, [photoId, dispatch])

  // Enables drawing mode if the pen tool is selected
  useEffect(() => {
    const canvas = localsRef.current.canvas
    if(canvas) {
      toggleDrawingMode(canvas, selectedTool)
    }
  }, [selectedTool])

  const onClose = useCallback(() => {
    const { canvas } = localsRef.current
    if(canvas) {
      const newJson = canvas.toJSON()
      const dirty = !markup || !markup.json ||
        JSON.stringify(omit(markup.json, ['backgroundImage'])) !== JSON.stringify(omit(newJson, ['backgroundImage']))
      if(dirty) {
        dispatch(openConfirmationDialog({
          title: 'Are you sure?',
          description: "Changes are going to be lost, are you sure you want to continue?",
          action: () => dispatch(closeImageEditor())
        }))
        return
      } else {
        dispatch(closeImageEditor())
      }
    } else {
      dispatch(closeImageEditor())
    }
  }, [dispatch, markup])

  // Updates the selected tool local ref
  useEffect(() => {
    localsRef.current.selectedTool = selectedTool
  }, [selectedTool])
  
  const onImageLoaded = useCallback((ref) => {
    const { current } = ref
    const { canvas, scale, convertEventToViewportPoint } = current
    localsRef.current.canvas = canvas

    const onMouseUp = (obj) => {
      const selectedTool = localsRef.current.selectedTool
      const point = convertEventToViewportPoint(obj.e)
      handleToolMouseUp(canvas, scale, point, selectedTool, dispatch)
      setDeleteEnabled(!isEmpty(canvas.getActiveObjects()))
    }

    const onMouseMove = (obj) => {
      const { selectedTool } = localsRef.current
      const point = convertEventToViewportPoint(obj.e)
      handleCanvasMouseMove(canvas, selectedTool, point)
    }

    const onSelectionUpdated = () => {
      setDeleteEnabled(!isEmpty(canvas.getActiveObjects()))
    }

    canvas.on('mouse:up', onMouseUp)
    canvas.on('mouse:move', onMouseMove)
    canvas.on('selection:updated', onSelectionUpdated)
    return () => {
      canvas.off('mouse:up', onMouseUp)
      canvas.off('mouse:move', onMouseMove)
      canvas.off('selection:updated', onSelectionUpdated)
    }
  }, [dispatch])

  const onDeleteSelected = useCallback(() => {
    const { canvas } = localsRef.current
    deleteSelectedObjects(canvas)
  }, [])

  useDeleteKeyDownListener(open, localsRef)

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
    >
      <div id='image-editor' className={classes.wrapper}>
        <PageHeader
          title={strings.imageEditor.title}
          renderPageActions={() => (
            <>
              <ActionButton onClick={onDeleteSelected} IconComponent={DeleteIcon} classes={classes} text='Delete Selected Objects' disabled={!deleteEnabled} />
              <ActionButton onClick={onSave} IconComponent={Save} classes={classes} text='Save' />
              <ActionButton onClick={onClose} IconComponent={CloseIcon} classes={classes} text='Close' />
            </>
          )}
          showMenuToggle={true}
        />
        <div className={classes.container}>
          <EditorToolbar />
          {open && <ImageWithMarkup photoId={photoId} onLoaded={onImageLoaded} editable />}
        </div>
      </div>
    </Dialog>
  )
}

const styles = theme => ({
  wrapper: {
    height: '100%',
    width: '100%'
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    height: 'calc(100% - 60px)',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  }
})

export default withStyles(styles)(ImageEditor)
