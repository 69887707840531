import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {
    getDescendantsAndSelfIfPresent,
    getDirty,
    getNodeOrNull,
    getNodes,
    getRootNodes,
} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {putNodeProperty} from "../../actions";
import {TbfSelectDeviceFriendly} from "tbf-react-library";
import ReactJson from "react-json-view";
import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import {strings} from "../components/SopLocalizedStrings";

class NodeView extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedRootNodeId: null,
            nodeInputSearchTerm: null,
            worqItemInputSearchTerm: null,
            selectedNodeId: props.nodeId || null
        };
    }

    handleTouch = () => {
        let {selectedNodeId} = this.state;
        this.props.onPutNodeProperty({id: selectedNodeId})
    }

    handleRootNodeChange = (node) => {
        this.setState({selectedRootNodeId: node && node.value, selectedNodeId: node && node.value});
    };
    handleNodeChange = (node) => {
        this.setState({selectedNodeId: node && node.value})
    };

    handleNodeSearchTermChange = (searchTerm) => {
        this.setState({nodeInputSearchTerm: searchTerm});
    }

    handleWorqItemSearchTermChange = (searchTerm) => {
        this.setState({worqItemInputSearchTerm: searchTerm});
    }

    render() {
        let {rootNodesAsOptions, state} = this.props;
        let {selectedNodeId, selectedRootNodeId, nodeInputSearchTerm, worqItemInputSearchTerm} = this.state;
        let filteredOptions = selectedRootNodeId ? getDescendantsAndSelfIfPresent(state, selectedRootNodeId) : Object.values(getNodes(state));
        let formatTitle = a => {
            let parts = [];
            parts.push(a.type);
            parts.push(a.name || a.title || (a.linkType + ' ' + a.toNodeKey + ' ' + a.toNodeTitle));
            parts.push(a.id);
            if (a.processingError) {
                parts.push('Errors: ' + a.processingError);
            }
            if (a.processingWarning) {
                parts.push('Warning: ' + a.processingWarning);
            }
            return parts.join(': ')
        };
        let nodeOptions = filteredOptions
            .map(a => ({
                value: a.id,
                label: formatTitle(a)
            }));
        let selectedNode = getNodeOrNull(state, selectedNodeId);
        let selectedDirty = getDirty(state, selectedNodeId);
        let selectedServer = (selectedDirty && selectedDirty.storeServer) || null;
        let meta = selectedDirty ? {...selectedDirty} : null;
        if (meta) {
            delete meta.storeServer;
        }
        return (
            <>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <TbfSelectDeviceFriendly
                            propertyName={'node'}
                            onChange={this.handleRootNodeChange}
                            label={'Worq item'}
                            options={rootNodesAsOptions}
                            value={selectedRootNodeId}
                            inputValue={worqItemInputSearchTerm}
                            handleSearchTermChange={this.handleWorqItemSearchTermChange}
                            closeMenuOnSelect={true}
                            isSearchable={true}
                            mobileView={true}
                            menuPortalTarget={document.body}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TbfSelectDeviceFriendly
                            propertyName={'node'}
                            onChange={this.handleNodeChange}
                            label={'Node'}
                            options={nodeOptions}
                            value={selectedNodeId}
                            inputValue={nodeInputSearchTerm}
                            handleSearchTermChange={this.handleNodeSearchTermChange}
                            isSearchable={true}
                            closeMenuOnSelect={true}
                            mobileView={true}
                            menuPortalTarget={document.body}
                        />
                    </Grid>
                    {
                        selectedNode &&
                        <>
                            <Grid item xs={12}>
                                <Button onClick={this.handleTouch}
                                        title={strings.buttons.touch}>{strings.buttons.touch}</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <h2>Redux Version</h2>
                                <ReactJson src={selectedNode}/>
                            </Grid>
                        </>
                    }
                    {
                        selectedServer &&
                        <Grid item xs={12}>
                            <h2>Server Copy</h2>
                            <ReactJson src={selectedServer}/>
                        </Grid>
                    }
                    {
                        meta &&
                        <Grid item xs={12}>
                            <h2>Meta</h2>
                            <ReactJson src={meta}/>
                        </Grid>
                    }
                </Grid>
            </>
        );
    }
}

const styles = () => ({});
NodeView.propTypes = {};
const mapStateToProps = (state) => {
    let rootNodes = getRootNodes(state);
    let rootNodesAsOptions = rootNodes.map(a => ({
        value: a.id,
        label: a.type + ': ' + a.name + ': ' + a.key + ': ' + a.title + ': ' + a.id
    }));
    rootNodesAsOptions.sort((a, b) => (a.label || '').localeCompare(b.label));

    return {
        state: state,
        rootNodes: rootNodes,
        rootNodesAsOptions: rootNodesAsOptions
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeView));
