import React, { Suspense } from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../../../style/alert.css';
import FormHelperText from "@mui/material/FormHelperText";
import {strings} from "../../../components/SopLocalizedStrings";
import {ComponentBase} from "tbf-react-library";
import MessageType from "../../../components/MessageType";
import withDraftJs from './LazyDraftJs';
import Loader from '../../../components/Loader';

class ExecutionQuestionRuleInvalidMessage extends ComponentBase {

    render() {
        let {invalidInputMessage, convertToRaw, ContentState} = this.props;
        const useMessage = invalidInputMessage ?? convertToRaw?.(ContentState?.createFromText(strings.warnings.invalidValue))
        const useType = invalidInputMessage ? 'error' : 'plain'
        return (
            <FormHelperText error={true}>
                <MessageType contentState={useMessage} messageType={useType}/>
            </FormHelperText>
        );
    }
}

const styles = () => ({});
ExecutionQuestionRuleInvalidMessage.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let rule = getNodeOrNull(state, ownProps.ruleId);
    return {
        invalidInputMessage: (rule && rule.invalidInputMessage) || null
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withDraftJs(ExecutionQuestionRuleInvalidMessage, false)));
