import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {putNodeProperty} from "../../actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {SharedAuth} from "tbf-react-library";
import {Permissions} from "../../permissions";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {strings} from "../components/SopLocalizedStrings";

class ProjectOfflineButton extends Component {

    constructor(props) {
        super(props);
        this.handleToggleClicked = this.handleToggleClicked.bind(this);

        this.state = {offlineLoading: false};
    }

    handleToggleClicked() {
        const {project} = this.props;
        this.props.onPutNodeProperty({id: project.id, offline: !project.offline});
    }

    render() {
        const {project, classes} = this.props;
        if (SharedAuth.userHasPermission(Permissions.project.read)) {
            return (
                <FormControlLabel
                    className={classes.toggleContainer + ' toggleSwitchFieldPrimary'}
                    control={<Switch
                        checked={project.offline}
                        onChange={this.handleToggleClicked}
                        value="checkedA"
                        inputProps={{
                            'aria-label': strings.project.show.toggleSaveOffline,
                            'aria-pressed': project.offline || false,
                        }}
                        color="primary"
                        data-cy='offline'/>
                    }
                    labelPlacement={'start'}
                    label={strings.project.show.toggleSaveOffline}
                />

            );
        }
        return <React.Fragment/>;
    }
}

const styles = () => ({
    toggleContainer: {
        marginLeft: 0,
        '& .MuiFormControlLabel-label': {
            fontSize: '0.9rem'
        }
    }
});
ProjectOfflineButton.propTypes = {};
const mapStateToProps = (state, ownProps) => {

    return {
        project: getNodeOrNull(state, ownProps.projectId),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProjectOfflineButton)));
