import {createSelector} from 'reselect'
import {NODE_IDS} from '../../../reducers/graphReducer'
import {getClientConfig, getGraph, getNodeOrNull, getNodes} from '../../../selectors/graphSelectors'
import get from 'lodash/get'
import {getTime} from "tbf-react-library";
import {HOURS_24} from "../../../util/constants";

export const getCurrentLocation = createSelector(
    getNodes,
    (nodes) => {
      return get(nodes, [NODE_IDS.Location], null)
    }
)

export const getCurrentLocationPosition = createSelector(
  getCurrentLocation,
  (location) => {
    return location && location.position
    ? [location.position.latitude, location.position.longitude]
        : null
  }
)

export const isCachingMap = createSelector(
    getNodes,
    (nodes) => {
      return get(nodes, [NODE_IDS.UserDevice, 'cachingRunning'], false)
    }
)

export const isCachingAssignedExecutions = createSelector(
    getGraph,
    (state) => {
        let node = getNodeOrNull(state, NODE_IDS.MyAssignedExecutions);
        if (!node) {
            return false;
        }
        return node.loading;
    }
)

export const isOfflineCachingEnabled = createSelector(
    getNodes,
    (nodes) => {
        return get(nodes, [NODE_IDS.UserDevice, 'assignmentsOffline'], undefined)
    }
)
export const getOfflineDownloadProgress = createSelector(
    getNodes,
    (nodes) => {
        return get(nodes, [NODE_IDS.UserDevice, 'assignmentsOfflineState'], undefined)
    }
)
export const getMapsOfflineDownloadProgress = createSelector(
    getNodes,
    (nodes) => {
        return get(nodes, [NODE_IDS.UserDevice, 'mapsOfflineState'], undefined)
    }
)
export const isOfflineMapsCachingEnabled = createSelector(
    getNodes,
    (nodes) => {
        return get(nodes, [NODE_IDS.UserDevice, 'mapsOffline'], undefined)
    }
)

export const retryMapDownload = {
    id: NODE_IDS.UserDevice,
    cachedAssignments: [],
    mapsOffline: true
}
export const retryItemDownload = {
    id: NODE_IDS.MyAssignedExecutions,
    lastReloadTicks: null,
    loadingError: null,
    loadingErrorCount: 0
}
export const downloadAllItems = {
    id: NODE_IDS.MyAssignedExecutions,
    lastReloadTicks: null,
    loadingError: null,
    loadingErrorCount: 0,
    lastFullReloadTicks: getTime() - 7 * HOURS_24 - 1000
}

export const MAP_SOURCE = {
    OpenStreetMaps: 'OpenStreetMaps',
    ESRI: 'ESRI'
}

const OpenStreetMapsSettings = {
    name: 'OSM',
    osmMapLayers: [
        {
            source: MAP_SOURCE.OpenStreetMaps,
            name: 'OSM',
            appHost: 'INTEGRATION',
            url: '/public/proxy/openapi/osm/{z}/{x}/{y}.png',
            offline: false,
            edgeBufferTiles: 1,
            updateWhenZooming: false,
            updateWhenIdle: true
        }
    ],
}

/**
* Example settings for a map layer that runs through the integration service
*  ```
* const EsriMapSettings = {
*    source: MAP_SOURCE.ESRI,
*    name: 'ESRI',
*    offline: false,
*    authenticated: true,
*    dynamicLayers: [{
*      name: 'Base Layer',
*      appHost: 'INTEGRATION',
*      url: 'proxy/gis/fbcb7ac2-1ea2-412f-b100-ecb253dc2b81/ElectricMSSLandbase/MapServer'
*    }]
*  }
* ```
**/

const DEFAULT_MAP_SETTINGS = {
    layers: [
        OpenStreetMapsSettings
    ],
    geosearch: {
        provider: {
            type: "OpenStreetMap",
            config: {
                countrycodes: "au"
            }
        }
    }
}

export const getMapSettings = createSelector(
  getClientConfig,
  (clientConfig) => {
    const clientConfigMapSettings = get(clientConfig, ['mapSettings'])
    if(clientConfigMapSettings) {
      if(clientConfigMapSettings.extend) {
        return {
          ...DEFAULT_MAP_SETTINGS,
          ...clientConfigMapSettings,
          layers: [
            ...DEFAULT_MAP_SETTINGS.layers,
            ...(clientConfigMapSettings.layers || [])
          ]
        }
      }
      return clientConfigMapSettings
    }
    return DEFAULT_MAP_SETTINGS
  }
)