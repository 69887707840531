import React, {useCallback} from 'react';
import {withStyles} from '@mui/styles';
import {NODE_IDS} from '../../../reducers/graphReducer';
import {TbfAction} from 'tbf-react-library';
import {strings} from "../../components/SopLocalizedStrings";
import {useCallbackPatchNode, useNodeOrNull} from "../../../hooks/nodeHooks";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {isNullOrUndefined} from "../../../util/util";

const ExecutionShowRelatedWorkAction = () => {

    const userSetting = useNodeOrNull(NODE_IDS.UserSettings);
    const patchNode = useCallbackPatchNode();
    const showExecutionProperties = isNullOrUndefined(userSetting.showExecutionProperties) ? true : userSetting.showExecutionProperties;

    const showHide = showExecutionProperties ? 'Hide' : 'View';

    let onClick = useCallback((e) => {
        patchNode({
            id: NODE_IDS.UserSettings,
            showExecutionProperties: !showExecutionProperties
        });
    }, [showExecutionProperties]);

    return (<TbfAction
                menuItem={true}
                title={`${showHide} ${strings.execution.show.relatedWork}`}
                dataCy='view-realted-work'
                iconComponent={showExecutionProperties ? VisibilityOffIcon : VisibilityIcon}
                onClick={onClick}
    >
        {`${showHide} ${strings.execution.show.relatedWork}`}
    </TbfAction>);
}

const styles = (theme) => ({
    item: {
        color: theme.palette.grey.seven.main,
        fontSize: '14px',
        paddingTop: 5,
        width: '100%'
    },
});
export default withStyles(styles)(ExecutionShowRelatedWorkAction);