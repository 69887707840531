import React from 'react';
import '../../style/alert.css';
import {ErrorBoundary} from "tbf-react-library";
import ProjectShowProcedures from "../project/ProjectShowProcedures";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {
    useCallbackPatchNode,
    useGetLinkedChildren,
    useNodeOrNull,
} from "../../hooks/nodeHooks";
import QueryAddExecution from "./question/buttons/QueryAddExecution";

export const LegacyTable = ({name, classNames, classes, executionId, queryQuestionId}) => {
    const execution = useNodeOrNull(executionId);
    const executions = useGetLinkedChildren(executionId) ?? [];

    const categories = React.useMemo(() => {
        const categories = Array.from(new Set(executions.map(node => node.category || 'Category')));
        categories.sort();
        return categories ?? [];
    }, [executions?.length]);

    const onPutNodeProperty = useCallbackPatchNode();
    const handleTabChange = (event, value) => {
        onPutNodeProperty({id: executionId, selectedCategory: value});
    };

    return (
        <div data-cy-question={name} className={classNames}
             data-cy-element={'Question'}>
            <div className={`${classes.gridListContainer}`}>
                <ErrorBoundary>
                    <Tabs
                        value={execution?.selectedCategory ?? 0}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                    >
                        {categories.map(category => (
                            <Tab key={category} label={category}/>
                        ))}
                    </Tabs>
                    <ProjectShowProcedures addAction={() => <QueryAddExecution disabled={false} questionId={queryQuestionId}/>} executionId={executionId} />
                </ErrorBoundary>
            </div>
        </div>
    )
}