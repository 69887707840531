import React from "react";
import { SharedAuth } from "tbf-react-library"
import { Permissions } from "../../permissions"
import { strings } from "../components/SopLocalizedStrings";
import HandymanIcon from '@mui/icons-material/Handyman';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import IconButton from "@mui/material/IconButton";
import GraphMobileListItem from "./GraphMobileListItem";
import { NODE_IDS } from "../../reducers/graphReducer";
import { useCallbackPatchNode, useNodeOrNull } from "../../hooks/nodeHooks";


const GraphTroubleshootModeToggle = () => {
    const isTemplateDesigner = SharedAuth.userHasPermission(Permissions.procedure.edit);

    const patch = useCallbackPatchNode();

    const {troubleshootOn} = useNodeOrNull(NODE_IDS.UserDevice) ?? {};

    const useIcon = troubleshootOn ?  <HandymanIcon /> : <HandymanOutlinedIcon />;

    const handleClick = () => {
        patch({id: NODE_IDS.UserDevice, troubleshootOn: !troubleshootOn});
    }

    if (!isTemplateDesigner) {
        return null;
    }

    return <IconButton
        size={'small'}
        onClick={handleClick}
        data-cy={'graph-troubleshoot-mode-toggle'}
        title={strings.buttons.graphTroubleshootMode}
    >
        <GraphMobileListItem>
            {useIcon}
            <span className={'menuItemTitle'}>{strings.buttons.toggleTroubleshootMode}</span>                        
        </GraphMobileListItem>
    </IconButton>;
}

export default GraphTroubleshootModeToggle;