import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../../style/alert.css';
import "../../../style/form.css";
import {strings} from "../../components/SopLocalizedStrings";
import Box from "@mui/material/Box";
import {getActiveDescendantsAndSelfIfPresent, getNodeOrNull} from "../../../selectors/graphSelectors";
import {putNodesProperty} from "../../../actions";
import {ComponentBase} from "tbf-react-library";
import {InputLabel} from "@mui/material";
import {NODE_TYPE_OPTIONS} from "../../../reducers/graphReducer";
import Grid from "@mui/material/Grid";
import NodeTextField from "../../graph/NodeTextField";
import GraphDelete from "../../graph/GraphDelete";
import ProcedureCalculateEditor from "./ProcedureCalculateEditor";

const pageStrings = strings.procedure.rule;

class ProcedureRuleAutoCreateRowEditor extends ComponentBase {

    handleDelete = () => {
        let {onPutNodesProperty, rule} = this.props;
        onPutNodesProperty([{id: rule.id, deleted: true}]);
    }

    handleUndelete = () => {
        let {onPutNodesProperty, rule} = this.props;
        onPutNodesProperty([{id: rule.id, deleted: false}]);
    }

    render() {
        let {rule, disabled, copyToOptions, rowIndex, classes} = this.props;
        return (
            <React.Fragment>
                <Grid container className={classes.boxWithBorder} data-cy-element={'ChildRule'}>
                    <Grid item xs={12} className={(rule.deleted ? 'alert-danger' : '')}>
                        <Box display={'flex'}>
                            <Box flexGrow={1}>
                                <InputLabel>{pageStrings.autoCreateChildRules} #{rowIndex + 1}</InputLabel>
                            </Box>
                            {
                                !disabled &&
                                <Box>
                                    <GraphDelete nodeId={rule.id} iconButton={true}/>
                                </Box>
                            }
                        </Box>

                        <NodeTextField nodeId={rule.id} nodePropertyName={'copyToNodeIds'}
                                       disabled={disabled}
                                       visual={'select'}
                                       nodeValueOptions={copyToOptions}/>
                        <ProcedureCalculateEditor ruleId={rule.id} disabled={disabled}/>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

const styles = (theme) => ({
    boxWithBorder: {
        border: '1px solid black',
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
});
ProcedureRuleAutoCreateRowEditor.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let rule = getNodeOrNull(state, ownProps.ruleId);
    let parentRule = getNodeOrNull(state, rule.nodeIds[0]);
    let copyToOptions = [];
    if (rule && rule.copyToOn) {
        let procedure = getNodeOrNull(state, parentRule.createExecutionProcedureId);
        let nodeQuestions = procedure ? getActiveDescendantsAndSelfIfPresent(state, procedure.id) : [];
        let options = nodeQuestions
            .filter(a => a.type === NODE_TYPE_OPTIONS.ProcedureQuestion)
            .map(a => ({id: a.id, name: procedure.name + ' - ' + a.name}));
        copyToOptions.push(...options);
    }
    return {
        rule: rule,
        parentRule: parentRule,
        copyToOptions: copyToOptions
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedureRuleAutoCreateRowEditor));
