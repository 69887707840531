import React from 'react';
import withStyles from '@mui/styles/withStyles';
import NextButton from "@mui/icons-material/NavigateNextRounded";
import BeforeButton from "@mui/icons-material/NavigateBeforeRounded";
import IconButton from "@mui/material/IconButton";
import {strings} from "./SopLocalizedStrings";

const defaultScrollStep = 250;

class SmoothHorizontalScroller extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showScrollActions: false,
            scrollBeforeActionDisabled: false,
            scrollNextActionDisabled: false,
        }
        this.scrollContainer = React.createRef();
        this.scrollStep = props.scrollStep || defaultScrollStep;
    }


    componentDidMount() {
        this.updateScrollActionsVisibility();
        window.addEventListener('resize', this.updateScrollActionsVisibility);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateScrollActionsVisibility);
    }

    updateScrollActionsVisibility = () => {
        this.setState({
            showScrollActions: this.checkIfContainerHasScroll()
        });
        this.handleScrollActions();
    }

    checkIfContainerHasScroll = () => {
        const elem = this.scrollContainer.current;
        return elem.offsetWidth < elem.scrollWidth;
    }

    handleScrollActions = () => {
        const elem = this.scrollContainer.current;
        if (elem.scrollLeft === 0) {
            this.setState({scrollBeforeActionDisabled: true});
        } else {
            this.setState({scrollBeforeActionDisabled: false});
        }
        let scrollRightTo = elem.offsetWidth + elem.scrollLeft;
        if (elem.scrollWidth <= scrollRightTo) {
            this.setState({scrollNextActionDisabled: true});
        } else {
            this.setState({scrollNextActionDisabled: false});
        }
    }

    scrollHorizontalLeft = () => {
        const elem = this.scrollContainer.current;
        let scrollLeftTo = elem.scrollLeft - this.scrollStep;
        if (scrollLeftTo <= 0) {
            scrollLeftTo = 0;
        }
        elem.scrollTo({
            top: 0, left: scrollLeftTo, behavior: 'smooth'
        });
    }

    scrollHorizontalRight = () => {
        const elem = this.scrollContainer.current;
        let scrollRightTo = elem.scrollLeft + this.scrollStep;
        elem.scrollTo({
            top: 0, left: scrollRightTo, behavior: 'smooth'
        });
    }

    render() {
        const {children, classes, containerProps = {}} = this.props;
        const {showScrollActions, scrollBeforeActionDisabled, scrollNextActionDisabled} = this.state;

        return (
            <div className={classes.container}>
                {
                    showScrollActions &&
                    <div className={classes.scrollActions}>
                        <IconButton
                            className={classes.scrollActionButton}
                            disabled={scrollBeforeActionDisabled}
                            title={strings.buttons.prev}
                            onClick={() => this.scrollHorizontalLeft()}
                            data-cy={'scroll-before-btn'}
                            size="large">
                            <BeforeButton/>
                        </IconButton>
                        <IconButton
                            className={classes.scrollActionButton}
                            disabled={scrollNextActionDisabled}
                            title={strings.buttons.next}
                            onClick={() => this.scrollHorizontalRight()}
                            data-cy={'scroll-next-btn'}
                            size="large">
                            <NextButton/>
                        </IconButton>
                    </div>
                }
                <div className={classes.flexContainer} ref={this.scrollContainer} onScroll={this.handleScrollActions} {...containerProps}>
                    {children}
                </div>
            </div>
        );
    }

}

const styles = () => ({
    container: {
        width: '100%',
        float: 'left',
        position: 'relative'
    },
    flexContainer: {
        width: '100%',
        float: 'left',
        display: 'inline-flex',
        overflowX: 'auto',
        paddingLeft: 10,
        paddingTop: 5,
        marginLeft: -5,
        flexDirection: 'row',
        '&::after': {
            content: `'.'`,
            width: 20,
            height: 20,
            color: 'transparent'
        },
        '&::-webkit-scrollbar': {display: 'none'},
    },
    scrollActions: {
        display: 'block',
        textAlign: 'right',
        position: 'absolute',
        right: 0,
        top: -40
    },
    scrollActionButton: {
        color: '#7c7c7d',
        fontSize: 14,
        width: 20,
        height: 20,
        borderRadius: 2
    },
});

export default withStyles(styles)(SmoothHorizontalScroller);
