import {mergeUnique} from "../../util/util";
import {getNodeSchemaOrError, getNodesOrNull} from "../../selectors/graphSelectors";
import {NODE_TYPE_OPTIONS} from "../graphReducer";

/**
 * Defines the standard properties of a node
 * @type {{loaded: {server: boolean, kind: string}, deleted: {kind: string}, lastReloadTicks: {server: boolean, kind: string}, loadingError: {server: boolean, kind: string}, rootId: {kind: string}, lastSaveTicks: {server: boolean, kind: string, displayName: string}, storeRedux: {server: boolean, kind: string, displayName: string}, id: {kind: string}, lastUpdatedDateTime: {dirtyCompare: boolean, kind: string}, type: {kind: string}, loading: {server: boolean, kind: string}}}
 */
export const SYSTEM_PROPERTIES = {
    id: {kind: 'id'},
    rootId: {kind: 'string', dirtyCompare: false},
    type: {kind: 'string'},
    deleted: {kind: 'boolean'},
    createdDateTime: {kind: 'date', storeServer: false, retrieveServer: true},
    createdByUser: {kind: 'string', storeServer: false, retrieveServer: true},
    clientCreatedDateTime: {kind: 'date', dirtyCompare: false},
    clientCreatedByUser: {kind: 'string', storeServer: false},
    lastUpdatedDateTime: {kind: 'date', storeServer: false, retrieveServer: true},
    lastUpdatedByUser: {kind: 'string', storeServer: false, retrieveServer: true},
    version: {kind: 'int', storeServer: false, retrieveServer: true},
    lastReloadTicks: {kind: 'integer', storeServer: false},
    lastFullReloadTicks: {kind: 'integer', storeServer: false},
    /**
     * True when a new object, or it has been loaded from the server.
     * False when GraphResourceLoad creates it and it isn't yet loaded.
     * Is true for all nodes, meaning it may just be a summary without its children loaded.
     * When node is loaded from server and does not exist it will be loaded: false, and nodeExists: false
     */
    loaded: {kind: 'boolean', storeServer: false},
    processingError: {kind: 'string', storeServer: false},
    processingWarning: {kind: 'string', storeServer: false},
    scopes: {kind: 'array', storeServer: false, display: 'Used for selective Indexeddb loading of data'},
    scope: {kind: 'array', storeServer: false, display: 'Bug, delete eventually'},
    canView: {kind: 'boolean', storeServer: false, retrieveServer: true},
    canComplete: {kind: 'boolean', storeServer: false, retrieveServer: true},
    storeServer: {kind: 'boolean', storeServer: false, retrieveServer: false},
};


export const nodeFactory = (nodeType, firstAttributes) => {
    return {
        type: nodeType,
        ...firstAttributes
    };
};

export const calculateLoadedFull = (state, node) => {
    let schema = getNodeSchemaOrError(state, node.type);
    let hasLoadedFullProperty = !!schema.properties['loadedFull'];
    if (hasLoadedFullProperty) {
        if (node.type === NODE_TYPE_OPTIONS.ProcedureRoot || node.type === NODE_TYPE_OPTIONS.ExecutionRoot) {
            let childrenIds = (node.children || []);
            let childrenLoaded = getNodesOrNull(state, childrenIds);
            if (childrenIds.length > 0 && childrenLoaded != null) {
                node.loadedFull = true;
            } else if (node.loadedFull) {
                node.loadedFull = false;
            }
        }
    }
};
export const domainRuleNOP = {
    onStored: (state, node) => {
        return {
            [node.id]: node
        };
    },
    onPut: (state, node) => {
        if (node.rootId == null) {
            node.rootId = node.parentId || node.id;
        }
        if (node.scopes == null || node.scopes.length === 0) {
            node.scopes = mergeUnique(node.scopes, [node.rootId]);
        }
        calculateLoadedFull(state, node);
        let update = {};
        update[node.id] = node;
        return update;
    },
    onLoad: (state, node) => {
        if (node.rootId == null) {
            node.rootId = node.parentId || node.id;
        }
        calculateLoadedFull(state, node);
        return {
            [node.id]: node
        };
    },
    onOffline: (state, node) => {
        domainRuleNOP.onPut(state, node);
    },
    onSaveSuccess: (state, node) => {
        return {
            [node.id]: node
        };
    },
    calculateSaveInterval: (state, node) => {
        let interval;
        let completedTypes = ['ExecutionStep', 'ExecutionTask'];
        let immediateSaveTypes = ['Photo', 'ExecutionLink', 'ExecutionAssignment', 'NodeView'];
        if (immediateSaveTypes.includes(node.type) || (completedTypes.includes(node.type) && node.completed)) {
            // Save quickly if we know they are not half done
            interval = [0, 60, 5 * 60, 15 * 60, 30 * 60, 60 * 60, 60 * 60, 60 * 60, 60 * 60, 24 * 60 * 60];
        } else {
            // Save with a small delay otherwise
            interval = [3, 60, 5 * 60, 15 * 60, 30 * 60, 60 * 60, 60 * 60, 60 * 60, 60 * 60, 24 * 60 * 60];
        }
        return interval;
    }
};