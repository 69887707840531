import React, {useCallback} from 'react'
import useIdentityToken from "./identityHooks";
import {INTEGRATION_TOKEN} from "../identities";
import {APPS, deriveApiUrl, SHARED_AUTH_INSTANCE, SharedAuth} from "tbf-react-library";
import useAddAuthUrlToServiceWorker from "./useAddAuthUrlToServiceWorker";

/**
 * This will get the token for integration and register it with the service worker.
 *
 * @returns {boolean}
 * @constructor
 */
export function useIntegrationAuth() {
    const getIntegrationAuth = useCallback(() => INTEGRATION_TOKEN, [])
    const getSopAuth = useCallback(() => SHARED_AUTH_INSTANCE, [])
    const integrationTokenResponse = useIdentityToken(getIntegrationAuth, true, null);
    const sopTokenResponse = useIdentityToken(getSopAuth, true, null);
    const url = deriveApiUrl(APPS.INTEGRATION, SharedAuth.getClientId(), SharedAuth.getTenantId());
    const useToken = sopTokenResponse?.token ? sopTokenResponse : integrationTokenResponse;
    useAddAuthUrlToServiceWorker([url], useToken, true)
    return sopTokenResponse?.token != null;
}

export default function IntegrationAuthComponent() {
    useIntegrationAuth();
    return (<></>);
}