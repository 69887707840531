import React, {useCallback, useState} from 'react';
import '../../../style/alert.css';
import ExecutionAssignmentDialog from "./ExecutionAssignmentDialog";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/EditRounded";
import {strings} from "../../components/SopLocalizedStrings";
import withStyles from "@mui/styles/withStyles";
import {useHasExecutionPermission} from "../../../hooks/executionHooks";
import {Permissions} from "../../../permissions";

const pageStrings = strings.execution.assignmentDialog;
const ExecutionAssignmentDialogWrapper = ({displayOpen, handleClose, nodeIds, classes}) => {

    const [open, setOpen] = useState(displayOpen === false)

    const sampleNodeId = nodeIds.length > 0 ? nodeIds[0] : null;
    const canAssign = useHasExecutionPermission(sampleNodeId, Permissions.execution.assign)

    const onOpen = useCallback(() => {
        setOpen(true)
    }, [])
    const onClose = useCallback(() => {
        setOpen(false)
        handleClose && handleClose()
    }, [handleClose])

    if (!canAssign) {
        return null;
    }

    return <React.Fragment>

        {
            displayOpen !== false &&
            <Button color='inherit'
                    title={pageStrings.editLink}
                    size={'small'}
                    onClick={onOpen}
                    data-cy={'edit-assignments'}
                    className={classes.link}>
                <EditIcon/>&nbsp;{pageStrings.editLink}
            </Button>
        }
        {
            open &&
            <ExecutionAssignmentDialog nodeIds={nodeIds} displayOpen={displayOpen} handleClose={onClose}/>
        }
    </React.Fragment>
}
const styles = () => ({
    link: {
        padding: '2px 10px',
    }
});
export default withStyles(styles)(ExecutionAssignmentDialogWrapper)