import React from "react";
import { useChildRuleByActionAndFormatOrNull, useNodeOrNull } from "../../hooks/nodeHooks"
import makeStyles from "@mui/styles/makeStyles";
import { PROCEDURE_THEME_FORMATS, PROCEDURE_TYPE_COLORS, RULE_ACTION_TYPE } from "../../reducers/graphReducer";
import { useProcedureConfig } from "../../hooks/procedureHooks";

const useStyle = makeStyles((theme) => ({
    divider: {
        height: '100%',
        width: theme.spacing(1),
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: ({colour}) => colour,
        borderRadius: 2,
    },
}));

const useProcedureThemeColour = (procedureId, procedureType) => {
    const procedure = useNodeOrNull(procedureId);
    const config = useProcedureConfig(procedureId);
    const colorRule = useChildRuleByActionAndFormatOrNull(procedureId, RULE_ACTION_TYPE.theme.id, PROCEDURE_THEME_FORMATS.colour.id);

    if (procedure && procedure.loadedFull && colorRule && !colorRule.deleted && colorRule.calculateValueQuery) {
        return JSON.parse(colorRule.calculateValueQuery);
    }

    if (config && config.themes && config.themes.length) {
        const theme = config.themes[0];
        return theme.colour;
    }

    return PROCEDURE_TYPE_COLORS[procedureType];
}

const ExecutionHeaderThemedDivider = ({procedureId, procedureType}) => {

    const colour = useProcedureThemeColour(procedureId, procedureType);

    const classes = useStyle({colour: colour});

    return <div className={classes.divider} />;
}

export default ExecutionHeaderThemedDivider;