import {useCallback, useEffect, useState} from 'react'
import {useOnline} from "./onlineHooks"
import {SharedAuth} from "tbf-react-library";

export default function useIdentityToken(identity, enabled, badToken) {
    const [token, setToken] = useState({token: null})
    const online = useOnline()
    let tokenUpdated = useCallback(() => {
        if (!enabled || !online || SharedAuth.isAnonymous()) return
        if (!identity().hasActiveToken()) return
        identity()
            .getToken()
            .then((newToken, expiresAt) => {
                if (newToken !== token?.token || expiresAt !== token?.expiresAt) {
                    setToken({token: newToken, tokenError: null, expiresAt: expiresAt});
                }
            })
            .catch((e) => {
                console.error(e);
                setToken({...token, tokenError: `Unable to get security token. ${e.description || ''}`});
            });
    }, [enabled, online, token?.token, token?.expiresAt, identity])

    useEffect(() => {
        tokenUpdated()
    }, [tokenUpdated, badToken])

    useEffect(() => {
        if (tokenUpdated && identity().addLoginListener) {
            identity().addLoginListener(tokenUpdated)
        }
        return () => {
            identity().removeLoginListener(tokenUpdated)
        }
    }, [tokenUpdated, identity])
    return token;
}
