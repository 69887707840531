import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {withRouter} from "react-router";
import withStyles from '@mui/styles/withStyles';
import {AssignmentIcon, Breadcrumbs, ErrorBoundary, PageContentWrapper, PageHeader} from "tbf-react-library";
import {strings} from "../components/SopLocalizedStrings";
import {NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {useDispatch} from "react-redux";
import EmptyAssignmentMessage from "./EmptyAssignmentMessage";
import AssignmentCard from "./AssignmentCard";
import MapView from '../MapView'
import cn from 'classnames'
import Button from '@mui/material/Button'
import MapIcon from '@mui/icons-material/Map'
import ListIcon from '@mui/icons-material/List'
import ArrowLeft from '@mui/icons-material/ArrowBackIos'
import ArrowRight from '@mui/icons-material/ArrowForwardIos'
import {useMediaQuery, useTheme} from '@mui/material';
import debounce from 'lodash/debounce'
import AssignmentsOfflineInput from './AssignmentsOfflineInput'
import {useGetOrCreateNode} from "../../hooks/nodeHooks";
import {putNodeProperty} from "../../actions";
import {MINUTES_5, REPORT_EVENT_TYPES} from "../../util/constants";
import {useAssignmentFeatures, useAssignments} from "../../hooks/assignmentHooks";
import MapAssignmentPopup from "./MapAssignmentPopup";


const mapPopup = props => (<MapAssignmentPopup {...props}/>)

const Assignments = ({classes}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [activeAssignmentId, setActiveAssignmentId] = useState()
    const dispatch = useDispatch()
    let assignments = useAssignments()
    let features = useAssignmentFeatures()
    const hasLocation = features?.length > 0
    const assignmentsContainerRef = useRef()

    const selectorId = NODE_IDS.MyAssignmentPage;
    const {
        listScrollTop,
        mapExpanded
    } = useGetOrCreateNode(selectorId, NODE_TYPE_OPTIONS.MyAssignmentPage, {})

    // We need to invalidate the map size, the leaflet functionality does not trigger a resize
    // when the container size changes only on window resize
    const mapRef = useRef()
    useEffect(() => {
        if (mapRef.current && mapRef.current.map) {
            mapRef.current.map.invalidateSize()
        }
        if (assignmentsContainerRef.current) {
            assignmentsContainerRef.current.scrollTop = listScrollTop;
        }
    }, [mapExpanded, listScrollTop])

    const onToggleMap = useCallback(() => {
        dispatch(putNodeProperty({id: selectorId, mapExpanded: !mapExpanded}))
    }, [selectorId, mapExpanded, dispatch])

    const onEnterAssignmentCard = useCallback((e, assignmentId) => {
        setActiveAssignmentId(assignmentId)
    }, [])

    const onLeaveAssignmentCard = useCallback(() => {
        setActiveAssignmentId(null)
    }, [])

    const listenScrollEvent = (event) => {
        event.persist();
        debouncedChangeHandler(event);
    }

    const debouncedChangeHandler = useMemo(() => debounce((event) => {
        const {scrollTop} = event.target;
        dispatch(putNodeProperty({id: selectorId, listScrollTop: scrollTop}))
    }, 300), [selectorId, dispatch]);

    return (
        <>
            <PageHeader
                title={strings.assignment.myAssignmentDisplayName}
                PageIcon={<img src={AssignmentIcon} alt={''}/>}
                showMenuToggle={true}/>
            <PageContentWrapper>
                <Breadcrumbs list={[]}/>
                <GraphResourceLoad
                    key={NODE_IDS.MyAssignments}
                    resourcePath={NODE_IDS.MyAssignments}
                    friendlyName={strings.assignment.namePlural}
                    nodeType={NODE_TYPE_OPTIONS.NodeAssignment}
                    incrementalLoadOff={true}
                    reloadIntervalMs={MINUTES_5}>
                    <div className={classes.innerWrapper}>
                        <div className={classes.toolBar}>
                            <label className={'count'}>{assignments.length} worq items shown</label>
                            <AssignmentsOfflineInput/>
                        </div>
                        <div className={classes.container}>
                            <div className={cn(classes.assignmentsContainer, {mapExpanded})}
                                 onScroll={listenScrollEvent}
                                 ref={assignmentsContainerRef} role="list">
                                {assignments.length === 0 && <EmptyAssignmentMessage/>}
                                {assignments.length > 0 &&
                                    assignments.map((assignment, index) => (
                                        <ErrorBoundary key={index}>
                                            <AssignmentCard
                                                assignmentId={assignment.id}
                                                eventName={REPORT_EVENT_TYPES.assignedPageLinkClicked.name}
                                                onMouseEnter={onEnterAssignmentCard}
                                                onMouseLeave={onLeaveAssignmentCard}
                                            />
                                        </ErrorBoundary>
                                    ))
                                }
                            </div>
                            {hasLocation && (
                                <>
                                    <ErrorBoundary>
                                        <MapView
                                            className={cn(classes.mapContainer, {mapExpanded})}
                                            features={features}
                                            zoomLevel={features?.length === 1 && 18}
                                            ref={mapRef}
                                            activeId={activeAssignmentId}
                                            mapViewId={NODE_IDS.AssignmentMapView}
                                            popupComponent={mapPopup}
                                        />
                                    </ErrorBoundary>
                                    <Button
                                        title={mapExpanded ? strings.buttons.list : strings.buttons.map}
                                        variant="contained"
                                        color="primary"
                                        className={cn(classes.toggleMapButton, {mapExpanded})}
                                        startIcon={mapExpanded
                                            ? isMobile ? <ListIcon/> : <ArrowRight/>
                                            : isMobile ? <MapIcon/> : <ArrowLeft/>}
                                        onClick={onToggleMap}>
                                        {!isMobile && <span>{mapExpanded ? 'Show List' : 'Expand Map'}</span>}
                                        {isMobile && <span>{mapExpanded ? 'List' : 'Map'}</span>}
                                    </Button>
                                </>
                            )}
                        </div>

                    </div>
                </GraphResourceLoad>
            </PageContentWrapper>
        </>
    )
}

const styles = theme => ({
    innerWrapper: {
        width: '100%'
    },
    toolBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 20px',
        '& .count': {
            color: theme.palette.grey.seven.main,
            fontSize: 14,
            fontFamily: theme.fonts.primary.bold,
        }
    },
    container: {
        display: 'block',
        position: 'relative',
        overflow: 'auto',
        height: 'calc(100vh - 203px)', // TODO: Make this smarter, how do we calculate the full height
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    assignmentsContainer: {
        background: theme.palette.primary.two.main,
        padding: 20,
        width: '100%',
        flexGrow: 0,
        flexShrink: 0,
        overflow: 'auto',
        [theme.breakpoints.up('md')]: {
            width: 500
        }, '&.mapExpanded': {
            display: 'none'
        }
    },

    mapContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        flexGrow: 1,
        flexShrink: 1,
        opacity: 0,
        pointerEvents: 'none',
        '&.mapExpanded': {
            pointerEvents: 'all',
            opacity: 1
        },
        [theme.breakpoints.up('md')]: {
            pointerEvents: 'all',
            opacity: 1,
            position: 'static',
            height: '100%'
        }
    },
    toggleMapButton: {
        position: 'absolute',
        bottom: 80,
        left: '50%',
        transform: 'translate(-50%, 0)',
        zIndex: 1000,
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
        },
        [theme.breakpoints.up('md')]: {
            backgroundColor: 'white',
            color: 'currentColor',
            bottom: 'auto',
            left: 510,
            top: 10,
            transform: 'translate(0, 0)',
            '&.mapExpanded': {
                left: 10,
            },
            '&:hover': {
                backgroundColor: 'white'
            }
        }
    }
});

export default withStyles(styles)(withRouter(Assignments));
