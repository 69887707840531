import {useCallback, useEffect} from 'react'
import {getTime} from "tbf-react-library";
import {getIntegrationAuth} from "../actions/integration";

let globalTimer = null;
let globalToken = null;
export default function useAddAuthUrlToServiceWorker(urls, token, enabled) {
  globalToken = token;
  const sendMessage = useCallback(() => {
    if (!navigator.serviceWorker) {
      // Not supported
      return;
    } else if (!navigator.serviceWorker.controller) {
      return;
    } else if (!enabled || !globalToken?.token || (globalToken?.expiresAt && globalToken?.expiresAt < getTime())) {
      return;
    }

    const messageChannel = new MessageChannel()
    messageChannel.port1.onmessage = () => {
    }
    navigator.serviceWorker.controller.postMessage({
      type: 'ADD_URL',
      urls,
      token: globalToken?.token
    }, [messageChannel.port2])
  }, [token?.token, enabled, ...urls])

  useEffect(() => {

    // #3 Timer
    if (!globalTimer) {
      globalTimer = window.setInterval(() => {
        sendMessage();
      }, 5 * 60000);
    }

    // #4 SW Activated
    // If the service worker is not ready, listen for the ready event and then run the code
    // SW is short lived, so each time it starts up we need to re-send the token
    // https://stackoverflow.com/questions/29741922/prevent-service-worker-from-automatically-stopping
    if (navigator.serviceWorker) {
      navigator.serviceWorker.ready.then(() => {
        console.info('serviceworker.active, sending message.')
        sendMessage()
        setTimeout(() => sendMessage(), 100)
      })
    }

    // #5 Send Now
    sendMessage()

    return () => {
      if (navigator.serviceWorker?.controller) {
        // No need to remove URLS ... that I can think of.
        //navigator.serviceWorker.controller.postMessage({type: 'REMOVE_URL', urls})
      }
    }
  }, [enabled, token?.token, sendMessage])

  useEffect(() => {
    // Establish session with integration
    getIntegrationAuth().then(() => {
      console.info('Created session with integration succeeded.')
    }).catch(err => {
      console.info('Created session with integration fail.', err)
    })
  }, [enabled, token?.token])
}
