import React from 'react';
import {connect} from "react-redux";
import withStyles from '@mui/styles/withStyles';
import {withRouter} from "react-router-dom";
import {downloadHelpDocumentContent, getHelpPoints, setHelpPointLoading} from '../actions/loadHelpPointActions';
import * as PropTypes from "prop-types";
import {isEmptyObject} from "../../util/util";
import {SharedAuth} from "tbf-react-library";

class LoadHelpPoints extends React.Component {
    constructor(props) {
        super(props);
        props.onSetHelpPointLoading(true);
        this.manualDownloaded = false;
    }

    componentDidMount() {
        const {isLoadingHelpPoints, externalId, onGetHelpPoints} = this.props;
        if (!isLoadingHelpPoints && !SharedAuth.isAnonymous()) {
            onGetHelpPoints({externalId: externalId});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            isLoadingHelpPoints,
            loadedHelpPoints,
            loadedHelpPointManuals,
            onDownloadHelpDocumentContent
        } = this.props;
        if (!isLoadingHelpPoints && !isEmptyObject(loadedHelpPoints) && !this.manualDownloaded) {
            const requestedManuals = [];
            for (let id in loadedHelpPoints) {
                if (loadedHelpPoints.hasOwnProperty(id)) {
                    const helpPoint = loadedHelpPoints[id];
                    if (requestedManuals.indexOf(helpPoint.manualId) === -1 && !loadedHelpPointManuals[helpPoint.manualId]) {
                        requestedManuals.push(helpPoint.manualId);
                        if (helpPoint && helpPoint.manualId) {
                            onDownloadHelpDocumentContent(helpPoint);
                        }
                    }
                }
            }
            this.manualDownloaded = true;
        }
    }

    render() {
        return <React.Fragment/>
    }
}

const styles = () => ({})

LoadHelpPoints.propTypes = {
    externalId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
    return {
        isLoadingHelpPoints: state.loadHelpPointReducer.isLoadingHelpPoints,
        loadedHelpPoints: state.loadHelpPointReducer.loadedHelpPoints,
        loadedHelpPointManuals: state.loadHelpPointReducer.loadedHelpPointManuals
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetHelpPointLoading: (state) => dispatch(setHelpPointLoading(state)),
        onGetHelpPoints: (helpDocumentSource) => dispatch(getHelpPoints(helpDocumentSource)),
        onDownloadHelpDocumentContent: (helpPoint) => dispatch(downloadHelpDocumentContent(helpPoint)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(LoadHelpPoints)));
