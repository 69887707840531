import {TbfModal} from "tbf-react-library";
import React, {useState} from "react";
import JSONPretty from "react-json-pretty";
import makeStyles from "@mui/styles/makeStyles";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import {strings} from "../components/SopLocalizedStrings";
import InfoIcon from "@mui/icons-material/InfoRounded";
import {useNodeOrNull} from "../../hooks/nodeHooks";

export const ExecutionShowNodeInfoDialog = ({  nodeId, node, title, buttonStyle }) => {
    const style = useStyles();
    const [open, setOpen] = useState(false);

    const nodeToDisplay = useNodeOrNull(nodeId);

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <IconButton
                title={strings.buttons.diagnostics}
                data-cy='toggle-diagnostics'
                className={ buttonStyle ?? style.diagnosticButton}
                onClick={() => setOpen(true)}
                edge="end"
                size="small">
                <InfoIcon/>
            </IconButton>

            <TbfModal
                open={open}
                onClose={handleClose}>
                <div className={style.paper}>
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        <JSONPretty id="json-pretty" data={node ?? nodeToDisplay}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </div>
            </TbfModal>
        </>
    )

}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    diagnosticButton: {
        top: '50%',
        right: 5,
        position: 'absolute',
        transform: 'translateY(-50%)',
        '& svg.MuiSvgIcon-root': {
            fontSize: '1.2rem'
        },
    }
}))
