import React, {Suspense, useCallback, useEffect, useState} from "react";
import {downloadAttachment} from "../../actions/executions";
import {PUBLIC_URL, reportUserError} from "tbf-react-library";
import Loader from "./Loader";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ProgressFilled from "../MapView/ProgressFilled";
import FileType from '../../assets/fileType.png';
import { strings } from "./SopLocalizedStrings";
import { encodeUrlLastPart } from "../../util/util";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useCallbackPatchNode } from "../../hooks/nodeHooks";

const Document = React.lazy(() =>
    import('react-pdf').then(({Document, pdfjs}) => {
        pdfjs.GlobalWorkerOptions.workerSrc = `${PUBLIC_URL}/pdf.worker.min.2.16.105.js`;
        return {default: Document}
    })
);
const Page = React.lazy(() => import('react-pdf').then(({Page}) => ({default: Page})));

const PDFViewer = ({classes, fileUrl, fileId, inMemoryUrl}) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(1);
    const [file, setFile] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const {innerHeight} = window;
    const canvasHeight = innerHeight - 225;
    const [isDownloadingFailed, setIsDownloadingFailed] = useState(false);
    const patch = useCallbackPatchNode();

    const MAX_DOWNLOAD_ATTEMPT = 3;

    const setFileData = (fileData) => {
        setFile(fileData);
        setIsDownloading(false);
    }

    const handleDownloadFile = useCallback((downloadAttempt = 0, fromMemory = false) => {
        if (fileUrl) {
            setIsDownloading(true);
            const useUrl = fromMemory ? inMemoryUrl : fileUrl;
            const encodedUrl = encodeUrlLastPart(useUrl);
            downloadAttachment(encodedUrl, true, !fromMemory).then(response => {
                if (response) {
                    const reader = new FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        setFileData(base64data);
                    }
                    if (!inMemoryUrl) {
                        patch({id: fileId, originalInMemoryUrl: window.URL.createObjectURL(response.data)})
                    }
                }
                setIsDownloading(false);
            }).catch(error => {
                if (downloadAttempt < MAX_DOWNLOAD_ATTEMPT) {
                    reportUserError(error, null, 'File download failed.');
                    handleDownloadFile(downloadAttempt + 1);
                } else {
                    if (!fromMemory && inMemoryUrl) {
                        reportUserError(error, null, 'File download failed. Attempting to download cached file.');
                        handleDownloadFile(downloadAttempt + 1, true);
                    } else {
                        setIsDownloadingFailed(true);
                        setIsDownloading(false);
                    }
                }
            });
        }
    }, [fileUrl, setIsDownloading])

    useEffect(() => {
        if (isDownloadingFailed) {
            return;
        }

        if (!file && !isDownloading) {
            handleDownloadFile();
        }
    }, [file, isDownloading, handleDownloadFile, isDownloadingFailed]);

    const onDocumentLoadSuccess = (pdf) => {
        const {_pdfInfo} = pdf;
        setPageNumber(1);
        setNumPages(_pdfInfo.numPages || 1);
    }

    if (isDownloading) {
        return <Loader circular={true} circularSize={32} source={'Loading...'} loaderStyleClass={classes.loader} />
    }

    if (isDownloadingFailed) {
        return <div className={classes.previewNotAvailable} >
            <img src={FileType} className={'typeIcon'} alt={''} />
            <span className={classes.previewNotAvailableLabel} >{strings.photo.errors.previewNotAvailable}</span>
        </div>
    }

    return (
        <div className={classes.container} id={`pdfViewer_${fileId}`}>
            <Suspense fallback={<ProgressFilled/>}>
                <Document
                    file={file}
                    onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} height={canvasHeight}/>
                </Document>
                <div className={'navigationToolBar'}>
                    <IconButton
                        disabled={pageNumber <= 1}
                        onClick={() => setPageNumber(pageNumber - 1)}
                        size="large">
                        <ChevronLeftRoundedIcon/>
                    </IconButton>
                    <span className={'pageNumber'}>{pageNumber} / {numPages}</span>
                    <IconButton
                        disabled={pageNumber === numPages}
                        onClick={() => setPageNumber(pageNumber + 1)}
                        size="large">
                        <ChevronRightRoundedIcon/>
                    </IconButton>
                </div>
            </Suspense>
        </div>
    );
}

const styles = theme => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        maxHeight: '77vh',
        '&:hover .navigationToolBar': {
            opacity: 1,
        },
        '& .navigationToolBar': {
            position: 'absolute',
            bottom: 15,
            zIndex: 2,
            opacity: 0,
            background: theme.palette.primary.two.main,
            boxShadow: '0 30px 40px 0 rgb(16 36 94 / 36%)',
            borderRadius: 25,
            border: `1px solid ${theme.palette.primary.two.main}`,
            transition: 'opacity ease-in-out 0.2s',
            '& .pageNumber': {
                margin: '0 5px',
                color: theme.palette.primary.three.main,
            },
            '& button:not([disabled])': {
                color: theme.palette.primary.three.main,
            },
        },
    },
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: '-12rem',
        margin: 'auto',
    },
    previewNotAvailable: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '80%'
    },
    previewNotAvailableLabel: {
        display: 'block',
        color: 'white',
        fontSize: 14,
        marginTop: 10,
    }
});

export default withStyles(styles)(PDFViewer);
