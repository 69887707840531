import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {connect} from "react-redux";
import {blurNodeProperty, focusNodeProperty, putNodesProperty} from "../../../../actions";
import ExecutionQuestionInputBase from "./ExecutionQuestionInputBase";
import Input from "@mui/material/Input";
import {strings} from "../../../components/SopLocalizedStrings";
import FormatListNumberedRoundedIcon from "@mui/icons-material/FormatListNumberedRounded";
import {MAX_INTEGER_VALUE, MIN_INTEGER_VALUE, reportBusinessError} from "tbf-react-library";
import InputAdornment from "@mui/material/InputAdornment";
import {FORMATS} from "../../../../reducers/graphReducer";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {parseFormat} from "tbf-jsonlogic";

class ExecutionQuestionNumber extends ExecutionQuestionInputBase {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        const {field, valueDisabled} = this.props;

        if (valueDisabled) return;

        const value = e.target.value;
        if (value && Number(value) > MAX_INTEGER_VALUE) {
            reportBusinessError(strings.warnings.integerRangeExceeded);
        } else if (value && Number(value) < MIN_INTEGER_VALUE) {
            reportBusinessError(strings.warnings.integerRangeLowered);
        } else {
            this.updateNodeProperty(field, value, field);
        }
    };

    getDisplay = (value) => {
        const { format, formatDisplay, valueFormatted, valueDisabled } = this.props;
        let formatOption = FORMATS[format]
        let postfix = formatOption && formatOption.postfix;
        let prefix = formatOption && formatOption.prefix;

        if (format === FORMATS.custom.id) {
            const parsedFormat = parseFormat(formatDisplay);
            postfix = parsedFormat?.postfix.trim();
            prefix = parsedFormat?.prefix.trim() === FORMATS.dollar2.prefix ? FORMATS.dollar2.prefix : undefined;
        }

        if (valueDisabled) {
            let formattedValue = valueFormatted ?? '';
            if (formattedValue[0] === '$') {
                formattedValue = formattedValue.replace('$', '');
            }
            return [formattedValue, postfix, prefix]
        }

        return [value, postfix, prefix]
    }

    render = () => {
        const {value, valueUnknown, valueDisabled, format, field, inputPattern} = this.props;
        let {value: standardValue, ...standardProperties} = this.renderInputStandardProperties(value, valueUnknown, field);

        const [displayValue, postfix, prefix] = this.getDisplay(standardValue);

        let endAdornment = postfix && !valueDisabled ?
            <InputAdornment
                className={'suffix-label'}
                position="end">{postfix}</InputAdornment> : '';
        let startAdornment = prefix ? <AttachMoneyIcon aria-label='Dollar' aria-hidden='false' /> : <FormatListNumberedRoundedIcon/>;
        const displayAsText = isNaN(Number(displayValue));
        return <Input
            type={(valueDisabled && displayAsText) ? 'text' : 'number'}
            step={0.01}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            {...standardProperties}
            inputProps={{
                value: displayValue,
                pattern: inputPattern,
                ...standardProperties.inputProps
            }}
            onChange={this.handleChange}
            onKeyDown={(e) => {
                if (e.keyCode === 69 || e.keyCode === 187) {
                    e.preventDefault();
                }
            }}
        />
    }
}

const styles = () => ({});
ExecutionQuestionNumber.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    let valueUnknown = executionQuestionNode[ownProps.field + 'Unknown'];
    let valueDisabled = ownProps.disabled || (executionQuestionNode && executionQuestionNode.initialValueReadOnly);

    return {
        questionName: executionQuestionNode.name,
        valueUnknown: valueUnknown,
        valueDisabled: valueDisabled,
        format: executionQuestionNode.format,
        formatDisplay: executionQuestionNode.formatDisplay,
        inputPattern: executionQuestionNode.inputPattern,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
        onFocusNodeProperty: (id, propertyName) => dispatch(focusNodeProperty(id, propertyName)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionNumber));
