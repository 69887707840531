import React, {useMemo} from "react";
import ProcedureCalculateEditor from "../rules/ProcedureCalculateEditor";
import {
    useActiveDescendantsAndSelf,
    useCallbackPatchNode,
    useCallbackPatchNodes,
    useChildRulesByActionOrNull,
    useNodeOrError,
    useNodeOrNull,
    useNodeSchemaOrError, useNodesIfPresent
} from "../../../hooks/nodeHooks";
import {COMPLETE_LABELS_FORMAT, NODE_TYPE_OPTIONS, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import {strings} from "../../components/SopLocalizedStrings";
import NodeTextField from "../../graph/NodeTextField";
import {AddAction, Panel, TbfOptionsMenu} from "tbf-react-library";
import {createRule, DynamicLabelRules} from "../../../factory/procedureFactory";
import PanelSection from "../../components/PanelSection";
import GraphDelete from "../../graph/GraphDelete";
import TbfToggle from "../../components/common/TbfToggle";
import {useCallbackCreateOrRestoreRule, useCompleteActionStyle} from "../../../hooks/procedureHooks";

const ProcedureCompleteCustomLabelEditor = ({labelRule, disabled}) => {
    const label = COMPLETE_LABELS_FORMAT[labelRule?.format]?.name ?? labelRule?.format;
    return <ProcedureCalculateEditor disabled={disabled} ruleId={labelRule.id} label={label}/>
}

export const ProcedureCompleteLabelCollection = ({procedureId, collection, disabled}) => {
    const ruleSchema = useNodeSchemaOrError(NODE_TYPE_OPTIONS.ProcedureRule)
    const procedure = useNodeOrNull(procedureId);
    const patch = useCallbackPatchNode();
    const allNodes = useActiveDescendantsAndSelf(procedureId);
    const allRules = useNodesIfPresent(collection?.ruleIds);
    const ruleId = collection.id;
    const labelRules = allRules
                        .filter(e => e.actionType === RULE_ACTION_TYPE.label.id)
                        .sort((a, b) => a.number - b.number);

    const navigationRule = allRules.find(e => e.actionType === RULE_ACTION_TYPE.navigateNextOnComplete.id);
    const isNavigationRuleOn = !!navigationRule && !navigationRule.deleted

    const options = useMemo(() => {
        return allNodes
            .filter(a => a.type === NODE_TYPE_OPTIONS.ProcedureStep || a.type === NODE_TYPE_OPTIONS.ProcedureTask || a.type === NODE_TYPE_OPTIONS.ProcedureRoot)
            .map((item) => {
                let name = `${item.type.replace('Procedure', '')} #${item.number} - ${item.name}`;
                if(item.type === NODE_TYPE_OPTIONS.ProcedureRoot) {
                    name = "All steps/tasks";
                }

                return {
                    id: item.id,
                    name: name,
                    order: item.number,
                }
            })
    }, [allNodes])

    if(!collection) {
        return  <></>
    }

    const renderPanelActions = () => {
        return (<>
            {
                !disabled &&
                <TbfOptionsMenu>
                    <GraphDelete
                        nodeId={ruleId}
                        friendlyName={strings.procedure.settings.collection.collectionDeleteName}
                        menuItem={true}
                    />
                </TbfOptionsMenu>
            }
        </>)
    }

    const onNavigateNextOnCompleteChange = (e) => {
        const checked = e.target.checked;
        if(checked && navigationRule) {
            patch({
                id: navigationRule.id,
                deleted: false,
            });
        } else if(!checked) {
            patch({
                id: navigationRule.id,
                deleted: true,
            });
        } else {
            const newChildRule = createRule(collection, procedure, ruleSchema, {
                actionType: RULE_ACTION_TYPE.navigateNextOnComplete.id,
                alwaysOn: true,
                nodeIds: [ruleId],
            })
            patch(newChildRule);
        }
    }

    return (
        <>
            <Panel title={`R#${collection.number} - Custom Label Set`} cypressElement={'LabelRuleSet'} renderPanelActions={renderPanelActions}>
                <PanelSection>
                    <>
                        <NodeTextField
                            nodeId={collection.id}
                            nodePropertyName={'nodeIds'}
                            disabled={disabled}
                            visual={'select'}
                            nodeValueOptions={options}
                        />
                        {
                            labelRules.map((rule) => <ProcedureCompleteCustomLabelEditor disabled={disabled} key={rule.id} labelRule={rule}/>)
                        }

                        <TbfToggle
                            id={'navigateNextOnComplete'}
                            label={RULE_ACTION_TYPE.navigateNextOnComplete.name}
                            checked={isNavigationRuleOn}
                            disabled={disabled}
                            onChange={onNavigateNextOnCompleteChange}
                        />
                    </>
                </PanelSection>
            </Panel>
        </>
    )
}

export const ProcedureCompleteLabelCollections = ({disabled, procedureId}) => {
    const currentStyle = useCompleteActionStyle(procedureId)
    const completeLabels = useChildRulesByActionOrNull(procedureId, RULE_ACTION_TYPE.completeLabels.id)
        .filter(e => !e.deleted)

    const addNew = useCallbackCreateOrRestoreRule(procedureId, RULE_ACTION_TYPE.completeLabels.id, DynamicLabelRules(currentStyle), false)

    return (
        <>
            {
                completeLabels?.map((collection) => <ProcedureCompleteLabelCollection key={collection.id} disabled={disabled} collection={collection} procedureId={procedureId} />)
            }
            <AddAction
                label={strings.procedure.rule.addLAnotherLabelSetButton}
                title={strings.procedure.rule.addLAnotherLabelSetButton}
                canAdd={true}
                onClick={addNew}
                disabled={disabled}
                dataCy={'add-label-set'}
            />
        </>
    )
}