import React from "react";
import withStyles from '@mui/styles/withStyles';
import NodeTextField from "../../graph/NodeTextField";
import {Panel} from "tbf-react-library";
import {connect} from "react-redux";
import {strings} from "../../components/SopLocalizedStrings";
import ProcedureRuleCustomKey from "../rules/ProcedureRuleCustomKey";

class ProcedureSettingsGeneral extends React.Component {

    render() {
        const {procedureId, disabled} = this.props;

        return <>
            <Panel title={strings.procedure.show.settingsGeneral}>
                <NodeTextField nodeId={procedureId} nodePropertyName={'name'} disabled={disabled}/>
                <NodeTextField nodeId={procedureId} nodePropertyName={'procedureType'} disabled={disabled}/>
                <NodeTextField nodeId={procedureId} nodePropertyName={'category'} disabled={disabled}/>
            </Panel>
            <ProcedureRuleCustomKey procedureId={procedureId} disabled={disabled}/>
        </>;
    }
}

const styles = () => ({});

ProcedureSettingsGeneral.propTypes = {};
const mapStateToProps = () => {

    return {};
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedureSettingsGeneral));
