import React, {useCallback, useEffect, useRef, useState} from 'react';
import withStyles from '@mui/styles/withStyles';
import Camera from 'react-html5-camera-photo';
import './camera.css';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useResizeObserver from "../../hooks/useResizeObserver";
import useDebounceCallback from "../../hooks/useDebounceCallback";

const idealResolution = [{width: 1920, height: 1440}];
/**
 * Breaking this into its own component to avoid re-render flashing issue.
 * @param onTakePhoto
 * @param onCameraError
 * @param idealFacingMode
 * @param onOpen
 * @returns {JSX.Element}
 * @constructor
 */
const TakePhotoCameraCamera = ({onTakePhoto, onCameraError, idealFacingMode, onCameraStart}) => {
    return (<Camera
        onTakePhoto={onTakePhoto}
        onCameraError={onCameraError}
        // idealFacingMode = {FACING_MODES.ENVIRONMENT}
        idealFacingMode={idealFacingMode}
        onCameraStart={onCameraStart}
        // onCameraStop = { onClose }
        //idealResolution={idealResolution}
        isMaxResolution={true}
    />)
}

const TakePhotoCamera = ({classes, handleTakePhoto, aspectRatio}) => {
    const containerRef = useRef()
    const [error, setError] = useState(null)
    const [cameraOpen, setCameraOpen] = useState(false)
    const [devices, setDevices] = useState([])
    const [deviceIndex, setDeviceIndex] = useState(0)
    const [takenPhoto, setTakenPhoto] = useState(null)
    const [containerSize, setContainerSize] = useState(null)

    const measureContainer = useCallback(() => {
        if (containerRef.current) {
            const box = containerRef.current.getBoundingClientRect();
            setContainerSize({width: box.width, height: box.height})
        }
    })

    const onOpen = useCallback(() => {
        setCameraOpen(true)
        measureContainer()
        window.setTimeout(measureContainer, 100)
        window.setTimeout(measureContainer, 1000)
    }, [setCameraOpen, measureContainer])

    const onClose = useCallback(() => {
        setCameraOpen(false)
    }, [setCameraOpen])

    const onAccept = useCallback(() => {
        handleTakePhoto(takenPhoto)
        setTakenPhoto(null)
    }, [handleTakePhoto, takenPhoto, setTakenPhoto])

    const onDiscard = useCallback(() => {
        setTakenPhoto(null)
    }, [setTakenPhoto])

    const onFlipCamera = useCallback(() => {
        setDeviceIndex((deviceIndex + 1) % devices.length)
    }, [deviceIndex, setDeviceIndex, devices])

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(devices => {
            const useDevices = devices.filter(a => a.kind === "videoinput")
            setDevices(useDevices)
        })
    }, [])

    useResizeObserver(containerRef, useDebounceCallback(() => {
        window.setTimeout(measureContainer, 100)
    }, 200, []))

    const measuredRef = useCallback(node => {
        if (node !== null) {
            const box = node.getBoundingClientRect();
            setContainerSize({width: box.width, height: box.height})
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            measureContainer();
        }, 2000)
    }, [deviceIndex])

    if (devices.length == 0) {
        return null
    }

    const leftCropStyle = {}
    const rightCropStyle = {}

    if (containerSize && aspectRatio) {
        const containerAspectRatio = containerSize.width / containerSize.height;
        if (containerAspectRatio > aspectRatio) {
            // Container is WIDER so lets trim the width
            // Lets say Container is 5 / 2 = 2.5, but we want  2 / 1 = 2.
            // Then we want 100% of the height, and 4 / 2 the width, so remove 1 / 5 / 2 = 10% from each side?
            // Man this is doing my head in. I am glad I am not a 3D model coder.
            const widthPercentage = (100 - (100 / containerAspectRatio * aspectRatio)) / 2;
            leftCropStyle.width = widthPercentage + "%";
            leftCropStyle.height = '100%';
            rightCropStyle.width = widthPercentage + "%";
            rightCropStyle.height = '100%';
            rightCropStyle.top = 0;
        } else {
            const heightPercentage = (100 - (100 / aspectRatio * containerAspectRatio)) / 2;
            leftCropStyle.width = "100%";
            leftCropStyle.height = heightPercentage + "%";
            rightCropStyle.width = '100%';
            rightCropStyle.height = heightPercentage + "%";
            rightCropStyle.bottom = 0;
        }
    }

    return (
        <div className={classes.cameraContainer} ref={measuredRef}>
            {aspectRatio && <div className={classes.leftCrop} style={leftCropStyle} data-cy={'inline-camera-aspect-ratio-left-' + aspectRatio}></div>}
            {takenPhoto && <React.Fragment>
                <img className={classes.imageTaken} src={takenPhoto}/>
                    <div className={classes.imageTakenAcceptContainer}>
                    <div id="outer-circle">
                        <div id="inner-circle" className="">
                            <IconButton
                                onClick={onAccept}
                                className={classes.imageTakenAcceptButton}
                                title={'Use photo'}
                                data-cy={'use-photo'}
                            >
                                <CheckCircleIcon/>
                            </IconButton>
                        </div>
                    </div>
                </div>
                {
                    <IconButton
                        onClick={onDiscard}
                        className={classes.imageTakenDiscard}
                        title={'Re-take photo'}
                        data-cy={'re-take-photo'}
                    >
                        <ArrowBackIcon/>
                    </IconButton>
                }
            </React.Fragment>}
            <div ref={containerRef} data-cy={'inline-camera'}>
                <TakePhotoCameraCamera
                    onTakePhoto={setTakenPhoto}
                    onCameraError={setError}
                    idealFacingMode={devices[deviceIndex].deviceId}
                    onCameraStart={onOpen}
                />
            </div>
            {aspectRatio && <div className={classes.rightCrop} style={rightCropStyle} data-cy={'inline-camera-aspect-ratio-right-' + aspectRatio}/>}
            {
                devices.length > 1 && !takenPhoto &&
                <IconButton onClick={onFlipCamera} className={classes.flipCamera} title={'Switch camera'}>
                    <FlipCameraIosIcon/>
                </IconButton>
            }
        </div>
    );
}

const styles = () => ({
    ".react-html5-camera-photo": {
        '&video': {
            width: "100%"
        }
    },
    cameraContainer: {
        position: 'relative'
    },
    flipCamera: {
        position: "absolute",
        left: "calc(50% + 100px)",
        bottom: "20px",
        color: "white",
        '& svg': {
            fontSize: "2.5em"
        },
        zIndex: 15
    },
    leftCrop: {
        position: "absolute",
        backgroundColor: "white",
        opacity: 0.7,
        zIndex: 10
    },
    rightCrop: {
        position: "absolute",
        right: 0,
        backgroundColor: "white",
        opacity: 0.7,
        zIndex: 10
    },
    imageTaken: {
        width: "100%",
        height: "100%",
        position: "absolute"
    },
    imageTakenAcceptContainer: {
        position: "absolute",
        left: "50%",
        bottom: "90px",
        zIndex: 20
    },
    imageTakenAcceptButton: {
        '& svg': {
            left: "-5px",
            position: "absolute",
            top: "-9px;",
            fontSize: "54px",
            color: "#3F25F2",
        },
    },
    imageTakenDiscard: {
        position: "absolute",
        left: "calc(50% - 120px)",
        bottom: "20px",
        color: "black",
        background: "white",
        '& svg': {
            fontSize: "2em"
        },
        zIndex: 15
    },
    "#container-circles": {
        zIndex: 15
    }
})

export default withStyles(styles)(TakePhotoCamera);
