import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import SwipeableViews from "react-swipeable-views";
import {virtualize} from 'react-swipeable-views-utils';
import ExecutionStep from "../execution/ExecutionStep";
import {cypress} from "tbf-react-library";

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

class SwipableTabs extends Component {

    slideRenderer = ({index}) => {
        let {visibleStepsIds, tabSelectedIndex, troubleshootOn, diagnosticsOn} = this.props;
        if (index >= visibleStepsIds.length) {
            return <React.Fragment key={index}/>
        }
        const nodeId = visibleStepsIds[index];
        return <ExecutionStep
            key={nodeId}
            stepId={nodeId}
            hideStep={(tabSelectedIndex !== index)}
            troubleshootOn={troubleshootOn}
            diagnosticsOn={diagnosticsOn}
        />
    }

    render() {
        let {tabSelectedIndex, onChangeIndex, classes, visibleStepsIds} = this.props;
        const animateTransitions = cypress.isAnimateTabTransitionEnabled();

        return (
            <VirtualizeSwipeableViews
                overscanSlideAfter={1}
                overscanSlideBefore={1}
                slideCount={visibleStepsIds.length}
                animateTransitions={animateTransitions}
                index={tabSelectedIndex}
                onChangeIndex={(changedIndex) => onChangeIndex(null, changedIndex, false)}
                slideRenderer={this.slideRenderer}
                className={classes.swipeContainer}/>
        )
    }
}

const styles = () => ({
    swipeContainer: {
        paddingBottom: '50px',
        '& .react-swipeable-view-container': {
            '& div[data-swipeable="true"][aria-hidden="true"]': {
                height: 0,
            },
            '& div[data-swipeable="true"][aria-hidden="false"]': {
                minHeight: 'calc(100vh - 300px)',
                paddingBottom: 20,
            }
        }
    }
});

export default withStyles(styles)(SwipableTabs);
