import React, {useState} from "react";
import withStyles from '@mui/styles/withStyles';
import {useNodeOrNull, useReportEventCallback} from "../../hooks/nodeHooks";
import {useCallbackUpdateSelectedView, useViewOptions} from "./ExecutionViewHooks";
import {TbfSelectDeviceFriendly} from "tbf-react-library";
import { REPORT_EVENT_TYPES } from "../../util/constants";

/**
 * Let the user select from a list of views (e.g. Recently created)
 *
 * @param classes
 * @param selectorId
 * @returns {JSX.Element}
 * @constructor
 */
const CollectionViewSelector = ({classes, selectorId}) => {
    const [inputSearchTerm, setInputSearchTerm] = useState(null)
    const selector = useNodeOrNull(selectorId);
    const options = useViewOptions(selectorId);
    const selectedOption = options.find(d => d.value === selector?.selectedViewId)
    const updateSelectorView = useCallbackUpdateSelectedView(selectorId);
    const reportEvent = useReportEventCallback();
    const handleFilterChange = (option) => {
        reportEvent(selector?.procedureId, REPORT_EVENT_TYPES.listingPageViewChanged.name, {viewId: option.value, viewName: option.label});
        updateSelectorView(option);
    }
    if (options.length <= 1) {
        return null
    }
    return (<>
        {
            options && options.length > 0 &&
            <TbfSelectDeviceFriendly
                onChange={handleFilterChange}
                propertyName={'workItemFilter'}
                label={'Select'}
                options={options}
                value={selectedOption}
                inputValue={inputSearchTerm}
                handleSearchTermChange={setInputSearchTerm}
                closeMenuOnSelect={true}
                isSearchable={false}
                mobileView={true}
                menuPortalTarget={document.body}
                customSelectContainerClass={classes.filterSelectBox}
            />
        }
    </>);
}
const styles = (theme) => ({
    filterSelectBox: {
        width: 200,
        background: theme.palette.primary.two.main,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
        '& .basic-single': {
            margin: '0 !important',
        },
    },
});
export default withStyles(styles)(CollectionViewSelector);
