import {SharedAuth} from "tbf-react-library";


const includePort = window.location.port && window.location.port !== "80" && window.location.port !== "443"
export const ROUTES = {
    basePath: `${window.location.protocol}//${window.location.hostname}${includePort ? ':' + window.location.port : ''}/app`,
    sharing: (procedure, anonymous) => {
        const proceureName = ROUTES.procedureName(procedure.name)
        const clientId = SharedAuth.getClientId()
        const relativeUrl = `/${clientId}/main/sharing/${procedure.id}/${proceureName}/new`
        return anonymous ? '/public' + relativeUrl : relativeUrl;
    },
    procedureDashboard: (procedure) => {
        return `/dashboard/${procedure.id}/${ROUTES.procedureName(procedure.name)}`;
    },
    procedureName: (procedure) => {
        return procedure.toLowerCase()
            .replaceAll(' ', '-')
            .replaceAll('--', '-')
            .replace(new RegExp(/[^0-9a-z-]/g), '')
            .trim();
    },
    execution: (execution) => {
        return `/executions/${execution.id}`;
    },
    executionSubmitted: (execution, anonymous, returnUrl) => {
        const procedureName = ROUTES.procedureName(execution.name)
        const path = anonymous ?
            `/public/${SharedAuth.getClientId()}/${SharedAuth.getTenantId()}/${procedureName}/items/${execution.key}/submitted${returnUrl ? `?returnUrl=${returnUrl}` : ""}` :
            `/executions/${execution.id}/submitted`
        return path;
    }
}