// in src/Avatar.js
import React from 'react';
import MuiAvatar from '@mui/material/Avatar';
import withStyles from '@mui/styles/withStyles';
import {getNameInitials} from "../../../util/util";

const styles = (theme) => ({
    avatar: {
        width: 25,
        height: 25,
    },
    userInitials: {
        marginRight: 6,
        width: 24,
        height: 24,
        fontSize: 12,
        backgroundColor: '#6897d8',
        //background: theme.palette.primary.one.main,
        '& .teams': {
            background: `${theme.palette.grey.five.main} !important`,
        }
    },
});

const AvatarView = ({user, classes, name, type}) => {
    const useName = name || user?.name
    const initials = getNameInitials(useName)
    return <MuiAvatar className={classes.userInitials} title={useName}>{initials}</MuiAvatar>
}

const TbfAvatar = withStyles(styles)(AvatarView);

export default TbfAvatar;