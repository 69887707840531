import React, {useState} from "react";
import ExecutionAssignmentDialogWrapper from "../../../execution/assignments/ExecutionAssignmentDialogWrapper";
import GroupIcon from '@mui/icons-material/GroupRounded';
import BulkActionMenuItem from "./BulkActionMenuItem";
import {summaryToFullId} from "../../../../factory/executionFactory";
import GraphResourceLoad from "../../../graph/GraphResourceLoad";
import {strings} from "../../../components/SopLocalizedStrings";

export default function BulkActionAssign({action, selectedRowIds, ...props}) {
    const [assigningNodeIds, setAssigningNodeIds] = useState([]);

    const onClick = () => {
        setAssigningNodeIds(selectedRowIds.map((id) => summaryToFullId(id)));
    }

    const onDialogClose = () => {
        setAssigningNodeIds([]);
    }

    return <>
        {assigningNodeIds[0] && <GraphResourceLoad
            key={assigningNodeIds[0]}
            resourcePath={`/executions?id=${assigningNodeIds[0]}`}
            friendlyName={strings.execution.name}
            nodeId={assigningNodeIds[0]}
            nodeType={'ExecutionRoot'}
            hideOfflineWarnings={false}
            disableLoader
        />}
        {(assigningNodeIds.length > 0) && <ExecutionAssignmentDialogWrapper
            displayOpen={false}
            nodeIds={assigningNodeIds}
            handleClose={onDialogClose}
        />}
        <BulkActionMenuItem
            selectedRowIds={selectedRowIds}
            action={action}
            onClick={onClick}
            icon={<GroupIcon/>}
            cyName={'bulk-action-assign'}
            {...props}
        >
            {action.name}
        </BulkActionMenuItem>
    </>
}