import React from "react";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import {MenuItem, MenuList} from "@mui/material";
import {convertOptionObjectToSelectOptions} from "../../../util/util";
import {strings} from "../../components/SopLocalizedStrings";
import ProcedureSettingsSearch from "./ProcedureSettingsSearch";
import ProcedureSettingsCollectionDesign from "./ProcedureSettingsCollectionDesign";
import ProcedureSecurityPanel from "../security/ProcedureSecurityPanel";
import ProcedureSettingsGeneral from "./ProcedureSettingsGeneral";
import {getNodeOrNull} from "../../../selectors/graphSelectors";
import {putNodeProperty} from "../../../actions";
import {connect} from "react-redux";
import ProcedureSettingsDesign from "./ProcedureSettingsDesign";
import ProcedureActionsPanel from "../manualActions/ProcedureActionsPanel";

const PROCEDURE_SHOW_SETTINGS_OPTIONS = {
    general: {id: 'general', name: strings.procedure.show.settingsGeneral},
    search: {id: 'search', name: strings.procedure.show.settingsSearch},
    design: {id: 'design', name: strings.procedure.show.settingsDesign},
    collection: {id: 'collection', name: strings.procedure.show.settingsCollectionDesign},
    security: {id: 'security', name: strings.procedure.settings.security.tabTitle},
    actions: {id: 'actions', name: strings.procedure.settings.actions.tabTitle},
};

class ProcedureSettings extends React.Component {

    selectMenu = (menu) => {
        let {onPutNodeProperty, procedureId} = this.props;
        onPutNodeProperty({id: procedureId, selectedSettingTab: menu.value})
    }

    render() {
        const {classes, procedureId, disabled, selectedSettingTab} = this.props;
        const menuList = convertOptionObjectToSelectOptions(PROCEDURE_SHOW_SETTINGS_OPTIONS);

        return <Grid container className={classes.settingsGrid}>
            <Grid item xs={3} sm={3} md={3} className={classes.settingsTabs}>
                <MenuList>
                    {
                        menuList.map(menu => {
                            return <MenuItem
                                onClick={() => this.selectMenu(menu)}
                                className={`${classes.menuItem} ${menu.value === selectedSettingTab ? classes.menuSelected : ''}`}
                                key={menu.value}
                            >
                                {menu.label}
                            </MenuItem>
                        })
                    }
                </MenuList>
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
                {
                    selectedSettingTab === PROCEDURE_SHOW_SETTINGS_OPTIONS.general.id &&
                    <ProcedureSettingsGeneral procedureId={procedureId} disabled={disabled}/>
                }
                {
                    selectedSettingTab === PROCEDURE_SHOW_SETTINGS_OPTIONS.search.id &&
                    <ProcedureSettingsSearch procedureId={procedureId} disabled={disabled}/>
                }
                {
                    selectedSettingTab === PROCEDURE_SHOW_SETTINGS_OPTIONS.design.id &&
                    <ProcedureSettingsDesign nodeId={procedureId} disabled={disabled}/>
                }
                {
                    selectedSettingTab === PROCEDURE_SHOW_SETTINGS_OPTIONS.collection.id &&
                    <ProcedureSettingsCollectionDesign nodeId={procedureId} disabled={disabled}/>
                }
                {
                    selectedSettingTab === PROCEDURE_SHOW_SETTINGS_OPTIONS.security.id &&
                    <ProcedureSecurityPanel procedureId={procedureId} disabled={disabled}/>
                }
                {
                    selectedSettingTab === PROCEDURE_SHOW_SETTINGS_OPTIONS.actions.id &&
                    <ProcedureActionsPanel procedureId={procedureId} disabled={disabled} />
                }
            </Grid>
        </Grid>
    }
}

const styles = theme => ({
    settingsGrid: {
        position: 'relative',
    },
    settingsTabs: {
        padding: theme.spacing(2) - 8,
    },
    menuItem: {
        color: theme.palette.grey.seven.main,
        borderRadius: theme.spacing(1),
    },
    menuSelected: {
        background: theme.palette.secondary.four.main,
        color: theme.palette.primary.two.main,
    },
    settingsContent: {
        background: theme.palette.primary.two.main,
        padding: '15px 20px 30px 20px',
        marginTop: theme.spacing(2),
    },
});


const mapStateToProps = (state, ownProps) => {
    let node = getNodeOrNull(state, ownProps.procedureId);
    return {
        selectedSettingTab: node?.selectedSettingTab || PROCEDURE_SHOW_SETTINGS_OPTIONS.general.id,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedureSettings));
