import React from 'react';
import Button from "@mui/material/Button";
import {connect} from "react-redux";
import withStyles from '@mui/styles/withStyles';
import {withRouter} from "react-router";
import moment from "moment";
import {Panel, SharedAuth, TbfAction, withMobile} from "tbf-react-library";
import Loader from "../components/Loader";
import EmptyRecentActivityImage from "../../assets/emptyRecentActivityImage.PNG";
import ExpandLess from "@mui/icons-material/ExpandLessRounded";
import ExpandMore from "@mui/icons-material/ExpandMoreRounded";
import {defaultValue, dynamicSort} from "../../util/util";
import EmptyMessage from './EmptyMessage'
import ActivityCard from './ActivityCard'
import {strings} from "../components/SopLocalizedStrings";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {getNodeOrNull, getNodesById} from "../../selectors/graphSelectors";
import {MINUTES_5, REPORT_EVENT_TYPES} from "../../util/constants";

const activityShownItems = 5;

class RecentActivities extends React.Component {
    state = {
        numberOfActivitiesShown: activityShownItems,
    }

    renderActivityCard = (activity, index) => {
        const userId = SharedAuth.getUserId();
        let {classes} = this.props;
        let userName;
        if (activity.userId === userId) {
            userName = strings.execution.activity.nameSelf;
        } else if (activity.user && activity.user.name) {
            userName = activity.user.name
        } else {
            userName = strings.execution.activity.nameNull;
        }
        return (
            <ActivityCard {...activity} key={index}>
                <span
                    data-cy="recent-activity-action"
                    className={classes.activityCardLastViewedTime}>{
                    activity.activityTitle + ' ' + strings.execution.activity.joinWord + ' ' + userName
                } {activity.activityDateTime ? moment(activity.activityDateTime).fromNow() : ''}</span>

            </ActivityCard>
        )
    }

    renderActivitySection = () => {
        const {classes, items} = this.props;
        const {numberOfActivitiesShown} = this.state;
        const seeMoreVisible = items.length > activityShownItems;
        const itemsSorted = [...items];
        itemsSorted.sort(dynamicSort('-activityDateTime'));
        const activities = seeMoreVisible ? itemsSorted.slice(0, numberOfActivitiesShown) : itemsSorted;
        const isShowingMore = numberOfActivitiesShown === items.length;

        return <React.Fragment>
            <div className={classes.container} role="list">
                {
                    activities.map((activity, index) => this.renderActivityCard(activity, index))}
            </div>
            <div className={classes.container}>
                {
                    seeMoreVisible &&
                    <TbfAction
                        size="small"
                        className={classes.showMoreActivityButton}
                        title={isShowingMore ? strings.buttons.showLess : strings.buttons.showMore}
                        endIcon={(isShowingMore ? <ExpandLess/> : <ExpandMore/>)}
                        onClick={() => {
                            this.setState({
                                numberOfActivitiesShown: (isShowingMore ? activityShownItems : items.length)
                            });
                        }}
                        eventName={isShowingMore ? REPORT_EVENT_TYPES.dashboardPageAssignedShowLess.name : REPORT_EVENT_TYPES.dashboardPageAssignedShowMore.name}
                    >
                        {isShowingMore ? strings.buttons.showLess : strings.buttons.showMore}
                    </TbfAction>
                }
            </div>
        </React.Fragment>
    }


    render() {
        const {classes, items, loading, className} = this.props;
        let titleLoader = loading && items.length > 0;
        return (
            <Panel title={strings.dashboard.recent} loading={titleLoader} className={className}>
                <div className={classes.container}>
                    <GraphResourceLoad
                        resourcePath={NODE_IDS.MyActivityRecent}
                        friendlyName={strings.activity.namePlural}
                        nodeType={NODE_TYPE_OPTIONS.NodeActivity}
                        hideLoader={true}
                        hideOfflineWarnings={false}
                        reloadIntervalMs={MINUTES_5}>
                        {
                            !loading && items.length === 0 && (
                                <EmptyMessage
                                    message='No recent items yet'
                                    secondaryMessage='Your recent work will show up here'>
                                    <img src={EmptyRecentActivityImage} alt={''}/>
                                </EmptyMessage>
                            )
                        }
                        {
                            items.length > 0 && this.renderActivitySection()
                        }
                    </GraphResourceLoad>
                    {
                        loading && items.length === 0 &&
                        <div>
                            <Loader loaderContainerStyleClass={classes.loadingIndicator} circular={true}
                                    contentCentered={true} circularSize={32} source={'recent activity'}/>
                        </div>
                    }
                </div>
            </Panel>
        )
    }

}

const styles = (theme) => ({
    container: {
        width: '100%',
        float: 'left',
        '& .emptyMessageContainer': {
            paddingTop: 'calc(10vh - 20px)',
            '@media (min-height: 900px)': {
                paddingTop: 'calc(10vh - 55px)',
            },
        },
    },
    paddingLeft0: {
        paddingLeft: 0
    },
    activityCardLastViewedTime: {
        display: 'block',
        position: 'absolute',
        bottom: 3,
        right: 8,
        color: '#575757',
        fontSize: 12
    },
    showMoreActivityButton: {
        fontSize: '0.75rem',
        padding: '4px 8px',
        color: theme.palette.grey.five.main,
        textTransform: 'none',
        marginTop: 10,
        border: 'none !important',
    },
    loadingIndicator: {
        minHeight: 300
    }
});
const mapStateToProps = (state) => {
    let resourcesSync = getNodeOrNull(state, NODE_IDS.MyActivityRecent);
    let ids = resourcesSync?.nodeIds || [];
    let items = getNodesById(state, ids);
    return {
        loading: defaultValue(resourcesSync?.loading, false),
        items: items,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withMobile()(withRouter(RecentActivities))));
