import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {NODE_IDS} from "../../reducers/graphReducer";
import {Permissions} from "../../permissions";
import {ComponentBase, cypress, reportEvent, SharedAuth} from "tbf-react-library";
import {formatBytes, isOnline} from "../../util/util";

class GraphStatistics extends ComponentBase {

    constructor(props) {
        super(props);
        this.state = {};
        this.getStorage.bind(this);
        this.interval = setInterval(() => this.getStorage(false), 60000);
        this.interval2 = setInterval(() => this.getStorage(true), 60 * 60000);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearInterval(this.interval);
    }

    componentWillMount() {
        this.getStorage(true)
    }

    reportedBefore = false;

    getStorage(reportSize) {
        // Avoid hitting indexeddb when inactive
        if (document.hidden) {
            return;
        }
        if (navigator && navigator.storage && navigator.storage.estimate) {
            navigator.storage.estimate().then(function (estimate) {
                let usageP = estimate.usage * 100 / estimate.quota;
                let p = (usageP).toFixed(2);
                if (this.unmounted) {
                    return;
                }
                this.setState({usedStoragePercentage: p, quota: estimate.quota, usage: estimate.usage});
                if (usageP > 80 && this.reportedBefore === false && isOnline()) {
                    this.reportedBefore = true;
                    reportEvent({
                        name: 'IndexeddbFullWarning',
                        usage: estimate.usage,
                        quota: estimate.quota,
                        percentage: usageP,
                        usageDetails: estimate.usageDetails
                    })
                }
                if (reportSize && isOnline()) {
                    reportEvent({
                        name: 'IndexeddbUsage',
                        usage: estimate.usage,
                        quota: estimate.quota,
                        percentage: usageP,
                        usageDetails: estimate.usageDetails
                    })
                }
            }.bind(this));
        }
        if (navigator && navigator.storage && navigator.storage.persisted) {
            navigator.storage.persisted().then(result => {
                this.setState({storagePersisted: result});
            })
        }
    }

    render() {
        const {nodeCount, canReadDiagnostic, pendingStoreDbNodeCount, pendingUserSaveNodeCount} = this.props;
        const Container = this.props.container || React.Fragment;
        let {usedStoragePercentage, quota, usage, storagePersisted} = this.state;
        let persistedMsg = storagePersisted == null ? '' : (storagePersisted ? ' persistence on' : 'persistence off');
        return <Container>
            <span>
                {usedStoragePercentage != null && <span
                    title={`Using ${formatBytes(usage)} of ${formatBytes(quota)} ${persistedMsg}`}>Storage Used {usedStoragePercentage}%</span>}
                {canReadDiagnostic && <span>| {nodeCount} Nodes</span>}
                {canReadDiagnostic && <span>| <span data-cy="pendingStoreDbNodeCount"
                                                    data-pending-indexeddb-save-count={pendingStoreDbNodeCount}>{pendingStoreDbNodeCount}</span> IndexedDb Requests</span>}
                {canReadDiagnostic &&
                    <span>| <span data-cy="pendingUserSaveNodeCount"
                                  data-pending-server-save-count={pendingUserSaveNodeCount}>{pendingUserSaveNodeCount}</span> Unsaved</span>}
            </span>
        </Container>
    }
}

const styles = () => ({});
GraphStatistics.propTypes = {};
const mapStateToProps = (state) => {
    let canReadDiagnostic = SharedAuth.userHasPermission(Permissions.diagnostic.read) || cypress.isTimerEnabled();
    return {
        userSettings: getNodeOrNull(state, NODE_IDS.UserSettings),
        canReadDiagnostic: canReadDiagnostic,
        pendingStoreDbNodeCount: state.graph.pendingStoreDbNodeCount,
        pendingUserSaveNodeCount: state.graph.pendingUserSaveNodeCount
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphStatistics));
