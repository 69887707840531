import React from "react";
import {strings} from "../../components/SopLocalizedStrings";
import {useCallbackCreateRule} from "../../../hooks/procedureHooks";
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import {AddAction} from "tbf-react-library";

const pageStrings = strings.procedure.settings.security;
const ProcedureSecurityAddAction = ({parentRuleId, disabled}) => {
    const createNew = useCallbackCreateRule(parentRuleId, {
        actionType: RULE_ACTION_TYPE.grant.id,
        alwaysOn: true,
        calculateValueOn: true
    })
    if (disabled) {
        return null
    }
    return <>
        <AddAction
            label={pageStrings.addLabel}
            title={pageStrings.addTitle}
            canAdd={!disabled}
            onClick={createNew}
            dataCy={'add-security'}
        />
    </>;
}
export default ProcedureSecurityAddAction;
