import merge from "lodash/merge";
import {BasicConfig,} from "react-awesome-query-builder";
import {ruRU} from "@mui/material/locale";
import enUS from "antd/lib/locale/en_US";

import AntdConfig from 'react-awesome-query-builder/lib/config/antd'

import MaterialConfig from "react-awesome-query-builder/lib/config/material";
import {DATE_FORMAT_STORED, TIME_FORMAT_STORED} from "../../../reducers/graphReducer";

const skinToConfig = {
    vanilla: BasicConfig,
    antd: AntdConfig,
    material: MaterialConfig,
};

const getConfig = (skin) => {
    const InitialConfig = skinToConfig[skin];

    const conjunctions = {
        ...InitialConfig.conjunctions,
    };

    const proximity = {
        ...InitialConfig.operators.proximity,
        valueLabels: [
            {label: "Word 1", placeholder: "Enter first word"},
            {label: "Word 2", placeholder: "Enter second word"},
        ],
        textSeparators: [
            //'Word 1',
            //'Word 2'
        ],
        options: {
            ...InitialConfig.operators.proximity.options,
            optionLabel: "Near", // label on top of "near" selectbox (for config.settings.showLabels==true)
            optionTextBefore: "Near", // label before "near" selectbox (for config.settings.showLabels==false)
            optionPlaceholder: "Select words between", // placeholder for "near" selectbox
            minProximity: 2,
            maxProximity: 10,
            defaults: {
                proximity: 2
            },
            customProps: {}
        }
    };

    const operators = {
        ...InitialConfig.operators,
        // examples of  overriding
        proximity,
        between: {
            ...InitialConfig.operators.between,
            valueLabels: [
                "Value from",
                "Value to"
            ],
            textSeparators: [
                "from",
                "to"
            ],
        },
        // As this is not supported when converting jsonlogic to tree, pretend it is select_many
        multiselect_any: {
            ...InitialConfig.operators.select_any_in,
            label: "Any in",
            labelForFormat: "Any",
            reversedOp: "multiselect_not_any"
        },
        multiselect_not_any: {
            ...InitialConfig.operators.select_not_any_in,
            label: "Not any in",
            labelForFormat: "Not any in",
            reversedOp: "multiselect_any",
        },
        // override label
        multiselect_equals: {
            ...InitialConfig.operators.multiselect_equals,
            label: "All in",
            labelForFormat: "All",
            valueSources: ["value", "field"]
        },
        // override label
        multiselect_not_equals: {
            ...InitialConfig.operators.multiselect_not_equals,
            label: "Not all in",
            labelForFormat: "Not all",
            valueSources: ["value", "field"]
        },
        is_null: {
            label: "Is empty",
            labelForFormat: "IS EMPTY",
            sqlOp: "IS NULL",
            cardinality: 0,
            reversedOp: "is_not_null",
            formatOp: (field, op, value, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
                return isForDisplay ? `${field} is empty` : `!${field}`;
            },
            jsonLogic: "==",
        },
        is_not_null: {
            label: "Is not empty",
            labelForFormat: "IS NOT EMPTY",
            sqlOp: "IS NOT NULL",
            cardinality: 0,
            reversedOp: "is_null",
            formatOp: (field, op, value, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
                return isForDisplay ? `${field} is not empty` : `!!${field}`;
            },
            jsonLogic: "!=",
            elasticSearchQueryType: "exists",
        },
    };


    const widgets = {
        ...InitialConfig.widgets,
        // examples of  overriding
        text: {
            ...InitialConfig.widgets.text,
        },
        slider: {
            ...InitialConfig.widgets.slider,
            customProps: {
                width: "300px"
            }
        },
        rangeslider: {
            ...InitialConfig.widgets.rangeslider,
            customProps: {
                width: "300px"
            },
        },
        date: {
            ...InitialConfig.widgets.date,
            dateFormat: "DD.MM.YYYY",
            valueFormat: "DD-MM-YYYY",
        },
        time: {
            ...InitialConfig.widgets.time,
            timeFormat: "HH:mm",
            valueFormat: "HH:mm:ss",
        },
        datetime: {
            ...InitialConfig.widgets.datetime,
            timeFormat: "HH:mm",
            dateFormat: "DD.MM.YYYY",
            valueFormat: "YYYY-MM-DD HH:mm:ss",
        },
        func: {
            ...InitialConfig.widgets.func,
            customProps: {
                showSearch: true
            }
        },
        treeselect: {
            ...InitialConfig.widgets.treeselect,
            customProps: {
                showSearch: true
            }
        },

    };


    const types = {
        ...InitialConfig.types,
        // examples of  overriding
        boolean: merge(InitialConfig.types.boolean, {
            widgets: {
                boolean: {
                    widgetProps: {
                        hideOperator: true,
                        operatorInlineLabel: "is"
                    },
                    opProps: {
                        equal: {
                            label: "is"
                        },
                        not_equal: {
                            label: "is not"
                        }
                    }
                },
            },
        }),
        multiselect: {
            defaultOperator: "multiselect_any",
            widgets: {
                multiselect: {
                    operators: [
                        "multiselect_any",
                        "multiselect_not_any",
                        "multiselect_equals",
                        "multiselect_not_equals",
                        'is_null',
                        'is_not_null',
                    ]
                }
            },
        },
        select: {
            mainWidget: "select",
            defaultOperator: "select_equals",
            widgets: {
                select: {
                    operators: [
                        "select_equals",
                        "select_not_equals",
                        "is_null",
                        "is_not_null",
                    ],
                    widgetProps: {
                        customProps: {
                            showSearch: true
                        }
                    },
                },
                multiselect: {
                    operators: [
                        "select_any_in",
                        "select_not_any_in",
                        "is_null",
                        "is_not_null",
                    ],
                },
            },
        },
        boolean_select: {
            defaultOperator: "equal",
            widgets: {
                select: {
                    operators: [
                        "equal",
                        "not_equal",
                        'is_null',
                        'is_not_null',
                    ]
                }
            },
        },
        text: {
            defaultOperator: "equal",
            widgets: {
                text: {
                    operators: [
                        "equal",
                        "not_equal",
                        "like",
                        "not_like",
                        'is_null',
                        'is_not_null',
                    ],
                    widgetProps: {},
                    opProps: {},
                },
            }
        },
        object: {
            defaultOperator: "is_not_null",
            widgets: {
                text: {
                    operators: [
                        'is_null',
                        'is_not_null',
                    ],
                    widgetProps: {},
                    opProps: {},
                },
            }
        },
        date: {
            widgets: {
                date: {
                    operators: [
                        'equal',
                        'not_equal',
                        'less',
                        'less_or_equal',
                        'greater',
                        'greater_or_equal',
                        'between',
                        'not_between',
                        'is_null',
                        'is_not_null',
                    ],
                    widgetProps: {
                        valueFormat: DATE_FORMAT_STORED,
                        jsonLogic: (val) => {
                            return val;
                        }
                    }
                },
            },
        },
        time: {
            widgets: {
                time: {
                    operators: [
                        'equal',
                        'not_equal',
                        'less',
                        'less_or_equal',
                        'greater',
                        'greater_or_equal',
                        'between',
                        'not_between',
                        'is_null',
                        'is_not_null',
                    ],
                    widgetProps: {
                        valueFormat: TIME_FORMAT_STORED,
                        jsonLogic: (val) => {
                            return val;
                        }
                    }
                },
            },
        }
    };


    const localeSettings = {
        locale: {
            moment: "en",
            antd: enUS,
            material: ruRU,
        },
        valueLabel: "Value",
        valuePlaceholder: "Value",
        fieldLabel: "Field",
        operatorLabel: "Operator",
        funcLabel: "Function",
        fieldPlaceholder: "Select field",
        funcPlaceholder: "Select function",
        operatorPlaceholder: "Select operator",
        deleteLabel: null,
        addGroupLabel: "Add group",
        addRuleLabel: "Add rule",
        delGroupLabel: null,
        notLabel: "Not",
        valueSourcesPopupTitle: "Select value source",
        removeRuleConfirmOptions: {
            title: "Are you sure delete this rule?",
            okText: "Yes",
            okType: "danger",
        },
        removeGroupConfirmOptions: {
            title: "Are you sure delete this group?",
            okText: "Yes",
            okType: "danger",
        },
    };

    const settings = {
        ...InitialConfig.settings,
        ...localeSettings,

        valueSourcesInfo: {
            value: {
                label: "Value"
            },
            field: {
                label: "Field",
                widget: "field",
            },
            func: {
                label: "Function",
                widget: "func",
            }
        },
        maxNesting: 5,
        canLeaveEmptyGroup: true,
        showErrorMessage: true,

    };

    //////////////////////////////////////////////////////////////////////

    const fields = {};
    //////////////////////////////////////////////////////////////////////

    const funcs = {};

    const config = {
        conjunctions,
        operators,
        widgets,
        types,
        settings,
        fields,
        funcs,
    };

    return config;
};
export default getConfig;
