import React from 'react';
import { TimePicker } from "tbf-react-library";
import { useInputDispatch } from './hooks';

const ExecutionQuestionTime = ({ value, format, disabled, executionQuestion, questionName, field }) => {

    const { onFocusNode, onBlurNode, updateNodeProperty } = useInputDispatch({ value, disabled, executionQuestion, questionId: executionQuestion.id });

    const handleFocus = () => {
        onFocusNode(executionQuestion.id, executionQuestion.id);
    }

    const handleBlur = () => {
        onBlurNode(executionQuestion.id, executionQuestion.id);
    }

    const handleChange = (value, field) => {
        const valueString = value?.toString?.();
        if (valueString === 'Invalid Date') {
            return;
        }
        updateNodeProperty(field, value, field);
    }

    return <TimePicker
        placeholder={!disabled ? 'e.g. 10:35 AM' : ''}
        format={format}
        id={field + '-' + executionQuestion.id}
        name={field + '-' + executionQuestion.id}
        value={value}
        onChange={(value) => handleChange(value, field)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
        readOnly={disabled}
        inputProps={{
            'data-cy': questionName + '_' + field
        }}
    />
}

export default ExecutionQuestionTime;