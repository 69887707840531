import * as React from 'react';
import * as EsriLeaflet from 'esri-leaflet';
import {FeatureLayer} from 'esri-leaflet';
import L from 'leaflet';
import EsriLeafletLayer from "./EsriLeafletLayer";
import 'leaflet.offline'
import './offline/Leaflet.Offline'

const originalRequest = FeatureLayer.prototype._requestFeatures;
export const TbfFeatureLayerService = FeatureLayer.extend({
    _requestFeatures: function (bounds, coords, callback, offset) {
        originalRequest.call(this, bounds, coords, callback, offset)
    }
});
export const tiledDynamicMapLayer = L.TileLayer.extend({

    options: L.Util.extend({},
        EsriLeaflet.ImageMapLayer.prototype.options, {
            redrawBuffer: true
        }),

    _requests: [],

    /**
     * @constructor
     * @extends {L.TileLayer}
     * @param  {String} url
     * @param  {Object} options
     */
    initialize: function (url, options) {
        L.TileLayer.prototype.initialize.call(this, url, options);
        EsriLeaflet.ImageMapLayer.prototype.initialize.call(this, url, options);
    },

    /**
     * @param  {L.Map} map
     */
    onAdd: function (map) {
        if (map.options.crs && map.options.crs.code) {
            var sr = map.options.crs.code.split(':')[1];
            this.options.bboxSR = sr;
            this.options.imageSR = sr;
        }

        map.on('zoomstart zoomend', this._onZoomChange, this);
        return L.TileLayer.prototype.onAdd.call(this, map);
    },

    /**
     * @param  {L.Map} map
     */
    onRemove: function (map) {
        map.off('zoomstart zoomend', this._onZoomChange, this);
        L.TileLayer.prototype.onRemove.call(this, map);
        EsriLeaflet.ImageMapLayer.prototype.onRemove.call(this, map);
    },

    /**
     * @param {Array.<Number>|Array.<String>} layers
     * @return {L.esri.Layers.TiledImageMapLayer} self
     */
    setLayers: function (layers) {
        EsriLeaflet.ImageMapLayer.prototype.setLayers.call(this, layers);
        return this.redraw();
    },

    /**
     * @param {Array.<Object>} layerDefs
     * @return {L.esri.Layers.TiledImageMapLayer} self
     */
    setLayerDefs: function (layerDefs) {
        EsriLeaflet.ImageMapLayer.prototype.setLayerDefs.call(this, layerDefs);
        return this.redraw();
    },

    /**
     * @param {Object} timeOptions
     * @return {L.esri.Layers.TiledImageMapLayer} self
     */
    setTimeOptions: function (timeOptions) {
        EsriLeaflet.ImageMapLayer.prototype.setTimeOptions.call(this, timeOptions);
        return this.redraw();
    },

    /**
     * Set/unset zooming flag to avoid unneeded requests
     * @param  {Object} e
     */
    _onZoomChange: function (e) {
        this._zooming = (e.type === 'zoomstart');
    },

    /**
     * @param  {L.LatLngBounds} bounds
     * @param  {L.Point}        size
     * @return {Object}
     */
    _buildExportParams: function (bounds, size) {
        var nw = this._map.options.crs.project(bounds.getNorthWest());
        var se = this._map.options.crs.project(bounds.getSouthEast());

        var params = {
            bbox: [se.x, se.y, nw.x, nw.y].join(','),
            size: size.x + ',' + size.y,
            dpi: 96,
            format: this.options.format,
            transparent: this.options.transparent,
            bboxSR: this.options.bboxSR,
            imageSR: this.options.imageSR
        };

        if (this.options.layers) {
            params.layers = 'show:' + this.options.layers.join(',');
        }

        if (this.options.layerDefs) {
            params.layerDefs = JSON.stringify(this.options.layerDefs);
        }

        if (this.options.timeOptions) {
            params.timeOptions = JSON.stringify(this.options.timeOptions);
        }

        if (this.options.from && this.options.to) {
            params.time = this.options.from.valueOf() + ',' + this.options.to.valueOf();
        }

        if (this.service.options.token) {
            params.token = this.service.options.token;
        }

        return params;
    },

    /**
     * @param  {Object}  tile
     * @param  {L.Point} tilePoint
     */
    _loadTile: function (tile, tilePoint) {
        tile._layer = this;
        tile.onload = this._tileOnLoad;
        tile.onerror = this._tileOnError;

        this._adjustTilePoint(tilePoint);
        this.getTileUrl(tilePoint, function (err, url) {
            tile.src = url;
        });

        this.fire('tileloadstart', {
            tile: tile
        });
    },

    /**
     * Async request tile url
     * @param  {L.Point}  tilePoint
     * @param  {Function} callback
     */
    getTileUrl: function (tilePoint) { // (Point, Number) -> String
        var map = this._map,
            tileSize = this.options.tileSize,

            nwPoint = tilePoint.multiplyBy(tileSize),
            sePoint = nwPoint.add([tileSize, tileSize]);

        var bounds = new L.LatLngBounds(map.unproject(nwPoint, tilePoint.z),
            map.unproject(sePoint, tilePoint.z));
        var size = new L.Point(this.options.tileSize, this.options.tileSize);

        var params = this._buildExportParams(bounds, size);

        return this._requestExport(params);
    },

    /**
     * Export call, json or image straight awy
     * @param  {Object}          params
     * @param  {L.LatLngBounds}  bounds
     * @param  {Function}        callback
     */
    _requestExport: function (params, bounds, callback) {
        if (this.options.f === 'json') {
            // This requires the callback as the URL is a temp URL returned in JSON response
            throw new Error('json is not supported.')
        } else {
            params.f = 'image';
            return this.options.url + 'export' + L.Util.getParamString(params);
        }
    },

    redraw: function () {
        if (this._map) {
            if (this.options.redrawBuffer) {
                let front = this._tileContainer;
                this._clearBgBuffer();
                this._tileContainer = this._bgBuffer;
                this._bgBuffer = front;
                this._tiles = {};
                this._tilesToLoad = 0;
                this._tilesTotal = 0;
            } else {
                this._reset({
                    hard: true
                });
            }

            this._update();
        }
        return this;
    },

    /**
     * Bounds or params changed
     */
    _update: function () {
        if (this._map && this._map._animatingZoom) {
            return;
        }
        L.TileLayer.prototype._update.call(this);
    },

    _tileOnLoad: function (done, tile) {
        L.TileLayer.prototype._tileOnLoad.call(this, done, tile);
    },

});
//
// (function(methods) {
//     for (var i = 0, len = methods.length; i < len; i++) {
//         EsriLeaflet.TiledImageMapLayer.prototype[methods[i]] =
//             EsriLeaflet.Layers.ImageMapLayer.prototype[methods[i]];
//     }
// })([
//     /** @borrows L.esri.Layers.ImageMapLayer.prototype.getLayers as getLayers */
//     'getLayers',
//     /** @borrows L.esri.Layers.ImageMapLayer.prototype.getLayerDefs as getLayerDefs */
//     'getLayerDefs',
//     /** @borrows L.esri.Layers.ImageMapLayer.prototype.getTimeOptions as getTimeOptions */
//     'getTimeOptions',
//     /** @borrows L.esri.Layers.ImageMapLayer.prototype.metadata as metadata */
//     'metadata',
//     /** @borrows L.esri.Layers.ImageMapLayer.prototype.query as query */
//     'query',
//     /** @borrows L.esri.Layers.ImageMapLayer.prototype.identify as identify */
//     'identify',
//     /** @borrows L.esri.Layers.ImageMapLayer.prototype.find as find */
//     'find',
//     /** @borrows L.esri.Layers.ImageMapLayer.prototype._getPopupData as _getPopupData */
//     '_getPopupData',
//     /** @borrows L.esri.Layers.ImageMapLayer.prototype._propagateEvent as _propagateEvent */
//     '_propagateEvent'
// ]);
//
// // factory
// EsriLeaflet.tiledImageMapLayer =
//     EsriLeaflet.Layers.tiledImageMapLayer = function(url, options) {
//         return new EsriLeaflet.Layers.TiledImageMapLayer(url, options);
//     };

const TiledDynamicMapLayer = React.forwardRef((props, ref) => (
    <EsriLeafletLayer ref={ref} layerType={tiledDynamicMapLayer} {...props} />
));

export default TiledDynamicMapLayer;