import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Button from "@mui/material/Button";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {putNodeProperty} from "../../actions";
import {strings} from "../components/SopLocalizedStrings";

const pageStrings = strings.project.addExecution;

class ProjectAddProcedureButton extends Component {

    handleAddClicked = (event) => {
        let {createExecutionNode, onPutNodeProperty} = this.props;
        onPutNodeProperty({id: createExecutionNode.id, submit: true});
        event.preventDefault();
    };

    render() {
        let {createExecutionNode} = this.props;
        return (
            <Button onClick={this.handleAddClicked} color='primary' variant='contained' type={'submit'} data-cy='add'
                    title={strings.formatString(pageStrings.submitButton, createExecutionNode.procedureCount)}
                    disabled={!(createExecutionNode.procedureCount > 0)}>
                <AddRoundedIcon/>&nbsp;&nbsp;{strings.formatString(pageStrings.submitButton, createExecutionNode.procedureCount)}
            </Button>
        )
    }
}

const styles = () => ({
    grow: {
        flexGrow: 1,
    }
});
ProjectAddProcedureButton.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.createExecutionId;
    let createNode = getNodeOrNull(state, nodeId);
    return {
        createExecutionNode: createNode
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: nodes => dispatch(putNodeProperty(nodes))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProjectAddProcedureButton)));
