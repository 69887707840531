import React from 'react';
import '../../../style/alert.css';
import "../../../style/form.css";
import {strings} from "../../components/SopLocalizedStrings";
import {TbfAction} from "tbf-react-library";
import TbfDialog from "../../components/common/TbfDialog";
import ProcedureSettingsDesign from "../settings/ProcedureSettingsCollectionDesign";

const ProcedureCollectionDialog = ({nodeId, showDeleted, renderOpenAction, disabled}) => {

    const renderFooter = ({handleClose}) => (<>
        <TbfAction onClick={handleClose} primaryAction={true} dataCy={'close-dialog'}>Ok</TbfAction>
    </>)

    return (<TbfDialog
        renderOpenAction={renderOpenAction}
        title={strings.procedure.settings.collection.collectionViewTitle}
        renderFooter={renderFooter}
        fullScreen={true}
        disableEnforceFocus
    >
        <ProcedureSettingsDesign nodeId={nodeId} showDeleted={showDeleted} disabled={disabled}/>
    </TbfDialog>)
}
export default ProcedureCollectionDialog
