import axios from "axios";
import {APPS, deriveApiUrl, SHARED_AUTH_INSTANCE, SharedAuth} from 'tbf-react-library';
import { cacheImage } from "../util/offline";

export const getApiBaseUrl = () => {
    return deriveApiUrl(APPS.SOP, SHARED_AUTH_INSTANCE.getClientId(), SHARED_AUTH_INSTANCE.getTenantId());
}

export const executePromise = (method, url, payload = null, withCredentials = false) => {
    return SHARED_AUTH_INSTANCE.getToken()
        .then(token => {
            const headers = {
                'Authorization': 'Bearer ' + token,
                withCredentials: withCredentials
            };
            const instance = axios.create({
                headers: headers
            });

            return instance[method](url, payload, {headers});
        });
};

export const executeSilentPromise = (method, url, payload = null, withCredentials = false) => {
    return SharedAuth.getToken()
        .then(token => {
            const headers = {
                'Authorization': 'Bearer ' + token,
                withCredentials: withCredentials
            };
            const instance = axios.create({
                headers: headers
            });
            return instance[method](url, payload, {headers})
        });
};

export function executeBlobPromise(method, url, withCredentials = false, attemptWithoutToken = false) {
    
    return SharedAuth.getToken()
        .then(token => {
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/pdf, image/*',
                withCredentials: withCredentials || !!token,
            };

            if (!cacheImage()) {
                headers['Cache-Control'] = 'no-cache';
            }

            return axios({
                url: url,
                method: method,
                responseType: 'blob',
                headers: headers
            });
        }).catch(() => {
            if (attemptWithoutToken) {
                const headers = {
                    'Accept': 'application/pdf, image/*',
                    withCredentials: false,
                };
                if (!cacheImage()) {
                    headers['Cache-Control'] = 'no-cache';
                }
                return axios({
                    url: url,
                    method: method,
                    responseType: 'blob',
                    headers: headers
                });
            }
        });
}

export function executePromiseWithoutAuthorization(method, url, headers = {}) {
    return axios({
        url: url,
        method: method,
        responseType: 'blob',
        headers: headers
    });
}