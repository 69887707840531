import React from "react";
import FieldIncompleteIndicator from "../../../components/FieldIncompleteIndicator";
import Input from "@mui/material/Input";
import {strings} from "../../../components/SopLocalizedStrings";
import ExecutionQuestionRuleCommentInstructions from "../message/ExecutionQuestionRuleCommentInstructions";
import {formatFullDate, hasValue} from "../../../../util/util";
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {DIAGNOSTIC_MODES, NODE_IDS} from "../../../../reducers/graphReducer";
import {blurNodeProperty, focusNodeProperty, putNodesProperty} from "../../../../actions";
import {connect} from "react-redux";
import withStyles from '@mui/styles/withStyles';
import ExecutionQuestionInputBase from "./ExecutionQuestionInputBase";
import NoteRoundedIcon from '@mui/icons-material/NoteRounded';
import {questionHasInvalidReason} from "../../../../selectors/executionSelectors";

class ExecutionQuestionNote extends ExecutionQuestionInputBase {


    constructor() {
        super();
        this.state = {
            focused: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleChange = (field) => (e) => {
        this.updateNodeProperty(field, e.target.value, field);
    };

    handleFocus = () => {
        this.setState({focused: true});
    }
    handleBlur = () => {
        this.setState({focused: false});
    }

    render() {
        let {
            classes, disabled, diagnosticsOn, field, value, inputId, fieldIsIncomplete, required, questionId,
            commentByUser, commentDateTime
        } = this.props;
        let {focused} = this.state;
        if (disabled && !hasValue(value)) {
            return <React.Fragment/>;
        }

        return (<div className={'fieldContainer'}>
            {
                required && fieldIsIncomplete && <FieldIncompleteIndicator/>
            }
            {
                <ExecutionQuestionRuleCommentInstructions questionId={questionId} field={field}/>
            }
            <Input
                id={inputId}
                value={value}
                onChange={this.handleChange(field + 'Comment')}
                fullWidth={!hasValue(value) ? focused : true}
                multiline={true}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                startAdornment={<NoteRoundedIcon/>}
                rows={1}
                readOnly={disabled}
                data-readonly={disabled}
                className={classes.commentBox + ' textFieldPrimary'}
                inputProps={{'data-cy': field + 'Comment', maxRows: 15}}
                placeholder={strings.inputBox.commentBoxPlaceholder}
                required={required}
                data-required={(!disabled && required)}
                data-answered={(disabled || value === '') ? undefined : true}
            />
            {
                diagnosticsOn && commentByUser &&
                <div className={classes.diagnostics}>
                    Comment by {commentByUser} at {formatFullDate(commentDateTime)}
                </div>
            }
        </div>);
    }
}

const styles = () => ({
    diagnostics: {
        fontSize: '80%',
        color: '#666'
    },
    commentBox: {
        width: '200px',
        transition: 'width .3s'
    }
});

const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    return {
        inputId: executionQuestionNode.id + '-Comment-' + ownProps.field,
        diagnosticsOn: getNodeOrNull(state, NODE_IDS.UserDevice).diagnosticMode === DIAGNOSTIC_MODES.full.id,
        value: executionQuestionNode[ownProps.field + 'Comment'] || '',
        fieldIsIncomplete: questionHasInvalidReason(executionQuestionNode, ownProps.field + 'Comment'),
        required: executionQuestionNode[ownProps.field + 'CommentRequired'],
        commentByUser: executionQuestionNode[ownProps.field + 'CommentByUser'],
        commentDateTime: executionQuestionNode[ownProps.field + 'CommentDateTime'],
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
        onFocusNodeProperty: (id, propertyName) => dispatch(focusNodeProperty(id, propertyName)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionNote));
