import React, { useState, useCallback, forwardRef } from 'react';
import { Button, Menu } from "@mui/material";
import ExecutionActionButton from './ExecutionActionButton';
import { AddAction } from 'tbf-react-library';


const ExecutionActionGroupButton = ({executionId, group, rules}, ref) => {
    const [menuAnchor, setMenuAnchor] = useState(null);

    const closeMenu = useCallback(() => {
        setMenuAnchor(null)
    }, []);

    const handleClick = (event) => {
        setMenuAnchor(event.currentTarget);
    }

    const handleAction = () => {
        closeMenu();
    }

    return <>
        <AddAction
            isMultiple={true}
            primaryAction={true}
            onClick={handleClick}
            label={group}
            dataCy={`action-group-button-${group.toString().replaceAll(' ', '-').toLowerCase()}`}
        />
        <Menu
            ref={ref}
            id='optionMenu'
            getcontentanchorel={null}
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={closeMenu}
            transitionDuration={100}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            {rules.map(({executionRule, buttonRule}) => {
                return <ExecutionActionButton
                    key={buttonRule.id}
                    executionId={executionId}
                    ruleId={executionRule.id}
                    procedureId={executionRule.createExecutionProcedureId}
                    linkType={executionRule.createExecutionLinkType}
                    buttonLabel={buttonRule.calculateValue}
                    onAction={handleAction}
                    menuItem
                />
            })}
        </Menu>
    </>
}

export default forwardRef(ExecutionActionGroupButton);