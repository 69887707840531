import auth0 from 'auth0-js';
import {getTime, tbfLocalStorage, URL_CALLBACK_ABSOLUTE} from "tbf-react-library";
import {MINUTES_5} from "./util/constants";

class Identity {
    constructor({name, audience, ...rest}) {
        if (!name) {
            throw Error('Name is required')
        }
        if (!audience) {
            throw Error('Audience is required')
        }
        this.name = name
        this.auth0 = new auth0.WebAuth({
            domain: process.env.REACT_APP_AUTHO_DOMAIN,
            clientID: process.env.REACT_APP_AUTHO_CLIENT_ID,
            redirectUri: URL_CALLBACK_ABSOLUTE,
            responseType: 'token id_token',
            scope: 'openid profile email roles groups search:Group',
            audience,
            ...rest
        });

        const newAfter = JSON.parse(tbfLocalStorage.getItem(`${this.name}_renew_after`));
        this.renew = this.renew.bind(this);
        if (newAfter) {
            this.renewTimer = setTimeout(this.renew, newAfter - getTime());
        }
        this.token = tbfLocalStorage.getItem(`${this.name}_access_token`);
        this.expiresAt = JSON.parse(tbfLocalStorage.getItem(`${this.name}_expires_at`));
    }

    loginListeners = [];

    addLoginListener = (callback) => {
        this.loginListeners.push(callback);
        this.raiseLoginEvent();
    }
    removeLoginListener = (callback) => {
        this.loginListeners = this.loginListeners.filter(a => a !== callback);
    }

    raiseLoginEvent() {
        for (let listener of this.loginListeners) {
            listener();
        }
    }

    renew = () => {
        this.auth0.checkSession({}, this.processAuth0Result);
    }

    token = null;
    expiresAt = null;
    inProgressPromise = false;
    additionalPromises = [];
    lastTokenNewTime = null
    hasActiveToken = () => {
        if (!this.token) {
            return false
        }
        const hasExpired = this.expiresAt && (this.expiresAt < new Date().getTime());
        return !hasExpired
    }
    getToken = async () => {
        const hasExpired = this.expiresAt && (this.expiresAt < new Date().getTime());
        const that = this
        if (this.token && !hasExpired) {
            return new Promise((resolve) => {
                resolve(this.token, this.expiresAt);
            });
        } else if (this.inProgressPromise) {
            return await new Promise((resolve, reject) => {
                that.additionalPromises.push({resolve: resolve, reject: reject});
            });
        } else if (this.lastTokenNewTime && getTime() - this.lastTokenNewTime < MINUTES_5) {
            throw Error('Token has expired and an attempt to renew was recently made.')
        } else {
            this.inProgressPromise = true;
            return await new Promise((resolve, reject) => {
                that.additionalPromises.push({resolve: resolve, reject: reject});
                this.lastTokenNewTime = getTime()
                that.auth0.checkSession({}, that.processAuth0Result);
            });
        }
    }

    getTokenExpiresAt = () => this.expiresAt;

    processAuth0Result = (err, authResult) => {
        this.inProgressPromise = false;
        let promises = this.additionalPromises;
        this.additionalPromises = [];
        if (err) {
            for (let p of promises) {
                p.reject(err);
            }
        } else {
            this.token = authResult.accessToken;
            this.expiresAt = (authResult.expiresIn - 30) * 1000 + getTime();

            tbfLocalStorage.setItem(`${this.name}_access_token`, authResult.accessToken);
            tbfLocalStorage.setItem(`${this.name}_token_type`, authResult.tokenType);
            tbfLocalStorage.setItem(`${this.name}_expires_at`, JSON.stringify(this.expiresAt));
            let renewAfter = Math.min(authResult.expiresIn * 1000 / 2, 1000 * 60 * 15) + getTime();
            tbfLocalStorage.setItem(`${this.name}_renew_after`, JSON.stringify(renewAfter));
            this.renewTimer = setTimeout(this.renew, authResult.expiresIn * 1000 / 2);
            for (let p of promises) {
                p.resolve(authResult.accessToken, this.expiresAt);
            }
            this.raiseLoginEvent()
        }
    };
}

export const INTEGRATION_TOKEN = new Identity({
    name: 'integration',
    audience: process.env.REACT_APP_AUTH_INTEGRATION_AUDIENCE,
    useCookie: true
})
export const IDENTITY_TOKEN = new Identity({name: 'identity', audience: 'https://api.identity.tenbagsfull.com.au'})
