import React from "react";
import withStyles from '@mui/styles/withStyles';
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseRounded";
import ProgressWithLabel from "../components/ProgressWithLabel";
import {getNodeOrNull, getNodesById} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {strings} from "../components/SopLocalizedStrings";
import {MINUTES_5} from "../../util/constants";

class AssignmentProgressDetails extends React.Component {

    render() {
        const {
            classes,
            handleCloseProgressPopover,
            executionId,
            status,
            totalQuestionCount,
            completedQuestionCount
        } = this.props;

        return <React.Fragment>
            <GraphResourceLoad
                nodeId={executionId}
                friendlyName={strings.execution.namePlural}
                nodeType={'ExecutionRoot'}
                reloadIntervalMs={MINUTES_5}
                hideLoader={true}
            />
            <div className={classes.progressPopoverContainer}>
                <div className={'header'}>
                    <span>Progress</span>
                    <div className={classes.grow}/>
                    <IconButton size={'small'} className={'closeButton'}
                                title={strings.buttons.closeProgressPopover}
                                onClick={handleCloseProgressPopover}>
                        <CloseIcon/>
                    </IconButton>
                </div>
                <div className={'content'}>
                    <ProgressWithLabel total={totalQuestionCount} completed={completedQuestionCount}/>
                    {
                        (status.toLowerCase() !== 'done') &&
                        <span
                            className={'subText'}>Status changes to 'Done' once all questions are answered</span>
                    }

                    {
                        (status.toLowerCase() === 'done') &&
                        <span
                            className={'subText'}>Status is 'Done', all questions are answered</span>
                    }
                </div>
            </div>
        </React.Fragment>
    }
}

const styles = theme => ({
    progressPopoverContainer: {
        background: theme.palette.primary.two.main,
        maxWidth: 220,
        '& .header': {
            display: 'flex',
            alignItems: 'center',
            padding: '10px 15px',
            color: theme.palette.grey.seven.main,
            fontFamily: theme.fonts.primary.bold,
            '& .closeButton': {
                position: 'absolute',
                right: 3,
                top: 3,
            }
        },
        '& .content': {
            padding: '10px 15px 12px 15px',
        },
        '& .subText': {
            color: theme.palette.grey.four.main,
            fontSize: 12,
            marginTop: 10,
            display: 'block',
        }
    },
});
const mapStateToProps = (state, ownProps) => {
    const assignment = getNodeOrNull(state, ownProps.assignmentId);
    const itemNode = getNodeOrNull(state, assignment.nodeId);
    let totalQuestionCount = 0;
    let completedQuestionCount = 0;
    if (itemNode) {
        if (itemNode.type === 'ExecutionStep') {
            const tasks = getNodesById(state, itemNode.children);
            for (let task of tasks) {
                const questions = getNodesById(state, task.children);
                const completedQuestions = questions.filter(question => question.completed);
                totalQuestionCount = totalQuestionCount + questions.length + (task.completeEnabled ? 1 : 0);
                completedQuestionCount = completedQuestionCount + completedQuestions.length + (task.completeEnabled && task.completed ? 1 : 0);
            }
        } else if (itemNode.type === 'ExecutionTask') {
            const questions = getNodesById(state, itemNode.children);
            const completedQuestions = questions.filter(question => question.completed);
            totalQuestionCount = questions.length + (itemNode.completeEnabled ? 1 : 0);
            completedQuestionCount = completedQuestions.length + (itemNode.completeEnabled && itemNode.completed ? 1 : 0);
        }
    }

    return {
        totalQuestionCount: totalQuestionCount,
        completedQuestionCount: completedQuestionCount,
        executionId: assignment.executionId,
        status: assignment.status,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AssignmentProgressDetails));
