import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {connect} from "react-redux";
import {focusNodeProperty, putNodesProperty} from "../../../../actions";
import ExecutionQuestionInputBase from "./ExecutionQuestionInputBase";
import Input from "@mui/material/Input";
import LocationOn from "@mui/icons-material/LocationOn";
import IconButton from "@mui/material/IconButton";
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import {LOCATION_MODES, NODE_IDS} from "../../../../reducers/graphReducer";
import {strings} from "../../../components/SopLocalizedStrings";
import {convertGeoJsonFeatureToText, convertTextToGeoJsonFeature} from "../../../../util/location";
import cloneDeep from "lodash/cloneDeep";
import {MAP_SHAPE_TYPES} from "../../../MapView/constants";
import {getGeographicTools} from "../../../../selectors/executionSelectors";

class ExecutionQuestionGeographic extends ExecutionQuestionInputBase {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleUseLocation = this.handleUseLocation.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleChange = (e) => {
        let {field} = this.props;
        let text = e.target.value;
        let feature = convertTextToGeoJsonFeature(text);
        this.updateNodeProperty(field, feature, field);
    };

    handleBlur = () => {
        let {field, sourceValue} = this.props;
        let updated = cloneDeep(sourceValue);
        // Lets remove text so we go back to the formatted value
        // Need to use text while typing as otherwise you enter 1 and it adds a space which is annoying
        updated && updated.properties && delete updated.properties.text;
        this.updateNodeProperty(field, updated, field);
    }

    handleUseLocation = () => {
        let {field, location} = this.props;
        let feature = location.feature;
        this.updateNodeProperty(field, feature, field);
    }

    render = () => {
        let {classes, sourceValue, locationAvailable, disabled, valueUnknown, field, allowedGeometryTypes} = this.props;
        let value = convertGeoJsonFeatureToText(sourceValue) || '';
        let standardProperties = this.renderInputStandardProperties(value, valueUnknown, field);
        const isPoint = allowedGeometryTypes.length === 1 && allowedGeometryTypes[0] === MAP_SHAPE_TYPES.marker.id;
        if (!isPoint) {
            return null;
        }
        return (
            <Input
                {...standardProperties}
                type={'text'}
                startAdornment={<LocationOn/>}
                endAdornment={!disabled &&
                    <IconButton
                        className={classes.locationButton}
                        title={strings.execution.questions.geographic.useCurrentLocation}
                        disabled={!locationAvailable}
                    data-cy="locate-me"
                    onClick={this.handleUseLocation}
                    size="large">
                    <LocationSearchingIcon className={'notAdornmentIcon'}/>
                </IconButton>}
                placeholder={!disabled ? strings.execution.questions.geographic.placeholder : ''}
                fullWidth={true}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={value}
            />
        );
    }
}

const styles = () => ({
    locationButton: {
        width: 40,
        height: 40,
        marginLeft: 5,
    },
});
ExecutionQuestionGeographic.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    let valueUnknown = executionQuestionNode[ownProps.field + 'Unknown'];
    let valueDisabled = ownProps.disabled || (executionQuestionNode && executionQuestionNode.initialValueReadOnly);
    let location = getNodeOrNull(state, NODE_IDS.Location);
    let locationAvailable = location.mode === LOCATION_MODES.available.id
        && location.feature;
    // Location age is old on desktop, need to investigate more when it becomes unreliable
    //&& location.position.timestamp > new Date(new Date().getTime() - 1000 * 60 * 5);
    const allowedGeometryTypes = getGeographicTools(state, ownProps.questionId)
    return {
        location: location,
        questionName: executionQuestionNode.name,
        locationAvailable: locationAvailable,
        valueUnknown: valueUnknown,
        valueDisabled: valueDisabled,
        allowedGeometryTypes: allowedGeometryTypes
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
        onFocusNodeProperty: (id, propertyName) => dispatch(focusNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionGeographic));
