import React from 'react';
import {AddAction} from "tbf-react-library";
import {Permissions} from "../../../permissions";
import {strings} from "../../components/SopLocalizedStrings";

const ProcedureAddButton = ({procedureType, primaryAction}) => {
    return <AddAction
        permission={Permissions.procedure.create}
        path={`/procedures/${procedureType.id}/new`}
        title={strings.procedure.list.newButtonTitle}
        primaryAction={primaryAction}
    />
}
export default ProcedureAddButton
