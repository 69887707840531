import {
    getActiveChildrenSafe,
    getActiveDescendantsSafe,
    getActiveNodesByType,
    getAncestorNodeByTypeOrNull,
    getChildrenDescendantsAndSelf,
    getDescendantsAndSelf,
    getFirstAppliedNodeByRule,
    getMatchingExecutionNodeToProcedureNode,
    getNodeOrError,
    getNodeOrNull,
    getNodes,
    getNodesByFilter,
    getNodesById,
    getNodesIfPresent,
    getVisibleNodesSafe
} from "./graphSelectors";
import {
    ADD_ACTION_LABEL_FORMATS,
    COMPLETE_ACTION_STYLES,
    COMPLETE_MODES,
    LINK_TYPES_SELECTABLE,
    NAVIGATION_STYLES,
    NODE_IDS,
    NODE_TYPE_OPTIONS,
    PROCEDURE_EXECUTION_VIEW_TYPES,
    RULE_ACTION_TYPE
} from "../reducers/graphReducer";
import {EXECUTION_PROCEDURE_MAP, getCustomCompleteLabels, getSummaryId, isNodeSaved} from "../factory/executionFactory";
import {EMPTY_ARRAY, hasValue} from "../util/util";
import {reportDeveloperWarning} from "tbf-react-library";
import {ExecutionPermissionsWhenDeleted, Permissions} from "../permissions";
import {hasProcedurePermission} from "./procedureSelectors";
import {
    getActiveChildrenRules,
    getActiveChildRulesForNodeByActionOrNull,
    getEnabledRuleForNodeByActionOrNull,
    getEnabledRulesForNodeByActionIfPresent,
    getEnabledSubRulesForNodeByActionIfPresent,
    getOnChildRulesForNodeByActionOrNull,
} from "./ruleSelectors";
import {strings} from "../layouts/components/SopLocalizedStrings";
import {MAP_SHAPE_TYPES} from "../layouts/MapView/constants";

export const getExecutions = (state) => getActiveNodesByType(state, NODE_TYPE_OPTIONS.ExecutionRoot);

export const getExecutionProcedureNodes = (state, nodeId) =>
    getDescendantsAndSelf(state, nodeId).filter(a => EXECUTION_PROCEDURE_MAP[a.type])


export const isExecutionLoadedFull = procedure => {
    let looksLoaded = procedure && procedure.loadedFull;
    let butIsNot = looksLoaded && !hasValue(procedure?.children);
    if (butIsNot) {
        reportDeveloperWarning(`Procedure looks loaded but is not.`, procedure);
        return false;
    }
    return looksLoaded;
};

export const getProcedureExecutionView = (state, procedureId) => {
    const userDeviceData = getNodeOrNull(state, NODE_IDS.UserDevice);
    return userDeviceData && userDeviceData.procedureExecutionView && userDeviceData.procedureExecutionView[procedureId];
}
/***
 * This will get summary over full. This is useful for listing pages where you generally
 * want the latest version.
 * @param state
 * @param executionIds
 * @returns {[]}
 */
export const getExecutionSummaryFullByIdIfPresent = (state, executionIds) => {
    let nodes = getNodes(state);
    let result = [];
    for (let id of executionIds || []) {
        let summary = nodes[getSummaryId(id)] || null;
        if (summary) {
            result.push(summary);
        } else {
            let full = nodes[id] || null;
            if (full) {
                result.push(full);
            }
        }
    }
    return result;
};
/***
 * This will get full over summary.
 * @param state
 * @param executionIds
 * @returns {[]}
 */
export const getExecutionFullSummaryByIdIfPresent = (state, executionIds) => {
    let nodes = getNodes(state);
    let result = [];
    for (let id of executionIds || []) {
        let full = nodes[id] || null;
        if (full?.loaded) {
            result.push(full);
        } else {
            let summary = nodes[getSummaryId(id)] || null;
            if (summary?.loaded) {
                result.push(summary);
            }
        }
    }
    return result;
};
/**
 * This will get full over summary, and null if they do not exist
 * @param state
 * @param id
 */
export const getExecutionFullSummaryOrNull = (state, id) => {
    let node = getNodeOrNull(state, id);
    if (!node?.loaded) {
        node = getNodeOrNull(state, getSummaryId(id));
    }
    return node?.loaded ? node : null;
};
/**
 * This will get full over summary, and null if they do not exist
 * @param state
 * @param id
 */
export const getExecutionFullOrNull = (state, id) => {
    let node = getNodeOrNull(state, id);
    return node?.loaded ? node : null;
};
/**
 * This will get summary over full, and null if they do not exist
 * @param state
 * @param id
 */
export const getExecutionSummaryFullOrNull = (state, id) => {
    let node = getNodeOrNull(state, getSummaryId(id));
    if (!node?.loaded) {
        node = getNodeOrNull(state, id);
    }
    return node?.loaded ? node : null;
};

export const getExecutionLinkNewFrom = (state, fromNodeId) => {
    return getNodesByFilter(state, {type: NODE_TYPE_OPTIONS.ExecutionLinkNew, fromExecutionId: fromNodeId});
}
export const questionHasInvalidReason = (questionNode, propertyName) => {
    return questionNode?.notCompletedReasons?.find(a => a.propertyName === propertyName)
}


export const getActiveExecutionQuestions = (state, procedureId) => {
    let nodes = getChildrenDescendantsAndSelf(state, procedureId);
    return nodes
        .filter(a => !a.deleted && a.type === NODE_TYPE_OPTIONS.ExecutionQuestion);
}

export const getActiveExecutionQuestionsSafe = (state, procedureId) => {
    let nodes = getActiveDescendantsSafe(state, procedureId);
    return nodes
        .filter(a => !a.deleted && a.type === NODE_TYPE_OPTIONS.ExecutionQuestion);
}

export const hasExecutionPermission = (state, nodeId, permission) => {
    const node = getNodeOrNull(state, nodeId)
    const execution = getNodeOrNull(state, node?.rootId)
    if (!execution) {
        return false;
    }
    if (execution.destroyed === true) {
        return false;
    }
    if (execution.deleted === true && !ExecutionPermissionsWhenDeleted.includes(permission)) {
        return false;
    }
    if (permission === Permissions.execution.complete && node.canComplete === false) {
        return false;
    }
    if (execution.draft && (permission === Permissions.execution.complete || permission === Permissions.execution.edit)) {
        permission = Permissions.execution.create
    }
    if (execution._metadata) {
        // An empty permission array means they have none, which is odd, as how did they load it?
        const permissions = execution._metadata.permissions || EMPTY_ARRAY;
        const foundPermission = permissions
            .find(a => a.permission === permission && (a.nodes?.includes(nodeId) || a.nodes?.includes(node.parentId) || a.nodes?.includes(node.rootId)));
        return foundPermission !== undefined;
    }
    return hasProcedurePermission(state, execution?.procedureId, permission);
}

export const attachmentsUploaded = (state, nodeId, field, dependencies) => {
    const getPhotoIds = (question, field) => {
        const photoIds = [];
        if (field === 'initial' || !field) {
            photoIds.push(...(question.initialPhotoIds ?? []));
        }
        if (field === 'resolved' || !field) {
            photoIds.push(...(question.resolvedPhotoIds ?? []));
        }
        return photoIds;
    }

    const node = getNodeOrError(state, nodeId);
    let photoIds;
    if (node.type !== NODE_TYPE_OPTIONS.ExecutionQuestion){
        const questions = getActiveExecutionQuestions(state, nodeId);
        photoIds = questions.flatMap((q) => getPhotoIds(q, field));
    } else {
        photoIds = getPhotoIds(node, field);
    }
    const photos = getNodesIfPresent(state, photoIds);
    if (dependencies) {
        photoIds.forEach((photoId) => dependencies.push({from: photoId, to: nodeId, properties: ['createdDateTime', 'lastUpdatedDateTime']}));
    }
    return photos.every((p) => p.createdDateTime && p.lastUpdatedDateTime);
}

export const computeCompleteAccess = (state, nodeOrNodeId, createScreen, disabled, returnDependencies) => {

    const dependencies = [];
    let node = typeof nodeOrNodeId === 'string' ? getNodeOrNull(state, nodeOrNodeId) : nodeOrNodeId;
    let nodeId = node.id;
    let rootNode = getNodeOrNull(state, node.rootId);
    let user = getNodeOrNull(state, NODE_IDS.User) || {};
    let userEmail = user.email;
    let canComplete = hasExecutionModifyPermission(state, node.id, createScreen);
    let nodeStep;
    let nodeTask;
    let nodeQuestion;
    switch (node.type) {
        case NODE_TYPE_OPTIONS.ExecutionRoot:
            const canView = hasExecutionPermission(state, nodeId, Permissions.execution.read)
            const disabled = !canComplete || (node && node.destroyed === true) || (node && node.canComplete === false);
            return [{
                disabled: disabled,
                canView: canView,
                canComplete: canComplete
            }, {}];
        case NODE_TYPE_OPTIONS.ExecutionStep:
            nodeStep = node;
            break;
        case NODE_TYPE_OPTIONS.ExecutionTask:
            nodeTask = node;
            nodeStep = getNodeOrNull(state, nodeTask?.parentId);
            break;
        case NODE_TYPE_OPTIONS.ExecutionQuestion:
            nodeQuestion = node;
            nodeTask = getNodeOrNull(state, nodeQuestion?.parentId);
            nodeStep = getNodeOrNull(state, nodeTask?.parentId);
            break;
        default:
    }
    const userCompleteNode = nodeStep.completeMode === COMPLETE_MODES.task.id ? nodeTask : nodeStep;
    let userCompleted = userCompleteNode?.userCompleted || {};
    let completedByMe = userCompleted.completedUserEmail === userEmail;
    let cancelPermissionEnabled = hasExecutionPermission(state, node.id, Permissions.execution.edit);
    if (completedByMe) {
        cancelPermissionEnabled = cancelPermissionEnabled || hasExecutionPermission(state, node.id, Permissions.execution.editCompleter);
    }
    let canCancel = canComplete && cancelPermissionEnabled;
    const completed = userCompleted.completed || false;

    // previous and next step index is only being used at: src/layouts/execution/ExecutionComplete.js
    // STEPS
    const visibleStepIds = rootNode?.activeChildren || EMPTY_ARRAY;
    const currentIndex = visibleStepIds.indexOf(nodeStep.id);
    let stepIndex = currentIndex >= 0 ? currentIndex : 0;
    let nextStepIndex = stepIndex + 1;
    if (nextStepIndex >= visibleStepIds.length) {
        nextStepIndex = undefined;
    }

    let previousStepIndex = stepIndex - 1;
    if (previousStepIndex < 0) {
        previousStepIndex = null;
    }


    // TASKS
    let nextTaskIndex = null
    let nodeIsTask = nodeTask === node
    if (nodeIsTask) {
        let taskIndex = nodeStep.children.indexOf(node.id)
        for (let i = taskIndex + 1; i < nodeStep.children.length; i++) {
            let id = nodeStep.children[i];
            let n = getNodeOrNull(state, id);
            if (n.visible) {
                nextTaskIndex = i;
                break;
            }
        }
    }

    const completeActionStyleRule = getEnabledRuleForNodeByActionOrNull(state, rootNode.id, RULE_ACTION_TYPE.completeActionStyle.id);
    const completeActionStyle = completeActionStyleRule?.format || COMPLETE_ACTION_STYLES.standard.id;
    const isFormOrCalculator = completeActionStyle === COMPLETE_ACTION_STYLES.form.id || completeActionStyle === COMPLETE_ACTION_STYLES.calculator.id;
    const isLastTaskOnStep = nodeIsTask && nextTaskIndex == null
    let nextStepOnComplete = isFormOrCalculator && nextStepIndex && (isLastTaskOnStep || node === nodeStep)

    if (disabled == null) {
        disabled = hasReadOnlyRuleOn(state, node.id)
            || (nodeTask && hasReadOnlyRuleOn(state, nodeTask.id))
            || (nodeStep && hasReadOnlyRuleOn(state, nodeStep.id))
            || hasReadOnlyRuleOn(state, node.rootId)
            || !node.visible
            || node.deleted
    }
    let useCanComplete = !completed && canComplete && !disabled
    let useCanCancel = completed && canCancel && !disabled

    // Dynamic Label
    let completeLabels;

    // override fields with custom complete labels
    const custom = getCustomCompleteLabels(state, node.rootId, nodeId);
    completeLabels = {...strings.execution.show[completeActionStyle], ...custom?.calculatedLabels};

    if(completeActionStyle === COMPLETE_ACTION_STYLES.standard.id) {
        nextStepOnComplete = custom?.navigateNextOnComplete && nextStepIndex && (isLastTaskOnStep || node === nodeStep);
    }

    if (returnDependencies) {
        dependencies.push({from: user.id, to: nodeId, properties: ['email']});
        dependencies.push({from: rootNode?.id, to: nodeId, properties: ['children', 'rules', 'ruleIds', 'draft', 'preview', 'deleted', 'destroyed', '_metadata']});
        if (userCompleteNode && userCompleteNode.id !== nodeId) {
            dependencies.push({from: userCompleteNode.id, to: nodeId, properties: ['userCompleted', 'completed']});
        }
        if (rootNode) {
            for (const stepId of rootNode.children) {
                if (stepId === nodeId) {
                    continue;
                }
                dependencies.push({from: stepId, to: nodeId, properties: ['visible']});
            }
        }
        if (nodeStep) {
            for (const taskId of nodeStep.children) {
                if (taskId === nodeId) {
                    continue;
                }
                dependencies.push({from: taskId, to: nodeId, properties: ['visible']});
            }
        }
        if (completeActionStyleRule) {
            dependencies.push({from: completeActionStyleRule.id, to: nodeId, properties: ['evaluatedValue', 'format']});
        }
        if (custom?.collectionLabel) {
            dependencies.push({from: custom?.collectionLabel?.id, to: nodeId, properties: ['ruleIds, evaluatedValue', 'deleted']})
        }
        for (const rule of custom?.labelRules ?? []) {
            dependencies.push({from: rule.id, to: nodeId, properties: ['calculateValue', 'format', 'deleted']});
        }
        if (custom?.navigateNextOnComplete) {
            dependencies.push({from: custom?.navigateNextOnComplete?.id, to: nodeId, properties: ['evaluatedValue', 'deleted']});
        }
        const readOnlyRules = [];
        if (nodeQuestion) {
            const questionReadOnlyRules = getActiveChildRulesForNodeByActionOrNull(state, nodeQuestion.id, RULE_ACTION_TYPE.readOnly.id);
            readOnlyRules.push(...questionReadOnlyRules);
        }
        if (nodeTask) {
            const taskReadOnlyRules = getActiveChildRulesForNodeByActionOrNull(state, nodeTask.id, RULE_ACTION_TYPE.readOnly.id);
            readOnlyRules.push(...taskReadOnlyRules);
        }
        if (nodeStep) {
            const stepReadOnlyRules = getActiveChildRulesForNodeByActionOrNull(state, nodeStep.id, RULE_ACTION_TYPE.readOnly.id);
            readOnlyRules.push(...stepReadOnlyRules);
        }
        const executionReadOnlyRules = getActiveChildRulesForNodeByActionOrNull(state, rootNode.id, RULE_ACTION_TYPE.readOnly.id);
        readOnlyRules.push(...executionReadOnlyRules);
        for (const rule of readOnlyRules) {
            dependencies.push({from: rule.id, to: nodeId, properties: ['evaluatedValue', 'deleted']});
        }
        return [{
            completed: completed,
            completedUserName: userCompleted.completedUserName || null,
            completedUserEmail: userCompleted.completedUserEmail || null,
            completedDate: userCompleted.completedDate || null,
            completeEnabled: node.completeEnabled,
            canComplete: useCanComplete,
            canCancel: useCanCancel,
            completeMode: nodeStep.completeMode,
            allQuestionsCompleted: node.allQuestionsCompleted,
            nextStepIndex: nextStepIndex,
            previousStepIndex: previousStepIndex,
            completeActionStyle: completeActionStyle,
            nextStepOnComplete: nextStepOnComplete,
            nextTaskIndex: nextTaskIndex,
            completeLabels,
            navigateNextOnComplete: custom?.navigateNextOnComplete ?? isFormOrCalculator,
            disabled,
        }, dependencies];
    }

    return {
        completed: completed,
        completedUserName: userCompleted.completedUserName || null,
        completedUserEmail: userCompleted.completedUserEmail || null,
        completedDate: userCompleted.completedDate || null,
        completeEnabled: node.completeEnabled,
        canComplete: useCanComplete,
        canCancel: useCanCancel,
        completeMode: nodeStep.completeMode,
        allQuestionsCompleted: node.allQuestionsCompleted,
        nextStepIndex: nextStepIndex,
        previousStepIndex: previousStepIndex,
        completeActionStyle: completeActionStyle,
        nextStepOnComplete: nextStepOnComplete,
        nextTaskIndex: nextTaskIndex,
        completeLabels,
        navigateNextOnComplete: custom?.navigateNextOnComplete ?? isFormOrCalculator,
        disabled,
    };
}

export const hasReadOnlyRuleOn = (state, nodeId) => {
    return getOnChildRulesForNodeByActionOrNull(state, nodeId, RULE_ACTION_TYPE.readOnly.id).length > 0;
}

export const hasExecutionModifyPermission = (state, nodeId, createScreen = false) => {
    const node = getNodeOrNull(state, nodeId)
    const execution = getNodeOrNull(state, node?.rootId)
    if (!execution) {
        return false;
    }
    if (execution.destroyed === true) {
        return false;
    }
    if (execution.deleted === true) {
        return false;
    }
    if (node.canComplete === false) {
        return false;
    }
    // If new dialog, require create permission
    // If full page and not yet saved and draft, require create
    // If full page and not yet saved, require create and complete
    // If full page and saved, require complete
    
    let isSaved = isNodeSaved(execution);
    if (execution.preview) {
        // No permissions checks, as one would assume if user can view template, then they can preview it.
    } else {
        if (createScreen || !isSaved || execution.draft) {
            let hasProcedureCreate = hasProcedurePermission(state, execution?.procedureId, Permissions.execution.create);
            let hasExecutionCreate = hasExecutionPermission(state, nodeId, Permissions.execution.create);
            if (!hasProcedureCreate && !hasExecutionCreate) {
                return false;
            }
        }
        if (!createScreen && !execution.draft) {
            if (isSaved) {
                let hasComplete = hasExecutionPermission(state, nodeId, Permissions.execution.complete);
                let hasEdit = hasExecutionPermission(state, nodeId, Permissions.execution.edit);
                if (!hasComplete && !hasEdit) {
                    return false;
                }
            } else {
                let hasProcedurePerm = hasProcedurePermission(state, execution?.procedureId, Permissions.execution.complete);
                let hasProcedureEdit = hasProcedurePermission(state, execution?.procedureId, Permissions.execution.edit);
                if (!hasProcedurePerm && !hasProcedureEdit) {
                    return false;
                }
            }
        }
    }
    return true;
}

export const getNavigationStyle = (state, executionId) => {
    // #1 Via ... menu takes precendence
    const execution = getNodeOrNull(state, executionId)
    const viaMenu = getProcedureExecutionView(state, execution?.procedureId)
    if (viaMenu) {
        return viaMenu === PROCEDURE_EXECUTION_VIEW_TYPES.toc ? NAVIGATION_STYLES.toc.id : NAVIGATION_STYLES.tab.id
    }
    // #2 Fallback to rule
    return getEnabledRuleForNodeByActionOrNull(state, executionId, RULE_ACTION_TYPE.navigationStyle.id)?.format || NAVIGATION_STYLES.tab.id;
}
export const getCompleteActionStyle = (state, executionId) => {
    return getEnabledRuleForNodeByActionOrNull(state, executionId, RULE_ACTION_TYPE.completeActionStyle.id)?.format || COMPLETE_ACTION_STYLES.standard.id;
}

export const getActiveSteps = (state, executionId, troubleshootOn = false) => {
    const executionNode = getNodeOrNull(state, executionId);
    const stepIds = (executionNode && executionNode.children) || [];
    const steps = getNodesIfPresent(state, stepIds);
    return steps
        .filter(node => (troubleshootOn || node.visible === true) && node.deleted !== true)
        .filter(node => node.canView !== false);
}

export const getNodeContainerByTypeOrNull = (state, executionId, opts = {}) => {
    const {nodeId, ruleId, type, matchWithProcedure} = opts;
    let node = null;
    let rule = null;
    if (nodeId) {
        node = getNodeOrNull(state, nodeId);
    }
    if (!node) {
        if (ruleId) {
            rule = getNodeOrNull(state, ruleId);
        }
        if (rule) {
            node = getFirstAppliedNodeByRule(state, rule);
        }
    }
    if (matchWithProcedure && node?.type.startsWith('Procedure')) {
        node = getMatchingExecutionNodeToProcedureNode(state, executionId, nodeId);
    }
    if (node) {
        return getAncestorNodeByTypeOrNull(state, node, type);
    }

    return null;
}

export const getChildNodeIndex = (state, node, child, opts = {}) => {
    const {visible} = opts;
    if (!node || !child) {
        return -1;
    }
    let children;
    if (visible) {
        children = getVisibleNodesSafe(state, node.children, node);
    } else {
        children = getActiveChildrenSafe(state, node);
    }
    return children?.findIndex((c) => c.id === child.id) ?? -1;
}

export const getActiveAvailableActionRules = (state, executionId) => {
    const rules = [];

    const manuallyAddActionRules = [];

    // Strip of unimportant props
    const strip = (rule) => ({
        id: rule.id,
        calculateValue: rule.calculateValue,
        ...(rule.createExecutionProcedureId && rule.createExecutionLinkType ? {
            createExecutionProcedureId: rule.createExecutionProcedureId,
            createExecutionLinkType: rule.createExecutionLinkType
        } : {})
    });


    const actionAddRules = getEnabledRulesForNodeByActionIfPresent(state, executionId, RULE_ACTION_TYPE.manuallyAddExecution.id);
    for (const actionAddRule of actionAddRules) {
        const hasPermission = hasProcedurePermission(state, actionAddRule.createExecutionProcedureId, Permissions.execution.create);
        if (hasPermission) {
            manuallyAddActionRules.push(actionAddRule);
        }
    }

    for (const manuallyAddActionRule of manuallyAddActionRules) {
        const actionRules = {
            executionRule: strip(manuallyAddActionRule),
        };

        const childRules = getActiveChildrenRules(state, manuallyAddActionRule.id);

        childRules.forEach((childRule) => {
            switch (childRule.format) {
                case ADD_ACTION_LABEL_FORMATS.group.id:
                    actionRules.groupRule = strip(childRule);
                    break;
                case ADD_ACTION_LABEL_FORMATS.button.id:
                    actionRules.buttonRule = strip(childRule);
                    break;
                default:
                    break;
            }
        });

        rules.push(actionRules);
    }
    return rules;
}

export const getGeographicTools = (state, questionId) => {
    const rules = getEnabledSubRulesForNodeByActionIfPresent(state, questionId, RULE_ACTION_TYPE.geographicTools.id)
    const values = []
    for (let rule of rules) {
        let x = rule.calculateValue
        if (Array.isArray(x)) {
            for (let item of x) {
                values.push(item)
            }
        }
    }
    if (rules.length === 0) {
        values.push(MAP_SHAPE_TYPES.marker.id)
    }
    return values
}

export const getLinkedChildren = (state, nodeId) => {
    const node = getNodeOrNull(state, nodeId);
    let links = getNodesById(state, node.links || []).filter(a => a && a.deleted !== true && a.toNodeName);
    const executions = [];

    for (let link of links) {
        if (link.linkType !== LINK_TYPES_SELECTABLE.child.id) {
            continue;
        }
        // Getting full first as when auto-deleted we mark full as deleted and leave summary alone
        let toNode = getExecutionFullSummaryOrNull(state, link.toNodeId);
        // Lets hide deleted so when deleted from data grid they disappear but if not sure show em
        if (toNode && (node?.deleted === true || !toNode?.deleted) && !toNode?.draft) {
            executions.push(toNode);
        }
    }

    return executions;
}

export const hasOfflineEnabled = (state) => {
    const node = getNodeOrNull(state, NODE_IDS.UserDevice);
    const offlineExecutions = node.offlineExecutions;
    const offlineData = Object.values(offlineExecutions);
    const hasOfflineExecutions = offlineData.some(e => e.on === true);

    const hasOfflineAssignments = node.assignmentsOfflineState?.worqItems?.downloaded > 0;

    return hasOfflineExecutions || hasOfflineAssignments;
}