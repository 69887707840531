export const checkHasValue = (value, message = null, obj = null) => {
    if (value == null) {
        let objMsg = (obj && ' Object: ' + JSON.stringify(obj)) || '';
        let msgStart = message || 'Precondition failed. Expected not null or undefined but was: ' + value + '.';
        let msg = msgStart + objMsg;
        throw new Error(msg);
    }
};

export const precondition = {
    hasValue: checkHasValue
};

export default precondition;