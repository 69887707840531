import {tbfLocalStorage} from "tbf-react-library"
import {getBrowserVisibilityProp} from "../util/util";
import {putNodeProperty} from "../actions";
import {NODE_IDS} from "../reducers/graphReducer";
import { isIndexedDbInitialised } from "../util/offline";

export const updateInternetAvailableOverride = (on) => {
    tbfLocalStorage.setItem('perm_mock_online', on ? 'true' : 'false');
    if (computeIsOnline) {
        computeIsOnline()
    }
}
let computeIsOnline = () => {
}
let lastSeenResult = null
const onlineMiddleware = store => {

    const handleOnline = () => {
        computeIsOnline()
    }

    const handleOffline = () => {
        computeIsOnline()
    }

    const onVisibilityChange = () => {
        computeIsOnline()
    }

    computeIsOnline = () => {
        let mockedOnline = tbfLocalStorage.getItem('perm_mock_online')
        const on = mockedOnline === "false" ? false : window.navigator.onLine

        if (on !== lastSeenResult && isIndexedDbInitialised()) {
            lastSeenResult = on
            setTimeout(() => {
                store.dispatch(putNodeProperty({id: NODE_IDS.UserDevice, internetAvailable: on}))
            }, 0)
        }
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    document.addEventListener(getBrowserVisibilityProp(), onVisibilityChange, false);

    return next => action => {
        return next(action)
    }
}

export default onlineMiddleware
