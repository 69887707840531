import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {styles} from '../style/main'

class BrowserSupported extends Component {

    render() {
        const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
        if (isIE) {
            return <div class="alert alert-error">The browser IE is not supported. Please use Chrome, Safari, or
                Edge.</div>
        }
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        )
    }
}

BrowserSupported.propTypes = {};

export default withStyles(styles, {withTheme: true})(BrowserSupported)