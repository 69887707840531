import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../style/alert.css';
import {putNodeProperty} from "../../actions";
import Badge from "@mui/material/Badge";
import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded';
import PropTypes from "prop-types";
import HelpPointMarker from "../../manuals/layouts/HelpPointMarker";
import AssignmentsUserBadge from "../components/AssignmentsUserBadge";

class ExecutionStepTabLabel extends Component {

    render() {
        const {warningCount, name, completed, procedureStepId, tabIndex, classes, selectedStepIndex, nodeId} = this.props;
        let hasWarnings = warningCount > 0;
        if (hasWarnings) {
            return <React.Fragment>
                <Badge className={classes.badge} badgeContent={warningCount} color={"error"}>
                    {name}
                    {completed && <CheckCircleIcon className={classes.checkIcon}/>}
                </Badge>
                {
                    selectedStepIndex === tabIndex &&
                    <HelpPointMarker helpPointExternalId={procedureStepId}
                                     helpIconClassName={'helpIconAbsoluteTopRight'} isIcon={true}/>
                }
            </React.Fragment>
        }
        return (
            <span>
                {name}
                {completed && <CheckCircleIcon className={classes.checkIcon}/>}
                <AssignmentsUserBadge nodeId={nodeId} inline={true}/>

                {
                    selectedStepIndex === tabIndex &&
                    <HelpPointMarker helpPointExternalId={procedureStepId}
                                     helpIconClassName={'helpIconAbsoluteTopRight'} isIcon={true}/>
                }
            </span>);
    }
}

const styles = (theme) => ({
    badge: {
        padding: `0 ${theme.spacing(2)}`,
        '& .MuiBadge-badge': {
            transform: 'scale(1) translate(0%, -30%)',
            height: 14,
            width: 14,
            minWidth: 'auto'
        }
    },
    progressIcon: {
        verticalAlign: 'bottom'
    },
    button: {
        margin: theme.spacing(1),
    },
    grow: {
        flexGrow: 1,
    },
    checkIcon: {
        fill: theme.palette.success.main,
        verticalAlign: 'middle',
        marginLeft: 2,
        marginRight: 5,
        marginTop: -4,
        fontSize: 14
    }
});

ExecutionStepTabLabel.propTypes = {
    nodeId: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};
const mapStateToProps = (state, ownProps) => {
    let node = getNodeOrNull(state, ownProps.nodeId);
    return {
        warningCount: node.warningCount,
        name: node.name,
        completed: node.completed,
        procedureStepId: node.procedureStepId
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionStepTabLabel));
