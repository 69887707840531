import { Button } from "@mui/material"
import React, { useContext, useCallback } from "react"
import { QueryParamsContext } from "./QueryParamsProvider";
import { strings } from "../components/SopLocalizedStrings";

const ExecutionCancelReturn = ({variant, className, color}) => {
    const {returnUrl} = useContext(QueryParamsContext);

    const handleCancel = useCallback(() => {
        if (returnUrl) {
            window.top.location.href = returnUrl;
        }
    }, [returnUrl])

    if (!returnUrl) return null;

    return <Button title={strings.labels.cancel} className={className} color={color ?? "secondary"} variant={variant ?? "outlined"} onClick={handleCancel} data-cy='cancel-return-url'>
        {strings.labels.cancel}
    </Button>
}

export default ExecutionCancelReturn;