import {APPS, deriveApiUrl, SHARED_AUTH_INSTANCE} from "tbf-react-library";

const computedUrls = {};
export const createMapUrl = (url, appHost) => {
    if (!appHost) return url
    if (url.startsWith('https://') || url.startsWith('http://')) {
        return url;
    }
    if (!computedUrls[appHost]) {
        computedUrls[appHost] = {};
    }
    if (!computedUrls[appHost][url]) {
        let baseUrl = deriveApiUrl(APPS[appHost], SHARED_AUTH_INSTANCE.getClientId(), SHARED_AUTH_INSTANCE.getTenantId());
        if (!url.startsWith("/")) {
            url = "/" + url;
        }
        computedUrls[appHost][url] = `${baseUrl}${url}`
    }
    return computedUrls[appHost][url];
}
