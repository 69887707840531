import { OPEN_CONFIRMATION_DIALOG, CONFIRM_CONFIRMATION_DIALOG } from '../actions/confirmation'

var confirmationAction = null

const confirmationMiddleware = store => next => action => {
  if (action.type === OPEN_CONFIRMATION_DIALOG) {
    confirmationAction = action.payload.action
  }
  if (action.type === CONFIRM_CONFIRMATION_DIALOG && confirmationAction) {
    confirmationAction()
  }
  return next(action)
}

export default confirmationMiddleware
