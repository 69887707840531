import {executeBlobPromise, executePromise, getApiBaseUrl} from "./commonAction";
import {NODE_TYPE_OPTIONS} from "../reducers/graphReducer";

export const searchHistory = (nodeId, nodeType) => {
    let nodePath = nodeType === NODE_TYPE_OPTIONS.ExecutionRoot ? 'executions' : 'procedures';
    let url = `${getApiBaseUrl()}/${nodePath}/${nodeId}/history`;
    return executePromise('get', url);
};

export const searchVersions = (nodeId, nodeType) => {
    let nodePath = nodeType === NODE_TYPE_OPTIONS.ExecutionRoot ? 'executions' : 'procedures';
    let url = `${getApiBaseUrl()}/${nodePath}/${nodeId}/versions`;
    return executePromise('get', url);
};

export const downloadVersion = (nodeId, nodeType, versionFileName) => {
    let nodePath = nodeType === NODE_TYPE_OPTIONS.ExecutionRoot ? 'executions' : 'procedures';
    let url = `${getApiBaseUrl()}/${nodePath}/${nodeId}/versions/${versionFileName}`;
    return executeBlobPromise('get', url);
};