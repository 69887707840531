import Resizer from "react-image-file-resizer";
import {PROCEDURE_TYPES} from "../reducers/graphReducer";
import {blobToDataURL} from "./util";

export const getImageDimensions = (file) => {
    return new Promise(function (resolved, reject) {
        let i = new Image();
        i.onload = function () {
            resolved({width: i.width, height: i.height});
            i = null;
        };
        i.onerror = function (error) {
            reject(error);
        };
        i.src = file;
    })
};

export const resizeImage = (file, width, height) => {

    return new Promise(function (resolved) {
        Resizer.imageFileResizer(
            file,
            width,
            height,
            'JPEG',
            90,
            0,
            uri => {
                resolved(uri)
            },
            'base64'
        );
    })
};

export const fileToSameFormatDataURL = (file, success, error) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        success(reader.result);
        reader = null;
    };
    reader.onerror = (err) => {
        error(err);
        reader = null;
    };
}

export const fileToDataURL = (file, maxWidth, maxHeight, imageQuality, aspectRatio) => {
    if (typeof file === 'string') {
        file = dataURLtoBlob(file);
    }

    const isSvgOrPng = file.type === "image/png" || file.type?.startsWith('image/svg');
    if(isSvgOrPng && !aspectRatio) {
        return blobToDataURL(file);
    }

    return new Promise(function (resolved) {
        getDataUrl(file, maxWidth, maxHeight, imageQuality, (image) => {
            resolved(image);
        }, aspectRatio);
    })
};

export const dataURLtoBlob = (dataurl) => {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
};

// Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
export const resizeDataURL = (datas, wantedWidth, wantedHeight, imageQuality, aspectRatio) => {
    return new Promise(async function (resolve) {

        // We create an image to receive the Data URI
        let img = document.createElement('img');

        // When the event "onload" is triggered we can resize the image.
        img.onload = function () {
            // We create a canvas and get its context.
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            let useWidth = wantedWidth;
            let useHeight = wantedHeight;

            let sx = 0;
            let sy = 0;
            let sWidth = this.width;
            let sHeight = this.height;

            const imageAspectRatio = this.width / this.height;

            if (aspectRatio != null) {
                if (imageAspectRatio > aspectRatio) {
                    // Crop the left/right hand parts
                    const sourceKeepWidth = img.width / imageAspectRatio * aspectRatio;
                    sx = (img.width - sourceKeepWidth) / 2;
                    sWidth = sourceKeepWidth;
                    sHeight = img.height;
                    useWidth = useWidth / imageAspectRatio * aspectRatio
                } else {
                    // Crop the top/bottom parts
                    const sourceKeepHeight = img.height / aspectRatio * imageAspectRatio;
                    sy = (img.height - sourceKeepHeight) / 2;
                    sWidth = img.width;
                    sHeight = sourceKeepHeight;
                    useHeight = useHeight / aspectRatio * imageAspectRatio
                }
            }

            // We set the dimensions at the wanted size.
            canvas.width = useWidth;
            canvas.height = useHeight;

            // We resize the image with the canvas method drawImage();
            ctx.drawImage(this, sx, sy, sWidth, sHeight, 0, 0, wantedWidth, wantedHeight);

            let dataURI = canvas.toDataURL("image/webp", imageQuality);

            if (!dataURI.startsWith('data:image/webp') && !dataURI.startsWith('data:image/jpeg')) {
                dataURI = canvas.toDataURL("image/jpeg", imageQuality);
            }

            // This is the return of the Promise
            resolve(dataURI);

            img = null;
            canvas.remove();
            canvas = null;
            ctx = null;
        };

        // We put the Data URI in the image's src attribute
        img.src = datas;

    })
};

// Use it like : let newDataURI = await resizedataURL('yourDataURIHere', 50, 50);
export const getDataUrl = (file, maxWidth, maxHeight, imageQuality, callback2, aspectRatio) => {
    // let reader = new FileReader();
    // const callback = function () {
    let reader2 = new FileReader();
    reader2.onload = function (e) {
        let srcBase64 = e.target.result;
        let img = new Image();

        img.onload = function () {
            let width = img.width,
                height = img.height,
                canvas = document.createElement('canvas'),
                ctx = canvas.getContext("2d");

            // set proper canvas dimensions before export
            let useWidth = width;
            let useHeight = height;

            if (useWidth > maxWidth) {
                useHeight = maxWidth / useWidth * useHeight;
                useWidth = maxWidth
            }
            if (useHeight > maxHeight) {
                useWidth = maxWidth / useHeight * useWidth;
                useHeight = maxHeight
            }

            let sx = 0;
            let sy = 0;
            let sWidth = width;
            let sHeight = height;

            const imageAspectRatio = width / height;

            if (aspectRatio != null) {
                if (imageAspectRatio > aspectRatio) {
                    // Crop the left/right hand parts
                    const sourceKeepWidth = width / imageAspectRatio * aspectRatio;
                    sx = (width - sourceKeepWidth) / 2;
                    sWidth = sourceKeepWidth;
                    sHeight = height;
                    useWidth = useWidth / imageAspectRatio * aspectRatio
                } else {
                    // Crop the top/bottom parts
                    const sourceKeepHeight = height / aspectRatio * imageAspectRatio;
                    sy = (height - sourceKeepHeight) / 2;
                    sWidth = width;
                    sHeight = sourceKeepHeight;
                    useHeight = useHeight / aspectRatio * imageAspectRatio
                }
            }
            canvas.width = useWidth;
            canvas.height = useHeight;

            // draw image
            ctx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, useWidth, useHeight);

            // export base64
            let dataURI = canvas.toDataURL("image/webp", imageQuality);

            if (!dataURI.startsWith('data:image/webp') && !dataURI.startsWith('data:image/jpeg')) {
                dataURI = canvas.toDataURL("image/jpeg", imageQuality);
            }
            callback2(dataURI);

            // reader = null;
            reader2 = null;
            img = null;
            canvas.remove();
            canvas = null;
        };

        img.onerror = function () {
            // Assuming if error than file is not an image.
            callback2(srcBase64);

            // reader = null;
            reader2 = null;
            img = null;
        }

        img.src = srcBase64;
    };

    reader2.readAsDataURL(file);
    // };
    //
    //
    // reader.onload = function () {
    //     return callback();
    // };
    // reader.readAsArrayBuffer(file);
}

export const getProcedureTypeIcon = (type) => {
    switch (type) {
        case PROCEDURE_TYPES.swms.id:
            return require("../assets/procedureTypes/swms.svg");
        case PROCEDURE_TYPES.audit.id:
            return require("../assets/procedureTypes/audit.svg");
        case PROCEDURE_TYPES.issue.id:
            return require("../assets/procedureTypes/issue.svg");
        case PROCEDURE_TYPES.workspace.id:
            return require("../assets/procedureTypes/workspace.svg");
        case PROCEDURE_TYPES.project.id:
            return require("../assets/procedureTypes/project.svg");
        case PROCEDURE_TYPES.dashboard.id:
            return require("../assets/procedureTypes/dashboard.svg");
        case PROCEDURE_TYPES.form.id:
            return require("../assets/procedureTypes/form.svg");
        case PROCEDURE_TYPES.subject.id:
            return require("../assets/procedureTypes/subject.svg");
        case PROCEDURE_TYPES.itp.id:
        default:
            return require("../assets/procedureTypes/itp.svg");
    }
}

export function imageElementToBlob(img, callback) {
    // Create an empty canvas element
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    // Copy the image contents to the canvas
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // Get the data-URL formatted image
    // Firefox supports PNG and JPEG. You could check img.src to
    // guess the original format, but be aware the using "image/jpg"
    // will re-encode the image.
    canvas.toBlob(callback, "image/webp", 0.85);
}
