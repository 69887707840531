import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {formatFullDate, formatUser} from "../../util/util";
import {ComponentBase, cypress} from "tbf-react-library";

class ProcedurePropertyAudit extends ComponentBase {

    render() {
        let {lastUpdatedDateTime, lastUpdatedByUser, releaseVersion, classes} = this.props;
        return (
            <React.Fragment>
                <div data-cy-element={'Property'} className={classes.auditWrapper}>

                    {
                        lastUpdatedDateTime &&
                        <span className={classes.audit}>
                                Modified {formatFullDate(lastUpdatedDateTime)}
                            </span>
                    }
                    {
                        lastUpdatedByUser &&
                        <span className={classes.audit}>&nbsp;by {formatUser(lastUpdatedByUser)}</span>
                    }
                </div>
                {
                    releaseVersion &&
                    <div data-cy-element={'Property'} className={classes.auditWrapper}>
                        {
                            lastUpdatedDateTime &&
                            <span className={classes.audit}>
                                Version {releaseVersion}
                            </span>
                        }
                    </div>
                }
            </React.Fragment>
        );
    }
}

const styles = () => ({
    auditWrapper: {
        paddingLeft: 4,
        whiteSpace: 'break-spaces',
    }
});
ProcedurePropertyAudit.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.nodeId;
    let node = getNodeOrNull(state, nodeId);
    return {
        lastUpdatedByUser: (node && node.lastUpdatedByUser) || null,
        lastUpdatedDateTime: (node && node.lastUpdatedDateTime) || null,
        releaseVersion: cypress.isCypress() ? null : node?.releaseVersion || null
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedurePropertyAudit));
