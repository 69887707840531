import React, {Component} from 'react'
import withStyles from '@mui/styles/withStyles';
import {connect} from 'react-redux';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {LOCATION_MODES, NODE_IDS} from "../../reducers/graphReducer";
import LocationOnIcon from '@mui/icons-material/LocationOnRounded'
import LocationOffIcon from '@mui/icons-material/LocationOffRounded'

class LocationFooter extends Component {

    render() {
        const {locationMode, locationPosition, classes} = this.props;
        const Container = this.props.container || React.Fragment;

        switch (locationMode) {
            case LOCATION_MODES.unavailable.id:
            default:
                return <Container>
                    <LocationOffIcon className={classes.locationIcon}/> Not Available
                </Container>;
            case LOCATION_MODES.available.id:
                return <Container>
                    <LocationOnIcon className={classes.locationIcon}/>
                    {
                        locationPosition && locationPosition.accuracy ?
                            '\u00B1' + Math.round(locationPosition.accuracy) + 'm' :
                            'Looking for GPS location...'
                    }
                </Container>;
            case LOCATION_MODES.userDenied.id:
                return <Container>
                    <LocationOffIcon className={classes.locationIcon}/> Not Allowed
                </Container>;
        }
    }
}

const styles = () => ({
    locationIcon: {
        fontSize: 14,
        position: 'relative',
        top: 2,
    },
});

const mapStateToProps = (state) => {
    const location = getNodeOrNull(state, NODE_IDS.Location);
    return {
        locationMode: location.mode,
        locationPosition: location.position,
    }
};

export default connect(mapStateToProps, null)(withStyles(styles, {withTheme: true})(LocationFooter));
