import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useCallbackPatchNode, useNodeOrNull } from "../../../hooks/nodeHooks"
import { getNodeOrNull, getNodesIfPresent } from "../../../selectors/graphSelectors";
import { Collapse, Divider, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import ExecutionComplete from "../ExecutionComplete";
import { ExecutionQuestionsGridItems } from "../ExecutionTask";
import { LINK_TYPES, NODE_IDS } from "../../../reducers/graphReducer";
import HighlightNode from "../troubleshoot/HighlightNode";
import cn from "classnames";
import { strings } from "../../components/SopLocalizedStrings";
import { keyBy } from "lodash";
import QueryAddExecution from "../question/buttons/QueryAddExecution";
import GraphDelete from "../../graph/GraphDelete";
import { TransitionGroup } from "react-transition-group";
import Loader from "../../components/Loader";
import GraphResourceLoad from "../../graph/GraphResourceLoad";
import { MINUTES_5 } from "../../../util/constants";

const useStyle = makeStyles((theme) => ({
    container: {
        margin: `0 0 ${theme.spacing(1)}`,
    },
    tasksContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: `16px 0`,
    },
    taskContainer: {
        overflowY: "visible",
    },
    taskGrid: {
        padding: `0 16px 0 18px`,
    },
    taskDivider: {
        margin: '32px 0',
    },
    countText: {
        color: theme.palette.grey.seven.main,
        fontSize: 16,
        fontFamily: theme.fonts.primary.bold,
        marginRight: 5,
    },
    countWrap: {
        padding: '5px 0',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    nothingToShow: {
        color: theme.palette.grey.four.main,
        padding: theme.spacing(1),
    },
    executionTitle: {
        color: theme.palette.grey.seven.main,
        fontFamily: theme.fonts.primary.bold,
        fontSize: 24,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 16,
        overflowY: "visible",
        "& span": {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflowX: "hidden",
        }
    },
    highlight: {
        backgroundColor: theme.palette.grey.two.main,
        transition: "all 0.5s ease-in-out",
    },
    addButton: {
        border: 'none !important',
    },
}))

const PAGE_SIZE = 100;

const useLinksFirstTasks = (questionId) => {
    return useSelector((state) => {
        const question = getNodeOrNull(state, questionId);
        const selectorId = NODE_IDS.ExecutionListingPage(questionId);
        const selector = getNodeOrNull(state, selectorId);
        const executions = getNodesIfPresent(state, question?.initialValue || selector?.executionIds || []).filter(e => !e.deleted);
        const executionsById = keyBy(executions, (e) => e.id);
        const firstSteps = getNodesIfPresent(state, executions.map(e => e.children?.[0]));
        const firstTasks = getNodesIfPresent(state, firstSteps.map(e => e.children?.[0])).map(t => ({...t, rootTitle: executionsById[t.rootId]?.title}));

        return firstTasks;
    });
}

const renderTask = (task, index, questionId, highlight, disabled, classes) => {
    const {id, rootId, disabled: taskDisabled, procedureTaskId, columnWidth, children, rootTitle} = task;
    return <>
        {index > 0 && <Divider className={classes.taskDivider} aria-hidden="true" />}
        <Grid id={`${questionId}-${rootId}`} container className={cn(classes.taskContainer, {
            [classes.highlight]: highlight === rootId
        })} data-cy-element={"Task"}>
            <div className={classes.executionTitle}>
                <span>{rootTitle}</span>
                {!disabled && <GraphDelete nodeId={rootId} iconButton color="warning" />}
            </div>
            <ExecutionComplete nodeId={id} disabled={disabled || taskDisabled}>
                <ExecutionQuestionsGridItems
                    procedureTaskId={procedureTaskId}
                    questionIds={children}
                    taskColumnWidth={columnWidth}
                    disabled={disabled || taskDisabled}
                    className={classes.taskGrid}
                />
            </ExecutionComplete>
        </Grid>
    </>;
}

export default function ExecutionLinkRepeatingSections({questionId, disabled, ...props}) {

    const classes = useStyle();

    const selectorId = NODE_IDS.ExecutionListingPage(questionId);

    const selector = useNodeOrNull(selectorId);

    const tasks = useLinksFirstTasks(questionId);

    const patch = useCallbackPatchNode();

    const [highlight, setHighlight] = useState(null);

    const handleAddCompleted = (item) => {
        if (item.linkType === LINK_TYPES.none.id) {
            patch({
                id: selectorId,
                recentlyCreated: [
                    ...(selector?.recentlyCreated || []),
                    {id: item.id, addedTicks: new Date().getTime()}
                ]
            });
        }
        setHighlight(item.id);
    }

    useEffect(() => {
        if (highlight) {
            setTimeout(() => setHighlight(null), 1000);
            const element = document.getElementById(`${questionId}-${highlight}`);
            element?.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }, [highlight])

    return <div {...props}>
        {selector?.loadUrl && <GraphResourceLoad
            key={selector?.loadUrl}
            resourcePath={selector?.loadUrl}
            friendlyName={strings.execution.namePlural}
            nodeType={'ExecutionRoot'}
            reloadIntervalMs={MINUTES_5}
            hideLoader={true}
            incrementalLoadOff={true}
            pageSize={PAGE_SIZE}
            displayErrorMode={'inline'}
        />}
        <div className={cn(classes.flexContainer, classes.countWrap)}>
            <HighlightNode nodeId={questionId}>
                <label className={classes.countText}>{selector?.totalTitle}</label></HighlightNode>
            <span data-cy={'count'}
                    className={'muiToolbarCountBadge'}>{selector?.total == null ? '' : selector?.total}</span>
        </div>
        <div className={classes.tasksContainer}>
            {tasks.length === 0 && <div className={classes.nothingToShow}>
                {strings.execution.workItems.sections.empty}
            </div>}
            {selector.isLoading ?
            <Loader circular contentCentered /> :
            <TransitionGroup>
                {
                    tasks.map((task, index) => {
                        return <Collapse key={task.id}>
                            {renderTask(task, index, questionId, highlight, disabled, classes)}
                        </Collapse>
                    })
                }
            </TransitionGroup>}
        </div>
        <QueryAddExecution questionId={questionId} disabled={disabled} onAddCompleted={handleAddCompleted} variant="text" className={classes.addButton} label={strings.app.addMore} primaryAction={false} dialog={false} />
    </div>
}