import {strings} from "../layouts/components/SopLocalizedStrings";

export const GLOBAL_SEARCH_TYPES = {
    workItems: {id: 'workItems', name: strings.execution.workItems.title},
    templates: {id: 'templates', name: 'Lists'},
    users: {id: 'users', name: 'People'},
    teams: {id: 'teams', name: 'Teams'},
}

export const REPORT_EVENT_TYPES = {
    listingPageViewChanged: {id: 'listingPageViewChanged', name: 'ListingPage.ViewChanged'},
    listingPageSearchFilterUsed: {id: 'listingPageSearchFilterUsed', name: 'ListingPage.TextSearch'},
    listingPageReportToggled: {id: 'listingPageReportToggled', name: 'ListingPage.ReportToggled'},
    listingPageFilterOpened: {id: 'listingPageFilterOpened', name: 'ListingPage.Filter.Opened'},
    listingPageColumnsOpened: {id: 'listingPageColumnsOpened', name: 'ListingPage.Columns.Opened'},
    listingPageItemClicked: {id: 'listingPageItemClicked', name: 'ListingPage.Item.Clicked'},
    listingPageStatusDetailsOpened: {id: 'listingPageStatusDetailsOpened', name: 'ListingPage.Item.StatusDetails.Opened'},
    executionPagePropertiesLinkClicked: {id: 'executionPagePropertiesLinkClicked', name: 'ExecutionPage.Properties.Link.Clicked'},
    executionPageSelectLinkClicked: {id: 'executionPageSelectLinkClicked', name: 'ExecutionPage.Select.Link.Clicked'},
    executionPageQueryItemClicked: {id: 'executionPageQueryItemClicked', name: 'ExecutionPage.Query.Link.Clicked'},
    executionPagePropertiesAssignmentAdded: {id: 'executionPagePropertiesAssignmentAdded', name: 'ExecutionPage.Properties.Assignment.Added'},
    executionPagePropertiesAssignmentRemoved: {id: 'executionPagePropertiesAssignmentRemoved', name: 'ExecutionPage.Properties.Assignment.Removed'},
    executionPagePropertiesLinkAdded: {id: 'executionPagePropertiesLinkAdded', name: 'ExecutionPage.Properties.Link.Added'},
    executionPagePropertiesLinkRemoved: {id: 'executionPagePropertiesLinkRemoved', name: 'ExecutionPage.Properties.Link.Removed'},
    executionPagePropertiesLinksShowMore: {id: 'executionPagePropertiesLinksShowMore', name: 'ExecutionPage.Properties.Links.ShowMore'},
    executionPagePropertiesAttachmentViewed: {id: 'executionPagePropertiesAttachmentViewed', name: 'ExecutionPage.Properties.Attachment.Viewed'},
    executionPageAttachmentViewed: {id: 'executionPageAttachmentViewed', name: 'ExecutionPage.Attachment.Viewed'},
    executionPageMenuOpened: {id: 'executionPageMenuOpened', name: 'ExecutionPage.Menu.Opened'},
    executionPageMenuTemplate: {id: 'executionPageMenuTemplate', name: 'ExecutionPage.Menu.Template'},
    executionPageMenuExport: {id: 'executionPageMenuExport', name: 'ExecutionPage.Menu.ExportJSON'},
    executionPageMenuDelete: {id: 'executionPageMenuDelete', name: 'ExecutionPage.Menu.Delete'},
    executionPageMenuRestore: {id: 'executionPageMenuRestore', name: 'ExecutionPage.Menu.Restore'},
    executionPageMenuDestroy: {id: 'executionPageMenuDestroy', name: 'ExecutionPage.Menu.Destroy'},
    executionPageMenuExportPdf: {id: 'executionPageMenuExportPdf', name: 'ExecutionPage.Menu.ExportPDF'},
    executionPageMenuActivity: {id: 'executionPageMenuActivity', name: 'ExecutionPage.Menu.Activity'},
    executionPageMenuHistory: {id: 'executionPageMenuHistory', name: 'ExecutionPage.Menu.History'},
    executionPageMenuVersions: {id: 'executionPageMenuVersions', name: 'ExecutionPage.Menu.Versions'},
    executionPageMenuPull: {id: 'executionPageMenuPull', name: 'ExecutionPage.Menu.Pull'},
    executionPageMenuViewToggle: {id: 'executionPageMenuViewToggle', name: 'ExecutionPage.Menu.ViewToggle'},
    executionPageMenuOffline: {id: 'executionPageMenuOffline', name: 'ExecutionPage.Menu.Offline'},
    executionPageToolbarOffline: {id: 'executionPageToolbarOffline', name: 'ExecutionPage.Toolbar.Offline'},
    executionPageToolbarStatusDetails: {id: 'executionPageToolbarStatusDetails', name: 'ExecutionPage.Toolbar.StatusDetails'},
    globalSearchLinkClicked: {id: 'globalSearchLinkClicked', name: 'GlobalSearch.Link.Clicked'},
    globalSearchUsed: {id: 'globalSearchUsed', name: 'GlobalSearch.Used'},
    dashboardPageRecentLinkClicked: {id: 'dashboardPageRecentLinkClicked', name: 'DashboardPage.Recent.Link.Clicked'},
    dashboardPageAssignedLinkClicked: {id: 'dashboardPageAssignedLinkClicked', name: 'DashboardPage.Assigned.Link.Clicked'},
    dashboardPageRecentViewAll: {id: 'dashboardPageRecentViewAll', name: 'DashboardPage.Recent.ViewAll'},
    dashboardPageRecentShowMore: {id: 'dashboardPageRecentShowMore', name: 'DashboardPage.Recent.ShowMore'},
    dashboardPageAssignedShowMore: {id: 'dashboardPageAssignedShowMore', name: 'DashboardPage.Assigned.ShowMore'},
    dashboardPageRecentShowLess: {id: 'dashboardPageRecentShowLess', name: 'DashboardPage.Recent.ShowLess'},
    dashboardPageAssignedShowLess: {id: 'dashboardPageAssignedShowLess', name: 'DashboardPage.Assigned.ShowLess'},
    dashboardPageMyWorqTabChanged: {id: 'dashboardPageMyWorqTabChanged', name: 'DashboardPage.MyWorq.TabChanged'},
    dashboardPageMyWorqTabItemClicked: {id: 'dashboardPageMyWorqTabItemClicked', name: 'DashboardPage.MyWorq.ItemClicked'},
    projectPageFilterChanged: {id: 'projectPageFilterChanged', name: 'ProjectPage.FilterChanged'},
    projectPageItemClicked: {id: 'projectPageItemClicked', name: 'ProjectPage.Item.Clicked'},
    assignedPageLinkClicked: {id: 'assignedPageLinkClicked', name: 'AssignedPage.Link.Clicked'},
    breadcrumbLinkClicked: {id: 'breadcrumbLinkClicked', name: 'Breadcrumb.Link.Clicked'},
    userMenuOpened: {id: 'userMenuOpened', name: 'UserMenu.Opened'},
    helpPointOpened: {id: 'helpPointOpened', name: 'HelpPoint.Opened'},
    sideMenuItemClicked: {id: 'sideMenuItemClicked', name: 'SideMenu.Item.Clicked'}
}


export const MINUTES_1 = 1 * 60 * 1000;
export const MINUTES_2 = 2 * 60 * 1000;
export const MINUTES_5 = 5 * 60 * 1000;
export const MINUTES_15 = 15 * 60 * 1000;
export const HOURS_1 = 60 * 60 * 1000;
export const HOURS_24 = 24 * 60 * 60 * 1000;
export const DAYS_7 = 7 * 24 * 60 * 60 * 1000;