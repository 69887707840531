import { Button, Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { ChromePicker, CustomPicker} from 'react-color';
import cn from 'classnames';

const useStyle = makeStyles((theme) => ({
    button: {
        display: 'flex',
        height: 32,
        width: 72,
        padding: '2px',
        border: `solid 1px ${theme.palette.grey.two.main}`,
        borderRadius: '8px',
    },
    color: {
        display: 'flex',
        minWidth: 'calc(100% - 4px)',
        minHeight: 'calc(100% - 4px)',
        borderRadius: '4px',
    },
    picker: {
        fontFamily: `${theme.fonts.primary.regular} !important`,
    }
}));

const TbfColorPicker = CustomPicker(({disabled, className, ...props}) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const {hex} = props;

    const classes = useStyle();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = !!anchorEl;

    const id = open ? 'color-picker-popover' : undefined;

    return <div>
        <Button disabled={disabled} onClick={handleClick} aria-describedby={id} className={cn(className, classes.button)}>
            <div className={classes.color} style={{backgroundColor: hex}} />
        </Button>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <ChromePicker className={classes.picker} {...props} />
        </Popover>
    </div>
});

export default TbfColorPicker;