import React, { useLayoutEffect } from 'react'
import { ResizeObserver } from '@juggle/resize-observer'

export default function useResizeObserver (targetRef, callback) {
  useLayoutEffect(() => {
    if(targetRef.current) {
      const ro = new ResizeObserver(callback)
      ro.observe(targetRef.current) 
      return () => {
        ro.disconnect()
      }
    }
  }, [callback, targetRef])
}

export const useParentWidthObserver = (parentRef) => {
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        setWidth(entry.contentRect.width);
      }
    });

    if (parentRef.current) {
      observer.observe(parentRef.current);
    }

    return () => {
      if (parentRef.current) {
        observer.unobserve(parentRef.current);
      }
    };
  }, [parentRef]);

  return {width};
}
