import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../style/alert.css';
import "./../../style/form.css";
import {putNodesProperty, reportNodeEvent} from "../../actions";
import {ComponentBase} from "tbf-react-library";
import {ASSIGNMENT_ENTITY_TYPES, ASSIGNMENT_TYPES, NODE_IDS} from "../../reducers/graphReducer";
import ExecutionSelector from "../components/ExecutionSelector";
import {getNodeSchemaOrNull, getNodesIfPresent, getRootNodeOrError} from "../../selectors/graphSelectors";
import {createChildNode} from "../../factory/graphFactory";
import {strings} from "../components/SopLocalizedStrings";
import {GLOBAL_SEARCH_TYPES, REPORT_EVENT_TYPES} from "../../util/constants";

class ExecutionAssignmentUser extends ComponentBase {

    handleChangeSelect = (selection) => {
        // 1. Prepare
        let {assignments, onPutNodesProperty, nodeId, execution, assignmentSchema, targetId, reportEvent} = this.props;
        let selectedUsers = selection || [];
        let selectedUsersById = {};
        for (let selectedUser of selectedUsers) {
            selectedUsersById[selectedUser.id] = selectedUser;
        }

        // 2. Delete Removed Items
        let hasDeleted = false;
        let updates = [];
        let existingAssignmentsByEntityId = {};
        for (let existingAssignment of assignments) {
            existingAssignmentsByEntityId[existingAssignment.entityId] = existingAssignment;
            let stillExists = selectedUsersById[existingAssignment.entityId];
            if (!stillExists) {
                updates.push({id: existingAssignment.id, deleted: true});
                hasDeleted = true;
            }
        }

        // 3. Add new assignments
        let hasAdded = false;
        let newAssignmentIds = [];
        for (let selectedUser of selectedUsers) {
            let alreadyExists = existingAssignmentsByEntityId[selectedUser.id];
            if (alreadyExists) {
                continue;
            }
            hasAdded = true;
            let assignAttr = {
                assignmentType: ASSIGNMENT_TYPES.task.id,
                assignedNodeId: nodeId,
                entityId: selectedUser.id,
                entityName: selectedUser.title,
                entityType: ASSIGNMENT_ENTITY_TYPES.user.id
            };
            let assignmentNode = createChildNode(execution, assignmentSchema, assignAttr);
            newAssignmentIds.push(assignmentNode.id);
            updates.push(assignmentNode);
        }

        // 4. Execution Root
        if (newAssignmentIds.length > 0) {
            updates.push({id: execution.id, assignments: [...(execution.assignments || []), ...newAssignmentIds]});
        }

        if (hasAdded) {
            reportEvent(targetId, REPORT_EVENT_TYPES.executionPagePropertiesAssignmentAdded.name);
        }

        if (hasDeleted) {
            reportEvent(targetId, REPORT_EVENT_TYPES.executionPagePropertiesAssignmentRemoved.name);
        }

        // 4. Send it off
        onPutNodesProperty(updates);
    }

    render() {
        let {procedureIds, disabled, selectedOption, nodeId} = this.props;
        return (
            <React.Fragment>
                {
                    nodeId &&
                    <ExecutionSelector
                        procedureIds={procedureIds}
                        multiple={true}
                        mobileView={true}
                        value={selectedOption}
                        handleChange={this.handleChangeSelect}
                        disabled={disabled}
                        id={nodeId + '_assignments'}
                        renderOptionKey={false}
                        placeholder={strings.assignment.unassignedUser}
                        searchTypes={[GLOBAL_SEARCH_TYPES.users.id]}
                        menuPortalTarget={document.body}
                        defaultOptions={true}
                    />
                }
            </React.Fragment>);
    };
}

const styles = () => ({});
ExecutionAssignmentUser.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    // Support 1 node for now, so not bulk screen
    const nodes = ownProps.nodes;
    const node = nodes[0];
    let execution = getRootNodeOrError(state, node.id);
    let assignments = getNodesIfPresent(state, node.assignments || [])
        .filter(a => !a.deleted && a.entityType === ASSIGNMENT_ENTITY_TYPES.user.id && a.assignedNodeId === node.id);

    let selectedOption = assignments.map(a => ({id: a.entityId, title: a.entityName}));
    return {
        procedureIds: [NODE_IDS.UserSubject],
        assignments: assignments,
        selectedOption: selectedOption,
        execution: execution,
        assignmentSchema: getNodeSchemaOrNull(state, 'ExecutionAssignment'),
        nodeId: node && node.id
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: nodes => dispatch(putNodesProperty(nodes)),
        reportEvent: (id, eventName, eventProperties) => dispatch(reportNodeEvent(id, eventName, eventProperties)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionAssignmentUser));
