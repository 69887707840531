import React, {useCallback, useState} from "react";
import {withRouter} from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import {GLOBAL_SEARCH_TYPES} from "../../util/constants";
import Button from "@mui/material/Button";
import ExpandLess from "@mui/icons-material/ExpandLessRounded";
import ExpandMore from "@mui/icons-material/ExpandMoreRounded";
import {URL_IDENTITY_BASE} from "tbf-react-library";
import TbfAvatar from "../components/common/TbfAvatar";

const GlobalSearchUserList = ({history, classes, title, items, type}) => {
    const limit = 10;
    const seeAllEnabled = items.length > limit;
    const [seeAll, setSeeAll] = useState(false);

    const getItems = useCallback(() => {
        return seeAllEnabled ? seeAll ? items : items.slice(0, limit) : items;
    }, [items, seeAllEnabled, seeAll]);

    const handleClick = useCallback((item) => {
        if (item.type === GLOBAL_SEARCH_TYPES.users.id) {
            history.push(`/executions/${item.value}`);
        } else if (item.type === GLOBAL_SEARCH_TYPES.teams.id) {
            window.open(`${URL_IDENTITY_BASE}/groups/${item.value}`, '_blank');
        }
    }, [history]);

    return <div className={classes.container}>
        <div className={classes.title}>{title}</div>
        <div>
            {
                getItems().map((item, index) => <div key={`user_${index}`} className={classes.customCardOptionContainer}
                                                     onClick={() => handleClick(item)}>
                    <label className={classes.customOptionTitle}>
                        <TbfAvatar name={item.label}/>
                        <span className={'userName'} title={item.label}>{item.label}</span>
                    </label>
                    {
                        item.email && <span className={classes.userEmail}>{item.email}</span>
                    }
                </div>)
            }
        </div>
        {
            seeAllEnabled &&
            <div className={classes.footer}>
                <Button
                    className={classes.showMoreButton}
                    startIcon={(seeAll ? <ExpandLess/> : <ExpandMore/>)}
                    onClick={() => setSeeAll(!seeAll)}>
                    {seeAll ? 'See fewer' : 'See all'} {title.toLowerCase()}
                </Button>
            </div>
        }
    </div>;
}

const styles = (theme) => ({
    container: {
        width: '100%',
        borderRadius: 4,
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        padding: '12px 15px',
        float: 'left',
        background: theme.palette.primary.two.main,
    },
    title: {
        fontFamily: theme.fonts.primary.bold,
        height: 30,
        padding: '0 5px',
    },
    footer: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        padding: '10px 10px 0px 10px',
    },
    showMoreButton: {
        fontSize: '0.75rem',
        textTransform: 'none',
    },
    customCardOptionContainer: {
        float: 'left',
        width: 'auto !important',
        padding: '10px 15px',
        transition: 'background .3s',
        boxShadow: '1px 1px 4px rgb(147 160 159)',
        margin: '8px 5px 4px 5px',
        borderRadius: 4,
        display: 'inline-grid',
        '&:hover': {
            background: theme.palette.secondary.one.main,
        }
    },
    customOptionTitle: {
        width: '100%',
        float: 'left',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        '& .userInitials': {
            marginRight: 6,
            width: 32,
            height: 32,
            fontSize: 12,
            background: theme.palette.primary.one.main,
        },
        '& .userInitials.teams': {
            background: `${theme.palette.grey.five.main} !important`,
        },
        '& .userName': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            color: theme.palette.primary.three.main,
        },
    },
    userEmail: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: theme.palette.grey.six.main,
        marginTop: 5,
    },
});

export default withStyles(styles)(withRouter(GlobalSearchUserList));
