import React from "react";
import withStyles from '@mui/styles/withStyles';

const LocationCard = ({classes, position}) => {

    return <div className={classes.container}>
        <div className={classes.label}>Location details</div>
        <div className={classes.row}>
            <span className={classes.label}>Latitude:</span>
            <span>&nbsp;{position?.lat}</span>
        </div>
        <div className={classes.row}>
            <span className={classes.label}>Longitude:</span>
            <span>&nbsp;{position?.lng}</span>
        </div>
    </div>
}

const styles = theme => ({
    container: {
        width: '100%',
        padding: '10px 15px',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    label: {
        fontFamily: theme.fonts.primary.bold,
    },
});

export default withStyles(styles)(LocationCard);
