import React from "react";
import withStyles from '@mui/styles/withStyles';
import {useNodeOrError,} from "../../../hooks/nodeHooks";
import {Panel, TbfOptionsMenu} from "tbf-react-library";
import NodeTextField from "../../graph/NodeTextField";
import {strings} from "../../components/SopLocalizedStrings";
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import ProcedureSecurityRuleMembers from "./ProcedureSecurityRuleMembers";
import GraphDelete from "../../graph/GraphDelete";
import ProcedureCompileMessages from "../ProcedureCompileMessages";

const pageStrings = strings.procedure.settings.security;
const grantDenyOptions = [RULE_ACTION_TYPE.grant].map(a => ({value: a.id, label: a.name}))
const ProcedureSecurityRule = ({disabled, ruleId}) => {
    const rule = useNodeOrError(ruleId)
    const renderPanelActions = () => {
        return (<>
            {
                !disabled &&
                <TbfOptionsMenu>
                    <GraphDelete
                        nodeId={ruleId}
                        friendlyName={strings.procedure.rule.title}
                        menuItem={true}
                    />
                </TbfOptionsMenu>
            }
        </>)
    }
    return (<Panel title={rule.name} renderPanelActions={renderPanelActions}>
        <NodeTextField
            nodeId={rule.id}
            nodePropertyName={'actionType'}
            disabled={disabled}
            visual={'select'}
            label={pageStrings.grantDenyLabel}
            nodeValueOptions={grantDenyOptions}
        />
        <ProcedureSecurityRuleMembers disabled={disabled} ruleId={ruleId}/>
        <NodeTextField
            nodeId={rule.id}
            nodePropertyName={'permissions'}
            disabled={disabled}
            visual={'select'}
        />
        <ProcedureCompileMessages nodeId={ruleId}/>
    </Panel>)
}
const styles = () => ({});
export default withStyles(styles)(ProcedureSecurityRule);
