import React, { Suspense } from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodesOrError} from "../../../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../../../style/alert.css';
import {ComponentBase} from "tbf-react-library";
import {EMPTY_ARRAY} from "../../../../util/util";
import ExecutionQuestionMessage from "./ExecutionQuestionRuleMessage";
import ExecutionQuestionInvalidMessage from "./ExecutionQuestionRuleInvalidMessage";
import MessageType from "../../../components/MessageType";
import withDraftJs from "./LazyDraftJs";
import Loader from '../../../components/Loader';

class ExecutionQuestionMessages extends ComponentBase {

    render() {
        let {rules, initialValueInvalidReason, classes, convertToRaw, ContentState} = this.props;

        return (
            <div>
                {
                    initialValueInvalidReason &&
                    <MessageType customClass={classes.customInvalidReasonClass}
                                 contentState={convertToRaw?.(ContentState?.createFromText(initialValueInvalidReason))}
                                 messageType={'error'}/>
                }
                {rules.filter(a => a.invalidInputOn).map(item => (
                    <ExecutionQuestionInvalidMessage key={item.id} ruleId={item.id}/>))}
                {rules.filter(a => a.messageOn).map(item => (
                    <ExecutionQuestionMessage key={item.id} ruleId={item.id}/>))}
            </div>
        );
    }
}

const styles = () => ({
    customInvalidReasonClass: {
        marginTop: 5
    }
});
ExecutionQuestionMessages.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    if (!executionQuestionNode) {
        return {
            rules: []
        }
    }
    let includePhoto = executionQuestionNode.initialPhotoIdsEnabled;
    let activeRules = getNodesOrError(state, executionQuestionNode.ruleIds || EMPTY_ARRAY)
        .filter(a => a.evaluatedValue === true)
        .filter(a => a.invalidInputOn || a.messageOn || (includePhoto && a.photoInstructionsOn));
    if (activeRules.length === 0) {
        activeRules = EMPTY_ARRAY;
    }
    return {
        rules: activeRules,
        initialValueInvalidReason: executionQuestionNode.initialValueInvalidReason
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withDraftJs(ExecutionQuestionMessages, false)));
