import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useReportEventCallback } from "../../../hooks/nodeHooks";


export default function TbfSopLink({nodeId, eventName, eventProperties, to, dataCy, onClick, title, className, ...props}) {

    const reportEvent = useReportEventCallback();

    const handleClick = (e) => {
        if (eventName) {
            reportEvent(nodeId, eventName, eventProperties);
        }
        onClick?.(e);
    }
    
    return <Link
        to={to}
        onClick={handleClick}
        title={title}
        className={className}
        data-cy={dataCy}
        {...props}
    />
}