import React from "react"
import { getGridSingleSelectOperators, getGridStringOperators } from '@mui/x-data-grid-premium';
import { createStyles, makeStyles } from '@mui/styles';

import { GLOBAL_SEARCH_TYPES } from "../../../util/constants";
import ExecutionSelector from "../../components/ExecutionSelector";
import { useNodesById } from "../../../hooks/nodeHooks";


const WorqSelectFilter = ({ selectMany, procedureIds, item, applyValue }) => {
    const classes = useStyles();
    const field = `${item.field}_${item.id}`;

    const nodeIds = React.useMemo(() => {
        let selectedValue = item?.value;
        if (!selectedValue) {
            return []
        };

        if (!selectMany) {
            selectedValue = [selectedValue]
        }

        const nodeIds = selectedValue.map(id => `ExecutionSummary-${id}`);
        return nodeIds;

    }, [item?.value, selectMany])

    const selectedOptions = useNodesById(nodeIds);

    const handleChangeSelect = (selection) => {
        let newValue = null;

        if (selectMany && selection.length > 0) {
            newValue = selection.map(a => a.id);
        }
        else if (selectMany && selection.length === 0) {
            newValue = null;
        }
        else {
            newValue = selection?.id
        }

        applyValue({ ...item, value: newValue })
    }

    return <ExecutionSelector
        procedureIds={procedureIds}
        multiple={selectMany}
        value={selectedOptions}
        handleChange={handleChangeSelect}
        id={field}
        customId={field}
        propertyName={field}
        renderOptionKey={false}
        mobileView={true}
        customClass={classes.customClass}
        canCreate={false}
        searchTypes={[GLOBAL_SEARCH_TYPES.workItems.id]}
        menuPortalTarget={document.body}
        defaultOptions={true}
        workItemSearch={true}
        placeholder="Type to search"
    />
}

const useStyles = makeStyles(() =>
    createStyles({
        customClass: {
            '& .select__indicators': {
                position: 'absolute',
                right: 0,
                top: 0,
                height: '100%',
            },
            '& .select__dropdown-indicator': {
                padding: 3
            },
            '& .select__clear-indicator': {
                marginRight: 4,
            },
            '& .select__placeholder': {
                width: 'calc(100% - 30px)'
            },
            '& .select__placeholder .MuiFormLabel-root': {
                marginBottom: 0,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            },
            '& .select__input-container': {
                display: 'grid',
            },
            '& .select__control': {
                marginLeft: 4,
            }
        }
    }),
);


const stringOperators = [
    'contains',
    'isEmpty',
    "isNotEmpty"
  ]

const selectOperators = [
    'is',
    'isAnyOf',
]

const stringFilterOperator = getGridStringOperators()
    .filter(({ value }) => stringOperators.includes(value))

const selectFilterOperator = getGridSingleSelectOperators()
    .filter(({ value }) => selectOperators.includes(value))

const worqSelectFilterOperator = selectFilterOperator
    // override InputComponent to use custom component ( WorqSelectFilter )
    .map((operator) => {
        const selectMany = operator.value === 'isAnyOf';
        return {
            ...operator,
            InputComponent: ({ applyValue, item, apiRef }) => {
                const columns = apiRef.current.state.columns.lookup;
                const column = columns[item.field];
                const procedureIds = column.procedureIds;

                if (!procedureIds) {
                    return undefined;
                }

                return <WorqSelectFilter
                    applyValue={applyValue}
                    item={item}
                    selectMany={selectMany}
                    procedureIds={procedureIds} />;
            }
        }
    })


export const getGridWorqSelectOperators = () => {
    return {
        extendType: 'singleSelect',
        filterOperators: [...worqSelectFilterOperator, ...stringFilterOperator]
    };
}

export const getGridExtendedSelectOperators = () => {
    return {
        extendType: 'singleSelect',
        filterOperators: [...selectFilterOperator, ...stringFilterOperator]
    };
}
  
export const getGridExtendedTextOperators = () => {
    return {
        extendType: 'string',
        filterOperators: [...stringFilterOperator]
    };
  }

export const worqSelect = {
    type: 'singleSelect',
    filterOperators: [...worqSelectFilterOperator, ...stringFilterOperator]
}

export const extendedSelect = {
    type: 'singleSelect',
    filterOperators: [...selectFilterOperator, ...stringFilterOperator]
};

export const extendedString = {
    type: 'string',
    filterOperators: [...stringFilterOperator]
};