if (!Object.values) Object.values = o => Object.keys(o).map(k => o[k]);
export const Permissions = {
    manuals: {
        create: "create:HelpDocument",
        read: "read:HelpDocument",
        delete: "delete:HelpDocument",
        update: "update:HelpDocument",
    }
};

export const MANUAL_PERMISSION_LIST = Object.values(Permissions).map(item => Object.values(item).join(' ')).join(' ');
