import React, {useCallback} from 'react';
import {Permissions} from "../../permissions";
import {strings} from "../components/SopLocalizedStrings";
import {useDispatch, useSelector} from "react-redux";
import {useHasProcedurePermission} from "../../hooks/procedureHooks";
import {putNodesProperty} from "../../actions";
import {DeleteSweep} from "@mui/icons-material";
import {SharedAuth, TbfAction} from "tbf-react-library";
import {pruneDeleted} from "../../factory/procedureFactory";
import {useNodePropertyOrNull} from "../../hooks/nodeHooks";


const ProcedurePruneButton = ({procedureId}) => {
    const dispatch = useDispatch()
    const reduxState = useSelector(state => state);
    const callBack = useCallback(() => {
        let pruned = pruneDeleted(reduxState, procedureId);
        dispatch(putNodesProperty(pruned))
    }, [dispatch, reduxState, procedureId])
    const hasPermission = useHasProcedurePermission(procedureId, Permissions.procedure.edit)
        && SharedAuth.userHasPermission(Permissions.diagnostic.read)
    const editOn = useNodePropertyOrNull(procedureId, a => a.editOn)
    if (!hasPermission || !editOn) {
        return null;
    }
    return <TbfAction
        menuItem={true}
        permission={Permissions.procedure.create}
        title={strings.procedure.show.pruneButtonMessage}
        onClick={callBack}
        iconComponent={DeleteSweep}
        data-cy={'prune'}
    >{strings.procedure.show.pruneButtonTitle}</TbfAction>
}
export default ProcedurePruneButton
