import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../../../style/alert.css';
import {putNodeProperty} from "../../../../actions";
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import * as PropTypes from "prop-types";
import {ComponentBase} from "tbf-react-library";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ButtonBase from "@mui/material/ButtonBase";
import ExecutionPhoto from "./ExecutionPhoto";
import ImageListItem from "@mui/material/ImageListItem";
import {strings} from "../../../components/SopLocalizedStrings";

class ExecutionPhotoListPhoto extends ComponentBase {
    height = 82;
    width = 82;

    render() {
        let {
            classes,
            photoId,
            photoExists,
            executionQuestionId,
            disabled,
            openLightbox,
            field,
            handleButtonPress,
            handleButtonRelease,
            selectPhoto,
            isPhotoSelected,
            attachmentUrl,
            originalUrl,
            selection,
        } = this.props;

        if (!photoExists) {
            return null;
        }
        const photo = {id: photoId, attachmentUrl, originalUrl};
        return (
            <React.Fragment>
                <ImageListItem onTouchStart={(event) => handleButtonPress && handleButtonPress(event, photo)}
                    onTouchEnd={(event) => handleButtonRelease && handleButtonRelease(event, photo)}
                    onClick={(event) => openLightbox && openLightbox(event, photo)}
                    onContextMenu={(event) => event.preventDefault()}
                    className={classes.gridListTile} disabled={disabled}>
                    <div className={classes.photoTitle} >
                        {
                            selection &&
                            <CheckCircleRoundedIcon
                                className={'photoSelectingCheckIcon' + (isPhotoSelected ? ' photoSelectedCheckIcon' : '')}
                                onClick={(event) => selectPhoto && selectPhoto(event, photo)}/>
                        }
                        <ButtonBase
                            aria-label={strings.buttons.showPhotoPreview}
                            className={classes.photoTile + ' ' + (isPhotoSelected ? 'selected' : '')}>
                            <ExecutionPhoto
                                executionQuestionId={executionQuestionId} field={field} photoId={photoId}/>
                        </ButtonBase>
                    </div>
                </ImageListItem>
            </React.Fragment>
        )
    }
}

const styles = (theme => ({
    gridListTile: {
        cursor: 'pointer',
        height: 'auto !important',
        padding: '0px 3px !important',
        display: 'inline-block',
        '&:hover .photoSelectingCheckIcon': {
            display: 'block'
        },
        '& .photoSelectingCheckIcon': {
            color: theme.palette.grey.three.main,
            position: 'absolute',
            zIndex: 1,
            borderRadius: '50%',
            fontSize: 18,
            top: 0,
            left: 0,
            display: 'none'
        },
        '& .photoSelectedCheckIcon': {
            color: theme.palette.primary.one.main,
            background: theme.palette.primary.two.main,
            display: 'block'
        }
    },
    photoTile: {
        cursor: 'pointer',
        width: '80px !important',
        height: '80px !important',
        transition: 'transform .25s',
        '&.selected': {
            transform: 'scale(0.9)',
        }
    },
    photoTitle: {
        borderRadius: 4,
        border: `1px solid transparent`,
        overflow: 'hidden'
    }
}));

ExecutionPhotoListPhoto.propTypes = {
    executionQuestionId: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    photoId: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const photo = getNodeOrNull(state, ownProps.photoId);
    const attachmentUrl = photo && photo[ownProps.attachmentType + 'Url'];
    const originalUrl = photo && photo['originalUrl'];
    return {
        photoExists: !!photo,
        attachmentUrl,
        originalUrl,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionPhotoListPhoto));
