import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {hasAnyDirtyNodes,} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {getDbNode} from "../../util/offline";
import {getTime, reportDeveloperWarning, reportError, reportInfo, tbfLocalStorage} from "tbf-react-library";
import {retryStoreDbNodes} from "../../actions";
import {NODE_IDS} from "../../reducers/graphReducer";
import {MINUTES_1} from "../../util/constants";

/**
 * Detect a broken indexeddb and reload the app when safe to do.
 *
 * Alternatively, if issue is fixed then re-try saving to indexeddb the failed ones.
 */
class GraphAutoReloadApp extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            lastIndexedDbError: null,
            indexedDbUnavailable: false,
            restoreAttemptCount: 0
        }
        this.onVisibilityChanged = this.onVisibilityChanged.bind(this);
        this.evaluateIndexedDbError = this.evaluateIndexedDbError.bind(this);
    }

    componentDidMount() {
        document.onvisibilitychange = this.onVisibilityChanged;
    }

    lastRestoreRequested = null;
    checkingIndexedIsAlive = false;

    componentDidUpdate(prevProps, prevState, snapshot) {
        const noLongerDirty = this.props.hasDirty === false && prevProps.hasDirty === true;
        // Once all saved lets see if reload is required
        if (noLongerDirty && this.state.indexedDbUnavailable) {
            this.evaluateIndexedDbError();
        }
        // Saving to indexeddb failed
        if (this.props.storedDbError && !prevProps.storedError) {
            this.checkIndexedIsAlive();
        }
    }


    /**
     * When app becomes active re-check if indexeddb is having issues.
     * @param e
     * @param count
     */
    onVisibilityChanged = () => {
        if (document.visibilityState === 'visible') {
            this.checkIndexedIsAlive();
        }
    };

    checkIndexedIsAlive = (count = 1) => {
        const {storedDbErrorNodeIds, retryStoreDbNodes} = this.props;
        if (this.checkingIndexedIsAlive && count === 1) {
            return;
        }
        this.checkingIndexedIsAlive = true;
        getDbNode(NODE_IDS.UserDevice)
            .then(() => {
                this.checkingIndexedIsAlive = false;
                this.setState({lastIndexedDbError: null, indexedDbUnavailable: false})
                let ids = Object.keys(storedDbErrorNodeIds || {})
                if (ids.length > 0) {
                    const now = getTime();
                    if (!this.lastRestoreRequested || this.lastRestoreRequested + MINUTES_1 < now) {
                        this.lastRestoreRequested = getTime();
                        this.setState(state => ({restoreAttemptCount: state.restoreAttemptCount + 1}))
                        retryStoreDbNodes(ids);
                        reportDeveloperWarning(`Attempting store to indexeddb of store failed nodes. Count: [${ids.length}].`)
                    }
                }
            })
            .catch((err) => {
                reportError(`IndexedDb check failed with [${err}].`);
                if (count >= 3) {
                    this.checkingIndexedIsAlive = false;
                    this.setState({lastIndexedDbError: err, indexedDbUnavailable: true});
                    this.evaluateIndexedDbError(err)
                } else {
                    window.setTimeout(() => {
                        this.checkIndexedIsAlive(count + 1);
                    }, 1000)
                }
            });
    }

    reloadStarted = false;
    evaluateIndexedDbError = (err) => {
        // Ah shit
        const canReload = this.props.storedDbErrorNodeIds === null || this.props.hasDirty === false;
        if (canReload) {
            // Limit to 1 per hour just in case its not fixed by a refresh
            const lastReload = tbfLocalStorage.getItem('last_indexeddb_reload');
            const nowTime = getTime();
            if (lastReload != null) {
                const lastReloadTime = JSON.parse(lastReload);
                const diff = nowTime - lastReloadTime;
                if (diff < 1000 * 60 * 60) {
                    console.info('Indexeddb is broken but already reloaded within the last hour.')
                    return;
                }
            }
            tbfLocalStorage.setItem('last_indexeddb_reload', JSON.stringify(nowTime));
            reportError(`IndexedDb broken with [${err}]. Reloading...`);
            this.reloadStarted = true;
            reportInfo('This application will reload in 5 seconds due to an issue with local storage.');
            // Give the logging a chance to run
            window.setTimeout(() => {
                console.info('GraphAutoReloadApp.reload');
                window.location.reload();
            }, 5000);
        } else {
            reportError(`IndexedDb broken with [${err}] and unsaved changes. Not reloading.`);
        }
        this.forceUpdate();
    };

    render() {
        const {isStorageFull} = this.props;
        const {lastIndexedDbError} = this.state;
        return (
            <React.Fragment>
                {
                    lastIndexedDbError &&
                    <p data-cy={'GraphAutoReloadApp'} className={'alert alert-warning'}>Local storage is unavailable.
                        Once outstanding changes are
                        saved the application will automatically reload.</p>
                }

                {
                    isStorageFull &&
                    <p data-cy={'GraphAutoReloadApp'} className={'alert alert-warning'}>Your device is out of storage. Please sync any remaining changes, and then log out and back in.</p>
                }
            </React.Fragment>
        )
    }
}

const styles = () => ({});
GraphAutoReloadApp.propTypes = {};
const mapStateToProps = (state) => {
    const hasDirty = hasAnyDirtyNodes(state);

    const storedDbError = state.graph.storedDbError + "";
    return {
        hasDirty: hasDirty,
        storedDbError: state.graph.storedDbError,
        storedDbErrorNodeIds: state.graph.storedDbErrorNodeIds,
        isStorageFull: storedDbError?.includes("QuotaExceededError")
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        retryStoreDbNodes: nodeIds => dispatch(retryStoreDbNodes(nodeIds)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphAutoReloadApp));
