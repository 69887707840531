import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import {getDirty, getNodeOrNull} from "../../selectors/graphSelectors";
import MessageType from "../components/MessageType";
import {strings} from "../components/SopLocalizedStrings";
import {clearSaveError} from "../../actions";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/RefreshRounded";
import {NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {TbfError} from 'tbf-react-library';

class GraphNodeSaveErrorMessage extends Component {

    constructor(props, context) {
        super(props, context);
        this.retrySave = this.retrySave.bind(this);
    }

    retrySave() {
        let {clearSaveError, nodeId} = this.props;
        clearSaveError({id: nodeId});
    }

    render() {
        let {saveError, saveAborted, saveErrorMsg, savedException} = this.props;
        if (!saveError) {
            return null;
        }
        return (
            <>
                {
                    saveAborted &&
                    <MessageType messageType={'error'}>{strings.execution.errors.saveAborted}</MessageType>
                }
                <TbfError
                    message={`${saveErrorMsg} ${saveError}`}
                    exception={savedException}
                    action={<Button
                                sx={{ml:1}}
                                variant={'outlined'}
                                onClick={this.retrySave}
                                data-cy={'retry-save'}> <RefreshIcon/> Retry
                            </Button>}/>
            </>
        )
    }
}

const styles = () => ({});
GraphNodeSaveErrorMessage.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let dirty = getDirty(state, ownProps.nodeId);
    let node = getNodeOrNull(state, ownProps.nodeId);
    let template = node?.type === NODE_TYPE_OPTIONS.ProcedureRoot;
    let saveErrorMsg = template ? strings.procedure.errors.saveError : strings.execution.errors.saveError;
    return {
        saveError: (dirty && dirty.lastSaveError) || null,
        saveAborted: (dirty && dirty.saveAborted) || false,
        savedException:  (dirty && dirty.lastSavedException) || null,
        saveErrorMsg: saveErrorMsg
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        clearSaveError: (node) => dispatch(clearSaveError(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphNodeSaveErrorMessage));
