import React from "react";
import withStyles from '@mui/styles/withStyles';
import TbfAvatar from "./common/TbfAvatar";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { URL_IDENTITY_BASE } from "tbf-react-library";

const ExecutionSelectUsersOption =
    React.forwardRef(({
                          innerProps,
                          classes,
                          data,
                          isFocused,
                          isSelected,
                          disabled,
                          onLinkClick,
                          link,
                          teams
                      }, ref) => {

        const Wrapper = link ? ({children}) =>
            (!teams ? <Link to={`/executions/${data.value}`} onClick={onLinkClick}>
                {children}
            </Link> : <a href={`${URL_IDENTITY_BASE}/groups/${data.value}`} target="_blank" rel="noreferrer" onClick={onLinkClick}>{children}</a>)
        : React.Fragment;

        return (
            <div ref={ref} {...innerProps}
                 role={'listitem'}
                 data-username={data.label}
                 className={
                     classes.customCardOptionContainer +
                     ' select__option' +
                     (isFocused ? ' select__option--is-focused' : '') +
                     (isSelected ? ' select__option--is-selected' : '') +
                     (disabled ? ' select__option--is-disabled' : '')
                 }>
                <label className={classes.customOptionTitle}>
                    <TbfAvatar name={data.label}/>
                    <Wrapper>
                        <span className={'userName option_name'} title={data.label}>{data.label}</span>
                    </Wrapper>
                </label>
            </div>
        )
    });

const styles = theme => ({
    customOptionContainer: {
        width: '100%',
        padding: '7px 15px',
        float: 'left',
        transition: 'background .3s'
    },
    customCardOptionContainer: {
        float: 'left',
        width: 'auto !important',
        padding: '7px 15px',
        transition: 'background .3s',
        boxShadow: '1px 1px 4px rgb(147 160 159)',
        margin: '8px -5px 4px 15px',
        borderRadius: 4,
    },
    customOptionTitle: {
        width: '100%',
        float: 'left',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '& .optionIcon': {
            marginRight: 8,
        },
        '& .userName': {
            
            color: theme.palette.primary.three.main,
        },
    },
});

export default withStyles(styles)(ExecutionSelectUsersOption);
