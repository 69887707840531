import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../style/alert.css';
import "./../../style/form.css";
import {getNodeOrNull, getNodesById, getNodesByIdAsLookup, getNodeSchemaOrNull} from "../../selectors/graphSelectors";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import {putNodeProperty, putNodesProperty, reportNodeEvent} from "../../actions";
import keyBy from "lodash/keyBy";
import {ASSIGNMENT_ENTITY_TYPES, ASSIGNMENT_TYPES, NODE_IDS} from "../../reducers/graphReducer";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Select from "@mui/material/Select";
import {Permissions} from "../../permissions";
import {createChildNode} from "../../factory/graphFactory";
import {ComponentBase, SharedAuth} from "tbf-react-library";
import {getGroups} from "../../selectors/groupSelectors";
import {ExecutionShowNodeInfoDialog} from "./ExecutionShowNodeInfoDialog";
import { REPORT_EVENT_TYPES } from '../../util/constants';

const initialState = {
    selectedEntityId: null,
    selectOpen: false
};

class ExecutionAssignmentChip extends ComponentBase {

    constructor(props) {
        super(props);
        this.state = {...initialState};
        this.handleSelectOpen = this.handleSelectOpen.bind(this);
        this.handleSelectClose = this.handleSelectClose.bind(this);
    }

    handleSelectOpen = (event) => {
        event.stopPropagation();
        this.setState({selectOpen: true})
    };

    handleSelectClose = (event) => {
        event.stopPropagation();
        this.setState({selectOpen: false, selectedEntityId: null})
    };

    handleDelete = (entityId) => () => {
        let {assignments} = this.props;
        let selectedAssignments = assignments.filter(a => a.entityId === entityId);
        selectedAssignments.forEach(a => this.props.onPutNodeProperty({id: a.id, deleted: true}));
    };

    handleChipClicked = (entityId) => (event) => {
        event.stopPropagation();
        this.setState({selectedEntityId: entityId, selectOpen: true});
    };

    handleEntitySelected = (existingEntityId) => (event) => {
        let {assignments, nodes, entitiesById, executionsById, onPutNodesProperty, assignmentSchema, nodeId, onPutNodeProperty, reportEvent} = this.props;
        let selectedEntityId = event.target.value === 'unassigned' ? null : event.target.value;
        let group = selectedEntityId ? entitiesById[selectedEntityId] : null;
        let nodesToCreate;
        let assignmentsToDelete;
        if (existingEntityId == null) {
            let alreadyAssignedMap = keyBy(assignments, a => a.assignedNodeId);
            nodesToCreate = selectedEntityId ? nodes.filter(a => !alreadyAssignedMap[a.id]) : [];
            assignmentsToDelete = [];
        } else {
            assignmentsToDelete = assignments.filter(a => a.entityId === existingEntityId);
            let alreadyAssignedMap = keyBy(assignmentsToDelete, a => a.assignedNodeId);
            nodesToCreate = selectedEntityId ? nodes.filter(a => alreadyAssignedMap[a.id]) : [];
        }
        assignmentsToDelete.forEach(a => {
            onPutNodeProperty({id: a.id, deleted: true});
        });
        reportEvent(nodeId, REPORT_EVENT_TYPES.executionPagePropertiesAssignmentAdded.name, {selectedId: selectedEntityId});
        for (let node of nodesToCreate) {
            let execution = executionsById[node.rootId];
            let assignAttr = {
                assignmentType: ASSIGNMENT_TYPES.task.id,
                assignedNodeId: node.id,
                entityId: selectedEntityId,
                entityName: group.name,
                entityType: ASSIGNMENT_ENTITY_TYPES.group.id
            };
            let assignmentNode = createChildNode(execution, assignmentSchema, assignAttr);
            onPutNodesProperty([assignmentNode, {
                id: execution.id,
                assignments: [...(execution.assignments || []), assignmentNode.id]
            }]);
        }
        this.setState(initialState);
    };

    renderEntityAutoComplete = (selectedValue) => {
        let {groups} = this.props;
        let {selectOpen} = this.state;
        return (
            <Select
                fullWidth
                value={selectedValue || 'unassigned'}
                inputProps={{autoComplete: "off"}}
                open={selectOpen}
                margin={'none'}
                onChange={this.handleEntitySelected(selectedValue)}
                onOpen={this.handleSelectOpen}
                onClose={this.handleSelectClose}
            >
                <MenuItem value={'unassigned'}>Unassigned</MenuItem>
                {Object.values(groups).map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        );
    };

    render() {
        let {selectedEntityId} = this.state;
        let {
            classes,
            nodes,
            assignedCount,
            entitiesById,
            assignedEntityCounts,
            includeCount,
            displayUnassigned,
            displayDelete,
            canAssign
        } = this.props;
        let unassignedCount = nodes.length - assignedCount;
        let unassignedSelected = selectedEntityId === 'unassigned';
        return (
            <React.Fragment>
                {
                    unassignedCount > 0 && displayUnassigned !== false &&
                    <span>
                            <Chip
                                avatar={(includeCount && <Avatar>{unassignedCount}</Avatar>) || null}
                                label={unassignedSelected ? this.renderEntityAutoComplete(null) : 'Unassigned'}
                                className={classes.chip}
                                clickable={canAssign}
                                onClick={canAssign ? this.handleChipClicked('unassigned') : null}
                            />
                        </span>
                }
                {
                    Object.entries(assignedEntityCounts).map(([entityId, count], index) => (
                        <React.Fragment key={index}>
                            <Chip
                                avatar={(includeCount && <Avatar>{count}</Avatar>) || null}
                                label={selectedEntityId === entityId ? this.renderEntityAutoComplete(entityId) : `${entitiesById[entityId] ? entitiesById[entityId].name : entityId}`}
                                className={`${classes.chip} ${entitiesById[entityId].deleted ? classes.deleted : ""}`}
                                onDelete={displayDelete ? this.handleDelete(entityId) : null}
                                clickable={canAssign}
                                onClick={canAssign ? this.handleChipClicked(entityId) : null}
                            />
                            <ExecutionShowNodeInfoDialog nodeId={entitiesById[entityId].nodeId}
                                                         title={entitiesById[entityId].name}
                                                         buttonStyle={classes.diagnosticButton}/>
                        </React.Fragment>
                    ))
                }
            </React.Fragment>);
    };
}

const styles = (theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    icon: {
        fill: theme.palette.add.main
    },
    gridSelected: {
        backgroundColor: theme.palette.primary.main
    },
    chip: {
        paddingRight: theme.spacing(1)
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main
    },
    gridOdd: {
        backgroundColor: theme.palette.odd.main
    },
    link: {
        display: 'flex',
        alignItems: 'center'
    },
    deleted: {
        backgroundColor: theme.palette.deleted.one.main
    },
    diagnosticButton: {
        '& svg.MuiSvgIcon-root': {
            fontSize: '1.2rem'
        },
    }
});
ExecutionAssignmentChip.propTypes = {
    nodes: PropTypes.array.isRequired,
    includeCount: PropTypes.bool.isRequired,
    displayUnassigned: PropTypes.bool,
    displayDelete: PropTypes.bool,
    displayUsers: PropTypes.bool
};
const mapStateToProps = (state, ownProps) => {
    const nodes = ownProps.nodes || getNodesById(state, ownProps.nodeIds);
    const userDevice = getNodeOrNull(state, NODE_IDS.UserDevice);
    const troubleShootOn = userDevice?.troubleshootOn;
    // Existing assignments
    let assignedEntityCounts = {};
    let assignedCount = 0;
    let assignments = [];
    let assignmentEntitysById = {};
    for (let node of nodes) {
        for (let assignmentId of node.assignments || []) {
            let assignment = getNodeOrNull(state, assignmentId);
            if (!assignment || assignment.assignedNodeId !== node.id) {
                continue;
            }
            if (!ownProps.displayUsers && assignment.entityType === ASSIGNMENT_ENTITY_TYPES.user.id) {
                continue;
            }

            if(!troubleShootOn && assignment.deleted ) {
                continue;
            }

            if(ownProps.hideDeletedGroups && assignment.deleted ) {
                continue;
            }

            assignments.push(assignment);
            if (!assignedEntityCounts[assignment.entityId]) {
                assignedEntityCounts[assignment.entityId] = 0;
            }
            assignedEntityCounts[assignment.entityId]++;
            assignedCount++;
            // Assigned user does not load groups, so derive them
            assignmentEntitysById[assignment.entityId] = {
                id: assignment.entityId,
                name: assignment.entityName,
                entityTye: assignment.entityType,
                deleted: assignment.deleted,
                nodeId: assignment.id
            };
        }
    }
    let groups = getGroups(state)
    let entitiesById = assignmentEntitysById;
    if (groups.length === 0) {
        // Groups not yet loaded
        groups = Object.values(assignmentEntitysById).filter(a => a.entityType === ASSIGNMENT_ENTITY_TYPES.group.id);
    } else {
        // Use latest name from groups/users
        entitiesById = {
            ...entitiesById,
            ...keyBy(groups.map(g => ({
                id: g.id,
                nodeId: entitiesById[g.id]?.nodeId,
                name: g.name,
                entityTye: ASSIGNMENT_ENTITY_TYPES.group.id,
                deleted: entitiesById[g.id]?.deleted ?? false
            })), a => a.id)
        };
    }
    // Get latest name for used users when available
    let userEntries = Object.values(assignmentEntitysById).filter(a => a.entityType === ASSIGNMENT_ENTITY_TYPES.user.id);
    for (let userEntity of userEntries) {
        let user = getNodeOrNull(state, userEntity.id);
        if (user && user.title) {
            userEntity.name = user.title;
        }
    }
    return {
        assignments: assignments,
        groups: groups,
        entitiesById: entitiesById,
        assignedEntityCounts: assignedEntityCounts,
        executionsById: getNodesByIdAsLookup(state, nodes.map(a => a.rootId)),
        assignmentSchema: getNodeSchemaOrNull(state, 'ExecutionAssignment'),
        assignedCount: assignedCount,
        canAssign: SharedAuth.userHasPermission(Permissions.execution.assign),
        nodes: nodes
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        onPutNodesProperty: nodes => dispatch(putNodesProperty(nodes)),
        reportEvent: (id, eventName, eventProperties) => dispatch(reportNodeEvent(id, eventName, eventProperties)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionAssignmentChip));
