import get from 'lodash/get';

/**
 * As re-used in reducer needs to support state.graph.nodes and state.nodes
 * @param state
 * @returns {*}
 */
export const getLoadedHelp = (state) => state.loadHelpPointReducer ? state.loadHelpPointReducer : state;
export const getLoadedHelpPoints = (state) => getLoadedHelp(state).loadedHelpPoints;
export const getLoadedHelpPointOrNull = (state, nodeId) => get(getLoadedHelpPoints(state), [nodeId]) || null;

export const getLoadedHelpManuals = (state) => getLoadedHelp(state).loadedHelpPointManuals;
export const getLoadedHelpManualOrNull = (state, nodeId) => get(getLoadedHelpManuals(state), [nodeId]) || null;
