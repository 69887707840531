import {getNodeOrNull} from "../../selectors/graphSelectors";
import {NODE_IDS} from "../graphReducer";
import {domainRuleNOP} from "./common";
import {mergeUnique} from "../../util/util";
import {setClientConfiguration} from "tbf-react-library";

export const CLIENT_CONFIG_DOMAIN_RULES = {
    ...domainRuleNOP,
    onPut: (state, node, beforeState) => {
        const user = getNodeOrNull(state, NODE_IDS.User);
        let procedures = node.procedures
        const hasAccess = (procedureAccessList, userAccessSet) => {
            if (!procedureAccessList) {
                return false;
            }
            return procedureAccessList.some(a => userAccessSet.has(a));
        }
        if (procedures && (user?.groups?.length || user?.permissions?.length)) {
            node.procedures = {...procedures};
            const userScopes = new Set();
            for (let group of user.groups || []) {
                userScopes.add(group)
            }
            for (let permission of user.permissions || []) {
                userScopes.add(permission)
            }
            for (let procedure of Object.values(procedures).filter(a => !!a)) {
                const updatedProcedure = {
                    ...procedure,
                    can: {}
                };
                if (hasAccess(procedure.createScopes, userScopes)) {
                    updatedProcedure.can.create = true;
                }
                if (hasAccess(procedure.listScopes, userScopes)) {
                    updatedProcedure.can.list = true;
                }
                if (hasAccess(procedure.deleteScopes, userScopes)) {
                    updatedProcedure.can.delete = true;
                }
                node.procedures[procedure.id] = updatedProcedure;
            }
        }
        node.scopes = mergeUnique(node.scopes, ['AlwaysLoad']);
        const beforeNode = getNodeOrNull(beforeState, node.id);
        if (!beforeNode || beforeNode.version !== node.version) {
            const menuConfig = {
                clientId: node.clientId,
                tenantId: node.tenantId,
                menu: node.menu
            };
            setClientConfiguration(menuConfig)
        }
        return {
            [node.id]: node
        }
    }
}