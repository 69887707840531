import React from "react";
import {strings} from "../../components/SopLocalizedStrings";
import {useNodeOrNull, useNodeSchemaOrNull, usePutNodesProperty} from "../../../hooks/nodeHooks";
import {
    ADD_ACTION_LABEL_FORMATS,
    ADD_ACTION_PLACEMENT_OPTIONS,
    NODE_TYPE_OPTIONS,
    RULE_ACTION_TYPE
} from "../../../reducers/graphReducer";
import {createChildNode} from "../../../factory/graphFactory";
import {AddAction} from "tbf-react-library";

const pageStrings = strings.procedure.settings.actions;

const ProcedureActionsAdd = ({procedureId, disabled, multiple}) => {
    const procedure = useNodeOrNull(procedureId);

    const schema = useNodeSchemaOrNull(NODE_TYPE_OPTIONS.ProcedureRule);

    const actionType = multiple ? RULE_ACTION_TYPE.manuallyAddExecutionBulk : RULE_ACTION_TYPE.manuallyAddExecution;

    const putNodesProperty = usePutNodesProperty();

    const handleAddNewAction = () => {
        const attr = {
            nodeIds: [procedureId],
            alwaysOn: true,
            actionType: actionType.id,
            format: ADD_ACTION_PLACEMENT_OPTIONS.top.id,
        };

        const ruleNode = createChildNode(procedure, schema, attr);

        const childRules = [
            {
                nodeIds: [ruleNode.id],
                actionType: RULE_ACTION_TYPE.label.id,
                alwaysOn: true,
                format: ADD_ACTION_LABEL_FORMATS.button.id,
            }
        ];
        if (!multiple) {
            childRules.push(
                {
                    nodeIds: [ruleNode.id],
                    actionType: RULE_ACTION_TYPE.label.id,
                    alwaysOn: true,
                    format: ADD_ACTION_LABEL_FORMATS.group.id,
                }
            )
        }
        const childRuleNodes = childRules.map((attr) => createChildNode(procedure, schema, attr));

        putNodesProperty([ruleNode, ...childRuleNodes]);
    }

    if (disabled) {
        return null;
    }

    return <AddAction
        label={multiple ? pageStrings.addMultipleLabel : pageStrings.addLabel}
        title={pageStrings.addTitle}
        canAdd={!disabled}
        onClick={handleAddNewAction}
        dataCy={multiple ? "add-action-bulk" : "add-action"}
    />;
}

export default ProcedureActionsAdd;