import {ComponentBase, DeviceOrientation, reportError, reportEvent, SharedAuth} from "tbf-react-library";
import {fileToDataURL, getImageDimensions, resizeDataURL} from "../../util/image";
import {createImageObjectUrl, getJsonDate} from "../../util/util";
import fileAttachmentImage from "../../assets/fileTypeLarge.png";
import Location from "../components/Location";
import {createNode} from "../../factory/graphFactory";
import {strings} from "../components/SopLocalizedStrings";
import {toast} from "react-toastify";

// 200mb
const MAX_SIZE = 200000000;

class ExecutionPhotoUploadBase extends ComponentBase {

    async handleFileAdded(file) {
        let {
            photoSchema,
            photoCaptureSchema,
            projectId,
            executionId,
            executionQuestionId,
            field,
            aspectRatio
        } = this.props;

        if(file.size > MAX_SIZE) {
            toast.error(strings.execution.errors.attachmentMaxSizeError);
            return;
        }

        try {
            const maxSize = 1920;
            const imageQuality = 0.90;
            const fileData = await fileToDataURL(file, maxSize, maxSize, imageQuality, aspectRatio);
            const isImage = fileData.startsWith('data:image/webp') || fileData.startsWith('data:image/jpeg');
            const imageSourceData = isImage ? fileData : fileAttachmentImage;
            const thumbnailWidth = 80;
            const thumbnailHeight = 80;
            const dimensions = await getImageDimensions(imageSourceData);
            const originalUrl = createImageObjectUrl(fileData);
            const thumbnailData = await resizeDataURL(imageSourceData, thumbnailWidth, thumbnailHeight, imageQuality, aspectRatio);
            const thumbnailUrl = createImageObjectUrl(thumbnailData);
            const attrPhoto = {
                projectId: projectId,
                executionId: executionId,
                executionQuestionId: executionQuestionId,
                propertyName: field + 'PhotoIds',
                originalData: SharedAuth.isAnonymous() ? fileData : null,
                originalWidth: dimensions.width,
                originalHeight: dimensions.height,
                originalInMemoryUrl: originalUrl,
                thumbnailWidth: thumbnailWidth,
                thumbnailHeight: thumbnailHeight,
                thumbnailInMemoryUrl: thumbnailUrl,
                photoTakenDate: getJsonDate(),
                coordinates: Location.getLocation(),
                orientation: DeviceOrientation.getOrientation(),
                filename: file.name
            };
            const photoNode = createNode(photoSchema, attrPhoto);
            const attrPhotoCapture = {
                photoId: photoNode.id,
                projectId: projectId,
                executionId: executionId,
                executionQuestionId: executionQuestionId,
                propertyName: field + 'PhotoIds',
                originalData: fileData,
                thumbnailData: thumbnailData,
                photoTakenDate: getJsonDate(),
                coordinates: Location.getLocation(),
                orientation: DeviceOrientation.getOrientation(),
                filename: file.name
            };
            const photoCaptureNode = createNode(photoCaptureSchema, attrPhotoCapture);
            photoNode.photoCaptureId = photoCaptureNode.id;
            reportEvent({
                name: 'PhotoCaptured',
                photoId: photoNode.id,
                executionId: executionId,
                projectId: projectId,
                executionQuestionId: executionQuestionId
            });
            this.props.onPutNodes([photoNode, photoCaptureNode]);
        } catch (error) {
            reportError('Error parsing image. ' + error.message, error);
        }
    };
}

export default ExecutionPhotoUploadBase;
