import {
    SET_FILE_DELETE_COMPLETED,
    SET_FILE_DELETE_SNACKBAR_TOGGLE_STATE,
    SET_FILE_DELETE_UNDO,
    SET_MENU_TOGGLE_STATE
} from '../actions/types';
import {TABLET_MAX_WIDTH} from 'tbf-react-library';

const initialState = {
    toggleState: window.innerWidth > TABLET_MAX_WIDTH,
    fileLength: 0,
    fileDeleteSnackBarToggleState: false,
    deleteUndo: false,
    deleteCompleted: false
}

const appReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case SET_MENU_TOGGLE_STATE: {
                return {
                    ...state,
                    toggleState: action.toggleState
                }
            }
            case SET_FILE_DELETE_SNACKBAR_TOGGLE_STATE: {
                return {
                    ...state,
                    fileDeleteSnackBarToggleState: action.toggleState,
                    fileLength: action.fileLength
                }
            }
            case SET_FILE_DELETE_UNDO: {
                return {
                    ...state,
                    deleteUndo: action.toggleState
                }
            }
            case SET_FILE_DELETE_COMPLETED: {
                return {
                    ...state,
                    deleteCompleted: action.toggleState
                }
            }
            default:
                return state;
        }
    } catch (error) {
        throw error;
    }
}

export default appReducer;