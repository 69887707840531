import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../style/alert.css';
import {withRouter} from "react-router-dom";
import * as PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import AddBookmarkIcon from "@mui/icons-material/AddRounded";
import EditBookmarkIcon from "@mui/icons-material/EditRounded";
import ShowBookmarkIcon from "@mui/icons-material/VisibilityRounded";
import BookmarkIcon from "@mui/icons-material/InfoRounded";
import {getHelpDocumentOrNull, getHelpPointOrNull} from "../selectors/helpManualSelectors";
import Loader from "../../layouts/components/Loader";
import {SharedAuth} from "tbf-react-library";
import {Permissions} from '../permissions';
import {strings} from "../../layouts/components/SopLocalizedStrings";
import {updateHelpPointNode} from "../actions/helpManualActions";
import {toManualsHelpPoint} from '../../util/navigate'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

class HelpPointEdit extends Component {
    handleOpenEditHelp = () => {
        // navigate to the help point in the help document in the manual web site
        const {nodeRootId, nodeId} = this.props
        toManualsHelpPoint(nodeRootId, nodeId)
    };

    render() {
        let {
            classes,
            helpPointLoaded,
            hasBookmarks,
            helpPointManualName,
            canCreateHelpPoint,
            canUpdateHelpPoint,
            canReadHelpPoint,
            helpDocumentLoading,
            helpDocumentSaving
        } = this.props;

        if (!canReadHelpPoint) {
            return <div/>
        }

        return (
            <React.Fragment>
                <div className={classes.wrapper} data-cy-element={'Help'}>
                    <label className={classes.bookmarkTitle}>
                        <BookmarkIcon className={classes.bookmarkIcon}/>
                        <label>
                            <span className={classes.marginRight5}>Help:</span>
                            {
                                helpDocumentSaving &&
                                <Loader friendlyName={'Saving...'} inline={true} source={'helpPoint'}/>
                            }
                            {
                                helpDocumentLoading &&
                                <Loader inline={true} source={'helpPoint'}/>
                            }
                            {
                                !helpDocumentLoading && !helpDocumentSaving &&
                                <span>{helpPointManualName || 'None'}</span>
                            }
                        </label>
                    </label>

                    {
                        canCreateHelpPoint && helpPointLoaded && !hasBookmarks &&
                        <IconButton
                            onClick={this.handleOpenEditHelp}
                            title={strings.manuals.addBookmark.title}
                            className={classes.bookmarkUpdateBtn}
                            data-cy={'show-bookmarkModal'}
                            size="large">
                            <AddBookmarkIcon/>
                        </IconButton>
                    }
                    {
                        canUpdateHelpPoint && helpPointLoaded && hasBookmarks &&
                        <IconButton
                            onClick={this.handleOpenEditHelp}
                            title={strings.manuals.editBookmark.title}
                            className={classes.bookmarkUpdateBtn}
                            data-cy={'show-bookmarkModal'}
                            size="large">
                            <EditBookmarkIcon className={'actionIcon'}/>
                        </IconButton>
                    }
                    {
                        !canUpdateHelpPoint && !canCreateHelpPoint && canReadHelpPoint && helpPointLoaded && hasBookmarks &&
                        <IconButton
                            onClick={this.handleOpenEditHelp}
                            title={strings.manuals.showBookmark.title}
                            className={classes.bookmarkUpdateBtn}
                            data-cy={'show-bookmarkModal'}
                            size="large">
                            <ShowBookmarkIcon/>
                        </IconButton>
                    }
                </div>
            </React.Fragment>
        );
    }
}

const styles = (theme) => ({
    wrapper: {
        position: 'relative',
        width: '100%',
        minHeight: 50
    },
    bookmarkTitle: {
        color: theme.palette.primary.main,
        width: '100%',
        display: 'inline-block',
        fontWeight: 400,
        verticalAlign: 'middle',
        height: '100%',
        position: 'relative',
        padding: '15px 65px 15px 27px',
        fontSize: 15,
        wordBreak: 'break-word',
        whiteSpace: 'break-spaces'
    },
    bookmarkIcon: {
        fontSize: 22,
        position: 'absolute',
        left: 2,
        top: 15
    },
    bookmarkUpdateBtn: {
        color: theme.palette.primary.main,
        position: 'absolute',
        right: 0,
        top: 0,
        height: 35,
        width: 35,
        margin: 'auto',
        bottom: 0,
        '& .actionIcon': {
            fontSize: 18
        }
    },
    bookmarkDeleteBtn: {
        color: theme.palette.primary.main,
        position: 'absolute',
        right: 30,
        top: 0,
        height: 35,
        width: 35,
        margin: 'auto',
        bottom: 0,
        '& .actionIcon': {
            fontSize: 18
        }
    },
    loadingIndicator: {
        color: theme.palette.primary.main,
        position: 'absolute',
        right: 8,
        top: 2,
        margin: 'auto',
        bottom: 0,
    },
    marginRight5: {
        marginRight: 5
    }
});
HelpPointEdit.propTypes = {
    helpDocumentExternalId: PropTypes.string.isRequired,
    helpPointExternalId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    externalProperty: PropTypes.string.isRequired
};
const mapStateToProps = (state, ownProps) => {
    const helpdocument = getHelpDocumentOrNull(state, ownProps.helpDocumentExternalId);
    const helpPoint = getHelpPointOrNull(state, ownProps.helpPointExternalId);
    const bookmarkIds = get(helpPoint, ['manualBookmarkIds'])
    return {
        nodeId: ownProps.helpPointExternalId,
        nodeRootId: ownProps.helpDocumentExternalId,
        helpPointLoaded: !!helpPoint,
        bookmarkIds,
        hasBookmarks: !isEmpty(bookmarkIds),
        helpPointDirty: helpPoint ? helpPoint.dirty : null,
        helpPointManualName: helpPoint ? helpPoint.manualName : null,
        canCreateHelpPoint: SharedAuth.userHasPermission(Permissions.manuals.create),
        canUpdateHelpPoint: SharedAuth.userHasPermission(Permissions.manuals.update),
        canReadHelpPoint: SharedAuth.userHasPermission(Permissions.manuals.read),
        canDeleteHelpPoint: SharedAuth.userHasPermission(Permissions.manuals.delete),
        helpDocumentLoading: helpdocument?.loading,
        helpDocumentSaving: helpdocument?.creating || helpdocument?.updating,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateHelpPointNode: (helpPoint) => dispatch(updateHelpPointNode(helpPoint))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(HelpPointEdit)));
