import React, {useEffect, useMemo, useRef} from "react";
import withStyles from '@mui/styles/withStyles';
import {useNodeOrNull} from "../../hooks/nodeHooks";
import {useMobile} from "tbf-react-library";
import {NODE_IDS} from "../../reducers/graphReducer";
import CollectionExpandMapButton from "./CollectionExpandMapButton";
import MapView from "../MapView";
import CollectionMapSearchThisArea from "./CollectionMapSearchThisAreaButton";
import WorkItemCard from "./WorkItemCard";
import {getNodeFeature} from "../assignments/selectors";

const mapPopup = ({feature}) => {
    const id = feature?.properties?.id
    if (!id) {
        return null;
    }
    return <WorkItemCard
        id={id}
        isMapPopup={true}
    />
}

/**
 * When clicked this will open the add worq item dialog.
 *
 * @param procedureId
 * @returns {JSX.Element}
 * @constructor
 */
const CollectionMap = ({selectorId, classes, workItems}) => {
    const mobileViewPort = useMobile()
    const selector = useNodeOrNull(selectorId)
    const mapId = NODE_IDS.ExecutionsMapView(selectorId)
    const focusedItemId = selector?.focusedItemId
    const mapRef = useRef()
    const features = useMemo(() => {
        return workItems.filter(a => a.feature).map(getNodeFeature)
    }, [workItems])
    // When viewport changes (expand/collapse map) we need to let the map know
    useEffect(() => {
        if (mapRef && mapRef.current && mapRef.current.map) {
            mapRef.current.map.invalidateSize()
        }
    }, [mapRef, selector])

    return (<>
        <div
            className={`${classes.mapContainer} ${((!mobileViewPort && classes.stickyContainer) || '')}`}
        >
            <CollectionMapSearchThisArea selectorId={selectorId} mapRef={mapRef}/>
            <CollectionExpandMapButton selectorId={selectorId}/>
            <MapView
                className={classes.map}
                mapViewId={mapId}
                ref={mapRef}
                features={features}
                activeId={focusedItemId}
                popupComponent={mapPopup}
            />
        </div>
    </>);
}
const styles = (theme) => ({
    mapContainer: {
        position: 'relative',
        flexGrow: 1,
        flexShrink: 1,
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        }
    },
    stickyContainer: {
        position: 'sticky !important',
        top: 0,
    },
    map: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
});
export default withStyles(styles)(CollectionMap);