import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    container: {
        justifyContent: 'normal',
        width: '100%',
        display: "flex"
    },
}));

const GraphMobileListItem = ({ children }) => {

    const classes = useStyles();

    return <span className={classes.container}>{children}</span>
}

export default GraphMobileListItem;