import {APPS, deriveApiUrl, SharedAuth} from "tbf-react-library";
import {executePromiseWithoutAuthorization, executeSilentPromise} from "./commonAction";

export const getApiBaseUrl = () => {
    return deriveApiUrl(APPS.INTEGRATION, SharedAuth.getClientId(), SharedAuth.getTenantId());
}

export const getIntegrationAuth = () => {
    let url = `${getApiBaseUrl()}/authenticated`;
    return executeSilentPromise('get', url, null, true);
};

export const pingIntegrationAuth = () => {
    let url = `${getApiBaseUrl()}/auth/ping`;
    return executePromiseWithoutAuthorization('get', url);
};
