import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodesById, getNodeSchemaOrNull, getSchema} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {putNodeProperty, putNodesProperty} from "../../actions";
import ReactDataGrid from 'react-data-grid';
import {Editors} from "react-data-grid-addons";
import '../../style/react-data-grid.css';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ProcedureProperties from "./ProcedureProperties";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import UnDeleteIcon from '@mui/icons-material/RestoreFromTrashRounded';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotosRounded';
import AddBoxIcon from '@mui/icons-material/AddBoxRounded';
import DownArrowIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import UpArrowIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import HistoryIcon from '@mui/icons-material/HistoryRounded';
import UpdateIcon from '@mui/icons-material/UpdateRounded';
import {
    CONDITIONAL_VALUES_OPTIONS,
    NODE_TYPE_OPTIONS,
    PROCEDURE_TYPES,
    QUESTION_TYPE_OPTIONS,
    VISIBLE_MODE_OPTIONS
} from "../../reducers/graphReducer";
import {arrayMove, arrayRemove} from "../../util/util";
import WarningIcon from '@mui/icons-material/WarningRounded';
import {getNodeIndex} from "../../util/graphUtils";
import {strings} from "../components/SopLocalizedStrings";
import FlareIcon from '@mui/icons-material/FlareRounded';
import {ComponentBase, cypress, reportInfo} from "tbf-react-library";
import {copyProcedureNodes, pasteProcedureNodes} from "../../factory/executionFactory";
import CopyAllRoundedIcon from '@mui/icons-material/FileCopyRounded';
import isEqual from "react-fast-compare";
import ProcedureCompileMessages from "./ProcedureCompileMessages";
import { keyBy } from 'lodash';
import {createProcedureNode} from "../../factory/procedureFactory";

export const TEMPLATE_CLONED_NODES_CLIPBOARD_TYPE = 'worq/template_cloned_nodes';
/************************************ DATA GRID ****************************************/
const {
    Draggable: {
        Container: DraggableContainer,
        RowActionsCell,
        DropTargetRowContainer
    }
} = require("react-data-grid-addons");

/************************************ EDITORS ****************************************/
const lookupToOptions = (lookup) => Object.keys(lookup).map(function (id) {
    return {
        id: id,
        value: lookup[id],
    }
});

const {DropDownEditor} = Editors;

const QuestionTypeEditor = <DropDownEditor options={lookupToOptions(QUESTION_TYPE_OPTIONS)}/>;

const ConditionTypeEditor = <DropDownEditor options={lookupToOptions(CONDITIONAL_VALUES_OPTIONS)}/>;

/************************************ FORMATTERS ****************************************/
const NameFormatter = ({value, row}) => {
        if (!row) {
            return value;
        }
        let inner = row.deleted ? <span style={{textDecoration: 'line-through', color: 'red'}}>{value}</span> : value;

        if (row.compileWarnings && row.compileWarnings.length > 0) {
            inner = <span className="alert alert-warning alert-icon"
                          style={{
                              background: '#faebea',
                              marginBottom: 0,
                              minWidth: 'auto'
                          }}><WarningIcon/>{inner}</span>
        } else if (row.compileSuggestions && row.compileSuggestions.length > 0) {
            inner = <span><FlareIcon/>{inner}</span>
        }
        if (row.enableCloning) {
            inner = <span style={{display: 'inline-flex', alignItems: 'center'}}
                          title={strings.procedure.show.cloneNodeIndicator}>
                {inner}
                <CopyAllRoundedIcon style={{
                    fontSize: 16,
                    color: '#ff9800',
                    marginLeft: 6,
                }}/>
            </span>
        }

        switch (row.type) {
            case 'ProcedureRoot':
                return <b data-cy-type={row.type} data-cy-name={value}>{inner}</b>;
            case 'ProcedureStep':
                return <b data-cy-type={row.type} data-cy-name={value}>{inner}</b>;
            case 'ProcedureTask':
                return <b data-cy-type={row.type} data-cy-name={value}>&nbsp;&nbsp;&nbsp;&nbsp;{inner}</b>;
            case 'ProcedureQuestion':
                return <span data-cy-type={row.type}
                             data-cy-name={value}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{inner}</span>;
            default:
                return <span>{inner}</span>;
        }
    }
;

/************************************ CONVERSION ****************************************/
const nodeToRow = (node) => {
    switch ((node || {}).type) {
        case 'ProcedureRoot':
            return {
                id: node.id,
                type: node.type,
                name: node.name,
                questionType: PROCEDURE_TYPES[node.procedureType].name,
                deleted: node.deleted,
                compileWarnings: node.compileWarnings,
                compileSuggestions: node.compileSuggestions,
                index: node.number,
            };
        case 'ProcedureStep':
            return {
                id: node.id,
                type: node.type,
                name: node.name,
                questionType: 'Step',
                visible: VISIBLE_MODE_OPTIONS[node.visible],
                deleted: node.deleted || node.parentDeleted,
                compileWarnings: node.compileWarnings,
                compileSuggestions: node.compileSuggestions,
                index: node.number,
            };
        case 'ProcedureTask':
            return {
                id: node.id,
                type: node.type,
                name: node.name,
                questionType: 'Task',
                visible: VISIBLE_MODE_OPTIONS[node.visible],
                deleted: node.deleted || node.parentDeleted,
                compileWarnings: node.compileWarnings,
                compileSuggestions: node.compileSuggestions,
                index: node.number,
            };
        case 'ProcedureQuestion':
            return {
                id: node.id,
                type: node.type,
                name: node.name,
                questionType: QUESTION_TYPE_OPTIONS[node.questionType],
                comment: CONDITIONAL_VALUES_OPTIONS[node.comment],
                photo: CONDITIONAL_VALUES_OPTIONS[node.photo],
                visible: VISIBLE_MODE_OPTIONS[node.visible],
                deleted: node.deleted || node.parentDeleted,
                compileWarnings: node.compileWarnings,
                compileSuggestions: node.compileSuggestions,
                index: node.number,
            };
        default:
            return node;
    }
};
/************************************ CONTEXT MENU ****************************************/
const getChildSchema = (schemas, parentType) => {
    let parentSchema = schemas[parentType];
    let childType = parentSchema.properties.children ? parentSchema.properties.children.kind[0].allowed.type : null;
    return childType ? schemas[childType] : null;
};

const pageStrings = strings.procedure.show;
let cypressData = {};

class ProcedureShowDataGrid extends ComponentBase {

    constructor(props) {
        super(props);
        const {procedureNode, editOn} = this.props;
        let defaultRowIndex = procedureNode.selectedRowIndex;
        this.state = {
            selectedRowIdx: defaultRowIndex === undefined ? -1 : defaultRowIndex,
            selectedNodeId: null,
            autoSelectNodeId: null,
            deletedNodesVisibility: false,
            copiedNode: null,
            rows: [],
            RowRenderer: editOn ? DropTargetRowContainer(ReactDataGrid.Row) : ReactDataGrid.Row,
            WrapperContainer: editOn ? DraggableContainer : React.Fragment,
            RowActionsCellElem: editOn ? RowActionsCell : undefined,
        };
    }

    grid = null;

    componentDidMount() {
        let {selectedRowIdx} = this.state;
        // Seed the table with 1 row
        if (this.props.nodes.length === 0) {
            this.addChild(this.props.procedureNode, 0);
        }
        let nodes = this.getVisibleNodes();
        if (selectedRowIdx >= 0 && selectedRowIdx < nodes.length) {
            this.setState({selectedNodeId: nodes[selectedRowIdx]});
            this.grid.selectCell({idx: 1, rowIdx: selectedRowIdx});
        }

        window.addEventListener('click', this.disableCopyPastEvents);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.disableCopyPastEvents);
        this.disableCopyPastEvents();
    }

    static getDerivedStateFromProps(props, state) {
        let {nodes, editOn} = props;
        let rows = nodes.map((a) => nodeToRow(a));
        let {copiedNode} = state;
        const cloningNodeId = (copiedNode && copiedNode.node && copiedNode.node.id) || null;

        function merge(existingItems, latestItems) {
            // Data Table modifies the items, so we need to stick with what we give it or we get a
            // de-select bug 843
            let lookup = {};
            existingItems.forEach(a => lookup[a.id || a.field] = a);
            let useList = [];
            for (let item of latestItems) {
                item.enableCloning = (editOn && cloningNodeId && item.id === cloningNodeId) || false;
                let existingItem = lookup[item.id || item.field];
                let areSame = existingItem && isEqual(item, existingItem);
                if (areSame) {
                    useList.push(existingItem);
                } else {
                    useList.push(item);
                }
            }
            return useList;
        }

        let updatedState = {
            rows: merge(state.rows, rows),
            editOn: props.editOn,
        }

        if (props.editOn !== state.editOn) {
            updatedState = {
                ...updatedState,
                RowRenderer: editOn ? DropTargetRowContainer(ReactDataGrid.Row) : ReactDataGrid.Row,
                WrapperContainer: editOn ? DraggableContainer : React.Fragment,
                RowActionsCellElem: editOn ? RowActionsCell : undefined,
            }
        }

        return updatedState;
    }

    getColumns = () => {
        let {editOn} = this.props;
        let columns = editOn ? [] : [{
            key: "indexed",
            name: "",
            editable: false,
            width: 60,
            formatter: ({row}) => {
                return <div style={{textAlign: 'center'}}>{(row?.index || '-')}</div>;
            }
        }];

        return columns.concat([
            {
                key: "name",
                name: "Title",
                editable: false,
                width: 550,
                resizable: true,
                formatter: NameFormatter,
            },
            {
                key: "questionType",
                name: "Type",
                editable: false,
                width: 90,
                resizable: true,
                editor: QuestionTypeEditor
            },
            {
                key: "comment",
                name: "Comments",
                editable: false,
                width: 90,
                resizable: true,
                editor: ConditionTypeEditor
            },
            {
                key: "photo",
                name: strings.photo.namePlural,
                editable: false,
                width: 90,
                resizable: true,
                editor: ConditionTypeEditor
            },
            {
                key: "visible",
                name: "Visible",
                editable: false,
                width: 90,
                resizable: true,
                editor: ConditionTypeEditor
            }
        ]);
    }

    enableCopyPastEvents = (event) => {
        event.preventDefault();
        event.stopPropagation();
        window.addEventListener("copy", this.clipboardCopyNodeEvent);
        window.addEventListener("paste", this.clipboardPasteNodeEvent);
    }

    disableCopyPastEvents = () => {
        window.removeEventListener("copy", this.clipboardCopyNodeEvent);
        window.removeEventListener("paste", this.clipboardPasteNodeEvent);
        this.setState({copiedNode: null});
    }

    clipboardCopyNodeEvent = (event) => {
        const {isCypressTest} = this.props;
        const selectedNode = this.getSelectedNodeData();
        if (selectedNode.node.type !== NODE_TYPE_OPTIONS.ProcedureRoot) {
            const {nodes, ruleNodes} = this.props;
            this.setState({copiedNode: selectedNode});
            const nodeData = copyProcedureNodes([...nodes, ...ruleNodes], selectedNode.node.id);
            if (!isCypressTest) {
                const clipboardData = event.clipboardData || window.clipboardData || event.originalEvent.clipboardData;
                clipboardData.setData(TEMPLATE_CLONED_NODES_CLIPBOARD_TYPE, JSON.stringify(nodeData));
            } else {
                cypressData = {nodeData};
            }
            event.preventDefault();
        } else {
            this.setState({copiedNode: null});
        }
    }

    clipboardPasteNodeEvent = (event) => {
        const {isCypressTest, editOn} = this.props;
        if (!editOn) return false;
        if (!isCypressTest) {
            const items = event.clipboardData.items;
            for (let item of items) {
                if (item.type.indexOf(TEMPLATE_CLONED_NODES_CLIPBOARD_TYPE) !== -1) {
                    item.getAsString((d) => {
                        const nodeData = (d && JSON.parse(d)) || {};
                        const copiedNode = nodeData?.copiedNode;
                        const allNodes = nodeData?.allNodes;
                        const rootTitle = nodeData?.rootTitle;
                        if (copiedNode) {
                            if (window.confirm(strings.formatString(pageStrings.cloneNodeConfirmationMsg, copiedNode.type.replace('Procedure', ''), copiedNode.name, rootTitle))) {
                                this.pasteNode(copiedNode, allNodes);
                            }
                        }
                    })
                }
            }
        } else {
            const nodeData = cypressData?.nodeData;
            const copiedNode = nodeData?.copiedNode;
            const allNodes = nodeData?.allNodes;
            const rootTitle = nodeData?.rootTitle;
            if (copiedNode) {
                if (window.confirm(strings.formatString(pageStrings.cloneNodeConfirmationMsg, copiedNode.type.replace('Procedure', ''), copiedNode.name, rootTitle))) {
                    this.pasteNode(copiedNode, allNodes);
                }
            }
        }
    }

    pasteNode = (copiedNode, allNodes) => {
        const {putNodesProperty, schemas, procedureNode, ruleSchema, ruleNodes, nodes} = this.props;
        const selectedNode = this.getSelectedNodeData();
        if (selectedNode && copiedNode) {
            const destinationNodeData = {
                rootId: selectedNode?.node?.rootId,
                node: selectedNode?.node,
                parentNode: selectedNode?.parentNode,
                nodeIndex: selectedNode?.nodeIndex,
                procedureNode: procedureNode,
                ruleSchema: ruleSchema,
                ruleNodes: ruleNodes,
                nodes: nodes,
            }
            const newAllNodes = {...keyBy(allNodes, "id"),...keyBy(nodes, "id"), ...keyBy(ruleNodes, "id")};
            const patchNodes = pasteProcedureNodes(Object.values(newAllNodes), copiedNode, schemas, destinationNodeData);
            putNodesProperty(patchNodes);
            const clonedNode = patchNodes[0];
            if (clonedNode) this.selectNodeOnUpdate(clonedNode.id);
        }
    }

    componentDidUpdate(prevProps) {
        // When inserting and moving things around we need to wait for a re-render of props to update the
        // selected row index
        let nodes = this.getVisibleNodes();
        let {selectedNodeId, selectedRowIdx, autoSelectNodeId} = this.state;
        let nodeIndex = getNodeIndex(nodes, selectedNodeId);
        if (autoSelectNodeId != null) {
            nodeIndex = getNodeIndex(nodes, autoSelectNodeId);
            if (nodeIndex >= 0) {
                this.setState({autoSelectNodeId: null, selectedNodeId: autoSelectNodeId});
            }
        }
        if (nodeIndex === -1) {
            // Selected node is now hidden, lets select node on same index or the last node if not enough
            let newSelectedRowIdx = Math.max(0, Math.min(selectedRowIdx, nodes.length - 1));
            let newSelectedNodeId = nodes[newSelectedRowIdx].id;
            this.setState({selectedNodeId: newSelectedNodeId, rowIdx: newSelectedRowIdx});
        } else if (nodeIndex !== selectedRowIdx) {
            this.setState({selectedRowIdx: nodeIndex});
            this.grid.selectCell({idx: 1, rowIdx: nodeIndex});
        }
        if (prevProps.editOn !== this.props.editOn) {
            this.grid.selectCell({idx: 1, rowIdx: selectedRowIdx});
        }
    }

    addChild = (parentNode, childIndex) => {
        const {schemas, putNodesProperty, procedureNode} = this.props;
        let parentSchema = schemas[parentNode.type];
        let childSchema = getChildSchema(schemas, parentNode.type);
        if (!childSchema) {
            reportInfo("Cannot add a child element to a " + parentSchema.displayName);
            return;
        }

        const childNode = createProcedureNode(childSchema, {parentId: parentNode.id, rootId: parentNode.rootId}, procedureNode.procedureType);
        let updatedChildren = [...parentNode.children];
        updatedChildren.splice(childIndex, 0, childNode.id);
        putNodesProperty([childNode, {id: parentNode.id, children: updatedChildren}]);
        this.selectNodeOnUpdate(childNode.id);
    };

    selectNodeOnUpdate = (nodeId) => {
        // Need to wait for a re-render for new nodes to appear in nodes list
        this.setState({autoSelectNodeId: nodeId});
    };

    moveChild = (node, parentNode, fromIndex, moveBy, rowIdx, toRowIdx = (rowIdx + moveBy)) => {
        let {onPutNodeProperty, putNodesProperty} = this.props;
        let nodes = this.getVisibleNodes();
        if (fromIndex < 0) {
            // ExecutionRoot
            return;
        }
        // Lets find parents next sibling and stick it there
        let newParent = null;
        let newChildIndex = null;
        for (let i = toRowIdx; i >= 0 && i < nodes.length; i += moveBy) {
            let isValidNewSibling = nodes[i].parentId === node.parentId;
            if (isValidNewSibling) {
                newParent = parentNode;
                newChildIndex = newParent.children.indexOf(nodes[i].id);
                break;
            }
            // A valid home is next node that is parent or same type as parent
            let isValidNewParent = nodes[i].type === parentNode.type && nodes[i].id !== parentNode.id;
            if (isValidNewParent) {
                newParent = nodes[i];
                newChildIndex = 0;
                break;
            }

            let isValidNewParentSibling = nodes[i].type === node.type;
            if (isValidNewParentSibling) {
                newParent = nodes.find(a => a.id === nodes[i].parentId);
                newChildIndex = newParent.children.indexOf(nodes[i].id) + 1;
                break;
            }
        }
        if (newParent == null) {
            // No where to move node to
            return;
        }
        if (newParent === parentNode) {
            let updatedChildren = arrayMove(parentNode.children, fromIndex, newChildIndex);
            onPutNodeProperty({id: parentNode.id, children: updatedChildren});
        } else {
            const siblingsChildren = [...newParent.children];
            siblingsChildren.splice(newChildIndex, 0, node.id);
            putNodesProperty([
                {id: parentNode.id, children: arrayRemove(parentNode.children, fromIndex)},
                {id: newParent.id, children: siblingsChildren},
                {id: node.id, parentId: newParent.id}
            ]);
        }
    };

    updateNode(node, update) {
        this.props.onPutNodeProperty({id: node.id, ...update});
    }

    getSelectedNodeData = () => {
        let {procedureNode} = this.props;
        let nodes = this.getVisibleNodes();
        let {selectedRowIdx} = this.state;
        let siblingNode = nodes[selectedRowIdx];
        let parentNode = nodes.find(a => a.id === siblingNode.parentId) || procedureNode;
        let siblingIndex = parentNode.children.indexOf(siblingNode.id);
        return {node: siblingNode, parentNode: parentNode, nodeIndex: siblingIndex, selectedRowIdx: selectedRowIdx};
    };

    handleDelete = () => {
        let {node} = this.getSelectedNodeData();
        this.updateNode(node, {deleted: true});
    };

    handleUnDelete = () => {
        let {node} = this.getSelectedNodeData();
        this.updateNode(node, {deleted: false});
    };

    handleInsertSibling = () => {
        let {parentNode, nodeIndex} = this.getSelectedNodeData();
        this.addChild(parentNode, nodeIndex + 1);
    };

    handleInsertChild = () => {
        let {node} = this.getSelectedNodeData();
        this.addChild(node, 0);
    };

    handleMoveUp = () => {
        let {node, parentNode, nodeIndex, selectedRowIdx} = this.getSelectedNodeData();
        this.moveChild(node, parentNode, nodeIndex, -1, selectedRowIdx);
    };

    handleMoveDown = () => {
        let {node, parentNode, nodeIndex, selectedRowIdx} = this.getSelectedNodeData();
        this.moveChild(node, parentNode, nodeIndex, 1, selectedRowIdx);
    };

    handleCellSelected = ({rowIdx}) => {
        let {onPutNodeProperty, procedureNode} = this.props;
        let nodes = this.getVisibleNodes();
        let selectedNodeId = nodes[rowIdx].id;
        this.setState({selectedRowIdx: rowIdx, selectedNodeId: selectedNodeId});
        onPutNodeProperty({id: procedureNode.id, selectedRowIndex: rowIdx})
    };

    isCellEditable = ({row, column}) => {
        if (column.key === 'name') {
            return true;
        }
        if (row.type !== 'ProcedureQuestion') {
            return false;
        }
        if (column.key === 'format') {
            return row.questionType === 'Number';
        }
        return true;
    };

    showHideDeleteNodes = () => {
        this.setState(state => ({deletedNodesVisibility: !state.deletedNodesVisibility}));
    };

    getVisibleNodes = () => {
        const {nodes} = this.props;
        const {deletedNodesVisibility} = this.state;
        return deletedNodesVisibility ?
            nodes :
            nodes.filter(a => a.type === 'ProcedureRoot' || (!a.deleted && !a.parentDeleted));
    };

    getVisibleRows = () => {
        const {rows} = this.state;
        const {deletedNodesVisibility} = this.state;
        return (deletedNodesVisibility ? rows : rows.filter(a => a.type === 'ProcedureRoot' || !a.deleted));
    };

    reorderRows = e => {
        this.handleCellSelected({rowIdx: e.rowSource.idx});
        let {node, parentNode, nodeIndex, selectedRowIdx} = this.getSelectedNodeData();
        let moveBy = node.type === 'ProcedureQuestion' ? 1 : -1;
        this.moveChild(node, parentNode, nodeIndex, moveBy, selectedRowIdx, e.rowTarget.idx);
    };

    render() {
        const {classes, procedureNode, disabled, editOn} = this.props;
        const {selectedRowIdx, deletedNodesVisibility, WrapperContainer, RowRenderer, RowActionsCellElem} = this.state;
        const showNodes = this.getVisibleNodes();
        const showRows = this.getVisibleRows();
        const selectedNode = selectedRowIdx >= 0 ? showNodes[selectedRowIdx] : null;
        const propertyNode = selectedNode || procedureNode;
        const rowRendererProps = editOn ? {onRowDrop: this.reorderRows} : {};
        return (
            <div>
                <Grid style={{margin: -16}} container spacing={4} justifyContent={"flex-start"}>
                    <Grid item xs={4} className={classes.propertiesContainer}>
                        {
                            !disabled &&
                            <Toolbar variant={'dense'}>
                                <IconButton
                                    color={'primary'}
                                    title={strings.buttons.addSibling}
                                    className={classes.margin}
                                    data-cy='add-sibling'
                                    onClick={this.handleInsertSibling}
                                    size="large">
                                    <AddBoxIcon/>
                                </IconButton>
                                <IconButton
                                    color={'primary'}
                                    title={strings.buttons.addChild}
                                    className={classes.margin}
                                    data-cy='add-child'
                                    onClick={this.handleInsertChild}
                                    size="large">
                                    <AddToPhotosIcon/>
                                </IconButton>
                                <IconButton
                                    color={'primary'}
                                    title={strings.buttons.moveSelectedUp}
                                    className={classes.margin}
                                    onClick={this.handleMoveUp}
                                    data-cy='move-up'
                                    size="large">
                                    <UpArrowIcon/>
                                </IconButton>
                                <IconButton
                                    color={'primary'}
                                    title={strings.buttons.moveSelectedDown}
                                    className={classes.margin}
                                    onClick={this.handleMoveDown}
                                    data-cy='move-down'
                                    size="large">
                                    <DownArrowIcon/>
                                </IconButton>
                                {
                                    selectedNode && !selectedNode.deleted &&
                                    <IconButton
                                        color={'primary'}
                                        title={strings.buttons.deleteSelectedItem}
                                        className={classes.margin}
                                        data-cy='delete'
                                        onClick={this.handleDelete}
                                        size="large">
                                        <DeleteIcon/>
                                    </IconButton>
                                }
                                {
                                    selectedNode && selectedNode.deleted &&
                                    <IconButton
                                        color={'primary'}
                                        title={strings.buttons.restoreSelectedItem}
                                        className={classes.margin}
                                        data-cy='restore'
                                        onClick={this.handleUnDelete}
                                        size="large">
                                        <UnDeleteIcon/>
                                    </IconButton>
                                }
                                <IconButton
                                    color={'primary'}
                                    title={this.state.deletedNodesVisibility ? strings.buttons.hideDeletedNodes : strings.buttons.showDeletedNodes}
                                    className={classes.margin}
                                    data-cy='toggle-show-deleted'
                                    onClick={this.showHideDeleteNodes}
                                    size="large">
                                    {this.state.deletedNodesVisibility ? <HistoryIcon/> : <UpdateIcon/>}
                                </IconButton>
                            </Toolbar>
                        }
                        <Paper className={classes.paper}>
                            <ProcedureCompileMessages nodeId={selectedNode?.id}/>
                            <ProcedureProperties
                                nodeId={propertyNode.id}
                                showDeleted={deletedNodesVisibility}
                                disabled={disabled}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={8} className={classes.reactDataGridContainer}
                          onClick={this.enableCopyPastEvents}>
                        <WrapperContainer>
                            <ReactDataGrid
                                ref={(g) => {
                                    this.grid = g;
                                }}
                                columns={this.getColumns()}
                                rowGetter={(i) => i < showRows.length ? showRows[i] : null}
                                rowsCount={showRows.length}
                                enableCellSelect={true}
                                minHeight={600}
                                onCheckCellIsEditable={this.isCellEditable}
                                onCellSelected={this.handleCellSelected}
                                enableRowSelect={null}
                                scollToRowIndex={selectedRowIdx}
                                rowScrollTimeout={null}
                                minWidth={'100%'}
                                rowActionsCell={RowActionsCellElem}
                                rowRenderer={<RowRenderer {...rowRendererProps}/>}
                            />
                        </WrapperContainer>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        "white-space": "pre",
        maxHeight: 'calc(100vh - 250px)',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    audit: {
        fontSize: '80%'
    },
    reactDataGridContainer: {
        padding: '10px 15px 0 15px !important',
        '& .react-grid-Header .react-grid-HeaderCell .react-grid-checkbox-container': {
            display: 'none'
        },
        '& .react-grid-Grid': {
            minHeight: 'calc(100vh - 200px) !important',
        },
    },
    propertiesContainer: {
        padding: '10px 15px 0 15px !important'
    },
});
ProcedureShowDataGrid.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.nodeId;
    let procedureNode = getNodeOrNull(state, nodeId);
    let ruleNodes = getNodesById(state, procedureNode.rules || []).filter(rule => !rule.draft && rule.nodeIds.length > 0);
    let nodes = [procedureNode];
    nodes.push();
    const includeDeleted = true;
    for (let stepId of procedureNode.children) {
        let step = getNodeOrNull(state, stepId);
        if (includeDeleted === false && step.deleted === true) {
            continue;
        }
        nodes.push(step);
        for (let taskId of step.children) {
            let task = getNodeOrNull(state, taskId);
            if (includeDeleted === false && task.deleted === true) {
                continue;
            }
            nodes.push(task);
            for (let questionId of task.children) {
                let question = getNodeOrNull(state, questionId);
                if (includeDeleted === false && question.deleted === true) {
                    continue;
                }
                nodes.push(question);
            }
        }
    }
    return {
        procedureNode: procedureNode,
        editOn: procedureNode.editOn,
        nodes: nodes,
        ruleNodes: ruleNodes,
        schemas: getSchema(state),
        isCypressTest: cypress.isCypress(),
        ruleSchema: getNodeSchemaOrNull(state, NODE_TYPE_OPTIONS.ProcedureRule)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        putNodesProperty: nodes => dispatch(putNodesProperty(nodes))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedureShowDataGrid));
