import React from 'react';
import {connect} from "react-redux";
import withStyles from '@mui/styles/withStyles';
import {withRouter} from "react-router-dom";
import {
    createHelpDocument,
    searchHelpDocument,
    setHelpDocumentSource,
    updateHelpDocument,
} from '../actions/helpManualActions';
import {ComponentBase} from "tbf-react-library";
import debounce from "lodash/debounce";

class HelpDocument extends ComponentBase {
    constructor(props) {
        super(props);
        this.isSearchingHelPoints = false;
        this.isLoadingHelpDocument = false;
        this.isCreatingHelpDocument = false;
        this.isUpdatingHelpDocumentSource = false;
        this.intervalTimer = null;
        this.intervalUpdatingTimer = null;
    }

    componentDidMount() {
        const {
            helpDocumentSource,
            onSetHelpDocumentSource,
            onSearchHelpDocument
        } = this.props;
        onSetHelpDocumentSource(helpDocumentSource);
        onSearchHelpDocument(helpDocumentSource);
    }

    createStarted = false;
    lastUpdate = null;

    componentDidUpdate() {
        const {
            helpDocumentSource,
            onCreateHelpDocument,
            helpDocument,
            helpPoints,
            helpPointsDirty,
        } = this.props;

        this.debounceSource(helpDocumentSource);


        if (helpDocument?.loaded && !helpDocument.saved && !helpDocument.creating) {
            if (!this.createStarted) {
                this.createStarted = true;
                onCreateHelpDocument({...helpDocument, helpPoints: helpPoints}, helpDocumentSource);
            }
        } else if (helpDocument?.loaded && helpDocument.saved && !helpDocument.updating && helpPointsDirty.length > 0) {
            this.debounceUpdate({...helpDocument, helpPoints: helpPoints}, helpDocumentSource);
        }
    }

    debounceUpdate = debounce((helpDocument, helpDocumentSource) => {
        const {onUpdateHelpDocument} = this.props;
        onUpdateHelpDocument(helpDocument, helpDocumentSource);
    }, 100);

    debounceSource = debounce((helpDocumentSource) => {
        const {onSetHelpDocumentSource} = this.props;
        onSetHelpDocumentSource(helpDocumentSource);
    }, 300);

    render() {
        return <div/>
    }
}

const styles = () => ({})

HelpDocument.propTypes = {};

const mapStateToProps = (state, ownProps) => {
    const helpDocument = state.helpManualReducer.helpDocuments[ownProps.helpDocumentSource.externalId] || null;
    const helpPoints = helpDocument ? helpDocument.helpPoints.map(d => state.helpManualReducer.helpPoints[d]) : [];
    const helpPointsDirty = helpPoints.filter(d => d.dirty);
    return {
        helpDocument: helpDocument,
        helpPoints: helpPoints,
        helpPointsDirty: helpPointsDirty
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSearchHelpDocument: (helpDocumentSource) => dispatch(searchHelpDocument(helpDocumentSource)),
        onSetHelpDocumentSource: (helpDocumentSource) => dispatch(setHelpDocumentSource(helpDocumentSource)),
        onCreateHelpDocument: (helpDocument, helpDocumentSource) => dispatch(createHelpDocument(helpDocument, helpDocumentSource)),
        onUpdateHelpDocument: (helpDocument, helpDocumentSource) => dispatch(updateHelpDocument(helpDocument, helpDocumentSource)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(HelpDocument)));
