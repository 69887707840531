import React from "react";
import ExecutionPhotoUploadBase from "./ExecutionPhotoUploadBase";
import {getGraph, getNodeOrNull, getNodeSchemaOrNull} from "../../selectors/graphSelectors";
import {blurNodeProperty, putNodeProperty, putNodes} from "../../actions";
import {connect} from "react-redux";
import {elementIsInViewport} from "../../util/util";
import {getEnabledChildRulesForNodeByActionOrNull} from "../../selectors/ruleSelectors";
import {RULE_ACTION_TYPE} from "../../reducers/graphReducer";

class ExecutionUploadPhotoFromClipboard extends ExecutionPhotoUploadBase {
    componentDidMount() {
        window.addEventListener("paste", this.clipboardPasteEvent);
    }

    componentWillUnmount() {
        const {onBlurNodeProperty} = this.props;
        onBlurNodeProperty(null, null);
        window.removeEventListener("paste", this.clipboardPasteEvent);
    }

    clipboardPasteEvent = (event) => {
        const {addPhotoEnabled, executionQuestionId, isPastingInExecution} = this.props;
        if (addPhotoEnabled && executionQuestionId && isPastingInExecution) {
            const questionElement = document.getElementById('question-label-' + executionQuestionId);
            if (questionElement && elementIsInViewport(questionElement)) {
                const items = event.clipboardData.items;
                if (items && items[0] && items[0].type.indexOf("image") !== -1) {
                    const file = items[0].getAsFile();
                    this.handleFileAdded(file);
                }
            }
        }
    }

    render() {
        return <React.Fragment/>;
    }
}

const mapStateToProps = (state, ownProps) => {
    const executionNode = getNodeOrNull(state, ownProps.executionId);
    const focusedNode = getGraph(state).focusedNode;
    const previousFocusedNode = getGraph(state).previousFocusedNode;
    const executionQuestionId = focusedNode?.id || previousFocusedNode?.id;
    const lastAnsweredQuestionNode = getNodeOrNull(state, executionQuestionId);
    const field = 'initial';
    let valueDisabled = lastAnsweredQuestionNode && lastAnsweredQuestionNode.initialValueReadOnly;
    const addPhotoEnabled = !valueDisabled && (lastAnsweredQuestionNode && lastAnsweredQuestionNode[field + 'PhotoIdsEnabled']);
    const isPastingInExecution = lastAnsweredQuestionNode?.rootId === executionNode?.id;

    const aspectRatioRules = getEnabledChildRulesForNodeByActionOrNull(state, ownProps.executionQuestionId, RULE_ACTION_TYPE.photoAspectRatio.id)
    const aspectRatio = aspectRatioRules.length > 0 ? aspectRatioRules[0].calculateValue : null
    return {
        projectId: executionNode?.projectId,
        executionId: executionNode?.id,
        procedureId: executionNode?.procedureId,
        attachmentType: (lastAnsweredQuestionNode && lastAnsweredQuestionNode.attachmentType) || null,
        mobilePhotoMode: (lastAnsweredQuestionNode && lastAnsweredQuestionNode.mobilePhotoMode) || null,
        photoSchema: getNodeSchemaOrNull(state, 'Photo'),
        photoCaptureSchema: getNodeSchemaOrNull(state, 'PhotoCapture'),
        photoInputId: lastAnsweredQuestionNode ? lastAnsweredQuestionNode.id + '-' + field : null,
        executionQuestionId,
        field,
        addPhotoEnabled,
        isPastingInExecution,
        aspectRatio
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName)),
        onPutNodes: nodes => dispatch(putNodes(nodes)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExecutionUploadPhotoFromClipboard);