import React from 'react';
import ExecutionInlineCamera from "./Photo/ExecutionInlineCamera";
import ExecutionPhotoList from "../attachment/ExecutionPhotoList";
import {useEnabledChildRulesForNodeByActionOrNull} from "../../../../hooks/nodeHooks";
import {RULE_ACTION_TYPE} from "../../../../reducers/graphReducer";

const ExecutionQuestionPhoto = ({disabled, executionQuestion, field}) => {


    const inlineCameraRule = useEnabledChildRulesForNodeByActionOrNull(executionQuestion.id, RULE_ACTION_TYPE.inlineCamera.id)

    if (!executionQuestion) {
        return
    }
    return <div style={{display: 'block'}}>
        {
            !disabled && inlineCameraRule.length > 0 &&
            <div>
                <ExecutionInlineCamera
                    executionQuestionId={executionQuestion?.id}
                    field={field}
                />
            </div>
        }
        <div>
            <ExecutionPhotoList
                disabled={disabled}
                executionQuestionId={executionQuestion.id}
                executionId={executionQuestion.rootId}
                field={field.replace('Value', '')}/>
        </div>
    </div>
}

export default ExecutionQuestionPhoto;