export const OPEN_IMAGE_EDITOR = 'OPEN_IMAGE_EDITOR'
export const CLOSE_IMAGE_EDITOR = 'CLOSE_IMAGE_EDITOR'
export const SELECT_IMAGE_EDITOR_TOOL = 'SELECT_IMAGE_EDITOR_TOOL'

export const openImageEditor = (payload) => ({
  type: OPEN_IMAGE_EDITOR,
  payload
})

export const closeImageEditor = () => ({
  type: CLOSE_IMAGE_EDITOR
})

export const selectImageEditorTool = (tool) => ({
  type: SELECT_IMAGE_EDITOR_TOOL,
  payload: tool
})
