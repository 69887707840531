import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {
    getAllDirty,
    getDirtyNodes,
    getDirtyNodesSaveAborted,
    getDirtyNodesToSave,
    getNodeOrNull,
    getNodes,
    getSchema
} from "../../selectors/graphSelectors";
import {checkSaveTimeout, clearSaveError, saveDirtyNodes} from "../../actions";
import {connect} from "react-redux";
import CloudQueue from "@mui/icons-material/CloudQueueRounded";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import CloudDone from "@mui/icons-material/CloudDone";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import {cypress, getTime, reportInfo, reportUserError} from "tbf-react-library";
import {isOnline} from "../../util/util";
import Loader from "../components/Loader";
import {strings} from "../components/SopLocalizedStrings";
import {ClickAwayListener, Popover} from "@mui/material";
import GraphSavingList from "./GraphSavingList";
import Button from "@mui/material/Button";
import GraphMobileListItem from './GraphMobileListItem';
import {NODE_IDS} from "../../reducers/graphReducer";

class GraphAutoSave extends Component {

    constructor(props) {
        super(props);
        this.state = {online: isOnline(), showProgressPopover: false, progressAnchorPosition: null};
        this.handleSave = this.handleSave.bind(this);
        this.handleOffline.bind(this);
        this.handleOnline.bind(this);
        this.clearSaveErrors = this.clearSaveErrors.bind(this);

        this.startTimer();
        window.addEventListener('online', () => this.handleOnline());
        window.addEventListener('offline', () => this.handleOffline());
        window.addEventListener('onbeforeunload', GraphAutoSave.leavePage);
    }

    userNotifiedOffline = false;
    saveRequestedNodeIds = {};
    saveAbortedNodeIds = {};
    displayLoadMessageNextRun = false;
    intervalTime = null;


    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.dirty) {
            this.startTimer()
        } else {
            this.stopTimer()
        }
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    startTimer() {
        if (this.intervalTime == null) {
            this.intervalTime = setInterval(() => this.handleSave(), 1000);
        }
    }

    stopTimer() {
        if (this.intervalTime !== null) {
            clearInterval(this.intervalTime);
            this.intervalTime = null;
        }
    }

    componentDidUpdate(prevProps) {
        const {dirtyNodesLoaded, dirtyCount, saveRunning, storedDbErrorNodeIds, pendingSaveNodeCount} = this.props;
        const dirtyLoaded = prevProps.dirtyNodesLoaded === false && dirtyNodesLoaded === true;
        if (dirtyLoaded && dirtyCount > 0) {
            this.displayLoadMessageNextRun = true;
            if (saveRunning) {
            } else if (isOnline()) {
            } else {
                let msg = <span><b>{dirtyCount}</b> unsaved changes. Changes will be saved when you go online.</span>;
                reportInfo(msg);
                this.userNotifiedOffline = true;
            }
        }
        if (dirtyCount && storedDbErrorNodeIds != null) {
            GraphAutoSave.leavingPageMessage = `There are ${dirtyCount} unsaved changes and indexddb is broken.`;
            GraphAutoSave.leavingPageLogOnly = false;
        } else if (pendingSaveNodeCount) {
            GraphAutoSave.leavingPageMessage = `There are ${pendingSaveNodeCount} pending indexeddb requests.`;
            GraphAutoSave.leavingPageLogOnly = !cypress.isUnsavedChangesEnabled();
        } else {
            GraphAutoSave.leavingPageMessage = null;
        }
    }

    static leavingPageMessage = null;
    static leavingPageLogOnly = null;

    static leavePage(e) {
        if (GraphAutoSave.leavingPageLogOnly) {
            // Cancel the event as stated by the standard.
            e.preventDefault();
            // Chrome requires returnValue to be set.
            e.returnValue = GraphAutoSave.leavingPageLogOnly;
        }
        return GraphAutoSave.leavingPageLogOnly;
    }

    clearSaveErrors() {
        let {nodes, dirtyNodes} = this.props;
        const dirtyNodeList = Object.values(dirtyNodes);
        for (let dirtyNode of dirtyNodeList) {
            let node = nodes[dirtyNode.id];
            this.props.clearSaveError(node);
        }
    }

    handleOffline() {
        this.setState({online: false})
    }

    handleOnline() {
        this.setState({online: true});
        this.userNotifiedOffline = false;
    }

    handleSave() {
        let {
            nodes,
            schema,
            dirtyToSave,
            allDirty,
            dirty,
            saveRunning,
            saveRunningDisplay,
            saveStartedTicks,
            nextSaveTicksMin,
            dirtyCount,
            checkSaveTimeout
        } = this.props;
        let online = isOnline();
        let now = getTime();
        const displayLoadMessage = this.displayLoadMessageNextRun;
        this.displayLoadMessageNextRun = false;
        if (!dirty) {
            return;
        }
        if (saveRunning) {
            checkSaveTimeout(saveRunning, saveStartedTicks)
            if (displayLoadMessage && saveRunningDisplay) {
                let msg = <span><b>{dirtyCount}</b> unsaved changes. Saving now...</span>;
                reportInfo(msg);
            }
            return;
        }
        let dirtySaveReady = dirtyToSave.filter(dirtyNode => dirtyNode.nextSaveTicks <= now).length > 0;
        if (nextSaveTicksMin > now || !dirtySaveReady) {
            if (displayLoadMessage) {
                let msg = <span><b>{dirtyCount}</b> unsaved changes. Press <CloudQueue/> to save now.</span>;
                reportInfo(msg);
            }
            return;
        }
        if (online) {
            let dirtyNodesToSave = {};
            dirtyToSave.forEach(dirtyNode => dirtyNodesToSave[dirtyNode.id] = dirtyNode);
            dirtyToSave.forEach(dirtyNode => this.saveRequestedNodeIds[dirtyNode.id] = true);
            this.props.saveDirtyNodes(nodes, schema, dirtyNodesToSave, allDirty);
        } else if (this.userNotifiedOffline === false) {
            reportInfo('You are offline. Your changes will be saved when you next go online and you are logged into this application.');
            this.userNotifiedOffline = true;
        }
    }

    handleOpenProgressPopover = (event) => {
        event.stopPropagation();
        const {clientX, clientY} = event;
        const progressAnchorPosition = {left: clientX, top: clientY};
        this.setState({showProgressPopover: true, progressAnchorPosition});
    }

    handleCloseProgressPopover = (event) => {
        event.stopPropagation();
        this.setState({showProgressPopover: false, progressAnchorPosition: null});
    }

    render() {
        let {saveRunningDisplay, dirtySaveAborted, dirtyCount, classes, isCypressTest} = this.props;
        let notifyUserFailure = dirtySaveAborted
            .filter(dirty => this.saveRequestedNodeIds[dirty.id] && !this.saveAbortedNodeIds[dirty.id]);
        notifyUserFailure.forEach(dirty => this.saveAbortedNodeIds[dirty.id] = true);
        if (notifyUserFailure.length > 0) {
            let roots = {};
            notifyUserFailure.forEach(dirty => roots[dirty.rootId] = true);
            let dirtyCount = Object.keys(roots).length;
            let errorMsg = 'Saving of ' + dirtyCount + ' changes has failed too many times. They will not be attempted again.';
            reportUserError(errorMsg);
        }
        const {showProgressPopover, progressAnchorPosition} = this.state;
        const isAppOnline = isOnline();
        return <>
            {
                isCypressTest && !showProgressPopover &&
                <Button onClick={this.clearSaveErrors} data-cy={'GraphAutoSave'} title={strings.buttons.graphAutoSave}
                        className={classes.cypressGraphAutoSaveButton}>{strings.buttons.graphAutoSave}</Button>
            }
            {
                <IconButton size={'small'} onClick={this.handleOpenProgressPopover} data-cy={'OpenGraphSavingList'}
                            title={strings.buttons.showGraphAutoSavingList}>
                    <GraphMobileListItem>
                        <Badge badgeContent={dirtyCount} color={"secondary"} data-dirty-count={dirtyCount}
                            className={dirtyCount === 0 ? classes.hideBadgeCount : ''}>
                            {
                                saveRunningDisplay &&
                                <React.Fragment>
                                    <Loader circular={true} circularSize={34} loaderStyleClass={classes.progress}
                                            source={'Graph Auto Save'}/>
                                    <CloudQueue/>
                                    <span className={'menuItemTitle'}>{strings.buttons.showGraphAutoSavingList}</span>
                                </React.Fragment>
                            }
                            {
                                !saveRunningDisplay &&
                                <React.Fragment>
                                    <CloudQueue/>
                                    <span className={'menuItemTitle'}>{strings.buttons.showGraphAutoSavingList}</span>
                                </React.Fragment>
                            }
                        </Badge>
                    </GraphMobileListItem>
                </IconButton>
            }
            {
                showProgressPopover &&
                <ClickAwayListener onClickAway={this.handleCloseProgressPopover}>
                    <Popover
                        open={showProgressPopover}
                        anchorReference="anchorPosition"
                        anchorPosition={progressAnchorPosition}
                        onClose={this.handleCloseProgressPopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        {
                            dirtyCount > 0 && <>
                                <GraphSavingList/>
                                {
                                    isAppOnline &&
                                    <Button
                                        className={classes.syncButton}
                                        variant={'contained'}
                                        color={'primary'}
                                        data-cy={'GraphAutoSave'}
                                        disabled={!isAppOnline || saveRunningDisplay}
                                        onClick={this.clearSaveErrors}
                                    >
                                        {
                                            saveRunningDisplay ? `${strings.buttons.syncing}...` : strings.buttons.syncNow
                                        }
                                    </Button>
                                }
                                {
                                    !isAppOnline &&
                                    <div className={classes.syncOfflineMessage}>
                                        <ErrorOutline/> {strings.buttons.syncOffline}
                                    </div>
                                }
                            </>
                        }
                        {
                            dirtyCount === 0 &&
                            <div className={`${classes.syncOfflineMessage} ${classes.changesSavedText}`}>
                                <CloudDone/> {strings.resource.worqSaved}
                            </div>
                        }
                    </Popover>
                </ClickAwayListener>
            }
        </>
    }
}

const styles = (theme) => ({
    progress: {
        position: 'absolute',
        top: -4,
        left: -5,
        color: theme.palette.secondary.six.main
    },
    hideBadgeCount: {
        '& .MuiBadge-invisible': {
            display: 'none',
        }
    },
    syncButton: {
        width: '100%',
        borderRadius: '0 0 4px 4px',
        padding: '10px 0',
    },
    cypressGraphAutoSaveButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 9999,
        opacity: 0,
        minWidth: 40,
        width: 40,
        height: 34,
        fontSize: 6,
    },
    syncOfflineMessage: {
        color: theme.palette.primary.three.main,
        width: '100%',
        fontWeight: 500,
        padding: '0 0 12px 0',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            marginRight: 4,
        }
    },
    changesSavedText: {
        padding: '15px 20px',
        '& .MuiSvgIcon-root': {
            marginRight: 8,
            fontSize: 22,
            color: theme.palette.status.success.icon,
        }
    }
});
GraphAutoSave.propTypes = {};
const mapStateToProps = (state) => {
    let dirtyToSave = getDirtyNodesToSave(state);
    let allDirty = getAllDirty(state);
    let dirtySaveAborted = getDirtyNodesSaveAborted(state);
    let nextSaveTicksMin = Math.min(...dirtyToSave.map(x => x.nextSaveTicks));
    const dirtyNodes = getDirtyNodes(state);
    // Count of nodes that failed to save into indexeddb for some reason
    const notStoredDbCount = dirtyToSave.filter(a => a.storedDb !== true).length;
    let dirtyCount = state.graph.pendingUserSaveNodeCount;
    let saveRunning = state.graph.saveRunning;
    const deviceInternetAvailable = getNodeOrNull(state, NODE_IDS.UserDevice)?.internetAvailable;
    return {
        nodes: getNodes(state),
        schema: getSchema(state),
        dirty: dirtyToSave.length !== 0,
        dirtyToSave: dirtyToSave,
        allDirty: allDirty,
        dirtySaveAborted: dirtySaveAborted,
        saveRunning: saveRunning,
        saveStartedTicks: state.graph.saveStartedTicks,
        saveRunningDisplay: dirtyCount > 0 && state.graph.saveRunning,
        nextSaveTicksMin: nextSaveTicksMin,
        dirtyNodes: dirtyNodes,
        dirtyNodesLoaded: state.graph.dirtyNodesLoaded,
        dirtyCount: dirtyCount,
        notStoredDbCount: notStoredDbCount,
        storedDbErrorNodeIds: state.graph.storedDbErrorNodeIds,
        pendingSaveNodeCount: state.graph.pendingSaveNodeCount,
        isCypressTest: cypress.isCypress(),
        deviceInternetAvailable: deviceInternetAvailable
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        clearSaveError: node => dispatch(clearSaveError(node)),
        saveDirtyNodes: (nodes, schema, dirtyNodes, allDirty) => dispatch(saveDirtyNodes(nodes, schema, dirtyNodes, allDirty)),
        checkSaveTimeout: (saving, saveStartedTicks) => dispatch(checkSaveTimeout(saving, saveStartedTicks))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphAutoSave));
