import React, { useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {strings} from "../../components/SopLocalizedStrings";
import {useNodeOrNull, useRulesForNode} from "../../../hooks/nodeHooks";
import {ADD_ACTION_LABEL_FORMATS, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import ProcedureRulesListItem from "./ExecutionRulesListItem";

const pageStrings = strings.procedure.rule;

const ExecutionRulesList = ({classes, disabled, showDeleted, nodeId, diagnosticsOn}) => {
    const node = useNodeOrNull(nodeId)
    const rules = useRulesForNode(nodeId)
        .filter(a => showDeleted || !a.deleted)
        .filter(a => !a.draft)
        .filter(rule =>
            rule.actionType !== RULE_ACTION_TYPE.collectionView.id &&
            rule.actionType !== RULE_ACTION_TYPE.completeLabels.id &&
            rule.actionType !== RULE_ACTION_TYPE.security.id &&
            rule.format !== ADD_ACTION_LABEL_FORMATS.button.id &&
            rule.format !== ADD_ACTION_LABEL_FORMATS.group.id)

    if (rules.length === 0) {
        return null
    }

    return (
        <div data-cy-element={'Rules'} className={classes.rulesContainer}>
            <List disablePadding={true} dense={true}>
                {
                    rules.length === 0 &&
                    <ListItem disablePadding={true} dense={true}>{pageStrings.none}</ListItem>
                }
                {
                    rules.map((rule) => (
                        <ProcedureRulesListItem
                            key={rule.id}
                            showDeleted={showDeleted}
                            nodeId={nodeId}
                            ruleId={rule.id}
                            diagnosticsOn={diagnosticsOn}
                        />))
                }
            </List>
        </div>
    )
}

const styles = (theme) => ({
    rulesContainer: {
        backgroundColor: theme.palette.grey.one.main,
        width: '100%'
    }
});
export default withStyles(styles)(ExecutionRulesList);
