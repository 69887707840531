import React from 'react';
import withStyles from '@mui/styles/withStyles';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {strings} from "../SopLocalizedStrings";

function TbfToggle({id, checked, label, disabled, onChange}) {
    return (<FormControlLabel
        className={'toggleSwitchFieldPrimary'}
        control={
            <Switch
                id={id}
                checked={checked || false}
                disabled={disabled}
                onChange={onChange}
                value="checked"
                color="primary"
                inputProps={{
                    'aria-label': strings.checkBox.primary,
                    'data-cy-label': label,
                    'aria-pressed': checked || false,
                    'role': 'button',
                }}
            />
        }
        label={label}
    />)
}

const styles = () => ({})

export default withStyles(styles)(TbfToggle);
