import React from 'react';
import withStyles from '@mui/styles/withStyles';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import RenderDraftJs from "./RenderDraftJs";
import ErrorIcon from '../../assets/error.svg';
import {MESSAGE_TYPES} from "../../reducers/graphReducer";

class MessageType extends React.Component {

    renderIcon = () => {
        const {messageType} = this.props;
        switch (messageType) {
            default:
            case 'info':
            case 'hint':
                return <InfoRoundedIcon className={'typeIcon'}/>;
            case 'plain':
                return '';
            case 'success':
                return <CheckCircleRoundedIcon className={'typeIcon'}/>;
            case 'warning':
                return <WarningRoundedIcon className={'typeIcon'}/>;
            case 'error':
                return <img src={ErrorIcon} className={'typeIcon'} alt={''}/>;
        }
    }

    render() {
        const {classes, contentState, messageType, customClass, children} = this.props;
        let cyElementNameMap = {
            [MESSAGE_TYPES.success.id]: 'Success',
            [MESSAGE_TYPES.warning.id]: 'Warning',
            [MESSAGE_TYPES.error.id]: 'Error',
            [MESSAGE_TYPES.info.id]: 'Info',
            [MESSAGE_TYPES.hint.id]: 'Hint',
            [MESSAGE_TYPES.plain.id]: 'Plain',
        };
        let dataCyElement = cyElementNameMap[messageType] || 'Message';

        return <span data-cy-element={dataCyElement}
                     className={classes.messageContainer + ' ' + classes[messageType] + ' ' + (customClass || '')}>
            {this.renderIcon()}
            {
                contentState && <RenderDraftJs contentState={contentState}/>
            }
            {
                children && (<>{children}</>)
            }
        </span>
    }
}

const styles = (theme) => ({
    messageContainer: {
        color: theme.palette.grey.seven.main,
        padding: '5px 10px 5px 8px',
        borderRadius: 4,
        display: 'flex',
        fontSize: 12,
        lineHeight: 1.45,
        width: '100%',
        alignItems: 'center',
        position: 'relative',
        wordBreak: 'break-word',
        '& .typeIcon': {
            fontSize: 14,
            marginRight: 3,
            top: 0,
            position: 'relative',
        },
        '& img.typeIcon': {
            width: 11,
            top: 0,
        },
        '& p': {
            marginBottom: 0,
            '&:empty': {
                marginBottom: '1em',
                width: '100%',
                float: 'left',
            }
        }
    },
    info: {
        backgroundColor: theme.palette.status.info.main,
        '& .typeIcon': {
            color: theme.palette.status.info.icon
        }
    },
    success: {
        backgroundColor: theme.palette.status.success.main,
        '& .typeIcon': {
            color: theme.palette.status.success.icon
        }
    },
    hint: {
        backgroundColor: theme.palette.status.plain.main,
        '& .typeIcon': {
            color: theme.palette.status.plain.icon
        }
    },
    plain: {
        backgroundColor: theme.palette.status.plain.main,
        padding: '5px 10px 5px 0',
        '& .typeIcon': {
            color: theme.palette.status.plain.icon
        }
    },
    warning: {
        backgroundColor: theme.palette.status.warning.main,
        '& .typeIcon': {
            color: theme.palette.status.warning.icon
        }
    },
    error: {
        backgroundColor: theme.palette.status.error.main,
        '& .typeIcon': {
            color: theme.palette.status.error.icon
        }
    }
});

export default withStyles(styles)(MessageType);
