import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../style/alert.css';
import {withRouter} from "react-router-dom";
import * as PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HelpPointIcon from "@mui/icons-material/HelpRounded";
import {getLoadedHelpPointOrNull} from "../selectors/loadHelpPointSelectors";
import {toggleHelpPointDisplay} from "../actions/loadHelpPointActions";
import {SharedAuth} from "tbf-react-library";
import {Permissions} from "../../permissions";
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import cn from 'classnames'

class HelpPointMarker extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onToggleClick = (e) => {
      const { helpPointToggleState, helpPointExternalId, onToggleHelpPointDisplay } = this.props;
      e.stopPropagation();
      onToggleHelpPointDisplay(helpPointExternalId, !helpPointToggleState);
    }

    render() {
        let {
          classes,
          helpPointLoaded,
          hasBookmarks,
          helpPointManualName,
          helpPointToggleState,
          helpIconClassName,
          isIcon,
          canReadHelpPoint
        } = this.props;
  
        if (!canReadHelpPoint) return null
        if(!helpPointLoaded || !hasBookmarks) return null

        const ButtonComponent = isIcon ? HelpPointIcon : IconButton

        return (
          <Tooltip title={'Help: ' + (helpPointManualName)}>
            <ButtonComponent
              data-cy="toggleHelpPoint"
              className={cn(helpIconClassName, helpPointToggleState && classes.helpIconActive)}
              onClick={this.onToggleClick}
            >
              {!isIcon && <HelpPointIcon/>}
            </ButtonComponent>
          </Tooltip>
        );
    }
}

const styles = () => ({
    helpIcon: {
        position: 'relative',
        top: -2,
        '& svg': {
            fontSize: 20
        }
    },
    helpIconInline: {
        position: 'relative',
        top: 1,
        '& svg': {
            fontSize: 20
        }
    },
    helpIconAbsoluteTopRight: {
        top: 0,
        right: -4,
        width: 22,
        height: 22,
        margin: 0,
        position: 'absolute',
        borderRadius: '50%',
        padding: 4,
        transition: 'background .3s',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)',
        }
    },
    helpIconSuperScript: {
        position: 'absolute',
        top: 4,
        '& svg': {
            fontSize: 18
        }
    },
    helpIconActive: {
        background: '#c7d3f7',
        color: '#00248a'
    }
});
HelpPointMarker.propTypes = {
    helpPointExternalId: PropTypes.string.isRequired
};
const mapStateToProps = (state, ownProps) => {
    const helpPoint = getLoadedHelpPointOrNull(state, ownProps.helpPointExternalId);
    const bookmarkIds = get(helpPoint, ['manualBookmarkIds'])
    return {
      helpPoint,
      helpPointLoaded: helpPoint !== null && helpPoint !== undefined,
      helpPointToggleState: helpPoint ? helpPoint.toggleState : false,
      helpPointManualName: helpPoint ? helpPoint.manualName : null,
      canReadHelpPoint: SharedAuth.userHasPermission(Permissions.manuals.read),
      bookmarkIds,
      hasBookmarks: !isEmpty(bookmarkIds)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onToggleHelpPointDisplay: (helpPointExternalId, toggleState) => dispatch(toggleHelpPointDisplay(helpPointExternalId, toggleState)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(HelpPointMarker)));
