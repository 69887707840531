import get from 'lodash/get';
import filter from 'lodash/filter';
import reject from 'lodash/reject';

/**
 * As re-used in reducer needs to support state.graph.nodes and state.nodes
 * @param state
 * @returns {*}
 */
export const getManual = (state) => state.helpManualReducer ? state.helpManualReducer : state;
export const getHelpDocuments = (state) => getManual(state).helpDocuments;
export const getHelpDocumentsByFilter = (state, applyFilter) => reject(filter(getHelpDocuments(state), applyFilter), {deleted: true});
export const getHelpDocumentsByType = (state, type) => getHelpDocumentsByFilter(state, {type: type});
export const getHelpDocumentOrNull = (state, nodeId) => get(getHelpDocuments(state), [nodeId]) || null;
export const getHelpDocumentOrError = (state, nodeId) => {
    let node = getHelpDocumentOrNull(state, nodeId);
    if (!node) {
        throw new Error(`Help Document with id [${nodeId}] does not exist.`);
    }
    return node;
};
export const getHelpDocumentsByIds = (state, nodeIds) => nodeIds.map(nodeId => get(getHelpDocuments(state), [nodeId])).filter(a => a);

export const getHelpPoints = (state) => getManual(state).helpPoints;
export const getHelpPointsByFilter = (state, applyFilter) => reject(filter(getHelpPoints(state), applyFilter), {deleted: true});
export const getHelpPointsByType = (state, type) => getHelpPointsByFilter(state, {type: type});
export const getHelpPointsByExternalId = (state, externalId) => getHelpPointsByFilter(state, {externalId: externalId});
export const getHelpPointOrNull = (state, nodeId) => get(getHelpPoints(state), [nodeId]) || null;
export const getHelpPointOrError = (state, nodeId) => {
    let node = getHelpPointOrNull(state, nodeId);
    if (!node) {
        throw new Error(`Help Point with id [${nodeId}] does not exist.`);
    }
    return node;
};
export const getHelpPointsByIds = (state, nodeIds) => nodeIds.map(nodeId => get(getHelpPoints(state), [nodeId])).filter(a => a);