import React, {useEffect, useRef} from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from "prop-types";
import {downloadAttachment} from "../../actions/executions";
import {useDispatch} from "react-redux";
import {putNodeProperty} from "../../actions";
import {reportDebug, getErrorMessage, reportError} from "tbf-react-library";
import {useNodeOrNull} from "../../hooks/nodeHooks";
import {createImageObjectUrl, encodeUrlLastPart} from "../../util/util";
import {getDbNode} from "../../util/offline";
import fileAttachmentImage from "../../assets/fileTypeLarge.png";

const SopApiImage = ({classes, attachmentId, attachmentType, onError, onLoading, unmountPhoto = true, ...props}) => {
    const photo = useNodeOrNull(attachmentId);
    const inMemoryUrl = photo[attachmentType + 'InMemoryUrl'];
    const attachmentUrl = photo[attachmentType + 'Url'];
    const downloadNow = !inMemoryUrl && !!attachmentUrl;
    let dispatch = useDispatch();
    let unmounted = useRef(false);
    const stateRef = useRef({
        isDownloading: false,
        downloadingAttempts: 0,
        inMemoryUrl: inMemoryUrl,
    });

    useEffect(() => {
        stateRef.current.inMemoryUrl = inMemoryUrl;
        return () => {
            if (unmountPhoto && stateRef.current.inMemoryUrl !== inMemoryUrl) {
                window.URL.revokeObjectURL(stateRef.current.inMemoryUrl);
            }
        }
    }, [inMemoryUrl]);

    useEffect(() => {
        return () => {
            unloadImage();
            unmounted.current = true;
        }
    }, []);

    useEffect(() => {
        if(!attachmentUrl) {
            return;
        }
        downloadImage(true);
    }, [attachmentUrl]);


    // load image from indexeddb if there is no attachmentUrl and inMemoryUrl
    useEffect(() => {
        if(attachmentUrl || inMemoryUrl) {
            return;
        }

        getDbNode(photo.photoCaptureId).then(dbNode => {
            const isImage = dbNode?.node?.originalData?.startsWith('data:image/webp') || dbNode?.node?.originalData?.startsWith('data:image/jpeg');
            const photoData = attachmentType === 'thumbnail' ? dbNode?.node?.thumbnailData : isImage ? dbNode?.node?.originalData : fileAttachmentImage;
            if (dbNode && photoData) {
                const objectUrl = createImageObjectUrl(photoData);
                reportDebug(`Restoring in memory ${attachmentType} for ${photo.id}}`);
                dispatch(putNodeProperty({id: attachmentId, [attachmentType + 'InMemoryUrl']: objectUrl}));
            } else {
                reportError('PhotoCapture missing for photo ' + photo.id, null, photo);
            }
        });
    }, [attachmentUrl, inMemoryUrl, photo.photoCaptureId, photo.id]);

    const downloadImage = (updateInMemoryUrl) => {
        if ((downloadNow && !stateRef.current.isDownloading && stateRef.current.downloadingAttempts < 3) || updateInMemoryUrl) {
            stateRef.current.isDownloading = true;
            stateRef.current.downloadingAttempts++;

            onLoading?.(true);
            const encodedUrl = encodeUrlLastPart(attachmentUrl);
            downloadAttachment(encodedUrl, true)
                .then(response => {
                    if (unmounted.current) {
                        return;
                    }
                    stateRef.current.isDownloading = false;
                    const objectUrl = window.URL.createObjectURL(response.data);
                    dispatch(putNodeProperty({id: attachmentId, [attachmentType + 'InMemoryUrl']: objectUrl}));
                    onLoading?.(false);
                })
                .catch(error => {
                    stateRef.current.isDownloading = false;
                    // reportError(error);

                    const errorMessage = getErrorMessage(error);
                    // comment it out for now, as it is not working properly
                    // if(!isOnline()) {
                    //     reportBusinessError("You are offline and the attachment has not been downloaded")
                    // } else {
                    //     reportBusinessError(`There was an error downloading image, error: ${errorMessage}`);
                    // }

                    onError?.();
                    reportError(`there was an error downloading image ${encodedUrl}. ${errorMessage}`,error);
                    onLoading?.(false);
                });
        }
    }

    const isSameImageMultipleRendered = () => {
        return document.querySelectorAll(`[data-canvas="canvas_${attachmentType}_${attachmentId}"]`).length > 1;
    };

    const unloadImage = () => {
        if (unmountPhoto && stateRef.current.inMemoryUrl && !isSameImageMultipleRendered()) {
            // Do not clear pending upload photos. This is achieved by downloadNow being false.
            window.URL.revokeObjectURL(stateRef.current.inMemoryUrl);
            dispatch(putNodeProperty({id: attachmentId, [attachmentType + 'InMemoryUrl']: null}));
        }
    }
    return <></>;
}

SopApiImage.propTypes = {
    attachmentId: PropTypes.string,
    filename: PropTypes.string,
    renderImage: PropTypes.bool
};
export const styles = () => ({
    hidden: {
        width: 0,
        height: 0,
        display: 'none',
    }
});

export default withStyles(styles)(SopApiImage)