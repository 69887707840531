import React, {useCallback, useEffect, useState} from 'react'
import {reportDebug, reportError, SharedAuth} from "tbf-react-library";
import {NODE_IDS} from "../reducers/graphReducer";
import {initialiseDbUser, isIndexedDbAvailable} from "../util/offline";
import {useCallbackPatchNode} from "../hooks/nodeHooks";
import {resultOrInvoke} from "../util/util";

const UserReduxConnector = ({children}) => {

    const [dbOn, setDbOn] = useState(false)
    const patchNode = useCallbackPatchNode()

    const onLogin = useCallback((user) => {
        const isOn = SharedAuth.isAuthenticated() || SharedAuth.isAnonymous();
        if (user.userId && isOn) {
            if (isIndexedDbAvailable()) {
                initialiseDbUser({
                    clientId: user.clientId,
                    tenantId: user.tenantId || 'main',
                    userId: user.userId,
                    reportError: reportError,
                    reportDebug: reportDebug
                })
                setDbOn(true)
            }
            user.id = NODE_IDS.User
            user.type = 'User'
            patchNode(user);
        }
    }, [patchNode, setDbOn])

    useEffect(() => {
        SharedAuth.addLoginListener(onLogin);
        return () => {

            SharedAuth.removeLoginListener(onLogin)
        }
    }, [onLogin])

    if (!dbOn) {
        return null
    }
    return <>{resultOrInvoke(children)}</>
}

export default UserReduxConnector

export const UserDbConnector = ({children}) => {

    const [dbOn, setDbOn] = useState(false)

    const onLogin = useCallback((user) => {

        const isOn = SharedAuth.isAuthenticated() && isIndexedDbAvailable();
        if (user.userId && isOn) {
            initialiseDbUser({
                clientId: user.clientId,
                tenantId: user.tenantId || 'main',
                userId: user.userId,
                reportError: reportError,
                reportDebug: reportDebug
            })
        }
        setDbOn(true)

    }, [setDbOn])

    useEffect(() => {
        SharedAuth.addLoginListener(onLogin);
        return () => {

            SharedAuth.removeLoginListener(onLogin)
        }
    }, [onLogin])

    if (!dbOn) {
        return null
    }
    return <>{resultOrInvoke(children)}</>
}