import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getActiveNodesByType, getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import NodeTextField from "../graph/NodeTextField";
import PageComponentBase from "../PageComponentBase";
import GraphDelete from "../graph/GraphDelete";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {blurNodeProperty, focusNodeProperty, putNodeProperty} from "../../actions";
import {Permissions} from "../../permissions";
import {strings} from "../components/SopLocalizedStrings";
import {
    Breadcrumbs,
    ErrorBoundary,
    HistoryBackButton,
    PageContentWrapper,
    PageHeader,
    ProjectIcon,
    SharedAuth,
} from "tbf-react-library";
import {GraphLoadGroup} from "../graph/GraphLoadGroup";

class ProjectEdit extends PageComponentBase {

    constructor(props) {
        super(props);
        this.state = {
            showExport: false,
        };
    }

    getBreadCrumbs = () => {
        const {
            projectNode
        } = this.props;
        if (projectNode) {
            return [
                {name: strings.project.namePlural, to: '/projects'},
                {name: projectNode.name || '[Blank]', to: '/projects/' + projectNode.id},
            ];
        }
        return [];
    }

    render() {
        let {nodeId, projectNode, groups} = this.props;
        return (
            <React.Fragment>
                <GraphLoadGroup/>
                <GraphResourceLoad
                    resourcePath={`/projects?id=${nodeId}&includeDeleted=true`}
                    nodeId={nodeId}
                    nodeType={'ProjectRoot'}
                >
                    {
                        projectNode && projectNode.loaded &&
                        <>
                            <PageHeader
                                title={projectNode.name}
                                PageIcon={<img src={ProjectIcon} alt={''}/>}
                                renderNavigationActions={() => <HistoryBackButton/>}
                                renderPageActions={() => {
                                    return <>
                                        {
                                            SharedAuth.userHasPermission(Permissions.project.delete) &&
                                            <GraphDelete nodeId={projectNode.id} iconButton={true}/>
                                        }
                                    </>
                                }}
                            />
                            <PageContentWrapper>
                                <Breadcrumbs list={this.getBreadCrumbs()}/>
                                {projectNode.deleted &&
                                <div className={"alert alert-danger"}>This record is marked as deleted</div>}
                                <NodeTextField nodeId={projectNode.id} nodePropertyName={'name'}/>
                                <NodeTextField nodeId={projectNode.id} nodePropertyName={'designerName'}/>
                                <NodeTextField nodeId={projectNode.id} nodePropertyName={'designerEmail'}/>
                                <NodeTextField nodeId={projectNode.id} nodePropertyName={'designerPhoneNumber'}/>
                                <div className='margin-top15'>
                                    <NodeTextField nodeId={projectNode.id} nodePropertyName={'assignedGroups'}
                                                   nodeValueOptions={groups} visual={'select'}/>
                                </div>
                                <ErrorBoundary>
                                    <NodeTextField nodeId={projectNode.id}
                                                   nodePropertyName={'descriptionDraft'}
                                                   visual={'editor'} />
                                </ErrorBoundary>
                            </PageContentWrapper>
                        </>
                    }
                </GraphResourceLoad>
            </React.Fragment>
        )
    }
}

const styles = () => ({
    grow: {
        flexGrow: 1,
    },
    editorWrapper: {
        border: '1px solid #AAA',
        minHeight: '300px'
    },
    spaced: {
        marginTop: 25,
        fontSize: '0.675rem',
        display: 'block',
        marginBottom: 5
    }
});
ProjectEdit.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.match.params.id;
    return {
        nodeId: nodeId,
        projectNode: getNodeOrNull(state, nodeId),
        groups: getActiveNodesByType(state, 'GroupRoot')
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        onFocusNodeProperty: (id, propertyName) => dispatch(focusNodeProperty(id, propertyName)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectEdit));
