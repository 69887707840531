import React from "react";
import withStyles from '@mui/styles/withStyles';

const HyperLinkText = ({children, classes}) => <span className={classes.hyperLinkText}>{children}</span>;

const styles = (theme) => ({
    hyperLinkText: {
        color: theme.palette.primary.one.main,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});
export default withStyles(styles)(HyperLinkText);
