import React, { useMemo } from "react";
import {Link} from "react-router-dom";
import CustomDataGrid from "../components/common/CustomDataGrid";
import makeStyles from "@mui/styles/makeStyles";
import get from "lodash/get";
import { DateAge } from "tbf-react-library";

const columns = [
    {
        field: "name",
        title: "Name",
        headerName: "Name",
        renderCell: ({row}) => row.id ? <Link to={`/procedures/${row.rootId}`}>{row.name || '[Blank]'}</Link> : null
    },
    {
        field: "category",
        title: "Category",
        headerName: "Category",
    },  
    {
        field: "lastUpdatedDateTime",
        headerName: "Modified",
        title: "Modified",
        renderCell: ({row}) => <DateAge value={row?.lastUpdatedDateTime}/>
    },
];

const useStyle = makeStyles((theme) => ({
    container: {
        height: 'calc(100vh - 172px)',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 160px)' // TODO: Make this smarter, how do we calculate the full height
        },
    }
}))

export default function ProcedureGrid({procedures, allowToggleDeleted, onToggleDeleted}) {
    const classes = useStyle();

    const procedureRows = useMemo(() => {
        return procedures.map((p) => ({
            id: p.id,
            rootId: p.rootId,
            deleted: p.deleted,
            ...columns.reduce((map, c) => ({
                ...map,
                [c.field]: get(p, c.field),
            }), {})
        }));
    }, [procedures]);

    return <div className={classes.container}>
        <CustomDataGrid
            rows={procedureRows}
            columns={columns}
            allowToggleDeleted={allowToggleDeleted}
            onToggleDeleted={onToggleDeleted}
        />
    </div>;
}