import React from "react";
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {QUESTION_TYPES} from "../../../../reducers/graphReducer";
import {blurNodeProperty, focusNodeProperty, putNodesProperty} from "../../../../actions";
import {connect} from "react-redux";
import withStyles from '@mui/styles/withStyles';
import ExecutionQuestionInputBase from "./ExecutionQuestionInputBase";
import SkipNextIcon from "@mui/icons-material/SkipNextRounded";
import IconButton from "@mui/material/IconButton";
import {strings} from "../../../components/SopLocalizedStrings";

class ExecutionQuestionUnknown extends ExecutionQuestionInputBase {

    handleToggleUnknown = () => () => {
        let {valueUnknown, field} = this.props;
        this.updateNodeProperty(field + 'ValueUnknown', !valueUnknown, field + 'Value');
    }

    render() {
        let {classes, valueDisabled, valueUnknownEnabled, valueUnknown, field, questionType} = this.props;

        if (questionType === QUESTION_TYPES.photo.id || questionType === QUESTION_TYPES.message.id || !valueUnknownEnabled) {
            return <React.Fragment/>;
        }

        return <IconButton size={'small'} color={'secondary'} title={strings.buttons.unknown}
                           data-cy='toggle-unknown'
                           className={classes.questionIcon}
                           onClick={this.handleToggleUnknown(field)}
                           disabled={!valueUnknownEnabled || valueDisabled}>
            {
                !valueUnknown && <SkipNextIcon/>
            }
            {
                valueUnknown &&
                <SkipNextIcon className={classes.iconButtonRequested}/>
            }
        </IconButton>;
    }
}

const styles = theme => ({
    questionIcon: {
        color: '#666',
        marginLeft: 15,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 5,
        }
    },
    iconButtonRequested: {
        color: '#666'
    },
});

const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    let valueDisabled = ownProps.disabled || (executionQuestionNode && executionQuestionNode.initialValueReadOnly);
    return {
        executionQuestionNode: executionQuestionNode,
        valueUnknownEnabled: executionQuestionNode[ownProps.field + 'ValueUnknownEnabled'],
        valueUnknown: executionQuestionNode[ownProps.field + 'ValueUnknown'],
        questionType: executionQuestionNode.questionType,
        valueDisabled: valueDisabled,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
        onFocusNodeProperty: (id, propertyName) => dispatch(focusNodeProperty(id, propertyName)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionUnknown));
