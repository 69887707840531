import React from "react";
import withStyles from '@mui/styles/withStyles';
import {strings} from "../components/SopLocalizedStrings";
import {useNodeOrNull, useReportEventCallback} from "../../hooks/nodeHooks";
import {useCallbackUpdateFilter} from "./ExecutionViewHooks";
import get from "lodash/get";
import {Input} from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchRounded";
import { REPORT_EVENT_TYPES } from "../../util/constants";
import useDebouncedCallback from "../../hooks/useDebounceCallback";

/**
 * Filter to apply to the Table/List views
 *
 * @param selectorId
 * @returns {JSX.Element}
 * @constructor
 */
const CollectionSearchInput = ({classes, selectorId}) => {
    const selector = useNodeOrNull(selectorId);
    const searchTerm = get(selector, ['searchTerm'], null)
    const onInput = useCallbackUpdateFilter(selectorId)
    const reportEvent = useReportEventCallback();
    const sendEvent = useDebouncedCallback(() => {
        reportEvent(selector?.procedureId, REPORT_EVENT_TYPES.listingPageSearchFilterUsed.name);
    }, 400, [reportEvent]);
    const handleInput = (e) => {
        sendEvent();
        onInput(e.target.value);
    }
    return (<>
        <Input
            name={'searchTerm'}
            defaultValue={searchTerm}
            placeholder={strings.inputBox.searchListPlaceholder}
            onInput={handleInput}
            className={classes.searchBox + ' textFieldPrimary'}
            startAdornment={<SearchIcon/>}/>
    </>);
}
const styles = (theme) => ({
    searchBox: {
        position: 'relative',
        width: '100%',
        display: 'block',
        '& .MuiInput-input': {
            borderColor: `${theme.palette.grey.five.main} !important`,
            '&::placeholder': {
                color: `${theme.palette.grey.five.main} !important`,
                opacity: '1 !important',
            },
        },
    },
});
export default withStyles(styles)(CollectionSearchInput);