import React from "react";
import withStyles from '@mui/styles/withStyles';
import WarningIcon from "@mui/icons-material/WarningRounded";
import {useNodeOrNull} from "../../hooks/nodeHooks";
import {strings} from "../components/SopLocalizedStrings";
import FlareIcon from "@mui/icons-material/FlareRounded";
import {hasValue} from "../../util/util";

const pageStrings = strings.procedure.show;
const ProcedureCompileMessages = ({nodeId, classes}) => {
    const selectedNode = useNodeOrNull(nodeId);
    const compileWarnings = selectedNode?.compileWarnings || [];
    const compileSuggestions = selectedNode?.compileSuggestions || [];
    const serverCompileWarnings = selectedNode?.serverCompileWarnings || [];
    const combined = hasValue(compileWarnings) ? compileWarnings : serverCompileWarnings;
    return (
        <>
            {
                combined.length > 0 &&
                <div className="alert alert-danger alert-icon">
                    <WarningIcon/> {pageStrings.compileWarningsMessage}
                    <ul>
                        {
                            combined.map((a, i) => (
                                <li className={classes.listItem} key={i}>{a}</li>))
                        }
                    </ul>
                </div>
            }
            {
                Array.isArray(compileSuggestions) && compileSuggestions.length > 0 &&
                <div className="alert alert-info alert-icon">
                    <FlareIcon/> {pageStrings.compileSuggestions}
                    <ul>
                        {
                            compileSuggestions.map((a, i) => (
                                <li className={classes.listItem} key={i}>{a}</li>))
                        }
                    </ul>
                </div>
            }
        </>
    );
}
const styles = () => ({
    listItem: {
        whiteSpace: 'break-spaces'
    }
});
export default withStyles(styles)(ProcedureCompileMessages);