import React from "react";
import {strings} from "../../components/SopLocalizedStrings";
import {Panel} from "tbf-react-library";
import {Grid} from "@mui/material";
import ProcedureActionsAdd from "./ProcedureActionsAdd";
import ProcedureActionRules from "./ProcedureActionsRules";

const pageStrings = strings.procedure.settings.actions;

const ProcedureActionsPanel = ({procedureId, disabled}) => {
    return <>
        <Panel title={pageStrings.tabTitle}>
            <Grid container>
                <Grid item xs={12}>
                    <p>{pageStrings.about1}</p>
                    <p>{pageStrings.about2}</p>
                </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent={"flex-end"}>
                <ProcedureActionsAdd procedureId={procedureId} disabled={disabled} multiple={true}/>
                <ProcedureActionsAdd procedureId={procedureId} disabled={disabled} multiple={false}/>
            </Grid>
        </Panel>
        <ProcedureActionRules procedureId={procedureId} disabled={disabled} />
    </>;
}
export default ProcedureActionsPanel;
