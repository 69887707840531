import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../style/alert.css';
import {withRouter} from "react-router-dom";
import * as PropTypes from "prop-types";
import HelpDocument from '../../manuals/layouts/HelpDocument';
import {ComponentBase} from "tbf-react-library";

class ProcedureHelpDocument extends ComponentBase {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {helpDocumentSource} = this.props;
        return (
            <React.Fragment>
                <HelpDocument helpDocumentSource={helpDocumentSource}/>
            </React.Fragment>
        );
    }
}

const styles = () => ({});
ProcedureHelpDocument.propTypes = {
    procedureId: PropTypes.string.isRequired
};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.procedureId;
    let procedureNode = getNodeOrNull(state, nodeId);
    let helpPointsSource = [];
    let helpDocumentSource = {
        externalId: procedureNode.id,
        externalName: procedureNode.name,
        externalType: 'Template',
        externalParentIndex: '0',
        externalParentId: null,
        deleted: false,
        externalProperty: 'Help'
    };

    for (let s = 0; s < procedureNode.children.length; s++) {
        const stepId = procedureNode.children[s];
        let step = getNodeOrNull(state, stepId);
        helpPointsSource.push({
            externalId: step.id,
            externalName: step.name,
            deleted: step.deleted || step.parentDeleted || false,
            externalParentId: step.parentId,
            externalParentIndex: Number(s).toString(),
            externalProperty: 'Help',
            externalType: 'Step',
        });
        for (let t = 0; t < step.children.length; t++) {
            const taskId = step.children[t];
            let task = getNodeOrNull(state, taskId);
            helpPointsSource.push({
                externalId: task.id,
                externalName: task.name,
                deleted: task.deleted || task.parentDeleted || false,
                externalParentId: task.parentId,
                externalParentIndex: Number(t).toString(),
                externalProperty: 'Help',
                externalType: 'Task',
            });
            for (let q = 0; q < task.children.length; q++) {
                const questionId = task.children[q];
                let question = getNodeOrNull(state, questionId);
                helpPointsSource.push({
                    externalId: question.id,
                    externalName: question.name,
                    deleted: question.deleted || question.parentDeleted || false,
                    externalParentId: question.parentId,
                    externalParentIndex: Number(q).toString(),
                    externalProperty: 'Help',
                    externalType: 'Question',
                });
            }
        }
    }

    return {
        helpDocumentSource: {...helpDocumentSource, synced: false, helpPointSources: [...helpPointsSource]}
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProcedureHelpDocument)));
