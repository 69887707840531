import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodesById} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExecutionIcon from "@mui/icons-material/AssignmentRounded";
import ArrowUpIcon from "@mui/icons-material/ArrowDropUpRounded";
import {ComponentBase} from "tbf-react-library";
import {EMPTY_ARRAY} from "../../util/util";
import {COMPLETE_ACTION_STYLES, LINK_TYPES} from "../../reducers/graphReducer";
import NavigateNextIcon from "@mui/icons-material/NavigateNextRounded";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBeforeRounded";
import {strings} from "../components/SopLocalizedStrings";
import {getCompleteActionStyle} from "../../selectors/executionSelectors";
import {getCustomCompleteLabels} from "../../factory/executionFactory";

class ExecutionNavigationButton extends ComponentBase {
    constructor(props) {
        super(props);
        this.state = {
            menuAnchor: null
        }
    }

    render() {
        const {classes, selectedStepIndex, handleTabChange, direction, firstLastStep, beforeAfterLinks, showNextPrevButton, nextPrevLabel} = this.props;

        if(!showNextPrevButton) {
            return <></>
        }
        if (firstLastStep && beforeAfterLinks.length) {
            return (
                <React.Fragment>
                    {
                        beforeAfterLinks.length === 1 &&
                        <Button
                            color={'inherit'}
                            className={direction === 'Previous' ? classes.buttonPrevious : classes.buttonNext}
                            title={direction === 'Previous' ? strings.buttons.prev : strings.buttons.next}
                            data-cy={direction}
                            component={Link}
                            to={'/executions/' + (beforeAfterLinks[0].toNodeId)}>
                            {direction === 'Previous' && <NavigateBeforeIcon/>}
                            {nextPrevLabel}
                            {direction === 'Next' && <NavigateNextIcon/>}
                        </Button>
                    }

                    {
                        beforeAfterLinks.length > 1 &&
                        <React.Fragment>
                            <Button
                                color={'inherit'}
                                className={classes.buttonNext}
                                data-cy={direction}
                                title={strings.buttons.pageOptionMenu}
                                aria-controls="linkedExecutionOptionMenu"
                                aria-haspopup="true"
                                onClick={(event) => this.setState({menuAnchor: event.currentTarget})}>
                                {nextPrevLabel} <ArrowUpIcon/>
                            </Button>
                            <Menu
                                id='linkedExecutionOptionMenu'
                                getContentAnchorEl={null}
                                anchorEl={this.state.menuAnchor}
                                keepMounted
                                open={Boolean(this.state.menuAnchor)}
                                onClose={() => this.setState({menuAnchor: null})}
                                anchorOrigin={{vertical: "top", horizontal: "center"}}
                                transformOrigin={{vertical: "bottom", horizontal: "center"}}>
                                {
                                    beforeAfterLinks.map((linkedNode, index) => {
                                        return <MenuItem key={index} component={Link}
                                                         to={'/executions/' + linkedNode.toNodeId}>
                                            <div className={classes.listItemWrapper}>
                                                <ExecutionIcon className='listItemIcon'/>
                                                <label className='listItemLabel'>{linkedNode.toNodeTitle}</label>
                                            </div>
                                        </MenuItem>
                                    })
                                }
                            </Menu>
                        </React.Fragment>
                    }
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Button
                        color={'inherit'}
                        className={direction === 'Previous' ? classes.buttonPrevious : classes.buttonNext}
                        title={direction === 'Previous' ? strings.buttons.prev : strings.buttons.next}
                        disabled={firstLastStep}
                        data-cy='previous'
                        onClick={(e) => {
                            const nextIndex = direction === 'Previous' ? selectedStepIndex - 1 : selectedStepIndex + 1;
                            handleTabChange(e, nextIndex, true);
                        }}>
                        {direction === 'Previous' && <NavigateBeforeIcon/>}
                        {nextPrevLabel}
                        {direction === 'Next' && <NavigateNextIcon/>}
                    </Button>
                </React.Fragment>
            )
        }
    }
}

const styles = (theme) => ({
    buttonPrevious: {
        margin: theme.spacing(1),
        padding: '6px 16px 6px 8px'
    },
    buttonNext: {
        margin: theme.spacing(1),
        padding: '6px 8px 6px 16px'
    },
    listItemWrapper: {
        color: theme.palette.grey.seven.main,
        fontSize: '14px',
        width: '100%',
        '& .listItemIcon': {
            verticalAlign: 'middle',
            marginRight: 4
        },
        '& .listItemLabel': {
            verticalAlign: 'middle'
        }
    }
});
const mapStateToProps = (state, ownProps) => {
    const visibleSteps = ownProps.visibleSteps;
    const execution = getNodeOrNull(state, ownProps.executionId);
    const links = getNodesById(state, execution.links || []).filter(a => a && a.deleted !== true);
    const firstLastStep = ownProps.direction === 'Previous' ? ownProps.selectedStepIndex === 0 : ownProps.selectedStepIndex === visibleSteps.length - 1;

    // override fields with custom complete labels
    const currentStep = visibleSteps[ownProps.selectedStepIndex];
    const completeActionStyle = getCompleteActionStyle(state, ownProps.executionId)
    const customCompleteLabels = getCustomCompleteLabels(state, ownProps.executionId, currentStep);

    const completeLabels = {...strings.execution.show[completeActionStyle], ...customCompleteLabels?.calculatedLabels};
    const nextPrevLabel = ownProps.direction === 'Next' ? completeLabels.nextCompleted : completeLabels.previousCompleted;


    let beforeAfterLinks;
    if (firstLastStep) {
        beforeAfterLinks = ownProps.direction === 'Previous' ? links.filter(d => d.linkType === LINK_TYPES.before.id) : links.filter(d => d.linkType === LINK_TYPES.after.id);
    } else {
        beforeAfterLinks = EMPTY_ARRAY;
    }

    const completeStyleUses = completeActionStyle === COMPLETE_ACTION_STYLES.standard.id;
    return {
        beforeAfterLinks: beforeAfterLinks,
        firstLastStep: firstLastStep,
        showNextPrevButton: completeStyleUses && nextPrevLabel && (visibleSteps.length > 1 || beforeAfterLinks.length > 0),
        nextPrevLabel,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionNavigationButton));
