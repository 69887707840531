import React, { forwardRef, Suspense } from 'react'
import ProgressFilled from './ProgressFilled'

const MapView = React.lazy(() => import(/* webpackChunkName: "map" */ './MapView'));

const LazyMapView = forwardRef((props, ref) => {
  return (
    <Suspense fallback={<ProgressFilled />}>
      <MapView {...props} ref={ref} />
    </Suspense>
  )
})

export default LazyMapView
