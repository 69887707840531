import React, { useState } from "react";
import 'react-phone-number-input/style.css';
import Input from "@mui/material/Input";
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { useInputDispatch } from "./hooks";
import cn from "classnames";
import PhoneIcon from "@mui/icons-material/PhoneRounded";
import makeStyles from '@mui/styles/makeStyles';
import { parsePhoneNumber } from "react-phone-number-input";
import { DEFAULT_PHONE_NUMBER_COUNTRY } from "../../../../reducers/graphReducer";

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        "& select": {
            backgroundColor: theme.palette.primary.two.main,
        },
    }
}));

const ExecutionQuestionPhoneNumberInput = React.forwardRef(({inputProps, ...props}, ref) => {

    const className = cn(props.className, inputProps.className);

    return <Input
        inputRef={ref}
        {...inputProps}
        {...props}
        startAdornment={<PhoneIcon />}
        className={className}
        readOnly={inputProps.readOnly}
    />;
});

export default function ExecutionQuestionPhoneNumber({value, disabled, executionQuestion, field, valueUnknown}) {
    const { useInputStandardProperties, updateNodeProperty } = useInputDispatch({ value, disabled, executionQuestion, questionId: executionQuestion.id });
    const handleChange = (value) => {
        updateNodeProperty(field, value, field);
    }
    const {onChange, onFocus, onBlur, value: inputValue, ...inputProps} = useInputStandardProperties(valueUnknown, field, {handleChange});
    
    const classes = useStyles();

    const [currentCountry, setCurrentCountry] = useState(null);

    const {number} = parsePhoneNumber(inputValue || "") ?? parsePhoneNumber(inputValue || "", currentCountry || DEFAULT_PHONE_NUMBER_COUNTRY) ?? {};

    const handleCountryChange = (country) => {
        setCurrentCountry(country);
    }

    return <div className={classes.inputContainer}>
        <PhoneInput
            defaultCountry={DEFAULT_PHONE_NUMBER_COUNTRY}
            flags={flags}
            initialValueFormat="national"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onCountryChange={handleCountryChange}
            value={number || inputValue}
            countrySelectProps={{
                disabled: inputProps.readOnly,
            }}
            inputComponent={ExecutionQuestionPhoneNumberInput}
            inputProps={inputProps}
        />
    </div>;
}