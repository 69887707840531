import React from 'react';
import withStyles from '@mui/styles/withStyles';

function PanelSection({children, classes}) {
    return (
        <div className={classes.panelSection}>
            {children}
        </div>
    )
}

const styles = (theme) => ({
    panelSection: {
        paddingBottom: theme.spacing(1)
    }
});

export default withStyles(styles)(PanelSection);
