import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodesIfPresent} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ProcedureShowImport from "./actions/ProcedureShowImport";
import PageComponentBase from "../PageComponentBase";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import cloneDeep from "lodash/cloneDeep";
import {strings} from "../components/SopLocalizedStrings";
import {NODE_IDS, NODE_TYPE_OPTIONS, PROCEDURE_TYPES} from "../../reducers/graphReducer";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ProcedureAddButton from "./actions/ProcedureAddButton";
import {putNodeProperty} from "../../actions";
import {Breadcrumbs, PageContentWrapper, PageHeader} from "tbf-react-library";
import Dashboard from "@mui/icons-material/DashboardRounded";
import ProcedureGrid from './ProcedureGrid';

const pageStrings = strings.procedure.list;
const procedureTypes = Object.values(PROCEDURE_TYPES);

class ProcedureList extends PageComponentBase {

    constructor(props) {
        super(props);
        this.state = {
            displayDeleted: false
        };
        this.toggleDisplayDeleted = this.toggleDisplayDeleted.bind(this);
    }

    toggleDisplayDeleted = (deleted) => {
        this.setState({displayDeleted: deleted});
    };

    handleTabChange = (event, value, scroll) => {
        this.props.onPutNodeProperty({id: NODE_IDS.UserSettings, selectedProcedureTabIndex: value});
        if (scroll) {
            window.scrollTo(0, 0);
        }
    };

    renderTabs() {
        let {selectedProcedureTabIndex} = this.props;
        return (
            <Tabs
                value={selectedProcedureTabIndex}
                onChange={this.handleTabChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                >
                {
                    procedureTypes.map(option => <Tab key={option.id} wrapped={true} label={option.name}/>)
                }
            </Tabs>
        );
    }

    render() {
        let {
            procedureNodes,
            deletedProcedureNodes,
            selectedProcedureType,
            resourcePath,
            resourcePathDeleted,
            theme
        } = this.props;
        let {displayDeleted} = this.state;
        const useResourcePath = displayDeleted ? resourcePathDeleted : resourcePath;
        const procedures = displayDeleted ? deletedProcedureNodes : procedureNodes;
        return <>
            <PageHeader
                title={pageStrings.title}
                showMenuToggle={true}
                PageIcon={<Dashboard/>}
                renderPageActions={() => {
                    return <>
                        <ProcedureShowImport/>
                        <ProcedureAddButton
                            procedureType={selectedProcedureType}
                            primaryAction={true}
                        />
                    </>
                }}
                renderPageTabs={() => this.renderTabs()}
            />
            <PageContentWrapper>
                <Breadcrumbs list={[]}/>
                <GraphResourceLoad
                    key={useResourcePath}
                    resourcePath={useResourcePath}
                    friendlyName={strings.procedure.namePlural}
                    nodeType={NODE_TYPE_OPTIONS.ProcedureRoot}>
                    <div className={'materialTableResponsiveWrapper'}>
                        <ProcedureGrid
                            procedures={procedures}
                            onToggleDeleted={this.toggleDisplayDeleted}
                        />
                    </div>
                </GraphResourceLoad>
            </PageContentWrapper>
        </>
    }
}

const styles = (theme) => ({
    addLink: {textDecoration: 'none'},
    grow: {
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
    },
});
ProcedureList.propTypes = {};
const mapStateToProps = (state) => {
    const userSettings = getNodeOrNull(state, NODE_IDS.UserSettings);
    const selectedProcedureTabIndex = userSettings && userSettings.selectedProcedureTabIndex ? userSettings.selectedProcedureTabIndex : 0;
    const selectedProcedureType = procedureTypes[selectedProcedureTabIndex];
    const resourcePath = `/procedures?procedureType=${selectedProcedureType.id}&summary=true&sortBy=lastUpdatedDateTime`;
    const resourcePathDeleted = `/procedures?procedureType=${selectedProcedureType.id}&includeDeleted=true&summary=true&sortBy=lastUpdatedDateTime`;
    let resourceSync = getNodeOrNull(state, resourcePath);
    let resourceSyncDeleted = getNodeOrNull(state, resourcePathDeleted);
    let procedures = getNodesIfPresent(state, resourceSync?.nodeIds);
    let deletedProcedures = getNodesIfPresent(state, resourceSyncDeleted?.nodeIds);
    return {
        procedureNodes: cloneDeep(procedures),
        deletedProcedureNodes: cloneDeep(deletedProcedures),
        selectedProcedureTabIndex: selectedProcedureTabIndex,
        selectedProcedureType: selectedProcedureType,
        resourcePath: resourcePath,
        resourceSync: resourceSync,
        resourcePathDeleted: resourcePathDeleted,
        resourceSyncDeleted: resourceSyncDeleted,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(withRouter(ProcedureList)));
