export const OPEN_CONFIRMATION_DIALOG = 'OPEN_CONFIRMATION_DIALOG'
export const CLOSE_CONFIRMATION_DIALOG = 'CLOSE_CONFIRMATION_DIALOG'
export const CONFIRM_CONFIRMATION_DIALOG = 'CONFIRM_CONFIRMATION_DIALOG'

export const openConfirmationDialog = (payload) => ({
  type: OPEN_CONFIRMATION_DIALOG,
  payload
})

export const closeConfirmationDialog = () => ({
  type: CLOSE_CONFIRMATION_DIALOG
})

export const confirmConfirmationDialog = () => ({
  type: CONFIRM_CONFIRMATION_DIALOG
})
