import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodeSchemaOrNull} from "../../selectors/graphSelectors";
import {blurNodeProperty, focusNodeProperty, putNodeProperty} from "../../actions";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import draftToHtml from "draftjs-to-html";
import {
    ComponentBase,
    ErrorBoundary,
    MAX_INTEGER_VALUE,
    MIN_INTEGER_VALUE,
    reportBusinessError,
    SharedAuth,
    TbfSelectDeviceFriendly,
} from "tbf-react-library";
import {strings} from "../components/SopLocalizedStrings";
import {Radio, RadioGroup} from "@mui/material";
import {EMPTY_ARRAY, makeArray} from "../../util/util";
import SopRichTextEditor from "../execution/LazySopRichTextEditor";
import {customImageEntityTransform} from "../components/RenderDraftJs";
import TbfToggle from "../components/common/TbfToggle";
import ProcedureRuleCalculate from "../procedure/ProcedureRuleCalculate";
import {Permissions} from "../../permissions";
import {PROCEDURE_LINK_STYLE} from "../../reducers/graphReducer";

class NodeTextField extends ComponentBase {

    static defaultProps = {
        displayLabel: true,
        disabled: false
    };

    constructor(props) {
        super(props);

        this.state = {inputSearchTerm: null};

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleSwitchChange = event => {
        let {nodeId, nodePropertyName} = this.props;
        let updatedNode = {
            id: nodeId,
            [nodePropertyName]: event.target.checked
        };
        this.props.onPutNodeProperty(updatedNode);
    };

    handleCheckboxChange = (key) => (event) => {
        let {nodePropertyName, value, nodeId} = this.props;
        let checked = event.target.checked;
        let updatedProperty;
        if (checked && !value.includes(key)) {
            updatedProperty = [...value, key];
        } else if (!checked) {
            updatedProperty = [...value.filter(item => item !== key)];
        } else {
            updatedProperty = value;
        }
        let updatedNode = {
            id: nodeId,
            [nodePropertyName]: updatedProperty
        };
        this.props.onPutNodeProperty(updatedNode);
    };

    handleChange = (event) => {
        let {nodePropertyName, kind, nodeId, nodeValueProperty, storeJson} = this.props;
        let cleanedValue = null;
        let label = null;
        if (event) {
            if (event.target) {
                cleanedValue = event.target.value === 'null' ? null : event.target.value;
            } else if (event.constructor === Array) {
                cleanedValue = event.map(d => d.value);
                label = event.map(a => a.label).join(', ');
            } else if (event.constructor === Object) {
                cleanedValue = event.value === 'null' ? null : event.value;
                label = event.label;
            }
        }

        if (kind === 'number' || kind === 'integer') {
            if (cleanedValue == null || cleanedValue === '') {
                cleanedValue = null;
            } else if (kind === 'integer') {
                cleanedValue = Number.parseInt(cleanedValue, 10);
            } else if (kind === 'number') {
                cleanedValue = Number.parseFloat(cleanedValue);
                if (cleanedValue !== null && cleanedValue < MIN_INTEGER_VALUE) {
                    return reportBusinessError(strings.warnings.integerRangeLowered);
                } else if (cleanedValue !== null && cleanedValue > MAX_INTEGER_VALUE) {
                    return reportBusinessError(strings.warnings.integerRangeExceeded);
                }
            }
        }
        if (storeJson) {
            cleanedValue = JSON.stringify(cleanedValue)
        }
        let updatedNode = {
            id: nodeId,
            [nodePropertyName]: cleanedValue
        };
        if (nodeValueProperty) {
            updatedNode[nodeValueProperty] = label;
        }

        this.props.onPutNodeProperty(updatedNode);
    }

    handleFocus = () => {
        let {onFocusNodeProperty, nodePropertyName, nodeId} = this.props;
        onFocusNodeProperty(nodeId, nodePropertyName);
    };

    handleBlur = () => {
        let {onBlurNodeProperty, nodePropertyName, nodeId} = this.props;
        onBlurNodeProperty(nodeId, nodePropertyName);
    };

    handleSearchTermChange = (searchTerm) => {
        this.setState({inputSearchTerm: searchTerm});
    }

    render() {
        let {
            classes,
            nodeSchema,
            nodePropertyName,
            kind,
            displayLabel,
            disabled,
            label,
            visual,
            required,
            visible,
            options,
            nodeId,
            onFocusNodeProperty,
            onBlurNodeProperty,
            value,
            min,
            max,
            pattern,
            storeJson,
            multiple,
            usePortalTargetForSelect = true
        } = this.props;
        const {inputSearchTerm} = this.state;
        let useLabel = displayLabel ? label || nodeSchema.properties[nodePropertyName].displayName : null;
        let property = nodeSchema.properties[nodePropertyName];
        if (!visible) {
            return <React.Fragment/>
        }
        if (visual === 'checkbox') {
            let selectedOptions = value || [];
            return (
                <div data-cy-element={'Property'}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        {displayLabel && <FormLabel htmlFor={nodePropertyName}>{useLabel}</FormLabel>}
                        <FormGroup name={nodePropertyName} id={nodePropertyName}>
                            {options.map((item, ind) => {
                                    let checked = selectedOptions.includes(item.value);
                                    return (<FormControlLabel key={`${item.value}_${ind}`}
                                                              className={classes.checkBoxLabel}
                                                              control={<Checkbox checked={checked} value={item.value}/>}
                                                              label={item.label}
                                                              onChange={this.handleCheckboxChange(item.value)}
                                                              disabled={disabled}/>);
                                }
                            )}
                        </FormGroup>
                    </FormControl>
                </div>);
        }
        if (visual === 'radio') {
            return (
                <div data-cy-element={'Property'}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        {displayLabel && <FormLabel htmlFor={nodePropertyName}>{useLabel}</FormLabel>}
                        <RadioGroup name={nodePropertyName} id={nodePropertyName} value={value}
                                    onChange={this.handleChange}>
                            {options.map((item,ind) => {
                                    return (<FormControlLabel key={`${item.value}_${ind}`}
                                                              value={item.value}
                                                              control={<Radio/>}
                                                              label={item.label}
                                                              disabled={disabled}/>);
                                }
                            )}
                        </RadioGroup>
                    </FormControl>
                </div>);
        }
        if (visual === 'switch') {
            return (
                <div data-cy-element={'Property'}>
                    <TbfToggle
                        id={nodePropertyName}
                        label={useLabel}
                        checked={value}
                        disabled={disabled}
                        onChange={this.handleSwitchChange}
                    />
                </div>);
        }
        if (visual === 'select') {
            if (multiple == null) {
                multiple = Array.isArray(property.kind);
            }
            let selectedIds = value;
            if (selectedIds != null && !Array.isArray(selectedIds) && storeJson) {
                selectedIds = JSON.parse(selectedIds)
            }
            let selectedValues = [];

            if (selectedIds == null) {
                selectedIds = 'null';
            }

            if (selectedIds) {
                if (selectedIds.constructor === String) {
                    selectedValues = options.filter(d => d.value === selectedIds);
                } else if (selectedIds.constructor === Array) {
                    selectedValues = options.filter(d => selectedIds.indexOf(d.value) !== -1);
                }
            }

            return (
                <div data-cy-element={'Property'}>
                    <FormLabel className={'sizeSmall'} htmlFor={nodePropertyName}>{useLabel}</FormLabel>
                    <TbfSelectDeviceFriendly
                        multiple={multiple}
                        closeMenuOnSelect={true}
                        propertyName={nodePropertyName}
                        onChange={this.handleChange}
                        options={options.sort((a, b) => {
                            if (a.order && b.order) {
                                return (a.order - b.order);
                            }
                            return (a.label || '').localeCompare(b.label || '');
                        })}
                        value={selectedValues}
                        inputValue={inputSearchTerm}
                        handleSearchTermChange={this.handleSearchTermChange}
                        disabled={disabled}
                        required={false}
                        isSearchable={true}
                        mobileView={true}
                        menuPortalTarget={document.body}
                        menuPortalTarget={usePortalTargetForSelect ? document.body: undefined}
                        menuPlacement={"auto"}
                    />
                </div>);
        }

        if (visual === 'editor') {
            return (
                <div key={nodeId + nodePropertyName} data-cy-element={'Property'} id={nodePropertyName}>
                    <FormLabel className={'sizeSmall'} htmlFor={nodePropertyName}>{useLabel}</FormLabel>
                    <ErrorBoundary>
                        <SopRichTextEditor
                            disabled={disabled}
                            id={nodePropertyName}
                            nodeId={nodeId}
                            value={value}
                            onFocus={() => {
                                onFocusNodeProperty(nodeId, nodePropertyName);
                            }}
                            onBlur={() => {
                                onBlurNodeProperty(nodeId, nodePropertyName);
                            }}
                            onContentStateChange={(contentState) => {
                                let contentStateJson;
                                let html = draftToHtml(contentState, {}, false, customImageEntityTransform);
                                let htmlText = html.replace(/(<p>|<\/p>|\s|)/gm, '');
                                if (!htmlText) {
                                    contentStateJson = null;
                                } else {
                                    contentStateJson = JSON.stringify(contentState);
                                }
                                this.props.onPutNodeProperty({id: nodeId, [nodePropertyName]: contentStateJson})
                            }}
                        />
                    </ErrorBoundary>
                </div>
            );
        }

        if (visual === 'jsonlogic') {
            return (
                <div id={`${useLabel.toString().replaceAll(' ', '-')}-${nodePropertyName}`} data-cy-element={'Property'}>
                    <FormLabel className={'sizeSmall'} htmlFor={nodePropertyName}>{useLabel}</FormLabel>
                    <ProcedureRuleCalculate ruleId={nodeId} disabled={disabled} nodePropertyName={nodePropertyName}/>
                </div>
            )
        }

        let inputType;
        switch (kind) {
            case 'number':
                inputType = 'number';
                break;
            case 'email':
                inputType = 'email';
                break;
            case 'telephone':
                inputType = 'tel';
                break;
            default:
                inputType = 'text';
        }


        return (
            <div data-cy-element={'Property'}>
                <FormLabel className={'sizeSmall'} htmlFor={nodePropertyName}>{useLabel}</FormLabel>
                <TextField
                    id={nodePropertyName}
                    className={'textFieldPrimary ' + classes.textField}
                    margin="normal"
                    fullWidth
                    variant='standard'
                    onChange={this.handleChange}
                    value={value == null ? '' : value}
                    multiline={property.multiline}
                    type={inputType}
                    required={required}
                    inputProps={{
                        min: min,
                        max: max,
                        autoComplete: "off",
                        pattern: pattern,
                        'data-cy-label': useLabel,
                        readOnly: disabled,
                        style: {
                            maxRows: 100
                        }
                    }}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                />
            </div>
        )
    }
}

const styles = () => ({
    textField: {
        display: 'block',
    },
    checkBoxLabel: {
        whiteSpace: 'break-spaces',
        wordBreak: 'break-all'
    }
});

NodeTextField.propTypes = {
    nodeId: PropTypes.string.isRequired,
    nodePropertyName: PropTypes.string.isRequired,
    displayLabel: PropTypes.bool,
    label: PropTypes.string,
    nodeValueOptions: PropTypes.array,
    disabled: PropTypes.bool
};
const mapStateToProps = (state, {
    nodeId,
    nodePropertyName,
    visual,
    nodeValueOptions,
    disabled,
    hideWhenEnabledIsFalse,
    storeJson
}) => {
    let node = getNodeOrNull(state, nodeId);
    if (node == null) {
        throw new Error(`Node with id ${nodeId} was not found.`)
    }
    let nodeSchema = getNodeSchemaOrNull(state, node.type);
    let property = nodeSchema.properties[nodePropertyName];
    if (!nodeSchema.properties[nodePropertyName]) {
        throw new Error(`Node with id [${nodeId}] and type [${node.type}] does not have a definition for property [${nodePropertyName}].`)
    }
    let kind = node[nodePropertyName + 'Kind'] || property.kind;
    if (!visual) {
        if (Array.isArray(kind)) {
            visual = 'checkbox';
        } else if (kind === 'boolean') {
            visual = 'switch';
        } else if (kind === 'enum' || nodeValueOptions) {
            visual = 'select';
        } else {
            visual = 'input'
        }
    }
    let useDisabled = disabled || (node[nodePropertyName + 'Enabled'] !== undefined && !node[nodePropertyName + 'Enabled']);
    let required = node[nodePropertyName + 'Required'] !== undefined && node[nodePropertyName + 'Required'];
    let visible = node[nodePropertyName + 'Visible'] !== false;
    if (hideWhenEnabledIsFalse && node[nodePropertyName + 'Enabled'] === false) {
        visible = false;
    }
    let options = nodeValueOptions || node[nodePropertyName + 'Options'] || property.values || EMPTY_ARRAY;


    if (options.constructor === Object) {
        options = Object.entries(options).map(([value, label]) => ( {
            value: value,
            label: (label ? label : '')
        }));
    } else if (options.constructor === Array) {
        options = options.map(d => ({
            label: (d.name ? d.name : d.label),
            value: (d.id ? d.id : d.value),
            ...d
        }));
    }
    let selectedIds = node[nodePropertyName];
    if (selectedIds != null && !Array.isArray(selectedIds) && storeJson) {
        selectedIds = JSON.parse(selectedIds)
    }
    selectedIds = makeArray(selectedIds)
    let missingOptions = selectedIds.filter(a => options.find(b => b.value === a) === undefined);
    for (let missingOption of missingOptions) {
        options.push({value: missingOption, label: `Selected option no longer available.`});
    }
    return {
        nodeSchema: nodeSchema,
        kind: kind,
        visual: visual,
        disabled: useDisabled,
        required: required,
        visible: visible,
        options: options,
        value: node[nodePropertyName],
        min: node[nodePropertyName + 'Min'],
        max: node[nodePropertyName + 'Max'],
        pattern: node[nodePropertyName + 'Pattern'] || null
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        onFocusNodeProperty: (id, propertyName) => dispatch(focusNodeProperty(id, propertyName)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeTextField));
