import React, {useCallback, useState} from "react";
import withStyles from '@mui/styles/withStyles';
import {Link} from "@mui/material";
import ExecutionJsonLogicDialog from "./ExecutionJsonLogicDialog";

const ExecutionJsonLogic = ({classes, jsonLogic, children}) => {
    const [open, setOpen] = useState(false)
    const toggleOpen = useCallback(() => {
        setOpen(!open)
    }, [setOpen, open])
    return (
        <React.Fragment>
            <Link onClick={toggleOpen}>{children}</Link>
            {
                open &&
                <ExecutionJsonLogicDialog
                    jsonLogic={jsonLogic}
                    open={open}
                    toggleOpen={toggleOpen}/>
            }
        </React.Fragment>
    );
}
const styles = () => ({});
export default withStyles(styles)(ExecutionJsonLogic);
