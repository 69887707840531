import React from "react";
import withStyles from '@mui/styles/withStyles';
import {strings} from "../../components/SopLocalizedStrings";
import {useActiveChildRulesByActionIfPresent, useChildRulesByAction} from "../../../hooks/nodeHooks";
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import ProcedureRuleCollectionColumn from "./ProcedureCollectionColumn";
import {useCallbackCreateRuleCollectionColumn, useCallbackReorderRule} from "../../../hooks/procedureHooks";
import {AddAction} from "tbf-react-library";
import first from "lodash/first";

const ProcedureRuleCollectionColumns = ({disabled, parentRuleId, ruleActionType, showDeleted, isDialog}) => {
    const columns = useChildRulesByAction(parentRuleId, ruleActionType).filter(a => showDeleted || !a.deleted)
    const addColumn = useCallbackCreateRuleCollectionColumn(parentRuleId, ruleActionType)
    const columnSources = useActiveChildRulesByActionIfPresent(first(columns)?.id, RULE_ACTION_TYPE.collectionColumnSource.id)
    const reOrderColumns =  useCallbackReorderRule(parentRuleId);
    const multipleSources = columnSources.length >= 2

    const handleAddColumn = () => addColumn();

    return (
        <div data-cy-element={'Columns'}>
            <table style={{width: '100%'}}>
                {
                    <thead>
                    <tr>
                        {
                            !disabled &&  <th></th>
                        }
                        {
                            multipleSources &&
                            columnSources.map(a => (<th key={a.id}>{a.procedureName}</th>))
                        }
                        {
                            !multipleSources &&
                            <th>Source</th>
                        }
                        <th>Display name</th>
                        <th/>
                    </tr>
                    </thead>
                }
                <tbody>
                    {
                        columns.map((c, index) => (
                            <ProcedureRuleCollectionColumn onMove={reOrderColumns}
                                                           key={c.id}
                                                           index={index}
                                                           prevNode={columns[index - 1]}
                                                           nextNode={columns[index + 1]}
                                                           ruleId={c.id}
                                                           isDialog={isDialog}
                                                           ruleActionType={ruleActionType}
                                                           disabled={disabled}/>))
                    }
                </tbody>
            </table>
            <AddAction
                label={strings.procedure.rule.listViewDesignAddColumn}
                onClick={handleAddColumn}
                title={strings.procedure.rule.listViewDesignAddColumnTip}
                canAdd={!disabled}
                dataCy={'add-column'}
            />
        </div>
    );
}
const styles = () => ({});
export default withStyles(styles)(ProcedureRuleCollectionColumns);
