import React from "react";
import {withRouter} from "react-router-dom";
import withStyles from '@mui/styles/withStyles';
import {getProcedureTypeIcon} from "../../util/image";
import {Popover} from "@mui/material";
import AssignmentStatus from "./AssignmentStatus";
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import AssignmentProgressDetails from "./AssignmentProgressDetails";
import AssignmentDetails from "./AssignmentDetails";
import {ClickableArea, reportEvent, TruncateReadMoreText} from "tbf-react-library";
import Button from "@mui/material/Button";
import {strings} from "../components/SopLocalizedStrings";
import {NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {hasItems} from "../../util/util";
import LocationOn from "@mui/icons-material/LocationOn";
import TbfSopLink from "../components/common/TbfSopLink";
import ExecutionThemedIcon from "../execution/ExecutionThemedIcon";

class AssignmentCard extends React.Component {
    state = {
        progressAnchorEl: null,
        detailsAnchorEl: null,
    }

    handleOpenProgressPopover = (event) => {
        event.stopPropagation();
        this.setState({progressAnchorEl: event.target});
    }

    handleCloseProgressPopover = (event) => {
        event.stopPropagation();
        this.setState({progressAnchorEl: null});
    }

    handleOpenAssignmentDetailsPopover = (event) => {
        event.stopPropagation();
        this.setState({detailsAnchorEl: event.target});
    }

    handleCloseAssignmentDetailsPopover = (event) => {
        event.stopPropagation();
        this.setState({detailsAnchorEl: null});
    }

    getAssignmentTitle = () => {
        const {nodeType, rootTitle, nodeTitle, rootKey} = this.props;
        switch (nodeType) {
            default:
            case NODE_TYPE_OPTIONS.ExecutionRoot:
                return nodeTitle;
            case NODE_TYPE_OPTIONS.ExecutionStep:
            case NODE_TYPE_OPTIONS.ExecutionTask:
                return `${nodeTitle} on ${rootKey} - ${rootTitle}`;
        }
    }

    onMouseEnter = (e) => {
        if (this.props.onMouseEnter) this.props.onMouseEnter(e, this.props.assignmentId)
    }

    onMouseLeave = (e) => {
        if (this.props.onMouseEnter) this.props.onMouseLeave(e, this.props.assignmentId)
    }

    render() {
        const {
            classes,
            procedureType,
            status,
            assignmentId,
            executionId,
            parentNodes,
            feature,
            eventName,
            procedureId,
        } = this.props;
        const {progressAnchorEl, detailsAnchorEl} = this.state;
        const showProgressPopover = Boolean(progressAnchorEl);
        const showDetailsPopover = Boolean(detailsAnchorEl);
        const title = this.getAssignmentTitle();

        return <React.Fragment>
            <ClickableArea
                data-cy={'assignment-card'}
                role="listitem"
                className={classes.assignmentCard}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}>
                <div>
                    <label className={classes.assignmentCardHeader}>
                        <ExecutionThemedIcon className={'icon'} procedureId={procedureId} procedureType={procedureType} onClick={this.handleOpenAssignmentDetailsPopover} />
                        <TbfSopLink
                            className={'title'}
                            title={title}
                            to={`/executions/${executionId}`}
                            tabIndex='-1'
                            data-expand-click-area
                            nodeId={assignmentId}
                            eventName={eventName}
                        >
                            <TruncateReadMoreText>{title}</TruncateReadMoreText>
                        </TbfSopLink>
                    </label>
                    {
                        parentNodes && <label className={classes.parentName}>{parentNodes}</label>
                    }

                    <div className={classes.assignmentCardFooter}>
                        {
                            feature?.properties?.name &&
                            <React.Fragment>
                                <LocationOn className={classes.locationIcon}/> <span
                                className={classes.locationTitle}>{feature?.properties?.name}</span>
                            </React.Fragment>
                        }
                        <div className={classes.grow}/>
                        <Button title={strings.buttons.showProgressPopover} onClick={this.handleOpenProgressPopover}
                                className={classes.statusWrapper}>
                            <AssignmentStatus status={status}/>
                        </Button>
                    </div>
                    <div className={classes.assignmentCardFooter}>
                        <div className={classes.grow}/>
                    </div>

                    {
                        showProgressPopover &&
                        <Popover
                            open={showProgressPopover}
                            anchorEl={progressAnchorEl}
                            onClose={this.handleCloseProgressPopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}>
                            <AssignmentProgressDetails
                                assignmentId={assignmentId}
                                handleCloseProgressPopover={this.handleCloseProgressPopover}
                            />
                        </Popover>
                    }

                    {
                        showDetailsPopover &&
                        <Popover
                            open={showDetailsPopover}
                            anchorEl={detailsAnchorEl}
                            onClose={this.handleCloseAssignmentDetailsPopover}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}>
                            <AssignmentDetails
                                assignmentId={assignmentId}
                                handleCloseProgressPopover={this.handleCloseAssignmentDetailsPopover}
                            />
                        </Popover>
                    }

                </div>
            </ClickableArea>
        </React.Fragment>
    }
}

const styles = theme => ({
    grow: {
        flexGrow: 1
    },
    assignmentCard: {
        padding: '10px 5px',
        borderBottom: `1px solid ${theme.palette.grey.two.main}`,
        transition: 'box-shadow .2s',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 3px 3px #00000029',
            background: theme.palette.secondary.one.main
        },
        '&:last-child': {
            borderBottom: `none`,
        },
    },
    assignmentCardHeader: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& .icon': {
            width: 30,
            height: 30,
            marginRight: 8,
            borderRadius: 6,
            border: '1px solid transparent',
            transition: 'transform .3s',
            '&:hover': {
                transform: 'scale(1.2)',
                borderColor: theme.palette.primary.two.main,
            },
        },
        '& .title': {
            fontSize: 14,
            color: theme.palette.primary.three.main,
            '&:hover': {
                textDecoration: 'underline',
            },
        }
    },
    assignmentCardFooter: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%',
    },
    fontBold: {
        fontFamily: theme.fonts.primary.bold
    },
    textCapitalise: {
        textTransform: 'capitalize'
    },
    statusWrapper: {
        padding: '3px 5px',
        borderRadius: 4,
        minWidth: '100px',
        '&:hover': {
            background: theme.palette.grey.one.main,
            '& .title': {
                color: theme.palette.grey.seven.main
            }
        },
    },
    parentName: {
        width: '100%',
        color: theme.palette.grey.five.main,
        fontSize: 12,
        paddingLeft: 38,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 5,
    },
    locationIcon: {
        fill: theme.palette.grey.five.main
    },
    locationTitle: {
        color: theme.palette.grey.five.main
    }
});

const mapStateToProps = (state, ownProps) => {
    const assignment = getNodeOrNull(state, ownProps.assignmentId);
    let parentNodes = null;
    if (hasItems(assignment.parents)) {
        assignment.parents.forEach(parent => {
            parentNodes = parentNodes ? parentNodes + ' > ' + parent.title : parent.title;
        });
    }

    return {
        nodeType: assignment.nodeType,
        rootTitle: assignment.rootTitle,
        procedureType: assignment.procedureType,
        nodeTitle: assignment.nodeTitle,
        rootKey: assignment.rootKey,
        status: assignment.status,
        executionId: assignment.executionId,
        parentNodes: parentNodes,
        feature: assignment.feature,
        procedureId: assignment.procedureId,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(AssignmentCard)));
