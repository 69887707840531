import React, {useMemo} from "react";
import withStyles from '@mui/styles/withStyles';
import {extractJsonLogicVars} from "../../../factory/sopJsonLogic";
import {convertJsonLogicToFormula} from "tbf-jsonlogic";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import {strings} from "../../components/SopLocalizedStrings";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ExecutionJsonLogicVar from "./ExecutionJsonLogicVar";

const ExecutionJsonLogic = ({classes, jsonLogic, open, toggleOpen}) => {
    const jsonLogicObj = JSON.parse(jsonLogic)
    const schema = useMemo(() => {
        const results = []
        extractJsonLogicVars(jsonLogicObj, results, true)
        const schema2 = {references: []}
        const referenceByPath = {};
        for (let result of results) {
            if (result.var === '') {
                referenceByPath[result.var] = {
                    shortName: 'Item',
                    display: 'Item',
                    path: ''
                };
            } else {
                if (!referenceByPath[result.var]) {
                    referenceByPath[result.var] = {
                        shortName: "" + (Object.values(referenceByPath).length + 1),
                        display: "" + (Object.values(referenceByPath).length + 1),
                        path: result.var,
                        nodeId: result.nodeId,
                        property: result.property
                    };
                }
            }
        };
        schema2.references = Object.values(referenceByPath);
        return schema2
    }, [jsonLogic]);

    const jsonLogicHuman = convertJsonLogicToFormula(jsonLogicObj, schema)
    return (<Dialog
            open={open}
            onClose={toggleOpen}
            scroll={"paper"}
            aria-labelledby={'dialog-title'}>
            <DialogTitle className={classes.dialogTitle}>
                <Typography variant={"span"} className={classes.title} id={'dialog-title'}>
                    Formula
                </Typography>
            </DialogTitle>
            <DialogContent dividers={true}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {jsonLogicHuman}
                        </Grid>
                        {
                            schema.references.length > 0 &&
                            <Grid item xs={12}>
                                Where
                            </Grid>
                        }
                        {
                            schema.references.filter(reference => reference.shortName !== 'Item').map(reference => (
                                <Grid item xs={12} key={reference.shortName}>
                                    <ExecutionJsonLogicVar reference={reference}/>
                                </Grid>
                            ))
                        }
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' variant='contained' className={classes.button}
                        title={strings.app.close}
                        onClick={toggleOpen}
                        data-cy='jsonlogic-inspect-close'>{strings.app.close}</Button>
            </DialogActions>
        </Dialog>
    );
}
const styles = () => ({});
export default withStyles(styles)(ExecutionJsonLogic);
