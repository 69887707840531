import React from 'react';
import { TbfDateTimePicker } from 'tbf-react-library';
import { useInputDispatch } from './hooks';

const ExecutionQuestionDateTime = ({ value, format, disabled, executionQuestion, questionName, field }) => {
    
    const { onFocusNode, onBlurNode, updateNodeProperty } = useInputDispatch({ value, disabled, executionQuestion, questionId: executionQuestion.id });

    const handleFocus = () => {
        onFocusNode(executionQuestion.id, executionQuestion.id);
    }

    const handleBlur = () => {
        onBlurNode(executionQuestion.id, executionQuestion.id);
    }

    const handleChange = (value, field, valid) => {
        if (value !== null && !valid) return;
        updateNodeProperty(field, value, field);
    }
    
    return <TbfDateTimePicker
        placeholder={!disabled ? 'e.g. 22/10/2020 10:35 AM' : ''}
        format={format}
        id={field + '-' + executionQuestion.id}
        name={field + '-' + executionQuestion.id}
        value={value}
        onChange={(value, _, valid) => handleChange(value, field, valid)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
        readOnly={disabled}
        inputProps={{
            'data-cy': questionName + '_' + field
        }}
        sync
    />
}

export default ExecutionQuestionDateTime;