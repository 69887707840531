import React from "react";
import {sortBy} from "lodash";
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import ProcedureActionRule from "./ProcedureActionRule";
import {useRulesForNodeByActionIfPresent} from "../../../hooks/nodeHooks";

const actionTypes = [RULE_ACTION_TYPE.manuallyAddExecution.id, RULE_ACTION_TYPE.manuallyAddExecutionBulk.id]

const ProcedureActionRules = ({procedureId, disabled}) => {
    const rules = useRulesForNodeByActionIfPresent(procedureId, actionTypes).filter(a => !a.deleted);

    const sorted = sortBy(rules, ['number']);

    return <>
        {sorted.map((rule, index) =>
            <ProcedureActionRule key={rule.id} ruleId={rule.id} disabled={disabled} index={index + 1}/>
        )}
    </>;
}

export default ProcedureActionRules;