import React from 'react';
import '../../../style/alert.css';
import {TbfAction} from "tbf-react-library";
import AssignmentIcon from '@mui/icons-material/Assignment';
import {strings} from "../../components/SopLocalizedStrings";

const ExecutionAssignmentAction = ({onCLick}) => {
    return <TbfAction
        menuItem={true}
        title={strings.execution.assignmentDialog.title}
        dataCy='assign-to'
        iconComponent={AssignmentIcon}
        onClick={onCLick}
    >
        {strings.execution.assignmentDialog.title}
    </TbfAction>
}
export default ExecutionAssignmentAction