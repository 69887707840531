import { fabric } from 'fabric'
import { selectImageEditorTool } from './actions'
import { pointerTool } from './reducer'

let isDrawingLine = false
let line = null
let startingPoint = null
let lineOptions = null

const drawLine = (canvas, point) => {
  if(!line) {
    line = new fabric.Line([startingPoint.x, startingPoint.y, point.x, point.y], lineOptions)
    canvas.add(line)
  }
  line.set({ 'x2': point.x, 'y2': point.y });
  canvas.renderAll()
}

export const toggleDrawingLine = (canvas, point, options, dispatch) => {
  if(isDrawingLine) {
    drawLine(canvas, point)
    isDrawingLine = false
    line = null
    startingPoint = null
    dispatch(selectImageEditorTool(pointerTool))
  } else {
    isDrawingLine = true
    startingPoint = point
    lineOptions = options
  }
}

export const handleLineMouseMove = (canvas, tool, point) => {
  if(tool.type !== 'Line' && isDrawingLine) {
    isDrawingLine = false
    line = null
  }
  if(tool.type === 'Line' && isDrawingLine) {
    drawLine(canvas, point)
  }
}