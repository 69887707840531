import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../../../style/alert.css';
import {blurNodeProperty, focusNodeProperty, putNodesProperty} from "../../../../actions";
import 'date-fns';
import ExecutionQuestionInputBase from "./ExecutionQuestionInputBase";
import {TbfSelectDeviceFriendly} from "tbf-react-library";

class ExecutionQuestionSelectDropDown extends ExecutionQuestionInputBase {
    state = {inputSearchTerm: null};

    handleChangeSelect = (selection) => {
        let {field, selectMany} = this.props;
        if (selection == null) {
            this.updateNodeProperty(field, null, field);
        } else {
            let selectedItems = Array.isArray(selection) ? selection : [selection];
            let selectedIds = selectedItems.map(a => a.value);
            this.updateNodeProperty(field, selectMany ? selectedIds : selectedIds[0], field);
        }
    };

    handleSearchTermChange = (searchTerm) => {
        this.setState({inputSearchTerm: searchTerm});
    }

    render = () => {
        let {
            disabled,
            selectMany,
            field,
            questionId,
            options,
            selectedOption
        } = this.props;

        const {
            inputSearchTerm
        } = this.state;

        return (
            <TbfSelectDeviceFriendly
                multiple={selectMany}
                closeMenuOnSelect={true}
                propertyName={questionId + "_" + field}
                onChange={this.handleChangeSelect}
                onBlur={this.handleBlurElement}
                onFocus={this.handleFocusElement}
                options={options}
                value={selectedOption}
                inputValue={inputSearchTerm}
                handleSearchTermChange={this.handleSearchTermChange}
                disabled={disabled}
                required={true}
                isClearable={true}
                mobileView={true}
                isSearchable={true}
                role={'combobox'}
                minWidth={250}
                menuPortalTarget={document.body}
            />)
    };
}

const styles = () => ({});
ExecutionQuestionSelectDropDown.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    let value = executionQuestionNode[ownProps.field];
    let values = Array.isArray(value) ? value : (value ? [value] : []);
    let options = executionQuestionNode.optionsParsed || [];
    let selectedOption = options.filter(a => values.includes(a.value));
    return {
        options: options,
        selectedOption: selectedOption,
        selectMany: executionQuestionNode.selectMany,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
        onFocusNodeProperty: (id, propertyName) => dispatch(focusNodeProperty(id, propertyName)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionSelectDropDown));
