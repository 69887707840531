import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../../../selectors/graphSelectors";
import {connect} from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import '../../../../style/alert.css';
import {blurNodeProperty, putNodesProperty} from "../../../../actions";
import 'date-fns';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import sortBy from "lodash/sortBy";
import {calculateTextSize} from "../../../../util/util";
import ExecutionQuestionSelectResizeBase from "./ExecutionQuestionSelectResizeBase";
import { strings } from '../../../components/SopLocalizedStrings';

class ExecutionQuestionSelectCheckbox extends ExecutionQuestionSelectResizeBase {
    handleChangeCheckbox = (field, currentValue, newValue) => () => {
        if (!currentValue) {
            currentValue = [];
        }
        const index = currentValue.indexOf(newValue);
        let newArray = [...currentValue];
        if (index === -1) {
            newArray.push(newValue);
        } else {
            newArray.splice(index, 1);
        }
        this.updateNodeProperty(field, newArray, field, true);
    };

    render = () => {
        const {
            classes,
            disabled,
            value,
            field,
            options = [],
            questionName,
            questionId,
            width
        } = this.props;
        const { parentWidth } = this.state;
        const optionPercentage = width/parentWidth;
        const isMoreThan50Percent = optionPercentage > 0.5;

        if (options.length === 0) {
            return <span className={classes.noOptions}>{strings.execution.questions.select.noOptions}</span>
        }

        let checkBoxGroupValues = value ? value : [];
        if (!Array.isArray(checkBoxGroupValues)) {
            checkBoxGroupValues = [checkBoxGroupValues]
        }
        return (<FormGroup
            row={!isMoreThan50Percent}
            ref={this.selectorRef}
            aria-label={questionName}
            name={field + '-' + questionId}
            sx={{
                flexGrow: 1
            }}
            className={'checkBoxFieldPrimary'}
        >
            {
                options?.map(option => (
                    <FormControlLabel
                        value={option.value}
                        key={option.value}
                        control={<Checkbox onChange={this.handleChangeCheckbox(field, value, option.value)}
                                           checked={checkBoxGroupValues.includes(option.value)}/>}
                        label={option.label}
                        className={classes.radio}
                        disabled={disabled}
                    />
                ))
            }
        </FormGroup>);
    };
}

const styles = (theme) => ({
    noOptions: {
        color: theme.palette.grey.four.main,
    }
});
ExecutionQuestionSelectCheckbox.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let executionQuestionNode = getNodeOrNull(state, ownProps.questionId);
    let value = executionQuestionNode[ownProps.field];
    let options = executionQuestionNode.optionsParsed;

    const sortedOptons = sortBy(options, opt => opt.label.length);
    const longestWidth = sortedOptons[sortedOptons.length - 1]; // get the last item, since this is sorted by asc
    const width = calculateTextSize({text: longestWidth?.label})
    const checkboxOffset = 42;

    return {
        options: options,
        questionName: executionQuestionNode.name,
        value: value,
        width: width + checkboxOffset
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestionSelectCheckbox));
