import {
    HELP_POINT_MANUAL_HTML_LOADED,
    HELP_POINTS_DISPLAY_TOGGLE,
    HELP_POINTS_LOADED,
    HELP_POINTS_LOADING
} from "./helpManualActionTypes";
import {execute} from "./manualServices";
import {getApiBaseUrl} from "./commonActions";

export const setHelpPointLoading = (loadingState) => {
    return {
        type: HELP_POINTS_LOADING,
        payload: loadingState
    }
}

export const toggleHelpPointDisplay = (helpPointExternalId, toggleState) => {
    return {
        type: HELP_POINTS_DISPLAY_TOGGLE,
        payload: {helpPointExternalId: helpPointExternalId, toggleState: toggleState}
    }
}

export const getHelpPoints = (criteria) => {
    const url = `${getApiBaseUrl()}/helpdocument?externalId=${encodeURIComponent(criteria.externalId)}&definedOnly=true`;
    const actions = {success: HELP_POINTS_LOADED};
    return execute('get', url, null, actions, criteria);
};

export const downloadHelpDocumentContent = (helpPoint) => {
    const url = `${getApiBaseUrl()}/manuals/${helpPoint.manualId}/download?documentType=html`;
    const actions = {success: HELP_POINT_MANUAL_HTML_LOADED};
    return execute('get', url, null, actions, helpPoint);
};
