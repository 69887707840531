import React from "react";
import withStyles from '@mui/styles/withStyles';
import {TbfAction} from "tbf-react-library";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import {GridPreferencePanelsValue} from "@mui/x-data-grid-premium";
import {useNodeOrNull} from "../../hooks/nodeHooks";

const CollectionShowToggleColumnButton = ({classes, dataTableRef, selectorId}) => {
    const selector = useNodeOrNull(selectorId);
    const showMap = selector?.showMap
    const [hasTableRef,setHasTableRef] = React.useState(dataTableRef.current);


    React.useEffect(() => {
        setHasTableRef(!!dataTableRef.current);
    }, [dataTableRef.current])

    if(!hasTableRef || showMap) {
        return  <></>
    }

    const handleClicked = () => {
        const preferencePanel = dataTableRef.current?.state?.preferencePanel;
        if(preferencePanel?.open && preferencePanel?.openedPanelValue === "columns") {
            return;
        }

        dataTableRef.current?.showPreferences(GridPreferencePanelsValue.columns);
    }

    return (
        <TbfAction
            data-cy={'grid-columns'}
            className={classes.toggleMapButton}
            onClick={handleClicked}
            iconComponent={ViewColumnIcon}
        >
            {'Columns'}
        </TbfAction>
    )
}
const styles = () => ({
    toggleMapButton: {
        flexShrink: 0,
        flexGrow: 0,
        backgroundColor: '#fff',
    },
});
export default withStyles(styles)(CollectionShowToggleColumnButton);