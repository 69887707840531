import React, {useMemo} from "react";
import NodeTextField from "../../graph/NodeTextField";
import {useActiveDescendantsAndSelf} from "../../../hooks/nodeHooks";

const ProcedureRuleNodeIdSelector = ({ruleId, disabled, procedureId}) => {

    const allNodes = useActiveDescendantsAndSelf(procedureId)
    const options = useMemo(() => {
        return allNodes
            .map((item) => (
                {
                    id: item.id,
                    name: `${item.type.replace('ProcedureRoot', 'Worq item').replace('Procedure', '')} #${item.number} - ${item.name}`,
                    order: item.number,
                }))
    }, [allNodes])

    return (
        <>
            <NodeTextField
                nodeId={ruleId}
                nodePropertyName={'nodeIds'}
                disabled={disabled}
                visual={'select'}
                nodeValueOptions={options}
            />
        </>
    )
}

export default ProcedureRuleNodeIdSelector;
