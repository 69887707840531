import React from "react";
import withStyles from '@mui/styles/withStyles';
import {useRuleForNodeByActionOrNull, useRulesForNode,} from "../../../hooks/nodeHooks";
import {RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import ProcedureSecurityRule from "./ProcedureSecurityRule";
import sortBy from "lodash/sortBy";

const ProcedureSecurityRules = ({disabled, procedureId, showDeleted}) => {
    const rule = useRuleForNodeByActionOrNull(procedureId, RULE_ACTION_TYPE.security.id)
    const rules = useRulesForNode(rule.id).filter(a => showDeleted || !a.deleted)
    const sorted = sortBy(rules, ['number']);
    return (<>
        {
            sorted.map(a => <ProcedureSecurityRule ruleId={a.id} key={a.id} disabled={disabled}/>)
        }
    </>)
}
const styles = () => ({});
export default withStyles(styles)(ProcedureSecurityRules);