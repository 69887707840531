import {strings} from "./components/SopLocalizedStrings";
import {ComponentBase} from "tbf-react-library";

class PageComponentBase extends ComponentBase {
    componentDidMount() {
        PageComponentBase.updateDocumentTitle();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        PageComponentBase.updateDocumentTitle();
    }

    static updateDocumentTitle() {
        let h1List = document.getElementsByTagName('h1');
        if (h1List.length) {
            document.title = h1List[0].innerText + strings.app.pageTitlePostfix;
        }
    }
}

PageComponentBase.propTypes = {
};

export default (PageComponentBase);
