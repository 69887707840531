import {combineReducers} from 'redux';
import graph from "./graphReducer";
import helpManualReducer from '../manuals/reducers/helpManualReducer';
import loadHelpPointReducer from '../manuals/reducers/loadHelpPointReducer';
import appReducer from './appReducer';
import {breadCrumbReducer} from 'tbf-react-library';
import imageEditor from '../layouts/ImageEditor/reducer'
import confirmation from './confirmation'

const reducer = combineReducers({
    graph,
    helpManualReducer,
    loadHelpPointReducer,
    breadCrumbReducer,
    appReducer,
    imageEditor,
    confirmation
});

export default reducer;
