import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import PageComponentBase from "../PageComponentBase";
import {strings} from "../components/SopLocalizedStrings";
import ActivityStream from "../activity/ActivityStream";
import {Breadcrumbs, HistoryBackButton, PageContentWrapper, PageHeader, SharedAuth} from "tbf-react-library";
import ViewActivityIcon from "@mui/icons-material/HistoryRounded";

const pageStrings = strings.user.activity;

class UserActivity extends PageComponentBase {

    render() {
        let {userId} = this.props;
        return (
            <>
                <PageHeader
                    title={pageStrings.title}
                    PageIcon={<ViewActivityIcon/>}
                    renderNavigationActions={() => <HistoryBackButton/>}
                    showMenuToggle={false}/>
                <PageContentWrapper>
                    <Breadcrumbs list={[]}/>
                    <ActivityStream criteria={{userId: userId}}/>
                </PageContentWrapper>
            </>
        )
    }
}

const styles = () => ({});
UserActivity.propTypes = {};
const mapStateToProps = () => {
    return {
        userId: SharedAuth.getUserId()
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserActivity));
