import React, {useEffect, useState} from "react";
import {strings} from "../components/SopLocalizedStrings";
import {useNodeOrNull, useNodeSchemaOrError} from "../../hooks/nodeHooks";
import {HistoryBackButton, PageContentWrapper, PageHeader} from "tbf-react-library";
import {createNode} from "../../factory/graphFactory";
import {EXECUTION_SOURCE_TYPES, NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {useDispatch} from "react-redux";
import {putNodeProperty} from "../../actions";
import {Permissions} from "../../permissions";
import {useHistory} from "react-router-dom";
import {useHasProcedurePermission} from "../../hooks/procedureHooks";
import {hasItems} from "../../util/util";
import Typography from "@mui/material/Typography";
import {getProcedureTypeIcon} from "../../util/image";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {isProcedureLoadedFull} from "../../selectors/procedureSelectors";
import {ROUTES} from "../Routes";
import ExecutionShow from "../execution/ExecutionShow";

/**
 * When clicked this will open the add worq item dialog.
 *
 * @param procedureId
 * @param canAdd
 * @returns {JSX.Element}
 * @constructor
 */
const AddNewExecutionRoute = ({procedureId, anonymous, returnUrl}) => {
    const [executionLinkNewId, setExecutionLinkNewId] = useState(null)
    const procedure = useNodeOrNull(procedureId)
    const schema = useNodeSchemaOrError(NODE_TYPE_OPTIONS.ExecutionLinkNew)
    const executionLinkNew = useNodeOrNull(executionLinkNewId)
    const execution = useNodeOrNull(executionLinkNew?.executionId)
    const dispatch = useDispatch()
    const history = useHistory()

    const canAdd = useHasProcedurePermission(procedureId, Permissions.execution.create)
    const hasCompileWarnings = hasItems(procedure?.compileWarnings)
    const submit = !anonymous
    const submitOnDone = anonymous

    // Create it
    useEffect(() => {
        if (procedure && procedure.loadedFull && executionLinkNewId === null && canAdd && !hasCompileWarnings) {
            const newNode = createNode(schema, {procedureId: procedureId, submit: submit, submitOnDone: submitOnDone, source: {kind: EXECUTION_SOURCE_TYPES.add.id}})
            dispatch(putNodeProperty(newNode))
            setExecutionLinkNewId(newNode.id)
        }
    }, [procedure, executionLinkNewId, canAdd, hasCompileWarnings, dispatch, procedureId, schema, submit])

    // Redirect to execution
    // Cypress uses this a lot, I don't want to change those tests
    useEffect(() => {
        if (executionLinkNew?.executionId && executionLinkNewId && execution) {
            dispatch(putNodeProperty({id: executionLinkNewId, submit: submit, submitOnDone: submitOnDone}))

            if(!anonymous) {
                const url = ROUTES.execution(execution)
                history.replace(url)
            }
        }
    }, [executionLinkNew?.executionId, executionLinkNewId, dispatch, history, execution, submit, anonymous])

    let errorMessage = hasCompileWarnings ? strings.procedure.show.compileErrorsMessage : null
    if (!canAdd && isProcedureLoadedFull(procedure)) {
        errorMessage = 'User does not have permission to create a ' + procedure?.name
    }

    if(anonymous && executionLinkNew?.executionId) {
        return <ExecutionShow isAnonymous={true} executionId={executionLinkNew?.executionId} key={executionLinkNew?.executionId} returnUrl={returnUrl} />
    }

    const title = errorMessage ? 'Error' : procedure?.name || 'Loading';
    const renderPageTabs = () => null
    const renderPageActions = () => null
    return (<>
        <Typography variant={'h1'} hidden={true} id={'hiddenTitleElement'}>{title}</Typography>
        <PageHeader
            fullWidthTitle={true}
            title={strings.execution.addNewPage.title}
            PageIcon={<img src={getProcedureTypeIcon(procedure?.procedureType)} alt={''}/>}
            renderNavigationActions={() => <HistoryBackButton/>}
            renderPageTabs={renderPageTabs}
            renderPageActions={renderPageActions}
        />
        <PageContentWrapper>
            <GraphResourceLoad
                friendlyName={strings.procedure.name}
                resourcePath={NODE_IDS.ProcedureFull(procedureId)}
                nodeId={procedureId}
                nodeType={'ProcedureRoot'}
                displayErrorMode={'inline'}
            />
            {
                errorMessage && <div class={'alert alert-error'}>{errorMessage}</div>
            }
        </PageContentWrapper>
    </>);
}
export default AddNewExecutionRoute;
