import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../../style/alert.css';
import "../../../style/form.css";
import {strings} from "../../components/SopLocalizedStrings";
import Box from "@mui/material/Box";
import {
    getActiveNodesByType,
    getNodeOrNull,
    getNodesById,
    getNodeSchemaOrNull,
} from "../../../selectors/graphSelectors";
import {putNodesProperty} from "../../../actions";
import {ComponentBase} from "tbf-react-library";
import {InputLabel} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/AddRounded";
import {createChildNode} from "../../../factory/graphFactory";
import {NODE_TYPE_OPTIONS} from "../../../reducers/graphReducer";
import Grid from "@mui/material/Grid";
import ProcedureRuleAutoCreateRowEditor from "./ProcedureRuleAutoCreateRowEditor";

const pageStrings = strings.procedure.rule;
const initialState = {
    open: false,
};

class ProcedureRuleAutoCreateEditor extends ComponentBase {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleAdd = () => {
        let {ruleSchema, procedure, parentRule, onPutNodesProperty} = this.props;
        let ruleAttr = {
            alwaysOn: true,
            nodeIds: [parentRule.id],
            draft: parentRule.draft,
            copyToOn: true,
            calculateValueOn: true
        };
        let ruleNode = createChildNode(procedure, ruleSchema, ruleAttr);
        onPutNodesProperty([ruleNode]);
    }

    render() {
        let {classes, disabled, childRules} = this.props;
        return (
            <React.Fragment>
                <div data-cy-element={'ChildRules'}>
                    <Box display={'flex'}>
                        <Box flexGrow={1}>
                            <InputLabel
                                className={classes.labelAlignButton}>{pageStrings.autoCreateChildRules}</InputLabel>
                        </Box>
                        {
                            !disabled &&
                            <Box>
                                <IconButton
                                    onClick={this.handleAdd}
                                    data-cy={'add-child-rule'}
                                    title={pageStrings.addButtonTooltip}
                                    size="large">
                                    {
                                        <AddIcon/>
                                    }
                                </IconButton>
                            </Box>
                        }
                    </Box>
                    <Grid container>
                        {
                            childRules.map((c, i) => (
                                <Grid item xs={12} key={c.id}>
                                    <ProcedureRuleAutoCreateRowEditor ruleId={c.id} rowIndex={i} disabled={disabled}/>
                                </Grid>
                            ))
                        }

                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

const styles = () => ({});
ProcedureRuleAutoCreateEditor.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let parentRule = getNodeOrNull(state, ownProps.ruleId);
    let procedure = getNodeOrNull(state, parentRule.rootId);
    let allRules = parentRule.draft ?
        getActiveNodesByType(state, NODE_TYPE_OPTIONS.ProcedureRule).filter(a => a.rootId === parentRule.rootId) :
        getNodesById(state, procedure.rules);
    let childRules = allRules.filter(a => a.nodeIds.includes(parentRule.id) && a.copyToOn);
    if (!ownProps.showDeleted) {
        childRules = childRules.filter(a => !a.deleted);
    }
    return {
        procedure: procedure,
        parentRule: parentRule,
        childRules: childRules,
        ruleSchema: getNodeSchemaOrNull(state, NODE_TYPE_OPTIONS.ProcedureRule)
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedureRuleAutoCreateEditor));
