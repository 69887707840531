import React from 'react';
import {Permissions} from "../../../permissions";
import {strings} from "../../components/SopLocalizedStrings";
import {TbfAction} from "tbf-react-library";
import EditIcon from '@mui/icons-material/EditRounded';
import {useHasProcedurePermission} from "../../../hooks/procedureHooks";
import {useCallbackPatchNode, useNodePropertyOrNull} from "../../../hooks/nodeHooks";

const ProcedureEditButton = ({procedureId}) => {
    const can = useHasProcedurePermission(procedureId, Permissions.procedure.edit);
    const editOn = useNodePropertyOrNull(procedureId, a => a.editOn)
    const callback = useCallbackPatchNode({id: procedureId, editOn: true})
    if (!can || editOn) {
        return null;
    }
    return (<TbfAction
        iconComponent={EditIcon}
        dataCy={'edit-procedure'}
        onClick={callback}
    >
        {strings.buttons.edit}
    </TbfAction>)
}
export default ProcedureEditButton
