import React, { useMemo } from "react";
import { useNodeOrNull, useNodesIfPresent } from "../../../hooks/nodeHooks";
import { Link } from "react-router-dom";
import { strings } from "../../components/SopLocalizedStrings";
import withStyles from '@mui/styles/withStyles';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { LINK_TYPES_SELECTABLE } from "../../../reducers/graphReducer";

const RuleCreatedExecutionsList = ({ruleId, classes}) => {
    const rule = useNodeOrNull(ruleId);
    const createdExecutions = useNodesIfPresent(rule.createExecutionIds);
    const createdExecutionsById = useMemo(() => {
        return createdExecutions.reduce((map, node) => ({...map, [node.id]: node}), {});
    }, [createdExecutions]);

    const getExecutionKey = (execution) => {
        if (!execution?.key) {
            if (!execution) {
                return strings.execution.show.notLoadedKeyText;
            } else {
                return strings.execution.show.blankKeyText;
            }
        }
        return execution.key;
    }

    if (!rule || !rule.createExecutionIds?.length) {
        return null;
    }

    return <List className={classes.list}>
        {rule.createExecutionIds?.map((executionId) => {
            return <ListItem className={classes.listItem} key={executionId}><p>- {strings.execution.show.createdLabel} {LINK_TYPES_SELECTABLE[rule.createExecutionLinkType].name} <Link className={classes.link} to={`/executions/${executionId}`}>{getExecutionKey(createdExecutionsById[executionId])} {createdExecutionsById[executionId] && `- ${createdExecutionsById[executionId].title}`}</Link></p></ListItem>
        })}
    </List>
}

const styles = () => ({
    link: {
        textDecoration: 'underline',
    },
    list: {
        margin: 0,
        padding: 0,
    },
    listItem: {
        margin: 0,
        padding: 0,
    },
});

export default withStyles(styles)(RuleCreatedExecutionsList);