import React from "react";
import withStyles from '@mui/styles/withStyles';
import List from "@mui/material/List";
import ExecutionShowLinkItem from "./ExecutionShowLinkItem";

const ExecutionShowLinkList = ({executionId, classes, items, canRemoveLink, linkedExecutions}) => {

    return <List dense={true} className={classes.listContainer}>
        {
            items.map((item, index) =>
            <ExecutionShowLinkItem
                key={index}
                executionId={executionId}
                item={item}
                canRemoveLink={canRemoveLink}
                linkedExecutions={linkedExecutions}
            />)
        }
    </List>
}

const styles = () => ({
    listContainer: {
        paddingTop: 2,
        paddingBottom: 0,
    },
});

export default withStyles(styles)(ExecutionShowLinkList);
