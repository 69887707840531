import React from 'react'
import {withRouter} from 'react-router-dom'
import {getProcedureTypeIcon} from '../../util/image'
import {ClickableArea} from "tbf-react-library";
import withStyles from '@mui/styles/withStyles';
import {TruncateReadMoreText} from "tbf-react-library";
import { REPORT_EVENT_TYPES } from '../../util/constants';
import TbfSopLink from '../components/common/TbfSopLink';
import ExecutionThemedIcon from '../execution/ExecutionThemedIcon';

const ActivityCard = ({id, classes, rootId, procedureType, rootTitle, nodeTitle, title, procedureId, children}) => {
    const includeBothTitles = nodeTitle && rootTitle && nodeTitle !== rootTitle;

    return (
        <ClickableArea className={classes.card} role="listitem">
            <div>
                <ExecutionThemedIcon procedureId={procedureId} procedureType={procedureType} className={classes.icon} />
                <span className={classes.header}>
                    <TbfSopLink
                        nodeId={id}
                        dataCy='recent-activity-link'
                        eventName={REPORT_EVENT_TYPES.dashboardPageRecentLinkClicked.name}
                        key={'link'}
                        to={`/executions/${rootId}`}
                        tabIndex='-1'
                        title={rootTitle || title}
                        data-expand-click-area
                    >
                        <TruncateReadMoreText>{rootTitle || title}</TruncateReadMoreText>
                    </TbfSopLink>
                </span>
                {includeBothTitles && <label className={classes.copy}>{nodeTitle}</label>}
                {children}
            </div>
        </ClickableArea>
    )
}

const styles = theme => ({
    card: {
        display: 'block',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.grey.two.main}`,
        position: 'relative',
        padding: '15px 5px 20px 55px',
        transition: 'box-shadow .2s',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 3px 3px #00000029',
            background: theme.palette.secondary.one.main
        }
    },
    icon: {
        display: 'block',
        position: 'absolute',
        top: 10,
        left: 8,
        borderRadius: 7,
        width: "30px !important",
        height: "30px !important",
        maxWidth: 30,
        maxHeight: 30,
    },
    header: {
        display: 'block',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        '& a': {
            color: theme.palette.primary.three.main,
            // textDecoration: 'none',
        }
    },
    copy: {
        display: 'block',
        textTransform: 'capitalize',
        color: theme.palette.grey.six.main,
        fontSize: 12
    }
})

export default withRouter(
    withStyles(styles)(
        ActivityCard
    ));
