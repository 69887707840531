import React, {useCallback, useState} from 'react';
import '../../../style/alert.css';
import "../../../style/form.css";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useMobile} from "tbf-react-library";


const TbfDialog = ({renderOpenAction, renderFooter, title, dataCy, children, fullScreen, ...props}) => {
    const [open, setOpen] = useState(false)
    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const isMobile = useMobile()

    return (
        <React.Fragment>
            {renderOpenAction && renderOpenAction({handleOpen: handleOpen})}
            {
                <Dialog open={open} onClose={handleClose} data-cy={dataCy} fullWidth={true}
                        fullScreen={fullScreen == null ? isMobile : fullScreen} {...props}>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        {children}
                    </DialogContent>
                    <DialogActions>
                        {renderFooter && renderFooter({handleOpen: handleOpen, handleClose: handleClose})}
                    </DialogActions>
                </Dialog>
            }
        </React.Fragment>
    )
}
export default TbfDialog
