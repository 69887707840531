import React, {useCallback} from 'react';
import {useInputDispatch} from './hooks';
import LazyJsonEditor from "../../LazyJsonEditor";

const ExecutionQuestionJson = ({value, disabled, executionQuestion, field}) => {

    const {updateNodeProperty} = useInputDispatch({
        value,
        disabled,
        executionQuestion,
        questionId: executionQuestion.id
    });

    const handleChange = useCallback(({newData}) => {
        const useValue = newData == null ? null : JSON.stringify(newData)
        updateNodeProperty(field, useValue, field);
    }, [updateNodeProperty, field])

    let useJson = null;
    try {
        useJson = JSON.parse(value)
    } catch (e) {
    }

    return <LazyJsonEditor
        data={useJson == null ? {} : useJson}
        onUpdate={handleChange}
        restrictEdit={disabled}
        restrictDelete={disabled}
        restrictAdd={disabled}
        maxWidth={'100%'}
    />
}

export default ExecutionQuestionJson;