import React, {Component} from 'react';
import {connect} from "react-redux";
import {NODE_IDS, NODE_TYPE_OPTIONS} from '../../reducers/graphReducer';
import GraphResourceLoad from './GraphResourceLoad';
import {strings} from '../components/SopLocalizedStrings';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import LoadHelpPoints from "../../manuals/layouts/LoadHelpPoints";
import {ErrorBoundary} from "tbf-react-library";
import {HOURS_1, MINUTES_15} from "../../util/constants";
import {GraphLoadGroup} from "./GraphLoadGroup";

class GraphOfflineLoad extends Component {

    render() {
        let {assignmentsOffline, assignmentsOfflineCompleted, offlineExecutions, offlineResources} = this.props;
        let executionIds = Object.entries(offlineExecutions).filter(([, on]) => on).map(([id]) => id);
        return (
            <>
                <ErrorBoundary>
                    {
                        assignmentsOffline &&
                        <>
                            <GraphResourceLoad
                                key={NODE_IDS.MyAssignments}
                                resourcePath={NODE_IDS.MyAssignments}
                                friendlyName={strings.assignment.namePlural}
                                nodeType={NODE_TYPE_OPTIONS.NodeAssignment}
                                incrementalLoadOff={true}
                                reloadIntervalMs={MINUTES_15}
                                hideLoader={true}
                                displayErrorMode={'none'}
                                offline={true}
                            />
                            <GraphResourceLoad
                                key={NODE_IDS.MyAssignedExecutions}
                                resourcePath={NODE_IDS.MyAssignedExecutions}
                                friendlyName={strings.assignment.namePlural}
                                nodeType={NODE_TYPE_OPTIONS.ExecutionRoot}
                                reloadIntervalMs={MINUTES_15}
                                hideLoader={true}
                                displayErrorMode={assignmentsOfflineCompleted ? 'none' : 'inline'}
                                offline={true}
                                incrementalLoadAll={true}
                            />
                        </>
                    }
                    <GraphResourceLoad
                        key={NODE_IDS.Me}
                        resourcePath={NODE_IDS.Me}
                        nodeType={NODE_TYPE_OPTIONS.ExecutionRoot}
                        reloadIntervalMs={MINUTES_15}
                        hideLoader={true}
                        displayErrorMode={'none'}
                        hideOfflineWarnings={true}
                        offline={true}
                        friendlyName={strings.execution.me}
                    />
                    <GraphLoadGroup
                        key={NODE_IDS.Groups}
                        incrementalLoadOff={true}
                        reloadIntervalMs={MINUTES_15}
                        // We dont want slow teams loading to slow down cypress tests
                        disableLoader={true}
                        firstLoadQuickOff={true}
                        hideOfflineWarnings={true}
                    />
                    {
                        executionIds.map(id => this.renderExecutionSelf(id))
                    }
                    {
                        offlineResources?.projectIds &&
                        offlineResources.projectIds.map(id => this.renderExecutionProject(id))
                    }
                    {
                        offlineResources?.procedureIds &&
                        offlineResources.procedureIds.map(id => this.renderProcedure(id))
                    }
                    {
                        offlineResources?.executionListPaths &&
                        offlineResources.executionListPaths.map(id => this.renderExecutionScope(id))
                    }
                    {
                        offlineResources?.photoPaths &&
                        offlineResources.photoPaths.map(id => this.renderPhotos(id))
                    }
                </ErrorBoundary>
            </>
        )
    }

    renderProcedure(id) {
        return (
            <div key={id}>
                <LoadHelpPoints externalId={id}/>
                <GraphResourceLoad
                    resourcePath={NODE_IDS.ProcedureFull(id)}
                    nodeId={id}
                    friendlyName={strings.procedure.name}
                    nodeType={'ProcedureRoot'}
                    reloadIntervalMs={HOURS_1}
                    hideLoader={true}
                    hideOfflineWarnings={true}
                    firstLoadQuickOff={true}
                    offline={true}
                    displayErrorMode={'none'}
                />
            </div>
        )
    }

    renderExecutionSelf(nodeId) {
        return (
            <GraphResourceLoad
                key={nodeId}
                resourcePath={`/executions?id=${nodeId}`}
                friendlyName={strings.execution.name}
                nodeId={nodeId}
                nodeType={'ExecutionRoot'}
                reloadIntervalMs={HOURS_1}
                hideLoader={true}
                hideOfflineWarnings={true}
                firstLoadQuickOff={true}
                offline={true}
                displayErrorMode={'none'}
            />)
    }

    renderExecutionScope(scopedPath) {
        return (
            <GraphResourceLoad
                key={scopedPath + '2'}
                resourcePath={scopedPath}
                friendlyName={strings.execution.namePlural}
                nodeType={'ExecutionRoot'}
                reloadIntervalMs={HOURS_1}
                hideLoader={true}
                hideOfflineWarnings={true}
                firstLoadQuickOff={true}
                offline={true}
                displayErrorMode={'none'}
            />)
    }

    renderExecutionProject(executionProjectId) {
        return (
            <GraphResourceLoad
                key={executionProjectId}
                resourcePath={NODE_IDS.Project(executionProjectId)}
                friendlyName={strings.project.name}
                nodeId={executionProjectId}
                nodeType={'ProjectRoot'}
                reloadIntervalMs={HOURS_1}
                hideLoader={true}
                hideOfflineWarnings={true}
                firstLoadQuickOff={true}
                offline={true}
                displayErrorMode={'none'}
            />)
    }

    renderPhotos(path) {
        return (
            <GraphResourceLoad
                key={path}
                resourcePath={path}
                friendlyName={strings.photo.namePlural}
                nodeType={'Photo'}
                reloadIntervalMs={HOURS_1}
                hideLoader={true}
                hideOfflineWarnings={true}
                firstLoadQuickOff={true}
                offline={true}
                displayErrorMode={'none'}
            />)
    }


}

GraphOfflineLoad.propTypes = {};
const mapStateToProps = (state) => {
    let userDevice = getNodeOrNull(state, NODE_IDS.UserDevice)
    return {
        assignmentsOffline: userDevice?.assignmentsOffline || false,
        assignmentsOfflineCompleted: userDevice?.assignmentsOfflineCompleted || false,
        offlineExecutions: userDevice?.offlineExecutions || {},
        offlineResources: userDevice?.offlineResources
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphOfflineLoad);
