import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import withStyles from '@mui/styles/withStyles';
import ActivityAvatar from "../components/common/TbfAvatar";
import {formatFullDate} from "../../util/util";
import ExecutionLink from "../execution/ExecutionLink";
import {strings} from "../components/SopLocalizedStrings";

const styles = {
    truncate: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    innerTitle: {
        fontStyle: 'italic'
    }
};

const formatActivityTitle = (activity, classes) => {
    let includeBothTitles = activity.nodeTitle && activity.rootTitle && activity.nodeTitle !== activity.rootTitle;
    return (<span>
        <span>{activity.activityTitle} </span>
        {
            includeBothTitles && <span><span className={classes.innerTitle}>{activity.nodeTitle}</span> on </span>
        }
        <ExecutionLink nodeId={activity.rootId}>{activity.rootTitle}</ExecutionLink>
    </span>);
};
const formatActivitySecondary = activity => {
    let parents = activity.parent || [];
    return (<span>
        {activity.activityDateTime && <span>{formatFullDate(activity.activityDateTime)}</span>}
        {
            parents.map(parent => <span key={parent.title}>{parent.title}</span>)
        }
    </span>);
}

const ActivityItemView = ({activity, classes}) => (
    <ListItem data-cy-element={'Item'}>
        <ListItemAvatar>
            <ActivityAvatar user={activity.user}/>
        </ListItemAvatar>
        <ListItemText
            primary={
                <div className={classes.truncate}>
                    <strong>
                        {activity.user ? activity.user.name : strings.execution.activity.nameNull}
                    </strong>{' '}
                    {formatActivityTitle(activity, classes)}
                </div>
            }
            secondary={formatActivitySecondary(activity)}
        />
    </ListItem>
);

const EventItem = withStyles(styles)(ActivityItemView);

export default EventItem;