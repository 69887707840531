import React from "react";
import withStyles from '@mui/styles/withStyles';
import {TbfAction} from "tbf-react-library";
import FilterListIcon from '@mui/icons-material/FilterList';
import {useNodeOrNull} from "../../hooks/nodeHooks";

const CollectionShowFilterButton = ({classes, dataTableRef, selectorId}) => {
    const selector = useNodeOrNull(selectorId);
    const showMap = selector?.showMap
    const [hasTableRef,setHasTableRef] = React.useState(dataTableRef.current);

    React.useEffect(() => {
        setHasTableRef(!!dataTableRef.current);
    }, [dataTableRef.current])

    if(!hasTableRef || showMap) {
        return  <></>
    }

    const handleClicked = () => {
        const preferencePanel = dataTableRef.current?.state?.preferencePanel;
        if(preferencePanel?.open && preferencePanel?.openedPanelValue === "filters") {
          return;
        }

        dataTableRef.current?.showFilterPanel();

    };

    return (
        <TbfAction
            data-cy={'grid-filters'}
            className={classes.toggleMapButton}
            onClick={handleClicked}
            iconComponent={FilterListIcon}
        >
            {'Filters'}
        </TbfAction>
    )
}
const styles = () => ({
    toggleMapButton: {
        flexShrink: 0,
        flexGrow: 0,
        backgroundColor: '#fff',
    },
});
export default withStyles(styles)(CollectionShowFilterButton);