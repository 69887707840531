import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from "react-router";
import { strings } from '../components/SopLocalizedStrings';
import EmptyMessage from './EmptyMessage';
import EmptyWorkspaceImage from "../../assets/emptyWorkspaceImage.PNG";
import SmoothHorizontalScroller from '../components/SmoothHorizontalScroller';
import Button from "@mui/material/Button";
import ChevronRight from "@mui/icons-material/ChevronRightRounded";
import { ClickableArea, reportEvent, TruncateReadMoreText } from 'tbf-react-library';
import { Link } from "react-router-dom";
import moment from 'moment';
import cn from 'classnames';
import ExecutionOfflineAction from '../execution/actions/ExecutionOfflineAction';
import { useNodesIfPresent } from '../../hooks/nodeHooks';
import { REPORT_EVENT_TYPES } from '../../util/constants';
import TbfSopLink from '../components/common/TbfSopLink';

export const RecentExecutionTypes = {
    availableOffline: 'availableOffline',
    project: 'project',
    workspace: 'workspace'
}

const RecentExecutionConfig = {
    availableOffline: {
        plural: 'executions',
    },
    project: {
        plural: 'projects',
    },
    workspace: {
        plural: 'workspaces',
    }
}

const styles = (theme) => ({
    container: {
        float: 'left',
        width: '100%',
        '& .emptyMessageContainer': {
            padding: '15px 0 5px 0',
            '& img': {
                maxWidth: 260,
            },
        },
        position: 'relative',
    },
    paddingBottom28: {
        paddingBottom: '28px',
    },
    sectionFooter: {
        width: '100%',
        padding: '10px 10px 0px 10px',
        float: 'left'
    },
    marginLeft10: {
        marginLeft: 10
    },
    marginRight20: {
        marginRight: 20
    },
    linkBtnSmall: {
        fontSize: 12,
        color: '#0c6be2'
    },
    emptyMessageImage: {
        width: '100%'
    },
    executionCard: {
        width: 250,
        minWidth: 250,
        height: 125,
        padding: '25px 10px 10px 10px',
        background: '#ffffff',
        boxShadow: '0 1px 2px 0 rgba(0,0,0,.15)',
        borderRadius: 2,
        display: 'inline-block',
        cursor: 'pointer',
        position: 'relative',
        float: 'left',
        marginRight: 15,
        marginBottom: 10,
        transition: 'box-shadow .2s, transform .1s',
        '&:hover': {
            boxShadow: '0 7px 8px 0 rgba(0,0,0,.15)',
            transform: 'scale(1.03)'
        }
    },
    executionHeader: {
        display: 'block',
        height: 20,
        background: '#d3d1fb',
        position: 'absolute',
        width: '100%',
        top: 0,
        left: 0,
        borderRadius: '2px 2px 0 0',
        cursor: 'pointer',
    },
    executionTitle: {
        display: 'block',
        fontSize: 14,
        color: theme.palette.primary.three.main,
        '&:hover': {
            textDecoration: 'underline',
            color: theme.palette.primary.three.main,
        },
    },
    executionLastUpdatedTime: {
        fontWeight: 400,
        fontSize: 12,
        position: 'absolute',
        bottom: 8,
        right: 10,
        color: theme.palette.grey.five.main,
        cursor: 'pointer',
    },
    linkButton: {
        fontSize: 12,
        padding: '4px 8px',
        position: 'absolute',
        bottom: 0,
        right: 0,
        '& .MuiButton-endIcon': {
            marginTop: 1,
            marginLeft: 0,
            marginRight: -4,
        }
    },
    loadingIndicator: {
        minHeight: 160,
    },
    offlineButton: {
        position: 'absolute',
        left: 0,
        bottom: 0,
    },
    sectionContent: {
        minHeight: 86,
    }
});

export const ExecutionCard = withStyles(styles)(function ExecutionCard({ classes, executionType, execution, ...rest }) {
    return <ClickableArea
        {...rest}
        data-cy={`${executionType}-link`}
        className={classes.executionCard + ' ' + classes.marginRight20}
        key={execution.id}
    >
        <label className={classes.executionHeader} />
        <TbfSopLink
            className={classes.executionTitle}
            title={execution.title}
            to={`/executions/${execution.rootId}`}
            tabIndex='-1'
            data-expand-click-area
            nodeId={execution.id}
            eventName={REPORT_EVENT_TYPES.dashboardPageMyWorqTabItemClicked.name}
        >
            <TruncateReadMoreText maxLength={85} showMoreDisabled={true}>
                {execution.title}
            </TruncateReadMoreText>
        </TbfSopLink>
        <ExecutionOfflineAction className={classes.offlineButton} executionId={execution.rootId} stopPropagation small showNotAvailableOffline={false} />
        <label className={classes.executionLastUpdatedTime}>
            {moment(execution.lastUpdatedDateTime).fromNow()}
        </label>
    </ClickableArea>
});

const RecentExecutions = withRouter(withStyles(styles)(function RecentExecutions({
    classes,
    history,
    executionType,
    canViewAll,
    executionIds,
}) {

    const executionTypePlural = RecentExecutionConfig[executionType].plural;

    const handleViewAll = () => {
        history.push(`/list/${executionType}`);
    }

    const executions = useNodesIfPresent(executionIds);

    return <div className={cn(classes.container, {
        [classes.paddingBottom28]: executions.length > 0
    })}>
        <div className={classes.sectionContent}>
            {
                executions.length === 0 && (
                    <EmptyMessage
                        message='Nothing here yet'
                        secondaryMessage={
                            <span>
                                The most relevant {executionTypePlural} for you will show up here.
                                {canViewAll && <Link to={`/list/${executionType}`} className={cn(classes.linkBtnSmall, classes.marginLeft10)}>
                                    {`View all ${executionTypePlural} >`}
                                </Link>}
                            </span>
                        }
                    >
                        <img className={classes.emptyMessageImage} src={EmptyWorkspaceImage} aria-colcount={''} />
                    </EmptyMessage>
                )
            }
            {
                executions.length > 0 &&
                <SmoothHorizontalScroller scrollStep={270} containerProps={{ role: 'list' }} >
                    {executions.map((execution, index) => <ExecutionCard key={index} execution={execution} executionType={executionType} role="listitem" />)}
                </SmoothHorizontalScroller>
            }
            {canViewAll && <Button
                variant='contained'
                title={strings.buttons.viewAll}
                color='secondary'
                size="small"
                className={classes.linkButton}
                endIcon={<ChevronRight />}
                onClick={handleViewAll}>
                {strings.buttons.viewAll}
            </Button>}
        </div>
    </div>
}));

export default RecentExecutions;