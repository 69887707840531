import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../style/alert.css';
import * as PropTypes from "prop-types";
import {ComponentBase} from "tbf-react-library";
import ExecutionLink from "./ExecutionLink";

class ExecutionReferenceBadge extends ComponentBase {

    render() {
        let {executionLoaded, executionId, executionTitle} = this.props;
        return (
            <span>
                {
                    executionLoaded &&
                    <ExecutionLink nodeId={executionId}>
                        {executionTitle}
                    </ExecutionLink>
                }
                {
                    !executionLoaded &&
                    <span>Not loaded</span>
                }
            </span>
        )
    }
}

const styles = () => ({});
ExecutionReferenceBadge.propTypes = {
    executionId: PropTypes.string.isRequired
};
const mapStateToProps = (state, ownProps) => {
    let execution = getNodeOrNull(state, ownProps.executionId);
    return {
        executionLoaded: !!execution,
        executionId: execution ? execution.id : null,
        executionTitle: execution ? execution.title : null,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionReferenceBadge));
