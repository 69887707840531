import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../style/alert.css';
import PageComponentBase from "../PageComponentBase";
import {reportUserError, SharedAuth} from "tbf-react-library";
import {Permissions} from "../../permissions";
import Loader from "../components/Loader";
import {searchHistory} from "../../actions/history";
import ReactDataGrid from "react-data-grid";
import {Data, Filters, Toolbar} from "react-data-grid-addons";
import {formatDay, formatTime} from "../../util/util";

const {
    NumericFilter,
    AutoCompleteFilter,
    MultiSelectFilter
} = Filters;

class HistoryStream extends PageComponentBase {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: false
        }
        this.addFilter = this.addFilter.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.getValidFilterValues = this.getValidFilterValues.bind(this);
    }

    componentWillUnmount() {
        this.mouted = false;
    }

    componentDidMount() {
        let {rootId, rootType} = this.props;
        this.mouted = true;
        this.setState({loading: true});
        searchHistory(rootId, rootType).then(response => {
            if (this.mouted) {
                let rows = response.data?.items || response.data;
                for (let row of rows) {
                    row.changeDate = (row.changeDateTime && formatDay(row.changeDateTime)) || '';
                    row.changeTime = (row.changeDateTime && formatTime(row.changeDateTime)) || '';
                    row.name = (row.area || []).join(' -> ');
                    // Filter code does not deal with nulls
                    for (let propertyName of Object.keys(row)) {
                        if (row[propertyName] == null) {
                            row[propertyName] = '';
                        }
                    }
                }
                this.setState({
                    loading: false,
                    items: response.data
                });
            }
        }).catch(error => {
            reportUserError(error, null, 'Loading history failed.');
            if (this.mouted) {
                this.setState({loading: false});
            }
        });
    }

    getValidFilterValues(rows, columnId) {
        let validValues = rows
            .map(r => r[columnId])
            .filter(a => a !== null && a !== undefined);
        return [...new Set(validValues)];
    }

    addFilter(newFilter) {
        let {filters} = this.state;
        let updateFilters = {...filters};
        if (newFilter.filterTerm) {
            updateFilters[newFilter.column.key] = newFilter;
        } else {
            delete updateFilters[newFilter.column.key];
        }
        this.setState({filters: updateFilters});
    }

    clearFilters() {
        this.setState({filters: null});
    }

    render() {
        let {items, loading, filters} = this.state;
        let groupedRows = Data.Selectors.getRows({rows: items, groupBy, filters})
        return (
            <React.Fragment>
                {
                    loading &&
                    <Loader/>
                }
                <ReactDataGrid
                    columns={columns}
                    rowGetter={(i) => groupedRows[i]}
                    rowsCount={groupedRows.length}
                    toolbar={<Toolbar enableFilter={true}/>}
                    onAddFilter={this.addFilter}
                    onClearFilters={this.clearFilters}
                    enableRowSelect={null}
                    minWidth={'100%'}
                    getValidFilterValues={columnKey => this.getValidFilterValues(items, columnKey)}
                    rowScrollTimeout={null}
                />
            </React.Fragment>);
    }
}

const columns = [
    {key: "changeTime", name: "Time", editable: false, width: 80, resizable: true, filterable: true},
    {
        key: "changeVersion",
        name: "Version",
        editable: false,
        width: 80,
        resizable: true,
        filterable: true,
        filterRenderer: NumericFilter
    },
    {
        key: "userName",
        name: "Who",
        editable: false,
        width: 160,
        resizable: true,
        filterable: true,
        filterRenderer: MultiSelectFilter
    },
    {
        key: "nodeId",
        name: "Id",
        editable: false,
        width: 200,
        resizable: true,
        filterable: true,
        filterRenderer: AutoCompleteFilter
    },
    {
        key: "name",
        name: "Name",
        editable: false,
        width: 300,
        resizable: true,
        filterable: true,
        filterRenderer: AutoCompleteFilter
    },
    {
        key: "type",
        name: "Change",
        editable: false,
        width: 160,
        resizable: true,
        filterable: true,
        filterRenderer: MultiSelectFilter
    },
    {
        key: "nodeType",
        name: "Type",
        editable: false,
        width: 160,
        resizable: true,
        filterable: true,
        filterRenderer: MultiSelectFilter
    },
    {
        key: "property",
        name: "Property",
        editable: false,
        width: 160,
        resizable: true,
        filterable: true,
        filterRenderer: MultiSelectFilter
    },
    {key: "before", name: "Before", editable: false, width: 300, resizable: true, filterable: true},
    {key: "after", name: "After", editable: false, width: 300, resizable: true, filterable: true},
];
const groupBy = ["changeDate"];

const styles = () => ({
    day: {
        marginBottom: '1em',
    },
});
HistoryStream.propTypes = {};
const mapStateToProps = () => {
    return {
        canAssign: SharedAuth.userHasPermission(Permissions.execution.read)
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HistoryStream));
