import React, { useMemo } from "react";
import { useRulesForNode } from "../../../hooks/nodeHooks";
import withStyles from '@mui/styles/withStyles';
import { RULE_ACTION_TYPE } from "../../../reducers/graphReducer";
import RuleCopiedToNodesList from "./RuleCopiedToNodesList";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const actionTypes = Object.values(RULE_ACTION_TYPE);

const ExecutionRulesChildRulesList = ({classes, ruleId}) => {
    const childRules = useRulesForNode(ruleId);

    const childRuleActions = useMemo(() => {
        const rules = [];
        for (const childRule of childRules) {
            for (const actionType of actionTypes) {
                if (childRule[actionType.id] === true) {
                    switch (actionType.id) {
                        case RULE_ACTION_TYPE.copyToOn.id:
                            rules.push({
                                name: actionType.name,
                                calculate: childRule.calculateValueOn ? childRule.calculateValueQuery : null,
                                calculateDateTime: childRule.calculateValueOn ? childRule.calculateValueDateTime : null,
                                rule: childRule,
                                actionType: actionType,
                            });
                        default:
                            continue;
                    }
                }
            }
        }
        return rules;
    }, [childRules])

    return <List className={classes.list}>
        {childRuleActions?.map((action) => {
            if (action.actionType.id === RULE_ACTION_TYPE.copyToOn.id) {
                return <ListItem className={classes.listItem}>
                    <RuleCopiedToNodesList key={action.rule.id} ruleId={action.rule.id} />
                </ListItem>;
            }
            return null;
        })}
    </List>
}

const styles = () => ({
    list: {
        margin: 0,
        padding: 0,
    },
    listItem: {
        margin: 0,
        padding: 0,
    }
});

export default withStyles(styles)(ExecutionRulesChildRulesList);