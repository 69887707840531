import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../../style/alert.css';
import {withRouter} from "react-router-dom";
import * as PropTypes from "prop-types";
import VerticalSplitIcon from '@mui/icons-material/VerticalSplitRounded';
import {strings} from "../../components/SopLocalizedStrings";
import {putNodeProperty} from "../../../actions";
import {ComponentBase} from "tbf-react-library";
import ImageSearchIcon from '@mui/icons-material/ImageSearchRounded';
import Button from "@mui/material/Button";

const pageStrings = strings.procedure.show;

class ProcedurePreviewToggleButton extends ComponentBase {

    handlePreviewClick = () => {
        let {procedureId, onPutNodeProperty, history} = this.props;
        onPutNodeProperty({id: procedureId, sideBySidePreviewOn: false});
        history.push('/procedures/' + procedureId + '/preview');
    };

    handleSideBySideClick = () => {
        let {procedureId, onPutNodeProperty, history} = this.props;
        onPutNodeProperty({id: procedureId, sideBySidePreviewOn: true});
        history.push('/procedures/' + procedureId);
    };

    render() {
        const {classes, fullPage} = this.props;

        return (
            <React.Fragment>
                {
                    !fullPage &&
                    <Button color={'inherit'} onClick={this.handlePreviewClick}
                            title={pageStrings.previewButtonTooltip}
                            className={classes.previewButton}
                            data-cy='preview-procedure'>
                        <ImageSearchIcon/>
                    </Button>
                }
                {
                    fullPage &&
                    <Button color={'inherit'} onClick={this.handleSideBySideClick}
                            title={pageStrings.previewSideBySideButtonTooltip}
                            className={classes.previewButton}
                            data-cy='live-preview-procedure'>
                        <VerticalSplitIcon/>
                    </Button>
                }
            </React.Fragment>
        )
    }
}

const styles = (theme) => ({
    previewButton: {
        color: theme.palette.primary.one.main,
    }
});
ProcedurePreviewToggleButton.propTypes = {
    procedureId: PropTypes.string.isRequired
};
const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProcedurePreviewToggleButton)));
