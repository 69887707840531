import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodeSchemaOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../style/alert.css';
import {putNode, putNodeProperty} from "../../actions";
import {Link, withRouter} from "react-router-dom";
import * as PropTypes from "prop-types";
import {createNode} from "../../factory/graphFactory";
import ExecutionShow from "../execution/ExecutionShow";
import Loader from "../components/Loader";
import {strings} from "../components/SopLocalizedStrings";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import PageComponentBase from "../PageComponentBase";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import ProcedurePreviewToggleButton from "./actions/ProcedurePreviewToggleButton";
import {getExecutionPreviewReset} from "../../factory/executionFactory";

const pageStrings = strings.procedure.preview;

class ProcedurePreview extends PageComponentBase {


    constructor(props, context) {
        super(props, context);
        this.prepare = this.prepare.bind(this);
        this.reset = this.reset.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.prepare();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.prepare();
    }

    reset = () => {
        let {executionPreviewId, onPutNodeProperty} = this.props;
        onPutNodeProperty(getExecutionPreviewReset(executionPreviewId));
    };

    handleClose = () => {
        let {procedureId, onPutNodeProperty} = this.props;
        onPutNodeProperty({id: procedureId, sideBySidePreviewOn: false});
    };

    prepare = () => {
        let {
            executionPreviewSchema,
            executionPreviewId,
            executionPreviewNode,
            procedureId,
            loaded,
            onPutNode
        } = this.props;
        if (!executionPreviewNode && loaded) {
            // Lets always create a preview so that we can secretly test upgrade
            // as the procedure is built.
            let previewAttr = {
                id: executionPreviewId,
                procedureId: procedureId
            };
            let node = createNode(executionPreviewSchema, previewAttr);
            onPutNode(node);
        }
    };

    render() {
        let {executionNode, procedureId, classes, fullPage} = this.props;
        return (
            <React.Fragment>
                <GraphResourceLoad
                    friendlyName={strings.procedure.name}
                    nodeId={procedureId}
                    nodeType={'ProcedureRoot'}
                >
                    {
                        <Toolbar disableGutters={true} className={'alert alert-info'}>
                            <Typography variant="h1" color="inherit" noWrap>
                                <span>{strings.labels.preview}</span>
                            </Typography>
                            <div className={classes.grow}/>
                            <ProcedurePreviewToggleButton fullPage={fullPage} procedureId={procedureId}/>
                            <span className={classes.toolbarLink}>
                                    <Button color='inherit' className={classes.button} onClick={this.reset}
                                            title={pageStrings.resetButton}
                                            data-cy='reset-preview'>
                                        <RefreshIcon/>
                                    </Button>
                            </span>
                            <span>
                                    {
                                        fullPage &&
                                        <Link to={`/procedures/${procedureId}`} className={classes.toolbarLink}>
                                            <Button color='inherit' className={classes.button}
                                                    title={pageStrings.closeButton} data-cy='close-preview'>
                                                <CloseIcon/>
                                            </Button>
                                        </Link>
                                    }
                                {
                                    !fullPage &&
                                    <Button color='inherit' className={classes.button} onClick={this.handleClose}
                                            title={pageStrings.closeButton}
                                            data-cy='close-preview'>
                                        <CloseIcon/>
                                    </Button>
                                }
                                </span>
                        </Toolbar>
                    }
                    {
                        executionNode &&
                        <ExecutionShow executionId={executionNode.id} key={executionNode.id}/>
                    }
                    {
                        !executionNode &&
                        <Loader source={strings.labels.preview} friendlyName={strings.labels.preview}/>
                    }
                </GraphResourceLoad>
            </React.Fragment>
        )
    }
}

const styles = () => ({
    grow: {
        flexGrow: 1,
    },
    toolbarLink: {
        paddingLeft: 0
    },
});
ProcedurePreview.propTypes = {
    procedureId: PropTypes.string.isRequired,
    fullPage: PropTypes.bool
};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.procedureId;
    let procedure = getNodeOrNull(state, nodeId);
    let previewNodeId = 'preview-procedure-' + nodeId;
    let procedurePreviewNode = getNodeOrNull(state, previewNodeId);
    let execution = (procedurePreviewNode && procedurePreviewNode.executionId &&
        getNodeOrNull(state, procedurePreviewNode.executionId)) || null;
    return {
        loaded: procedure && procedure.loaded,
        name: procedure && procedure.name,
        executionPreviewId: previewNodeId,
        executionPreviewSchema: getNodeSchemaOrNull(state, 'ExecutionPreview'),
        executionPreviewNode: procedurePreviewNode,
        executionNode: execution,
        fullPage: !!ownProps.fullPage
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNode: nodes => dispatch(putNode(nodes)),
        onPutNodeProperty: nodes => dispatch(putNodeProperty(nodes)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProcedurePreview)));
