import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {withRouter} from "react-router-dom";
import PageComponentBase from "../PageComponentBase";
import RecentActivities from "./RecentActivities";
import {Breadcrumbs, DESKTOP_MIN_WIDTH, PageContentWrapper, PageHeader} from "tbf-react-library";
import {strings} from "../components/SopLocalizedStrings";
import Album from "@mui/icons-material/AlbumRounded";
import RecentAssignments from "./RecentAssignments";
import RecentExecutionsTabs from './RecentExecutionsTabs';

class Dashboard extends PageComponentBase {

    render() {
        let {classes} = this.props;
        return <>
            <PageHeader
                title={strings.dashboard.name}
                PageIcon={<Album/>}
                showMenuToggle={true}/>
            <PageContentWrapper>
                <Breadcrumbs list={[]}/>
                <div className={classes.innerWrapper}>
                    <RecentExecutionsTabs className={classes.executionsContainer} />
                    <div className={classes.grid}>
                        <RecentAssignments className={classes.recentContainer}/>
                        <RecentActivities className={classes.recentContainer}/>
                    </div>
                </div>
            </PageContentWrapper>
        </>
    }
}

const styles = (theme) => ({
    innerWrapper: {
        width: '100%',
        maxWidth: 1200,
        margin: '0 auto'
    },
    sectionDivider: {
        width: '100%',
        float: 'left',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: theme.spacing(1),
        float: 'left',
        width: '100%',
        [theme.breakpoints.up(DESKTOP_MIN_WIDTH)]: {
            gridTemplateColumns: '1fr 1fr'
        }
    },
    executionsContainer: {
        minHeight: 220,
        float: 'left',
        position: 'relative',
    },
    recentContainer: {
        minHeight: 'calc(100vh - 450px)',
        '@media (min-height: 900px)': {
            minHeight: 'calc(100vh - 800px)',
        },
    },
});

export default withStyles(styles)(withRouter(Dashboard));
