import React from "react";
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import {useOfflineEnabled, usePendingUserSaveNodeIds} from "../../hooks/nodeHooks";
import {clearGraph} from "../../actions";
import {useDispatch} from "react-redux";
import {reportError} from "tbf-react-library";

const ExecutionDraftErrorBanner = ({executionId, procedureId}) => {
    const pendingUserSaveNodeIds = usePendingUserSaveNodeIds();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if(!procedureId) {
            return;
        }

        reportError(`User is attempting to view a draft execution for ${procedureId}`);
    }, [procedureId])

    const hasOfflineData = useOfflineEnabled();

    const hasChangesOrOfflineData =  pendingUserSaveNodeIds.includes(executionId) || hasOfflineData;
    let message = 'This record is in an invalid state. After pending saves are synched and you have are online please logout and back in.';

    if(!hasChangesOrOfflineData) {
        message = 'This record is in an invalid state. Please';
    }

    const onReload = React.useCallback(() => {
        dispatch?.(clearGraph())
        window.location.reload()
    }, [dispatch])

    return(
        <Alert severity="warning">
            {message}
            {!hasChangesOrOfflineData && <Button data-cy={"reload-app"} onClick={onReload} variant="text">Reload App</Button>}
        </Alert>
    )
}

export default  ExecutionDraftErrorBanner;