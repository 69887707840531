import { SharedAuth } from "tbf-react-library";

let db = null;
const dbVersion = 3;
const storeName = "nodes";
const keyPath = "id";
export const indexScopeName = "by_scopes";
const indexScopePath = "node.scopes";
export const indexDirtyName = "by_dirty";
const indexDirtyPath = "dirtyNode.dirtyIndexed";
const indexRootIdName = "by_rootId";
const indexRootIdPath = "node.rootId";
export const indexStoredDateTimeName = "by_storedDateTime";
const indexStoredDateTimePath = "storedDateTime";
let previousVersionNumber = null;
let mockIndexedDbError = null;
export const setMockError = err => mockIndexedDbError = err;
export const hasMockError = () => !!mockIndexedDbError;
let noImageCache = false;
export const setNoImageCache = (val) => noImageCache = val;
export const cacheImage = () => !noImageCache;
let operationCounters = {};
const incrementOperationCount = (name, times) => {
    operationCounters[name] = (operationCounters[name] || 0) + times
};
export const resetOperationCounters = () => {
    operationCounters = {
        'put': 0,
        'put all': 0
    };
};
export const isIndexedDbAvailable = () => {
    return !SharedAuth.isAnonymous();
}
resetOperationCounters();
let usingDbName = null;
let reportError = (...args) => console.error(...args)
let reportDebug = (...args) => console.debug(...args)
const getTime = () => Date.now()
export const initialiseDbUser = (options) => {
    const {clientId, userId, tenantId} = options;
    reportError = options.reportError ?? reportError
    reportDebug = options.reportDebug ?? reportDebug
    if (usingDbName && !options.ignoreExisting) {
        return
    }
    let dbName = 'graph_' + userId + '_' + clientId + '_' + tenantId;
    dbName = dbName.toLowerCase().replace(/[^a-z0-9_]/g, '');
    if (usingDbName !== null && dbName !== usingDbName) {
        throw new Error("Authenticated user has changed, this is not supported. Current Database: " + usingDbName + " New Database: " + dbName);
    }
    const overrideName = localStorage.getItem('sop_indexeddb_name');
    if (overrideName) {
        dbName = overrideName;
    }
    usingDbName = dbName;
    return usingDbName;
}
export const dbName = () => {
    if (!usingDbName) {
        throw new Error("Db not yet initialised");
    }
    return usingDbName;
};
export const uninitialiseIndexedDb = () => {
    usingDbName = null;
}
export const isIndexedDbInitialised = () => {
    return usingDbName !== null;
}
export const getOperationCounters = () => operationCounters;
const openDb = () => new Promise((resolve, reject) => {
    // For testing
    if (mockIndexedDbError) {
        setTimeout(() => reject(window.mockIndexedDbError || mockIndexedDbError), 100);
        return;
    }
    const request = indexedDB.open(dbName(), dbVersion);
    request.onsuccess = function () {
        db = request.result;
        resolve(db);
    };
    request.onerror = function () {
        let error = request.error;
        reportError('Error opening indexeddb ' + (error && error.message), error);
        reject(error);
    };
    request.onupgradeneeded = function (event) {
        let db = event.target.result;
        previousVersionNumber = event.oldVersion;
        let objectStore;
        if (!db.objectStoreNames.contains(storeName)) {
            // Create an objectStore
            objectStore = db.createObjectStore(storeName, {keyPath: keyPath});
        } else {
            // Get Store
            objectStore = request.transaction.objectStore(storeName);
        }

        // Version 1 is from 2020, no idea why firefox is using it for ag
        // Index the scope field for selective loading
        if (!objectStore.indexNames.contains(indexScopeName)) {
            objectStore.createIndex(indexScopeName, indexScopePath, {unique: false, multiEntry: true});
        }
        // Index the scope field for selective loading
        if (!objectStore.indexNames.contains(indexDirtyName)) {
            objectStore.createIndex(indexDirtyName, indexDirtyPath);
        }
        if (!objectStore.indexNames.contains(indexRootIdName)) {
            objectStore.createIndex(indexRootIdName, indexRootIdPath);
        }
        if (!objectStore.indexNames.contains(indexStoredDateTimeName)) {
            objectStore.createIndex(indexStoredDateTimeName, indexStoredDateTimePath);
        }

        // Use transaction oncomplete to make sure the objectStore creation is
        // finished before adding data into it.
        objectStore.transaction.oncomplete = function () {
            resolve(db);
        };
    };
});
const deleteDb = (name) => new Promise((resolve, reject) => {
    name = name || dbName();
    let request = window.indexedDB.deleteDatabase(name);
    request.onerror = function () {
        let error = request.error;
        reject(error);
    };
    request.onsuccess = function () {
        resolve();
    };
});

const perform = (action, name, txMode) => {
    incrementOperationCount(name, 1);
    return openDb().then((db) => {
        return new Promise((resolve, reject) => {
            const start = getTime();
            const tx = db.transaction([storeName], txMode);
            const store = tx.objectStore(storeName);
            const request = action(store);
            request.onsuccess = function (event) {
                const end = getTime();
                name && reportDebug(`IndexedDb completed ${name} in ${end - start}ms.`);
                db.close();
                resolve(event.target.result);
            };
            request.onerror = function () {
                let error = request.error;
                db.close();
                reject(error);
            };
        });
    });
};
export const performCursor = (action, name, txMode, projection, limit = null) => {
    return openDb().then((db) => {
        return new Promise((resolve, reject) => {
            const start = getTime();
            const tx = db.transaction([storeName], txMode);
            const store = tx.objectStore(storeName);
            const request = action(store);
            const results = [];
            request.onsuccess = function (event) {
                let cursor = event.target.result;
                if (cursor && (limit === null || !limit(cursor.value))) {
                    const value = projection ? projection(cursor.value) : cursor.value;
                    results.push(value);
                    cursor.continue();
                } else {
                    const end = getTime();
                    name && reportDebug(`IndexedDb completed ${name} in ${end - start}ms  returning ${results.length} results.`);
                    db.close();
                    incrementOperationCount(name, results.length);
                    resolve(results);
                }
            };
            request.onerror = function () {
                let error = request.error;
                db.close();
                reject(error);
            };
        });
    });
};

const errorHandler = ({reject, e, errorType, errors, tx}) => {
    errors.push(errorType)
    const useError = e?.target?.error || tx.error
    if (useError) {
        const isDomException = useError instanceof DOMException;
        errors.push(isDomException ? useError.name : useError)
    }
    reject(errors.join(","));
}

const performMany = (action, name, times, txMode, nodes) => {
    incrementOperationCount(name, times);
    return openDb().then((db) => {
        return new Promise((resolve, reject) => {
            const start = getTime();
            const tx = db.transaction([storeName], txMode);
            const store = tx.objectStore(storeName);
            const requests = action(store);
            let results = [];
            const errors = [];
            const bulk = nodes.length > 5
            if (bulk && requests.length) {
                const lastRequest = requests[requests.length - 1]
                lastRequest.onsuccess = function () {
                    results = nodes
                };
                lastRequest.onerror = function () {
                    let error = lastRequest.error;
                    errors.push(error);
                };
            } else {
                requests.forEach(request => {
                    request.onsuccess = function (event) {
                        results.push(event.target.result);
                    };
                    request.onerror = function () {
                        let error = request.error;
                        errors.push(error);
                    };
                });
            }
            tx.oncomplete = () => {
                const end = getTime();
                name && reportDebug(`IndexedDb completed ${name} in ${end - start}ms returning ${results.length} results.`);
                db.close();
                if (errors.length > 0) {
                    reject(errors);
                } else {
                    resolve(results);
                }
            };
            tx.addEventListener('error', (e) => errorHandler({reject, e, errorType: 'Transaction error.', errors, tx}))
            tx.addEventListener('abort', (e) => errorHandler({reject, e, errorType: 'Transaction aborted.', errors, tx}))
        });
    });
};

export const getDbNode = (id) => perform(store => store.get(id), 'get', 'readonly');
export const storeDbNode = (node) => perform(store => store.put(node), 'put', 'readwrite');
export const storeDbNodes = (nodes) => performMany(store => nodes.map(node => store.put(node)), 'put all', nodes.length, 'readwrite', nodes);
export const deleteDbNode = (id) => perform(store => store.delete(id), 'delete', 'readwrite');
export const deleteDbNodes = (ids) => performMany(store => ids.map(node => store.delete(node)), 'delete by ids', ids.length, 'readwrite', ids);
export const getDbNodes = () => performCursor(store => store.openCursor(), 'get all', 'readonly');
export const getDbNodeByScope = (scope) => performCursor(store => store.index(indexScopeName).openCursor(IDBKeyRange.only(scope)), 'get by scope [' + scope + ']', 'readonly');
export const getDbNodeByDirty = () => performCursor(store => store.index(indexDirtyName).openCursor(IDBKeyRange.only(1)), 'get by dirty', 'readonly');
export const getDbNodeByRootId = (rootId) => performCursor(store => store.index(indexRootIdName).openCursor(IDBKeyRange.only(rootId)), 'get by rootId [' + rootId + ']', 'readonly');
export const patchDbNodesIfPresent = (ids, patch) => {
    let promises = ids.map(id => {
        return getDbNode(id)
            .then(node => {
                if (node) {
                    const patched = patch(node);
                    return storeDbNode(patched);
                }
            })
            .catch((err) => {
                // If not found that is ok
                console.info(err);
            })
    });
    return Promise.all(promises);
}
export const clearDb = (name) => deleteDb(name);
export const countDb = () => perform(store => store.count(), 'count', 'readonly');
export const getPreviousVersionNumber = () => previousVersionNumber;
export const setDbNameUnitTest = name => usingDbName = name;
export const isDbConnected = () => !!usingDbName;