import {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeSchemaOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {putNode} from "../../actions";
import {createNode} from "../../factory/graphFactory";
import {getJsonDate} from "../../util/util";
import {NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {SharedAuth} from "tbf-react-library";

class NodeVisited extends Component {

    alreadyRecorded = false;

    componentDidMount() {
        this.recordView();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        this.recordView();
    }

    recordView() {
        let {nodeId, nodeType, schema, onPutNode} = this.props;
        if (!this.alreadyRecorded && nodeType !== null && !SharedAuth.isAnonymous()) {
            let attributes = {
                viewedDateTime: getJsonDate(),
                viewType: nodeType,
                viewedId: nodeId
            }
            let viewNode = createNode(schema, attributes);
            onPutNode(viewNode);
            this.alreadyRecorded = true;
        }
    }

    render() {
        return null;
    }
}

const styles = () => ({});
NodeVisited.propTypes = {};
const mapStateToProps = (state) => {
    return {
        schema: getNodeSchemaOrNull(state, NODE_TYPE_OPTIONS.NodeView)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNode: node => dispatch(putNode(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeVisited));
