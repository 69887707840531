import {strings} from "../layouts/components/SopLocalizedStrings";
import {hasValue, parseFloatOrNull} from "./util";
import {getPositions} from "../layouts/MapView/helpers";

export const convertGeoJsonFeatureToText = feature => {
    if (!feature) {
        return '';
    }
    let coordinates = (feature && feature.geometry && feature.geometry.coordinates) || [''];
    if (!coordinates) {
        return "";
    }
    let parts = [];
    const height = coordinates.slice(2).map(a => a + "m");
    let coordinatesOrdered = [...coordinates.slice(0, 2).reverse(), ...height];
    parts.push(coordinatesOrdered.join(' '));
    if (feature.properties?.accuracy) {
        parts.push(strings.location.accuracyPrefix + feature.properties.accuracy + strings.location.accuracyPostfix);
    }
    if (feature.properties?.name) {
        parts.push(feature.properties.name);
    }
    return parts.join(' ');
}

export const convertTextToGeoJsonFeature = text => {
    if (!hasValue(text)) {
        return null;
    }

    // #1 COORDINATES
    let parts = text.split(/[,\s]+/);
    let coordinates = parts.slice(0, 3).map(a => parseFloatOrNull(a));

    // #2 COORDINATES HEIGHT
    let thirdPositionIsHeight = parts.length >= 3 && parts[2].match('^[0-9,.]+m$');
    let remainder;
    if (thirdPositionIsHeight) {
        remainder = parts.slice(3);
    } else {
        coordinates = coordinates.slice(0, 2);
        remainder = parts.slice(2);
    }

    // Store as lng lat
    if (coordinates.length === 0) {
        coordinates.push(null);
        coordinates.push(null);
    } else if (coordinates.length === 1) {
        coordinates.unshift(null);
    } else {
        coordinates = [coordinates[1], coordinates[0], ...coordinates.slice(2)];
    }

    let feature = {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": coordinates
        },
        "properties": {
            "text": text
        }
    };

    // #3 ACCURACY +-1000m
    let nextIsAccuracy = remainder.length > 0 && remainder[0].startsWith(strings.location.accuracyPrefix);
    if (nextIsAccuracy) {
        feature.properties.accuracy = parseFloatOrNull(remainder[0].substring(1));
        remainder = remainder.slice(1);
    }

    // #4 NAME
    if (remainder.length > 0) {
        feature.properties.name = remainder.join(' ');
    }

    return feature;
}
export const calculateFeatureDistanceInKm = (featureA, featureB) => {
    let getCoordinates = feature => {
        return feature && feature.geometry && feature.geometry.coordinates;
    };
    let coordA = getCoordinates(featureA);
    let coordB = getCoordinates(featureB);
    if (!coordA || !coordB) {
        return null;
    }
    return calculateDistanceFromLatLonInKm(coordA[1], coordA[0], coordB[1], coordB[0]);
};
export const calculateDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371000; // Radius of the earth in meters
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
}
export const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
}
export const formatDistance = (distance) => {
    if (distance == null) {
        return '';
    }
    if (distance >= 10000) {
        let opt = {
            maximumFractionDigits: 0,
            useGrouping: true
        };
        return (distance / 1000).toLocaleString('en', opt) + ' km';
    }
    if (distance >= 1000) {
        let opt = {
            minimumIntegerDigits: 1,
            maximumFractionDigits: 1,
            useGrouping: true
        };
        return (distance / 1000).toLocaleString('en', opt) + ' km';
    }
    return distance.toFixed(0) + ' m';
}

export const checkLocationFeatureInvalid = (feature) => {
    if (!feature) {
        return false
    }
    if (feature.type !== 'Feature' || feature.geometry == null) {
        return true
    }
    if (feature.geometry.type === 'Point') {
        return !feature.geometry
            || !feature.geometry.coordinates
            || feature.geometry.coordinates.filter(a => hasValue(a)).length < 2
    }
    return false
}

export const checkLocationCoordinatesAreInBounds = (feature) => {
    let coords = {latitude: true, longitude: true};
    const positions = getPositions([feature])
    for (let coordinates of positions) {
        if (coordinates[0] > 90 || coordinates[0] < -90) {
            coords.latitude = false;
        }
        if (coordinates[1] > 180 || coordinates[1] < -180) {
            coords.longitude = false;
        }
    }
    return coords;
}
