import React from "react";
import {strings} from "../../components/SopLocalizedStrings";
import GridOnIcon from '@mui/icons-material/GridOn';
import GridOffIcon from '@mui/icons-material/GridOff';
import {NonMobileOnly, TbfAction} from "tbf-react-library";
import AlphaFeature from "../../components/AlphaFeature";

const FieldListToggleButton = React.forwardRef(({className, onClick, on}, ref) => {

    const handleClick = () => {
        onClick?.();
    }

    const useLabel = strings.buttons.fieldList;
    const useIcon = on ? GridOffIcon : GridOnIcon;

    return (
        <AlphaFeature>
            <NonMobileOnly>
                <TbfAction
                    className={className}
                    title={useLabel}
                    dataCy='view-pivot-field-list'
                    iconComponent={useIcon}
                    onClick={handleClick}
                    ref={ref}
                >
                    {useLabel}
                </TbfAction>
            </NonMobileOnly>
        </AlphaFeature>
    )
})

export default FieldListToggleButton;
