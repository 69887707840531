import {
    HELP_POINT_MANUAL_HTML_LOADED,
    HELP_POINTS_DISPLAY_TOGGLE,
    HELP_POINTS_LOADED,
    HELP_POINTS_LOADING
} from '../actions/helpManualActionTypes'
import keyBy from 'lodash/keyBy'
import first from 'lodash/first'

const initialState = {
    loadedHelpPoints: {},
    loadedHelpPointManuals: {},
    isLoadingHelpPoints: false
}

const loadHelpPointReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case HELP_POINTS_LOADING: {
                return {
                    ...state,
                    isLoadingHelpPoints: action.payload
                };
            }
            case HELP_POINTS_LOADED: {
              const helpDocument = first(action.payload.response.items)
              if(helpDocument) {
                return {
                  ...state,
                  loadedHelpPoints: helpDocument.helpPoints ? keyBy(helpDocument.helpPoints, x => x.externalId) : {},
                  isLoadingHelpPoints: false
                }
              } else {
                return state
              }
            }
            case HELP_POINT_MANUAL_HTML_LOADED: {
                return {
                    ...state,
                    loadedHelpPointManuals: {
                        ...state.loadedHelpPointManuals,
                        [action.payload.manualId]: action.payload.response
                    }
                };
            }
            case HELP_POINTS_DISPLAY_TOGGLE: {
                return {
                    ...state,
                    loadedHelpPoints: {
                        ...state.loadedHelpPoints,
                        // [action.payload.helpPoint.helpPointExternalId]: {
                        [action.payload.helpPointExternalId]: {
                            ...state.loadedHelpPoints[action.payload.helpPointExternalId],
                            toggleState: action.payload.toggleState
                        }
                    }
                };
            }
            default:
                return state;
        }
    } catch (error) {
        throw error;
    }
};

export default loadHelpPointReducer;
