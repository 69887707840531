import React from "react";
import withStyles from '@mui/styles/withStyles';
import {formatPercentage} from "../../../util/util";

export const Percentage = ({value}) => {
    const percentage = value == null ? '' : formatPercentage(value)
    return (<span>{percentage}%</span>)
}

const styles = () => ({});
export default withStyles(styles)(Percentage);