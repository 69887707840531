import {NODE_TYPE_OPTIONS, RULE_ACTION_TYPE} from "../reducers/graphReducer";
import {getNodeOrError, getNodeOrNull, getNodesByType, getNodesIfPresent, getNodesOrError} from "./graphSelectors";
import {EMPTY_ARRAY} from "../util/util";

export const getActiveMentionedRules = (state, nodeId) => {
    return getActiveRulesForNode(state, nodeId).filter(a => !a.draft);
}
export const getActiveChildrenRules = (state, nodeId) => {
    return getActiveMentionedRules(state, nodeId);
}
export const getRulesForNode = (state, nodeId) => {
    const node = getNodeOrError(state, nodeId);
    let rules;
    if (node.ruleIds === null) {
        rules = EMPTY_ARRAY;
    } else if (node.ruleIds) {
        rules = getNodesOrError(state, node.ruleIds || []);
    } else {
        let execution = getNodeOrError(state, node.rootId);
        rules = getNodesOrError(state, execution.rules || []).filter(a => (a.nodeIds || []).includes(node.id));
    }
    return rules;
}
export const getRulesForNodeIfPresent = (state, nodeId) => {
    const node = getNodeOrNull(state, nodeId);
    if (!node) {
        return [];
    }
    let rules;
    if (node.ruleIds === null) {
        rules = EMPTY_ARRAY;
    } else if (node.ruleIds) {
        rules = getNodesIfPresent(state, node.ruleIds || []);
    } else {
        let execution = getNodeOrNull(state, node.rootId);
        rules = getNodesIfPresent(state, execution.rules || []).filter(a => (a.nodeIds || []).includes(node.id));
    }
    return rules;
}
export const getRulesIfPresent = (state, nodeId) => {
    const node = getNodeOrNull(state, nodeId);
    if (!node) {
        return [];
    }
    let rules = [];
    rules.push(...getNodesIfPresent(state, node.ruleIds || []));
    let execution = getNodeOrNull(state, node.rootId);
    rules.push(...getNodesIfPresent(state, execution?.rules || []));
    return rules;
}
export const getRulesWithoutNode = (state, nodeId) => {
    if (nodeId == null) {
        return [];
    }
    const node = getNodeOrNull(state, nodeId);
    let execution = getNodeOrNull(state, node?.rootId);
    return getNodesIfPresent(state, execution?.rules || []).filter(a => (a.nodeIds || []).length === 0);
}
export const getRulesForNodeByActionIfPresent = (state, nodeId, actionType) => {
    const nodes = getRulesForNodeIfPresent(state, nodeId);
    if (Array.isArray(actionType)) {
        return nodes.filter(a => actionType.includes(a.actionType));
    }
    return nodes.filter(a => a.actionType === actionType);
}

export const getRulesByActionIfPresent = (state, nodeId, actionType) => {
    const nodes = getRulesIfPresent(state, nodeId);
    if (Array.isArray(actionType)) {
        return nodes.filter(a => actionType.includes(a.actionType));
    }
    return nodes.filter(a => a.actionType === actionType);
}

export const getEnabledRulesForNodeByActionIfPresent = (state, nodeId, actionType) => {
    return getRulesForNodeByActionIfPresent(state, nodeId, actionType).filter(a => !!a.evaluatedValue);
}

export const getSubRulesForNodeByActionIfPresent = (state, nodeId, actionType) => {
    const rules = getRulesForNodeIfPresent(state, nodeId);
    const result = []
    for (let rule of rules) {
        const subRules = getRulesForNodeByActionIfPresent(state, rule.id, actionType)
        for (let subRule of subRules) {
            result.push(subRule)
        }
    }
    return result
}

export const getEnabledSubRulesForNodeByActionIfPresent = (state, nodeId, actionType) => {
    return getSubRulesForNodeByActionIfPresent(state, nodeId, actionType).filter(a => !!a.evaluatedValue);
}

export const getActiveRulesForNode = (state, nodeId) => {
    return getRulesForNode(state, nodeId).filter(a => !a.deleted);
}
export const getActiveRulesForNodeIfPresent = (state, nodeId) => {
    return getRulesForNodeIfPresent(state, nodeId).filter(a => !a.deleted);
}

export const getEnabledRulesForNode = (state, nodeId) => {
    return getActiveRulesForNodeIfPresent(state, nodeId).filter(a => a.evaluatedValue);
}

export const getEnabledRuleForNodeByActionOrNull = (state, nodeId, actionType) => {
    const rule = getActiveRuleForNodeByActionOrNull(state, nodeId, actionType);
    return rule?.evaluatedValue ? rule : null;
}
export const getActiveRuleForNodeByActionOrNull = (state, nodeId, actionType) => {
    let node = getNodeOrNull(state, nodeId);
    if (node == null) {
        return null;
    }
    let childRules = getActiveRulesForNodeIfPresent(state, nodeId)
        .filter(a => a.actionType === actionType && !a.deleted);
    if (childRules.length > 1) {
        throw new Error(`Expected only 0-1 rules for action ${actionType} but found ${childRules.length} for Execution ${node.rootId}.`)
    }
    return childRules.length === 0 ? null : childRules[0];
}
export const getActiveChildRuleByActionOrNull = (state, parentRuleId, actionType) => {
    let parentRule = getNodeOrNull(state, parentRuleId);
    if (parentRule == null) {
        return null;
    }
    let childRules = getActiveRulesForNode(state, parentRuleId)
        .filter(a => a.actionType === actionType && !a.deleted);
    if (childRules.length > 1) {
        throw new Error(`Expected only 0-1 rules for action ${actionType} but found ${childRules.length} for Execution ${parentRule.rootId}.`)
    }
    return childRules.length === 0 ? null : childRules[0];
}
export const getEnabledChildRulesForNodeByActionOrNull = (state, parentRuleId, actionType) => {
    return getActiveChildRulesForNodeByActionOrNull(state, parentRuleId, actionType).filter(a => a.evaluatedValue === true)
}

export const getActiveChildRulesForNodeByActionOrNull = (state, parentRuleId, actionType) => {
   return getActiveHierarchyRulesForNodeByActionOrNull(state, parentRuleId, actionType)
}

export const getActiveRulesForNodeByAction = (state, parentRuleId, actionType) => {
    return getActiveHierarchyRulesForNodeByActionOrNull(state, parentRuleId, actionType, true)
}

const getActiveHierarchyRulesForNodeByActionOrNull = (state, parentRuleId, actionType, applyToParentRules) => {
    const action = RULE_ACTION_TYPE[actionType];
    const nodesToCheck = [parentRuleId];
    let checkingNodeId = parentRuleId;
    if (action.applyToDescendants) {
        while (checkingNodeId != null) {
            let node = getNodeOrNull(state, checkingNodeId);
            if (node && node.parentId && node.id !== node.parentId) {
                nodesToCheck.push(node.parentId);
            }
            checkingNodeId = node?.parentId;
        }
    }
    let rules = [];
    for (let id of nodesToCheck) {
        let parentRules = getActiveRulesForNodeIfPresent(state, id);

        if(applyToParentRules) {
            const possibleChild = parentRules.filter(r => r.actionType === actionType);
            for (let possibleRule of possibleChild) {
                rules.push(possibleRule)
            }
        } else {
            for (let parentRule of parentRules) {
                const possibleChild = getActiveRulesByActionForNode(state, parentRule.id, actionType);
                for (let possibleRule of possibleChild) {
                    rules.push(possibleRule)
                }
            }
        }
    }
    return rules;
}
export const getOnChildRulesForNodeByActionOrNull = (state, parentRuleId, actionType) => {
    let rules = getActiveChildRulesForNodeByActionOrNull(state, parentRuleId, actionType)
    rules = rules.filter(a => a.evaluatedValue)
    return rules;
}
export const getActiveRulesByActionForNode = (state, nodeId, actionType) => {
    let rules = getActiveRulesForNodeIfPresent(state, nodeId);
    return rules.filter(a => a.actionType === actionType && a.deleted === false);
}
export const getRulesByActions = (state, nodeId, actionTypes) => {
    let procedureNode = getNodeOrNull(state, nodeId);
    let rules = getNodesOrError(state, procedureNode?.rules || procedureNode?.ruleIds || []);
    return rules.filter(a => actionTypes.includes(a.actionType));
}
export const getChildRulesByAction = (state, parentRuleId, actionType, includeDraftRule, format) => {
    let parentRule = getNodeOrNull(state, parentRuleId);
    if (!parentRule) {
        return []
    }
    let rules;

    if (includeDraftRule) {
        rules = getNodesByType(state, NODE_TYPE_OPTIONS.ProcedureRule)
            .filter(a => a.actionType === actionType && a.rootId === parentRule.rootId);
        return rules;
    } else if (parentRule.draft && parentRule.type === NODE_TYPE_OPTIONS.ProcedureRule) {
        // This means it will not be recorded in procedure.rules just yet
        rules = getNodesByType(state, NODE_TYPE_OPTIONS.ProcedureRule)
            .filter(a => a.rootId === parentRule.rootId);
    } else if (parentRule.type === NODE_TYPE_OPTIONS.ProcedureRule ||
        parentRule.type === NODE_TYPE_OPTIONS.ProcedureRoot ||
        parentRule.type === NODE_TYPE_OPTIONS.ProcedureStep ||
        parentRule.type === NODE_TYPE_OPTIONS.ProcedureTask ||
        parentRule.type === NODE_TYPE_OPTIONS.ProcedureQuestion
    ) {
        let procedureNode = getNodeOrNull(state, parentRule.rootId);
        rules = getNodesIfPresent(state, procedureNode?.rules || []);
    } else {
        rules = getRulesForNodeIfPresent(state, parentRuleId);
    }
    return rules.filter(a => {
        let sameFormat = true;
        if (format) {
            sameFormat = format === a.format;
        }
        return a.actionType === actionType && a.nodeIds.includes(parentRuleId) && sameFormat;
    });
}
export const getActiveChildRulesByAction = (state, parentRuleId, actionType) => {
    return getChildRulesByAction(state, parentRuleId, actionType).filter(a => !a.deleted)
}
export const getChildRulesByActionIfPresent = (state, parentRuleId, actionType) => {
    let parentRule = getNodeOrNull(state, parentRuleId);
    let rules;
    if (parentRule == null) {
        rules = [];
    } else if (parentRule.draft) {
        // This means it will not be recorded in procedure.rules just yet
        rules = getNodesByType(state, NODE_TYPE_OPTIONS.ProcedureRule)
            .filter(a => a.rootId === parentRule.rootId);
    } else {
        let procedureNode = getNodeOrNull(state, parentRule.rootId);
        rules = getNodesOrError(state, procedureNode?.rules || []);
    }
    return rules.filter(a => a.actionType === actionType && a.nodeIds.includes(parentRuleId));
}
export const getActiveChildRulesByActionIfPresent = (state, parentRuleId, actionType) => {
    return getChildRulesByActionIfPresent(state, parentRuleId, actionType).filter(a => !a.deleted)
}
export const getChildRuleByActionOrNull = (state, parentRuleId, actionType) => {
    let rules = getChildRulesByAction(state, parentRuleId, actionType)
    if (rules.length === 0) {
        return null;
    }
    return rules[0];
}

export const getChildRuleByActionAndFormatOrNull = (state, parentRuleId, actionType, format) => {
    let rules = getChildRulesByAction(state, parentRuleId, actionType, null, format);
    if (rules.length === 0) {
        return null;
    }
    return rules[0];
}

export const getRuleByActionOrNull = (state, nodeId, actionType) => {
    let rules = getRulesByActions(state, nodeId, [actionType]);
    if (rules.length === 0) {
        return null;
    }
    return rules[0];
}

export const isRuleOn = (state, actionType, nodeId) => {
    let thisNodeId = nodeId;
    // Check if a rule is applied to this node or one of its parents
    while (true) {
        // See what rules are on the node
        const rules = getActiveRulesForNodeIfPresent(state, thisNodeId)
            .filter(a => a.evaluatedValue === true);
        if (rules.filter(a => a.actionType === actionType).length) {
            return true;
        }
        // See if the rule has children of it's own
        for (let rule of rules.filter(a => a.actionType === RULE_ACTION_TYPE.block.id)) {
            const childRules = getActiveRulesForNodeIfPresent(state, rule.id)
                .filter(a => a.evaluatedValue === true && a.actionType === actionType);
            if (childRules.length) {
                return true;
            }
        }
        const thisNode = getNodeOrError(state, thisNodeId);
        if (!thisNode.parentId || thisNode.id === thisNode.parentId) {
            break;
        }
        thisNodeId = thisNode.parentId;
    }
    return false;
}

export const getProcedureChildRulesByAction = (state, procedureId, actionType) => {
    const procedureNode = getNodeOrNull(state, procedureId);
    const rules = getNodesOrError(state, procedureNode?.rules || []);
    return rules.filter(a => a.actionType === actionType);
}

export const getNodesRulesByAction = (state, nodeIds, actionType) => {
    const rules = [];
    nodeIds.forEach(nodeId => {
        const rule = getChildRuleByActionOrNull(state, nodeId, actionType);
        if(rule) {
            rules.push({...rule, nodeId});
        }
    });

    return rules;
}