import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../../../style/alert.css';
import {putNodeProperty, putNodesProperty, reportNodeEvent} from "../../../../actions";
import {getDirtiesById, getNodeOrNull, getNodesById, getNodesIfPresent} from "../../../../selectors/graphSelectors";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import * as PropTypes from "prop-types";
import keyBy from "lodash/keyBy";
import ExecutionPhotoCarousel from './ExecutionPhotoCarousel';
import {ComponentBase, reportUserError} from "tbf-react-library";
import CloseIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/GetAppRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import {arrayMove, EMPTY_ARRAY, existsInArrayByKey, hasValue, isEmptyObject} from "../../../../util/util";
import Loader from "../../../components/Loader";
import {
    setFileDeleteCompleted,
    setFileDeleteSnackbarToggleState,
    setFileDeleteSnackbarUndo
} from "../../../../actions/appActions";
import fileDownload from 'js-file-download';
import ExecutionQuestionAddAttachment from "./ExecutionQuestionAddAttachment";
import ExecutionQuestionRulePhotoInstruction from "../message/ExecutionQuestionRulePhotoInstruction";
import {QUESTION_TYPES} from "../../../../reducers/graphReducer";
import FieldIncompleteIndicator from "../../../components/FieldIncompleteIndicator";
import {downloadAttachment} from "../../../../actions/executions";
import ExecutionPhotoListPhoto from "./ExecutionPhotoListPhoto";
import {strings} from "../../../components/SopLocalizedStrings";
import {questionHasInvalidReason} from "../../../../selectors/executionSelectors";
import Button from "@mui/material/Button";
import PreviewIcon from "@mui/icons-material/VisibilityRounded";
import { REPORT_EVENT_TYPES } from '../../../../util/constants';

class ExecutionPhotoList extends ComponentBase {

    constructor(props) {
        super(props);
        this.state = {
            viewerIsOpen: false,
            currentImageId: '',
            isDownloading: {},
            isDeleting: false,
            deletingSnackbarOpen: false,
            selectedPhotos: [],
            photoIds: null,
            executionQuestionId: null,
            field: null,
        };
        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.handleButtonPress = this.handleButtonPress.bind(this);
        this.handleButtonRelease = this.handleButtonRelease.bind(this);
    }

    height = 150;
    selectionMode = false;
    currentSelectedPhotoId = null;

    componentDidUpdate() {
        let {
            deleteUndo,
            deleteCompleted,
            onSetFileDeleteSnackbarUndo,
            onSetFileDeleteSnackbarToggleState,
            onSetFileDeleteCompleted
        } = this.props;
        const {isDeleting, selectedPhotos} = this.state;
        if (selectedPhotos.length === 0) {
            this.selectionMode = false;
            this.currentSelectedPhotoId = null;
        }
        if (deleteUndo && isDeleting) {
            this.setState({isDeleting: false});
            onSetFileDeleteSnackbarUndo(false);
            onSetFileDeleteSnackbarToggleState(false);
            this.undoDeletePhotos();
            this.deSelectPhotos();
        }

        if (deleteCompleted && isDeleting) {
            this.setState({isDeleting: false}, () => {
                onSetFileDeleteCompleted(false);
            });
            this.deSelectPhotos();
        }
    }

    componentWillUnmount() {
        //Fix warning: Can’t perform a react state update on an unmounted component
        this.setState = () => {
        };
    }

    undoDeletePhotos = () => {
        const {onPutNodesProperty, onPutNodeProperty, viewOnlyLightBox} = this.props;
        const field = viewOnlyLightBox ? this.state.field : this.props.field;
        const photoIds = viewOnlyLightBox ? this.state.photoIds : this.props.photoIds;
        const executionQuestionId = viewOnlyLightBox ? this.state.executionQuestionId : this.props.executionQuestionId;
        const {selectedPhotos} = this.state;
        let photoNodes = [], updatedPhotoIds = [].concat(photoIds).concat(selectedPhotos.map(d => d.id));
        for (let p of selectedPhotos) {
            photoNodes.push({id: p.id, deleted: false});
            const fromIndex = updatedPhotoIds.indexOf(p.id);
            updatedPhotoIds = arrayMove(updatedPhotoIds, fromIndex, p.originalIndex);
        }
        onPutNodesProperty(photoNodes);
        onPutNodeProperty({
            id: executionQuestionId,
            [field + 'PhotoIds']: updatedPhotoIds
        });
        if (viewOnlyLightBox) {
            this.setState({photoIds: updatedPhotoIds});
        }
    }

    deleteSelectedPhotos = () => {
        const {
            onPutNodesProperty,
            onSetFileDeleteSnackbarToggleState,
            onPutNodeProperty,
            viewOnlyLightBox,
        } = this.props;
        const {selectedPhotos} = this.state;
        const field = viewOnlyLightBox ? this.state.field : this.props.field;
        const photoIds = viewOnlyLightBox ? this.state.photoIds : this.props.photoIds;
        const executionQuestionId = viewOnlyLightBox ? this.state.executionQuestionId : this.props.executionQuestionId;
        this.setState({isDeleting: true});
        onSetFileDeleteSnackbarToggleState(true, selectedPhotos.length);
        let photoNodes = [], deletePhotoIds = [];
        for (let p of selectedPhotos) {
            photoNodes.push({id: p.id, deleted: true});
            deletePhotoIds.push(p.id);
        }
        const updatedPhotoIds = photoIds.filter(id => deletePhotoIds.indexOf(id) === -1);
        onPutNodesProperty(photoNodes);
        onPutNodeProperty({
            id: executionQuestionId,
            [field + 'PhotoIds']: updatedPhotoIds
        });
        if (viewOnlyLightBox) {
            this.setState({photoIds: updatedPhotoIds});
        }
    }

    closeLightbox = () => {
        this.setState({viewerIsOpen: false});
    };

    openLightbox = (event, photo) => {
        const {executionId, reportEvent, eventName} = this.props;
        this.setState({viewerIsOpen: true, currentImageId: photo?.id || null});
        reportEvent(executionId, eventName || REPORT_EVENT_TYPES.executionPageAttachmentViewed.name, {photoId: photo?.id});
    };

    handleButtonPress(event, photo) {
        this.buttonPressTimer = setTimeout(() => {
            this.selectionMode = true;
            this.currentSelectedPhotoId = photo.id;
            this.updateSelectedPhotos(photo);
        }, 500);
    }

    handleButtonRelease(event, photo) {
        clearTimeout(this.buttonPressTimer);
        if (photo && this.selectionMode && photo.id !== this.currentSelectedPhotoId) {
            this.currentSelectedPhotoId = null;
            this.selectPhoto(event, photo);
        } else if (this.selectionMode && photo.id === this.currentSelectedPhotoId) {
            event.preventDefault();
        }
    }

    selectPhoto = (event, photo) => {
        this.selectionMode = true;
        event.preventDefault();
        event.stopPropagation();
        this.updateSelectedPhotos(photo);
    }

    updateSelectedPhotos = (photo, deleteAfter) => {
        const {selectedPhotos} = this.state;
        const {viewOnlyLightBox} = this.props;
        const photoIds = viewOnlyLightBox ? this.state.photoIds : this.props.photoIds;
        const index = existsInArrayByKey(selectedPhotos, 'id', photo.id);
        this.setState(state => {
            if (index === -1) {
                const originalIndex = photoIds.indexOf(photo.id);
                state.selectedPhotos.push({...photo, originalIndex: originalIndex});
            } else {
                state.selectedPhotos.splice(index, 1);
            }
            return {
                selectedPhotos: state.selectedPhotos
            };
        }, () => {
            if (deleteAfter) {
                this.deleteSelectedPhotos();
            }
        });
    }

    isPhotoSelected = (photoId) => {
        const {selectedPhotos} = this.state;
        const index = existsInArrayByKey(selectedPhotos, 'id', photoId);
        return index !== -1;
    }

    deSelectPhotos = () => {
        this.selectionMode = false;
        this.setState({
            selectedPhotos: [],
            photoIds: null,
            executionQuestionId: null,
            field: null,
        });
    }

    downloadSelectedPhotos = () => {
        const {selectedPhotos} = this.state;
        for (let photo of selectedPhotos) {
            this.handleDownloadPhoto(photo);
        }
    }

    handleDownloadPhoto = (photo) => {
        this.setState(state => {
            state.isDownloading[photo.id] = true;
            return {
                isDownloading: state.isDownloading
            }
        });
        const url = photo.originalUrl;
        downloadAttachment(url).then(response => {
            if (response) {
                const urlParts = url.split('/');
                const fileName = urlParts[urlParts.length - 1];
                fileDownload(new Blob([response.data]), fileName);
            }
            this.setState(state => {
                delete state.isDownloading[photo.id];
                return {
                    isDownloading: state.isDownloading
                }
            });
        }).catch(error => {
            reportUserError(error, null, 'Photo download failed.');
            this.setState(state => {
                delete state.isDownloading[photo.id];
                return {
                    isDownloading: state.isDownloading
                }
            });
        });
    }

    render() {
        let {
            classes,
            photoIds,
            executionId,
            field,
            executionQuestionId,
            canRead,
            canDelete,
            disabled,
            fieldIsAnswered,
            photoInstructions,
            photoInstructionRuleIds,
            showRequired,
            fieldIsIncomplete,
            addPhotoEnabled,
            displayPhotoList,
            viewOnlyLightBox,
            executionTotalPhotoCount,
        } = this.props;
        let {viewerIsOpen, currentImageId, isDownloading, selectedPhotos, isDeleting} = this.state;
        let cls = [];
        cls.push(classes.gridList);

        if (!viewOnlyLightBox) {
            if (addPhotoEnabled) {
                if (showRequired) {
                    cls.push('fieldIsRequired');
                }
                if (fieldIsAnswered) {
                    cls.push('fieldIsAnswered');
                }
            }

            let notEmpty = (addPhotoEnabled && (photoInstructions || hasValue(photoInstructionRuleIds))) || displayPhotoList;
            if (!notEmpty) {
                return null;
            }
        }

        return <>
            {
                !viewOnlyLightBox && <div className={'fieldContainer'}>
                    {
                        fieldIsIncomplete && <FieldIncompleteIndicator/>
                    }
                    {
                        addPhotoEnabled &&
                        <React.Fragment>
                            {
                                photoInstructions &&
                                <ExecutionQuestionRulePhotoInstruction photoInstructionsText={photoInstructions}/>
                            }
                            {
                                photoInstructionRuleIds.map((id) => (
                                    <ExecutionQuestionRulePhotoInstruction key={id} ruleId={id}/>))
                            }
                        </React.Fragment>
                    }
                    {
                        displayPhotoList &&
                        <ImageList className={cls.join(' ')} cols={-1} rowHeight={this.height} gap={4}
                                  id={'gridList_' + executionQuestionId}
                                  data-cy={'ExecutionPhotoList'}>
                            {
                                addPhotoEnabled &&
                                <ImageListItem key={'AddPhotoControl'} className={classes.gridListTile}>
                                    <div className={classes.photoTitle} >
                                        <ExecutionQuestionAddAttachment
                                            field={field}
                                            questionId={executionQuestionId}
                                        />
                                    </div>                                   
                                </ImageListItem>
                            }
                            {
                                photoIds.filter(a => hasValue(a)).map(photoId =>
                                    <ExecutionPhotoListPhoto
                                        key={'photo-list-photo' + photoId}
                                        photoId={photoId}
                                        executionQuestionId={executionQuestionId}
                                        attachmentType={'thumbnail'}
                                        field={field}
                                        disabled={disabled}
                                        selection={true}
                                        selectPhoto={this.selectPhoto}
                                        isPhotoSelected={this.isPhotoSelected(photoId)}
                                        handleButtonPress={this.handleButtonPress}
                                        handleButtonRelease={this.handleButtonRelease}
                                        openLightbox={this.openLightbox}/>
                                )
                            }
                        </ImageList>
                    }
                    {
                        !isDeleting && selectedPhotos && selectedPhotos.length > 0 &&
                        <div className={classes.selectedPhotoContainer + ' ' + classes.marginTop10}>
                            <IconButton className={'closeSelectionButton'} size={'small'} onClick={this.deSelectPhotos}
                                        title={strings.buttons.closePhotoSelection}>
                                <CloseIcon/>
                            </IconButton>
                            <span className={'title'}>{selectedPhotos.length} selected</span>
                            <div className={classes.grow}/>

                            {
                                canRead &&
                                <div className={classes.iconWrapper}>
                                    <IconButton className={'downloadSelectionButton'} size={'small'}
                                                title={strings.buttons.downloadSelectedPhotos}
                                                disabled={!isEmptyObject(isDownloading)}
                                                onClick={this.downloadSelectedPhotos}>
                                        <DownloadIcon/>
                                    </IconButton>
                                    {!isEmptyObject(isDownloading) &&
                                        <Loader circular={true} circularSize={32} loaderStyleClass={classes.fabProgress}
                                                source={'Execution Photo List'}/>}
                                </div>
                            }

                            {
                                canDelete &&
                                <IconButton className={'deleteSelectionButton'} size={'small'}
                                            title={strings.buttons.deleteSelectedPhotos}
                                            onClick={this.deleteSelectedPhotos}>
                                    <DeleteIcon/>
                                </IconButton>
                            }
                        </div>
                    }
                </div>
            }
            {
                viewOnlyLightBox && executionTotalPhotoCount > 0 &&
                <Button color={'inherit'} title={strings.buttons.showAttachments}
                        data-cy='show-attachments'
                        onClick={this.openLightbox}
                        className={classes.actionButton}>
                    <PreviewIcon/>&nbsp;{strings.execution.attachments.viewButton}
                </Button>
            }
            {
                viewerIsOpen &&
                <ExecutionPhotoCarousel
                    onCloseCarousel={this.closeLightbox}
                    currentImageId={currentImageId}
                    deletePhoto={(photo) => {
                        this.setState({
                            field: photo.propertyName?.replace('PhotoIds', ''),
                            photoIds: photo.questionNodePhotoIds,
                            executionQuestionId: photo.executionQuestionId,
                        }, () => this.updateSelectedPhotos(photo, true))
                    }}
                    executionId={executionId}/>
            }
        </>
    }

}

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        display: 'block !important',
        marginLeft: '-2px !important',
        marginBottom: '-6px !important',
        overflow: 'visible',
    },
    gridListTile: {
        cursor: 'pointer',
        height: 'auto !important',
        padding: '0px 3px !important',
        display: 'inline-block',
        '&:hover .photoSelectingCheckIcon': {
            display: 'block'
        },
        '& .photoSelectingCheckIcon': {
            color: theme.palette.grey.three.main,
            position: 'absolute',
            zIndex: 1,
            borderRadius: '50%',
            fontSize: 18,
            top: 0,
            left: 0,
            display: 'none'
        },
        '& .photoSelectedCheckIcon': {
            color: theme.palette.primary.one.main,
            background: theme.palette.primary.two.main,
            display: 'block'
        }
    },
    photoTitle: {
        borderRadius: 4,
        border: `1px solid transparent`,
        overflow: 'hidden'
    },
    grow: {
        flexGrow: 1
    },
    marginTop10: {
        marginTop: 10
    },
    selectedPhotoContainer: {
        display: 'flex',
        width: 220,
        alignItems: 'center',
        background: theme.palette.grey.one.main,
        border: `1px solid ${theme.palette.grey.two.main}`,
        padding: 5,
        borderRadius: 4,
        '& .title': {
            color: theme.palette.grey.seven.main,
            marginLeft: 8
        },
        '& .closeSelectionButton': {
            color: theme.palette.grey.five.main
        },
        '& .downloadSelectionButton': {
            color: theme.palette.primary.one.main
        },
        '& .deleteSelectionButton': {
            color: theme.palette.primary.one.main
        },
    },
    iconWrapper: {
        position: 'relative',
    },
    fabProgress: {
        color: theme.palette.secondary.four.main,
        position: 'absolute',
        top: -1,
        left: -1,
        zIndex: 1,
    },
    disabledBtn: {
        pointerEvents: 'none',
        opacity: 0.8
    },
    attachmentInstructionsContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 4
    },
    photoInstructionIcon: {
        marginRight: 2,
        fontSize: 18,
        zIndex: 9,
    },
    attachmentInstructions: {
        fontSize: 12,
    },
    actionButton: {
        padding: '2px 10px',
    },
});

ExecutionPhotoList.propTypes = {
    executionQuestionId: PropTypes.string,
    disabled: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
    let execution = getNodeOrNull(state, ownProps.executionId);
    const executionTotalPhotoCount = execution.totalPhotoCount;
    let completeAccess;
    if (ownProps.executionQuestionId) {
        let executionQuestionNode = getNodeOrNull(state, ownProps.executionQuestionId);
        completeAccess = executionQuestionNode?.completeAccess;
    } else {
        // Photo list from right hand properties, cannot know if user can delete or not
        completeAccess = {}
    }
    const canDelete = completeAccess?.canComplete;
    const canRead = true;
    let photoListGalleryProps = {};
    if (!ownProps.viewOnlyLightBox) {
        let executionQuestionNode = getNodeOrNull(state, ownProps.executionQuestionId);
        let field = ownProps.field.replace('Value', '');
        const photoIds = executionQuestionNode[field + 'PhotoIds'] || [];
        const photos = getNodesById(state, photoIds);
        let dirtyNodes = getDirtiesById(state, photos.map(photo => photo.id));
        let includePhoto = executionQuestionNode[field + 'PhotoIdsEnabled'];
        let activeRuleIds = getNodesIfPresent(state, executionQuestionNode.ruleIds || EMPTY_ARRAY)
            .filter(a => a.evaluatedValue === true && includePhoto && a.photoInstructionsOn)
            .map(a => a.id);
        let isQuestionPhoto = executionQuestionNode.questionType === QUESTION_TYPES.photo.id;
        const required = executionQuestionNode[field + 'PhotoIdsRequired'];
        const addPhotoEnabled = !ownProps.disabled && executionQuestionNode[field + 'PhotoIdsEnabled'];
        let displayPhotoList = required || hasValue(photos) || hasValue(photoIds) || executionQuestionNode.questionType === QUESTION_TYPES.photo.id;
        photoListGalleryProps = {
            attachmentType: executionQuestionNode.attachmentType,
            questionId: executionQuestionNode.id,
            photoIds: photoIds,
            dirtyNodes: keyBy(dirtyNodes, 'id'),
            photoInstructionRuleIds: activeRuleIds,
            photoInstructions: executionQuestionNode.photoInstructions,
            photoIdsEnabled: executionQuestionNode[field + 'Enabled'],
            questionType: executionQuestionNode.questionType,
            showRequired: required && !isQuestionPhoto,
            fieldIsAnswered: !isQuestionPhoto && photos.length > 0,
            fieldIsIncomplete: questionHasInvalidReason(executionQuestionNode, field + 'PhotoIds'),
            addPhotoEnabled: addPhotoEnabled,
            displayPhotoList: displayPhotoList,
        }
    }

    return {
        canDelete: canDelete,
        canRead: canRead,
        fileDeleteSnackBarToggleState: state.appReducer.fileDeleteSnackBarToggleState,
        deleteCompleted: state.appReducer.deleteCompleted,
        fileLength: state.appReducer.fileLength,
        deleteUndo: state.appReducer.deleteUndo,
        executionTotalPhotoCount: executionTotalPhotoCount,
        ...photoListGalleryProps,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: (node) => dispatch(putNodeProperty(node)),
        onPutNodesProperty: (node) => dispatch(putNodesProperty(node)),
        onSetFileDeleteSnackbarToggleState: (state, fileLength) => dispatch(setFileDeleteSnackbarToggleState(state, fileLength)),
        onSetFileDeleteSnackbarUndo: (state) => dispatch(setFileDeleteSnackbarUndo(state)),
        onSetFileDeleteCompleted: (state) => dispatch(setFileDeleteCompleted(state)),
        reportEvent: (id, eventName, eventProperties) => dispatch(reportNodeEvent(id, eventName, eventProperties)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionPhotoList));
