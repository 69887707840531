import React from 'react';
import '../../../style/alert.css';
import {exportExecutionToPDF} from "../../../actions/executions";
import Export from "@mui/icons-material/GetAppRounded";
import withStyles from '@mui/styles/withStyles';
import {toast} from "react-toastify";
import {getNodeOrNull} from "../../../selectors/graphSelectors";
import {connect} from "react-redux";
import {ComponentBase, reportUserError} from "tbf-react-library";
import {strings} from "../../components/SopLocalizedStrings";
import TbfSopAction from '../../components/common/TbfSopAction';
import { REPORT_EVENT_TYPES } from '../../../util/constants';

class ExecutionExportPDF extends ComponentBase {

    constructor(props) {
        super(props);
        this.state = {
            isExporting: false
        }
    }

    exportToPdf = () => {
        const {preview, draft, handleClicked} = this.props;
        handleClicked && handleClicked();
        if (preview) {
            toast.error(strings.execution.errors.exportPdfPreview);
        } else if (draft) {
            toast.error(strings.execution.errors.exportPdfPreview);
        } else {
            const {nodeId} = this.props;
            this.setState({isExporting: true});
            let promise = exportExecutionToPDF(nodeId);
            promise.then(response => {
                if (response) {
                    let fileName = 'execution';
                    const contentDisposition = response.headers['content-disposition'];
                    if (contentDisposition) {
                        const contentDispositionParts = contentDisposition.split('filename=')[1];
                        fileName = contentDispositionParts ? contentDispositionParts.split(';')[0] ? contentDispositionParts.split(';')[0].split('"').join("") : 'execution' : 'execution';
                    }
                    const fileDownload = require('js-file-download');
                    fileDownload(new Blob([response.data]), fileName);
                }
                this.setState({isExporting: false});
            }).catch(error => {
                reportUserError(error, null, 'Generating PDF failed.');
                this.setState({isExporting: false});
            });
        }
    }

    render() {
        const {nodeId, canExport} = this.props;
        if (!canExport) {
            return null;
        }
        return (
            <TbfSopAction
                menuItem={true}
                title={strings.execution.show.exportPdf}
                dataCy='export-pdf'
                iconComponent={Export}
                onClick={this.exportToPdf}
                nodeId={nodeId}
                eventName={REPORT_EVENT_TYPES.executionPageMenuExportPdf.name}
            >
                {strings.execution.show.exportPdf}
            </TbfSopAction>);
    }
}

const styles = (theme) => ({
    iconWrapper: {
        position: 'relative',
        width: 'auto',
        float: 'left',
        marginRight: 8,
        top: 1,
    },
    listItemWrapper: {
        color: theme.palette.grey.seven.main,
        fontSize: '14px',
        paddingTop: 5,
        width: '100%'
    },
    listItemFabProgress: {
        color: theme.palette.primary.one.main,
        position: 'absolute',
        top: -4,
        left: -4,
        zIndex: 1,
    },
    disabled: {
        pointerEvents: 'none',
        opacity: 0.7
    }
});
ExecutionExportPDF.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.nodeId;
    let executionNode = getNodeOrNull(state, nodeId);
    let canExport = executionNode?.completeAccess?.canView
    return {
        nodeId: nodeId,
        preview: executionNode.preview,
        draft: executionNode.draft,
        canExport: canExport
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionExportPDF));
