import React from 'react'
import {NODE_IDS} from '../../reducers/graphReducer'
import {downloadAllItems, retryItemDownload,} from '../MapView/selectors'
import {DateAge, TbfAction} from "tbf-react-library";
import {useCallbackPatchNode, useNodeOrNull} from "../../hooks/nodeHooks";
import TestingFeature from "../components/TestingFeature";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import Grid from "@mui/material/Grid";

function AssignmentsOfflineDiagnostics({}) {
  const itemLoader = useNodeOrNull(NODE_IDS.MyAssignedExecutions)
  const checkNow = useCallbackPatchNode(retryItemDownload)
  const downloadAll = useCallbackPatchNode(downloadAllItems)
  return (
      <TestingFeature>
        <Grid container>
          <Grid item xs={12} sm={6}>
            Last checked <DateAge value={itemLoader?.lastReloadTicks}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TbfAction
                onClick={checkNow}
                iconComponent={CheckCircleOutlineIcon}
                title={'Check for updates now'}
                data-cy={'offline-check-now'}
            >
              Check now
            </TbfAction>
          </Grid>
          <Grid item xs={12} sm={6}>
            Last full download <DateAge value={itemLoader?.lastFullReloadTicks}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TbfAction
                onClick={downloadAll}
                iconComponent={DownloadForOfflineOutlinedIcon}
                title={'Re-download all assigned items'}
                data-cy={'offline-re-download-all'}
            >
              Download again
            </TbfAction>
          </Grid>
        </Grid>
      </TestingFeature>
  )
}

export default AssignmentsOfflineDiagnostics
