import React from "react";
import EmptyRecentActivityImage from "../../assets/emptyRecentActivityImage.PNG";
import withStyles from '@mui/styles/withStyles';

class EmptyAssignmentMessage extends React.Component {

    render() {
        let {classes} = this.props;
        return <div className={classes.emptyMessageContainer}>
            <label className={classes.emptyMainMessage}>No assignments</label>
            <label className={classes.emptySecondaryMessage}>Your assignments will show up here</label>
            <div className={classes.emptyMessageImageContainer}>
                <img src={EmptyRecentActivityImage} alt={''}/>
            </div>
        </div>
    }
}

const styles = theme => ({
    emptyMessageContainer: {
        width: '100%',
        textAlign: 'center',
        paddingTop: 'calc(10vh - 20px)',
        '@media (min-height: 900px)': {
            paddingTop: 'calc(10vh - 55px)',
        },
    },
    emptyMainMessage: {
        display: 'block',
        fontWeight: 500,
        fontSize: 18,
        color: '#2c3435'
    },
    emptySecondaryMessage: {
        display: 'block',
        fontWeight: 500,
        fontSize: 12,
        color: '#265156',
        padding: '5px 0'
    },
    emptyMessageImageContainer: {
        display: 'block',
    },
});

export default withStyles(styles)(EmptyAssignmentMessage);
