import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import withStyles from '@mui/styles/withStyles';
import {clearGraph} from "../../actions";
import {connect} from "react-redux";
import IconButton from "@mui/material/IconButton";
import DirectionsBoat from '@mui/icons-material/DirectionsBoat';
import {cypress} from "tbf-react-library";
import Badge from "@mui/material/Badge";
import {getGraph, getNodeOrNull} from "../../selectors/graphSelectors";
import {strings} from "../components/SopLocalizedStrings";
import GraphMobileListItem from './GraphMobileListItem';
import {DIAGNOSTIC_MODES, NODE_IDS} from '../../reducers/graphReducer';

class GraphNodeViewButton extends Component {

    handleClick = () => {
        let {history} = this.props;
        history.push('/graph/nodes');
    }

    render() {
        let {display, errorCount, classes} = this.props;
        return <>
            {
                display &&
                <IconButton size={'small'} data-cy={'GraphNodeViewButton'} onClick={this.handleClick}
                            title={strings.buttons.graphNodeView}>
                    <GraphMobileListItem>
                        <Badge badgeContent={errorCount} color={"secondary"} data-error-count={errorCount}
                            className={errorCount === 0 ? classes.hideBadgeCount : ''}>
                            <DirectionsBoat/>
                        </Badge>
                        <span className={'menuItemTitle'}>Nodes</span>
                    </GraphMobileListItem>
                </IconButton>
            }
        </>
    }
}

const styles = () => ({
    hideBadgeCount: {
        '& .MuiBadge-invisible': {
            display: 'none',
        }
    }
});
GraphNodeViewButton.propTypes = {};
const mapStateToProps = (state) => {
    let graph = getGraph(state);
    const diagnosticModeOn = getNodeOrNull(state, NODE_IDS.UserDevice)?.diagnosticMode === DIAGNOSTIC_MODES.full.id;
    const isCypress = cypress.isCypress();
    return {
        display: diagnosticModeOn || isCypress,
        errorCount: graph.processingErrorCount + graph.processingWarningCount
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onClearGraph: () => dispatch(clearGraph())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(GraphNodeViewButton)));
