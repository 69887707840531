import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {clearGraph} from "../../actions";
import {connect} from "react-redux";
import IconButton from "@mui/material/IconButton";
import {cypress} from "tbf-react-library";
import ReplayIcon from '@mui/icons-material/Replay';
import {strings} from "../components/SopLocalizedStrings";
import GraphMobileListItem from './GraphMobileListItem';

class GraphReloadButton extends Component {

    handleClear() {
        window.location.reload()
    }

    render() {
        let {classes, display} = this.props;
        return <>
            {
                display &&
                <IconButton size={'small'} onClick={this.handleClear} data-cy={'GraphReloadButton'}
                            title={strings.buttons.graphReload}>
                    <GraphMobileListItem>
                        <ReplayIcon className={classes.buttonIcon}/>
                        <span className={'menuItemTitle'}>{strings.buttons.reload}</span>                        
                    </GraphMobileListItem>
                </IconButton>
            }
        </>
    }
}

const styles = () => ({});
GraphReloadButton.propTypes = {};
const mapStateToProps = () => {
    return {
        display: cypress.isReloadButtonEnabled()
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onClearGraph: () => dispatch(clearGraph())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphReloadButton));
