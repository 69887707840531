import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../../../style/alert.css';
import {putNodeProperty, putNodes} from "../../../../actions";
import Input from "@mui/material/Input";
import {getNodeOrNull, getNodeSchemaOrNull} from "../../../../selectors/graphSelectors";
import {hasValue} from "../../../../util/util";
import * as PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {strings} from "../../../components/SopLocalizedStrings";
import ExecutionPhotoUploadBase from "../../ExecutionPhotoUploadBase";
import {getEnabledChildRulesForNodeByActionOrNull} from "../../../../selectors/ruleSelectors";
import {RULE_ACTION_TYPE} from "../../../../reducers/graphReducer";

class ExecutionTakePhoto extends ExecutionPhotoUploadBase {

    constructor(props) {
        super(props);
        this.state = {
            imageMetaData: '',
            messages: [],
            photos: []
        };
        this.handleFileChanged = this.handleFileChanged.bind(this);
    }

    async handleFileChanged(e) {
        if (typeof window.FileReader !== 'function') {
            throw Error("The file API isn't supported on this browser.");
        }
        const input = e.target;
        const files = input.files;

        for (const file of files) {
            await this.handleFileAdded(file);
        }
    };

    getAttachmentsInputProps = () => {
        const {attachmentType, mobilePhotoMode, required} = this.props;
        const allowedFileTypes = '.doc,.docx,.odt,.txt,.rtf,.wps,.wks,.wpd,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,.xlsx,.xls,.csv,image/*';

        switch (attachmentType) {
            case "file":
                return {
                    accept: allowedFileTypes,
                    required: required,
                    tabIndex: -1,
                    multiple: true,
                    'aria-hidden': true
                };
            case "photo":
                if (mobilePhotoMode === "cameraonly") {
                    return {
                        accept: "image/*",
                        capture: "camera",
                        required: required,
                        tabIndex: -1,
                        multiple: true,
                        'aria-hidden': true
                    };
                } else if (mobilePhotoMode === "cameragallery") {
                    return {accept: "image/*", multiple: true, required: required, tabIndex: -1, 'aria-hidden': true};
                }
                return {required: required, tabIndex: -1, multiple: true, 'aria-hidden': true};
            default:
                return {required: required, tabIndex: -1, multiple: true, 'aria-hidden': true};
        }
    }

    clearInputContent = (event) => {
        event.target.value = null
    }

    render() {
        let {classes, photoInputId} = this.props;

        return (
            <FormControl>
                <FormLabel htmlFor={photoInputId} hidden={true} aria-label={strings.buttons.uploadAttachments}/>
                <Input id={photoInputId} type="file"
                       onClick={this.clearInputContent}
                       inputProps={this.getAttachmentsInputProps()}
                       onChange={this.handleFileChanged}
                       className={classes.inputFile}
                />
            </FormControl>

        )
    }
}

const styles = () => ({
    inputFile: {
        width: '0.1px;',
        height: '0.1px',
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1
    },
});
ExecutionTakePhoto.propTypes = {
    executionQuestionId: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
    let node = getNodeOrNull(state, ownProps.executionQuestionId);
    let executionNode = getNodeOrNull(state, node.rootId);
    const photoIds = (node && node[ownProps.field + 'PhotoIds']) || null;
    const aspectRatioRules = getEnabledChildRulesForNodeByActionOrNull(state, ownProps.executionQuestionId, RULE_ACTION_TYPE.photoAspectRatio.id)
    const aspectRatio = aspectRatioRules.length > 0 ? aspectRatioRules[0].calculateValue : null
    return {
        projectId: executionNode?.projectId,
        executionId: executionNode?.id,
        procedureId: executionNode?.procedureId,
        attachmentType: node?.attachmentType || null,
        mobilePhotoMode: node?.mobilePhotoMode || null,
        photoSchema: getNodeSchemaOrNull(state, 'Photo'),
        photoCaptureSchema: getNodeSchemaOrNull(state, 'PhotoCapture'),
        photoInputId: ownProps.executionQuestionId + '-' + ownProps.field,
        required: node && node[ownProps.field + 'PhotoIdsRequired'] && !hasValue(photoIds),
        aspectRatio: aspectRatio
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        onPutNodes: nodes => dispatch(putNodes(nodes)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionTakePhoto));
