import React, {Component} from 'react';
import flow from 'lodash/flow'
import {Router, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import {connect, Provider} from 'react-redux';
import '../style/App.css';
import DrawerWithAppbar from './components/drawer-with-appbar.jsx';
import Main from './main.jsx';
import {
    ai,
    cypress,
    DeviceOrientation,
    ErrorBoundary,
    history,
    ScreenReaderText,
    SHARED_AUTH_INSTANCE,
    SharedAuth,
    theme,
    TOAST_AUTO_CLOSE,
    TOAST_PAUSE_FOCUS_LOST,
    URL_RETURNED_RENEW_RELATIVE,
} from "tbf-react-library";
import SOPRoute from './Router'
import {styles} from '../style/app-styles'
import store from '../store';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {withAITracking} from '@microsoft/applicationinsights-react-js';
import Location from "./components/Location";
import BrowserSupported from "./BrowserSupported";
import UserReduxConnector, {UserDbConnector} from "./UserReduxConnector";
import SopFooter from "./components/SopFooter";
import CssBaseline from '@mui/material/CssBaseline';
import GraphDeveloperChecks from "./graph/GraphDeveloperChecks";
import gsap from 'gsap'
import CSSPlugin from 'gsap/CSSPlugin'
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ImageEditor from './ImageEditor';
import ConfirmationDialog from './components/ConfirmationDialog';
import AppActiveReporter from './AppActiveReporter';
import GraphAutoSave from "./graph/GraphAutoSave";
import {Container} from "@mui/material";
import Activity from './components/Activity.jsx';
import GraphConfigLoad from "./graph/GraphConfigLoad";

gsap.registerPlugin(CSSPlugin, ScrollToPlugin)

const mapStateToProps = () => {
    return {};
};
const flowOfViews = [
    connect(mapStateToProps),
    withStyles(styles),
    withRouter
];
SHARED_AUTH_INSTANCE.initialise();
if (!cypress.isCypress()) {
    ai.initialize({history: history});
}
const AuthenticatedView = flow(flowOfViews)(({classes}) => {
    const isAuthenticated = SharedAuth.isAuthenticated();
    const isAnonymous = SharedAuth.isAnonymous()
    const inReturnedRenewPage = window.location.pathname.endsWith(URL_RETURNED_RENEW_RELATIVE);

    if(!isAuthenticated) {
        if (isAnonymous) {
            return <div className={classes.rootContainer}>
                <Container maxWidth="md" id={'main'} data-cy-element='Main' role={'main'}>
                    <GraphConfigLoad/>
                    <SOPRoute/>
                </Container>
                <ScreenReaderText>
                    <GraphAutoSave/>
                </ScreenReaderText>
            </div>
        }
        return <SOPRoute/>
    }

    if(isAuthenticated && inReturnedRenewPage) {
        return  <SOPRoute/>
    }

    return  <div className={classes.rootContainer}>
        <Location/>
        <Activity/>
        <DeviceOrientation/>
        <DrawerWithAppbar auth={SHARED_AUTH_INSTANCE}/>
        <ErrorBoundary>
            <Main/>
        </ErrorBoundary>
        <SopFooter customClass={classes.footerColor}/>
        <GraphDeveloperChecks/>
        <div id={'dialog-container'}/>
        <ImageEditor />
        <ConfirmationDialog />
    </div>
    }
);

class App extends Component {
     render() {
        return (
                <AppActiveReporter>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <UserDbConnector>
                                <Provider store={store}>
                                    <UserReduxConnector auth={SHARED_AUTH_INSTANCE}/>
                                        <Router history={history}>
                                            <BrowserSupported>
                                                <CssBaseline/>
                                                <AuthenticatedView/>
                                            </BrowserSupported>
                                            <ToastContainer theme={'colored'} autoClose={TOAST_AUTO_CLOSE} position={'bottom-right'}
                                                            pauseOnFocusLoss={TOAST_PAUSE_FOCUS_LOST}/>
                                        </Router>
                                </Provider>
                                <CssBaseline/>
                            </UserDbConnector>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </AppActiveReporter>
        );
    }
}

App.propTypes = {
    theme: PropTypes.object,
};

export default withAITracking(ai.reactPlugin, App);
