import React from 'react';
import {LoginPage} from "tbf-react-library";
import SopFooter from "./components/SopFooter";
import withStyles from '@mui/styles/withStyles';

class LoginWrapper extends React.Component {

    render() {
        const {auth, classes} = this.props;

        return <LoginPage auth={auth} renderFooter={() => (
            <SopFooter customClass={classes.customFooterClass}/>
        )}/>
    }
}

const styles = theme => ({
    customFooterClass: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%'
    }
});

export default withStyles(styles)(LoginWrapper);