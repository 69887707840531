import {domainRuleNOP} from "./common";
import {addDependency} from "../../factory/executionFactory";
import {getNodeOrNull} from "../../selectors/graphSelectors";
import isEqual from "lodash/isEqual";
import {NODE_IDS} from "../graphReducer";
import {mergeUnique} from "../../util/util";

export const USER_DOMAIN_RULES = {
    ...domainRuleNOP,
    onPut: (state, node, beforeState) => {
        const update = domainRuleNOP.onPut(state, node);
        node.permissionsMap = {};
        for (let permission of node.permissions || []) {
            node.permissionsMap[permission] = true;
        }
        addDependency(update, {from: NODE_IDS.Me, to: node, properties: ['loaded']});
        let userSubject = getNodeOrNull(state, NODE_IDS.Me);
        if (userSubject?.nodeIds?.length) {
            node.executionId = userSubject.nodeIds[0];
        }
        const beforeNode = getNodeOrNull(beforeState, node.id)
        if (!isEqual(beforeNode.groups, node.groups) || !isEqual(beforeNode.permissions, node.permissions)) {
            const config = getNodeOrNull(state, NODE_IDS.ClientConfig);
            if (config) {
                update[config.id] = {...config, userCounter: (config.userCounter || 0) + 1};
            }
        }
        node.scopes = mergeUnique(node.scopes, ['AlwaysLoad']);


        return update;
    }
}