import React from "react";
import withStyles from '@mui/styles/withStyles';
import {useLinkToQuestionRules} from "../../selectors/procedureSelectors";
import NodeTextField from "../graph/NodeTextField";
import {strings} from "../components/SopLocalizedStrings";
import FormLabel from "@mui/material/FormLabel";
import {useNodeOrError} from "../../hooks/nodeHooks";
import ProcedureSelector from "./ProcedureSelector";

const ProcedureSelectLink = ({classes, nodeId, disabled}) => {
    let node = useNodeOrError(nodeId);
    disabled = disabled || node.canComplete === false
    const nodeAutoRules = useLinkToQuestionRules(node.id).filter(rule => (!rule.deleted && !rule.implicitlyDeleted))
    return <>
        {
            nodeAutoRules.length === 1 && <>
                {
                    <ProcedureSelector nodeId={nodeAutoRules[0].id} disabled={disabled}
                                       propertyName={'linkMatchProcedureIds'}/>
                }
                <NodeTextField
                    nodeId={nodeAutoRules[0].id}
                    visual={'select'}
                    nodePropertyName={'linkMatchLinkTypes'}
                    disabled={disabled}
                />
                <NodeTextField
                    nodeId={nodeAutoRules[0].id}
                    nodePropertyName={'addNewOn'}
                    disabled={disabled}
                />
            </>
        }
        {
            nodeAutoRules.length > 1 &&
            <FormLabel
                className={classes.advancedModeMsg}>{strings.procedure.show.selectLinkAdvancedModeMsg}</FormLabel>
        }
    </>
}

const styles = () => ({
    advancedModeMsg: {
        margin: '20px 1px',
    },
});

export default withStyles(styles)(ProcedureSelectLink);
